import { CONTRACT_CONDITIONS_PAYMENT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isLoading: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    message: string | null;
    text: string;
    isActive: boolean;
    acceptedAt: string;
}
export const initialState: State = {
    isLoading: false,
    hasSuccess: false,
    hasError: false,
    message: null,
    text: "",
    isActive: false,
    acceptedAt: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT_CONDITIONS_PAYMENT.INIT: return contractConditionsPaymentInit(state);
        case CONTRACT_CONDITIONS_PAYMENT.DESTROY: return contractConditionsPaymentDestroy(state);
        case CONTRACT_CONDITIONS_PAYMENT.HYDRATE: return contractConditionsPaymentHydrate(state);
        case CONTRACT_CONDITIONS_PAYMENT.HYDRATE_SUCCESS: return contractConditionsPaymentHydrateSuccess(state, action.payload);
        case CONTRACT_CONDITIONS_PAYMENT.HYDRATE_FAILURE: return contractConditionsPaymentHydrateFailure(state, action.payload);
        case CONTRACT_CONDITIONS_PAYMENT.CLICK_ACCEPT: return contractConditionsPaymentClickAccept(state);
        default: return state;
    }
}
export function contractConditionsPaymentInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function contractConditionsPaymentDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function contractConditionsPaymentHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractConditionsPaymentHydrateSuccess(state: State, payload: string): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        text: payload
    };
}
export function contractConditionsPaymentHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function contractConditionsPaymentClickAccept(state: State): State {
    return {
        ...state,
        acceptedAt: new Date().toISOString()
    };
}
