import { SUBSCRIPTION_EDIT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState } from "@client/utils/shared-constants";

export class SubscriptionEditInitAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionEditDestroyAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionEditUpdateAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.UPDATE;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class SubscriptionEditUpdateSuccessAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.UPDATE_SUCCESS;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class SubscriptionEditUpdateFailureAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.UPDATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class SubscriptionEditClickSubmitAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.CLICK_SUBMIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionEditFormUpdateAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.FORM_UPDATE;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class SubscriptionEditFormResetAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.FORM_RESET;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionEditUploadAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.UPLOAD;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class SubscriptionEditUploadSuccessAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.UPLOAD_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class SubscriptionEditUploadFailureAction implements Action {
    readonly type = SUBSCRIPTION_EDIT.UPLOAD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
