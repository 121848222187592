import { State as RegisterState } from "@client/reducers/register-reducer";
import { Selector } from "@ngrx/store";
import { RegisterEmailForm } from "@client/utils/shared-constants";
export const getMessage: Selector<RegisterState, string | null> = (state: RegisterState) => state.message;
export const getHasError: Selector<RegisterState, boolean> = (state: RegisterState) => state.hasError;
export const getHasSuccess: Selector<RegisterState, boolean> = (state: RegisterState) => state.hasSuccess;
export const getIsRegistered: Selector<RegisterState, boolean> = (state: RegisterState) => state.isRegistered;
export const getIsLogged: Selector<RegisterState, boolean> = (state: RegisterState) => state.isLogged;
export const getIsWaitingValidation: Selector<RegisterState, boolean> = (state: RegisterState) => state.isWaitingValidation;
export const getIsActive: Selector<RegisterState, boolean> = (state: RegisterState) => state.isActive;
export const getIsComplete: Selector<RegisterState, boolean> = (state: RegisterState) => state.isComplete;
export const getIsLoading: Selector<RegisterState, boolean> = (state: RegisterState) => state.isLoading;
export const getForm: Selector<RegisterState, RegisterEmailForm> = (state: RegisterState) => state.form;
export const getFormConfirmPassword: Selector<RegisterState, string> = (state: RegisterState) => getForm(state) && getForm(state).confirm_password;
export const getFormEmail: Selector<RegisterState, string> = (state: RegisterState) => getForm(state) && getForm(state).email;
export const getFormMethod: Selector<RegisterState, string> = (state: RegisterState) => getForm(state) && getForm(state).method;
export const getFormPassword: Selector<RegisterState, string> = (state: RegisterState) => getForm(state) && getForm(state).password;
export const getFormCip: Selector<RegisterState, string> = (state: RegisterState) => getForm(state) && getForm(state).cip;
