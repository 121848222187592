import { State as AdminConfigurationState } from "@client/reducers/admin-configuration-reducer";
import { Selector } from "@ngrx/store";
import { MonerisConfiguration, SystemConfiguration } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminConfigurationState, boolean> = (state: AdminConfigurationState) => state.isActive;
export const getMessage: Selector<AdminConfigurationState, string | null> = (state: AdminConfigurationState) => state.message;
export const getHasError: Selector<AdminConfigurationState, boolean> = (state: AdminConfigurationState) => state.hasError;
export const getHasSuccess: Selector<AdminConfigurationState, boolean> = (state: AdminConfigurationState) => state.hasSuccess;
export const getIsLoading: Selector<AdminConfigurationState, boolean> = (state: AdminConfigurationState) => state.isLoading;
export const getIsLoaded: Selector<AdminConfigurationState, boolean> = (state: AdminConfigurationState) => state.isLoaded;
export const getIsEdit: Selector<AdminConfigurationState, boolean> = (state: AdminConfigurationState) => state.isEdit;
export const getForm: Selector<AdminConfigurationState, MonerisConfiguration | null> = (state: AdminConfigurationState) => state.form;
export const getData: Selector<AdminConfigurationState, SystemConfiguration | null> = (state: AdminConfigurationState) => state.data;
export const getDataMoneris: Selector<AdminConfigurationState, {
    url: string;
    vault: {
        id: string;
        key: string;
    };
    paypage: {
        id: string;
        key: string;
    };
}> = (state: AdminConfigurationState) => getData(state) && getData(state).moneris;
export const getDataMonerisUrl: Selector<AdminConfigurationState, string> = (state: AdminConfigurationState) => getDataMoneris(state) && getDataMoneris(state).url;
export const getDataMonerisVault: Selector<AdminConfigurationState, {
    id: string;
    key: string;
}> = (state: AdminConfigurationState) => getDataMoneris(state) && getDataMoneris(state).vault;
export const getDataMonerisVaultId: Selector<AdminConfigurationState, string> = (state: AdminConfigurationState) => getDataMonerisVault(state) && getDataMonerisVault(state).id;
export const getDataMonerisVaultKey: Selector<AdminConfigurationState, string> = (state: AdminConfigurationState) => getDataMonerisVault(state) && getDataMonerisVault(state).key;
export const getDataMonerisPaypage: Selector<AdminConfigurationState, {
    id: string;
    key: string;
}> = (state: AdminConfigurationState) => getDataMoneris(state) && getDataMoneris(state).paypage;
export const getDataMonerisPaypageId: Selector<AdminConfigurationState, string> = (state: AdminConfigurationState) => getDataMonerisPaypage(state) && getDataMonerisPaypage(state).id;
export const getDataMonerisPaypageKey: Selector<AdminConfigurationState, string> = (state: AdminConfigurationState) => getDataMonerisPaypage(state) && getDataMonerisPaypage(state).key;
