import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageStateComponent } from './message-state.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MessageStateComponent],
  exports: [MessageStateComponent]
})
export class MessageStateModule {}
