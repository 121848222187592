import { VEHICLES_EDIT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    hasSuccess: boolean;
    isActive: boolean;
    hasError: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    redirectOnComplete: boolean;
    message: string | null;
    form: Vehicule;
}
export const initialState: State = {
    hasSuccess: false,
    isActive: false,
    hasError: false,
    isLoading: false,
    isCompleted: false,
    redirectOnComplete: false,
    message: null,
    form: {
        description: "",
        id: 0,
        key: "",
        licencePlate: "",
        manufacturer: "",
        model: "",
        vehiculeYear: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case VEHICLES_EDIT.INIT: return vehiclesEditInit(state);
        case VEHICLES_EDIT.DESTROY: return vehiclesEditDestroy(state);
        case VEHICLES_EDIT.FORM_UPDATE: return vehiclesEditFormUpdate(state, action.payload);
        case VEHICLES_EDIT.SELECT_YEAR: return vehiclesEditSelectYear(state, action.payload);
        case VEHICLES_EDIT.SELECT_BRAND: return vehiclesEditSelectBrand(state, action.payload);
        case VEHICLES_EDIT.SELECT_MODEL: return vehiclesEditSelectModel(state, action.payload);
        case VEHICLES_EDIT.CLICK_SUBMIT: return vehiclesEditClickSubmit(state, action.payload);
        case VEHICLES_EDIT.UPDATE: return vehiclesEditUpdate(state, action.payload);
        case VEHICLES_EDIT.UPDATE_SUCCESS: return vehiclesEditUpdateSuccess(state, action.payload);
        case VEHICLES_EDIT.UPDATE_FAILURE: return vehiclesEditUpdateFailure(state, action.payload);
        default: return state;
    }
}
export function vehiclesEditInit(state: State): State {
    return {
        ...initialState,
        isActive: true,
    };
}
export function vehiclesEditDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function vehiclesEditFormUpdate(state: State, payload: Vehicule): State {
    return {
        ...state,
        form: payload,
    };
}
export function vehiclesEditSelectYear(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesEditSelectBrand(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesEditSelectModel(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesEditClickSubmit(state: State, payload: Vehicule): State {
    return { ...state };
}
export function vehiclesEditUpdate(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function vehiclesEditUpdateSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function vehiclesEditUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
