import { PAYMENT_SELECT_METHOD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    selectedMethod: string | null;
    isDasPaymentAccepted: boolean;
    isBankingCardPaymentAccepted: boolean;
    isFinancialInstitutionPaymentAccepted: boolean;
    isPpaPaymentAccepted: boolean;
}
export const initialState: State = {
    isActive: false,
    selectedMethod: null,
    isDasPaymentAccepted: true,
    isBankingCardPaymentAccepted: true,
    isFinancialInstitutionPaymentAccepted: true,
    isPpaPaymentAccepted: true
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PAYMENT_SELECT_METHOD.INIT: return paymentSelectMethodInit(state);
        case PAYMENT_SELECT_METHOD.DESTROY: return paymentSelectMethodDestroy(state);
        case PAYMENT_SELECT_METHOD.SELECT_METHOD: return paymentSelectMethodSelectMethod(state, action.payload);
        default: return state;
    }
}
export function paymentSelectMethodInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function paymentSelectMethodDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function paymentSelectMethodSelectMethod(state: State, payload: string): State {
    return {
        ...state,
        selectedMethod: payload
    };
}
