import { State as ParksSelectState } from "@client/reducers/parks-select-reducer";
import { Selector } from "@ngrx/store";
import { ParkInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.isActive;
export const getMessage: Selector<ParksSelectState, string | null> = (state: ParksSelectState) => state.message;
export const getHasError: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.hasError;
export const getHasSuccess: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.hasSuccess;
export const getIsLoading: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.isLoading;
export const getShowEmpty: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.showEmpty;
export const getShowPrivate: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.showPrivate;
export const getShowPublic: Selector<ParksSelectState, boolean> = (state: ParksSelectState) => state.showPublic;
export const getSelectedPark: Selector<ParksSelectState, ParkInfo | null> = (state: ParksSelectState) => state.selectedPark;
export const getSelectedParkCity: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).city;
export const getSelectedParkCivicNumber1: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).civicNumber1;
export const getSelectedParkCivicNumber2: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).civicNumber2;
export const getSelectedParkEmail: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).email;
export const getSelectedParkGpsLatitude: Selector<ParksSelectState, number> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).gpsLatitude;
export const getSelectedParkGpsLongitude: Selector<ParksSelectState, number> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).gpsLongitude;
export const getSelectedParkParkIdentificationNo: Selector<ParksSelectState, number> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).parkIdentificationNo;
export const getSelectedParkParkName: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).parkName;
export const getSelectedParkPhoneExtension: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).phoneExtension;
export const getSelectedParkPhoneNo: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).phoneNo;
export const getSelectedParkPostalCode: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).postalCode;
export const getSelectedParkProvince: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).province;
export const getSelectedParkServerHostName: Selector<ParksSelectState, string> = (state: ParksSelectState) => getSelectedPark(state) && getSelectedPark(state).serverHostName;
