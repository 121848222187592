import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_CODE_IMPORT, ADMIN_CODE_FORM, ADMIN_CODE_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeInfo, OrganizationInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    hasImport: boolean;
    selectedCodeIndex: number | null;
    selectedFirstnameIndex: number | null;
    selectedLastnameIndex: number | null;
    extraIndex: any | null;
    canUpdate: boolean;
    canDelete: boolean;
    canAdd: boolean;
    ignoreHeader: boolean;
    importStats: {
        total: number;
        add: number;
        update: number;
        delete: number;
    };
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: AccessCodeInfo[];
    importData: any;
    selecteAccessCode: AccessCodeInfo | null;
    selectedOrganizationCode: string;
    selectedGroupCode: string;
    showOrganizationBacklink: boolean;
    showGroupBacklink: boolean;
}
export const initialState: State = {
    hasImport: false,
    selectedCodeIndex: null,
    selectedFirstnameIndex: null,
    selectedLastnameIndex: null,
    extraIndex: "extraIndex",
    canUpdate: true,
    canDelete: false,
    canAdd: true,
    ignoreHeader: false,
    importStats: {
        total: 0,
        add: 0,
        update: 0,
        delete: 0
    },
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    importData: [],
    selecteAccessCode: null,
    selectedOrganizationCode: "",
    selectedGroupCode: "",
    showOrganizationBacklink: false,
    showGroupBacklink: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_CODE_LIST.INIT: return adminCodeListInit(state);
        case ADMIN_CODE_LIST.DESTROY: return adminCodeListDestroy(state);
        case ADMIN_CODE_LIST.HYDRATE: return adminCodeListHydrate(state);
        case ADMIN_CODE_LIST.HYDRATE_SUCCESS: return adminCodeListHydrateSuccess(state, action.payload);
        case ADMIN_CODE_LIST.HYDRATE_FAILURE: return adminCodeListHydrateFailure(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_CODE_INDEX: return adminCodeListSelectCodeIndex(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_CODE_INDEX_SUCCESS: return adminCodeListSelectCodeIndexSuccess(state);
        case ADMIN_CODE_LIST.SELECT_CODE_INDEX_FAILURE: return adminCodeListSelectCodeIndexFailure(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_FIRSTNAME_INDEX: return adminCodeListSelectFirstnameIndex(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_EXTRA_INDEX: return adminCodeListSelectExtraIndex(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_LASTNAME_INDEX: return adminCodeListSelectLastnameIndex(state, action.payload);
        case ADMIN_CODE_LIST.ENABLE_UPDATE: return adminCodeListEnableUpdate(state, action.payload);
        case ADMIN_CODE_LIST.ENABLE_DELETE: return adminCodeListEnableDelete(state, action.payload);
        case ADMIN_CODE_LIST.ENABLE_ADD: return adminCodeListEnableAdd(state, action.payload);
        case ADMIN_CODE_LIST.ENABLE_IGNORE_HEADER: return adminCodeListEnableIgnoreHeader(state, action.payload);
        case ADMIN_CODE_LIST.CANCEL_IMPORT: return adminCodeListCancelImport(state);
        case ADMIN_CODE_LIST.IMPORT_DATA_SUCCESS: return adminCodeListImportDataSuccess(state);
        case ADMIN_CODE_LIST.IMPORT_DATA_FAILURE: return adminCodeListImportDataFailure(state, action.payload);
        case ADMIN_CODE_LIST.IMPORT_DATA: return adminCodeListImportData(state, action.payload);
        case ADMIN_CODE_LIST.DELETE: return adminCodeListDelete(state);
        case ADMIN_CODE_LIST.DELETE_SUCCESS: return adminCodeListDeleteSuccess(state);
        case ADMIN_CODE_LIST.DELETE_FAILURE: return adminCodeListDeleteFailure(state, action.payload);
        case ADMIN_CODE_LIST.CLICK_ADD: return adminCodeListClickAdd(state);
        case ADMIN_CODE_LIST.CLICK_IMPORT: return adminCodeListClickImport(state);
        case ADMIN_CODE_LIST.CLICK_EDIT: return adminCodeListClickEdit(state);
        case ADMIN_CODE_LIST.CLICK_DELETE: return adminCodeListClickDelete(state);
        case ADMIN_CODE_LIST.SELECT: return adminCodeListSelect(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_ORGANIZATION_GROUP: return adminCodeListSelectOrganizationGroup(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_ORGANIZATION: return adminCodeListSelectOrganization(state, action.payload);
        case ADMIN_CODE_LIST.CLICK_ORGANIZATION_BACKLINK: return adminCodeListClickOrganizationBacklink(state);
        case ADMIN_CODE_LIST.CLICK_GROUP_BACKLINK: return adminCodeListClickGroupBacklink(state);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_CODE_IMPORT.READ_FILE_SUCCESS: return adminCodeImportReadFileSuccess(state, action.payload);
        case ADMIN_CODE_FORM.SELECT_ORGANIZATION_CODE: return adminCodeFormSelectOrganizationCode(state, action.payload);
        case ADMIN_CODE_FORM.SELECT_GROUP_CODE: return adminCodeFormSelectGroupCode(state, action.payload);
        case ADMIN_CODE_FORM.UPDATE_SUCCESS: return adminCodeFormUpdateSuccess(state);
        case ADMIN_CODE_FORM.ADD_SUCCESS: return adminCodeFormAddSuccess(state);
        default: return state;
    }
}
export function adminCodeListInit(state: State): State {
    return {
        ...initialState,
        data: state.data,
        selectedOrganizationCode: state.selectedOrganizationCode,
        selectedGroupCode: state.selectedGroupCode,
        isActive: true,
    };
}
export function adminCodeListDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function adminCodeListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminCodeListHydrateSuccess(state: State, payload: AccessCodeInfo[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload,
    };
}
export function adminCodeListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminCodeListSelectCodeIndex(state: State, payload: number): State {
    return { ...state, selectedCodeIndex: payload };
}
export function adminCodeListSelectCodeIndexSuccess(state: State): State {
    return {
        ...state,
        hasError: false,
        message: null
    };
}
export function adminCodeListSelectCodeIndexFailure(state: State, payload: any): State {
    return { ...state, selectedCodeIndex: null, hasError: true, message: payload };
}
export function adminCodeListSelectFirstnameIndex(state: State, payload: number): State {
    return { ...state, selectedFirstnameIndex: payload };
}
export function adminCodeListSelectExtraIndex(state: State, payload: any): State {
    const extraIndex = typeof state.extraIndex === 'object' ? state.extraIndex : {};
    return { ...state, extraIndex: { ...extraIndex, ...payload } };
}
export function adminCodeListSelectLastnameIndex(state: State, payload: number): State {
    return { ...state, selectedLastnameIndex: payload };
}
export function adminCodeListEnableUpdate(state: State, payload: boolean): State {
    return { ...state, canUpdate: payload };
}
export function adminCodeListEnableDelete(state: State, payload: boolean): State {
    return { ...state, canDelete: payload };
}
export function adminCodeListEnableAdd(state: State, payload: boolean): State {
    return { ...state, canAdd: payload };
}
export function adminCodeListEnableIgnoreHeader(state: State, payload: boolean): State {
    return { ...state, ignoreHeader: payload };
}
export function adminCodeListCancelImport(state: State): State {
    return {
        ...state,
        hasImport: false,
        importData: [],
        ignoreHeader: initialState.ignoreHeader,
        canAdd: initialState.canAdd,
        canDelete: initialState.canDelete,
        canUpdate: initialState.canUpdate,
        selectedCodeIndex: initialState.selectedCodeIndex,
        selectedFirstnameIndex: initialState.selectedFirstnameIndex,
        selectedLastnameIndex: initialState.selectedLastnameIndex,
    };
}
export function adminCodeListImportDataSuccess(state: State): State {
    return {
        ...state,
        hasImport: false,
        hasError: false,
        message: null,
        ignoreHeader: initialState.ignoreHeader,
        canAdd: initialState.canAdd,
        canDelete: initialState.canDelete,
        canUpdate: initialState.canUpdate,
        selectedCodeIndex: initialState.selectedCodeIndex,
        selectedFirstnameIndex: initialState.selectedFirstnameIndex,
        selectedLastnameIndex: initialState.selectedLastnameIndex,
    };
}
export function adminCodeListImportDataFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload
    };
}
export function adminCodeListImportData(state: State, payload: AccessCodeInfo[]): State {
    return { ...state, ...payload };
}
export function adminCodeListDelete(state: State): State {
    return {
        ...state,
        message: null,
        hasError: false,
    };
}
export function adminCodeListDeleteSuccess(state: State): State {
    return {
        ...state,
        selecteAccessCode: initialState.selecteAccessCode,
    };
}
export function adminCodeListDeleteFailure(state: State, payload: any): State {
    return { ...state, hasError: true, message: payload };
}
export function adminCodeListClickAdd(state: State): State {
    return state;
}
export function adminCodeListClickImport(state: State): State {
    return state;
}
export function adminCodeListClickEdit(state: State): State {
    return state;
}
export function adminCodeListClickDelete(state: State): State {
    return state;
}
export function adminCodeListSelect(state: State, payload: AccessCodeInfo): State {
    return {
        ...state,
        selecteAccessCode: payload,
    };
}
export function adminCodeListSelectOrganizationGroup(state: State, payload: string): State {
    return { ...state, selectedGroupCode: payload };
}
export function adminCodeListSelectOrganization(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload, selectedGroupCode: '' };
}
export function adminCodeListClickOrganizationBacklink(state: State): State {
    return state;
}
export function adminCodeListClickGroupBacklink(state: State): State {
    return state;
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return { ...state, selectedOrganizationCode: payload.organizationCode };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return { ...state, selectedGroupCode: payload.groupCode };
}
export function adminCodeImportReadFileSuccess(state: State, payload: []): State {
    return {
        ...state,
        hasImport: true,
        importData: payload
    };
}
export function adminCodeFormSelectOrganizationCode(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload, selectedGroupCode: '' };
}
export function adminCodeFormSelectGroupCode(state: State, payload: string): State {
    return { ...state, selectedGroupCode: payload };
}
export function adminCodeFormUpdateSuccess(state: State): State {
    return {
        ...state,
        selecteAccessCode: initialState.selecteAccessCode,
    };
}
export function adminCodeFormAddSuccess(state: State): State {
    return {
        ...state,
        selecteAccessCode: initialState.selecteAccessCode,
    };
}
