import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { PPA_ADD } from '@client/actions';
import { PpaAddSubmitFailureAction, PpaAddSubmitSuccessAction } from '@client/actions/ppa-add-actions';
import { Action } from '@client/lib/action';
import { getContractDataSubscriptionSpaqContractNo, getPpaAddForm } from '@client/selectors';
import { PaymentPPAInfo } from '@client/utils/shared-constants';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class PpaEffects {
  @Effect({ dispatch: true })
  submit$: Observable<Action> = this.actions$.pipe(
    ofType(PPA_ADD.SUBMIT),
    withLatestFrom(this.store.pipe(select(getPpaAddForm)), this.store.pipe(select(getContractDataSubscriptionSpaqContractNo))),
    switchMap(([a, ppa, contractNo]: [any, PaymentPPAInfo, any]) => {
      const test = this.fns.httpsCallable('addPpa');
      return test({
        ...ppa,
        financialInstitutionId: ppa.financialInstitutionId,
        contractNo
      }).pipe(
        map(() => new PpaAddSubmitSuccessAction()),
        catchError(e => of(new PpaAddSubmitFailureAction(e)))
      );
    })
  );

  constructor(private actions$: Actions, private store: Store<any>, private fns: AngularFireFunctions) {}
}
