import { Injectable } from '@angular/core';
import { ACCESS_CODE, ACCOUNT_STATUS, CONTRACT, REGISTER_ACCESS_CODE, REGISTER_CONTRACT, SESSION } from '@client/actions';
import { AccountStatusHydrateAction, AccountStatusHydrateFailureAction, AccountStatusHydrateSuccessAction } from '@client/actions/account-status-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { AccountStatusService } from '@client/core/services/account-status.service';
import { getRegisterAccessCodeIsActive, getRegisterContractIsActive } from '@client/selectors';
import { AccountStatusState } from '@client/utils/shared-constants';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { EMPTY, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../configs/environment';
import { Action } from '../../lib/action';

@Injectable()
export class AccountStatusEffects {
  @Effect()
  accountStatusHydrate: Observable<Action> = this.actions$.pipe(
    ofType<AccountStatusHydrateAction>(ACCOUNT_STATUS.HYDRATE),
    switchMap(action =>
      this.accountStatusService.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map((data: AccountStatusState) => new AccountStatusHydrateSuccessAction(data, action.correlationId)),
        catchError((e: any) => of(new AccountStatusHydrateFailureAction(e, action.correlationId)))
      )
    )
  );
  @Effect()
  accountStatusHydrate$: Observable<Action> = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.accountStatus, of(new AccountStatusHydrateAction(null, action.correlationId)), EMPTY))
  );
  @Effect({ dispatch: false })
  updateAccessCodeStatus = this.actions$.pipe(
    ofType(ACCESS_CODE.ADD_SUCCESS, REGISTER_ACCESS_CODE.CLICK_IGNORE),
    withLatestFrom(this.store.pipe(select(getRegisterAccessCodeIsActive))),
    switchMap(([action, isRegistering]) => (!isRegistering ? EMPTY : this.accountStatusService.update({ isAccessCodeRequired: false })))
  );

  @Effect({ dispatch: false })
  updateContractStatus = this.actions$.pipe(
    ofType(REGISTER_CONTRACT.CLICK_IGNORE, CONTRACT.SEARCH_SUCCESS),
    withLatestFrom(this.store.pipe(select(getRegisterContractIsActive))),
    switchMap(([action, isRegistering]) => (!isRegistering ? EMPTY : this.accountStatusService.update({ isContractRequired: false })))
  );

  constructor(private actions$: Actions, private store: Store<any>, private accountStatusService: AccountStatusService) {}
}
