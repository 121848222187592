import { State as ResetPasswordState } from "@client/reducers/reset-password-reducer";
import { Selector } from "@ngrx/store";
import { ResetPasswordForm } from "@client/utils/shared-constants";
export const getMessage: Selector<ResetPasswordState, string | null> = (state: ResetPasswordState) => state.message;
export const getHasError: Selector<ResetPasswordState, boolean> = (state: ResetPasswordState) => state.hasError;
export const getHasSuccess: Selector<ResetPasswordState, boolean> = (state: ResetPasswordState) => state.hasSuccess;
export const getIsActive: Selector<ResetPasswordState, boolean> = (state: ResetPasswordState) => state.isActive;
export const getIsLoading: Selector<ResetPasswordState, boolean> = (state: ResetPasswordState) => state.isLoading;
export const getIsCompleted: Selector<ResetPasswordState, boolean> = (state: ResetPasswordState) => state.isCompleted;
export const getForm: Selector<ResetPasswordState, ResetPasswordForm> = (state: ResetPasswordState) => state.form;
export const getFormConfirmPassword: Selector<ResetPasswordState, string> = (state: ResetPasswordState) => getForm(state) && getForm(state).confirm_password;
export const getFormPassword: Selector<ResetPasswordState, string> = (state: ResetPasswordState) => getForm(state) && getForm(state).password;
