import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { ServiceInfo, ServiceMetaInfo } from '@client/utils/shared-constants';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs/index';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class ServicesService {
  readonly endPoint = '/services';
  private url = environment.backendApiUrl + '/spaq';

  constructor(private db: AngularFireDatabase, private store: Store<any>, private http: HttpClient) {}

  deleteService({ availableServiceId }: ServiceInfo) {
    return this.db.object(`${this.endPoint}/${availableServiceId}`).remove();
  }

  getAll() {
    return this.db.list(this.endPoint).valueChanges();
  }

  getService(id: number) {
    return this.db.object(`${this.endPoint}/${id}`).valueChanges();
  }

  getServicesByContractNo(): Observable<any> {
    return this.http.get<any>(`${this.url}/services`);
  }

  hydrate() {
    return combineLatest(this.db.list<ServiceInfo>(this.endPoint).valueChanges(), this.db.object<{ [key: string]: ServiceMetaInfo }>('/services-meta').valueChanges()).pipe(
      mergeMap(([services, meta]) =>
        from(services).pipe(
          map((s: ServiceInfo) => ({ ...s, meta: (meta && meta[s.availableServiceId]) || null })),
          toArray()
        )
      )
    );
  }

  updateService(service: ServiceInfo) {
    return this.db.object(`${this.endPoint}/${service.availableServiceId}`).update(service);
  }
}
