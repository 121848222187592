import { State as AuthState } from "@client/reducers/auth-reducer";
import { Selector } from "@ngrx/store";
export const getApplyActionCodePending: Selector<AuthState, boolean> = (state: AuthState) => state.applyActionCodePending;
export const getCheckActionCodePending: Selector<AuthState, boolean> = (state: AuthState) => state.checkActionCodePending;
export const getConfirmPasswordResetPending: Selector<AuthState, boolean> = (state: AuthState) => state.confirmPasswordResetPending;
export const getCreateUserWithEmailAndPasswordPending: Selector<AuthState, boolean> = (state: AuthState) => state.createUserWithEmailAndPasswordPending;
export const getGetRedirectResultPending: Selector<AuthState, boolean> = (state: AuthState) => state.getRedirectResultPending;
export const getSendPasswordResetEmailPending: Selector<AuthState, boolean> = (state: AuthState) => state.sendPasswordResetEmailPending;
export const getSendSignInLinkToEmailPending: Selector<AuthState, boolean> = (state: AuthState) => state.sendSignInLinkToEmailPending;
export const getSetPersistencePending: Selector<AuthState, boolean> = (state: AuthState) => state.setPersistencePending;
export const getSignInAndRetrieveDataWithCredentialPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInAndRetrieveDataWithCredentialPending;
export const getSignInAnonymouslyPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInAnonymouslyPending;
export const getSignInWithCustomTokenPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInWithCustomTokenPending;
export const getSignInWithEmailAndPasswordPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInWithEmailAndPasswordPending;
export const getSignInWithEmailLinkPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInWithEmailLinkPending;
export const getSignInWithPhoneNumberPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInWithPhoneNumberPending;
export const getSignInWithPopupPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInWithPopupPending;
export const getSignInWithRedirectPending: Selector<AuthState, boolean> = (state: AuthState) => state.signInWithRedirectPending;
export const getUpdateCurrentUserPending: Selector<AuthState, boolean> = (state: AuthState) => state.updateCurrentUserPending;
export const getVerifyPasswordResetCodePending: Selector<AuthState, boolean> = (state: AuthState) => state.verifyPasswordResetCodePending;
export const getSignOutPending: Selector<AuthState, boolean> = (state: AuthState) => state.signOutPending;
