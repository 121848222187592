import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonSpinComponent} from '@client/shared/components/button-spin/button-spin.component';
import {ContentLoaderModule} from '@client/shared/components/content-loader';
import {LayoutSubNavComponent} from '@client/shared/components/layout/layout-sub-nav';
import {MessageStateComponent} from '@client/shared/components/message-state.component';
import {SpinnerStateComponent} from '@client/shared/components/spinner-state/spinner-state.component';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {ButtonComponent} from '@client/shared/components/button';
import {ErrorComponent} from '@client/shared/components/error';
import {FormComponent} from '@client/shared/components/form';
import {LinkChoiceComponent} from '@client/shared/components/link-choice';
import {NoResultComponent} from '@client/shared/components/no-result';
import {SubmitCaptchaComponent} from '@client/shared/components/submit-captcha';
import {SubmitComponent} from '@client/shared/components/submit';
import {SuccessComponent} from '@client/shared/components/success';
import {WarningComponent} from '@client/shared/components/warning';
import {MarkdownComponent} from '@client/shared/components/markdown/markdown.component';
import {NgxMdModule} from "ngx-md";

@NgModule({
  declarations: [
    FormComponent,
    SubmitComponent,
    ButtonComponent,
    SubmitCaptchaComponent,
    ErrorComponent,
    LinkChoiceComponent,
    SuccessComponent,
    WarningComponent,
    NoResultComponent,
    MessageStateComponent,
    SpinnerStateComponent,
    FormComponent,
    ButtonSpinComponent,
    FormComponent,
    LayoutSubNavComponent,
    MarkdownComponent
  ],
  exports: [
    FormComponent,
    SubmitComponent,
    ButtonComponent,
    SubmitCaptchaComponent,
    ErrorComponent,
    LinkChoiceComponent,
    SuccessComponent,
    WarningComponent,
    NoResultComponent,
    MessageStateComponent,
    SpinnerStateComponent,
    FormComponent,
    ButtonSpinComponent,
    FormComponent,
    LayoutSubNavComponent,
    MarkdownComponent
  ],
  imports: [TranslateModule, CommonModule, FormsModule, ReactiveFormsModule, ButtonsModule, ContentLoaderModule, NgxMdModule],
  providers: []
})
export class SharedComponentsModule {}
