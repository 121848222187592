import { State as SectorsState } from "@client/reducers/sectors-reducer";
import { Selector } from "@ngrx/store";
import { SectorInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<SectorsState, string | null> = (state: SectorsState) => state.message;
export const getPending: Selector<SectorsState, boolean> = (state: SectorsState) => state.pending;
export const getIsServiceRunning: Selector<SectorsState, boolean> = (state: SectorsState) => state.isServiceRunning;
export const getLoading: Selector<SectorsState, boolean> = (state: SectorsState) => state.loading;
export const getHasError: Selector<SectorsState, boolean> = (state: SectorsState) => state.hasError;
export const getHasSuccess: Selector<SectorsState, boolean> = (state: SectorsState) => state.hasSuccess;
export const getIsHydrated: Selector<SectorsState, boolean> = (state: SectorsState) => state.isHydrated;
export const getCollection: Selector<SectorsState, SectorInfo[]> = (state: SectorsState) => state.collection;
