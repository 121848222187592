import { PROFILE_DETAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
}
export const initialState: State = {
    isActive: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PROFILE_DETAILS.DESTROY: return profileDetailsDestroy(state);
        case PROFILE_DETAILS.INIT: return profileDetailsInit(state);
        default: return state;
    }
}
export function profileDetailsDestroy(state: State): State {
    return {
        isActive: false
    };
}
export function profileDetailsInit(state: State): State {
    return {
        isActive: true
    };
}
