import { PPA_ADD } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule, ServiceInfo, AccessCodeGroupInfo, SystemConfiguration, MonerisConfiguration, TestGroupInfo, TestInfo, AccessCodeServiceInfo, AccessCodeServiceCustomFields, ServiceMetaInfo, AccessCodeServiceCustomFieldsValue, FaqGroupInfo, PaymentPPAInfo } from "@client/utils/shared-constants";

export class PpaAddInitAction implements Action {
    readonly type = PPA_ADD.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class PpaAddDestroyAction implements Action {
    readonly type = PPA_ADD.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class PpaAddSubmitAction implements Action {
    readonly type = PPA_ADD.SUBMIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class PpaAddSubmitSuccessAction implements Action {
    readonly type = PPA_ADD.SUBMIT_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class PpaAddSubmitFailureAction implements Action {
    readonly type = PPA_ADD.SUBMIT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class PpaAddFormUpdateAction implements Action {
    readonly type = PPA_ADD.FORM_UPDATE;
    constructor(public payload: PaymentPPAInfo, public correlationId?: number) {
    }
}
