import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONTRACT_DETAILS, SETTINGS } from '@client/actions';
import { SettingsClickAction } from '@client/actions/settings-actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SettingsEffects {
  @Effect({ dispatch: false })
  click$ = this.actions$.pipe(
    ofType(SETTINGS.CLICK),
    map((action: SettingsClickAction) => action.payload),
    tap(a => {
      if (a.id === 'password') {
        this.router.navigate(['/account/settings/password/edit']);
      }
      if (a.id === 'subscription') {
        this.router.navigate(['/account/settings/subscription/details']);
      }
      if (a.id === 'profile') {
        this.router.navigate(['/account/settings/profil/details']);
      }
      if (a.id === 'access_code') {
        this.router.navigate(['/account/access-code/list']);
      }
      if (a.id === 'vehicles') {
        this.router.navigate(['/account/settings/vehicles/list']);
      }
      if (a.id === 'email') {
        this.router.navigate(['/account/settings/email/edit']);
      }
      if (a.id === 'ppa') {
        this.router.navigate(['/account/settings/ppa/edit']);
      }
      if (a.id === 'cc') {
        this.router.navigate(['/account/settings/ppa/edit']);
      }
    })
  );

  @Effect({ dispatch: false })
  clickPpaUpdate$ = this.actions$.pipe(
    ofType(CONTRACT_DETAILS.CLICK_PPA_UPDATE),
    map((action: any) => action.payload),
    tap(a => {
      this.router.navigate(['/account/settings/ppa/edit']);
    })
  );

  constructor(private actions$: Actions, private router: Router) {}
}
