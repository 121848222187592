import { REGISTER_CONTRACT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ContractForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isComplete: boolean;
    isPending: boolean;
    form: ContractForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isComplete: false,
    isPending: false,
    form: {
        contractNo: "",
        phone: "",
        postalCode: "",
      method: "contract"
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case REGISTER_CONTRACT.INIT: return registerContractInit(state);
        case REGISTER_CONTRACT.DESTROY: return registerContractDestroy(state);
        case REGISTER_CONTRACT.CLICK_SUBMIT: return registerContractClickSubmit(state);
        case REGISTER_CONTRACT.CLICK_IGNORE: return registerContractClickIgnore(state);
        case REGISTER_CONTRACT.IGNORE_STEP: return registerContractIgnoreStep(state);
        case REGISTER_CONTRACT.IGNORE_STEP_SUCCESS: return registerContractIgnoreStepSuccess(state);
        case REGISTER_CONTRACT.IGNORE_STEP_FAILURE: return registerContractIgnoreStepFailure(state, action.payload);
        case REGISTER_CONTRACT.FORM_UPDATE: return registerContractFormUpdate(state, action.payload);
        case REGISTER_CONTRACT.FORM_RESET: return registerContractFormReset(state);
        default: return state;
    }
}
export function registerContractInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function registerContractDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function registerContractClickSubmit(state: State): State {
    return state;
}
export function registerContractClickIgnore(state: State): State {
    return state;
}
export function registerContractIgnoreStep(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isComplete: false,
        isPending: false,
    };
}
export function registerContractIgnoreStepSuccess(state: State): State {
    return {
        ...state,
        hasSuccess: true,
        isComplete: true,
        isPending: false,
    };
}
export function registerContractIgnoreStepFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isPending: false,
    };
}
export function registerContractFormUpdate(state: State, payload: ContractForm): State {
    return {
        ...state,
        form: payload,
    };
}
export function registerContractFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form,
    };
}
