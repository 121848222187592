import { SESSION, PROFILE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ProfileInfo, ProfileWritableEntities, Session } from "@client/utils/shared-constants";
export interface State {
    isHydrated: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isPending: boolean;
    data: ProfileInfo | null;
}
export const initialState: State = {
    isHydrated: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: true,
    isPending: false,
    data: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PROFILE.DESTROY: return profileDestroy(state);
        case PROFILE.DESTROY_EDIT: return profileDestroyEdit(state);
        case PROFILE.GO_TO: return profileGoTo(state);
        case PROFILE.HYDRATE: return profileHydrate(state);
        case PROFILE.HYDRATE_SUCCESS: return profileHydrateSuccess(state, action.payload);
        case PROFILE.HYDRATE_FAILURE: return profileHydrateFailure(state, action.payload);
        case PROFILE.INIT: return profileInit(state);
        case PROFILE.INIT_EDIT: return profileInitEdit(state);
        case PROFILE.SUBMIT_FORM_EDIT: return profileSubmitFormEdit(state, action.payload);
        case PROFILE.SUBMIT_FORM_EDIT_FAILURE: return profileSubmitFormEditFailure(state, action.payload);
        case PROFILE.UPDATE_FAILURE: return profileUpdateFailure(state, action.payload);
        case PROFILE.UPDATE_SUCCESS: return profileUpdateSuccess(state, action.payload);
        case PROFILE.UPDATE: return profileUpdate(state, action.payload);
        case PROFILE.UPDATE_FORM_EDIT: return profileUpdateFormEdit(state, action.payload);
        case SESSION.STATE_CHANGE: return sessionStateChange(state, action.payload);
        default: return state;
    }
}
export function profileDestroy(state: State): State {
    return state;
}
export function profileDestroyEdit(state: State): State {
    return initialState;
}
export function profileGoTo(state: State): State {
    return state;
}
export function profileHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function profileHydrateSuccess(state: State, payload: ProfileInfo): State {
    return {
        ...state,
        data: payload,
        isHydrated: true,
        hasSuccess: true,
        isLoading: false
    };
}
export function profileHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        message: payload,
        hasError: true,
        isPending: false,
    };
}
export function profileInit(state: State): State {
    return state;
}
export function profileInitEdit(state: State): State {
    return state;
}
export function profileSubmitFormEdit(state: State, payload: ProfileWritableEntities): State {
    return { ...state };
}
export function profileSubmitFormEditFailure(state: State, payload: ProfileWritableEntities): State {
    return { ...state };
}
export function profileUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        message: payload,
        hasError: true,
        isPending: false,
    };
}
export function profileUpdateSuccess(state: State, payload: ProfileWritableEntities): State {
    return {
        ...state,
        hasSuccess: true,
        isPending: false,
    };
}
export function profileUpdate(state: State, payload: ProfileWritableEntities): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function profileUpdateFormEdit(state: State, payload: ProfileWritableEntities): State {
    return { ...state };
}
export function sessionStateChange(state: State, payload: Session): State {
    return {
        ...state,
        isHydrated: true,
        hasSuccess: true,
        isLoading: false,
        data: {
            ...payload
        }
    };
}
