import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr-CA';
import { InjectionToken, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import {AngularFireModule, FIREBASE_APP_NAME, FIREBASE_OPTIONS} from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule, URL } from '@angular/fire/database';

import {AngularFireFunctionsModule, ORIGIN} from '@angular/fire/functions';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@progress/kendo-angular-intl/locales/fr-CA/all';
import { load } from '@telerik/kendo-intl';
import * as calendar from 'cldr-data/main/fr-CA/ca-gregorian.json';
import * as currencies from 'cldr-data/main/fr-CA/currencies.json';
import * as dateFields from 'cldr-data/main/fr-CA/dateFields.json';
import * as numbers from 'cldr-data/main/fr-CA/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr-CA/timeZoneNames.json';
import * as currencyData from 'cldr-data/supplemental/currencyData.json';
import * as likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { DispatchersModule } from '../../dispatchers.module';
import {environment} from "@client/configs/environment";

load(likelySubtags, weekData, currencyData, numbers, currencies, calendar, dateFields, timeZoneNames);

export const FIREBASE_CONFIG = new InjectionToken('FIREBASE_CONFIG');
export const LOCALE_CONFIG = new InjectionToken('LOCALE_CONFIG');

// @dynamic
@NgModule({
  imports: [DispatchersModule, BrowserAnimationsModule, BrowserModule, HttpClientModule, CommonModule, AngularFireDatabaseModule, AngularFireAuthModule, AngularFireFunctionsModule],
  declarations: [],
  providers: [],
  exports: [DispatchersModule]
})
export class PlanctonModule {
  static forRoot(config: any): ModuleWithProviders<PlanctonModule> {
    return {
      ngModule: PlanctonModule,
      providers: [
        { provide: FIREBASE_OPTIONS, useValue: config.firebase.config },
//        { provide: FIREBASE_APP_NAME, useValue: '[DEFAULT]' },

//        { provide: ORIGIN, useValue: 'http://localhost:5001'},
//        { provide: URL, useValue: 'http://localhost:9000/?ns=spaq-mobile-stage'},
        { provide: LOCALE_ID, useValue: config.locale }
        //         {provide: ErrorHandler, useClass: RavenErrorHandler},
      ]
    };
  }

  constructor() {
    registerLocaleData(localeFr, 'fr-CA');
  }
}

