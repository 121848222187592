import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccessCodeExistsGuard,
  AccessCodeNotFoundExistsGuard,
  HasCodeGuard,
  HasForgotPasswordGuard,
  HasResetCodeGuard,
  IfAuthorizedGuard,
  IfNotLoggedInGuard,
  IfRegistrationCodeSuccessGuard,
  IfRegistrationContractSuccessGuard
} from '@client/core/guards';
import { HasAccessCodeGuard } from '@client/core/guards/has-access-code.guard';
import { HasContractNotPaidGuard } from '@client/core/guards/has-contract-not-paid.guard';
import { HasContractPaidGuard } from '@client/core/guards/has-contract-paid.guard';
import { HasContractGuard } from '@client/core/guards/has-contract.guard';
import { HasNoContractGuard } from '@client/core/guards/has-no-contract.guard';
import { HasNoSubscriptionGuard } from '@client/core/guards/has-no-subscription.guard';
import { HasRemainingStepsGuard } from '@client/core/guards/has-remaining-steps.guard';
import { HasSubscriptionGuard } from '@client/core/guards/has-subscription.guard';
import { IsAdminGuard } from '@client/core/guards/is-admin.guard';
import { IsEmailVerifiedGuard } from '@client/core/guards/is-email-verified.guard';
import { IsLoggedInGuard } from '@client/core/guards/is-logged-in.guard';
import { IsNotLoggedInGuard } from '@client/core/guards/is-not-logged-in.guard';
import { IsPublicGuard } from '@client/core/guards/is-public.guard';
import { IsVerifiedGuard } from '@client/core/guards/is-verified.guard';
import { LoginEmailGuard } from '@client/core/guards/login-email.guard';
import { RegistrationCompletedGuard } from '@client/core/guards/registration-completed.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    HasRemainingStepsGuard,
    HasNoSubscriptionGuard,
    HasContractGuard,
    HasNoContractGuard,
    HasContractNotPaidGuard,
    HasContractPaidGuard,
    LoginEmailGuard,
    IsVerifiedGuard,
    IsNotLoggedInGuard,
    IsPublicGuard,
    IsLoggedInGuard,
    IsAdminGuard,
    IsEmailVerifiedGuard,
    RegistrationCompletedGuard,
    HasSubscriptionGuard,
    HasAccessCodeGuard,
    IfAuthorizedGuard,
    AccessCodeExistsGuard,
    AccessCodeNotFoundExistsGuard,
    HasForgotPasswordGuard,
    HasResetCodeGuard,
    HasCodeGuard,
    IfRegistrationCodeSuccessGuard,
    IfNotLoggedInGuard,
    IfRegistrationContractSuccessGuard
  ]
})
export class CoreGuardModule {}
