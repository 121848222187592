import { State as AdminMetasFormState } from "@client/reducers/admin-metas-form-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeServiceCustomFields, AccessCodeServiceCustomFieldsValue } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminMetasFormState, boolean> = (state: AdminMetasFormState) => state.isActive;
export const getMessage: Selector<AdminMetasFormState, string | null> = (state: AdminMetasFormState) => state.message;
export const getHasError: Selector<AdminMetasFormState, boolean> = (state: AdminMetasFormState) => state.hasError;
export const getHasSuccess: Selector<AdminMetasFormState, boolean> = (state: AdminMetasFormState) => state.hasSuccess;
export const getIsLoading: Selector<AdminMetasFormState, boolean> = (state: AdminMetasFormState) => state.isLoading;
export const getIsEdit: Selector<AdminMetasFormState, boolean> = (state: AdminMetasFormState) => state.isEdit;
export const getImportData: Selector<AdminMetasFormState, any> = (state: AdminMetasFormState) => state.importData;
export const getForm: Selector<AdminMetasFormState, AccessCodeServiceCustomFields> = (state: AdminMetasFormState) => state.form;
export const getFormLabel: Selector<AdminMetasFormState, string> = (state: AdminMetasFormState) => getForm(state) && getForm(state).label;
export const getFormName: Selector<AdminMetasFormState, string> = (state: AdminMetasFormState) => getForm(state) && getForm(state).name;
export const getFormType: Selector<AdminMetasFormState, string> = (state: AdminMetasFormState) => getForm(state) && getForm(state).type;
export const getFormValues: Selector<AdminMetasFormState, AccessCodeServiceCustomFieldsValue[]> = (state: AdminMetasFormState) => getForm(state) && getForm(state).values;
export const getFormRequired: Selector<AdminMetasFormState, boolean> = (state: AdminMetasFormState) => getForm(state) && getForm(state).required;
export const getFormOrganizationCode: Selector<AdminMetasFormState, string> = (state: AdminMetasFormState) => getForm(state) && getForm(state).organizationCode;
export const getFormGroupCode: Selector<AdminMetasFormState, string> = (state: AdminMetasFormState) => getForm(state) && getForm(state).groupCode;
export const getFormOrder: Selector<AdminMetasFormState, number> = (state: AdminMetasFormState) => getForm(state) && getForm(state).order;
export const getSelectedOption: Selector<AdminMetasFormState, number | null> = (state: AdminMetasFormState) => state.selectedOption;
