import { NETWORK_STATUS } from "@client/actions";
import { Action } from "@client/lib/action";

export class NetworkStatusOnlineAction implements Action {
    readonly type = NETWORK_STATUS.ONLINE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class NetworkStatusOfflineAction implements Action {
    readonly type = NETWORK_STATUS.OFFLINE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
