import { State as AdminContractListState } from "@client/reducers/admin-contract-list-reducer";
import { Selector } from "@ngrx/store";
export const getIsActive: Selector<AdminContractListState, boolean> = (state: AdminContractListState) => state.isActive;
export const getMessage: Selector<AdminContractListState, string | null> = (state: AdminContractListState) => state.message;
export const getHasError: Selector<AdminContractListState, boolean> = (state: AdminContractListState) => state.hasError;
export const getHasSuccess: Selector<AdminContractListState, boolean> = (state: AdminContractListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminContractListState, boolean> = (state: AdminContractListState) => state.isLoading;
export const getSelectedContract: Selector<AdminContractListState, number | null> = (state: AdminContractListState) => state.selectedContract;
export const getData: Selector<AdminContractListState, any> = (state: AdminContractListState) => state.data;
export const getOnSync: Selector<AdminContractListState, boolean> = (state: AdminContractListState) => state.onSync;
