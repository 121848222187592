import { State as TestGroupFormState } from "@client/reducers/test-group-form-reducer";
import { Selector } from "@ngrx/store";
import { TestGroupInfo, TestInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<TestGroupFormState, boolean> = (state: TestGroupFormState) => state.isActive;
export const getMessage: Selector<TestGroupFormState, string | null> = (state: TestGroupFormState) => state.message;
export const getHasError: Selector<TestGroupFormState, boolean> = (state: TestGroupFormState) => state.hasError;
export const getHasSuccess: Selector<TestGroupFormState, boolean> = (state: TestGroupFormState) => state.hasSuccess;
export const getIsLoading: Selector<TestGroupFormState, boolean> = (state: TestGroupFormState) => state.isLoading;
export const getIsEdit: Selector<TestGroupFormState, boolean> = (state: TestGroupFormState) => state.isEdit;
export const getForm: Selector<TestGroupFormState, TestGroupInfo> = (state: TestGroupFormState) => state.form;
export const getFormId: Selector<TestGroupFormState, string> = (state: TestGroupFormState) => getForm(state) && getForm(state).id;
export const getFormName: Selector<TestGroupFormState, string> = (state: TestGroupFormState) => getForm(state) && getForm(state).name;
export const getFormTests: Selector<TestGroupFormState, any> = (state: TestGroupFormState) => getForm(state) && getForm(state).tests;
