import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_SERVICE_FORM, ADMIN_SERVICE_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeServiceInfo, OrganizationInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: AccessCodeServiceInfo[];
    selecteService: AccessCodeServiceInfo | null;
    selectedOrganizationCode: string;
    selectedGroupCode: string;
    showOrganizationBacklink: boolean;
    showGroupBacklink: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    selecteService: null,
    selectedOrganizationCode: "",
    selectedGroupCode: "",
    showOrganizationBacklink: false,
    showGroupBacklink: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_SERVICE_LIST.INIT: return adminServiceListInit(state);
        case ADMIN_SERVICE_LIST.DESTROY: return adminServiceListDestroy(state);
        case ADMIN_SERVICE_LIST.HYDRATE: return adminServiceListHydrate(state);
        case ADMIN_SERVICE_LIST.HYDRATE_SUCCESS: return adminServiceListHydrateSuccess(state, action.payload);
        case ADMIN_SERVICE_LIST.HYDRATE_FAILURE: return adminServiceListHydrateFailure(state, action.payload);
        case ADMIN_SERVICE_LIST.DELETE: return adminServiceListDelete(state);
        case ADMIN_SERVICE_LIST.DELETE_SUCCESS: return adminServiceListDeleteSuccess(state);
        case ADMIN_SERVICE_LIST.DELETE_FAILURE: return adminServiceListDeleteFailure(state, action.payload);
        case ADMIN_SERVICE_LIST.CLICK_ADD: return adminServiceListClickAdd(state);
        case ADMIN_SERVICE_LIST.CLICK_EDIT: return adminServiceListClickEdit(state);
        case ADMIN_SERVICE_LIST.CLICK_DELETE: return adminServiceListClickDelete(state);
        case ADMIN_SERVICE_LIST.SELECT: return adminServiceListSelect(state, action.payload);
        case ADMIN_SERVICE_LIST.SELECT_ORGANIZATION_GROUP: return adminServiceListSelectOrganizationGroup(state, action.payload);
        case ADMIN_SERVICE_LIST.SELECT_ORGANIZATION: return adminServiceListSelectOrganization(state, action.payload);
        case ADMIN_SERVICE_LIST.CLICK_ORGANIZATION_BACKLINK: return adminServiceListClickOrganizationBacklink(state);
        case ADMIN_SERVICE_LIST.CLICK_GROUP_BACKLINK: return adminServiceListClickGroupBacklink(state);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_SERVICE_FORM.SELECT_ORGANIZATION_CODE: return adminServiceFormSelectOrganizationCode(state, action.payload);
        case ADMIN_SERVICE_FORM.SELECT_GROUP_CODE: return adminServiceFormSelectGroupCode(state, action.payload);
        case ADMIN_SERVICE_FORM.UPDATE_SUCCESS: return adminServiceFormUpdateSuccess(state);
        case ADMIN_SERVICE_FORM.ADD_SUCCESS: return adminServiceFormAddSuccess(state);
        default: return state;
    }
}
export function adminServiceListInit(state: State): State {
    return {
        ...initialState,
        data: state.data,
        selectedOrganizationCode: state.selectedOrganizationCode,
        selectedGroupCode: state.selectedGroupCode,
        isActive: true,
    };
}
export function adminServiceListDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function adminServiceListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminServiceListHydrateSuccess(state: State, payload: AccessCodeServiceInfo[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload,
    };
}
export function adminServiceListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminServiceListDelete(state: State): State {
    return {
        ...state,
        message: null,
        hasError: false,
    };
}
export function adminServiceListDeleteSuccess(state: State): State {
    return {
        ...state,
        selecteService: initialState.selecteService,
    };
}
export function adminServiceListDeleteFailure(state: State, payload: any): State {
    return { ...state, hasError: true, message: payload };
}
export function adminServiceListClickAdd(state: State): State {
    return state;
}
export function adminServiceListClickEdit(state: State): State {
    return state;
}
export function adminServiceListClickDelete(state: State): State {
    return state;
}
export function adminServiceListSelect(state: State, payload: AccessCodeServiceInfo): State {
    return {
        ...state,
        selecteService: payload,
    };
}
export function adminServiceListSelectOrganizationGroup(state: State, payload: string): State {
    return { ...state, selectedGroupCode: payload };
}
export function adminServiceListSelectOrganization(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload, selectedGroupCode: '' };
}
export function adminServiceListClickOrganizationBacklink(state: State): State {
    return state;
}
export function adminServiceListClickGroupBacklink(state: State): State {
    return state;
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return { ...state, selectedOrganizationCode: payload.organizationCode };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return { ...state, selectedGroupCode: payload.groupCode };
}
export function adminServiceFormSelectOrganizationCode(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload, selectedGroupCode: '' };
}
export function adminServiceFormSelectGroupCode(state: State, payload: string): State {
    return { ...state, selectedGroupCode: payload };
}
export function adminServiceFormUpdateSuccess(state: State): State {
    return {
        ...state,
        selecteService: initialState.selecteService,
    };
}
export function adminServiceFormAddSuccess(state: State): State {
    return {
        ...state,
        selecteService: initialState.selecteService,
    };
}
