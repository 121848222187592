import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toString'
})
export class ToStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return null;
    }
    return typeof value !== 'string' ? String(value) : value;
  }
}
