import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormModule } from '@client/shared/components/forms';
import { PipesModule } from '@client/shared/pipes.module';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { ServiceDetailComponent, ServiceDetailDebugComponent, ServicesDirectoryComponent, ServicesListComponent, ServicesSelectComponent, ServicesSelectPaymentComponent } from './components';
import {ContractModule} from "@client/modules/contract";
import {ServiceAcceptConditionsComponent} from "@client/modules/services/components/service-accept-conditions.component";
import {UtilsModule} from "@client/modules/utils";

@NgModule({
  imports: [PipesModule, GridModule, DropDownsModule, DatePickerModule, TranslateModule, SharedComponentsModule, FormModule, SharedCommonModule, UtilsModule],

  declarations: [ServicesListComponent, ServiceDetailComponent, ServicesSelectComponent, ServicesDirectoryComponent, ServicesSelectPaymentComponent, ServiceDetailDebugComponent, ServiceAcceptConditionsComponent],
  exports: [ServicesListComponent, ServiceDetailComponent, ServicesSelectComponent, ServicesDirectoryComponent, ServicesSelectPaymentComponent]
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: []
    };
  }
}

