import { REGISTER_ACCESS_CODE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeSearchInfo } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isComplete: boolean;
    isLoading: boolean;
    form: AccessCodeSearchInfo;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isComplete: false,
    isLoading: false,
    form: {
        companyCode: "",
        organization: {
            adresse: "",
            city: "",
            organizationCode: "",
            organizationName: "",
            phoneNumber: "",
            postalCode: "",
            province: "",
            logo: "",
            config: null
        },
        userCode: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case REGISTER_ACCESS_CODE.INIT: return registerAccessCodeInit(state);
        case REGISTER_ACCESS_CODE.DESTROY: return registerAccessCodeDestroy(state);
        case REGISTER_ACCESS_CODE.CLICK_SUBMIT: return registerAccessCodeClickSubmit(state);
        case REGISTER_ACCESS_CODE.CLICK_IGNORE: return registerAccessCodeClickIgnore(state);
        case REGISTER_ACCESS_CODE.FORM_UPDATE: return registerAccessCodeFormUpdate(state, action.payload);
        case REGISTER_ACCESS_CODE.FORM_RESET: return registerAccessCodeFormReset(state);
        default: return state;
    }
}
export function registerAccessCodeInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function registerAccessCodeDestroy(state: State): State {
    return {
        ...initialState,
        isActive: false
    };
}
export function registerAccessCodeClickSubmit(state: State): State {
    return state;
}
export function registerAccessCodeClickIgnore(state: State): State {
    return state;
}
export function registerAccessCodeFormUpdate(state: State, payload: AccessCodeSearchInfo): State {
    return { ...state, form: payload };
}
export function registerAccessCodeFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form
    };
}
