import { REGISTER_SUBSCRIPTION } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState } from "@client/utils/shared-constants";

export class RegisterSubscriptionInitAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSubscriptionDestroyAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSubscriptionClickSubmitAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.CLICK_SUBMIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSubscriptionCreateAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.CREATE;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class RegisterSubscriptionCreateSuccessAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.CREATE_SUCCESS;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class RegisterSubscriptionCreateFailureAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.CREATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterSubscriptionClickLogoutAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.CLICK_LOGOUT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSubscriptionFormUpdateAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.FORM_UPDATE;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class RegisterSubscriptionFormResetAction implements Action {
    readonly type = REGISTER_SUBSCRIPTION.FORM_RESET;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
