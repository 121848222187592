import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import User = firebase.User;
import { from, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string;

  constructor(private afAuth: AngularFireAuth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.afAuth.authState.pipe(
      take(1),
      switchMap((u: User) => {
        let t = of(null);
        if (u) {
          t = from(u.getIdToken(false));
        }
        return t.pipe(
          tap(token => {
            // console.log('Key', token);
          }),
          switchMap(token => {
            const req = request.clone({
              setHeaders: {
                Authorization: `${token}`
              }
            });
            return next.handle(req);
          })
        );
      })
    );
  }
}
