import { TEST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    activePage: string;
}
export const initialState: State = {
    isActive: false,
    activePage: "details"
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case TEST.INIT: return testInit(state);
        case TEST.DESTROY: return testDestroy(state);
        case TEST.SHOW_PAGE: return testShowPage(state, action.payload);
        default: return state;
    }
}
export function testInit(state: State): State {
    return {
        ...state,
    };
}
export function testDestroy(state: State): State {
    return state;
}
export function testShowPage(state: State, payload: string): State {
    return { ...state, activePage: payload };
}
