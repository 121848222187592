import { NgModule } from '@angular/core';
import { AccessCodeModule } from '@client/modules/access-code';
import { ContractModule } from '@client/modules/contract';
import { EmailModule } from '@client/modules/email';
import { FaqModule } from '@client/modules/faq';
import { OrganizationsModule } from '@client/modules/organization';
import { ParksModule } from '@client/modules/parks';
import { PasswordModule } from '@client/modules/password';
import { ProfileModule } from '@client/modules/profile';
import { RegisterModule } from '@client/modules/register';
import { ServicesModule } from '@client/modules/services';
import { SubscriptionModule } from '@client/modules/subscription';
import { UserModule } from '@client/modules/user';
import { UtilsModule } from '@client/modules/utils';
import { VehiclesModule } from '@client/modules/vehicles';

@NgModule({
  imports: [
    SubscriptionModule.forRoot(),
    ParksModule.forRoot(),
    ContractModule.forRoot(),
    EmailModule.forRoot(),
    ServicesModule.forRoot(),
    UserModule.forRoot(),
    OrganizationsModule.forRoot(),
    AccessCodeModule.forRoot(),
    PasswordModule.forRoot(),
    FaqModule.forRoot(),
    VehiclesModule.forRoot(),
    ProfileModule.forRoot(),
    RegisterModule.forRoot(),
    UtilsModule
  ],
  exports: [],
  declarations: []
})
export class FeaturesModule {}
