import { State as SubscriptionEditState } from "@client/reducers/subscription-edit-reducer";
import { Selector } from "@ngrx/store";
import { SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<SubscriptionEditState, string | null> = (state: SubscriptionEditState) => state.message;
export const getHasError: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => state.hasError;
export const getHasSuccess: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => state.hasSuccess;
export const getIsActive: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => state.isActive;
export const getIsComplete: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => state.isComplete;
export const getIsLoading: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => state.isLoading;
export const getForceFormUpdate: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => state.forceFormUpdate;
export const getForm: Selector<SubscriptionEditState, SubscriptionInfo> = (state: SubscriptionEditState) => state.form;
export const getFormCustom: Selector<SubscriptionEditState, any> = (state: SubscriptionEditState) => getForm(state) && getForm(state).custom;
export const getFormSpaq: Selector<SubscriptionEditState, SubscriptionSpaqInfo> = (state: SubscriptionEditState) => getForm(state) && getForm(state).spaq;
export const getFormSpaqAccesDocuments: Selector<SubscriptionEditState, AccesDocumentInfo[]> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).accesDocuments;
export const getFormSpaqAccessOnHoldReason: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).accessOnHoldReason;
export const getFormSpaqAddressLine1: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).addressLine1;
export const getFormSpaqIdentificationCode: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).identificationCode;
export const getFormSpaqAddressLine2: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).addressLine2;
export const getFormSpaqAppartmentNo: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).appartmentNo;
export const getFormSpaqBalance: Selector<SubscriptionEditState, number> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).balance;
export const getFormSpaqCellPhone: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).cellPhone;
export const getFormSpaqCity: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).city;
export const getFormSpaqContractNo: Selector<SubscriptionEditState, number> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).contractNo;
export const getFormSpaqDynamicFieldJsonData: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).dynamicFieldJsonData;
export const getFormSpaqEmail: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).email;
export const getFormSpaqFirstName: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).firstName;
export const getFormSpaqHomePhone: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).homePhone;
export const getFormSpaqHomePhoneExtension: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).homePhoneExtension;
export const getFormSpaqIsAccessOnHold: Selector<SubscriptionEditState, boolean> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).isAccessOnHold;
export const getFormSpaqLanguage: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).language;
export const getFormSpaqLastName: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).lastName;
export const getFormSpaqPostalCode: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).postalCode;
export const getFormSpaqProvince: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).province;
export const getFormSpaqServiceCenter: Selector<SubscriptionEditState, number> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).serviceCenter;
export const getFormSpaqSex: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).sex;
export const getFormSpaqWorkPhone: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).workPhone;
export const getFormSpaqWorkPhoneExtension: Selector<SubscriptionEditState, number> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).workPhoneExtension;
export const getFormSpaqPaymentMethod: Selector<SubscriptionEditState, PaymentMethodInfo> = (state: SubscriptionEditState) => getFormSpaq(state) && getFormSpaq(state).paymentMethod;
export const getFormSpaqPaymentMethodCreditCardDataKey: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).CreditCardDataKey;
export const getFormSpaqPaymentMethodEffectiveDate: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).EffectiveDate;
export const getFormSpaqPaymentMethodEndDate: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).EndDate;
export const getFormSpaqPaymentMethodFinancialInstitutionId: Selector<SubscriptionEditState, number> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).FinancialInstitutionId;
export const getFormSpaqPaymentMethodFolioNo: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).FolioNo;
export const getFormSpaqPaymentMethodMethod: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).Method;
export const getFormSpaqPaymentMethodTransitNo: Selector<SubscriptionEditState, string> = (state: SubscriptionEditState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).TransitNo;
