import { CONTRACT, SUBSCRIPTION_EDIT, USER_VEHICLES_DETAILS, VEHICLES_DETAILS, VEHICLES_ADD, VEHICLES_EDIT, CONTRACT_CREATE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, SubscriptionInfo, Vehicule } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    hasSubscriptionUpdate: boolean;
    isCompleted: boolean;
    stepCompleted: boolean;
    hasVehicles: boolean;
    step: number;
    selectedParkId: number | null;
    selectedServiceId: number | null;
    selectedPayment: string | null;
    conditionsServiceAcceptedAt: string;
    conditionsPaymentAcceptedAt: string;
    isVisibleVehicleForm: boolean;
    skipPayment: boolean;
    skipPaymentConditions: boolean;
    paymentAuthRequired: boolean;
    choosePaymentCompleted: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    hasSubscriptionUpdate: false,
    isCompleted: false,
    stepCompleted: false,
    hasVehicles: false,
    step: 1,
    selectedParkId: null,
    selectedServiceId: null,
    selectedPayment: null,
    conditionsServiceAcceptedAt: "",
    conditionsPaymentAcceptedAt: "",
    isVisibleVehicleForm: false,
    skipPayment: false,
    skipPaymentConditions: false,
    paymentAuthRequired: false,
    choosePaymentCompleted: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT_CREATE.INIT: return contractCreateInit(state);
        case CONTRACT_CREATE.DESTROY: return contractCreateDestroy(state);
        case CONTRACT_CREATE.CLICK_NEXT: return contractCreateClickNext(state);
        case CONTRACT_CREATE.CLICK_PREVIOUS: return contractCreateClickPrevious(state);
        case CONTRACT_CREATE.CLICK_FINISH: return contractCreateClickFinish(state);
        case CONTRACT_CREATE.CLICK_ADD_VEHICLE: return contractCreateClickAddVehicle(state);
        case CONTRACT_CREATE.CLICK_MODIFY: return contractCreateClickModify(state, action.payload);
        case CONTRACT_CREATE.CLICK_PAYMENT_MODE: return contractCreateClickPaymentMode(state, action.payload);
        case CONTRACT.HYDRATE_SUCCESS: return contractHydrateSuccess(state, action.payload);
        case CONTRACT.ADD_PARK: return contractAddPark(state, action.payload);
        case CONTRACT.ADD_PARK_SUCCESS: return contractAddParkSuccess(state);
        case CONTRACT.ADD_PARK_FAILURE: return contractAddParkFailure(state, action.payload);
        case CONTRACT.ADD_SERVICE: return contractAddService(state, action.payload);
        case CONTRACT.ADD_SERVICE_SUCCESS: return contractAddServiceSuccess(state, action.payload);
        case CONTRACT.ADD_SERVICE_FAILURE: return contractAddServiceFailure(state, action.payload);
        case CONTRACT.ADD_SUBSCRIPTION_SERVICE: return contractAddSubscriptionService(state, action.payload);
        case CONTRACT.ADD_SUBSCRIPTION_SERVICE_SUCCESS: return contractAddSubscriptionServiceSuccess(state, action.payload);
        case CONTRACT.ADD_SUBSCRIPTION_SERVICE_FAILURE: return contractAddSubscriptionServiceFailure(state, action.payload);
        case CONTRACT.ADD_PAYMENT: return contractAddPayment(state, action.payload);
        case CONTRACT.ADD_PAYMENT_SUCCESS: return contractAddPaymentSuccess(state);
        case CONTRACT.ADD_PAYMENT_FAILURE: return contractAddPaymentFailure(state, action.payload);
        case CONTRACT.ACCEPT_CONDITIONS_SERVICE: return contractAcceptConditionsService(state);
        case CONTRACT.ACCEPT_CONDITIONS_SERVICE_SUCCESS: return contractAcceptConditionsServiceSuccess(state);
        case CONTRACT.ACCEPT_CONDITIONS_SERVICE_FAILURE: return contractAcceptConditionsServiceFailure(state, action.payload);
        case CONTRACT.ACCEPT_CONDITIONS_PAYMENT: return contractAcceptConditionsPayment(state);
        case CONTRACT.ACCEPT_CONDITIONS_PAYMENT_SUCCESS: return contractAcceptConditionsPaymentSuccess(state);
        case CONTRACT.ACCEPT_CONDITIONS_PAYMENT_FAILURE: return contractAcceptConditionsPaymentFailure(state, action.payload);
        case CONTRACT.DELETE_SERVICE_SUCCESS: return contractDeleteServiceSuccess(state, action.payload);
        case SUBSCRIPTION_EDIT.UPDATE: return subscriptionEditUpdate(state, action.payload);
        case SUBSCRIPTION_EDIT.UPDATE_SUCCESS: return subscriptionEditUpdateSuccess(state, action.payload);
        case SUBSCRIPTION_EDIT.UPDATE_FAILURE: return subscriptionEditUpdateFailure(state, action.payload);
        case USER_VEHICLES_DETAILS.CLICK_ADD: return userVehiclesDetailsClickAdd(state);
        case USER_VEHICLES_DETAILS.CLICK_EDIT: return userVehiclesDetailsClickEdit(state, action.payload);
        case VEHICLES_DETAILS.CLICK_EDIT: return vehiclesDetailsClickEdit(state, action.payload);
        case VEHICLES_ADD.ADD: return vehiclesAddAdd(state, action.payload);
        case VEHICLES_ADD.ADD_SUCCESS: return vehiclesAddAddSuccess(state, action.payload);
        case VEHICLES_ADD.ADD_FAILURE: return vehiclesAddAddFailure(state, action.payload);
        case VEHICLES_ADD.UPDATE_SUCCESS: return vehiclesAddUpdateSuccess(state, action.payload);
        case VEHICLES_EDIT.UPDATE: return vehiclesEditUpdate(state, action.payload);
        case VEHICLES_EDIT.UPDATE_SUCCESS: return vehiclesEditUpdateSuccess(state, action.payload);
        case VEHICLES_EDIT.UPDATE_FAILURE: return vehiclesEditUpdateFailure(state, action.payload);
        default: return state;
    }
}
export function contractCreateInit(state: State): State {
    return {
        ...state,
        isActive: true,
      isVisibleVehicleForm: false
    };
}
export function contractCreateDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        step: 1,
        hasSubscriptionUpdate: false
    };
}
export function contractCreateClickNext(state: State): State {
    const fixStep = state.step + 1;
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        stepCompleted:
          !!(fixStep === 1 && !!state.hasVehicles && !!state.selectedServiceId) ||
          !!(fixStep === 2 && !!state.hasSubscriptionUpdate) ||
          (fixStep === 3) || !!(fixStep === 4 && !!state.selectedPayment)  || false,
      step: fixStep,
    };
}
export function contractCreateClickPrevious(state: State): State {
    const fixStep = state.step - 1;
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        step: fixStep,
        stepCompleted:
          !!(fixStep === 1 && !!state.hasVehicles) ||
          !!(fixStep === 2 && !!state.hasSubscriptionUpdate) || (fixStep === 3)  || !!(fixStep === 4 && !!state.selectedPayment) || false
    };
}
export function contractCreateClickFinish(state: State): State {
    return state;
}
export function contractCreateClickAddVehicle(state: State): State {
    return {
        ...state,
        isVisibleVehicleForm: true,
    };
}
export function contractCreateClickModify(state: State, payload: number): State {
    return { ...state, step: payload };
}
export function contractCreateClickPaymentMode(state: State, payload: boolean): State {
    return { ...state };
}
export function contractHydrateSuccess(state: State, payload: ContractInfo): State {
    const skipPayment = payload && payload.service && (!payload.service.price || payload.service.serviceBillingType === 'DAS');
    const paymentCompleted = payload && payload.payment && ((payload.payment && payload.service && (payload.service.serviceType === 'Public' || payload.service.serviceType === 'Private')) || (payload.payment && payload.paymentBeforeApproval !== undefined));
    return {
        ...state,
        isCompleted: payload && payload.isCreationCompleted || initialState.isCompleted,
        step: payload && payload.isCreationCompleted ? state.isActive ? 4 : 1 : state.step,
        selectedParkId: payload && payload.park && payload.park.parkIdentificationNo >= 0 ? payload.park.parkIdentificationNo : null || initialState.selectedParkId,
        selectedServiceId: payload && payload.service && payload.service.availableServiceId || initialState.selectedServiceId,
        selectedPayment: payload && payload.service && payload.service.serviceBillingType === 'DAS' ? payload.service.serviceBillingType : payload && payload.payment || initialState.selectedPayment,
        conditionsServiceAcceptedAt: payload && payload.conditionsServiceAcceptedAt || initialState.conditionsServiceAcceptedAt,
        conditionsPaymentAcceptedAt: payload && payload.conditionsPaymentAcceptedAt || initialState.conditionsPaymentAcceptedAt,
        skipPayment: skipPayment,
        hasVehicles: payload && payload.vehicles && !!payload.vehicles.length || false,
        paymentAuthRequired: false,
        choosePaymentCompleted: paymentCompleted,
        skipPaymentConditions: payload && payload.service && !payload.service.price && payload.service.serviceBillingType !== 'DAS',
        stepCompleted:       (payload && !!(state.step === 1 && payload.vehicles && payload.service && payload.vehicles.length)) ||
          !!(state.step === 2 && state.hasSubscriptionUpdate) || state.step === 3 || !!(state.step === 4 && paymentCompleted) || false
    };
}
export function contractAddPark(state: State, payload: ParkInfo): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function contractAddParkSuccess(state: State): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function contractAddParkFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function contractAddService(state: State, payload: ServiceInfoWithGroup): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function contractAddServiceSuccess(state: State, payload: ServiceInfoWithGroup): State {
    return {
      ...state,
      step: !payload.service.price || payload.service.serviceBillingType === 'DAS' ? 1 : 1,
      skipPayment: !payload.service.price || payload.service.serviceBillingType === 'DAS' ? true : false,
      selectedPayment: payload.service.serviceBillingType === 'DAS' ? 'DAS' : state.selectedPayment,
      skipPaymentConditions: !payload.service.price && payload.service.serviceBillingType !== 'DAS' ? true : false,
      hasSuccess: false,
      isLoading: false,
    };
}
export function contractAddServiceFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function contractAddSubscriptionService(state: State, payload: SubscriptionService): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function contractAddSubscriptionServiceSuccess(state: State, payload: SubscriptionService): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        isCompleted: true,
    };
}
export function contractAddSubscriptionServiceFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function contractAddPayment(state: State, payload: string): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function contractAddPaymentSuccess(state: State): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function contractAddPaymentFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function contractAcceptConditionsService(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function contractAcceptConditionsServiceSuccess(state: State): State {
    return {
      ...state,
//        step: state.step + (state.skipPaymentConditions ? 1 : 0),
      hasSuccess: true,
      isLoading: false,
    };
}
export function contractAcceptConditionsServiceFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function contractAcceptConditionsPayment(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function contractAcceptConditionsPaymentSuccess(state: State): State {
    return contractCreateClickNext({
        ...state,
        step: state.step,
        hasSuccess: true,
        isLoading: false,
    });
}
export function contractAcceptConditionsPaymentFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function contractDeleteServiceSuccess(state: State, payload: any): State {
    return contractCreateClickModify({ ...state, isCompleted: false }, 1);
}
export function subscriptionEditUpdate(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function subscriptionEditUpdateSuccess(state: State, payload: SubscriptionInfo): State {
    return contractCreateClickNext({
        ...state,
        hasSuccess: true,
        isLoading: false,
        hasSubscriptionUpdate: true,
        step: state.isActive && state.step || state.step - 1,
    });
}
export function subscriptionEditUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function userVehiclesDetailsClickAdd(state: State): State {
    return {
        ...state,
        isVisibleVehicleForm: true,
    };
}
export function userVehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return {
        ...state,
        isVisibleVehicleForm: true,
    };
}
export function vehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return {
        ...state,
        isVisibleVehicleForm: true,
    };
}
export function vehiclesAddAdd(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function vehiclesAddAddSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        isVisibleVehicleForm: false,
        hasSuccess: true,
        isLoading: false,
    };
}
export function vehiclesAddAddFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function vehiclesAddUpdateSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        isVisibleVehicleForm: false,
        hasSuccess: true,
        isLoading: false,
    };
}
export function vehiclesEditUpdate(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function vehiclesEditUpdateSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function vehiclesEditUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
