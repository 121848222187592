import { State as ContactState } from "@client/reducers/contact-reducer";
import { Selector } from "@ngrx/store";
export const getMessage: Selector<ContactState, string | null> = (state: ContactState) => state.message;
export const getHasError: Selector<ContactState, boolean> = (state: ContactState) => state.hasError;
export const getHasSuccess: Selector<ContactState, boolean> = (state: ContactState) => state.hasSuccess;
export const getIsLoading: Selector<ContactState, boolean> = (state: ContactState) => state.isLoading;
export const getIsActive: Selector<ContactState, boolean> = (state: ContactState) => state.isActive;
export const getDisableSubject: Selector<ContactState, boolean> = (state: ContactState) => state.disableSubject;
export const getDisableNature: Selector<ContactState, boolean> = (state: ContactState) => state.disableNature;
export const getDisableContractNo: Selector<ContactState, boolean> = (state: ContactState) => state.disableContractNo;
export const getDisableService: Selector<ContactState, boolean> = (state: ContactState) => state.disableService;
