import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VehiclesAddDispatcher } from '@client/dispatchers/vehicles-add-dispatcher';
import { VehiclesDetailsDispatcher } from '@client/dispatchers/vehicles-details-dispatcher';
import { VehiclesDispatcher } from '@client/dispatchers/vehicles-dispatcher';
import { VehiclesEditDispatcher } from '@client/dispatchers/vehicles-edit-dispatcher';
import { VehiclesFormDispatcher } from '@client/dispatchers/vehicles-form-dispatcher';
import {
  VEHICLES_ADD_DISPATCHER,
  VEHICLES_ADD_STORE,
  VEHICLES_DETAILS_DISPATCHER,
  VEHICLES_DETAILS_STORE,
  VEHICLES_DISPATCHER,
  VEHICLES_EDIT_DISPATCHER,
  VEHICLES_EDIT_STORE,
  VEHICLES_FORM_DISPATCHER,
  VEHICLES_FORM_STORE,
  VEHICLES_STORE
} from '@client/injection';
import { PopupModule } from '@client/modules/popup';
import { FormModule } from '@client/shared/components/forms';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';

import { VehiclesAddStore } from '@client/stores/vehicles-add-store';
import { VehiclesDetailsStore } from '@client/stores/vehicles-details-store';
import { VehiclesEditStore } from '@client/stores/vehicles-edit-store';
import { VehiclesFormStore } from '@client/stores/vehicles-form-store';
import { VehiclesStore } from '@client/stores/vehicles-store';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddVehicleComponent, FieldsetVehicleComponent, VehicleItemComponent, VehiclesDetailComponent } from './components';
import { VehiclesAddComponent, VehiclesComponent, VehiclesEditComponent, VehiclesPageComponent } from './pages';

@NgModule({
  imports: [SharedCommonModule, DropDownsModule, SharedComponentsModule, SharedCommonModule, RouterModule, FormModule, SharedFormModule, PopupModule],
  declarations: [VehiclesPageComponent, VehicleItemComponent, VehiclesEditComponent, FieldsetVehicleComponent, VehiclesAddComponent, AddVehicleComponent, VehiclesDetailComponent, VehiclesComponent],
  exports: [VehiclesPageComponent, VehicleItemComponent, VehiclesEditComponent, FieldsetVehicleComponent, VehiclesAddComponent, AddVehicleComponent, VehiclesDetailComponent, VehiclesComponent, PopupModule]
})
export class VehiclesModule {
  static forRoot(): ModuleWithProviders<VehiclesModule> {
    return {
      ngModule: VehiclesModule,
      providers: [
        { provide: VEHICLES_STORE, useClass: VehiclesStore },
        { provide: VEHICLES_EDIT_STORE, useClass: VehiclesEditStore },
        { provide: VEHICLES_ADD_STORE, useClass: VehiclesAddStore },
        { provide: VEHICLES_DETAILS_STORE, useClass: VehiclesDetailsStore },
        { provide: VEHICLES_DISPATCHER, useClass: VehiclesDispatcher },
        { provide: VEHICLES_EDIT_DISPATCHER, useClass: VehiclesEditDispatcher },
        { provide: VEHICLES_ADD_DISPATCHER, useClass: VehiclesAddDispatcher },
        { provide: VEHICLES_DETAILS_DISPATCHER, useClass: VehiclesDetailsDispatcher },
        { provide: VEHICLES_FORM_DISPATCHER, useClass: VehiclesFormDispatcher },
        { provide: VEHICLES_FORM_STORE, useClass: VehiclesFormStore }
      ]
    };
  }
}
