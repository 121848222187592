import {Component, forwardRef, ViewChild, AfterViewInit} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { AbstractInput } from '@client/shared/components/forms/abstract-input';
import {TextBoxContainerComponent} from "@progress/kendo-angular-inputs";

@Component({
  selector: 'plancton-input-email',
  template: `
    <kendo-textbox-container class="w-100" for="login-email-email" floatingLabel="{{ 'SHARED.FORMS.INPUT_EMAIL.LABEL' | translate }}">
      <input [id]="formControlName" [name]="formControlName" [disabled]="disabled" [autofocus]="true" [autocomplete]="true" autocomplete="email" type="email" kendoTextBox [(ngModel)]="value" (ngModelChange)="updateModel()" (blur)="onModelTouched()" />

      <small class="alert-danger" *ngIf="!isValid">{{ 'SHARED.FORMS.INPUT_EMAIL.IS_INVALID' | translate }}</small>
    </kendo-textbox-container>
  `,
  styles: [],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputEmailComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputEmailComponent), multi: true }
  ]
})
export class InputEmailComponent extends AbstractInput implements AfterViewInit {
  @ViewChild(TextBoxContainerComponent) container;

  validateFn = (control: AbstractControl) => {
    if (control.value.length) {
      return Validators.email(control);
    }
    return null;
  }
  ngAfterViewInit() {
    window.setTimeout(() => this.container.focused = true, 0);
  }
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validateFn(control);
  }
}
