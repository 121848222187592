import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getSessionState, getSubscriptionState } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsPublicGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getSessionState),
      filter(x => x && x.isLoaded),
      switchMap(s => {
        if (!s.uid) {
          return of(true);
        }
        if (!s.emailVerified) {
          this.router.navigate(['/auth/register/email/pending']);
          return of(false);
        }
        return this.store.pipe(
          select(getSubscriptionState),
          filter(x => x && x.isHydrated),
          map(s => {
            const contractNo = s.data && s.data.spaq && s.data.spaq.contractNo;

            if (!contractNo) {
              this.router.navigate(['/auth/register/subscription']);
              return false;
            }

            this.router.navigate(['/account/contract/create']);
            return false;
          })
        );

        // return this.store.pipe(select(getSubscriptionState))
      })
    );
  }
}
