import { Injectable } from '@angular/core';
import { AUTH, REGISTER_EMAIL } from '@client/actions';
import { AuthCreateUserWithEmailAndPasswordAction, AuthCreateUserWithEmailAndPasswordSuccessAction, AuthSendEmailVerificationLinkAction, AuthSignInWithEmailAndPasswordAction } from '@client/actions/auth-actions';
import { RegisterEmailCompleteAction } from '@client/actions/register-email-actions';
import { Action } from '@client/lib/action';
import { getRegisterEmailForm, getRegisterEmailIsActive, getRegisterEmailIsRegistered } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class RegisterEmailAuthEffects {
  @Effect()
  SignInSuccess: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS),
    withLatestFrom(this.store.pipe(select(getRegisterEmailIsActive))),
    switchMap(([action, isRegistering]) => (!isRegistering ? EMPTY : of(new AuthSendEmailVerificationLinkAction())))
  );
  @Effect()
  clickSubmit$: Observable<Action> = this.actions$.pipe(
    ofType(REGISTER_EMAIL.CLICK_SUBMIT),
    withLatestFrom(this.store.pipe(select(getRegisterEmailForm))),
    map(([a, b]) => new AuthCreateUserWithEmailAndPasswordAction(b))
  );
  @Effect()
  createUserSuccess$: Observable<Action> = this.actions$.pipe(
    ofType<AuthCreateUserWithEmailAndPasswordSuccessAction>(AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS),
    map(action => new AuthSignInWithEmailAndPasswordAction(action.payload))
  );
  @Effect({ dispatch: false })
  isActive$ = this.store.pipe(select(getRegisterEmailIsActive));
  @Effect()
  sendLinkSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.SEND_EMAIL_VERIFICATION_LINK_SUCCESS),
    withLatestFrom(this.store.pipe(select(getRegisterEmailIsRegistered))),
    switchMap(([action, isRegistered]) => (!isRegistered ? EMPTY : of(new RegisterEmailCompleteAction())))
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
