import { NgModule } from '@angular/core';
import { AccessCodeAddStore } from '@client/stores/access-code-add-store';
import { AccessCodeDetailsStore } from '@client/stores/access-code-details-store';
import { ProfileDetailsStore } from '@client/stores/profile-details-store';
import { ProfileEditStore } from '@client/stores/profile-edit-store';
import { ProfileStore } from '@client/stores/profile-store';
import { SubscriptionDetailsStore } from '@client/stores/subscription-details-store';
import { SubscriptionEditStore } from '@client/stores/subscription-edit-store';
import { SubscriptionStore } from '@client/stores/subscription-store';

@NgModule({
  imports: [],
  declarations: [],
  providers: [ProfileStore, ProfileEditStore, ProfileDetailsStore, SubscriptionStore, SubscriptionEditStore, SubscriptionDetailsStore, AccessCodeAddStore, AccessCodeDetailsStore]
})
export class StoresModule {}
