import { WINDOW } from "@client/actions";
import { Action } from "@client/lib/action";

export class WindowResizeAction implements Action {
    readonly type = WINDOW.RESIZE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowOfflineAction implements Action {
    readonly type = WINDOW.OFFLINE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowBeforePrintAction implements Action {
    readonly type = WINDOW.BEFORE_PRINT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowAfterPrintAction implements Action {
    readonly type = WINDOW.AFTER_PRINT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowCutAction implements Action {
    readonly type = WINDOW.CUT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowCopyAction implements Action {
    readonly type = WINDOW.COPY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowPasteAction implements Action {
    readonly type = WINDOW.PASTE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowFullscreenChangeAction implements Action {
    readonly type = WINDOW.FULLSCREEN_CHANGE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowFullscreenErrorAction implements Action {
    readonly type = WINDOW.FULLSCREEN_ERROR;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class WindowErrorAction implements Action {
    readonly type = WINDOW.ERROR;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
