import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  AccessCodeGroupInfo,
  AccessCodeInfo,
  AccessCodeSearchInfo,
  SubscriptionInfo
} from '@client/utils/shared-constants';

import * as moment from 'moment';
import {EMPTY, from, Observable, of, throwError, zip} from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { OrganizationsService } from './organization.service';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class AccessCodeService {
  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase, private organizationService: OrganizationsService) {}

  add(accessCode: AccessCodeSearchInfo): Observable<any> {
    const userCode = accessCode.userCode.toUpperCase().replace(/[\.\#\[\]]/g, '');
    const companyCode = accessCode.companyCode.toUpperCase().replace(/[\.\#\[\]]/g, '');


    return this.afAuth.user.pipe(
      switchMap((u: User) => this.db
        .object(`/access-code/${companyCode}/${userCode}`)
        .valueChanges()
        .pipe(
          first(),
          switchMap((res: any) => {
            if (!res) {
              return throwError("Code introuvable. Assurez-vous d'avoir bien saisi vos informations");
            }
            if (res.used === true) {
              return throwError('Ce code est déjà utilisé');
            }
            if (res.single === false) {
              if (res.startAt) {
                if (new Date(res.startAt).getTime() > new Date().getTime()) {
                  return throwError("Ce code d'accès sera disponible à partir du " + moment(res.startAt).format('LL'));
                }

                if (new Date(res.endAt).getTime() < new Date().getTime()) {
                  return throwError("Ce code d'accès est expiré depuis le " + moment(res.endAt).format('LL'));
                }
              }
            }

            const act = [];
            act.push(this.db.object(`/user/${u.uid}/access-code`).set({ ...res, date: new Date().toISOString() }));

            if (res && res.values && typeof res.values === 'object') {
              act.push(this.db.object(`/user/${u.uid}/subscription/custom`).update(res.values));
            }

            return zip(...act);
          })
        ))
    );
  }

  delete(): Observable<void> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/access-code`).remove()))
    );
  }

  get(): Observable<any> {

    return this.afAuth.user.pipe(
      switchMap((u: User) => this.db
        .object<AccessCodeInfo>(`/user/${u.uid}/access-code`)
        .valueChanges()
        .pipe(switchMap((code: AccessCodeInfo) => (code ? this.getOrganization(code) : of(null)))))
    );
  }

  getCodes(companyCode, userCode): Observable<any> {
    return this.db
      .object(`/access-code/${companyCode}/codes/${userCode}`)
      .snapshotChanges()
      .pipe(
        first(),
        map(res => ({ id: res.key, ...(res.payload.val() as object) })),
        tap(res => {
          if (!res.id) {
            throw new Error("Code introuvable. Assurez-vous d'avoir bien saisi vos informations");
          }
          if (res.used === true) {
            throw new Error('Ce code est déjà utilisé');
          }
        })
      );
  }

  getCompanyName(companyCode): Observable<any> {
    return this.db
      .object(`/access-code/${companyCode}/name`)
      .valueChanges()
      .pipe(first());
  }

  getOrganization(code: AccessCodeInfo): Observable<any> {
    return this.organizationService.get(code);
  }

  hydrate(): Observable<AccessCodeGroupInfo> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => this.db
        .object<AccessCodeInfo>(`/user/${u.uid}/access-code`)
        .valueChanges()
        .pipe(switchMap((res: any) => (!res ? of(null) : this.db.object<AccessCodeGroupInfo>(`/organizations/${res.organizationCode}/groups/${res.groupCode && res.groupCode.groupCode || res.groupCode}`).valueChanges())))
    ));
  }

  searchAccessCode({ companyCode, userCode }: AccessCodeSearchInfo) {
    companyCode = companyCode.toUpperCase();
    return zip(this.getCodes(companyCode, userCode), this.getCompanyName(companyCode)).pipe(
      map(d => {
        return { ...d[0], companyName: d[1] as AccessCodeSearchInfo };
      })
    );
  }
}
