import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH, LAYOUT } from '@client/actions';
import { AccessCodeDeleteAction } from '@client/actions/access-code-actions';
import { ContractDeleteAction } from '@client/actions/contract-actions';
import { LayoutClickAction, LayoutClickDeleteUserAction, LayoutCollapseSideNavAction, LayoutSetScreenAction } from '@client/actions/layout-actions';
import { UserSyncAction } from '@client/actions/user-actions';
import { UserService } from '@client/core/services/user.service';
import { getLayoutScreenMobile } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Observable, of } from 'rxjs';
import { defer } from 'rxjs/internal/observable/defer';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class LayoutEffects {
  @Effect({ dispatch: false })
  deleteUser$: Observable<any> = this.actions$.pipe(
    ofType(LAYOUT.CLICK_DELETE_USER),
    switchMap(() => {
      const dialogRef = this.dialogService.open({
        title: 'Confirmation du mot de passe'
      });
      dialogRef.content.instance.loggedIn$ = of(false);
      return this.actions$.pipe(
        ofType(AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS),
        take(1),
        tap(() => dialogRef.close()),
        switchMap(() => this.user.delete())
      );
    })
  );
  @Effect({ dispatch: true })
  init$: Observable<any> = defer(() => {
    return fromEvent(window, 'resize').pipe(
      debounceTime(200),
      map(() => window.innerWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth),
      map(width => {
        const MOBILE_MAX_WIDTH = 425;
        const TABLET_MAX_WIDTH = 1024;

        const mobile = width <= MOBILE_MAX_WIDTH;
        const tablet = width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH;

        return new LayoutSetScreenAction({
          mobile,
          tablet,
          desktop: !mobile && !tablet
        });
      })
    );
  });
  @Effect({ dispatch: true })
  onClickSidebarItem$: Observable<any> = this.actions$.pipe(
    ofType(LAYOUT.CLICK),
    map((a: LayoutClickAction) => a.payload),
    withLatestFrom(this.store.pipe(select(getLayoutScreenMobile))),
    map(([action, isMobile]) => {
      if (isMobile || action.id === 'test') {
        this.store.dispatch(new LayoutCollapseSideNavAction(null, Date.now()));
      }
      switch (action.id) {
        case 'sync_account':
          return new UserSyncAction();
        case 'delete_access_code':
          return new AccessCodeDeleteAction();
        case 'delete_account':
          return new LayoutClickDeleteUserAction();
        case 'delete_contract':
          return new ContractDeleteAction();
        case 'ticket':
          window.open('https://spaq.ezpayca.com/', '_blank');
          break;
        default:
          if (action.link) {
            this.router.navigate([action.link]);
          }
      }

      return action;
    })
  );

  constructor(private router: Router, private actions$: Actions, private dialogService: DialogService, private user: UserService, private store: Store<any>) {}
}
