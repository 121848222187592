import { Injectable } from '@angular/core';
import { SECTORS, SESSION } from '@client/actions';
import { SectorsHydrateFailureAction, SectorsHydrateSuccessAction, SectorsStartServiceAction } from '@client/actions/sectors-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { SectorsService } from '@client/core/services/sectors.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { empty, iif, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class SectorsEffects {
  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.sectors, of(new SectorsStartServiceAction(null, action.correlationId)), empty()))
  );

  @Effect({ dispatch: true })
  hydrateSectors$ = this.actions$.pipe(
    ofType<SectorsStartServiceAction>(SECTORS.START_SERVICE),
    switchMap(action =>
      this.sectors.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map(sectors => new SectorsHydrateSuccessAction(sectors, action.correlationId)),
        catchError(error => of(new SectorsHydrateFailureAction(error, action.correlationId)))
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private sectors: SectorsService) {}
}
