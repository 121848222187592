import { TEST_GROUP_LIST, TEST_GROUP_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TestGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    form: TestGroupInfo;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    form: {
        id: "",
        name: "",
        tests: []
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case TEST_GROUP_FORM.INIT: return testGroupFormInit(state);
        case TEST_GROUP_FORM.DESTROY: return testGroupFormDestroy(state);
        case TEST_GROUP_FORM.CLICK_SUBMIT: return testGroupFormClickSubmit(state, action.payload);
        case TEST_GROUP_FORM.UPDATE: return testGroupFormUpdate(state, action.payload);
        case TEST_GROUP_FORM.UPDATE_SUCCESS: return testGroupFormUpdateSuccess(state);
        case TEST_GROUP_FORM.UPDATE_FAILURE: return testGroupFormUpdateFailure(state, action.payload);
        case TEST_GROUP_FORM.ADD: return testGroupFormAdd(state, action.payload);
        case TEST_GROUP_FORM.ADD_SUCCESS: return testGroupFormAddSuccess(state);
        case TEST_GROUP_FORM.ADD_FAILURE: return testGroupFormAddFailure(state, action.payload);
        case TEST_GROUP_LIST.SELECT: return testGroupListSelect(state, action.payload);
        case TEST_GROUP_LIST.CLICK_ADD: return testGroupListClickAdd(state);
        case TEST_GROUP_LIST.CLICK_EDIT: return testGroupListClickEdit(state);
        default: return state;
    }
}
export function testGroupFormInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function testGroupFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function testGroupFormClickSubmit(state: State, payload: TestGroupInfo): State {
    return { ...state };
}
export function testGroupFormUpdate(state: State, payload: TestGroupInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testGroupFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function testGroupFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function testGroupFormAdd(state: State, payload: TestGroupInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testGroupFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function testGroupFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function testGroupListSelect(state: State, payload: TestGroupInfo): State {
    return {
        ...state,
        form: payload,
    };
}
export function testGroupListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
        form: initialState.form,
    };
}
export function testGroupListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true,
    };
}
