import { Injectable } from '@angular/core';
import { SESSION, SUBSCRIPTION } from '@client/actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { SubscriptionHydrateAction, SubscriptionHydrateFailureAction, SubscriptionHydrateSuccessAction } from '@client/actions/subscription-actions';
import { SubscriptionService } from '@client/core/services/subscription.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, iif, of } from 'rxjs';

import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class SubscriptionEffects {
  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.subscription, of(new SubscriptionHydrateAction(null, action.correlationId)), EMPTY))
  );

  @Effect({ dispatch: true })
  hydrateSectors$ = this.actions$.pipe(
    ofType<SubscriptionHydrateAction>(SUBSCRIPTION.HYDRATE),
    switchMap(action => {
      try {
        return  this.subscription.hydrate().pipe(
          takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
          map(subscription => new SubscriptionHydrateSuccessAction(subscription, action.correlationId)),
          catchError(error => of(new SubscriptionHydrateFailureAction(error, action.correlationId)))
        )
      } catch (e) {
        alert('test');
      }

    }

    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private subscription: SubscriptionService) {}
}
