import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthLogoutAction } from '@client/actions/auth-actions';
import { AuthService } from '@client/core/services/auth.service';
import {getLayoutEnableAdminOptions, getLayoutShowSidebav} from '@client/selectors';
import {select, Store} from '@ngrx/store';
import { Observable, of } from 'rxjs/index';

import { lastBuild } from 'src/app/configs/last-build';
import * as moment from "moment";

@Component({
  selector: 'spaq-layout-account-sidebar',
  templateUrl: './layout-account-sidebar.component.html',
  styleUrls: ['./layout-account-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutAccountSidebarComponent implements OnInit {
  lastBuild = '';
  avatar = '/assets/spaq-logo-wb.jpg';
  enableAdminOptions$ = this.store.pipe(select(getLayoutEnableAdminOptions));
  @Output() clickAdminMenu = new EventEmitter();
  @Output() clickEnableAdminOptions = new EventEmitter();
  @Output() clickUserMenu = new EventEmitter();
  displayName = '';
  @Input() enableAdminOptions = false;
  haveContract = false;
  hide$: Observable<boolean> = of(false);
  @Input() isAdmin = false;
  @Input() isAdminMenu = false;
  @Input() isStaff = false;
  @Input() items = [];
  @Output() onClick = new EventEmitter();
  @Input() selectedGroup = '';
  @Input() selectedItem = '';

  constructor(private store: Store<any>, private router: Router, private afAuth: AngularFireAuth, private auth: AuthService) {
    this.hide$ = this.store.select(getLayoutShowSidebav);
    this.lastBuild = `${moment(lastBuild).format('LL')} (${moment(lastBuild).fromNow()})`;
  }

  ngOnInit() {}

  click($event, item) {
    this.onClick.emit(item);
    $event.preventDefault();
  }

  logout() {
    this.store.dispatch(new AuthLogoutAction());
  }

  onClickAdminMenu() {
    this.clickAdminMenu.emit();
  }

  onClickEnableAdminOptions() {
    this.clickEnableAdminOptions.emit();
  }

  onClickUserMenu() {
    this.clickUserMenu.emit();
  }
}
