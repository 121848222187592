import { CONTRACT, CONTRACT_CANCEL } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { SubscriptionService } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    selectedDate: string | null;
    selectedService: SubscriptionService | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isCompleted: false,
    selectedDate: null,
    selectedService: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT_CANCEL.INIT: return contractCancelInit(state);
        case CONTRACT_CANCEL.DESTROY: return contractCancelDestroy(state);
        case CONTRACT_CANCEL.CLICK_BACK: return contractCancelClickBack(state);
        case CONTRACT_CANCEL.CLICK_CANCEL: return contractCancelClickCancel(state, action.payload);
        case CONTRACT_CANCEL.SELECT_DATE: return contractCancelSelectDate(state, action.payload);
        case CONTRACT_CANCEL.SELECT_SERVICE: return contractCancelSelectService(state, action.payload);
        case CONTRACT.CANCEL: return contractCancel(state, action.payload);
        case CONTRACT.CANCEL_SUCCESS: return contractCancelSuccess(state, action.payload);
        case CONTRACT.CANCEL_FAILURE: return contractCancelFailure(state, action.payload);
        default: return state;
    }
}
export function contractCancelInit(state: State): State {
    return {
        ...state,
        isActive: true,
        selectedDate: null
    };
}
export function contractCancelDestroy(state: State): State {
    return initialState;
}
export function contractCancelClickBack(state: State): State {
    return state;
}
export function contractCancelClickCancel(state: State, payload: string): State {
    return state;
}
export function contractCancelSelectDate(state: State, payload: string): State {
    return {
        ...state,
        selectedDate: payload
    };
}
export function contractCancelSelectService(state: State, payload: SubscriptionService): State {
    return { ...state, selectedService: payload };
}
export function contractCancel(state: State, payload: string): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
        isCompleted: false
    };
}
export function contractCancelSuccess(state: State, payload: string): State {
    return {
        ...state,
        hasSuccess: true,
        isCompleted: true,
        isLoading: false
    };
}
export function contractCancelFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false
    };
}
