import { VEHICLES, VEHICLES_DETAILS, VEHICLES_ADD, VEHICLES_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    isLoading: boolean;
    message: string | null;
    isEdit: boolean;
    selectedId: string | null;
    form: Vehicule;
    validated: boolean;
    isValidationRequired: boolean;
}
export const initialState: State = {
    isActive: false,
    hasSuccess: false,
    hasError: false,
    isLoading: false,
    message: null,
    isEdit: false,
    selectedId: null,
    form: {
        description: "",
        id: 0,
        key: "",
        licencePlate: "",
        manufacturer: "",
        model: "",
        vehiculeYear: ""
    },
    validated: false,
    isValidationRequired: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case VEHICLES_FORM.INIT: return vehiclesFormInit(state, action.payload);
        case VEHICLES_FORM.DESTROY: return vehiclesFormDestroy(state);
        case VEHICLES_FORM.SELECT_YEAR: return vehiclesFormSelectYear(state, action.payload);
        case VEHICLES_FORM.SELECT_BRAND: return vehiclesFormSelectBrand(state, action.payload);
        case VEHICLES_FORM.VALIDATION_REQUIRED: return vehiclesFormValidationRequired(state);
        case VEHICLES_FORM.CLICK_VALIDATION: return vehiclesFormClickValidation(state);
        case VEHICLES.HYDRATE_SUCCESS: return vehiclesHydrateSuccess(state, action.payload);
        case VEHICLES_DETAILS.CLICK_EDIT: return vehiclesDetailsClickEdit(state, action.payload);
        case VEHICLES_ADD.SELECT_YEAR: return vehiclesAddSelectYear(state, action.payload);
        case VEHICLES_ADD.SELECT_BRAND: return vehiclesAddSelectBrand(state, action.payload);
        case VEHICLES_ADD.SELECT_MODEL: return vehiclesAddSelectModel(state, action.payload);
        default: return state;
    }
}
export function vehiclesFormInit(state: State, payload: string): State {
    return {
        ...state,
        isActive: true,
        isEdit: !!payload,
        selectedId: payload || initialState.selectedId
    };
}
export function vehiclesFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        selectedId: initialState.selectedId,
        form: initialState.form,
        validated: false,
        isValidationRequired: false
    };
}
export function vehiclesFormSelectYear(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesFormSelectBrand(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesFormValidationRequired(state: State): State {
    return {
        ...state,
        isValidationRequired: true,
        validated: false
    };
}
export function vehiclesFormClickValidation(state: State): State {
    return {
        ...state,
        validated: true,
        isValidationRequired: false
    };
}
export function vehiclesHydrateSuccess(state: State, payload: any): State {
    return {
        ...state,
        form: state.selectedId && payload.filter(v => v.id === Number(state.selectedId))[0] || initialState.form
    };
}
export function vehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return { ...state, form: payload };
}
export function vehiclesAddSelectYear(state: State, payload: string): State {
    return { ...state, form: { ...state.form, vehiculeYear: payload, manufacturer: '', model: '' } };
}
export function vehiclesAddSelectBrand(state: State, payload: string): State {
    return { ...state, form: { ...state.form, manufacturer: payload, model: '' } };
}
export function vehiclesAddSelectModel(state: State, payload: string): State {
    return { ...state, form: { ...state.form, model: payload } };
}
