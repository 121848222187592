import { State as AdminCodeListState } from "@client/reducers/admin-code-list-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeInfo } from "@client/utils/shared-constants";
export const getHasImport: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.hasImport;
export const getSelectedCodeIndex: Selector<AdminCodeListState, number | null> = (state: AdminCodeListState) => state.selectedCodeIndex;
export const getSelectedFirstnameIndex: Selector<AdminCodeListState, number | null> = (state: AdminCodeListState) => state.selectedFirstnameIndex;
export const getSelectedLastnameIndex: Selector<AdminCodeListState, number | null> = (state: AdminCodeListState) => state.selectedLastnameIndex;
export const getExtraIndex: Selector<AdminCodeListState, any | null> = (state: AdminCodeListState) => state.extraIndex;
export const getCanUpdate: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.canUpdate;
export const getCanDelete: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.canDelete;
export const getCanAdd: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.canAdd;
export const getIgnoreHeader: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.ignoreHeader;
export const getImportStats: Selector<AdminCodeListState, {
    total: number;
    add: number;
    update: number;
    delete: number;
}> = (state: AdminCodeListState) => state.importStats;
export const getImportStatsTotal: Selector<AdminCodeListState, number> = (state: AdminCodeListState) => getImportStats(state) && getImportStats(state).total;
export const getImportStatsAdd: Selector<AdminCodeListState, number> = (state: AdminCodeListState) => getImportStats(state) && getImportStats(state).add;
export const getImportStatsUpdate: Selector<AdminCodeListState, number> = (state: AdminCodeListState) => getImportStats(state) && getImportStats(state).update;
export const getImportStatsDelete: Selector<AdminCodeListState, number> = (state: AdminCodeListState) => getImportStats(state) && getImportStats(state).delete;
export const getIsActive: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.isActive;
export const getMessage: Selector<AdminCodeListState, string | null> = (state: AdminCodeListState) => state.message;
export const getHasError: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.hasError;
export const getHasSuccess: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.isLoading;
export const getData: Selector<AdminCodeListState, AccessCodeInfo[]> = (state: AdminCodeListState) => state.data;
export const getImportData: Selector<AdminCodeListState, any> = (state: AdminCodeListState) => state.importData;
export const getSelecteAccessCode: Selector<AdminCodeListState, AccessCodeInfo | null> = (state: AdminCodeListState) => state.selecteAccessCode;
export const getSelecteAccessCodeCompanyCode: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).companyCode;
export const getSelecteAccessCodeCompanyName: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).companyName;
export const getSelecteAccessCodeFirstName: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).firstName;
export const getSelecteAccessCodeId: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).id;
export const getSelecteAccessCodeLastName: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).lastName;
export const getSelecteAccessCodeUsed: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).used;
export const getSelecteAccessCodeUserCode: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).userCode;
export const getSelecteAccessCodeOrganizationCode: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).organizationCode;
export const getSelecteAccessCodeGroupCode: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).groupCode;
export const getSelecteAccessCodeSingle: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => getSelecteAccessCode(state) && getSelecteAccessCode(state).single;
export const getSelectedOrganizationCode: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => state.selectedOrganizationCode;
export const getSelectedGroupCode: Selector<AdminCodeListState, string> = (state: AdminCodeListState) => state.selectedGroupCode;
export const getShowOrganizationBacklink: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.showOrganizationBacklink;
export const getShowGroupBacklink: Selector<AdminCodeListState, boolean> = (state: AdminCodeListState) => state.showGroupBacklink;
