import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLogoutAction } from '@client/actions/auth-actions';
import { LayoutClickAction, LayoutClickAdminMenuAction, LayoutClickUserMenuAction, LayoutCollapseAdminOptionsAction } from '@client/actions/layout-actions';
import {
  getLayoutAdminMenu,
  getLayoutEnableAdminOptions,
  getLayoutIsAdminMenu,
  getLayoutMenu, getLayoutScopeId,
  getLayoutScreenMobile,
  getLayoutScreenTablet,
  getLayoutSelectedGroup,
  getLayoutSelectedItem,
  getLayoutShowSidebav, getOrganizationById,
  getSessionClaimsAccessLevel
} from '@client/selectors';
import { getSessionClaimsAdmin } from '@client/selectors/index';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {combineLatest, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'spaq-layout-account',
  template: `
    <spaq-layout-account-container>
      <spaq-layout-account-sidebar
        [isAdmin]="isAdmin$ | async"
        [selectedGroup]="selectedGroup$ | async"
        [selectedItem]="selectedItem$ | async"
        [isAdminMenu]="isAdminMenu$ | async"
        [isStaff]="isStaff$ | async"
        [enableAdminOptions]="enableAdminOptions$ | async"
        [hidden]="hide$ | async"
        (onClick)="onSidebarClick($event)"
        (clickAdminMenu)="onClickAdminMenu()"
        (clickEnableAdminOptions)="onEnableAdminOptions()"
        (clickUserMenu)="onClickUserMenu()"
        [items]="items$ | async"
        class="col-md-3 p-0 h-100"
      ></spaq-layout-account-sidebar>

      <spaq-layout-account-main class="col-md-9 p-0">
        <spaq-layout-account-header class="w-100"></spaq-layout-account-header>
        <router-outlet></router-outlet>
      </spaq-layout-account-main>
    </spaq-layout-account-container>
  `,
  styles: [
    `
      .oo {
        overflow: auto;
      }

      spaq-layout-account-sidebar {
      }
    `
  ]
})
export class LayoutAccountComponent implements OnInit {
  desktop$ = this.store.select(state => state.screen.desktop);
  enableAdminOptions$ = this.store.pipe(select(getLayoutEnableAdminOptions));
  isAdmin$ = this.store.pipe(select(getSessionClaimsAdmin));
  isAdminMenu$ = this.store.pipe(select(getLayoutIsAdminMenu));
  isStaff$ = this.store.pipe(
    select(getSessionClaimsAccessLevel),
    map(x => x === 5)
  );
  items$ = of({});
  mobile$ = this.store.pipe(select(getLayoutScreenMobile));
  hide$: Observable<any> = this.store.select(getLayoutShowSidebav).pipe(
    combineLatest(this.mobile$),
    map(x => {
      // console.log(x);
      if (x[1]) {
        return x[0];
      }
      return !x[0];
    })
  );
  public opened = true;
  selectedGroup$ = this.store.pipe(select(getLayoutSelectedGroup));
  selectedItem$ = this.store.pipe(select(getLayoutSelectedItem));

  scopeId$ = this.store.pipe(select(getLayoutScopeId));
  isSelectableGroup$ = this.scopeId$.pipe(switchMap(id => this.store.pipe(select(getOrganizationById, id)).pipe(map((x: any) => Boolean(x && x.config && x.config.enableSelectableGroup)))));
  tablet$ = this.store.pipe(select(getLayoutScreenTablet));

  constructor(private store: Store<any>, private router: Router) {
    this.items$ = this.isAdminMenu$.pipe(
      switchMap(isAdmin =>
        this.store.pipe(
          select((isAdmin && getLayoutAdminMenu) || getLayoutMenu),
          withLatestFrom(this.isSelectableGroup$),
          map(([x, isSelectableGroup]) => {
            if (x) {
              const values = Object.keys(x).filter(x => (isSelectableGroup && x === 'add_access_code' ? false : true)).map(key => x[key]);
              return values.map(l => {
                if (!l.children) {
                  return l;
                }

                const lvalues = Object.keys(l.children).map(key => l.children[key]);
                return { ...l, children: lvalues };
              });
            }
            return x;
          }),
          tap(x => {
            console.log(x);
          })
        )
      )
    );
  }

  ngOnInit() {}

  delete() {}

  logout() {
    this.store.dispatch(new AuthLogoutAction(null, Date.now()));
  }

  onClickAdminMenu() {
    this.store.dispatch(new LayoutClickAdminMenuAction(null, Date.now()));
  }

  onClickUserMenu() {
    this.store.dispatch(new LayoutClickUserMenuAction(null, Date.now()));
  }

  onEnableAdminOptions() {
    this.store.dispatch(new LayoutCollapseAdminOptionsAction(null, Date.now()));
  }

  onSidebarClick($event) {
    this.store.dispatch(new LayoutClickAction($event, Date.now()));
  }

  retryRefresh() {}
}
