import { State as AdminFaqListState } from "@client/reducers/admin-faq-list-reducer";
import { Selector } from "@ngrx/store";
import { FaqInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminFaqListState, boolean> = (state: AdminFaqListState) => state.isActive;
export const getMessage: Selector<AdminFaqListState, string | null> = (state: AdminFaqListState) => state.message;
export const getHasError: Selector<AdminFaqListState, boolean> = (state: AdminFaqListState) => state.hasError;
export const getHasSuccess: Selector<AdminFaqListState, boolean> = (state: AdminFaqListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminFaqListState, boolean> = (state: AdminFaqListState) => state.isLoading;
export const getCollection: Selector<AdminFaqListState, FaqInfo[]> = (state: AdminFaqListState) => state.collection;
export const getSelectedFaqId: Selector<AdminFaqListState, string> = (state: AdminFaqListState) => state.selectedFaqId;
