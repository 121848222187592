import { State as ForgotPasswordState } from "@client/reducers/forgot-password-reducer";
import { Selector } from "@ngrx/store";
import { ResetPasswordForm, ForgotPasswordForm } from "@client/utils/shared-constants";
export const getMessage: Selector<ForgotPasswordState, string | null> = (state: ForgotPasswordState) => state.message;
export const getHasError: Selector<ForgotPasswordState, boolean> = (state: ForgotPasswordState) => state.hasError;
export const getHasSuccess: Selector<ForgotPasswordState, boolean> = (state: ForgotPasswordState) => state.hasSuccess;
export const getIsActive: Selector<ForgotPasswordState, boolean> = (state: ForgotPasswordState) => state.isActive;
export const getIsLoading: Selector<ForgotPasswordState, boolean> = (state: ForgotPasswordState) => state.isLoading;
export const getIsCompleted: Selector<ForgotPasswordState, boolean> = (state: ForgotPasswordState) => state.isCompleted;
export const getIsResent: Selector<ForgotPasswordState, boolean> = (state: ForgotPasswordState) => state.isResent;
export const getFormReset: Selector<ForgotPasswordState, ResetPasswordForm> = (state: ForgotPasswordState) => state.form_reset;
export const getFormResetConfirmPassword: Selector<ForgotPasswordState, string> = (state: ForgotPasswordState) => getFormReset(state) && getFormReset(state).confirm_password;
export const getFormResetPassword: Selector<ForgotPasswordState, string> = (state: ForgotPasswordState) => getFormReset(state) && getFormReset(state).password;
export const getForm: Selector<ForgotPasswordState, ForgotPasswordForm> = (state: ForgotPasswordState) => state.form;
export const getFormEmail: Selector<ForgotPasswordState, string> = (state: ForgotPasswordState) => getForm(state) && getForm(state).email;
