import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { Vehicule } from "@client/utils/shared-constants";
import { State } from "@client/reducers/vehicles-form-reducer";
import { getVehiclesFormIsActive, getVehiclesFormHasSuccess, getVehiclesFormHasError, getVehiclesFormIsLoading, getVehiclesFormMessage, getVehiclesFormIsEdit, getVehiclesFormSelectedId, getVehiclesFormForm, getVehiclesFormFormDescription, getVehiclesFormFormId, getVehiclesFormFormKey, getVehiclesFormFormLicencePlate, getVehiclesFormFormManufacturer, getVehiclesFormFormModel, getVehiclesFormFormVehiculeYear, getVehiclesFormValidated, getVehiclesFormIsValidationRequired } from "@client/selectors";
/*test*/ 
@Injectable()
export class VehiclesFormStore extends BaseStore {
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormIsActive));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormHasSuccess));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormHasError));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormIsLoading));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getVehiclesFormMessage));
    }
    get isEdit(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormIsEdit));
    }
    get selectedId(): Observable<string | null> {
        return this.store.pipe(select(getVehiclesFormSelectedId));
    }
    get form(): Observable<Vehicule> {
        return this.store.pipe(select(getVehiclesFormForm));
    }
    get formDescription(): Observable<string> {
        return this.store.pipe(select(getVehiclesFormFormDescription));
    }
    get formId(): Observable<number> {
        return this.store.pipe(select(getVehiclesFormFormId));
    }
    get formKey(): Observable<string> {
        return this.store.pipe(select(getVehiclesFormFormKey));
    }
    get formLicencePlate(): Observable<string> {
        return this.store.pipe(select(getVehiclesFormFormLicencePlate));
    }
    get formManufacturer(): Observable<string> {
        return this.store.pipe(select(getVehiclesFormFormManufacturer));
    }
    get formModel(): Observable<string> {
        return this.store.pipe(select(getVehiclesFormFormModel));
    }
    get formVehiculeYear(): Observable<string> {
        return this.store.pipe(select(getVehiclesFormFormVehiculeYear));
    }
    get validated(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormValidated));
    }
    get isValidationRequired(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesFormIsValidationRequired));
    }
}
