import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ContractComponent } from '@client/modules/contract/contract.component';
import { ContractCancelComponent } from '@client/modules/contract/pages/contract-cancel.component';
import { ContractViewComponent } from '@client/modules/contract/pages/contract-view.component';

import { ParksModule } from '@client/modules/parks';
import { PaymentModule } from '@client/modules/payment';
import { ServicesModule } from '@client/modules/services';
import { SubscriptionModule } from '@client/modules/subscription';
import { UserModule } from '@client/modules/user';
import { UtilsModule } from '@client/modules/utils';
import { VehiclesModule } from '@client/modules/vehicles';
import { FormComponent } from '@client/shared/components/form';
import { FormModule } from '@client/shared/components/forms';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { NgxMdModule } from 'ngx-md';
import { ContractBillingDetailsComponent, ContractCreateNavComponent, ContractHeaderComponent, ContractSearchFormComponent, ContractSummaryComponent, ContractTopMenuComponent, SubscriptionDetailsComponent } from './components';
import {
  ContractConditionsPaymentComponent,
  ContractConditionsServiceComponent,
  ContractCreateComponent,
  ContractCreatePreviewComponent,
  ContractDetailsComponent,
  ContractImportComponent,
  ContractPayComponent,
  ContractSelectParkComponent,
  ContractSelectPaymentComponent,
  ContractSelectServiceComponent,
  ContractSummaryComponent as ContractSummaryComponentB,
  ContractUpdateSubscriptionComponent,
  ContractUpdateVehiclesComponent
} from './pages';
import {LayoutModule} from "@progress/kendo-angular-layout";

@NgModule({
  imports: [
    SharedCommonModule,
    HttpClientModule,
    ParksModule,
    ServicesModule,
    SharedComponentsModule,
    TranslateModule,
    DropDownsModule,
    //  RouterModule.forChild(routesContract),
    SubscriptionModule,
    VehiclesModule,
    PaymentModule,
    UserModule,
    DateInputsModule,
    IntlModule,
    SharedCommonModule,
    UtilsModule,
    NgxMdModule,
    FormModule,
    SharedFormModule,
    LayoutModule
  ],
  declarations: [
    ContractComponent,
    ContractDetailsComponent,
    ContractImportComponent,
    ContractSearchFormComponent,
    ContractViewComponent,
    ContractConditionsServiceComponent,
    ContractConditionsPaymentComponent,
    ContractCreateComponent,
    ContractSummaryComponent,
    ContractSummaryComponentB,
    SubscriptionDetailsComponent,
    ContractCancelComponent,
    ContractPayComponent,
    ContractHeaderComponent,
    ContractTopMenuComponent,
    ContractBillingDetailsComponent,
    ContractCreateNavComponent,
    ContractSelectParkComponent,
    ContractSelectServiceComponent,
    ContractSelectPaymentComponent,
    ContractUpdateSubscriptionComponent,
    ContractUpdateVehiclesComponent,
    ContractCreatePreviewComponent
  ],
  exports: [
    ContractComponent,
    ContractDetailsComponent,
    ContractImportComponent,
    ContractSearchFormComponent,
    ContractViewComponent,
    ContractConditionsServiceComponent,
    ContractConditionsPaymentComponent,
    ContractCreateComponent,
    ContractSummaryComponent,
    ContractSummaryComponentB,
    SubscriptionDetailsComponent,
    ContractCancelComponent,
    ContractPayComponent,
    ContractHeaderComponent,
    ContractTopMenuComponent
  ]
})
export class ContractModule {
  static forRoot(): ModuleWithProviders<ContractModule> {
    return {
      ngModule: ContractModule,
      providers: [FormComponent]
    };
  }
}
