import { State as TestGroupListState } from "@client/reducers/test-group-list-reducer";
import { Selector } from "@ngrx/store";
import { TestGroupInfo, TestInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<TestGroupListState, boolean> = (state: TestGroupListState) => state.isActive;
export const getMessage: Selector<TestGroupListState, string | null> = (state: TestGroupListState) => state.message;
export const getHasError: Selector<TestGroupListState, boolean> = (state: TestGroupListState) => state.hasError;
export const getHasSuccess: Selector<TestGroupListState, boolean> = (state: TestGroupListState) => state.hasSuccess;
export const getIsLoading: Selector<TestGroupListState, boolean> = (state: TestGroupListState) => state.isLoading;
export const getData: Selector<TestGroupListState, TestGroupInfo[]> = (state: TestGroupListState) => state.data;
export const getSelecteGroup: Selector<TestGroupListState, TestGroupInfo | null> = (state: TestGroupListState) => state.selecteGroup;
export const getSelecteGroupId: Selector<TestGroupListState, string> = (state: TestGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).id;
export const getSelecteGroupName: Selector<TestGroupListState, string> = (state: TestGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).name;
export const getSelecteGroupTests: Selector<TestGroupListState, any> = (state: TestGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).tests;
