import { AUTH } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, EmailEditForm } from "@client/utils/shared-constants";
export interface State {
    applyActionCodePending: boolean;
    checkActionCodePending: boolean;
    confirmPasswordResetPending: boolean;
    createUserWithEmailAndPasswordPending: boolean;
    getRedirectResultPending: boolean;
    sendPasswordResetEmailPending: boolean;
    sendSignInLinkToEmailPending: boolean;
    setPersistencePending: boolean;
    signInAndRetrieveDataWithCredentialPending: boolean;
    signInAnonymouslyPending: boolean;
    signInWithCustomTokenPending: boolean;
    signInWithEmailAndPasswordPending: boolean;
    signInWithEmailLinkPending: boolean;
    signInWithPhoneNumberPending: boolean;
    signInWithPopupPending: boolean;
    signInWithRedirectPending: boolean;
    updateCurrentUserPending: boolean;
    verifyPasswordResetCodePending: boolean;
    signOutPending: boolean;
}
export const initialState: State = {
    applyActionCodePending: false,
    checkActionCodePending: false,
    confirmPasswordResetPending: false,
    createUserWithEmailAndPasswordPending: false,
    getRedirectResultPending: false,
    sendPasswordResetEmailPending: false,
    sendSignInLinkToEmailPending: false,
    setPersistencePending: false,
    signInAndRetrieveDataWithCredentialPending: false,
    signInAnonymouslyPending: false,
    signInWithCustomTokenPending: false,
    signInWithEmailAndPasswordPending: false,
    signInWithEmailLinkPending: false,
    signInWithPhoneNumberPending: false,
    signInWithPopupPending: false,
    signInWithRedirectPending: false,
    updateCurrentUserPending: false,
    verifyPasswordResetCodePending: false,
    signOutPending: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case AUTH.APPLY_ACTION_CODE: return authApplyActionCode(state, action.payload);
        case AUTH.APPLY_ACTION_CODE_SUCCESS: return authApplyActionCodeSuccess(state, action.payload);
        case AUTH.APPLY_ACTION_CODE_FAILURE: return authApplyActionCodeFailure(state, action.payload);
        case AUTH.CHECK_ACTION_CODE: return authCheckActionCode(state, action.payload);
        case AUTH.CHECK_ACTION_CODE_SUCCESS: return authCheckActionCodeSuccess(state, action.payload);
        case AUTH.CHECK_ACTION_CODE_FAILURE: return authCheckActionCodeFailure(state, action.payload);
        case AUTH.CONFIRM_PASSWORD_RESET: return authConfirmPasswordReset(state, action.payload);
        case AUTH.CONFIRM_PASSWORD_RESET_SUCCESS: return authConfirmPasswordResetSuccess(state, action.payload);
        case AUTH.CONFIRM_PASSWORD_RESET_FAILURE: return authConfirmPasswordResetFailure(state, action.payload);
        case AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD: return authCreateUserWithEmailAndPassword(state, action.payload);
        case AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS: return authCreateUserWithEmailAndPasswordSuccess(state, action.payload);
        case AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE: return authCreateUserWithEmailAndPasswordFailure(state, action.payload);
        case AUTH.GET_REDIRECT_RESULT: return authGetRedirectResult(state);
        case AUTH.GET_REDIRECT_RESULT_SUCCESS: return authGetRedirectResultSuccess(state);
        case AUTH.GET_REDIRECT_RESULT_FAILURE: return authGetRedirectResultFailure(state, action.payload);
        case AUTH.IS_SIGN_IN_WITH_EMAIL_LINK: return authIsSignInWithEmailLink(state, action.payload);
        case AUTH.ON_AUTH_STATE_CHANGED: return authOnAuthStateChanged(state);
        case AUTH.ON_ID_TOKEN_CHANGED: return authOnIdTokenChanged(state);
        case AUTH.SEND_PASSWORD_RESET_EMAIL: return authSendPasswordResetEmail(state, action.payload);
        case AUTH.SEND_PASSWORD_RESET_EMAIL_SUCCESS: return authSendPasswordResetEmailSuccess(state, action.payload);
        case AUTH.SEND_PASSWORD_RESET_EMAIL_FAILURE: return authSendPasswordResetEmailFailure(state, action.payload);
        case AUTH.SEND_SIGN_IN_LINK_TO_EMAIL: return authSendSignInLinkToEmail(state, action.payload);
        case AUTH.SEND_SIGN_IN_LINK_TO_EMAIL_SUCCESS: return authSendSignInLinkToEmailSuccess(state, action.payload);
        case AUTH.SEND_SIGN_IN_LINK_TO_EMAIL_FAILURE: return authSendSignInLinkToEmailFailure(state, action.payload);
        case AUTH.SET_PERSISTENCE: return authSetPersistence(state, action.payload);
        case AUTH.SET_PERSISTENCE_SUCCESS: return authSetPersistenceSuccess(state, action.payload);
        case AUTH.SET_PERSISTENCE_FAILURE: return authSetPersistenceFailure(state, action.payload);
        case AUTH.SIGN_IN_AND_RETRIEVE_DATA_WITH_CREDENTIAL: return authSignInAndRetrieveDataWithCredential(state, action.payload);
        case AUTH.SIGN_IN_AND_RETRIEVE_DATA_WITH_CREDENTIAL_SUCCESS: return authSignInAndRetrieveDataWithCredentialSuccess(state, action.payload);
        case AUTH.SIGN_IN_AND_RETRIEVE_DATA_WITH_CREDENTIAL_FAILURE: return authSignInAndRetrieveDataWithCredentialFailure(state, action.payload);
        case AUTH.SIGN_IN_ANONYMOUSLY: return authSignInAnonymously(state);
        case AUTH.SIGN_IN_ANONYMOUSLY_SUCCESS: return authSignInAnonymouslySuccess(state, action.payload);
        case AUTH.SIGN_IN_ANONYMOUSLY_FAILURE: return authSignInAnonymouslyFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_CUSTOM_TOKEN: return authSignInWithCustomToken(state, action.payload);
        case AUTH.SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS: return authSignInWithCustomTokenSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE: return authSignInWithCustomTokenFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD: return authSignInWithEmailAndPassword(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS: return authSignInWithEmailAndPasswordSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE: return authSignInWithEmailAndPasswordFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_LINK: return authSignInWithEmailLink(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_LINK_SUCCESS: return authSignInWithEmailLinkSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_LINK_FAILURE: return authSignInWithEmailLinkFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_PHONE_NUMBER: return authSignInWithPhoneNumber(state, action.payload);
        case AUTH.SIGN_IN_WITH_PHONE_NUMBER_SUCCESS: return authSignInWithPhoneNumberSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_PHONE_NUMBER_FAILURE: return authSignInWithPhoneNumberFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_POPUP: return authSignInWithPopup(state);
        case AUTH.SIGN_IN_WITH_POPUP_SUCCESS: return authSignInWithPopupSuccess(state);
        case AUTH.SIGN_IN_WITH_POPUP_FAILURE: return authSignInWithPopupFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_REDIRECT: return authSignInWithRedirect(state, action.payload);
        case AUTH.SIGN_IN_WITH_REDIRECT_SUCCESS: return authSignInWithRedirectSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_REDIRECT_FAILURE: return authSignInWithRedirectFailure(state, action.payload);
        case AUTH.SIGN_OUT: return authSignOut(state);
        case AUTH.SIGN_OUT_SUCCESS: return authSignOutSuccess(state);
        case AUTH.SIGN_OUT_FAILURE: return authSignOutFailure(state, action.payload);
        case AUTH.UPDATE_CURRENT_USER: return authUpdateCurrentUser(state, action.payload);
        case AUTH.UPDATE_CURRENT_USER_SUCCESS: return authUpdateCurrentUserSuccess(state, action.payload);
        case AUTH.UPDATE_CURRENT_USER_FAILURE: return authUpdateCurrentUserFailure(state, action.payload);
        case AUTH.VERIFY_PASSWORD_RESET_CODE: return authVerifyPasswordResetCode(state, action.payload);
        case AUTH.VERIFY_PASSWORD_RESET_CODE_SUCCESS: return authVerifyPasswordResetCodeSuccess(state, action.payload);
        case AUTH.VERIFY_PASSWORD_RESET_CODE_FAILURE: return authVerifyPasswordResetCodeFailure(state, action.payload);
        case AUTH.RELOAD: return authReload(state);
        case AUTH.RELOAD_SUCCESS: return authReloadSuccess(state, action.payload);
        case AUTH.RELOAD_FAILURE: return authReloadFailure(state, action.payload);
        case AUTH.INIT: return authInit(state, action.payload);
        case AUTH.DESTROY: return authDestroy(state);
        case AUTH.AFTER_INIT: return authAfterInit(state, action.payload);
        case AUTH.CLICK_BACK_LINK: return authClickBackLink(state);
        case AUTH.LOGIN: return authLogin(state, action.payload);
        case AUTH.LOGIN_SUCCESS: return authLoginSuccess(state, action.payload);
        case AUTH.LOGIN_FAILURE: return authLoginFailure(state, action.payload);
        case AUTH.LOGOUT: return authLogout(state);
        case AUTH.LOGOUT_SUCCESS: return authLogoutSuccess(state);
        case AUTH.LOGOUT_FAILURE: return authLogoutFailure(state, action.payload);
        case AUTH.FORGOT_PASSWORD: return authForgotPassword(state, action.payload);
        case AUTH.FORGOT_PASSWORD_SUCCESS: return authForgotPasswordSuccess(state);
        case AUTH.FORGOT_PASSWORD_FAILURE: return authForgotPasswordFailure(state, action.payload);
        case AUTH.RESET_PASSWORD_UPDATE: return authResetPasswordUpdate(state, action.payload);
        case AUTH.RESET_PASSWORD_UPDATE_SUCCESS: return authResetPasswordUpdateSuccess(state, action.payload);
        case AUTH.RESET_PASSWORD_UPDATE_FAILURE: return authResetPasswordUpdateFailure(state, action.payload);
        case AUTH.VALIDATE_CODE: return authValidateCode(state, action.payload);
        case AUTH.VALIDATE_CODE_SUCCESS: return authValidateCodeSuccess(state, action.payload);
        case AUTH.VALIDATE_CODE_FAILURE: return authValidateCodeFailure(state, action.payload);
        case AUTH.REGISTER_EMAIL: return authRegisterEmail(state, action.payload);
        case AUTH.REGISTER_EMAIL_SUCCESS: return authRegisterEmailSuccess(state, action.payload);
        case AUTH.REGISTER_EMAIL_FAILURE: return authRegisterEmailFailure(state, action.payload);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK: return authSendEmailVerificationLink(state);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK_SUCCESS: return authSendEmailVerificationLinkSuccess(state);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK_FAILURE: return authSendEmailVerificationLinkFailure(state, action.payload);
        case AUTH.VALIDATE_EMAIL_CODE: return authValidateEmailCode(state, action.payload);
        case AUTH.VALIDATE_EMAIL_CODE_SUCCESS: return authValidateEmailCodeSuccess(state, action.payload);
        case AUTH.VALIDATE_EMAIL_CODE_FAILURE: return authValidateEmailCodeFailure(state, action.payload);
        case AUTH.STATE_SUCCESS: return authStateSuccess(state, action.payload);
        case AUTH.STATE_FAILURE: return authStateFailure(state);
        case AUTH.READY: return authReady(state);
        case AUTH.UPDATE_PASSWORD: return authUpdatePassword(state, action.payload);
        case AUTH.UPDATE_PASSWORD_SUCCESS: return authUpdatePasswordSuccess(state, action.payload);
        case AUTH.UPDATE_PASSWORD_FAILURE: return authUpdatePasswordFailure(state, action.payload);
        case AUTH.UPDATE_EMAIL: return authUpdateEmail(state, action.payload);
        case AUTH.UPDATE_EMAIL_SUCCESS: return authUpdateEmailSuccess(state, action.payload);
        case AUTH.UPDATE_EMAIL_FAILURE: return authUpdateEmailFailure(state, action.payload);
        default: return state;
    }
}
export function authApplyActionCode(state: State, payload: string): State {
    return { ...state, ...initialState, applyActionCodePending: true };
}
export function authApplyActionCodeSuccess(state: State, payload: string): State {
    return { ...state, ...initialState, applyActionCodePending: false };
}
export function authApplyActionCodeFailure(state: State, payload: any): State {
    return { ...state, ...initialState, applyActionCodePending: false };
}
export function authCheckActionCode(state: State, payload: ActionCodeInfo): State {
    return { ...state, ...initialState, checkActionCodePending: true };
}
export function authCheckActionCodeSuccess(state: State, payload: ActionCodeInfo): State {
    return { ...state, ...initialState, checkActionCodePending: false };
}
export function authCheckActionCodeFailure(state: State, payload: any): State {
    return { ...state, ...initialState, checkActionCodePending: false };
}
export function authConfirmPasswordReset(state: State, payload: any): State {
    return { ...state, ...initialState, confirmPasswordResetPending: true };
}
export function authConfirmPasswordResetSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, confirmPasswordResetPending: false };
}
export function authConfirmPasswordResetFailure(state: State, payload: any): State {
    return { ...state, ...initialState, confirmPasswordResetPending: false };
}
export function authCreateUserWithEmailAndPassword(state: State, payload: RegisterEmailForm): State {
    return { ...state, ...initialState, createUserWithEmailAndPasswordPending: true };
}
export function authCreateUserWithEmailAndPasswordSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, createUserWithEmailAndPasswordPending: false };
}
export function authCreateUserWithEmailAndPasswordFailure(state: State, payload: any): State {
    return { ...state, ...initialState, createUserWithEmailAndPasswordPending: false };
}
export function authGetRedirectResult(state: State): State {
    return state;
}
export function authGetRedirectResultSuccess(state: State): State {
    return state;
}
export function authGetRedirectResultFailure(state: State, payload: string): State {
    return { ...state, ...initialState, getRedirectResultPending: true };
}
export function authIsSignInWithEmailLink(state: State, payload: string): State {
    return { ...state, ...initialState };
}
export function authOnAuthStateChanged(state: State): State {
    return state;
}
export function authOnIdTokenChanged(state: State): State {
    return state;
}
export function authSendPasswordResetEmail(state: State, payload: any): State {
    return { ...state, ...initialState, sendPasswordResetEmailPending: true };
}
export function authSendPasswordResetEmailSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, sendPasswordResetEmailPending: false };
}
export function authSendPasswordResetEmailFailure(state: State, payload: any): State {
    return { ...state, ...initialState, sendPasswordResetEmailPending: false };
}
export function authSendSignInLinkToEmail(state: State, payload: any): State {
    return { ...state, ...initialState, sendSignInLinkToEmailPending: true };
}
export function authSendSignInLinkToEmailSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, sendSignInLinkToEmailPending: false };
}
export function authSendSignInLinkToEmailFailure(state: State, payload: any): State {
    return { ...state, ...initialState, sendSignInLinkToEmailPending: false };
}
export function authSetPersistence(state: State, payload: string): State {
    return { ...state, ...initialState, setPersistencePending: true };
}
export function authSetPersistenceSuccess(state: State, payload: string): State {
    return { ...state, ...initialState, setPersistencePending: false };
}
export function authSetPersistenceFailure(state: State, payload: any): State {
    return { ...state, ...initialState, setPersistencePending: false };
}
export function authSignInAndRetrieveDataWithCredential(state: State, payload: AuthCredential): State {
    return { ...state, ...initialState, signInAndRetrieveDataWithCredentialPending: true };
}
export function authSignInAndRetrieveDataWithCredentialSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInAndRetrieveDataWithCredentialPending: false };
}
export function authSignInAndRetrieveDataWithCredentialFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInAndRetrieveDataWithCredentialPending: false };
}
export function authSignInAnonymously(state: State): State {
    return { ...state, ...initialState, signInAnonymouslyPending: true };
}
export function authSignInAnonymouslySuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInAnonymouslyPending: false };
}
export function authSignInAnonymouslyFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInAnonymouslyPending: false };
}
export function authSignInWithCustomToken(state: State, payload: string): State {
    return { ...state, ...initialState, signInWithCustomTokenPending: true };
}
export function authSignInWithCustomTokenSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithCustomTokenPending: false };
}
export function authSignInWithCustomTokenFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithCustomTokenPending: false };
}
export function authSignInWithEmailAndPassword(state: State, payload: LoginForm): State {
    return { ...state, ...initialState, signInWithEmailAndPasswordPending: true };
}
export function authSignInWithEmailAndPasswordSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithEmailAndPasswordPending: false };
}
export function authSignInWithEmailAndPasswordFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithEmailAndPasswordPending: false };
}
export function authSignInWithEmailLink(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithEmailLinkPending: true };
}
export function authSignInWithEmailLinkSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithEmailLinkPending: false };
}
export function authSignInWithEmailLinkFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithEmailLinkPending: false };
}
export function authSignInWithPhoneNumber(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithPhoneNumberPending: true };
}
export function authSignInWithPhoneNumberSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithPhoneNumberPending: false };
}
export function authSignInWithPhoneNumberFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithPhoneNumberPending: false };
}
export function authSignInWithPopup(state: State): State {
    return { ...state, ...initialState, signInWithPopupPending: true };
}
export function authSignInWithPopupSuccess(state: State): State {
    return { ...state, ...initialState, signInWithPopupPending: false };
}
export function authSignInWithPopupFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithPopupPending: false };
}
export function authSignInWithRedirect(state: State, payload: string): State {
    return { ...state, ...initialState, signInWithRedirectPending: true };
}
export function authSignInWithRedirectSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithRedirectPending: false };
}
export function authSignInWithRedirectFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signInWithRedirectPending: false };
}
export function authSignOut(state: State): State {
    return { ...state, ...initialState, signOutPending: true };
}
export function authSignOutSuccess(state: State): State {
    return { ...state, ...initialState, signOutPending: false };
}
export function authSignOutFailure(state: State, payload: any): State {
    return { ...state, ...initialState, signOutPending: false };
}
export function authUpdateCurrentUser(state: State, payload: any): State {
    return { ...state, ...initialState, updateCurrentUserPending: true };
}
export function authUpdateCurrentUserSuccess(state: State, payload: any): State {
    return { ...state, ...initialState, updateCurrentUserPending: false };
}
export function authUpdateCurrentUserFailure(state: State, payload: any): State {
    return { ...state, ...initialState, updateCurrentUserPending: false };
}
export function authVerifyPasswordResetCode(state: State, payload: string): State {
    return { ...state, ...initialState, verifyPasswordResetCodePending: true };
}
export function authVerifyPasswordResetCodeSuccess(state: State, payload: string): State {
    return { ...state, ...initialState, verifyPasswordResetCodePending: false };
}
export function authVerifyPasswordResetCodeFailure(state: State, payload: any): State {
    return { ...state, ...initialState, verifyPasswordResetCodePending: false };
}
export function authReload(state: State): State {
    return { ...state, ...initialState, verifyPasswordResetCodePending: false };
}
export function authReloadSuccess(state: State, payload: User): State {
    return { ...state, ...initialState };
}
export function authReloadFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authInit(state: State, payload: AuthCodeInfo): State {
    return { ...state, ...initialState };
}
export function authDestroy(state: State): State {
    return state;
}
export function authAfterInit(state: State, payload: AuthCodeInfo): State {
    return { ...state, ...initialState };
}
export function authClickBackLink(state: State): State {
    return state;
}
export function authLogin(state: State, payload: LoginForm): State {
    return { ...state, ...initialState };
}
export function authLoginSuccess(state: State, payload: User): State {
    return { ...state, ...initialState };
}
export function authLoginFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authLogout(state: State): State {
    return state;
}
export function authLogoutSuccess(state: State): State {
    return state;
}
export function authLogoutFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authForgotPassword(state: State, payload: ForgotPasswordForm): State {
    return { ...state, ...initialState };
}
export function authForgotPasswordSuccess(state: State): State {
    return state;
}
export function authForgotPasswordFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authResetPasswordUpdate(state: State, payload: ResetPasswordForm): State {
    return { ...state, ...initialState };
}
export function authResetPasswordUpdateSuccess(state: State, payload: ResetPasswordForm): State {
    return { ...state, ...initialState };
}
export function authResetPasswordUpdateFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authValidateCode(state: State, payload: AuthCodeInfo): State {
    return { ...state, ...initialState };
}
export function authValidateCodeSuccess(state: State, payload: AuthCodeInfo): State {
    return { ...state, ...initialState };
}
export function authValidateCodeFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authRegisterEmail(state: State, payload: RegisterEmailForm): State {
    return { ...state, ...initialState };
}
export function authRegisterEmailSuccess(state: State, payload: RegisterEmailForm): State {
    return { ...state, ...initialState };
}
export function authRegisterEmailFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authSendEmailVerificationLink(state: State): State {
    return state;
}
export function authSendEmailVerificationLinkSuccess(state: State): State {
    return state;
}
export function authSendEmailVerificationLinkFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authValidateEmailCode(state: State, payload: AuthCodeInfo): State {
    return { ...state, ...initialState };
}
export function authValidateEmailCodeSuccess(state: State, payload: AuthCodeInfo): State {
    return { ...state, ...initialState };
}
export function authValidateEmailCodeFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authStateSuccess(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authStateFailure(state: State): State {
    return state;
}
export function authReady(state: State): State {
    return state;
}
export function authUpdatePassword(state: State, payload: UpdatePasswordForm): State {
    return { ...state, ...initialState };
}
export function authUpdatePasswordSuccess(state: State, payload: UpdatePasswordForm): State {
    return { ...state, ...initialState };
}
export function authUpdatePasswordFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
export function authUpdateEmail(state: State, payload: EmailEditForm): State {
    return { ...state, ...initialState };
}
export function authUpdateEmailSuccess(state: State, payload: EmailEditForm): State {
    return { ...state, ...initialState };
}
export function authUpdateEmailFailure(state: State, payload: any): State {
    return { ...state, ...initialState };
}
