import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { Vehicule } from "@client/utils/shared-constants";
import { State } from "@client/reducers/vehicles-add-reducer";
import { getVehiclesAddHasSuccess, getVehiclesAddIsActive, getVehiclesAddIsCompleted, getVehiclesAddRedirectOnComplete, getVehiclesAddHasError, getVehiclesAddIsLoading, getVehiclesAddMessage, getVehiclesAddForm, getVehiclesAddFormDescription, getVehiclesAddFormId, getVehiclesAddFormKey, getVehiclesAddFormLicencePlate, getVehiclesAddFormManufacturer, getVehiclesAddFormModel, getVehiclesAddFormVehiculeYear } from "@client/selectors";
/*test*/ 
@Injectable()
export class VehiclesAddStore extends BaseStore {
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesAddHasSuccess));
    }
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesAddIsActive));
    }
    get isCompleted(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesAddIsCompleted));
    }
    get redirectOnComplete(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesAddRedirectOnComplete));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesAddHasError));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesAddIsLoading));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getVehiclesAddMessage));
    }
    get form(): Observable<Vehicule> {
        return this.store.pipe(select(getVehiclesAddForm));
    }
    get formDescription(): Observable<string> {
        return this.store.pipe(select(getVehiclesAddFormDescription));
    }
    get formId(): Observable<number> {
        return this.store.pipe(select(getVehiclesAddFormId));
    }
    get formKey(): Observable<string> {
        return this.store.pipe(select(getVehiclesAddFormKey));
    }
    get formLicencePlate(): Observable<string> {
        return this.store.pipe(select(getVehiclesAddFormLicencePlate));
    }
    get formManufacturer(): Observable<string> {
        return this.store.pipe(select(getVehiclesAddFormManufacturer));
    }
    get formModel(): Observable<string> {
        return this.store.pipe(select(getVehiclesAddFormModel));
    }
    get formVehiculeYear(): Observable<string> {
        return this.store.pipe(select(getVehiclesAddFormVehiculeYear));
    }
}
