import { AUTH, RESET_PASSWORD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ResetPasswordForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    form: ResetPasswordForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: false,
    isCompleted: false,
    form: {
        confirm_password: "",
        password: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case RESET_PASSWORD.INIT: return resetPasswordInit(state);
        case RESET_PASSWORD.DESTROY: return resetPasswordDestroy(state);
        case RESET_PASSWORD.CLICK_SUBMIT: return resetPasswordClickSubmit(state);
        case RESET_PASSWORD.CLICK_LOGIN: return resetPasswordClickLogin(state);
        case RESET_PASSWORD.FORM_UPDATE: return resetPasswordFormUpdate(state, action.payload);
        case RESET_PASSWORD.FORM_RESET: return resetPasswordFormReset(state);
        case AUTH.CONFIRM_PASSWORD_RESET: return authConfirmPasswordReset(state, action.payload);
        case AUTH.CONFIRM_PASSWORD_RESET_SUCCESS: return authConfirmPasswordResetSuccess(state, action.payload);
        case AUTH.CONFIRM_PASSWORD_RESET_FAILURE: return authConfirmPasswordResetFailure(state, action.payload);
        default: return state;
    }
}
export function resetPasswordInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function resetPasswordDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function resetPasswordClickSubmit(state: State): State {
    return state;
}
export function resetPasswordClickLogin(state: State): State {
    return state;
}
export function resetPasswordFormUpdate(state: State, payload: ResetPasswordForm): State {
    return { ...state, form: payload };
}
export function resetPasswordFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form,
    };
}
export function authConfirmPasswordReset(state: State, payload: any): State {
    return {
        ...state,
        hasError: false,
        hasSuccess: false,
        message: null,
        isLoading: true,
        isCompleted: false,
    };
}
export function authConfirmPasswordResetSuccess(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        isCompleted: true,
        hasSuccess: true,
    };
}
export function authConfirmPasswordResetFailure(state: State, payload: any): State {
    return {
        ...state,
        ...payload,
        isCompleted: false,
        hasError: true,
        message: payload,
    };
}
