import { ADMIN_LOGS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
}
export const initialState: State = {};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_LOGS.INIT: return adminLogsInit(state);
        case ADMIN_LOGS.DESTROY: return adminLogsDestroy(state);
        default: return state;
    }
}
export function adminLogsInit(state: State): State {
    return state;
}
export function adminLogsDestroy(state: State): State {
    return state;
}
