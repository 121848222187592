import * as moment from 'moment-timezone';
import 'moment/locale/fr-ca';
import {ServiceInfo, ServiceMetaInfo} from './shared-constants';
import {DurationInputArg2} from 'moment';

export enum TimeUnit {
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Counter = 'Counter'
}

export enum ServiceType {
  Private = 'Privé',
  PrivateWithApprobation = 'Privé avec approbation',
  Public = 'Public',
  PublicWithApprobation = 'Public avec approbation'
}

export enum ServiceStartRule {
  NotDefined = 'Inconnu',
  FirstDayOfNextMonth = 'Premier jour du prochain mois',
  SubscriptionDay = 'Jour de l\'inscription'
}

export enum PartialMonthCalculation {
  NotDefined = 'Inconnu',
  Ratio = 'Ratio',
  MiddleMonth = 'Middle Month'
}

export enum ServiceBillingType {
  Monthly = 'Mensuel',
  PerUse = 'À l\'usage',
  Decremental = '',
  Maintenance = '',
  LumpSum = 'Montant forfaitaire',
  Episodic = 'Épisodique',
  DAS = 'DAS'
}

export interface PaymentInfo {
  amount: number;
  contractNo: number;
  financialInstitution: string;
  id: number;
  invoicePayments: InvoicePaymentInfo[];
  noReference: string;
  paymentMethodDescription: string;
  processDate: string;
}

export interface InvoicePaymentInfo {
  invoiceAmount: number;
  invoiceDate: string;
  invoiceDescription: string;
  invoiceId: number;
  invoiceTax1: number;
  invoiceTax2: number;
  invoiceTotalAmount: number;
  paidAmount: number;
}

export class Service {
  get availableFrom() {
    return this._data.availableFrom;
  }

  get availableFromFormated() {
    return moment(this.availableFrom)
      .tz('America/Montreal')
      .format('LL');
  }

  get availableServiceId() {
    return this._data.availableServiceId;
  }

  get availableUntil() {
    return this._data.availableUntil;
  }

  get availableUntilFormated() {
    if (moment(this.availableUntil).get('year') === 9999) {
      return 'Indéterminée';
    }
    return moment(this.availableUntil)
      .tz('America/Montreal')
      .format('LL');
  }

  get cancelationNotice() {
    return this._data.cancelationNotice;
  }

  get cancelationNoticeTimeUnit() {
    return this._data.cancelationNoticeTimeUnit;
  }

  get cancelationRule() {
    if (!this.isCancelable) {
      return 'Impossible';
    }
    if (this.isCancelationFirstDayOfMonth) {
      return 'Premier jour du mois suivant';
    }
    return `${this._data.cancelationNotice} ${this._data.cancelationNoticeTimeUnit}`;
  }

  get duration() {
    return this._data.duration;
  }

  get durationTime() {
    return this.isFixedDuration ? `${this.duration} ${this.durationTimeUnit}` : 'Indéterminée';
  }

  get durationTimeUnit() {
    return this._data.durationTimeUnit;
  }

  get endDate() {
    const endDate = moment(this.onSaleUntil).tz('America/Montreal');

    return endDate;
  }

  get endDateFormatted() {
    if (this.period && this._data.serviceBillingType.toString() === 'LumpSum' && this.durationTimeUnit === 'Week') {
      return moment(this.period.endDateRaw).tz('America/Montreal').endOf('day').format('LL');
    }
    if (moment(this.endDate).get('year') === 9999) {
      return 'Indéterminée';
    }
    return this.isFixedDuration
      ? moment(this.endDate)
        .tz('America/Montreal')
        .format('LL')
      : 'Indéterminée';
  }

  get havePartialAmmount() {
    if (this.price === 0) {
      return false;
    }
    if (
      ((this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Monthly') || (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Episodic')) &&
      ((this.rawData.serviceStartRule && this.rawData.serviceStartRule.toString() === 'FirstDayOfNextMonth') || (this.rawData.serviceStartRule && this.rawData.serviceStartRule.toString() === 'FirstDayOfCurrentMonth'))
    ) {
      return false;
    }
    return true;
  }

  get isApprovable() {
    return (this.rawData.serviceType && this.rawData.serviceType.toString() === 'PublicWithApprobation') || (this.rawData.serviceType && this.rawData.serviceType.toString() === 'PrivateWithApprobation');
  }

  get isBankingCardPaymentAccepted() {
    return this._data.isBankingCardPaymentAccepted;
  }

  get isCancelable() {
    return this._data.isCancelable;
  }

  get isCancelationFirstDayOfMonth() {
    return this._data.isCancelationFirstDayOfMonth;
  }

  get isDAS() {
    return this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'DAS';
  }

  get isDasPaymentAccepted() {
    return this._data.isDasPaymentAccepted;
  }

  get isEpisodic() {
    return this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Episodic';
  }

  get isFinancialInstitutionPaymentAccepted() {
    return this._data.isFinancialInstitutionPaymentAccepted;
  }

  get isFixedDuration() {
    return this._data.isFixedDuration;
  }

  get isMonthly() {
    return this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Monthly';
  }

  get isLumpSum() {
    return this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'LumpSum';
  }

  get isPpaPaymentAccepted() {
    return this._data.isPpaPaymentAccepted;
  }

  get isPrivate() {
    return (this.rawData.serviceType && this.rawData.serviceType.toString() === 'Private') || (this.rawData.serviceType && this.rawData.serviceType.toString() === 'PrivateWithApprobation');
  }

  get isSubscriptionAvailable() {
    return this._data.isSubscriptionAvailable;
  }

  get isTax1Exempted() {
    return this._data.isTax1Exempted;
  }

  get isTax2Exempted() {
    return this._data.isTax2Exempted;
  }

  get isTax3Exempted() {
    return this._data.isTax3Exempted;
  }

  get meta(): ServiceMetaInfo {
    return this._data.meta || {note: null};
  }

  get onSaleFrom() {
    return this._data.onSaleFrom;
  }

  get onSaleFromFormated() {
    return moment(this.onSaleFrom)
      .tz('America/Montreal')
      .format('LL');
  }

  get onSaleUntil() {
    return this._data.onSaleUntil;
  }

  get onSaleUntilFormated() {
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'LumpSum' && this.durationTimeUnit === 'Week') {
      if (this.duration) {
        const durationTimeUnit: DurationInputArg2 = this.rawData.durationTimeUnit as DurationInputArg2;
        const duration: number = Number(this.rawData.duration);
        const end = moment(this.onSaleFrom)
          .clone()
          // @ts-ignore
          .add(duration, durationTimeUnit)
          .endOf('day').format('LL');

        return end;
      }
    }
    if (moment(this.onSaleUntil).get('year') === 9999) {
      return 'Indéterminée';
    }
    return moment(this.onSaleUntil)
      .tz('America/Montreal')
      .format('LL');
  }

  get parkIdentificationNo() {
    return this._data.parkIdentificationNo;
  }

  get partialEndDate() {
    if (this.period && this._data.serviceBillingType.toString() === 'LumpSum' && this.durationTimeUnit === 'Week') {
      return moment(this.period.endDateRaw).tz('America/Montreal').endOf('day');
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Monthly') {
      if (!this.isFixedDuration) {
        return moment(this.onSaleUntil).tz('America/Montreal');
        // return this.startDate.add(1, 'month').startOf('month').subtract(1, 'day').endOf('day').tz('America/Montreal');
        // return moment(this.onSaleUntil).tz('America/Montreal');
      } else {
        return this.startDate.add(1, 'month').startOf('month').subtract(1, 'day').endOf('day').tz('America/Montreal');
        // return moment(this.onSaleUntil).tz('America/Montreal');
      }
      //    return this.startDate.add(1, 'month').startOf('month').subtract(1, 'day').endOf('day').tz('America/Montreal');
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Episodic') {
      // if (this.period && this.durationTimeUnit === 'Week') {
      if (this.period) {
        return moment(this.period.endDateRaw).tz('America/Montreal').endOf('day');
      } else {
        return moment(this.rawData.onSaleUntil).tz('America/Montreal').endOf('day');
      }
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'LumpSum') {
      return moment(this.onSaleUntil).tz('America/Montreal');
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'DAS') {
      return moment(this.onSaleUntil).tz('America/Montreal');
    }
    return moment(this.onSaleUntil).tz('America/Montreal');
  }

  get partialEndDateFormatted() {
    return this.partialEndDate.format('LL');
  }

  get partialInvoiceToDate() {
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Monthly') {
      return this.startDate
        .clone()
        .add(1, 'month')
        .startOf('month')
        .subtract(1, 'day')
        .endOf('day')
        .tz('America/Montreal');
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Episodic') {
      return this.partialEndDate;
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'LumpSum') {
      if (this.period && this.durationTimeUnit === 'Week') {
        return moment(this.period.endDateRaw).tz('America/Montreal').endOf('day');
      } else {
        return moment(this.onSaleUntil).tz('America/Montreal');
      }
    }
    if (this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'DAS') {
      return moment(this.onSaleUntil).tz('America/Montreal');
    }
    return this.startDate;
  }

  get partialInvoiceToDateFormatter() {
    return this.partialInvoiceToDate.format('LL');
  }

  get partialMonthCalculation() {
    return this._data.partialMonthCalculation;
  }

  get partialPrice() {
    const currentDay = this.startDate.date();
    if (this.rawData && this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'LumpSum') {
      const availableFrom = moment(this.rawData.onSaleFrom).tz('America/Montreal');
      const availableUntil = moment(this.rawData.onSaleUntil).tz('America/Montreal');

      const countMonths = Math.ceil(availableUntil.endOf('month').diff(this.startDate.clone().startOf('month'), 'months', true));

      const duration = this.rawData.duration || 1;
      const amount = this.rawData.price || 0;

      const priceByMonth = amount / duration;

      const periodFrom = this.startDate.clone().startOf('months');

      if ((this.rawData.partialMonthCalculation && this.rawData.partialMonthCalculation.toString() === 'Ratio') || (this.rawData.partialMonthCalculation && this.rawData.partialMonthCalculation.toString() === 'MiddleMonth')) {

        if (this.period && this.durationTimeUnit === 'Week') {
          return parseFloat((this.price * this.period.months).toString()).toFixed(2);
        }
        let cost = Number(priceByMonth);

        const daysInMonth = this.startDate.daysInMonth();
        const daysToPay = daysInMonth - currentDay + 1;

        const total = countMonths * Number(priceByMonth);

        cost = total;
        console.log((countMonths * amount) / duration, total, this.rawData.availableServiceId);

        return parseFloat(cost.toString()).toFixed(2);
      }
      if (this.rawData.partialMonthCalculation && this.rawData.partialMonthCalculation.toString() === 'OldMiddleMonth') {
        let cost = 0;
        if (currentDay >= 15) {
          cost = Number((Number(priceByMonth) / 2).toFixed(2));
        } else {
          cost = Number(priceByMonth);
        }

        cost = Number(cost) + countMonths * Number(priceByMonth);

        return parseFloat(cost.toString()).toFixed(2);
      }

      return parseFloat(amount.toString()).toFixed(2);
    }
    if (this.rawData && this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Monthly') {
      let billingDate = moment(this.startDate);
      let ApplyMiddleMonth = false;
      if (this.rawData.partialMonthCalculation && this.rawData.partialMonthCalculation.toString() === 'MiddleMonth') {
        if (currentDay >= 1 && currentDay <= 14) {
          billingDate = billingDate.clone().add('days', currentDay * -1 + 1);
        } else {
          ApplyMiddleMonth = true;
          billingDate = billingDate.clone().add('days', currentDay * -1 + 15);
        }
      }
      const daysInMonth = billingDate.daysInMonth() || 0;
      const daysToPay = daysInMonth - currentDay + 1 || 0;

      let amount = (daysToPay / daysInMonth) * (this.rawData.price || 0);

      if (ApplyMiddleMonth) {
        amount = (this.rawData.price || 0) / 2;
      }

      return parseFloat(amount.toString()).toFixed(2);
    }
    if (this.rawData && this.rawData.serviceBillingType && this.rawData.serviceBillingType.toString() === 'Episodic') {
      if (this.period) {
        return parseFloat((this.price * this.period.months).toString()).toFixed(2);
      } else {
        const start = moment();
        if (this.serviceStartRule && this.serviceStartRule.toString() === 'FirstDayOfNextMonth') {
          start
            .endOf('month')
            .add(1, 'day')
            .startOf('day');
        }
        if (this.serviceStartRule && this.serviceStartRule.toString() === 'FirstDayOfCurrentMonth') {
          start.startOf('month');
        }

        const availableUntil = moment(this.rawData.onSaleUntil).tz('America/Montreal');

        const totalMonths = Math.ceil(availableUntil.diff(start, 'month', true));

        return parseFloat((this.price * totalMonths).toString()).toFixed(2);
      }
    }
    return '0.00';
  }

  get period() {
    return this._data.period;
  }

  get price(): number {
    return Number(parseFloat((this._data && this._data.price && this._data.price.toString()) || '0'));
  }

  get rawData() {
    return this._data || ({} as ServiceInfo);
  }

  get sectorId() {
    return this._data.sectorId;
  }

  get serviceBillingType() {
    if (this._data && this._data.serviceBillingType) {
      return ServiceBillingType[this._data && this._data.serviceBillingType];
    } else {
      return '';
    }
  }

  get serviceDescription() {
    return this._data.serviceDescription;
  }

  get serviceStartRule() {
    return this._data.serviceStartRule;
  }

  get serviceType() {
    return this._data.serviceType;
  }

  get startDate() {
    if (this.period && this._data.serviceBillingType.toString() === 'LumpSum' && this.durationTimeUnit === 'Week') {
      return moment(this.period.startDateRaw).tz('America/Montreal');
    }
    const now = moment(new Date(this.onSaleFrom).getTime() > this.today.toDate().getTime() ? this.onSaleFrom : this.today);

    if (this._data && this._data.serviceBillingType && this._data.serviceBillingType.toString() === 'Episodic') {
      if (this.period) {
        return moment(this.period.startDateRaw).tz('America/Montreal');
      }
    }
    if (this._data && this._data.serviceBillingType && this._data.serviceBillingType.toString() === 'Monthly') {
      if (this.period) {
        return moment(this.period.startDateRaw).tz('America/Montreal');
      }
    }
    if (this._data && this._data.serviceBillingType && this._data.serviceBillingType.toString() === 'DAS') {
      if (this.period) {
        return moment(this.period.startDateRaw).tz('America/Montreal');
      }
    }
    if (this._data && this._data.serviceStartRule && this._data.serviceStartRule.toString() === 'FirstDayOfNextMonth') {
      return now
        .clone()
        .add(1, 'months')
        .startOf('month')
        .tz('America/Montreal');
    }
    if (this._data && this._data.serviceStartRule && this._data.serviceStartRule.toString() === 'FirstDayOfCurrentMonth') {
      return now
        .clone()
        .startOf('month')
        .tz('America/Montreal');
    }
    return now.tz('America/Montreal');
  }

  get startDateFormatted() {
    if (this.period && this._data.serviceBillingType.toString() === 'LumpSum' && this.durationTimeUnit === 'Week') {
      return moment(this.period.startDateRaw).tz('America/Montreal');
    }
    return moment(this.startDate)
      .tz('America/Montreal')
      .format('LL');
  }

  today = moment()
    .startOf('day')
    .tz('America/Montreal');

  private _data: ServiceInfo = {
    availableServiceId: 0,
    cancelationNotice: 0,
    cancelationNoticeTimeUnit: TimeUnit.Month,
    duration: 0,
    durationTimeUnit: TimeUnit.Month,
    isBankingCardPaymentAccepted: false,
    isCancelable: false,
    isCancelationFirstDayOfMonth: false,
    isDasPaymentAccepted: false,
    isFinancialInstitutionPaymentAccepted: false,
    isFixedDuration: false,
    isPpaPaymentAccepted: false,
    isSubscriptionAvailable: false,
    isTax1Exempted: false,
    isTax2Exempted: false,
    isTax3Exempted: false,
    parkIdentificationNo: 0,
    partialMonthCalculation: 'NotDefined',
    price: 0,
    sectorId: 0,
    serviceBillingType: 'Monthly',
    serviceDescription: '',
    serviceStartRule: 'NotDefined',
    serviceType: 'Private',
    availableFrom: new Date().toISOString(),
    availableUntil: new Date().toISOString(),
    onSaleFrom: new Date().toISOString(),
    onSaleUntil: new Date().toISOString(),
    period: null,
    meta: null
  };

  constructor(data: ServiceInfo) {
    if (data) {
      Object.keys(data).forEach(k => {
        if (this._data[k] !== undefined) {
          this._data[k] = data[k];
        }
      });
    }
  }

  isSubscriptionDay() {
    if (this.serviceStartRule && this.serviceStartRule.toString() === 'SubscriptionDay') {
      return true;
    }
    return false;
  }

  getAvailablePeriods(ss: any = null) {
    const periods: { value: number; start: string; end: string; endDateRaw: string; startDateRaw: string; months: number }[] = [];

    const endAt = moment(this.onSaleUntil);
    const start = moment(ss ? ss.startDateRaw : this.startDate) || moment();
    if (this.serviceStartRule && this.serviceStartRule.toString() === 'FirstDayOfNextMonth') {
      if (start.toDate().getTime() < new Date().getTime()) {
        start
          .endOf('month')
          .add(1, 'day')
          .startOf('day');
      }
    }
    if (this.serviceStartRule && this.serviceStartRule.toString() === 'FirstDayOfCurrentMonth') {
      start.startOf('month');
    }

    let months = Math.ceil(endAt.diff(start, 'month', true));

    if (!ss && months >= 2) {
      if (this.startDate.toDate().getTime() < new Date().getTime()) {
        months = 3;
      } else {
        months = 2;
      }
    }

    if (endAt.year() === 9999) {
      console.log('xxxxxxxxxxxxxx', months);
    }
    for (let i = 0; i < Math.min(months, 120); i++) {
      let end =  start
        .clone()
        .add(i, 'months')
        .endOf('month');

      if (this.durationTimeUnit === 'Week') {
        const durationTimeUnit: DurationInputArg2 = this.rawData.durationTimeUnit as DurationInputArg2;
        const duration: number = Number((i + 1) * Number(this.rawData.duration));
        end = start
          .clone()
          // @ts-ignore
          .add(duration, durationTimeUnit)
          .endOf('day');
      }

      let s = start
        .clone()
        .add(i, 'months')
        .startOf('month');

      if (!i && this.serviceStartRule && this.serviceStartRule.toString() === 'SubscriptionDay') {
        s = moment().startOf('day');
      }

      const sss = (ss && start.toISOString()) || s.toISOString();
      periods.push({
        value: i,
        start: (ss && start.format('LL')) || s.format('LL'),
        end: end.format('LL'),
        months: moment(end).diff(moment(sss), 'months') + 1,
        endDateRaw: end.toISOString() as string,
        startDateRaw: sss as string
      });
    }
    if (!ss && periods.length && this.serviceType === 'PublicWithApprobation' || this.serviceType === 'PrivateWithApprobation') {
      //  return [{...(periods && periods[0] || {}), start: 'Jour de l\'approbation'}];
    }
    return periods.slice(0, 3);
  }

  set(name, value) {
    const newData = {...this._data};

    newData[name] = value;

    this._data = newData;
  }

  setToday(date) {
    this.today = moment(date)
      .startOf('day')
      .tz('America/Montreal');
  }
}
