import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Injectable()
export class AccessCodeExistsGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router, private route: ActivatedRoute) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasAccessCode(next);
  }

  hasAccessCode(next) {
    return of(true);
  }
}
