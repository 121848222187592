import { EventEmitter, Host, Injector, Input, OnInit, Optional, Output, SkipSelf, Directive } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormGroup, NgControl, ValidationErrors, Validator } from '@angular/forms';

@Directive()
export abstract class AbstractInput implements ControlValueAccessor, Validator, OnInit {
  private _disabled = false;

  get disabled() {
    return this._disabled;
  }

  @Input() set disabled(value) {
    this._disabled = value;
  }

  private _value = '';

  @Input()
  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.valueChange.emit(value);
  }

  get formIsValid() {
    if (!this.form) {
      return true;
    }
    return this.form.valid && (this.form.dirty || this.form.touched);
  }

  get isValid() {
    return !(this.control && this.control.invalid && (this.control.dirty || this.control.touched));
  }

  @Input() autofocus = false;
  control: NgControl;
  form: FormGroup;
  @Input() formControlName: string;
  @Input() name;
  onModelChange: (_: any) => {};
  onModelTouched: () => {};
  @Input() required = false;
  validatorFn: (_: any) => {};
  @Output() valueChange = new EventEmitter();

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    protected controlContainer: ControlContainer,
    private _injector: Injector
  ) {}

  ngOnInit() {
    if (this.formControlName) {
      this.control = this._injector.get(NgControl);
    }

    if (this.controlContainer) {
      if (this.formControlName) {
        this.controlContainer.statusChanges.subscribe(x => {
          // console.log(x);
        });
        this.form = this.controlContainer.control as FormGroup;

        //  this.control = this.controlContainer.control.get(this.formControlName);
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn("Can't find parent FormGroup directive");
    }
  }

  registerOnChange(fn) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnValidatorChange(fn) {
    // console.log(fn);
    this.validatorFn = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  updateModel(value?) {
    if (value && this.control && this.control.control) {
      this.control.control.markAsDirty();
      this.control.control.setValue(value);
    }
    if (this.onModelChange) {
      this.onModelChange(value || this._value);
    }
  }
}
