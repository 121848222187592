import { State as NetworkStatusState, reducer as networkStatusReducer } from "./network-status-reducer";
import { State as PopupState, reducer as popupReducer } from "./popup-reducer";
import { State as EmailEditState, reducer as emailEditReducer } from "./email-edit-reducer";
import { State as AuthState, reducer as authReducer } from "./auth-reducer";
import { State as OobState, reducer as oobReducer } from "./oob-reducer";
import { State as SessionState, reducer as sessionReducer } from "./session-reducer";
import { State as FaqState, reducer as faqReducer } from "./faq-reducer";
import { State as OrganizationState, reducer as organizationReducer } from "./organization-reducer";
import { State as SectorsState, reducer as sectorsReducer } from "./sectors-reducer";
import { State as ContractState, reducer as contractReducer } from "./contract-reducer";
import { State as ContractCancelState, reducer as contractCancelReducer } from "./contract-cancel-reducer";
import { State as ContractCreateState, reducer as contractCreateReducer } from "./contract-create-reducer";
import { State as ContractDetailsState, reducer as contractDetailsReducer } from "./contract-details-reducer";
import { State as ContractConditionsServiceState, reducer as contractConditionsServiceReducer } from "./contract-conditions-service-reducer";
import { State as ContractConditionsPaymentState, reducer as contractConditionsPaymentReducer } from "./contract-conditions-payment-reducer";
import { State as ParksState, reducer as parksReducer } from "./parks-reducer";
import { State as ParksSelectState, reducer as parksSelectReducer } from "./parks-select-reducer";
import { State as CalendarState, reducer as calendarReducer } from "./calendar-reducer";
import { State as LayoutState, reducer as layoutReducer } from "./layout-reducer";
import { State as ProfileState, reducer as profileReducer } from "./profile-reducer";
import { State as ProfileDetailsState, reducer as profileDetailsReducer } from "./profile-details-reducer";
import { State as ProfileEditState, reducer as profileEditReducer } from "./profile-edit-reducer";
import { State as SubscriptionState, reducer as subscriptionReducer } from "./subscription-reducer";
import { State as RegisterState, reducer as registerReducer } from "./register-reducer";
import { State as LoginState, reducer as loginReducer } from "./login-reducer";
import { State as MetadataState, reducer as metadataReducer } from "./metadata-reducer";
import { State as ContactState, reducer as contactReducer } from "./contact-reducer";
import { State as AccountStatusState, reducer as accountStatusReducer } from "./account-status-reducer";
import { State as RegisterEmailState, reducer as registerEmailReducer } from "./register-email-reducer";
import { State as SubscriptionDetailsState, reducer as subscriptionDetailsReducer } from "./subscription-details-reducer";
import { State as SubscriptionEditState, reducer as subscriptionEditReducer } from "./subscription-edit-reducer";
import { State as RegisterSubscriptionState, reducer as registerSubscriptionReducer } from "./register-subscription-reducer";
import { State as RegisterAccessCodeState, reducer as registerAccessCodeReducer } from "./register-access-code-reducer";
import { State as RegisterContractState, reducer as registerContractReducer } from "./register-contract-reducer";
import { State as RegisterPendingState, reducer as registerPendingReducer } from "./register-pending-reducer";
import { State as UpdatePasswordState, reducer as updatePasswordReducer } from "./update-password-reducer";
import { State as ResetPasswordState, reducer as resetPasswordReducer } from "./reset-password-reducer";
import { State as ForgotPasswordState, reducer as forgotPasswordReducer } from "./forgot-password-reducer";
import { State as UserState, reducer as userReducer } from "./user-reducer";
import { State as UserVehiclesState, reducer as userVehiclesReducer } from "./user-vehicles-reducer";
import { State as UserVehiclesDetailsState, reducer as userVehiclesDetailsReducer } from "./user-vehicles-details-reducer";
import { State as VehiclesFormState, reducer as vehiclesFormReducer } from "./vehicles-form-reducer";
import { State as VehiclesState, reducer as vehiclesReducer } from "./vehicles-reducer";
import { State as VehiclesDetailsState, reducer as vehiclesDetailsReducer } from "./vehicles-details-reducer";
import { State as VehiclesAddState, reducer as vehiclesAddReducer } from "./vehicles-add-reducer";
import { State as VehiclesEditState, reducer as vehiclesEditReducer } from "./vehicles-edit-reducer";
import { State as SettingsState, reducer as settingsReducer } from "./settings-reducer";
import { State as ServicesState, reducer as servicesReducer } from "./services-reducer";
import { State as PaymentSelectMethodState, reducer as paymentSelectMethodReducer } from "./payment-select-method-reducer";
import { State as ServicesSelectState, reducer as servicesSelectReducer } from "./services-select-reducer";
import { State as AccessCodeState, reducer as accessCodeReducer } from "./access-code-reducer";
import { State as ContractSearchState, reducer as contractSearchReducer } from "./contract-search-reducer";
import { State as AccessCodeDetailsState, reducer as accessCodeDetailsReducer } from "./access-code-details-reducer";
import { State as AccessCodeAddState, reducer as accessCodeAddReducer } from "./access-code-add-reducer";
import { State as AdminConfigurationState, reducer as adminConfigurationReducer } from "./admin-configuration-reducer";
import { State as AdminProfileState, reducer as adminProfileReducer } from "./admin-profile-reducer";
import { State as AdminLogsState, reducer as adminLogsReducer } from "./admin-logs-reducer";
import { State as AdminMailsState, reducer as adminMailsReducer } from "./admin-mails-reducer";
import { State as AdminOrganizationFormState, reducer as adminOrganizationFormReducer } from "./admin-organization-form-reducer";
import { State as TestState, reducer as testReducer } from "./test-reducer";
import { State as TestGroupFormState, reducer as testGroupFormReducer } from "./test-group-form-reducer";
import { State as TestListState, reducer as testListReducer } from "./test-list-reducer";
import { State as TestFormState, reducer as testFormReducer } from "./test-form-reducer";
import { State as TestGroupListState, reducer as testGroupListReducer } from "./test-group-list-reducer";
import { State as AdminState, reducer as adminReducer } from "./admin-reducer";
import { State as AdminOrganizationListState, reducer as adminOrganizationListReducer } from "./admin-organization-list-reducer";
import { State as AdminGroupFormState, reducer as adminGroupFormReducer } from "./admin-group-form-reducer";
import { State as AdminGroupMetasState, reducer as adminGroupMetasReducer } from "./admin-group-metas-reducer";
import { State as AdminGroupListState, reducer as adminGroupListReducer } from "./admin-group-list-reducer";
import { State as AdminContractListState, reducer as adminContractListReducer } from "./admin-contract-list-reducer";
import { State as AdminServiceListState, reducer as adminServiceListReducer } from "./admin-service-list-reducer";
import { State as AdminMetasListState, reducer as adminMetasListReducer } from "./admin-metas-list-reducer";
import { State as AdminNotesFormState, reducer as adminNotesFormReducer } from "./admin-notes-form-reducer";
import { State as AdminNotesListState, reducer as adminNotesListReducer } from "./admin-notes-list-reducer";
import { State as AdminAccessCodeSearchState, reducer as adminAccessCodeSearchReducer } from "./admin-access-code-search-reducer";
import { State as AdminUsersFormState, reducer as adminUsersFormReducer } from "./admin-users-form-reducer";
import { State as AdminUsersListState, reducer as adminUsersListReducer } from "./admin-users-list-reducer";
import { State as AdminCodeListState, reducer as adminCodeListReducer } from "./admin-code-list-reducer";
import { State as AdminMetasFormState, reducer as adminMetasFormReducer } from "./admin-metas-form-reducer";
import { State as AdminCodeImportState, reducer as adminCodeImportReducer } from "./admin-code-import-reducer";
import { State as AdminCodeFormState, reducer as adminCodeFormReducer } from "./admin-code-form-reducer";
import { State as AdminServiceFormState, reducer as adminServiceFormReducer } from "./admin-service-form-reducer";
import { State as AdminFaqListGroupState, reducer as adminFaqListGroupReducer } from "./admin-faq-list-group-reducer";
import { State as AdminFaqListState, reducer as adminFaqListReducer } from "./admin-faq-list-reducer";
import { State as AdminFaqGroupEditState, reducer as adminFaqGroupEditReducer } from "./admin-faq-group-edit-reducer";
import { State as AdminFaqEditState, reducer as adminFaqEditReducer } from "./admin-faq-edit-reducer";
import { State as PpaAddState, reducer as ppaAddReducer } from "./ppa-add-reducer";
import { State as MonerisPaypageState, reducer as monerisPaypageReducer } from "./moneris-paypage-reducer";
import { State as MonerisVaultState, reducer as monerisVaultReducer } from "./moneris-vault-reducer";
import { Action, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { RouterReducerState, routerReducer } from "@ngrx/router-store";
import { environment } from "../../configs/environment";
export interface RootState {
    __router: RouterReducerState;
    __networkStatus: NetworkStatusState;
    __popup: PopupState;
    __emailEdit: EmailEditState;
    __auth: AuthState;
    __oob: OobState;
    __session: SessionState;
    __faq: FaqState;
    __organization: OrganizationState;
    __sectors: SectorsState;
    __contract: ContractState;
    __contractCancel: ContractCancelState;
    __contractCreate: ContractCreateState;
    __contractDetails: ContractDetailsState;
    __contractConditionsService: ContractConditionsServiceState;
    __contractConditionsPayment: ContractConditionsPaymentState;
    __parks: ParksState;
    __parksSelect: ParksSelectState;
    __calendar: CalendarState;
    __layout: LayoutState;
    __profile: ProfileState;
    __profileDetails: ProfileDetailsState;
    __profileEdit: ProfileEditState;
    __subscription: SubscriptionState;
    __register: RegisterState;
    __login: LoginState;
    __metadata: MetadataState;
    __contact: ContactState;
    __accountStatus: AccountStatusState;
    __registerEmail: RegisterEmailState;
    __subscriptionDetails: SubscriptionDetailsState;
    __subscriptionEdit: SubscriptionEditState;
    __registerSubscription: RegisterSubscriptionState;
    __registerAccessCode: RegisterAccessCodeState;
    __registerContract: RegisterContractState;
    __registerPending: RegisterPendingState;
    __updatePassword: UpdatePasswordState;
    __resetPassword: ResetPasswordState;
    __forgotPassword: ForgotPasswordState;
    __user: UserState;
    __userVehicles: UserVehiclesState;
    __userVehiclesDetails: UserVehiclesDetailsState;
    __vehiclesForm: VehiclesFormState;
    __vehicles: VehiclesState;
    __vehiclesDetails: VehiclesDetailsState;
    __vehiclesAdd: VehiclesAddState;
    __vehiclesEdit: VehiclesEditState;
    __settings: SettingsState;
    __services: ServicesState;
    __paymentSelectMethod: PaymentSelectMethodState;
    __servicesSelect: ServicesSelectState;
    __accessCode: AccessCodeState;
    __contractSearch: ContractSearchState;
    __accessCodeDetails: AccessCodeDetailsState;
    __accessCodeAdd: AccessCodeAddState;
    __adminConfiguration: AdminConfigurationState;
    __adminProfile: AdminProfileState;
    __adminLogs: AdminLogsState;
    __adminMails: AdminMailsState;
    __adminOrganizationForm: AdminOrganizationFormState;
    __test: TestState;
    __testGroupForm: TestGroupFormState;
    __testList: TestListState;
    __testForm: TestFormState;
    __testGroupList: TestGroupListState;
    __admin: AdminState;
    __adminOrganizationList: AdminOrganizationListState;
    __adminGroupForm: AdminGroupFormState;
    __adminGroupMetas: AdminGroupMetasState;
    __adminGroupList: AdminGroupListState;
    __adminContractList: AdminContractListState;
    __adminServiceList: AdminServiceListState;
    __adminMetasList: AdminMetasListState;
    __adminNotesForm: AdminNotesFormState;
    __adminNotesList: AdminNotesListState;
    __adminAccessCodeSearch: AdminAccessCodeSearchState;
    __adminUsersForm: AdminUsersFormState;
    __adminUsersList: AdminUsersListState;
    __adminCodeList: AdminCodeListState;
    __adminMetasForm: AdminMetasFormState;
    __adminCodeImport: AdminCodeImportState;
    __adminCodeForm: AdminCodeFormState;
    __adminServiceForm: AdminServiceFormState;
    __adminFaqListGroup: AdminFaqListGroupState;
    __adminFaqList: AdminFaqListState;
    __adminFaqGroupEdit: AdminFaqGroupEditState;
    __adminFaqEdit: AdminFaqEditState;
    __ppaAdd: PpaAddState;
    __monerisPaypage: MonerisPaypageState;
    __monerisVault: MonerisVaultState;
}
export const reducers: ActionReducerMap<RootState, Action> = {
    __router: routerReducer,
    __networkStatus: networkStatusReducer,
    __popup: popupReducer,
    __emailEdit: emailEditReducer,
    __auth: authReducer,
    __oob: oobReducer,
    __session: sessionReducer,
    __faq: faqReducer,
    __organization: organizationReducer,
    __sectors: sectorsReducer,
    __contract: contractReducer,
    __contractCancel: contractCancelReducer,
    __contractCreate: contractCreateReducer,
    __contractDetails: contractDetailsReducer,
    __contractConditionsService: contractConditionsServiceReducer,
    __contractConditionsPayment: contractConditionsPaymentReducer,
    __parks: parksReducer,
    __parksSelect: parksSelectReducer,
    __calendar: calendarReducer,
    __layout: layoutReducer,
    __profile: profileReducer,
    __profileDetails: profileDetailsReducer,
    __profileEdit: profileEditReducer,
    __subscription: subscriptionReducer,
    __register: registerReducer,
    __login: loginReducer,
    __metadata: metadataReducer,
    __contact: contactReducer,
    __accountStatus: accountStatusReducer,
    __registerEmail: registerEmailReducer,
    __subscriptionDetails: subscriptionDetailsReducer,
    __subscriptionEdit: subscriptionEditReducer,
    __registerSubscription: registerSubscriptionReducer,
    __registerAccessCode: registerAccessCodeReducer,
    __registerContract: registerContractReducer,
    __registerPending: registerPendingReducer,
    __updatePassword: updatePasswordReducer,
    __resetPassword: resetPasswordReducer,
    __forgotPassword: forgotPasswordReducer,
    __user: userReducer,
    __userVehicles: userVehiclesReducer,
    __userVehiclesDetails: userVehiclesDetailsReducer,
    __vehiclesForm: vehiclesFormReducer,
    __vehicles: vehiclesReducer,
    __vehiclesDetails: vehiclesDetailsReducer,
    __vehiclesAdd: vehiclesAddReducer,
    __vehiclesEdit: vehiclesEditReducer,
    __settings: settingsReducer,
    __services: servicesReducer,
    __paymentSelectMethod: paymentSelectMethodReducer,
    __servicesSelect: servicesSelectReducer,
    __accessCode: accessCodeReducer,
    __contractSearch: contractSearchReducer,
    __accessCodeDetails: accessCodeDetailsReducer,
    __accessCodeAdd: accessCodeAddReducer,
    __adminConfiguration: adminConfigurationReducer,
    __adminProfile: adminProfileReducer,
    __adminLogs: adminLogsReducer,
    __adminMails: adminMailsReducer,
    __adminOrganizationForm: adminOrganizationFormReducer,
    __test: testReducer,
    __testGroupForm: testGroupFormReducer,
    __testList: testListReducer,
    __testForm: testFormReducer,
    __testGroupList: testGroupListReducer,
    __admin: adminReducer,
    __adminOrganizationList: adminOrganizationListReducer,
    __adminGroupForm: adminGroupFormReducer,
    __adminGroupMetas: adminGroupMetasReducer,
    __adminGroupList: adminGroupListReducer,
    __adminContractList: adminContractListReducer,
    __adminServiceList: adminServiceListReducer,
    __adminMetasList: adminMetasListReducer,
    __adminNotesForm: adminNotesFormReducer,
    __adminNotesList: adminNotesListReducer,
    __adminAccessCodeSearch: adminAccessCodeSearchReducer,
    __adminUsersForm: adminUsersFormReducer,
    __adminUsersList: adminUsersListReducer,
    __adminCodeList: adminCodeListReducer,
    __adminMetasForm: adminMetasFormReducer,
    __adminCodeImport: adminCodeImportReducer,
    __adminCodeForm: adminCodeFormReducer,
    __adminServiceForm: adminServiceFormReducer,
    __adminFaqListGroup: adminFaqListGroupReducer,
    __adminFaqList: adminFaqListReducer,
    __adminFaqGroupEdit: adminFaqGroupEditReducer,
    __adminFaqEdit: adminFaqEditReducer,
    __ppaAdd: ppaAddReducer,
    __monerisPaypage: monerisPaypageReducer,
    __monerisVault: monerisVaultReducer
};
export function logger(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
    return (state: RootState, action: any): any => {
        const result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.groupEnd();
        return result;
    };
}
export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [logger] : [];
