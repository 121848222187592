import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spaq-layout-account-main',
  templateUrl: './layout-account-main.component.html',
  styleUrls: ['./layout-account-main.component.scss']
})
export class LayoutAccountMainComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
