import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { REGION } from '@angular/fire/functions';
import { AccessCodeService } from '@client/core/services/access-code.service';
import { AccountStatusService } from '@client/core/services/account-status.service';
import { AuthService } from '@client/core/services/auth.service';
import { ConfigService } from '@client/core/services/config.service';
import { ContractService } from '@client/core/services/contract.service';
import { EmailService } from '@client/core/services/email.service';
import { FaqService } from '@client/core/services/faq.service';
import { MetadataService } from '@client/core/services/metadata.service';
import { MonerisService } from '@client/core/services/moneris.service';
import { OrganizationsService } from '@client/core/services/organization.service';
import { ParksService } from '@client/core/services/parks.service';
import { ProfileService } from '@client/core/services/profile.service';
import { SectorsService } from '@client/core/services/sectors.service';
import { ServicesService } from '@client/core/services/services.service';
import { SessionService } from '@client/core/services/session.service';
import { SpaqApiService } from '@client/core/services/spaq-api.service';
import { SubscriptionService } from '@client/core/services/subscription.service';
import { TokenInterceptor } from '@client/core/services/token-interceptor.service';
import { UserVehiclesService } from '@client/core/services/user-vehicles.service';
import { UserService } from '@client/core/services/user.service';
import { VehiclesService } from '@client/core/services/vehicles.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    UserService,
    AccountStatusService,
    UserVehiclesService,
    SectorsService,
    MetadataService,
    SessionService,
    AccountStatusService,
    AuthService,
    FaqService,
    ConfigService,
    ProfileService,
    SubscriptionService,
    ServicesService,
    ParksService,
    OrganizationsService,
    ContractService,
    AccessCodeService,
    EmailService,
    TokenInterceptor,
    SpaqApiService,
    VehiclesService,
    MonerisService,
    { provide: REGION, useValue: 'us-central1' }
  ]
})
export class CoreServicesModule {}
