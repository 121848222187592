export const environment = {
  general: {
    adminEmail: 'NEPASREPONDRE@spaq.com',
    techEmail: 'NEPASREPONDRE@spaq.com',
    siteDescription: '',
    siteName: '',
    userCanRegister: true,
    siteUrl: 'https://spaq-mobile-production.firebaseapp.com'
  },
  production: true,
  showInternal: false,
  services: {
    vehicles: true,
    metadata: true,
    sectors: true,
    services: true,
    accountStatus: true,
    user: true,
    faq: true,
    profile: true,
    subscription: true,
    parks: true,
    organization: true,
    contract: true,
    accessCode: true
  },
  firebase: {
    apiKey: 'AIzaSyBWYLRRA1mbya1JdhrTC-hArLQA5I182w4',
    authDomain: 'spaq-mobile-production.firebaseapp.com',
    databaseURL: 'https://spaq-mobile-production.firebaseio.com',
    projectId: 'spaq-mobile-production',
    storageBucket: 'spaq-mobile-production.appspot.com',
    messagingSenderId: '285316023393',
    measurementId: 'G-E2C8EZXSTF'
  },
  backendApiUrl: 'https://us-central1-spaq-mobile-production.cloudfunctions.net',
  moneris: {
    url: 'https://esqa.moneris.com/HPPDP/index.php',
    paypage: {
      id: 'M4GQ3tore1',
      key: 'hpXEANG66AJ6'
    },
    vault: {
      id: 'ENU7Ttore1',
      key: 'res4LS4NFBYC'
    },
    id: 'ENU7Ttore1',
    key: 'res4LS4NFBYC'
  },
  settings: {
    menu: [
      {
        id: 'subscription',
        name: 'Informations personnelles',
        description: 'Configurez vos informations personnelles qui seront utilisées par défaut dans votre compte.'
      },
      { id: 'profile', name: 'Profil du compte', description: 'Configurez vos informations de connexion' },
   //   { id: 'access_code', name: "Code d'accès", description: "Ajoutez votre code d'accès pour débloquer des services privés" },
      { id: 'vehicles', name: 'Mes véhicules', description: 'Configurez vos véhicules' },
      //     {id: 'ppa', name: 'Prélèvement préautorisé', description: 'Configurez vos informations bancaires'},
      { id: 'password', name: 'Mot de passe', description: 'Modifiez le mot de passe de votre compte' },
      { id: 'email', name: 'Adresse courriel', description: "Modifiez l'adresse courriel de votre compte" }
      //      {id: 'contract_service', name: 'Contrat de service', description: ''},
      //      {id: 'contract_payment', name: 'Conditions de paiement', description: ''},
      //      {id: 'contract_confidentiality', name: 'Confidentialité', description: ''},
    ]
  }
};
