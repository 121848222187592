import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo } from "@client/utils/shared-constants";
import { State } from "@client/reducers/subscription-reducer";
import { getSubscriptionMessage, getSubscriptionHasError, getSubscriptionHasSuccess, getSubscriptionIsHydrated, getSubscriptionIsLoading, getSubscriptionIsCompleted, getSubscriptionData, getSubscriptionDataCustom, getSubscriptionDataSpaq, getSubscriptionDataSpaqAccesDocuments, getSubscriptionDataSpaqAccessOnHoldReason, getSubscriptionDataSpaqAddressLine1, getSubscriptionDataSpaqIdentificationCode, getSubscriptionDataSpaqAddressLine2, getSubscriptionDataSpaqAppartmentNo, getSubscriptionDataSpaqBalance, getSubscriptionDataSpaqCellPhone, getSubscriptionDataSpaqCity, getSubscriptionDataSpaqContractNo, getSubscriptionDataSpaqDynamicFieldJsonData, getSubscriptionDataSpaqEmail, getSubscriptionDataSpaqFirstName, getSubscriptionDataSpaqHomePhone, getSubscriptionDataSpaqHomePhoneExtension, getSubscriptionDataSpaqIsAccessOnHold, getSubscriptionDataSpaqLanguage, getSubscriptionDataSpaqLastName, getSubscriptionDataSpaqPostalCode, getSubscriptionDataSpaqProvince, getSubscriptionDataSpaqServiceCenter, getSubscriptionDataSpaqSex, getSubscriptionDataSpaqWorkPhone, getSubscriptionDataSpaqWorkPhoneExtension, getSubscriptionDataSpaqPaymentMethod, getSubscriptionDataSpaqPaymentMethodCreditCardDataKey, getSubscriptionDataSpaqPaymentMethodEffectiveDate, getSubscriptionDataSpaqPaymentMethodEndDate, getSubscriptionDataSpaqPaymentMethodFinancialInstitutionId, getSubscriptionDataSpaqPaymentMethodFolioNo, getSubscriptionDataSpaqPaymentMethodMethod, getSubscriptionDataSpaqPaymentMethodTransitNo } from "@client/selectors";
/*test*/ 
@Injectable()
export class SubscriptionStore extends BaseStore {
    get message(): Observable<string | null> {
        return this.store.pipe(select(getSubscriptionMessage));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionHasError));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionHasSuccess));
    }
    get isHydrated(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionIsHydrated));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionIsLoading));
    }
    get isCompleted(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionIsCompleted));
    }
    get data(): Observable<SubscriptionInfo> {
        return this.store.pipe(select(getSubscriptionData));
    }
    get dataCustom(): Observable<any> {
        return this.store.pipe(select(getSubscriptionDataCustom));
    }
    get dataSpaq(): Observable<SubscriptionSpaqInfo> {
        return this.store.pipe(select(getSubscriptionDataSpaq));
    }
    get dataSpaqAccesDocuments(): Observable<AccesDocumentInfo[]> {
        return this.store.pipe(select(getSubscriptionDataSpaqAccesDocuments));
    }
    get dataSpaqAccessOnHoldReason(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqAccessOnHoldReason));
    }
    get dataSpaqAddressLine1(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqAddressLine1));
    }
    get dataSpaqIdentificationCode(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqIdentificationCode));
    }
    get dataSpaqAddressLine2(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqAddressLine2));
    }
    get dataSpaqAppartmentNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqAppartmentNo));
    }
    get dataSpaqBalance(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDataSpaqBalance));
    }
    get dataSpaqCellPhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqCellPhone));
    }
    get dataSpaqCity(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqCity));
    }
    get dataSpaqContractNo(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDataSpaqContractNo));
    }
    get dataSpaqDynamicFieldJsonData(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqDynamicFieldJsonData));
    }
    get dataSpaqEmail(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqEmail));
    }
    get dataSpaqFirstName(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqFirstName));
    }
    get dataSpaqHomePhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqHomePhone));
    }
    get dataSpaqHomePhoneExtension(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqHomePhoneExtension));
    }
    get dataSpaqIsAccessOnHold(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionDataSpaqIsAccessOnHold));
    }
    get dataSpaqLanguage(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqLanguage));
    }
    get dataSpaqLastName(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqLastName));
    }
    get dataSpaqPostalCode(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPostalCode));
    }
    get dataSpaqProvince(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqProvince));
    }
    get dataSpaqServiceCenter(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDataSpaqServiceCenter));
    }
    get dataSpaqSex(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqSex));
    }
    get dataSpaqWorkPhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqWorkPhone));
    }
    get dataSpaqWorkPhoneExtension(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDataSpaqWorkPhoneExtension));
    }
    get dataSpaqPaymentMethod(): Observable<PaymentMethodInfo> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethod));
    }
    get dataSpaqPaymentMethodCreditCardDataKey(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodCreditCardDataKey));
    }
    get dataSpaqPaymentMethodEffectiveDate(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodEffectiveDate));
    }
    get dataSpaqPaymentMethodEndDate(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodEndDate));
    }
    get dataSpaqPaymentMethodFinancialInstitutionId(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodFinancialInstitutionId));
    }
    get dataSpaqPaymentMethodFolioNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodFolioNo));
    }
    get dataSpaqPaymentMethodMethod(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodMethod));
    }
    get dataSpaqPaymentMethodTransitNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDataSpaqPaymentMethodTransitNo));
    }
}
