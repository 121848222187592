import { ModuleWithProviders, NgModule } from '@angular/core';
import { OrganizationsModule } from '@client/modules/organization';
import { ServicesModule } from '@client/modules/services';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import { AccessCodeComponent } from './access-code.component';
import { AccessCodeFormComponent } from './components/access-code-form.component';
import { AccessCodeAddComponent } from './pages/access-code-add.component';
import { AccessCodeListPageComponent } from './pages/access-code-list.component';

@NgModule({
  imports: [SharedCommonModule, OrganizationsModule, ServicesModule, SharedComponentsModule, SharedCommonModule, SharedFormModule],
  declarations: [AccessCodeListPageComponent, AccessCodeFormComponent, AccessCodeAddComponent, AccessCodeComponent],
  exports: [AccessCodeListPageComponent, AccessCodeFormComponent, AccessCodeAddComponent]
})
export class AccessCodeModule {
  static forRoot(): ModuleWithProviders<AccessCodeModule> {
    return {
      ngModule: AccessCodeModule,
      providers: []
    };
  }
}
