import { ORGANIZATION } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo } from "@client/utils/shared-constants";

export class OrganizationHydrateAction implements Action {
    readonly type = ORGANIZATION.HYDRATE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class OrganizationHydrateSuccessAction implements Action {
    readonly type = ORGANIZATION.HYDRATE_SUCCESS;
    constructor(public payload: OrganizationInfo[], public correlationId?: number) {
    }
}

export class OrganizationHydrateFailureAction implements Action {
    readonly type = ORGANIZATION.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
