import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SubscriptionDetailsDispatcher } from '@client/dispatchers/subscription-details-dispatcher';
import { SubscriptionDispatcher } from '@client/dispatchers/subscription-dispatcher';
import { SubscriptionEditDispatcher } from '@client/dispatchers/subscription-edit-dispatcher';
import { SUBSCRIPTION_DETAILS_DISPATCHER, SUBSCRIPTION_DETAILS_STORE, SUBSCRIPTION_DISPATCHER, SUBSCRIPTION_EDIT_DISPATCHER, SUBSCRIPTION_EDIT_STORE, SUBSCRIPTION_STORE } from '@client/injection';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import { SubscriptionDetailsStore } from '@client/stores/subscription-details-store';
import { SubscriptionEditStore } from '@client/stores/subscription-edit-store';
import { SubscriptionStore } from '@client/stores/subscription-store';
import {
  FieldsetPaymentsMethodComponent,
  SubscriptionAccessDocumentComponent,
  SubscriptionExtraInfosComponent,
  SubscriptionFormComponent,
  SubscriptionInfosComponent,
  SubscriptionInfosItemComponent,
  SubscriptionSelectPaymentComponent,
  UserInfosComponent
} from './components';
import { SubscriptionComponent, SubscriptionDetailsComponent, SubscriptionEditComponent } from './pages';
import {DateInputsModule} from "@progress/kendo-angular-dateinputs";

@NgModule({
  imports: [SharedCommonModule, SharedComponentsModule, SharedCommonModule,
    DateInputsModule, SharedFormModule],
  declarations: [
    SubscriptionDetailsComponent,
    SubscriptionEditComponent,
    SubscriptionFormComponent,
    SubscriptionInfosComponent,
    SubscriptionInfosItemComponent,
    SubscriptionSelectPaymentComponent,
    FieldsetPaymentsMethodComponent,
    UserInfosComponent,
    SubscriptionExtraInfosComponent,
    SubscriptionAccessDocumentComponent,
    SubscriptionComponent
  ],
  exports: [
    SubscriptionDetailsComponent,
    SubscriptionEditComponent,
    SubscriptionFormComponent,
    SubscriptionInfosComponent,
    SubscriptionInfosItemComponent,
    SubscriptionSelectPaymentComponent,
    FieldsetPaymentsMethodComponent,
    UserInfosComponent,
    SubscriptionExtraInfosComponent,
    SubscriptionAccessDocumentComponent,
    SubscriptionComponent,
    RouterModule
  ]
})
export class SubscriptionModule {
  static forRoot(): ModuleWithProviders<SubscriptionModule> {
    return {
      ngModule: SubscriptionModule,
      providers: [
        { provide: SUBSCRIPTION_DISPATCHER, useClass: SubscriptionDispatcher },
        { provide: SUBSCRIPTION_EDIT_DISPATCHER, useClass: SubscriptionEditDispatcher },
        { provide: SUBSCRIPTION_DETAILS_DISPATCHER, useClass: SubscriptionDetailsDispatcher },
        { provide: SUBSCRIPTION_STORE, useClass: SubscriptionStore },
        { provide: SUBSCRIPTION_EDIT_STORE, useClass: SubscriptionEditStore },
        { provide: SUBSCRIPTION_DETAILS_STORE, useClass: SubscriptionDetailsStore }
      ]
    };
  }
}
