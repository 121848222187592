import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo } from "@client/utils/shared-constants";
import { State } from "@client/reducers/subscription-details-reducer";
import { getSubscriptionDetailsIsActive, getSubscriptionDetailsIsLoading, getSubscriptionDetailsForm, getSubscriptionDetailsFormCustom, getSubscriptionDetailsFormSpaq, getSubscriptionDetailsFormSpaqAccesDocuments, getSubscriptionDetailsFormSpaqAccessOnHoldReason, getSubscriptionDetailsFormSpaqAddressLine1, getSubscriptionDetailsFormSpaqIdentificationCode, getSubscriptionDetailsFormSpaqAddressLine2, getSubscriptionDetailsFormSpaqAppartmentNo, getSubscriptionDetailsFormSpaqBalance, getSubscriptionDetailsFormSpaqCellPhone, getSubscriptionDetailsFormSpaqCity, getSubscriptionDetailsFormSpaqContractNo, getSubscriptionDetailsFormSpaqDynamicFieldJsonData, getSubscriptionDetailsFormSpaqEmail, getSubscriptionDetailsFormSpaqFirstName, getSubscriptionDetailsFormSpaqHomePhone, getSubscriptionDetailsFormSpaqHomePhoneExtension, getSubscriptionDetailsFormSpaqIsAccessOnHold, getSubscriptionDetailsFormSpaqLanguage, getSubscriptionDetailsFormSpaqLastName, getSubscriptionDetailsFormSpaqPostalCode, getSubscriptionDetailsFormSpaqProvince, getSubscriptionDetailsFormSpaqServiceCenter, getSubscriptionDetailsFormSpaqSex, getSubscriptionDetailsFormSpaqWorkPhone, getSubscriptionDetailsFormSpaqWorkPhoneExtension, getSubscriptionDetailsFormSpaqPaymentMethod, getSubscriptionDetailsFormSpaqPaymentMethodCreditCardDataKey, getSubscriptionDetailsFormSpaqPaymentMethodEffectiveDate, getSubscriptionDetailsFormSpaqPaymentMethodEndDate, getSubscriptionDetailsFormSpaqPaymentMethodFinancialInstitutionId, getSubscriptionDetailsFormSpaqPaymentMethodFolioNo, getSubscriptionDetailsFormSpaqPaymentMethodMethod, getSubscriptionDetailsFormSpaqPaymentMethodTransitNo } from "@client/selectors";
/*test*/ 
@Injectable()
export class SubscriptionDetailsStore extends BaseStore {
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionDetailsIsActive));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionDetailsIsLoading));
    }
    get form(): Observable<SubscriptionInfo> {
        return this.store.pipe(select(getSubscriptionDetailsForm));
    }
    get formCustom(): Observable<any> {
        return this.store.pipe(select(getSubscriptionDetailsFormCustom));
    }
    get formSpaq(): Observable<SubscriptionSpaqInfo> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaq));
    }
    get formSpaqAccesDocuments(): Observable<AccesDocumentInfo[]> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqAccesDocuments));
    }
    get formSpaqAccessOnHoldReason(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqAccessOnHoldReason));
    }
    get formSpaqAddressLine1(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqAddressLine1));
    }
    get formSpaqIdentificationCode(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqIdentificationCode));
    }
    get formSpaqAddressLine2(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqAddressLine2));
    }
    get formSpaqAppartmentNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqAppartmentNo));
    }
    get formSpaqBalance(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqBalance));
    }
    get formSpaqCellPhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqCellPhone));
    }
    get formSpaqCity(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqCity));
    }
    get formSpaqContractNo(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqContractNo));
    }
    get formSpaqDynamicFieldJsonData(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqDynamicFieldJsonData));
    }
    get formSpaqEmail(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqEmail));
    }
    get formSpaqFirstName(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqFirstName));
    }
    get formSpaqHomePhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqHomePhone));
    }
    get formSpaqHomePhoneExtension(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqHomePhoneExtension));
    }
    get formSpaqIsAccessOnHold(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqIsAccessOnHold));
    }
    get formSpaqLanguage(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqLanguage));
    }
    get formSpaqLastName(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqLastName));
    }
    get formSpaqPostalCode(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPostalCode));
    }
    get formSpaqProvince(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqProvince));
    }
    get formSpaqServiceCenter(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqServiceCenter));
    }
    get formSpaqSex(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqSex));
    }
    get formSpaqWorkPhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqWorkPhone));
    }
    get formSpaqWorkPhoneExtension(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqWorkPhoneExtension));
    }
    get formSpaqPaymentMethod(): Observable<PaymentMethodInfo> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethod));
    }
    get formSpaqPaymentMethodCreditCardDataKey(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodCreditCardDataKey));
    }
    get formSpaqPaymentMethodEffectiveDate(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodEffectiveDate));
    }
    get formSpaqPaymentMethodEndDate(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodEndDate));
    }
    get formSpaqPaymentMethodFinancialInstitutionId(): Observable<number> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodFinancialInstitutionId));
    }
    get formSpaqPaymentMethodFolioNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodFolioNo));
    }
    get formSpaqPaymentMethodMethod(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodMethod));
    }
    get formSpaqPaymentMethodTransitNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionDetailsFormSpaqPaymentMethodTransitNo));
    }
}
