import { SUBSCRIPTION_DETAILS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState } from "@client/utils/shared-constants";

export class SubscriptionDetailsInitAction implements Action {
    readonly type = SUBSCRIPTION_DETAILS.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionDetailsDestroyAction implements Action {
    readonly type = SUBSCRIPTION_DETAILS.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionDetailsClickEditAction implements Action {
    readonly type = SUBSCRIPTION_DETAILS.CLICK_EDIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
