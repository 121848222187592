
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToString'
})
export class ConvertToStringPipe implements PipeTransform {

  transform(value: any, args?: any) {
    if (value === null || value === undefined) {
      return args || '';
    }
    if (typeof value === 'string') {
      return value;
    }
    return String(value);
  }
}
