import { CONTRACT, USER, USER_VEHICLES, POPUP } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    opened: boolean;
    title: string;
    content: string;
    width: number;
    height: number;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    opened: false,
    title: "Chargement en cours",
    content: "Merci de patienter un instant.",
    width: 400,
    height: 300
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case POPUP.INIT: return popupInit(state);
        case POPUP.DESTROY: return popupDestroy(state);
        case POPUP.CLOSE: return popupClose(state);
        case CONTRACT.DELETE: return contractDelete(state);
        case CONTRACT.DELETE_SUCCESS: return contractDeleteSuccess(state);
        case CONTRACT.DELETE_FAILURE: return contractDeleteFailure(state);
        case USER.SYNC: return userSync(state);
        case USER.SYNC_SUCCESS: return userSyncSuccess(state);
        case USER.SYNC_FAILURE: return userSyncFailure(state, action.payload);
        case USER_VEHICLES.DELETE: return userVehiclesDelete(state, action.payload);
        case USER_VEHICLES.DELETE_SUCCESS: return userVehiclesDeleteSuccess(state, action.payload);
        case USER_VEHICLES.DELETE_FAILURE: return userVehiclesDeleteFailure(state, action.payload);
        default: return state;
    }
}
export function popupInit(state: State): State {
    return state;
}
export function popupDestroy(state: State): State {
    return state;
}
export function popupClose(state: State): State {
    return {
        ...state,
        opened: false
    };
}
export function contractDelete(state: State): State {
    return {
        ...state,
        opened: true,
        hasSuccess: false,
        hasError: false,
        message: null,
        content: 'Supression du contrat en cours'
    };
}
export function contractDeleteSuccess(state: State): State {
    return {
        ...state,
        opened: false,
        hasSuccess: true,
        content: 'Supression du contrat en cours'
    };
}
export function contractDeleteFailure(state: State): State {
    return {
        ...state,
        opened: false,
        hasError: true,
        message: 'Une erreur est survenue',
        content: 'Supression du contrat en cours'
    };
}
export function userSync(state: State): State {
    return {
        ...state,
        opened: true,
        hasSuccess: false,
        hasError: false,
        message: null,
        content: 'Synchronisation du compte en cours'
    };
}
export function userSyncSuccess(state: State): State {
    return {
        ...state,
        opened: false,
        hasSuccess: true,
        content: 'Synchronisation du compte en cours'
    };
}
export function userSyncFailure(state: State, payload: any): State {
    return {
        ...state,
        opened: false,
        hasError: true,
        message: payload,
        content: 'Synchronisation du compte en cours'
    };
}
export function userVehiclesDelete(state: State, payload: Vehicule): State {
    return {
        ...state,
        opened: true,
        hasSuccess: false,
        hasError: false,
        message: null,
        content: 'Supression du véhicule en cours'
    };
}
export function userVehiclesDeleteSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        opened: false,
        hasSuccess: true,
        content: 'Supression du véhicule en cours'
    };
}
export function userVehiclesDeleteFailure(state: State, payload: any): State {
    return {
        ...state,
        opened: false,
        hasError: true,
        message: payload,
        content: 'Supression du véhicule en cours'
    };
}
