import { State as ContractDetailsState } from "@client/reducers/contract-details-reducer";
import { Selector } from "@ngrx/store";
export const getIsActive: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.isActive;
export const getMessage: Selector<ContractDetailsState, string | null> = (state: ContractDetailsState) => state.message;
export const getHasError: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.hasError;
export const getHasSuccess: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.hasSuccess;
export const getIsLoading: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.isLoading;
export const getIsCompleted: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.isCompleted;
export const getFreshlyCreated: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.freshlyCreated;
export const getPdfSuccess: Selector<ContractDetailsState, boolean | null> = (state: ContractDetailsState) => state.pdfSuccess;
export const getCanUpdatePpa: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.canUpdatePpa;
export const getCanUpdateCc: Selector<ContractDetailsState, boolean> = (state: ContractDetailsState) => state.canUpdateCc;
