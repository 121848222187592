import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { METADATA, SESSION } from '@client/actions';
import { MetadataHydrateAction, MetadataHydrateFailureAction, MetadataHydrateSuccessAction } from '@client/actions/metadata-actions';
import { SessionInitAction, SessionReloadAction } from '@client/actions/session-actions';
import { UserSyncAction } from '@client/actions/user-actions';
import { AuthService } from '@client/core/services/auth.service';
import { MetadataService } from '@client/core/services/metadata.service';
import { MetadataState } from '@client/utils/shared-constants';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

import { EMPTY, from, iif, Observable, of } from 'rxjs';
import { catchError, concatAll, map, mergeMap, pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../configs/environment';
import { Action } from '../../lib/action';

@Injectable()
export class MetadataEffects {
  @Effect()
  hydrate: Observable<Action> = this.actions$.pipe(
    ofType<MetadataHydrateAction>(METADATA.HYDRATE),
    switchMap(action =>
      this.metaService.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map((data: MetadataState) => new MetadataHydrateSuccessAction(data, action.correlationId)),
        catchError((e: any) => of(new MetadataHydrateFailureAction(e, action.correlationId)))
      )
    )
  );

  @Effect({ dispatch: true })
  hydrateSuccess$: Observable<any> = this.actions$.pipe(
    ofType<MetadataHydrateSuccessAction>(METADATA.HYDRATE_SUCCESS),
    pairwise(),
    mergeMap(([a, b]) => {
      return of(
        reduce(
          a.payload,
          function(result, value, key) {
            return isEqual(value, (b.payload || {})[key]) ? result : result.concat(key);
          },
          []
        )
      ).pipe(
        concatAll(),
        switchMap<any, any>(x => {
          if (x === 'refreshUser') {
            return of(new SessionReloadAction(null, b.correlationId));
          }
          if (x === 'refreshTime') {
            return this.afAuth.user.pipe(switchMap(u => u.getIdToken(true)), switchMap(x => EMPTY));
          }
          if (x === 'resync') {
            return new UserSyncAction(null, b.correlationId);
          }
          return EMPTY;
        })
      );
    })
  );

  @Effect()
  metadataHydrate$: Observable<Action> = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.metadata, of(new MetadataHydrateAction(null, action.correlationId)), EMPTY))
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private metaService: MetadataService, private afAuth: AngularFireAuth) {}
}
