import { VEHICLES_DETAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    hasSuccess: boolean;
    hasError: boolean;
    isLoading: boolean;
    message: string | null;
}
export const initialState: State = {
    hasSuccess: false,
    hasError: false,
    isLoading: false,
    message: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case VEHICLES_DETAILS.INIT: return vehiclesDetailsInit(state);
        case VEHICLES_DETAILS.DESTROY: return vehiclesDetailsDestroy(state);
        case VEHICLES_DETAILS.CLICK_ADD: return vehiclesDetailsClickAdd(state);
        case VEHICLES_DETAILS.CLICK_EDIT: return vehiclesDetailsClickEdit(state, action.payload);
        case VEHICLES_DETAILS.CLICK_DELETE: return vehiclesDetailsClickDelete(state, action.payload);
        default: return state;
    }
}
export function vehiclesDetailsInit(state: State): State {
    return state;
}
export function vehiclesDetailsDestroy(state: State): State {
    return state;
}
export function vehiclesDetailsClickAdd(state: State): State {
    return state;
}
export function vehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return { ...state, ...payload };
}
export function vehiclesDetailsClickDelete(state: State, payload: Vehicule): State {
    return { ...state, ...payload };
}
