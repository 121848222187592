import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@client/core/services/auth.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Injectable()
export class IfAuthorizedGuard implements CanActivate {
  constructor(private store: Store<any>, private auth: AuthService, private route: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return of(true);
  }
}
