import { ADMIN_FAQ_LIST_GROUP } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { FaqGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    collection: FaqGroupInfo[];
    selectedFaqId: string;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    collection: [],
    selectedFaqId: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_FAQ_LIST_GROUP.INIT: return adminFaqListGroupInit(state);
        case ADMIN_FAQ_LIST_GROUP.DESTROY: return adminFaqListGroupDestroy(state);
        case ADMIN_FAQ_LIST_GROUP.CLICK_SHOW_QUESTIONS: return adminFaqListGroupClickShowQuestions(state);
        case ADMIN_FAQ_LIST_GROUP.CLICK_ADD: return adminFaqListGroupClickAdd(state);
        case ADMIN_FAQ_LIST_GROUP.CLICK_ADD_GROUP: return adminFaqListGroupClickAddGroup(state);
        case ADMIN_FAQ_LIST_GROUP.CLICK_EDIT: return adminFaqListGroupClickEdit(state, action.payload);
        case ADMIN_FAQ_LIST_GROUP.CLICK_DELETE: return adminFaqListGroupClickDelete(state, action.payload);
        case ADMIN_FAQ_LIST_GROUP.CLICK_DELETE_SUCCESS: return adminFaqListGroupClickDeleteSuccess(state, action.payload);
        case ADMIN_FAQ_LIST_GROUP.CLICK_DELETE_FAILURE: return adminFaqListGroupClickDeleteFailure(state, action.payload);
        case ADMIN_FAQ_LIST_GROUP.HYDRATE: return adminFaqListGroupHydrate(state);
        case ADMIN_FAQ_LIST_GROUP.HYDRATE_SUCCESS: return adminFaqListGroupHydrateSuccess(state, action.payload);
        case ADMIN_FAQ_LIST_GROUP.HYDRATE_FAILURE: return adminFaqListGroupHydrateFailure(state, action.payload);
        default: return state;
    }
}
export function adminFaqListGroupInit(state: State): State {
    return state;
}
export function adminFaqListGroupDestroy(state: State): State {
    return state;
}
export function adminFaqListGroupClickShowQuestions(state: State): State {
    return state;
}
export function adminFaqListGroupClickAdd(state: State): State {
    return state;
}
export function adminFaqListGroupClickAddGroup(state: State): State {
    return state;
}
export function adminFaqListGroupClickEdit(state: State, payload: FaqGroupInfo): State {
    return { ...state, ...payload };
}
export function adminFaqListGroupClickDelete(state: State, payload: FaqGroupInfo): State {
    return { ...state, ...payload };
}
export function adminFaqListGroupClickDeleteSuccess(state: State, payload: FaqGroupInfo): State {
    return { ...state, ...payload };
}
export function adminFaqListGroupClickDeleteFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminFaqListGroupHydrate(state: State): State {
    return state;
}
export function adminFaqListGroupHydrateSuccess(state: State, payload: FaqGroupInfo[]): State {
    return { ...state, collection: payload };
}
export function adminFaqListGroupHydrateFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
