import { Injectable } from "@angular/core";
import { ISubscriptionEditDispatcher } from "@client/interfaces/subscription-edit-interface";
import { Store } from "@ngrx/store";
import { SubscriptionEditInitAction, SubscriptionEditDestroyAction, SubscriptionEditUpdateAction, SubscriptionEditUpdateSuccessAction, SubscriptionEditUpdateFailureAction, SubscriptionEditClickSubmitAction, SubscriptionEditFormUpdateAction, SubscriptionEditFormResetAction, SubscriptionEditUploadAction, SubscriptionEditUploadSuccessAction, SubscriptionEditUploadFailureAction } from "@client/actions/subscription-edit-actions";
import { SubscriptionInfo } from "@client/utils/shared-constants";
@Injectable()
export class SubscriptionEditDispatcher implements ISubscriptionEditDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditDestroyAction(payload, correlationId));
    }
    update(payload: SubscriptionInfo, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditUpdateAction(payload, correlationId));
    }
    updateSuccess(payload: SubscriptionInfo, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditUpdateSuccessAction(payload, correlationId));
    }
    updateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditUpdateFailureAction(payload, correlationId));
    }
    clickSubmit(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditClickSubmitAction(payload, correlationId));
    }
    formUpdate(payload: SubscriptionInfo, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditFormUpdateAction(payload, correlationId));
    }
    formReset(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditFormResetAction(payload, correlationId));
    }
    upload(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditUploadAction(payload, correlationId));
    }
    uploadSuccess(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditUploadSuccessAction(payload, correlationId));
    }
    uploadFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionEditUploadFailureAction(payload, correlationId));
    }
}
