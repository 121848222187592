import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getAccountStatusState } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasSubscriptionGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return this.store.pipe(
      select(getAccountStatusState),
      filter(x => !!x.hasSuccess),
      mergeMap((s: any) => {
        if (s.isSubscriptionRequired === false) {
          return of(true);
        }
        return this.router.navigate(['/auth/register/subscription']);
      })
    );
  }
}
