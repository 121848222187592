import { Component, OnInit } from '@angular/core';
import { InputBase } from '../input-base';

@Component({
  selector: 'spaq-input-bank-account-number',
  template: `
    <kendo-textbox-container [formGroup]="form" class="w-100" [floatingLabel]="label">
      <input type="text" kendoTextBox [formControlName]="name" [id]="name" />

      <small class="alert-danger mt-1" *ngIf="form.get(name).touched && form.get(name).errors && form.get(name).dirty">
        {{ form.get(name).hasError('required') ? 'Ce champ est requis' : '' }}
        {{ form.get(name).hasError('pattern') ? 'Format invalide' : '' }}
        {{ form.get(name).hasError('minlength') || form.get(name).hasError('maxlength') ? 'Doit être composé de 5 à 12 chiffres' : '' }}
      </small>
    </kendo-textbox-container>
  `
})
export class InputBankAccountNumberComponent extends InputBase implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
