import { SECTORS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { SectorInfo } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    pending: boolean;
    isServiceRunning: boolean;
    loading: boolean;
    hasError: boolean;
    hasSuccess: boolean;
    isHydrated: boolean;
    collection: SectorInfo[];
}
export const initialState: State = {
    message: null,
    pending: null,
    isServiceRunning: null,
    loading: null,
    hasError: null,
    hasSuccess: null,
    isHydrated: null,
    collection: []
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SECTORS.START_SERVICE: return sectorsStartService(state);
        case SECTORS.STOP_SERVICE: return sectorsStopService(state);
        case SECTORS.HYDRATE: return sectorsHydrate(state);
        case SECTORS.HYDRATE_SUCCESS: return sectorsHydrateSuccess(state, action.payload);
        case SECTORS.HYDRATE_FAILURE: return sectorsHydrateFailure(state, action.payload);
        default: return state;
    }
}
export function sectorsStartService(state: State): State {
    return { ...state, isServiceRunning: true };
}
export function sectorsStopService(state: State): State {
    return { ...state, isServiceRunning: false };
}
export function sectorsHydrate(state: State): State {
    return { ...state, loading: true };
}
export function sectorsHydrateSuccess(state: State, payload: SectorInfo[]): State {
    return { ...state, collection: payload, loading: false, hasSuccess: true, hasError: false, isHydrated: true };
}
export function sectorsHydrateFailure(state: State, payload: any): State {
    return { ...state, message: payload, loading: false, hasError: true, hasSuccess: false, isHydrated: false };
}
