import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { LayoutCollapseSideNavAction } from '@client/actions/layout-actions';
import {
  getLayoutEnableAdminOptions,
  getLayoutScopeId, getOrganizationAll,
  getOrganizationById,
  getSessionClaimsAdmin
} from '@client/selectors/index';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { EMPTY, interval, Subscription } from 'rxjs/index';
import {map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {AngularFireAuth} from "@angular/fire/auth";
import firebase from "firebase";
import User = firebase.User;

@Component({
  selector: 'spaq-layout-account-header',
  templateUrl: './layout-account-header.component.html',
  styleUrls: ['./layout-account-header.component.scss']
})
export class LayoutAccountHeaderComponent implements OnInit, OnDestroy {
  scopeId$ = this.store.pipe(select(getLayoutScopeId));
  organization$ = this.scopeId$.pipe(switchMap(id => this.store.pipe(select(getOrganizationById, id), map(x => x && x.organizationName || 'Public' ))));
organizationList$ = this.store.pipe(select(getOrganizationAll), map(x => [...x, {organizationCode: null, organizationName: 'Public'}]));
  enableAdminOptions$ = this.store.pipe(select(getLayoutEnableAdminOptions));
  states = {
    error: 0,
    success: 0,
    pending: 0
  };
  status = {
    idle: false,
    payment: false,
    vault: false,
    contract: false,
    pdf: false,
    preauth: false
  };
  subscription: Subscription;

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth, private store: Store<any>) {

  }

  ngOnDestroy() {
  }
  onOrganizationClick(organization: any) {
    if(!organization.organizationCode) {
      localStorage.removeItem('selectedOrganization');
      return this.afAuth.user.pipe(
        mergeMap((u: User) => [
          this.db.list(`/user/${u.uid}/vehicles`).valueChanges().pipe(switchMap(x => {
            return this.db.object(`/user/${u.uid}/contract`).set({deleted: true, vehicles: x})
          })).toPromise() ,
          this.db.object(`/user/${u.uid}/access-code`).set(null),
          this.db.object(`/user/${u.uid}/subscription/custom`).set(null)
        ]),
        take(1)
      ).subscribe(() => {
        location.reload(true);
      });
    }
      localStorage.setItem('selectedOrganization', organization.organizationCode);
    return this.afAuth.user.pipe(
      mergeMap((u: User) => [
        this.db.list(`/user/${u.uid}/vehicles`).valueChanges().pipe(switchMap(x => {
          return this.db.object(`/user/${u.uid}/contract`).set({deleted: true, vehicles: x})
        })).toPromise() ,
        this.db.object(`/user/${u.uid}/access-code`).set(null),
        this.db.object(`/user/${u.uid}/subscription/custom`).set(null)
      ]),
      take(1)
    ).subscribe(() => {
      location.reload(true);
    });
  }
  ngOnInit() {}

  collapse() {
    this.store.dispatch(new LayoutCollapseSideNavAction(null, Date.now()));
  }
}
