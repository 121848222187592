import { PROFILE } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities } from "@client/utils/shared-constants";

export class ProfileDestroyAction implements Action {
    readonly type = PROFILE.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileDestroyEditAction implements Action {
    readonly type = PROFILE.DESTROY_EDIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileGoToAction implements Action {
    readonly type = PROFILE.GO_TO;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileHydrateAction implements Action {
    readonly type = PROFILE.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileHydrateSuccessAction implements Action {
    readonly type = PROFILE.HYDRATE_SUCCESS;
    constructor(public payload: ProfileInfo, public correlationId?: number) {
    }
}

export class ProfileHydrateFailureAction implements Action {
    readonly type = PROFILE.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ProfileInitAction implements Action {
    readonly type = PROFILE.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileInitEditAction implements Action {
    readonly type = PROFILE.INIT_EDIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileSubmitFormEditAction implements Action {
    readonly type = PROFILE.SUBMIT_FORM_EDIT;
    constructor(public payload: ProfileWritableEntities, public correlationId?: number) {
    }
}

export class ProfileSubmitFormEditFailureAction implements Action {
    readonly type = PROFILE.SUBMIT_FORM_EDIT_FAILURE;
    constructor(public payload: ProfileWritableEntities, public correlationId?: number) {
    }
}

export class ProfileUpdateFailureAction implements Action {
    readonly type = PROFILE.UPDATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ProfileUpdateSuccessAction implements Action {
    readonly type = PROFILE.UPDATE_SUCCESS;
    constructor(public payload: ProfileWritableEntities, public correlationId?: number) {
    }
}

export class ProfileUpdateAction implements Action {
    readonly type = PROFILE.UPDATE;
    constructor(public payload: ProfileWritableEntities, public correlationId?: number) {
    }
}

export class ProfileUpdateFormEditAction implements Action {
    readonly type = PROFILE.UPDATE_FORM_EDIT;
    constructor(public payload: ProfileWritableEntities, public correlationId?: number) {
    }
}
