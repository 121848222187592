import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HasSubscriptionGuard } from '@client/core/guards/has-subscription.guard';
import { IsAdminGuard } from '@client/core/guards/is-admin.guard';
import { IsLoggedInGuard } from '@client/core/guards/is-logged-in.guard';
import { IsVerifiedGuard } from '@client/core/guards/is-verified.guard';
import { LayoutAccountComponent } from '@client/core/layout/account/layout-account.component';

const routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'prefix' },
  { path: 'auth', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
  {
    path: 'account',
    component: LayoutAccountComponent,
    canActivate: [IsLoggedInGuard, IsVerifiedGuard, HasSubscriptionGuard],
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)
  },
  {
    path: 'admin',
    canActivate: [IsLoggedInGuard, IsVerifiedGuard, HasSubscriptionGuard, IsAdminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
  declarations: []
})
export class WebRoutingModule {}
