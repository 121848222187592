import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { AbstractInput } from '@client/shared/components/forms/abstract-input';

@Component({
  selector: 'plancton-input-zipcode',
  template: `
    <p class="m-0">{{ label }}</p>
    <kendo-maskedtextbox
      [name]="formControlName"
      autocomplete="zipcode"
      [disabled]="disabled"
      class="w-100 input-zipcode"
      [mask]="mask"
      [(ngModel)]="value"
      (ngModelChange)="updateModel()"
      (blur)="onModelTouched()"
      [id]="formControlName"
    >
      >
    </kendo-maskedtextbox>

    <div *ngIf="!isValid">
      <small class="alert-danger" *ngIf="control.hasError('required')">Champ requis</small>
      <small class="alert-danger" *ngIf="control.hasError('pattern')">Un code postal valide est requis</small>
    </div>
  `,
  styles: [
    `
      p {
        font-size: 11px;
      }
    `
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputZipcodeComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputZipcodeComponent), multi: true }
  ]
})
export class InputZipcodeComponent extends AbstractInput implements OnInit {
  @Input() label = 'Code postal';
  mask = 'L0L 0L0';

  validateFn = (control: AbstractControl) => {
    if (control.value && control.value.length) {
      const re = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;

      return Validators.pattern(re)(control);
    }
    return null;
  };

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validateFn(control);
  }
}
