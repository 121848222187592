import { PROFILE_EDIT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm } from "@client/utils/shared-constants";

export class ProfileEditDestroyAction implements Action {
    readonly type = PROFILE_EDIT.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileEditInitAction implements Action {
    readonly type = PROFILE_EDIT.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileEditClickSubmitAction implements Action {
    readonly type = PROFILE_EDIT.CLICK_SUBMIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileEditFormUpdateAction implements Action {
    readonly type = PROFILE_EDIT.FORM_UPDATE;
    constructor(public payload: ProfileEditForm, public correlationId?: number) {
    }
}
