import { CONTRACT_CONDITIONS_SERVICE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isLoading: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    message: string | null;
    text: string;
    isActive: boolean;
    acceptedAt: string;
}
export const initialState: State = {
    isLoading: false,
    hasSuccess: false,
    hasError: false,
    message: null,
    text: "",
    isActive: false,
    acceptedAt: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT_CONDITIONS_SERVICE.INIT: return contractConditionsServiceInit(state);
        case CONTRACT_CONDITIONS_SERVICE.DESTROY: return contractConditionsServiceDestroy(state);
        case CONTRACT_CONDITIONS_SERVICE.HYDRATE: return contractConditionsServiceHydrate(state);
        case CONTRACT_CONDITIONS_SERVICE.HYDRATE_SUCCESS: return contractConditionsServiceHydrateSuccess(state, action.payload);
        case CONTRACT_CONDITIONS_SERVICE.HYDRATE_FAILURE: return contractConditionsServiceHydrateFailure(state, action.payload);
        case CONTRACT_CONDITIONS_SERVICE.CLICK_ACCEPT: return contractConditionsServiceClickAccept(state);
        default: return state;
    }
}
export function contractConditionsServiceInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function contractConditionsServiceDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function contractConditionsServiceHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractConditionsServiceHydrateSuccess(state: State, payload: string): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        text: payload
    };
}
export function contractConditionsServiceHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function contractConditionsServiceClickAccept(state: State): State {
    return {
        ...state,
        acceptedAt: new Date().toISOString()
    };
}
