import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AccessCodeGroupInfo, AccessCodeInfo, OrganizationConfigInfo } from '@client/utils/shared-constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrganizationsService {
  constructor(public db: AngularFireDatabase) {}

  get(search: AccessCodeInfo): Observable<AccessCodeGroupInfo> {
    return this.db.object<AccessCodeGroupInfo>(`/organizations/${search.organizationCode}/groups/${search.groupCode}`).valueChanges();
  }

  getAll(): Observable<any> {
    return this.db
      .list(`/organizations`)
      .snapshotChanges()
      .pipe(
        map(org =>
          org.map(x => {
            return { ...(x.payload.val() as object), organizationId: x.key };
          })
        )
      );
  }

  getOrganizationById(id: string): Observable<any> {
    return this.db.object<any>(`/organizations/${id}`).valueChanges();
  }

  getOrganizationConfig(id: string): Observable<OrganizationConfigInfo> {
    return this.db.object<OrganizationConfigInfo>(`/organizations/${id}/config`).valueChanges();
  }

  hydrate(): Observable<any> {
    return this.db
      .list(`/organizations`)
      .snapshotChanges()
      .pipe(
        map(org =>
          org.map(x => {
            return { ...(x.payload.val() as object), organizationId: x.key };
          })
        )
      );
  }

  hydrateOrganizationScope(data: any): Observable<any> {
    return this.db
      .object(`/organizations/${data.scope}`)
      .snapshotChanges()
      .pipe(
        map(org =>
          [org.payload.val()].map(x => {
            return { ...(x as object), organizationId: data.scope };
          })
        )
      );
  }
}
