import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { SESSION, USER_VEHICLES } from '@client/actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { UserVehiclesDeleteAction, UserVehiclesDeleteFailureAction, UserVehiclesDeleteSuccessAction, UserVehiclesStartServiceAction } from '@client/actions/user-vehicles-actions';
import { AuthService } from '@client/core/services/auth.service';
import { Action } from '@client/lib/action';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { empty, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class UserVehiclesEffects {
  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.vehicles, of(new UserVehiclesStartServiceAction(null, action.correlationId)), empty()))
  );

  @Effect()
  delete$: Observable<Action> = this.actions$.pipe(
    ofType<UserVehiclesDeleteAction>(USER_VEHICLES.DELETE),
    switchMap(x => {
      const test = this.fns.httpsCallable('vehicleDelete');
      return test(x.payload).pipe(
        map(r => new UserVehiclesDeleteSuccessAction(r, x.correlationId)),
        catchError(e => of(new UserVehiclesDeleteFailureAction(e, x.correlationId)))
      );
    })
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private fns: AngularFireFunctions) {}
}
