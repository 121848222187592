import { Injectable } from "@angular/core";
import { IProfileDetailsDispatcher } from "@client/interfaces/profile-details-interface";
import { Store } from "@ngrx/store";
import { ProfileDetailsDestroyAction, ProfileDetailsInitAction } from "@client/actions/profile-details-actions";
@Injectable()
export class ProfileDetailsDispatcher implements IProfileDetailsDispatcher {
    constructor(private store: Store<any>) {
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileDetailsDestroyAction(payload, correlationId));
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileDetailsInitAction(payload, correlationId));
    }
}
