import { State as VehiclesFormState } from "@client/reducers/vehicles-form-reducer";
import { Selector } from "@ngrx/store";
import { Vehicule } from "@client/utils/shared-constants";
export const getIsActive: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.isActive;
export const getHasSuccess: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.hasSuccess;
export const getHasError: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.hasError;
export const getIsLoading: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.isLoading;
export const getMessage: Selector<VehiclesFormState, string | null> = (state: VehiclesFormState) => state.message;
export const getIsEdit: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.isEdit;
export const getSelectedId: Selector<VehiclesFormState, string | null> = (state: VehiclesFormState) => state.selectedId;
export const getForm: Selector<VehiclesFormState, Vehicule> = (state: VehiclesFormState) => state.form;
export const getFormDescription: Selector<VehiclesFormState, string> = (state: VehiclesFormState) => getForm(state) && getForm(state).description;
export const getFormId: Selector<VehiclesFormState, number> = (state: VehiclesFormState) => getForm(state) && getForm(state).id;
export const getFormKey: Selector<VehiclesFormState, string> = (state: VehiclesFormState) => getForm(state) && getForm(state).key;
export const getFormLicencePlate: Selector<VehiclesFormState, string> = (state: VehiclesFormState) => getForm(state) && getForm(state).licencePlate;
export const getFormManufacturer: Selector<VehiclesFormState, string> = (state: VehiclesFormState) => getForm(state) && getForm(state).manufacturer;
export const getFormModel: Selector<VehiclesFormState, string> = (state: VehiclesFormState) => getForm(state) && getForm(state).model;
export const getFormVehiculeYear: Selector<VehiclesFormState, string> = (state: VehiclesFormState) => getForm(state) && getForm(state).vehiculeYear;
export const getValidated: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.validated;
export const getIsValidationRequired: Selector<VehiclesFormState, boolean> = (state: VehiclesFormState) => state.isValidationRequired;
