import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { TelemetryService } from '../../telemetry';

@Injectable()
export class TelemetryEffects {
  @Effect({ dispatch: false })
  logAll$ = this.actions$.pipe(map(x => this.telemetry.track(x)));

  constructor(private actions$: Actions, private telemetry: TelemetryService) {}
}
