import { Injectable } from '@angular/core';
import { APP, AUTH, PROFILE, SESSION } from '@client/actions';
import { AppInitAction } from '@client/actions/app-actions';
import { SessionDestroyAction, SessionInitAction, SessionReloadFailureAction, SessionReloadSuccessAction, SessionStartServiceAction, SessionStateChangeAction } from '@client/actions/session-actions';
import { SessionService } from '@client/core/services/session.service';
import { Action } from '@client/lib/action';
import { initialState } from '@client/reducers/session-reducer';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, pairwise, startWith, switchMap } from 'rxjs/operators';
import {UserSyncAction} from "@client/actions/user-actions";

@Injectable()
export class SessionEffects {
  @Effect()
  appInit$: Observable<Action> = this.actions$.pipe(
    ofType<AppInitAction>(APP.INIT),
    mergeMap(action => [new SessionStartServiceAction(null, action.correlationId)])
  );
  @Effect()
  sessionReload$: Observable<Action> = this.actions$.pipe(
    ofType<Action>(SESSION.RELOAD, AUTH.UPDATE_EMAIL_SUCCESS, AUTH.UPDATE_PASSWORD_SUCCESS, PROFILE.UPDATE_SUCCESS),
    switchMap(action =>
      this.session.reload().pipe(
        mergeMap(state => [new SessionReloadSuccessAction(state, action.correlationId), new SessionStateChangeAction(state, action.correlationId)]),
        catchError(e => of(new SessionReloadFailureAction(e, action.correlationId)))
      )
    )
  );
  @Effect()
  sessionStateChange$: Observable<Action> = this.actions$.pipe(
    startWith(new SessionStateChangeAction(initialState, new Date().getTime())),
    ofType<SessionStateChangeAction>(SESSION.STATE_CHANGE),
    pairwise(),
    switchMap(([a, b]) => {
      if (!a.payload.uid && b.payload.uid) {
        return of(new SessionInitAction(null, b.correlationId));
      }
      if (a.payload.uid && !b.payload.uid) {
        return of(new SessionDestroyAction(null, a.correlationId));
      }

      return EMPTY;
    })
  );
  @Effect()
  startService$: Observable<Action> = this.actions$.pipe(
    ofType<SessionStartServiceAction>(SESSION.START_SERVICE),
    switchMap(action => this.session.start().pipe(map(state => new SessionStateChangeAction(state, action.correlationId))))
  );

  constructor(private actions$: Actions, private store: Store<any>, private session: SessionService) {}
}
