export const contractService = `

Société Parc-Auto du Québec (ci-après désignée « SPAQ ») établit les conditions de location suivantes :

###### 1. TARIFS

1. Les tarifs sont ceux en vigueur au moment de l’acceptation du contrat par le locataire via la solution en ligne d’abonnement de SPAQ (signature électronique). Les tarifs s’appliquent à un seul véhicule occupant une seule place de stationnement;
2. SPAQ se réserve le droit de modifier ses tarifs sans préavis;

###### 2. PAIEMENTS

1. Le loyer de l’espace est payable d’avance mensuellement, semestriellement ou annuellement selon le terme spécifié du service sélectionné par le locataire;
2. Le loyer peut être payé par paiement préautorisé, par prélèvement automatique sur carte de crédit, ou sur le site Internet de l’institution financière du locataire. Il peut aussi être payé au centre de service du stationnement. À noter qu’un abonnement complété via la solution en ligne de SPAQ peut restreindre les modes de paiement offerts en fonction du service qui est sélectionné par le locataire;
3. Aucun remboursement de loyer n’est accordé;
4. Tout retard de paiement portera intérêt à un taux mensuel de 1,5%, soit 18% par année.

###### 3. DOCUMENTS D’ACCÈS AU STATIONNEMENT
1. S’il y a lieu, SPAQ remet au locataire le document d’accès associé à son abonnement en échange d’un dépôt, lequel est remboursable à la fin du contrat. Un document d’accès est valide pourvu que le locataire ait satisfait aux conditions prévues au présent contrat;
2. S’il y a lieu, le document d’accès doit être apposé à l’endos du rétroviseur intérieur du véhicule du locataire et être visible en tout temps de l’extérieur;
3. Tout remplacement de document d’accès occasionne des frais payables sur réception du document de remplacement;
4. Il est interdit de transférer, prêter ou échanger un document d’accès, sauf dans le cas d’utilisation de véhicules additionnels. Le locataire est responsable d’enregistrer son ou ses véhicules dans la solution d’abonnement en ligne de SPAQ;
5. Un document d’accès tel qu’une carte magnétique, vignette ou plaque d’immatriculation n’est valide que pendant l’une des périodes suivantes :
  *   jour de 6 h à 18 h du lundi au vendredi; - 24 heures 7 jours par semaine;
  *   soir et nuit de 15 h à 9 h du lundi au vendredi et du vendredi 15 h au lundi 9 h; - ou toute autre plage horaire, tel que spécifié au service sélectionné par le locataire;
6. Un locataire qui se présente au stationnement sans son document d’accès doit acquitter le tarif horaire et ce, sans réduction du loyer mensuel;

###### 4. STATIONNEMENT PROHIBÉ

1. Le locataire s’engage à respecter les règlements de SPAQ incluant les enseignes, panneaux, marquage ou lignage sur la chaussée ou autres faits reprochés par la sécurité pour assurer la bonne marche du stationnement;
2. Il est interdit de stationner dans les allées ou d’occuper plus d’un espace de stationnement par véhicule;
3. Le locataire qui contrevient aux articles 4.1 et 4.2 reçoit une réclamation de dommage par non respect des règles édictées par SPAQ et doit en défrayer le coût;

###### 5. ANNULATION DU CONTRAT

1. SPAQ se réserve le droit d’annuler le présent contrat en tout temps et sans préjudice à ses autres recours si le client n’en respecte pas les conditions expressément contenues au présent contrat;
2. En outre des conditions déjà énoncées, le locataire est en défaut en vertu du présent contrat lorsqu’il ne respecte pas les règlements de conduite dans le parc de stationnement;
3. Dans le cas de fermeture, de transformation, de changement de vocation ou de perte du contrat de gestion, SPAQ se réserve le droit d’annuler le présent contrat avec un préavis de trente (30) jours;
4. Dans l’éventualité de l’application de 5.3, le locataire renonce à tout recours contre SPAQ;
5. Un locataire qui désire annuler son contrat de location doit le faire au moyen d’un écrit reçu par SPAQ au moins trente (30) jours avant la date de renouvellement automatique, soit de 1er de chaque mois;

###### 6. RESPONSABILITÉS ET DIVERS

1. SPAQ n’assume aucune responsabilité pour les blessures, dommages ou pertes résultant de feu, vol, collision ou tout autre méfait causé au ou par le véhicule et à ou par son contenu à moins que cela résulte du fait personnel de SPAQ ou l’un de ses représentants;
2. Le locataire dégage expressément SPAQ de toute responsabilité pour ce qui est mentionné à 6.1 et renonce à tout recours en ce sens;
3. Le locataire s’engage toutefois à aviser sans délai SPAQ de tout incident pouvant impliquer des blessures, dommages, pertes ou méfaits;
4. Le présent contrat ne peut être transféré, cédé ou autrement aliéné;

###### 7. PROTECTION DES RENSEIGNEMENTS PERSONNELS

1. SPAQ se conforme à la Loi sur la protection des renseignements personnels, et le locataire consent à SPAQ le droit de recueillir et d’utiliser ces dits renseignements dans le cadre de ses opérations en relation avec le contrat de location.
`;

export const contractPaymentDas = `
###### Conditions de paiement par déduction à la source
Veillez-vous référer aux conditions indiquées au formulaire d’autorisation de déduction à la source.
`;

export const contractPaymentInternet = `
###### Conditions de paiement par Internet Bancaire

1. Le locataire doit ajouter le fournisseur « société parc-auto du Québec » à partir de la solution en ligne de son institution financière. Le numéro du contrat de location qui sera confirmé au locataire après avoir complété son inscription devra être utilisé afin d’indexer le numéro du compte;
2. Si le service choisi par le locataire est un abonnement payable le premier de chaque mois, il devra effectuer son paiement au moins cinq jours ouvrables à l’avance afin que SPAQ reçoive le paiement de sa banque. À la réception du paiement par SPAQ, un reçu sera transmis au locataire;
3. Si la solution de paiement de l’institution financière le permet, SPAQ recommande au locataire d’activer la récurrence du paiement à date fixe permettant ainsi de s’assurer que l’accès au parc de stationnement ne lui soit pas retiré advenant que SPAQ n’est pas reçu la confirmation du paiement par sa banque dans les temps requis;
4. La somme indiquée de l’abonnement est le tarif en vigueur au moment de l’acceptation du présent contrat et peut être changée sans préavis suite à un changement de tarif. Le cas échant le locataire qui aura opté pour la récurrence du paiement à date fixe devra s’assurer d’ajuster le montant de la mensualité.
5. Toute révocation de la présente autorisation doit être faite par avis écrit;
6. SPAQ n’effectue aucun remboursement au locataire;
7. Toute délivrance de la présente autorisation est réputée faite par le locataire;
8. Dans le cas d’un compte conjoint du locataire avec une autre personne, il a la responsabilité de s’assurer de l’accord des autres personnes liées au compte.
`;

export const contractPaymentPpa = `
###### Conditions de paiement par prélèvement préautorisé

1. L’Institution financière mentionnée sur le spécimen de chèque joint au contrat de location (ci-après désignée « Institution financière ») est autorisée à payer et à porter au débit du compte tout effet payable à l’ordre de « Société Parc-Auto du Québec » (ci-après désignée « SPAQ ») tiré sur l’établissement de l’Institution financière par la banque de SPAQ ou sur instruction directe de cette dernière;
2. Pour le traitement de chacun de ces débits, l’Institution financière procède exactement comme si le locataire avait à chaque fois demandé à l’Institution financière de payer la mensualité et d’en porter le montant au débit du compte du locataire;
3. La somme indiquée de l’abonnement est le tarif en vigueur au moment de l’acceptation du présent contrat et peut être changée sans préavis suite à un changement de tarif;
4. Toute révocation de la présente autorisation doit être faite par avis écrit;
5. SPAQ n’effectue aucun remboursement au locataire;
6. Toute délivrance de la présente autorisation est réputée faite par le locataire;
7. Dans le cas d’un compte conjoint du locataire avec une autre personne ou lorsque plus d’une signature est requise dans le compte, tous les signataires dudit compte doivent signer le spécimen de chèque à transmettre via la solution en ligne d’abonnement de SPAQ.
`;

export const contractPaymentCreditCard = `
###### Conditions de paiement par carte de crédit

1. L’émetteur de la carte de crédit inscrit par le locataire est autorisée à payer et à porter au débit du compte tout effet payable à l’ordre de « Société Parc-Auto du Québec » (ci-après désignée « SPAQ »);
2. Pour le traitement de chacun de ces débits, l’émetteur de la carte de crédit procède exactement comme si le locataire avait à chaque fois demandé à son émetteur de carte de crédit de payer la mensualité et d’en porter le montant au débit du compte du locataire;
3. La somme indiquée de l’abonnement est le tarif en vigueur au moment de l’acceptation du présent contrat et peut être changée sans préavis suite à un changement de tarif;
4. Toute révocation de la présente autorisation doit être faite par avis écrit;
5. SPAQ n’effectue aucun remboursement au locataire;
6. Toute délivrance de la présente autorisation est réputée faite par le locataire;
`;
