import { State as ContractState } from "@client/reducers/contract-reducer";
import { Selector } from "@ngrx/store";
import { ContractInfo, ParkInfo, ContractCostInfo, ServiceInfo, ServiceMetaInfo, SubscriptionService, SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo, Vehicule } from "@client/utils/shared-constants";
export const getMessage: Selector<ContractState, string | null> = (state: ContractState) => state.message;
export const getHasError: Selector<ContractState, boolean> = (state: ContractState) => state.hasError;
export const getHasSuccess: Selector<ContractState, boolean> = (state: ContractState) => state.hasSuccess;
export const getIsLoaded: Selector<ContractState, boolean> = (state: ContractState) => state.isLoaded;
export const getIsLoading: Selector<ContractState, boolean> = (state: ContractState) => state.isLoading;
export const getIsPending: Selector<ContractState, boolean> = (state: ContractState) => state.isPending;
export const getPaymenRequired: Selector<ContractState, boolean> = (state: ContractState) => state.paymenRequired;
export const getIsCancelable: Selector<ContractState, boolean> = (state: ContractState) => state.isCancelable;
export const getIsEnded: Selector<ContractState, boolean> = (state: ContractState) => state.isEnded;
export const getData: Selector<ContractState, ContractInfo | null> = (state: ContractState) => state.data;
export const getDataIsCreationCompleted: Selector<ContractState, boolean> = (state: ContractState) => getData(state) && getData(state).isCreationCompleted;
export const getDataIsPaid: Selector<ContractState, boolean> = (state: ContractState) => getData(state) && getData(state).isPaid;
export const getDataPaymentBeforeApproval: Selector<ContractState, boolean> = (state: ContractState) => getData(state) && getData(state).paymentBeforeApproval;
export const getDataPark: Selector<ContractState, ParkInfo> = (state: ContractState) => getData(state) && getData(state).park;
export const getDataParkCity: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).city;
export const getDataParkCivicNumber1: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).civicNumber1;
export const getDataParkCivicNumber2: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).civicNumber2;
export const getDataParkEmail: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).email;
export const getDataParkGpsLatitude: Selector<ContractState, number> = (state: ContractState) => getDataPark(state) && getDataPark(state).gpsLatitude;
export const getDataParkGpsLongitude: Selector<ContractState, number> = (state: ContractState) => getDataPark(state) && getDataPark(state).gpsLongitude;
export const getDataParkParkIdentificationNo: Selector<ContractState, number> = (state: ContractState) => getDataPark(state) && getDataPark(state).parkIdentificationNo;
export const getDataParkParkName: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).parkName;
export const getDataParkPhoneExtension: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).phoneExtension;
export const getDataParkPhoneNo: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).phoneNo;
export const getDataParkPostalCode: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).postalCode;
export const getDataParkProvince: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).province;
export const getDataParkServerHostName: Selector<ContractState, string> = (state: ContractState) => getDataPark(state) && getDataPark(state).serverHostName;
export const getDataCost: Selector<ContractState, ContractCostInfo> = (state: ContractState) => getData(state) && getData(state).cost;
export const getDataCostInvoiceId: Selector<ContractState, number> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceId;
export const getDataCostInvoiceDate: Selector<ContractState, string> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceDate;
export const getDataCostInvoiceDescription: Selector<ContractState, string> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceDescription;
export const getDataCostPaidAmount: Selector<ContractState, number> = (state: ContractState) => getDataCost(state) && getDataCost(state).paidAmount;
export const getDataCostInvoiceAmount: Selector<ContractState, number> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceAmount;
export const getDataCostInvoiceTax1: Selector<ContractState, number> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceTax1;
export const getDataCostInvoiceTax2: Selector<ContractState, number> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceTax2;
export const getDataCostInvoiceTotalAmount: Selector<ContractState, number> = (state: ContractState) => getDataCost(state) && getDataCost(state).invoiceTotalAmount;
export const getDataService: Selector<ContractState, ServiceInfo> = (state: ContractState) => getData(state) && getData(state).service;
export const getDataServicePeriod: Selector<ContractState, any> = (state: ContractState) => getDataService(state) && getDataService(state).period;
export const getDataServiceAvailableFrom: Selector<ContractState, string | null> = (state: ContractState) => getDataService(state) && getDataService(state).availableFrom;
export const getDataServiceAvailableServiceId: Selector<ContractState, number> = (state: ContractState) => getDataService(state) && getDataService(state).availableServiceId;
export const getDataServiceAvailableUntil: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).availableUntil;
export const getDataServiceCancelationNotice: Selector<ContractState, number> = (state: ContractState) => getDataService(state) && getDataService(state).cancelationNotice;
export const getDataServiceCancelationNoticeTimeUnit: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).cancelationNoticeTimeUnit;
export const getDataServiceDuration: Selector<ContractState, number> = (state: ContractState) => getDataService(state) && getDataService(state).duration;
export const getDataServiceDurationTimeUnit: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).durationTimeUnit;
export const getDataServiceIsBankingCardPaymentAccepted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isBankingCardPaymentAccepted;
export const getDataServiceIsCancelable: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isCancelable;
export const getDataServiceIsCancelationFirstDayOfMonth: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isCancelationFirstDayOfMonth;
export const getDataServiceIsDasPaymentAccepted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isDasPaymentAccepted;
export const getDataServiceIsFinancialInstitutionPaymentAccepted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isFinancialInstitutionPaymentAccepted;
export const getDataServiceIsFixedDuration: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isFixedDuration;
export const getDataServiceIsPpaPaymentAccepted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isPpaPaymentAccepted;
export const getDataServiceIsSubscriptionAvailable: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isSubscriptionAvailable;
export const getDataServiceIsTax1Exempted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isTax1Exempted;
export const getDataServiceIsTax2Exempted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isTax2Exempted;
export const getDataServiceIsTax3Exempted: Selector<ContractState, boolean> = (state: ContractState) => getDataService(state) && getDataService(state).isTax3Exempted;
export const getDataServiceOnSaleFrom: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).onSaleFrom;
export const getDataServiceOnSaleUntil: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).onSaleUntil;
export const getDataServiceParkIdentificationNo: Selector<ContractState, number> = (state: ContractState) => getDataService(state) && getDataService(state).parkIdentificationNo;
export const getDataServicePartialMonthCalculation: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).partialMonthCalculation;
export const getDataServicePrice: Selector<ContractState, number> = (state: ContractState) => getDataService(state) && getDataService(state).price;
export const getDataServiceSectorId: Selector<ContractState, number> = (state: ContractState) => getDataService(state) && getDataService(state).sectorId;
export const getDataServiceServiceBillingType: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).serviceBillingType;
export const getDataServiceServiceDescription: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).serviceDescription;
export const getDataServiceServiceStartRule: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).serviceStartRule;
export const getDataServiceServiceType: Selector<ContractState, string> = (state: ContractState) => getDataService(state) && getDataService(state).serviceType;
export const getDataServiceMeta: Selector<ContractState, ServiceMetaInfo> = (state: ContractState) => getDataService(state) && getDataService(state).meta;
export const getDataServiceMetaNote: Selector<ContractState, string> = (state: ContractState) => getDataServiceMeta(state) && getDataServiceMeta(state).note;
export const getDataPdfLink: Selector<ContractState, string | null> = (state: ContractState) => getData(state) && getData(state).pdfLink;
export const getDataPdfContent: Selector<ContractState, string | null> = (state: ContractState) => getData(state) && getData(state).pdfContent;
export const getDataPayment: Selector<ContractState, string | null> = (state: ContractState) => getData(state) && getData(state).payment;
export const getDataSectorId: Selector<ContractState, string | null> = (state: ContractState) => getData(state) && getData(state).sectorId;
export const getDataScopeId: Selector<ContractState, string | null> = (state: ContractState) => getData(state) && getData(state).scopeId;
export const getDataGroupId: Selector<ContractState, string | null> = (state: ContractState) => getData(state) && getData(state).groupId;
export const getDataSubscriptionService: Selector<ContractState, SubscriptionService> = (state: ContractState) => getData(state) && getData(state).subscriptionService;
export const getDataSubscriptionServiceEffectiveDate: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).effectiveDate;
export const getDataSubscriptionServiceEndDate: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).endDate;
export const getDataSubscriptionServiceIsApproved: Selector<ContractState, boolean> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).isApproved;
export const getDataSubscriptionServiceIsPaidByGroup: Selector<ContractState, boolean> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).isPaidByGroup;
export const getDataSubscriptionServiceServiceDescription: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).serviceDescription;
export const getDataSubscriptionServiceServiceId: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).serviceId;
export const getDataSubscriptionServiceId: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).id;
export const getDataSubscriptionServiceInvoiceAmount: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).invoiceAmount;
export const getDataSubscriptionServiceInvoiceTo: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).invoiceTo;
export const getDataSubscriptionServiceInvoiceFrom: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).invoiceFrom;
export const getDataSubscriptionServiceIsCancellationRequest: Selector<ContractState, boolean> = (state: ContractState) => getDataSubscriptionService(state) && getDataSubscriptionService(state).isCancellationRequest;
export const getDataSubscription: Selector<ContractState, SubscriptionInfo> = (state: ContractState) => getData(state) && getData(state).subscription;
export const getDataSubscriptionCustom: Selector<ContractState, any> = (state: ContractState) => getDataSubscription(state) && getDataSubscription(state).custom;
export const getDataSubscriptionSpaq: Selector<ContractState, SubscriptionSpaqInfo> = (state: ContractState) => getDataSubscription(state) && getDataSubscription(state).spaq;
export const getDataSubscriptionSpaqAccesDocuments: Selector<ContractState, AccesDocumentInfo[]> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).accesDocuments;
export const getDataSubscriptionSpaqAccessOnHoldReason: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).accessOnHoldReason;
export const getDataSubscriptionSpaqAddressLine1: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).addressLine1;
export const getDataSubscriptionSpaqIdentificationCode: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).identificationCode;
export const getDataSubscriptionSpaqAddressLine2: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).addressLine2;
export const getDataSubscriptionSpaqAppartmentNo: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).appartmentNo;
export const getDataSubscriptionSpaqBalance: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).balance;
export const getDataSubscriptionSpaqCellPhone: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).cellPhone;
export const getDataSubscriptionSpaqCity: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).city;
export const getDataSubscriptionSpaqContractNo: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).contractNo;
export const getDataSubscriptionSpaqDynamicFieldJsonData: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).dynamicFieldJsonData;
export const getDataSubscriptionSpaqEmail: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).email;
export const getDataSubscriptionSpaqFirstName: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).firstName;
export const getDataSubscriptionSpaqHomePhone: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).homePhone;
export const getDataSubscriptionSpaqHomePhoneExtension: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).homePhoneExtension;
export const getDataSubscriptionSpaqIsAccessOnHold: Selector<ContractState, boolean> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).isAccessOnHold;
export const getDataSubscriptionSpaqLanguage: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).language;
export const getDataSubscriptionSpaqLastName: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).lastName;
export const getDataSubscriptionSpaqPostalCode: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).postalCode;
export const getDataSubscriptionSpaqProvince: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).province;
export const getDataSubscriptionSpaqServiceCenter: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).serviceCenter;
export const getDataSubscriptionSpaqSex: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).sex;
export const getDataSubscriptionSpaqWorkPhone: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).workPhone;
export const getDataSubscriptionSpaqWorkPhoneExtension: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).workPhoneExtension;
export const getDataSubscriptionSpaqPaymentMethod: Selector<ContractState, PaymentMethodInfo> = (state: ContractState) => getDataSubscriptionSpaq(state) && getDataSubscriptionSpaq(state).paymentMethod;
export const getDataSubscriptionSpaqPaymentMethodCreditCardDataKey: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).CreditCardDataKey;
export const getDataSubscriptionSpaqPaymentMethodEffectiveDate: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).EffectiveDate;
export const getDataSubscriptionSpaqPaymentMethodEndDate: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).EndDate;
export const getDataSubscriptionSpaqPaymentMethodFinancialInstitutionId: Selector<ContractState, number> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).FinancialInstitutionId;
export const getDataSubscriptionSpaqPaymentMethodFolioNo: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).FolioNo;
export const getDataSubscriptionSpaqPaymentMethodMethod: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).Method;
export const getDataSubscriptionSpaqPaymentMethodTransitNo: Selector<ContractState, string> = (state: ContractState) => getDataSubscriptionSpaqPaymentMethod(state) && getDataSubscriptionSpaqPaymentMethod(state).TransitNo;
export const getDataVehicles: Selector<ContractState, Vehicule[]> = (state: ContractState) => getData(state) && getData(state).vehicles;
export const getDataConditionsServiceAcceptedAt: Selector<ContractState, string> = (state: ContractState) => getData(state) && getData(state).conditionsServiceAcceptedAt;
export const getDataConditionsPaymentAcceptedAt: Selector<ContractState, string> = (state: ContractState) => getData(state) && getData(state).conditionsPaymentAcceptedAt;
export const getDataCreatedAt: Selector<ContractState, string> = (state: ContractState) => getData(state) && getData(state).createdAt;
export const getDataPdfSuccess: Selector<ContractState, boolean> = (state: ContractState) => getData(state) && getData(state).pdfSuccess;
