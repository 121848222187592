import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { ProfileEditForm } from "@client/utils/shared-constants";
import { State } from "@client/reducers/profile-edit-reducer";
import { getProfileEditMessage, getProfileEditIsActive, getProfileEditHasError, getProfileEditHasSuccess, getProfileEditIsLoading, getProfileEditIsPending, getProfileEditForm, getProfileEditFormDisplayName, getProfileEditFormPhotoUrl } from "@client/selectors";
/*test*/ 
@Injectable()
export class ProfileEditStore extends BaseStore {
    get message(): Observable<string | null> {
        return this.store.pipe(select(getProfileEditMessage));
    }
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getProfileEditIsActive));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getProfileEditHasError));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getProfileEditHasSuccess));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getProfileEditIsLoading));
    }
    get isPending(): Observable<boolean> {
        return this.store.pipe(select(getProfileEditIsPending));
    }
    get form(): Observable<ProfileEditForm | null> {
        return this.store.pipe(select(getProfileEditForm));
    }
    get formDisplayName(): Observable<string> {
        return this.store.pipe(select(getProfileEditFormDisplayName));
    }
    get formPhotoUrl(): Observable<string> {
        return this.store.pipe(select(getProfileEditFormPhotoUrl));
    }
}
