import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const m = moment(value);
    return m.year() > 4000 ? 'Indéterminée' : m.format('LL');
  }
}
