import { NgModule } from '@angular/core';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { NgxMdModule } from 'ngx-md';
import { ConditionsAcceptorComponent } from './components';

@NgModule({
  declarations: [ConditionsAcceptorComponent],
  exports: [ConditionsAcceptorComponent],
  imports: [SharedCommonModule, NgxMdModule, SharedComponentsModule]
})
export class UtilsModule {}
