import { PARKS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ParkInfo } from "@client/utils/shared-constants";
export interface State extends EntityState<ParkInfo> {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selected: string | number | null;
}
export const selectId = (a: ParkInfo) => {
    return a.parkIdentificationNo;
};
export const sortByName = (a: ParkInfo, b: ParkInfo) => {
    return a.parkName.localeCompare(b.parkName);
};
export const adapter: EntityAdapter<ParkInfo> = createEntityAdapter<ParkInfo>({
    sortComparer: sortByName,
    selectId: selectId
});
export const initialState: State = adapter.getInitialState({
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selected: null
});
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PARKS.HYDRATE: return parksHydrate(state);
        case PARKS.HYDRATE_SUCCESS: return parksHydrateSuccess(state, action.payload);
        case PARKS.HYDRATE_FAILURE: return parksHydrateFailure(state, action.payload);
        case PARKS.SELECT: return parksSelect(state, action.payload);
        default: return state;
    }
}
export function parksHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true
    };
}
export function parksHydrateSuccess(state: State, payload: ParkInfo[]): State {
    return adapter.addAll(payload, {
        ...state,
        hasSuccess: true,
        isLoading: false,
    });
}
export function parksHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false
    };
}
export function parksSelect(state: State, payload: ParkInfo): State {
    return { ...state };
}
