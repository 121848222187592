import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { AccessCodeGroupInfo, AccessCodeServiceInfo, AccessCodeMetasInfo, AccessCodeMetasConditions, AccessCodeServiceCustomFields } from "@client/utils/shared-constants";
import { State } from "@client/reducers/access-code-reducer";
import { getAccessCodeData, getAccessCodeDataGroupCode, getAccessCodeDataGroupDescription, getAccessCodeDataOrganizationCode, getAccessCodeDataPublic, getAccessCodeDataServices, getAccessCodeDataMeta, getAccessCodeDataMetaConditions, getAccessCodeDataMetaConditionsService, getAccessCodeDataMetaConditionsPaymentDas, getAccessCodeDataMetaConditionsPaymentCc, getAccessCodeDataMetaConditionsPaymentPpa, getAccessCodeDataMetaConditionsPaymentInternet, getAccessCodeDataMetaCustomFields, getAccessCodeMessage, getAccessCodeHasError, getAccessCodeHasSuccess, getAccessCodeIsLoading, getAccessCodeIsPending, getAccessCodeIsHydrated, getAccessCodeIsActive } from "@client/selectors";
/*test*/ 
@Injectable()
export class AccessCodeStore extends BaseStore {
    get data(): Observable<AccessCodeGroupInfo | null> {
        return this.store.pipe(select(getAccessCodeData));
    }
    get dataGroupCode(): Observable<string> {
        return this.store.pipe(select(getAccessCodeDataGroupCode));
    }
    get dataGroupDescription(): Observable<string> {
        return this.store.pipe(select(getAccessCodeDataGroupDescription));
    }
    get dataOrganizationCode(): Observable<string> {
        return this.store.pipe(select(getAccessCodeDataOrganizationCode));
    }
    get dataPublic(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeDataPublic));
    }
    get dataServices(): Observable<AccessCodeServiceInfo[]> {
        return this.store.pipe(select(getAccessCodeDataServices));
    }
    get dataMeta(): Observable<AccessCodeMetasInfo> {
        return this.store.pipe(select(getAccessCodeDataMeta));
    }
    get dataMetaConditions(): Observable<AccessCodeMetasConditions> {
        return this.store.pipe(select(getAccessCodeDataMetaConditions));
    }
    get dataMetaConditionsService(): Observable<string | null> {
        return this.store.pipe(select(getAccessCodeDataMetaConditionsService));
    }
    get dataMetaConditionsPaymentDas(): Observable<string | null> {
        return this.store.pipe(select(getAccessCodeDataMetaConditionsPaymentDas));
    }
    get dataMetaConditionsPaymentCc(): Observable<string | null> {
        return this.store.pipe(select(getAccessCodeDataMetaConditionsPaymentCc));
    }
    get dataMetaConditionsPaymentPpa(): Observable<string> {
        return this.store.pipe(select(getAccessCodeDataMetaConditionsPaymentPpa));
    }
    get dataMetaConditionsPaymentInternet(): Observable<string | null> {
        return this.store.pipe(select(getAccessCodeDataMetaConditionsPaymentInternet));
    }
    get dataMetaCustomFields(): Observable<AccessCodeServiceCustomFields[]> {
        return this.store.pipe(select(getAccessCodeDataMetaCustomFields));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getAccessCodeMessage));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeHasError));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeHasSuccess));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeIsLoading));
    }
    get isPending(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeIsPending));
    }
    get isHydrated(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeIsHydrated));
    }
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeIsActive));
    }
}
