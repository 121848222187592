import { State as ProfileEditState } from "@client/reducers/profile-edit-reducer";
import { Selector } from "@ngrx/store";
import { ProfileEditForm } from "@client/utils/shared-constants";
export const getMessage: Selector<ProfileEditState, string | null> = (state: ProfileEditState) => state.message;
export const getIsActive: Selector<ProfileEditState, boolean> = (state: ProfileEditState) => state.isActive;
export const getHasError: Selector<ProfileEditState, boolean> = (state: ProfileEditState) => state.hasError;
export const getHasSuccess: Selector<ProfileEditState, boolean> = (state: ProfileEditState) => state.hasSuccess;
export const getIsLoading: Selector<ProfileEditState, boolean> = (state: ProfileEditState) => state.isLoading;
export const getIsPending: Selector<ProfileEditState, boolean> = (state: ProfileEditState) => state.isPending;
export const getForm: Selector<ProfileEditState, ProfileEditForm | null> = (state: ProfileEditState) => state.form;
export const getFormDisplayName: Selector<ProfileEditState, string> = (state: ProfileEditState) => getForm(state) && getForm(state).displayName;
export const getFormPhotoUrl: Selector<ProfileEditState, string> = (state: ProfileEditState) => getForm(state) && getForm(state).photoURL;
