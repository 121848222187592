import { State as AdminOrganizationListState } from "@client/reducers/admin-organization-list-reducer";
import { Selector } from "@ngrx/store";
import { OrganizationInfo, OrganizationConfigInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => state.isActive;
export const getMessage: Selector<AdminOrganizationListState, string | null> = (state: AdminOrganizationListState) => state.message;
export const getHasError: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => state.hasError;
export const getHasSuccess: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => state.isLoading;
export const getData: Selector<AdminOrganizationListState, OrganizationInfo[]> = (state: AdminOrganizationListState) => state.data;
export const getSelectedOrganization: Selector<AdminOrganizationListState, OrganizationInfo | null> = (state: AdminOrganizationListState) => state.selectedOrganization;
export const getSelectedOrganizationAdresse: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).adresse;
export const getSelectedOrganizationCity: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).city;
export const getSelectedOrganizationOrganizationCode: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).organizationCode;
export const getSelectedOrganizationOrganizationName: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).organizationName;
export const getSelectedOrganizationPhoneNumber: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).phoneNumber;
export const getSelectedOrganizationPostalCode: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).postalCode;
export const getSelectedOrganizationProvince: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).province;
export const getSelectedOrganizationLogo: Selector<AdminOrganizationListState, string> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).logo;
export const getSelectedOrganizationConfig: Selector<AdminOrganizationListState, OrganizationConfigInfo | null> = (state: AdminOrganizationListState) => getSelectedOrganization(state) && getSelectedOrganization(state).config;
export const getSelectedOrganizationConfigEnableGateway: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => getSelectedOrganizationConfig(state) && getSelectedOrganizationConfig(state).enableGateway;
export const getSelectedOrganizationConfigEnablePublicRegistration: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => getSelectedOrganizationConfig(state) && getSelectedOrganizationConfig(state).enablePublicRegistration;
export const getSelectedOrganizationConfigEnablePrivateRegistration: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => getSelectedOrganizationConfig(state) && getSelectedOrganizationConfig(state).enablePrivateRegistration;
export const getSelectedOrganizationConfigDisablePublicSubscriptionService: Selector<AdminOrganizationListState, boolean> = (state: AdminOrganizationListState) => getSelectedOrganizationConfig(state) && getSelectedOrganizationConfig(state).disablePublicSubscriptionService;
