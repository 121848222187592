import { State as TestFormState } from "@client/reducers/test-form-reducer";
import { Selector } from "@ngrx/store";
import { TestInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<TestFormState, boolean> = (state: TestFormState) => state.isActive;
export const getMessage: Selector<TestFormState, string | null> = (state: TestFormState) => state.message;
export const getHasError: Selector<TestFormState, boolean> = (state: TestFormState) => state.hasError;
export const getHasSuccess: Selector<TestFormState, boolean> = (state: TestFormState) => state.hasSuccess;
export const getIsLoading: Selector<TestFormState, boolean> = (state: TestFormState) => state.isLoading;
export const getIsEdit: Selector<TestFormState, boolean> = (state: TestFormState) => state.isEdit;
export const getForm: Selector<TestFormState, TestInfo> = (state: TestFormState) => state.form;
export const getFormId: Selector<TestFormState, number> = (state: TestFormState) => getForm(state) && getForm(state).id;
export const getFormName: Selector<TestFormState, string> = (state: TestFormState) => getForm(state) && getForm(state).name;
export const getFormWhen: Selector<TestFormState, string> = (state: TestFormState) => getForm(state) && getForm(state).when;
export const getFormTimestamp: Selector<TestFormState, number> = (state: TestFormState) => getForm(state) && getForm(state).timestamp;
export const getFormGroupId: Selector<TestFormState, string> = (state: TestFormState) => getForm(state) && getForm(state).group_id;
export const getFormLevel: Selector<TestFormState, string> = (state: TestFormState) => getForm(state) && getForm(state).level;
export const getSelectedGroupId: Selector<TestFormState, string> = (state: TestFormState) => state.selectedGroupId;
