import { State as LayoutState } from "@client/reducers/layout-reducer";
import { Selector } from "@ngrx/store";
import { LayoutScreenInfo, OrganizationConfigInfo, NodeItem } from "@client/utils/shared-constants";
export const getIsPublicArea: Selector<LayoutState, boolean> = (state: LayoutState) => state.isPublicArea;
export const getShowSidebav: Selector<LayoutState, boolean> = (state: LayoutState) => state.showSidebav;
export const getIsScope: Selector<LayoutState, boolean> = (state: LayoutState) => state.isScope;
export const getScopeId: Selector<LayoutState, string> = (state: LayoutState) => state.scopeId;
export const getScreen: Selector<LayoutState, LayoutScreenInfo> = (state: LayoutState) => state.screen;
export const getScreenDesktop: Selector<LayoutState, boolean> = (state: LayoutState) => getScreen(state) && getScreen(state).desktop;
export const getScreenMobile: Selector<LayoutState, boolean> = (state: LayoutState) => getScreen(state) && getScreen(state).mobile;
export const getScreenTablet: Selector<LayoutState, boolean> = (state: LayoutState) => getScreen(state) && getScreen(state).tablet;
export const getScopeConfig: Selector<LayoutState, OrganizationConfigInfo> = (state: LayoutState) => state.scopeConfig;
export const getScopeConfigEnableGateway: Selector<LayoutState, boolean> = (state: LayoutState) => getScopeConfig(state) && getScopeConfig(state).enableGateway;
export const getScopeConfigEnablePublicRegistration: Selector<LayoutState, boolean> = (state: LayoutState) => getScopeConfig(state) && getScopeConfig(state).enablePublicRegistration;
export const getScopeConfigEnablePrivateRegistration: Selector<LayoutState, boolean> = (state: LayoutState) => getScopeConfig(state) && getScopeConfig(state).enablePrivateRegistration;
export const getScopeConfigDisablePublicSubscriptionService: Selector<LayoutState, boolean> = (state: LayoutState) => getScopeConfig(state) && getScopeConfig(state).disablePublicSubscriptionService;
export const getEnableAdminOptions: Selector<LayoutState, boolean> = (state: LayoutState) => state.enableAdminOptions;
export const getIsAdminMenu: Selector<LayoutState, boolean> = (state: LayoutState) => state.isAdminMenu;
export const getSelectedGroup: Selector<LayoutState, string | null> = (state: LayoutState) => state.selectedGroup;
export const getSelectedItem: Selector<LayoutState, string | null> = (state: LayoutState) => state.selectedItem;
export const getAdminMenu: Selector<LayoutState, {
    [k: string]: NodeItem | null;
}> = (state: LayoutState) => state.admin_menu;
export const getMenu: Selector<LayoutState, {
    [k: string]: NodeItem | null;
}> = (state: LayoutState) => state.menu;
