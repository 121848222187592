import { USER_VEHICLES } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    hasSuccess: boolean;
    hasError: boolean;
    isLoading: boolean;
    message: string | null;
    collection: Vehicule[];
}
export const initialState: State = {
    hasSuccess: false,
    hasError: false,
    isLoading: false,
    message: null,
    collection: []
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case USER_VEHICLES.START_SERVICE: return userVehiclesStartService(state);
        case USER_VEHICLES.STOP_SERVICE: return userVehiclesStopService(state);
        case USER_VEHICLES.HYDRATE: return userVehiclesHydrate(state);
        case USER_VEHICLES.HYDRATE_SUCCESS: return userVehiclesHydrateSuccess(state, action.payload);
        case USER_VEHICLES.HYDRATE_FAILURE: return userVehiclesHydrateFailure(state, action.payload);
        case USER_VEHICLES.DELETE: return userVehiclesDelete(state, action.payload);
        case USER_VEHICLES.DELETE_SUCCESS: return userVehiclesDeleteSuccess(state, action.payload);
        case USER_VEHICLES.DELETE_FAILURE: return userVehiclesDeleteFailure(state, action.payload);
        default: return state;
    }
}
export function userVehiclesStartService(state: State): State {
    return state;
}
export function userVehiclesStopService(state: State): State {
    return state;
}
export function userVehiclesHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function userVehiclesHydrateSuccess(state: State, payload: Vehicule[]): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        collection: payload,
    };
}
export function userVehiclesHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function userVehiclesDelete(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function userVehiclesDeleteSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function userVehiclesDeleteFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
