import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_FORM, ADMIN_GROUP_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeGroupInfo, OrganizationInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: AccessCodeGroupInfo[];
    selecteGroup: AccessCodeGroupInfo | null;
    selectedOrganizationCode: string;
    showOrganizationBacklink: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    selecteGroup: null,
    selectedOrganizationCode: "",
    showOrganizationBacklink: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_GROUP_LIST.INIT: return adminGroupListInit(state);
        case ADMIN_GROUP_LIST.DESTROY: return adminGroupListDestroy(state);
        case ADMIN_GROUP_LIST.HYDRATE: return adminGroupListHydrate(state);
        case ADMIN_GROUP_LIST.HYDRATE_SUCCESS: return adminGroupListHydrateSuccess(state, action.payload);
        case ADMIN_GROUP_LIST.HYDRATE_FAILURE: return adminGroupListHydrateFailure(state, action.payload);
        case ADMIN_GROUP_LIST.DELETE: return adminGroupListDelete(state);
        case ADMIN_GROUP_LIST.DELETE_SUCCESS: return adminGroupListDeleteSuccess(state);
        case ADMIN_GROUP_LIST.DELETE_FAILURE: return adminGroupListDeleteFailure(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_ADD: return adminGroupListClickAdd(state);
        case ADMIN_GROUP_LIST.CLICK_EDIT: return adminGroupListClickEdit(state);
        case ADMIN_GROUP_LIST.CLICK_CONFIGURATION: return adminGroupListClickConfiguration(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_DELETE: return adminGroupListClickDelete(state);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT_ORGANIZATION_GROUP: return adminGroupListSelectOrganizationGroup(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_SHOW_SERVICES: return adminGroupListClickShowServices(state);
        case ADMIN_GROUP_LIST.CLICK_SHOW_CODES: return adminGroupListClickShowCodes(state);
        case ADMIN_GROUP_LIST.CLICK_SHOW_METAS: return adminGroupListClickShowMetas(state);
        case ADMIN_GROUP_LIST.CLICK_ORGANIZATION_BACKLINK: return adminGroupListClickOrganizationBacklink(state);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SHOW_GROUPS: return adminOrganizationListShowGroups(state, action.payload);
        case ADMIN_GROUP_FORM.UPDATE_SUCCESS: return adminGroupFormUpdateSuccess(state);
        case ADMIN_GROUP_FORM.ADD_SUCCESS: return adminGroupFormAddSuccess(state);
        default: return state;
    }
}
export function adminGroupListInit(state: State): State {
    return {
        ...initialState,
        data: state.data,
        selectedOrganizationCode: state.selectedOrganizationCode,
        selecteGroup: state.selecteGroup,
        isActive: true
    };
}
export function adminGroupListDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminGroupListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminGroupListHydrateSuccess(state: State, payload: AccessCodeGroupInfo[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload
    };
}
export function adminGroupListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminGroupListDelete(state: State): State {
    return {
        ...state,
        message: null,
        hasError: false
    };
}
export function adminGroupListDeleteSuccess(state: State): State {
    return {
        ...state,
        selecteGroup: initialState.selecteGroup,
    };
}
export function adminGroupListDeleteFailure(state: State, payload: any): State {
    return { ...state, hasError: true, message: payload };
}
export function adminGroupListClickAdd(state: State): State {
    return state;
}
export function adminGroupListClickEdit(state: State): State {
    return state;
}
export function adminGroupListClickConfiguration(state: State, payload: AccessCodeGroupInfo): State {
    return state;
}
export function adminGroupListClickDelete(state: State): State {
    return state;
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        selecteGroup: payload
    };
}
export function adminGroupListSelectOrganizationGroup(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload };
}
export function adminGroupListClickShowServices(state: State): State {
    return state;
}
export function adminGroupListClickShowCodes(state: State): State {
    return state;
}
export function adminGroupListClickShowMetas(state: State): State {
    return state;
}
export function adminGroupListClickOrganizationBacklink(state: State): State {
    return state;
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return { ...state, selectedOrganizationCode: payload.organizationCode };
}
export function adminOrganizationListShowGroups(state: State, payload: OrganizationInfo): State {
    return { ...state, selectedOrganizationCode: payload.organizationCode };
}
export function adminGroupFormUpdateSuccess(state: State): State {
    return {
        ...state,
        selecteGroup: initialState.selecteGroup,
    };
}
export function adminGroupFormAddSuccess(state: State): State {
    return {
        ...state,
        selecteGroup: initialState.selecteGroup,
    };
}
