import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {SubscriptionInfo, Vehicule} from '@client/utils/shared-constants';
import {EMPTY, from, Observable} from 'rxjs';
import {switchMap} from "rxjs/operators";
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class SubscriptionService {
  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {}

  hydrate(): Observable<SubscriptionInfo> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => u ? this.db.object<SubscriptionInfo>(`/user/${u.uid}/subscription`).valueChanges() : EMPTY)
    );
  }
}
