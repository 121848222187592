import { Injectable } from '@angular/core';
import { WindowAfterPrintAction, WindowBeforePrintAction, WindowCopyAction, WindowCutAction, WindowFullscreenChangeAction, WindowPasteAction, WindowResizeAction } from '@client/actions/window-actions';
import { createEffect } from '@ngrx/effects';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mapTo, startWith } from 'rxjs/operators';

@Injectable()
export class WindowEffects {
  afterPrint$ = createEffect(() => fromEvent(window, 'afterprint').pipe(mapTo(new WindowAfterPrintAction())));
  beforePrint$ = createEffect(() => fromEvent(window, 'beforeprint').pipe(mapTo(new WindowBeforePrintAction())));
  copy$ = createEffect(() => fromEvent(document, 'copy').pipe(mapTo(new WindowCopyAction())));
  cut$ = createEffect(() => fromEvent(document, 'cut').pipe(mapTo(new WindowCutAction())));
  fullscreenChange$ = createEffect(() => fromEvent(document, 'fullscreenchange').pipe(mapTo(new WindowFullscreenChangeAction())));
  paste$ = createEffect(() => fromEvent(document, 'paste').pipe(mapTo(new WindowPasteAction())));
  resize$ = createEffect(() =>
    fromEvent(window, 'resize').pipe(
      debounceTime(200),
      map(() => window.innerWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth),
      mapTo(new WindowResizeAction())
    )
  );

  constructor() {}
}
