import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import {AccountStatusState, SubscriptionInfo} from '@client/utils/shared-constants';
import firebase from 'firebase';
import User = firebase.User;
import {EMPTY, from, Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Injectable()
export class AccountStatusService {

  itemsRef: AngularFireList<AccountStatusState> | Observable<never>;

  constructor(private db: AngularFireDatabase, private auth: AngularFireAuth) {
    auth.authState
      .pipe(
        tap((u: User) => {
          this.itemsRef = u ? this.db.list<AccountStatusState>(`/user/${u.uid}/accountStatus`) : EMPTY;
        })
      )
      .subscribe();
  }

  hydrate() {
    return this.auth.user.pipe(
      switchMap((u: User) => u ? this.db.object<AccountStatusState>(`/user/${u.uid}/accountStatus`).valueChanges() : EMPTY)
    );
  }

  update(status: any) {
    return this.auth.user.pipe(
      switchMap((u: User) => u ? this.db.object<void>(`/user/${u.uid}/accountStatus`).update(status) : EMPTY)
    );
  }
}
