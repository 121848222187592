import { FAQ } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { FaqDataInfo, FaqInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    message: string | null;
    isHydrated: boolean;
    collection: FaqDataInfo;
    selectedFaqId: string;
    errorMessage: string | null;
    successMessage: string | null;
    selectedOrganizationId: number;
    isLoading: boolean;
}
export const initialState: State = {
    isActive: false,
    hasSuccess: false,
    hasError: false,
    message: null,
    isHydrated: false,
    collection: {
        groups: [],
        list: []
    },
    selectedFaqId: "",
    errorMessage: null,
    successMessage: null,
    selectedOrganizationId: 0,
    isLoading: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case FAQ.HYDRATE: return faqHydrate(state);
        case FAQ.HYDRATE_SUCCESS: return faqHydrateSuccess(state, action.payload);
        case FAQ.HYDRATE_FAILURE: return faqHydrateFailure(state, action.payload);
        case FAQ.INIT: return faqInit(state);
        case FAQ.DESTROY: return faqDestroy(state);
        case FAQ.ON_BLUR_ITEM: return faqOnBlurItem(state);
        case FAQ.ON_CLICK_ITEM: return faqOnClickItem(state, action.payload);
        case FAQ.ON_FOCUS_ITEM: return faqOnFocusItem(state);
        default: return state;
    }
}
export function faqHydrate(state: State): State {
    return {
        ...state,
        isLoading: false,
        message: null,
        hasSuccess: false,
        hasError: false,
        isHydrated: false
    };
}
export function faqHydrateSuccess(state: State, payload: FaqDataInfo): State {
    return { ...state, collection: payload, isHydrated: true, hasSuccess: true };
}
export function faqHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        message: payload,
        hasError: true,
        isLoading: false
    };
}
export function faqInit(state: State): State {
    return {
        ...initialState,
        collection: state.collection,
        isActive: true,
    };
}
export function faqDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function faqOnBlurItem(state: State): State {
    return state;
}
export function faqOnClickItem(state: State, payload: FaqInfo): State {
    return {
        ...state,
        selectedFaqId: state.selectedFaqId === payload.id ? null : payload.id
    };
}
export function faqOnFocusItem(state: State): State {
    return state;
}
