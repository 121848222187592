import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from '@client/shared/pipes.module';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { ParkDetailComponent, ParkDetailItemComponent, ParkListComponent, ParkListDetailsComponent, ParksSelectComponent } from './components';
import {InputsModule} from "@progress/kendo-angular-inputs";
import {ButtonsModule} from "@progress/kendo-angular-buttons";

@NgModule({
  imports: [SharedCommonModule, GridModule, TranslateModule, SharedComponentsModule, InputsModule, ButtonsModule, PipesModule, SharedComponentsModule, SharedCommonModule],
  declarations: [ParkListComponent, ParkDetailComponent, ParkListDetailsComponent, ParksSelectComponent, ParkDetailItemComponent],
  exports: [ParkListComponent, ParkDetailComponent, ParkListDetailsComponent, ParksSelectComponent]
})
export class ParksModule {
  static forRoot(): ModuleWithProviders<ParksModule> {
    return {
      ngModule: ParksModule,
      providers: []
    };
  }
}
