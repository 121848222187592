import { Injectable } from '@angular/core';
import { PROFILE, PROFILE_EDIT, SESSION } from '@client/actions';
import { ProfileHydrateAction, ProfileUpdateAction, ProfileUpdateFailureAction, ProfileUpdateSuccessAction } from '@client/actions/profile-actions';
import { ProfileEditClickSubmitAction } from '@client/actions/profile-edit-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { ProfileService } from '@client/core/services/profile.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class ProfileEffects {
  @Effect({ dispatch: true })
  profileEditClickSubmit$ = this.actions$.pipe(
    ofType<ProfileEditClickSubmitAction>(PROFILE_EDIT.CLICK_SUBMIT),
    map(action => new ProfileUpdateAction(action.payload, action.correlationId))
  );
  @Effect({ dispatch: true })
  profileUpdate$ = this.actions$.pipe(
    ofType<ProfileUpdateAction>(PROFILE.UPDATE),
    switchMap(action =>
      this.profile.updateProfile(action.payload).pipe(
        map(d => new ProfileUpdateSuccessAction(d, action.correlationId)),
        catchError(error => of(new ProfileUpdateFailureAction(error, action.correlationId)))
      )
    )
  );
  @Effect({ dispatch: true })
  sessionInit$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.profile, of(new ProfileHydrateAction(null, action.correlationId)), EMPTY))
  );

  constructor(private actions$: Actions, private store: Store<any>, private profile: ProfileService) {}
}
