import { ACCESS_CODE_DETAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
}
export const initialState: State = {
    isActive: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ACCESS_CODE_DETAILS.INIT: return accessCodeDetailsInit(state);
        case ACCESS_CODE_DETAILS.DESTROY: return accessCodeDetailsDestroy(state);
        case ACCESS_CODE_DETAILS.CLICK_ADD_ACCESS_CODE: return accessCodeDetailsClickAddAccessCode(state);
        default: return state;
    }
}
export function accessCodeDetailsInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function accessCodeDetailsDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function accessCodeDetailsClickAddAccessCode(state: State): State {
    return state;
}
