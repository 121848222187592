import { CONTRACT, SERVICES } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ServiceInfo, ContractInfo } from "@client/utils/shared-constants";
export interface State extends EntityState<ServiceInfo> {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    canSubscribe: boolean;
    selected: string | number | null;
}
export const selectId = (a: ServiceInfo) => {
    return a.availableServiceId;
};
export const sortByName = (a: ServiceInfo, b: ServiceInfo) => {
    return a.serviceDescription.localeCompare(b.serviceDescription);
};
export const adapter: EntityAdapter<ServiceInfo> = createEntityAdapter<ServiceInfo>({
    sortComparer: sortByName,
    selectId: selectId
});
export const initialState: State = adapter.getInitialState({
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    canSubscribe: false,
    selected: null
});
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SERVICES.HYDRATE: return servicesHydrate(state);
        case SERVICES.HYDRATE_SUCCESS: return servicesHydrateSuccess(state, action.payload);
        case SERVICES.HYDRATE_FAILURE: return servicesHydrateFailure(state, action.payload);
        case CONTRACT.HYDRATE_SUCCESS: return contractHydrateSuccess(state, action.payload);
        default: return state;
    }
}
export function servicesHydrate(state: State): State {
    return {
        ...state,
        hasError: false,
        hasSuccess: false,
        isLoading: true,
        message: null
    };
}
export function servicesHydrateSuccess(state: State, payload: ServiceInfo[]): State {
    return adapter.addAll(payload, {
        ...state,
        hasSuccess: true,
        isLoading: false,
    });
}
export function servicesHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload
    };
}
export function contractHydrateSuccess(state: State, payload: ContractInfo): State {
    return {
        ...state,
        canSubscribe: payload && !payload.isCreationCompleted
    };
}
