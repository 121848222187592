import { Injectable } from '@angular/core';
import { FORGOT_PASSWORD, RESET_PASSWORD, UPDATE_PASSWORD } from '@client/actions';
import { AuthConfirmPasswordResetAction, AuthForgotPasswordAction, AuthUpdatePasswordAction } from '@client/actions/auth-actions';
import { AuthService } from '@client/core/services/auth.service';
import { getForgotPasswordForm, getOobOobCode, getResetPasswordForm, getUpdatePasswordForm } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class PasswordAuthEffects {
  @Effect()
  forgotPassword$: Observable<any> = this.actions$.pipe(
    ofType(FORGOT_PASSWORD.CLICK_SUBMIT, FORGOT_PASSWORD.CLICK_RESEND_EMAIL),
    withLatestFrom(this.store.pipe(select(getForgotPasswordForm))),
    map(([a, b]) => new AuthForgotPasswordAction(b))
  );

  @Effect()
  resetPassword$: Observable<any> = this.actions$.pipe(
    ofType(RESET_PASSWORD.CLICK_SUBMIT),
    withLatestFrom(this.store.pipe(select(getOobOobCode))),
    withLatestFrom(this.store.pipe(select(getResetPasswordForm))),
    map(
      ([[a, b], c]) =>
        new AuthConfirmPasswordResetAction({
          code: b,
          newPassword: c.password
        })
    )
  );
  @Effect()
  updatePassword$: Observable<any> = this.actions$.pipe(
    ofType(UPDATE_PASSWORD.CLICK_SUBMIT),
    withLatestFrom(this.store.pipe(select(getUpdatePasswordForm))),
    map(([a, b]) => new AuthUpdatePasswordAction(b))
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService) {}
}
