import { LAYOUT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo } from "@client/utils/shared-constants";

export class LayoutClickAction implements Action {
    readonly type = LAYOUT.CLICK;
    constructor(public payload: NodeItem, public correlationId?: number) {
    }
}

export class LayoutSetScreenAction implements Action {
    readonly type = LAYOUT.SET_SCREEN;
    constructor(public payload: LayoutScreenInfo, public correlationId?: number) {
    }
}

export class LayoutAddNodeAction implements Action {
    readonly type = LAYOUT.ADD_NODE;
    constructor(public payload: NodeItem, public correlationId?: number) {
    }
}

export class LayoutRemoveNodeAction implements Action {
    readonly type = LAYOUT.REMOVE_NODE;
    constructor(public payload: NodeItem, public correlationId?: number) {
    }
}

export class LayoutAddGroupAction implements Action {
    readonly type = LAYOUT.ADD_GROUP;
    constructor(public payload: NodeItem, public correlationId?: number) {
    }
}

export class LayoutRemoveGroupAction implements Action {
    readonly type = LAYOUT.REMOVE_GROUP;
    constructor(public payload: NodeItem, public correlationId?: number) {
    }
}

export class LayoutCloseSideNavAction implements Action {
    readonly type = LAYOUT.CLOSE_SIDE_NAV;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutOpenSideNavAction implements Action {
    readonly type = LAYOUT.OPEN_SIDE_NAV;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutCollapseSideNavAction implements Action {
    readonly type = LAYOUT.COLLAPSE_SIDE_NAV;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutClickDeleteUserAction implements Action {
    readonly type = LAYOUT.CLICK_DELETE_USER;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutInitPublicAction implements Action {
    readonly type = LAYOUT.INIT_PUBLIC;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutInitPrivateAction implements Action {
    readonly type = LAYOUT.INIT_PRIVATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutCollapseAdminOptionsAction implements Action {
    readonly type = LAYOUT.COLLAPSE_ADMIN_OPTIONS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutClickAdminMenuAction implements Action {
    readonly type = LAYOUT.CLICK_ADMIN_MENU;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class LayoutClickUserMenuAction implements Action {
    readonly type = LAYOUT.CLICK_USER_MENU;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
