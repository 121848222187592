import { State as PopupState } from "@client/reducers/popup-reducer";
import { Selector } from "@ngrx/store";
export const getMessage: Selector<PopupState, string | null> = (state: PopupState) => state.message;
export const getHasError: Selector<PopupState, boolean> = (state: PopupState) => state.hasError;
export const getHasSuccess: Selector<PopupState, boolean> = (state: PopupState) => state.hasSuccess;
export const getOpened: Selector<PopupState, boolean> = (state: PopupState) => state.opened;
export const getTitle: Selector<PopupState, string> = (state: PopupState) => state.title;
export const getContent: Selector<PopupState, string> = (state: PopupState) => state.content;
export const getWidth: Selector<PopupState, number> = (state: PopupState) => state.width;
export const getHeight: Selector<PopupState, number> = (state: PopupState) => state.height;
