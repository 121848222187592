import { State as AdminNotesListState } from "@client/reducers/admin-notes-list-reducer";
import { Selector } from "@ngrx/store";
import { ServiceInfo, ServiceMetaInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => state.isActive;
export const getMessage: Selector<AdminNotesListState, string | null> = (state: AdminNotesListState) => state.message;
export const getHasError: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => state.hasError;
export const getHasSuccess: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => state.isLoading;
export const getSelecteService: Selector<AdminNotesListState, ServiceInfo | null> = (state: AdminNotesListState) => state.selecteService;
export const getSelecteServicePeriod: Selector<AdminNotesListState, any> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).period;
export const getSelecteServiceAvailableFrom: Selector<AdminNotesListState, string | null> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).availableFrom;
export const getSelecteServiceAvailableServiceId: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).availableServiceId;
export const getSelecteServiceAvailableUntil: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).availableUntil;
export const getSelecteServiceCancelationNotice: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).cancelationNotice;
export const getSelecteServiceCancelationNoticeTimeUnit: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).cancelationNoticeTimeUnit;
export const getSelecteServiceDuration: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).duration;
export const getSelecteServiceDurationTimeUnit: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).durationTimeUnit;
export const getSelecteServiceIsBankingCardPaymentAccepted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isBankingCardPaymentAccepted;
export const getSelecteServiceIsCancelable: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isCancelable;
export const getSelecteServiceIsCancelationFirstDayOfMonth: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isCancelationFirstDayOfMonth;
export const getSelecteServiceIsDasPaymentAccepted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isDasPaymentAccepted;
export const getSelecteServiceIsFinancialInstitutionPaymentAccepted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isFinancialInstitutionPaymentAccepted;
export const getSelecteServiceIsFixedDuration: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isFixedDuration;
export const getSelecteServiceIsPpaPaymentAccepted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isPpaPaymentAccepted;
export const getSelecteServiceIsSubscriptionAvailable: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isSubscriptionAvailable;
export const getSelecteServiceIsTax1Exempted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isTax1Exempted;
export const getSelecteServiceIsTax2Exempted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isTax2Exempted;
export const getSelecteServiceIsTax3Exempted: Selector<AdminNotesListState, boolean> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).isTax3Exempted;
export const getSelecteServiceOnSaleFrom: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).onSaleFrom;
export const getSelecteServiceOnSaleUntil: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).onSaleUntil;
export const getSelecteServiceParkIdentificationNo: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).parkIdentificationNo;
export const getSelecteServicePartialMonthCalculation: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).partialMonthCalculation;
export const getSelecteServicePrice: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).price;
export const getSelecteServiceSectorId: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).sectorId;
export const getSelecteServiceServiceBillingType: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).serviceBillingType;
export const getSelecteServiceServiceDescription: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).serviceDescription;
export const getSelecteServiceServiceStartRule: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).serviceStartRule;
export const getSelecteServiceServiceType: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).serviceType;
export const getSelecteServiceMeta: Selector<AdminNotesListState, ServiceMetaInfo> = (state: AdminNotesListState) => getSelecteService(state) && getSelecteService(state).meta;
export const getSelecteServiceMetaNote: Selector<AdminNotesListState, string> = (state: AdminNotesListState) => getSelecteServiceMeta(state) && getSelecteServiceMeta(state).note;
export const getSelectedParkIdentificationNo: Selector<AdminNotesListState, number> = (state: AdminNotesListState) => state.selectedParkIdentificationNo;
