import { AUTH } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm } from "@client/utils/shared-constants";

export class AuthApplyActionCodeAction implements Action {
    readonly type = AUTH.APPLY_ACTION_CODE;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthApplyActionCodeSuccessAction implements Action {
    readonly type = AUTH.APPLY_ACTION_CODE_SUCCESS;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthApplyActionCodeFailureAction implements Action {
    readonly type = AUTH.APPLY_ACTION_CODE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthCheckActionCodeAction implements Action {
    readonly type = AUTH.CHECK_ACTION_CODE;
    constructor(public payload: ActionCodeInfo, public correlationId?: number) {
    }
}

export class AuthCheckActionCodeSuccessAction implements Action {
    readonly type = AUTH.CHECK_ACTION_CODE_SUCCESS;
    constructor(public payload: ActionCodeInfo, public correlationId?: number) {
    }
}

export class AuthCheckActionCodeFailureAction implements Action {
    readonly type = AUTH.CHECK_ACTION_CODE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthConfirmPasswordResetAction implements Action {
    readonly type = AUTH.CONFIRM_PASSWORD_RESET;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthConfirmPasswordResetSuccessAction implements Action {
    readonly type = AUTH.CONFIRM_PASSWORD_RESET_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthConfirmPasswordResetFailureAction implements Action {
    readonly type = AUTH.CONFIRM_PASSWORD_RESET_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthCreateUserWithEmailAndPasswordAction implements Action {
    readonly type = AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class AuthCreateUserWithEmailAndPasswordSuccessAction implements Action {
    readonly type = AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthCreateUserWithEmailAndPasswordFailureAction implements Action {
    readonly type = AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthGetRedirectResultAction implements Action {
    readonly type = AUTH.GET_REDIRECT_RESULT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthGetRedirectResultSuccessAction implements Action {
    readonly type = AUTH.GET_REDIRECT_RESULT_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthGetRedirectResultFailureAction implements Action {
    readonly type = AUTH.GET_REDIRECT_RESULT_FAILURE;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthIsSignInWithEmailLinkAction implements Action {
    readonly type = AUTH.IS_SIGN_IN_WITH_EMAIL_LINK;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthOnAuthStateChangedAction implements Action {
    readonly type = AUTH.ON_AUTH_STATE_CHANGED;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthOnIdTokenChangedAction implements Action {
    readonly type = AUTH.ON_ID_TOKEN_CHANGED;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSendPasswordResetEmailAction implements Action {
    readonly type = AUTH.SEND_PASSWORD_RESET_EMAIL;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSendPasswordResetEmailSuccessAction implements Action {
    readonly type = AUTH.SEND_PASSWORD_RESET_EMAIL_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSendPasswordResetEmailFailureAction implements Action {
    readonly type = AUTH.SEND_PASSWORD_RESET_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSendSignInLinkToEmailAction implements Action {
    readonly type = AUTH.SEND_SIGN_IN_LINK_TO_EMAIL;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSendSignInLinkToEmailSuccessAction implements Action {
    readonly type = AUTH.SEND_SIGN_IN_LINK_TO_EMAIL_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSendSignInLinkToEmailFailureAction implements Action {
    readonly type = AUTH.SEND_SIGN_IN_LINK_TO_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSetPersistenceAction implements Action {
    readonly type = AUTH.SET_PERSISTENCE;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthSetPersistenceSuccessAction implements Action {
    readonly type = AUTH.SET_PERSISTENCE_SUCCESS;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthSetPersistenceFailureAction implements Action {
    readonly type = AUTH.SET_PERSISTENCE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInAndRetrieveDataWithCredentialAction implements Action {
    readonly type = AUTH.SIGN_IN_AND_RETRIEVE_DATA_WITH_CREDENTIAL;
    constructor(public payload: AuthCredential, public correlationId?: number) {
    }
}

export class AuthSignInAndRetrieveDataWithCredentialSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_AND_RETRIEVE_DATA_WITH_CREDENTIAL_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInAndRetrieveDataWithCredentialFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_AND_RETRIEVE_DATA_WITH_CREDENTIAL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInAnonymouslyAction implements Action {
    readonly type = AUTH.SIGN_IN_ANONYMOUSLY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSignInAnonymouslySuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_ANONYMOUSLY_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInAnonymouslyFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_ANONYMOUSLY_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithCustomTokenAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_CUSTOM_TOKEN;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthSignInWithCustomTokenSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithCustomTokenFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithEmailAndPasswordAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD;
    constructor(public payload: LoginForm, public correlationId?: number) {
    }
}

export class AuthSignInWithEmailAndPasswordSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithEmailAndPasswordFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithEmailLinkAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_EMAIL_LINK;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithEmailLinkSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_EMAIL_LINK_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithEmailLinkFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_EMAIL_LINK_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithPhoneNumberAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_PHONE_NUMBER;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithPhoneNumberSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_PHONE_NUMBER_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithPhoneNumberFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_PHONE_NUMBER_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithPopupAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_POPUP;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSignInWithPopupSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_POPUP_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSignInWithPopupFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_POPUP_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithRedirectAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_REDIRECT;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthSignInWithRedirectSuccessAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_REDIRECT_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignInWithRedirectFailureAction implements Action {
    readonly type = AUTH.SIGN_IN_WITH_REDIRECT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSignOutAction implements Action {
    readonly type = AUTH.SIGN_OUT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSignOutSuccessAction implements Action {
    readonly type = AUTH.SIGN_OUT_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSignOutFailureAction implements Action {
    readonly type = AUTH.SIGN_OUT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthUpdateCurrentUserAction implements Action {
    readonly type = AUTH.UPDATE_CURRENT_USER;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthUpdateCurrentUserSuccessAction implements Action {
    readonly type = AUTH.UPDATE_CURRENT_USER_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthUpdateCurrentUserFailureAction implements Action {
    readonly type = AUTH.UPDATE_CURRENT_USER_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthVerifyPasswordResetCodeAction implements Action {
    readonly type = AUTH.VERIFY_PASSWORD_RESET_CODE;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthVerifyPasswordResetCodeSuccessAction implements Action {
    readonly type = AUTH.VERIFY_PASSWORD_RESET_CODE_SUCCESS;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class AuthVerifyPasswordResetCodeFailureAction implements Action {
    readonly type = AUTH.VERIFY_PASSWORD_RESET_CODE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthReloadAction implements Action {
    readonly type = AUTH.RELOAD;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthReloadSuccessAction implements Action {
    readonly type = AUTH.RELOAD_SUCCESS;
    constructor(public payload: User, public correlationId?: number) {
    }
}

export class AuthReloadFailureAction implements Action {
    readonly type = AUTH.RELOAD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthInitAction implements Action {
    readonly type = AUTH.INIT;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class AuthDestroyAction implements Action {
    readonly type = AUTH.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthAfterInitAction implements Action {
    readonly type = AUTH.AFTER_INIT;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class AuthClickBackLinkAction implements Action {
    readonly type = AUTH.CLICK_BACK_LINK;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthLoginAction implements Action {
    readonly type = AUTH.LOGIN;
    constructor(public payload: LoginForm, public correlationId?: number) {
    }
}

export class AuthLoginSuccessAction implements Action {
    readonly type = AUTH.LOGIN_SUCCESS;
    constructor(public payload: User, public correlationId?: number) {
    }
}

export class AuthLoginFailureAction implements Action {
    readonly type = AUTH.LOGIN_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthLogoutAction implements Action {
    readonly type = AUTH.LOGOUT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthLogoutSuccessAction implements Action {
    readonly type = AUTH.LOGOUT_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthLogoutFailureAction implements Action {
    readonly type = AUTH.LOGOUT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthForgotPasswordAction implements Action {
    readonly type = AUTH.FORGOT_PASSWORD;
    constructor(public payload: ForgotPasswordForm, public correlationId?: number) {
    }
}

export class AuthForgotPasswordSuccessAction implements Action {
    readonly type = AUTH.FORGOT_PASSWORD_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthForgotPasswordFailureAction implements Action {
    readonly type = AUTH.FORGOT_PASSWORD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthResetPasswordUpdateAction implements Action {
    readonly type = AUTH.RESET_PASSWORD_UPDATE;
    constructor(public payload: ResetPasswordForm, public correlationId?: number) {
    }
}

export class AuthResetPasswordUpdateSuccessAction implements Action {
    readonly type = AUTH.RESET_PASSWORD_UPDATE_SUCCESS;
    constructor(public payload: ResetPasswordForm, public correlationId?: number) {
    }
}

export class AuthResetPasswordUpdateFailureAction implements Action {
    readonly type = AUTH.RESET_PASSWORD_UPDATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthValidateCodeAction implements Action {
    readonly type = AUTH.VALIDATE_CODE;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class AuthValidateCodeSuccessAction implements Action {
    readonly type = AUTH.VALIDATE_CODE_SUCCESS;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class AuthValidateCodeFailureAction implements Action {
    readonly type = AUTH.VALIDATE_CODE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthRegisterEmailAction implements Action {
    readonly type = AUTH.REGISTER_EMAIL;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class AuthRegisterEmailSuccessAction implements Action {
    readonly type = AUTH.REGISTER_EMAIL_SUCCESS;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class AuthRegisterEmailFailureAction implements Action {
    readonly type = AUTH.REGISTER_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthSendEmailVerificationLinkAction implements Action {
    readonly type = AUTH.SEND_EMAIL_VERIFICATION_LINK;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSendEmailVerificationLinkSuccessAction implements Action {
    readonly type = AUTH.SEND_EMAIL_VERIFICATION_LINK_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthSendEmailVerificationLinkFailureAction implements Action {
    readonly type = AUTH.SEND_EMAIL_VERIFICATION_LINK_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthValidateEmailCodeAction implements Action {
    readonly type = AUTH.VALIDATE_EMAIL_CODE;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class AuthValidateEmailCodeSuccessAction implements Action {
    readonly type = AUTH.VALIDATE_EMAIL_CODE_SUCCESS;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class AuthValidateEmailCodeFailureAction implements Action {
    readonly type = AUTH.VALIDATE_EMAIL_CODE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthStateSuccessAction implements Action {
    readonly type = AUTH.STATE_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthStateFailureAction implements Action {
    readonly type = AUTH.STATE_FAILURE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthReadyAction implements Action {
    readonly type = AUTH.READY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AuthUpdatePasswordAction implements Action {
    readonly type = AUTH.UPDATE_PASSWORD;
    constructor(public payload: UpdatePasswordForm, public correlationId?: number) {
    }
}

export class AuthUpdatePasswordSuccessAction implements Action {
    readonly type = AUTH.UPDATE_PASSWORD_SUCCESS;
    constructor(public payload: UpdatePasswordForm, public correlationId?: number) {
    }
}

export class AuthUpdatePasswordFailureAction implements Action {
    readonly type = AUTH.UPDATE_PASSWORD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AuthUpdateEmailAction implements Action {
    readonly type = AUTH.UPDATE_EMAIL;
    constructor(public payload: EmailEditForm, public correlationId?: number) {
    }
}

export class AuthUpdateEmailSuccessAction implements Action {
    readonly type = AUTH.UPDATE_EMAIL_SUCCESS;
    constructor(public payload: EmailEditForm, public correlationId?: number) {
    }
}

export class AuthUpdateEmailFailureAction implements Action {
    readonly type = AUTH.UPDATE_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
