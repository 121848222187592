import { ACCESS_CODE, ACCESS_CODE_ADD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeSearchInfo } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isActive: boolean;
    form: AccessCodeSearchInfo;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isActive: false,
    form: {
        companyCode: "",
        organization: {
            adresse: "",
            city: "",
            organizationCode: "",
            organizationName: "",
            phoneNumber: "",
            postalCode: "",
            province: "",
            logo: "",
            config: null
        },
        userCode: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ACCESS_CODE_ADD.INIT: return accessCodeAddInit(state);
        case ACCESS_CODE_ADD.DESTROY: return accessCodeAddDestroy(state);
        case ACCESS_CODE_ADD.FORM_UPDATE: return accessCodeAddFormUpdate(state, action.payload);
        case ACCESS_CODE_ADD.SUBMIT: return accessCodeAddSubmit(state, action.payload);
        case ACCESS_CODE.ADD: return accessCodeAdd(state, action.payload);
        case ACCESS_CODE.ADD_SUCCESS: return accessCodeAddSuccess(state, action.payload);
        case ACCESS_CODE.ADD_FAILURE: return accessCodeAddFailure(state, action.payload);
        default: return state;
    }
}
export function accessCodeAddInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function accessCodeAddDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function accessCodeAddFormUpdate(state: State, payload: AccessCodeSearchInfo): State {
    return { ...state };
}
export function accessCodeAddSubmit(state: State, payload: AccessCodeSearchInfo): State {
    return {
        ...state,
        message: null
    };
}
export function accessCodeAdd(state: State, payload: AccessCodeSearchInfo): State {
    return {
        ...state,
        message: null,
        hasSuccess: false,
        hasError: false,
        isLoading: true
    };
}
export function accessCodeAddSuccess(state: State, payload: AccessCodeSearchInfo): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false
    };
}
export function accessCodeAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
