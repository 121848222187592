import { State as RegisterContractState } from "@client/reducers/register-contract-reducer";
import { Selector } from "@ngrx/store";
import { ContractForm } from "@client/utils/shared-constants";
export const getMessage: Selector<RegisterContractState, string | null> = (state: RegisterContractState) => state.message;
export const getHasError: Selector<RegisterContractState, boolean> = (state: RegisterContractState) => state.hasError;
export const getHasSuccess: Selector<RegisterContractState, boolean> = (state: RegisterContractState) => state.hasSuccess;
export const getIsActive: Selector<RegisterContractState, boolean> = (state: RegisterContractState) => state.isActive;
export const getIsComplete: Selector<RegisterContractState, boolean> = (state: RegisterContractState) => state.isComplete;
export const getIsPending: Selector<RegisterContractState, boolean> = (state: RegisterContractState) => state.isPending;
export const getForm: Selector<RegisterContractState, ContractForm> = (state: RegisterContractState) => state.form;
export const getFormContractNo: Selector<RegisterContractState, string> = (state: RegisterContractState) => getForm(state) && getForm(state).contractNo;
export const getFormPhone: Selector<RegisterContractState, string> = (state: RegisterContractState) => getForm(state) && getForm(state).phone;
export const getFormPostalCode: Selector<RegisterContractState, string> = (state: RegisterContractState) => getForm(state) && getForm(state).postalCode;
