import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Vehicule } from '@client/utils/shared-constants';
import { Store } from '@ngrx/store';

import {from, Observable, zip} from 'rxjs';
import {first, map, switchMap, tap} from 'rxjs/operators';
import { environment } from '../../configs/environment';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class VehiclesService {
  get endPoint() {
    return this.afAuth.user.pipe(map((u: User) => {
      return `/user/${(u).uid}/vehicles`;
    }));
  }

  private url = environment.backendApiUrl + '/spaq';

  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth, private store: Store<any>, private http: HttpClient, private fns: AngularFireFunctions) {}

  addToUser($event): Observable<Vehicule[]> {
    return this.http.post<Vehicule[]>(`${this.url}/vehicles`, $event);
  }

  create(data: Vehicule) {
    const test = this.fns.httpsCallable('vehicleCreate');
    return test(data);
  }

  getBrands(year: string) {
    return this.db
      .list(`/vehicles/brand/${year}`)
      .valueChanges()
      .pipe(
        tap(x => {
          if (x) {
            x.push('Autre');
          }
          return x;
        })
      );
  }

  getData(year, brand) {
    return zip(this.getYears(), this.getBrands(year), this.getModels(year, brand)).pipe(
      map(data => ({
        years: data[0],
        brands: data[1],
        models: data[2]
      }))
    );
  }

  getFromUser() {
    return this.http.get(`${this.url}/vehicles`);
  }

  getModels(year: string, brand: string) {
    return this.db.list(`/vehicles/model/${brand}/${year}`).valueChanges();
  }

  getModelsByYearAndBrand(year: string, brand: string) {
    return this.db
      .list(`/vehicles/model/${brand}/${year}`)
      .snapshotChanges()
      .pipe(map(a => a.map(model => ({ model: model.payload.val(), id: model.key }))));
  }

  getYears() {
    return this.db
      .list('/vehicles/year')
      .valueChanges()
      .pipe(first());
  }

  hydrate() {
    return this.afAuth.user.pipe(
      switchMap((u: User) => this.db.object<Vehicule[]>(`/user/${u.uid}/vehicles`).valueChanges())
    );
  }

  removeToUser(event): Observable<Vehicule[]> {
    return this.http.delete<Vehicule[]>(`${this.url}/vehicles/${event.id}`);
  }

  update(data: Vehicule) {
    const handler = this.fns.httpsCallable('vehicleUpdate');
    return handler(data);
  }

  updateToUser(event): Observable<Vehicule[]> {
    return this.http.put<Vehicule[]>(`${this.url}/vehicles/${event.id}`, event);
  }
}
