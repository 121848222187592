import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Resolve } from '@angular/router';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
// import { InformationsUpdateComponent } from '@plancton/refactor/components/informations-update/informations-update.component';

import { Observable } from 'rxjs/index';
import { filter, take, tap } from 'rxjs/operators';
import { EmailUpdateComponent, PasswordUpdateComponent, ProfilUpdateComponent, UpdateSmsComponent } from './components';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class TeamResolver implements Resolve<Observable<User>> {
  constructor(private afAuth: AngularFireAuth) {}

  resolve() {
    return this.afAuth.authState.pipe(
      filter(x => !!x),
      take(1),
      tap(x => {})
    );
  }
}

@NgModule({
  imports: [SharedCommonModule, SharedFormModule, SharedComponentsModule],
  declarations: [
    //   InformationsUpdateComponent,
    ProfilUpdateComponent,
    PasswordUpdateComponent,
    EmailUpdateComponent,
    UpdateSmsComponent
  ],
  exports: [
    //   InformationsUpdateComponent,
    ProfilUpdateComponent,
    PasswordUpdateComponent,
    EmailUpdateComponent,
    UpdateSmsComponent
  ],
  entryComponents: [
    //   InformationsUpdateComponent,
    ProfilUpdateComponent,
    PasswordUpdateComponent,
    EmailUpdateComponent,
    UpdateSmsComponent
  ]
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: []
    };
  }

  constructor() {}
}
