import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@client/core/services/auth.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IfNotLoggedInGuard implements CanActivate {
  constructor(private store: Store<any>, private auth: AuthService, private route: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.afAuth.authState.pipe(
      map(user => {
        if (!user || user.isAnonymous || !user.emailVerified) {
          return true;
        }
        // this.route.navigate(['/account/contract/create']);
      })
    );
  }
}
