import { METADATA } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { MetadataState } from "@client/utils/shared-constants";
export interface State {
    refreshUser: string;
}
export const initialState: State = {
    refreshUser: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case METADATA.HYDRATE: return metadataHydrate(state);
        case METADATA.HYDRATE_FAILURE: return metadataHydrateFailure(state, action.payload);
        case METADATA.HYDRATE_SUCCESS: return metadataHydrateSuccess(state, action.payload);
        default: return state;
    }
}
export function metadataHydrate(state: State): State {
    return state;
}
export function metadataHydrateFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function metadataHydrateSuccess(state: State, payload: MetadataState): State {
    return { ...state, ...payload };
}
