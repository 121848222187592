import { State as AdminCodeFormState } from "@client/reducers/admin-code-form-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => state.isActive;
export const getMessage: Selector<AdminCodeFormState, string | null> = (state: AdminCodeFormState) => state.message;
export const getHasError: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => state.hasError;
export const getHasSuccess: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => state.hasSuccess;
export const getIsLoading: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => state.isLoading;
export const getIsEdit: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => state.isEdit;
export const getForm: Selector<AdminCodeFormState, AccessCodeInfo | null> = (state: AdminCodeFormState) => state.form;
export const getFormCompanyCode: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).companyCode;
export const getFormCompanyName: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).companyName;
export const getFormFirstName: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).firstName;
export const getFormId: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).id;
export const getFormLastName: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).lastName;
export const getFormUsed: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => getForm(state) && getForm(state).used;
export const getFormUserCode: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).userCode;
export const getFormOrganizationCode: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).organizationCode;
export const getFormGroupCode: Selector<AdminCodeFormState, string> = (state: AdminCodeFormState) => getForm(state) && getForm(state).groupCode;
export const getFormSingle: Selector<AdminCodeFormState, boolean> = (state: AdminCodeFormState) => getForm(state) && getForm(state).single;
