import { Observable } from 'rxjs';

export const readFile = blob =>
  Observable.create(obs => {
    if (!(blob instanceof Blob)) {
      obs.error(new Error('`blob` must be an instance of File or Blob.'));
      return;
    }

    const reader = new FileReader();

    reader.onerror = err => obs.error(err);
    reader.onabort = err => obs.error(err);
    reader.onload = () => obs.next({ data: (reader.result as string).split(',')[1], type: blob.type });
    reader.onloadend = () => obs.complete();

    return reader.readAsDataURL(blob);
  });
