import { Injectable } from "@angular/core";
import { IProfileEditDispatcher } from "@client/interfaces/profile-edit-interface";
import { Store } from "@ngrx/store";
import { ProfileEditDestroyAction, ProfileEditInitAction, ProfileEditClickSubmitAction, ProfileEditFormUpdateAction } from "@client/actions/profile-edit-actions";
import { ProfileEditForm } from "@client/utils/shared-constants";
@Injectable()
export class ProfileEditDispatcher implements IProfileEditDispatcher {
    constructor(private store: Store<any>) {
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileEditDestroyAction(payload, correlationId));
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileEditInitAction(payload, correlationId));
    }
    clickSubmit(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileEditClickSubmitAction(payload, correlationId));
    }
    formUpdate(payload: ProfileEditForm, correlationId = Date.now()) {
        this.store.dispatch(new ProfileEditFormUpdateAction(payload, correlationId));
    }
}
