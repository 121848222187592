import { NgModule } from '@angular/core';
import { CoreLayoutModule } from '@client/core/core-layout.module';
import { FeaturesModule } from './features.module';
import { WebRoutingModule } from './web-routing.module';

@NgModule({
  imports: [WebRoutingModule, FeaturesModule, CoreLayoutModule],
  exports: [],
  declarations: []
})
export class WebModule {}
