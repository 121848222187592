import { State as FaqState } from "@client/reducers/faq-reducer";
import { Selector } from "@ngrx/store";
import { FaqDataInfo, FaqGroupInfo, FaqInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<FaqState, boolean> = (state: FaqState) => state.isActive;
export const getHasSuccess: Selector<FaqState, boolean> = (state: FaqState) => state.hasSuccess;
export const getHasError: Selector<FaqState, boolean> = (state: FaqState) => state.hasError;
export const getMessage: Selector<FaqState, string | null> = (state: FaqState) => state.message;
export const getIsHydrated: Selector<FaqState, boolean> = (state: FaqState) => state.isHydrated;
export const getCollection: Selector<FaqState, FaqDataInfo> = (state: FaqState) => state.collection;
export const getCollectionGroups: Selector<FaqState, FaqGroupInfo[]> = (state: FaqState) => getCollection(state) && getCollection(state).groups;
export const getCollectionList: Selector<FaqState, FaqInfo[]> = (state: FaqState) => getCollection(state) && getCollection(state).list;
export const getSelectedFaqId: Selector<FaqState, string> = (state: FaqState) => state.selectedFaqId;
export const getErrorMessage: Selector<FaqState, string | null> = (state: FaqState) => state.errorMessage;
export const getSuccessMessage: Selector<FaqState, string | null> = (state: FaqState) => state.successMessage;
export const getSelectedOrganizationId: Selector<FaqState, number> = (state: FaqState) => state.selectedOrganizationId;
export const getIsLoading: Selector<FaqState, boolean> = (state: FaqState) => state.isLoading;
