<nav *ngIf="!(enableAdminOptions$ | async)" class="navbar bg-white">
  <i (click)="collapse()" aria-hidden="true" id="collapseMenu" class="fa fa-bars fa-2x text-dark"></i>
</nav>
<nav *ngIf="enableAdminOptions$ | async" class="navbar bg-white">
  <i (click)="collapse()" aria-hidden="true" id="collapseMenu"  class="fa fa-bars fa-2x text-dark"></i>


  <div class="navbar-brand ml-auto" href="#">
    <kendo-dropdownbutton
      [data]="organizationList$ | async"
      [look]="'outline'"
      [textField]="'organizationName'"
      [popupSettings]="{ animate: true, align: 'right'}"
      (itemClick)="onOrganizationClick($event)"
    >
      {{ organization$ | async }}
      <ng-template kendoDropDownButtonItemTemplate let-dataItem>
        <span *ngIf="(scopeId$ | async) == dataItem.organizationCode" class="k-icon k-i-check"></span>
        <span *ngIf="(scopeId$ | async) !== dataItem.organizationCode" class="k-icon"></span>
        <span>{{ dataItem.organizationName }}</span>
      </ng-template>
    </kendo-dropdownbutton>
  </div>
</nav>
