import { ADMIN_GROUP_LIST, ADMIN_GROUP_METAS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selectedGroup: AccessCodeGroupInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selectedGroup: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_GROUP_METAS.INIT: return adminGroupMetasInit(state);
        case ADMIN_GROUP_METAS.DESTROY: return adminGroupMetasDestroy(state);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_SERVICES: return adminGroupMetasUpdateConditionsServices(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_CC: return adminGroupMetasUpdateConditionsCc(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_DAS: return adminGroupMetasUpdateConditionsDas(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_PPA: return adminGroupMetasUpdateConditionsPpa(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_INTERNET: return adminGroupMetasUpdateConditionsInternet(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_SERVICES_SUCCESS: return adminGroupMetasUpdateConditionsServicesSuccess(state);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_CC_SUCCESS: return adminGroupMetasUpdateConditionsCcSuccess(state);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_DAS_SUCCESS: return adminGroupMetasUpdateConditionsDasSuccess(state);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_PPA_SUCCESS: return adminGroupMetasUpdateConditionsPpaSuccess(state);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_INTERNET_SUCCESS: return adminGroupMetasUpdateConditionsInternetSuccess(state);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_SERVICES_FAILURE: return adminGroupMetasUpdateConditionsServicesFailure(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_CC_FAILURE: return adminGroupMetasUpdateConditionsCcFailure(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_DAS_FAILURE: return adminGroupMetasUpdateConditionsDasFailure(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_PPA_FAILURE: return adminGroupMetasUpdateConditionsPpaFailure(state, action.payload);
        case ADMIN_GROUP_METAS.UPDATE_CONDITIONS_INTERNET_FAILURE: return adminGroupMetasUpdateConditionsInternetFailure(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_CONFIGURATION: return adminGroupListClickConfiguration(state, action.payload);
        default: return state;
    }
}
export function adminGroupMetasInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function adminGroupMetasDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupMetasUpdateConditionsServices(state: State, payload: string): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupMetasUpdateConditionsCc(state: State, payload: string): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupMetasUpdateConditionsDas(state: State, payload: string): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupMetasUpdateConditionsPpa(state: State, payload: string): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupMetasUpdateConditionsInternet(state: State, payload: string): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupMetasUpdateConditionsServicesSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminGroupMetasUpdateConditionsCcSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminGroupMetasUpdateConditionsDasSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminGroupMetasUpdateConditionsPpaSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminGroupMetasUpdateConditionsInternetSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminGroupMetasUpdateConditionsServicesFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminGroupMetasUpdateConditionsCcFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminGroupMetasUpdateConditionsDasFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminGroupMetasUpdateConditionsPpaFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminGroupMetasUpdateConditionsInternetFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminGroupListClickConfiguration(state: State, payload: AccessCodeGroupInfo): State {
    return { ...state, selectedGroup: payload };
}
