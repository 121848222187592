import { State as AdminFaqGroupEditState } from "@client/reducers/admin-faq-group-edit-reducer";
import { Selector } from "@ngrx/store";
import { FaqGroupInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => state.isActive;
export const getMessage: Selector<AdminFaqGroupEditState, string | null> = (state: AdminFaqGroupEditState) => state.message;
export const getHasError: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => state.hasError;
export const getHasSuccess: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => state.hasSuccess;
export const getIsLoading: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => state.isLoading;
export const getData: Selector<AdminFaqGroupEditState, FaqGroupInfo | null> = (state: AdminFaqGroupEditState) => state.data;
export const getDataName: Selector<AdminFaqGroupEditState, string> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).name;
export const getDataCollapse: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).collapse;
export const getDataId: Selector<AdminFaqGroupEditState, string> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).id;
export const getDataTitle: Selector<AdminFaqGroupEditState, string> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).title;
export const getDataOrder: Selector<AdminFaqGroupEditState, any> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).order;
export const getDataVisible: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).visible;
export const getDataOrganization: Selector<AdminFaqGroupEditState, string | null> = (state: AdminFaqGroupEditState) => getData(state) && getData(state).organization;
export const getIsEdit: Selector<AdminFaqGroupEditState, boolean> = (state: AdminFaqGroupEditState) => state.isEdit;
