import {AfterViewInit, Component, forwardRef, Input, ViewChild} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { AbstractInput } from '@client/shared/components/forms/abstract-input';
import {TextBoxContainerComponent} from "@progress/kendo-angular-inputs";

@Component({
  selector: 'plancton-input-password',
  template: `
    <kendo-textbox-container class="w-100" for="login-email-email" [floatingLabel]="label | translate">
      <input [id]="formControlName" [name]="formControlName" [disabled]="disabled" autocomplete="password" type="password" kendoTextBox [(ngModel)]="value" (ngModelChange)="updateModel()" (blur)="onModelTouched()" />

      <div *ngIf="!isValid">
        <small class="alert-danger" *ngIf="control.hasError('required')">{{ 'SHARED.FORMS.INPUT_PASSWORD.IS_REQUIRED' | translate }}</small>
        <small class="alert-danger" *ngIf="control.hasError('minlength')">{{ 'SHARED.FORMS.INPUT_PASSWORD.IS_MIN_REQUIRED' | translate }}</small>
        <small class="alert-danger" *ngIf="control.hasError('mismatch')">{{ 'SHARED.FORMS.INPUT_PASSWORD.IS_MISMATCH' | translate }}</small>
      </div>
    </kendo-textbox-container>
  `,
  styles: [],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputPasswordComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputPasswordComponent), multi: true }
  ]
})
export class InputPasswordComponent extends AbstractInput implements AfterViewInit {
  @Input() label = 'SHARED.FORMS.INPUT_PASSWORD.LABEL';
  @Input() match;

  @ViewChild(TextBoxContainerComponent) container;

  ngAfterViewInit() {
    window.setTimeout(() => this.container.focused = true, 0);
  }
  validateFn = (control: AbstractControl) => {
    if (control.value.length) {
      if (Validators.minLength(8)(control)) {
        return Validators.minLength(8)(control);
      }
      if (this.match) {
        const ctrl = this.controlContainer.control.get(this.match);

        if (!ctrl || ctrl.value !== control.value) {
          return { mismatch: true };
        }
      }
    }
    return null;
  };

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validateFn(control);
  }
}
