import { Injectable } from "@angular/core";
import { ICalendarDispatcher } from "@client/interfaces/calendar-interface";
import { Store } from "@ngrx/store";
import { CalendarDestroyAction, CalendarInitAction } from "@client/actions/calendar-actions";
@Injectable()
export class CalendarDispatcher implements ICalendarDispatcher {
    constructor(private store: Store<any>) {
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new CalendarDestroyAction(payload, correlationId));
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new CalendarInitAction(payload, correlationId));
    }
}
