import { NgModule } from '@angular/core';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { PopupConfirmDirective } from '@client/utils/popup-confirm.directive';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PopupComponent } from './components';

@NgModule({
  imports: [DialogModule, SharedComponentsModule, SharedCommonModule],
  declarations: [PopupComponent, PopupConfirmDirective],
  exports: [PopupComponent, DialogModule, PopupConfirmDirective],
  entryComponents: [PopupComponent]
})
export class PopupModule {}
