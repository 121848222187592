import { State as ContractCancelState } from "@client/reducers/contract-cancel-reducer";
import { Selector } from "@ngrx/store";
import { SubscriptionService } from "@client/utils/shared-constants";
export const getIsActive: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => state.isActive;
export const getMessage: Selector<ContractCancelState, string | null> = (state: ContractCancelState) => state.message;
export const getHasError: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => state.hasError;
export const getHasSuccess: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => state.hasSuccess;
export const getIsLoading: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => state.isLoading;
export const getIsCompleted: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => state.isCompleted;
export const getSelectedDate: Selector<ContractCancelState, string | null> = (state: ContractCancelState) => state.selectedDate;
export const getSelectedService: Selector<ContractCancelState, SubscriptionService | null> = (state: ContractCancelState) => state.selectedService;
export const getSelectedServiceEffectiveDate: Selector<ContractCancelState, string> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).effectiveDate;
export const getSelectedServiceEndDate: Selector<ContractCancelState, string> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).endDate;
export const getSelectedServiceIsApproved: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).isApproved;
export const getSelectedServiceIsPaidByGroup: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).isPaidByGroup;
export const getSelectedServiceServiceDescription: Selector<ContractCancelState, string> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).serviceDescription;
export const getSelectedServiceServiceId: Selector<ContractCancelState, number> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).serviceId;
export const getSelectedServiceId: Selector<ContractCancelState, number> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).id;
export const getSelectedServiceInvoiceAmount: Selector<ContractCancelState, string> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).invoiceAmount;
export const getSelectedServiceInvoiceTo: Selector<ContractCancelState, string> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).invoiceTo;
export const getSelectedServiceInvoiceFrom: Selector<ContractCancelState, string> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).invoiceFrom;
export const getSelectedServiceIsCancellationRequest: Selector<ContractCancelState, boolean> = (state: ContractCancelState) => getSelectedService(state) && getSelectedService(state).isCancellationRequest;
