import { TEST_FORM, TEST_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TestInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: any;
    selectedTest: TestInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    selectedTest: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case TEST_LIST.INIT: return testListInit(state);
        case TEST_LIST.DESTROY: return testListDestroy(state);
        case TEST_LIST.HYDRATE: return testListHydrate(state);
        case TEST_LIST.HYDRATE_SUCCESS: return testListHydrateSuccess(state, action.payload);
        case TEST_LIST.HYDRATE_FAILURE: return testListHydrateFailure(state, action.payload);
        case TEST_LIST.SELECT: return testListSelect(state, action.payload);
        case TEST_LIST.DELETE: return testListDelete(state);
        case TEST_LIST.DELETE_SUCCESS: return testListDeleteSuccess(state);
        case TEST_LIST.DELETE_FAILURE: return testListDeleteFailure(state, action.payload);
        case TEST_LIST.CLICK_ADD: return testListClickAdd(state);
        case TEST_LIST.CLICK_EDIT: return testListClickEdit(state);
        case TEST_LIST.CLICK_DELETE: return testListClickDelete(state);
        case TEST_FORM.UPDATE_SUCCESS: return testFormUpdateSuccess(state);
        case TEST_FORM.ADD_SUCCESS: return testFormAddSuccess(state);
        default: return state;
    }
}
export function testListInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function testListDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function testListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testListHydrateSuccess(state: State, payload: TestInfo[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload || [],
    };
}
export function testListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function testListSelect(state: State, payload: TestInfo): State {
    return { ...state, selectedTest: payload || null };
}
export function testListDelete(state: State): State {
    return state;
}
export function testListDeleteSuccess(state: State): State {
    return {
        ...state,
        selectedTest: null,
    };
}
export function testListDeleteFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function testListClickAdd(state: State): State {
    return {
        ...state,
        selectedTest: null,
    };
}
export function testListClickEdit(state: State): State {
    return state;
}
export function testListClickDelete(state: State): State {
    return state;
}
export function testFormUpdateSuccess(state: State): State {
    return state;
}
export function testFormAddSuccess(state: State): State {
    return state;
}
