import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getContractState } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasContractPaidGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return this.store.pipe(
      select(getContractState),
      filter(x => x.isLoaded !== null),
      mergeMap(s => {
        const isPaid = (s.data && s.data.isPaid) || false;
        const isPaymentRequired = (s && s.paymenRequired) || false;

        if (isPaid === true || isPaymentRequired === false) {
          return of(true);
        }
        return this.router.navigate(['/account/contract/payment']);
      })
    );
  }
}
