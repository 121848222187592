import { Injectable } from '@angular/core';
import { FAQ, SESSION } from '@client/actions';
import { FaqHydrateAction, FaqHydrateFailureAction, FaqHydrateSuccessAction } from '@client/actions/faq-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { FaqService } from '@client/core/services/faq.service';
import { Action } from '@client/lib/action';
import { getLayoutIsScope, getLayoutScopeId } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, iif, Observable, of } from 'rxjs';

import { catchError, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class ConfigEffects {
  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.faq, of(new FaqHydrateAction(null, action.correlationId)), EMPTY))
  );
  @Effect({ dispatch: true })
  hydrateFaq$: Observable<Action> = this.actions$.pipe(
    ofType<FaqHydrateAction>(FAQ.HYDRATE),
    withLatestFrom(this.store.pipe(select(getLayoutIsScope)), this.store.pipe(select(getLayoutScopeId))),
    switchMap(([action, isScope, scopeId]) =>
      this.faq.getAllVisible().pipe(
        map((x: any) => {
          const list = x.list;

          const sysList = list.filter(x => !!(x.organization === '_system')) || [];
          if (!isScope) {
            return {
              groups: x.groups.filter(x => x.organization === '_system').sort((a, b) => Number(a.order || 0) - Number(b.order || 0)),
              list: [[], sysList.sort((a, b) => Number(a.order || 0) - Number(b.order || 0))]
            };
          }
          const scopeList = list.filter(x => !!(x.organization === scopeId)) || [];

          return {
            groups: x.groups.filter(x => x.organization === scopeId || x.organization === '_system').sort((a, b) => Number(a.order || 0) - Number(b.order || 0)),
            list: [scopeList.sort((a, b) => Number(a.order || 0) - Number(b.order || 0)), sysList.sort((a, b) => Number(a.order || 0) - Number(b.order || 0))]
          };
        }),
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map((faqs: any) => new FaqHydrateSuccessAction(faqs, action.correlationId)),
        catchError(error => of(new FaqHydrateFailureAction(error, action.correlationId)))
      )
    )
  );
  constructor(private actions$: Actions, private store: Store<any>, private faq: FaqService) {}
}
