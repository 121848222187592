import { CONTRACT_CONDITIONS_PAYMENT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm } from "@client/utils/shared-constants";

export class ContractConditionsPaymentInitAction implements Action {
    readonly type = CONTRACT_CONDITIONS_PAYMENT.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractConditionsPaymentDestroyAction implements Action {
    readonly type = CONTRACT_CONDITIONS_PAYMENT.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractConditionsPaymentHydrateAction implements Action {
    readonly type = CONTRACT_CONDITIONS_PAYMENT.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractConditionsPaymentHydrateSuccessAction implements Action {
    readonly type = CONTRACT_CONDITIONS_PAYMENT.HYDRATE_SUCCESS;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class ContractConditionsPaymentHydrateFailureAction implements Action {
    readonly type = CONTRACT_CONDITIONS_PAYMENT.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractConditionsPaymentClickAcceptAction implements Action {
    readonly type = CONTRACT_CONDITIONS_PAYMENT.CLICK_ACCEPT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
