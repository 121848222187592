import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'spaq-form',
  template: `
    <form [ngClass]="{ pending: pending === true }" [formGroup]="myForm" (submit)="submit()">
      <span *ngIf="step" class="d-block text-right">
        Étape <b class="text-primary">{{ step }}</b> de <b class="text-primary">{{ totalStep }}</b>
      </span>
      <small class="alert-danger p-4 mb-2 form-error" *ngIf="error">{{ error.message }}</small>
      <ng-content></ng-content>

      <div class="spinner" *ngIf="pending">
        <div class="icon text-center">
          <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw d-block"></i>
          <small>Un instant...</small>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
      form {
        position: relative;
      }

      .form-error {
        font-size: 80%;
        font-weight: 400;

        &:empty {
          display: none;
        }
      }

      small:empty {
        display: none !important;
      }

      .spinner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: block;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);

        .icon {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          color: #ffffff;
          z-index: 200;
          transform: translate(-50%, -50%);
        }
      }
    `
  ]
})
export class FormComponent implements OnInit {
  get myForm() {
    return this.form ? this.form : new FormGroup({});
  }

  @Input() error: any;
  @Input() form: FormGroup;
  @Output() onSubmit = new EventEmitter();
  @Input() pending = false;
  @Input() step: number;
  @Input() totalStep: number;

  constructor() {}

  ngOnInit() {}

  submit() {
    Object.keys(this.form.value).forEach(val => {
      this.form.controls[val].markAsDirty();
      this.form.controls[val].markAsTouched();
      this.form.controls[val].updateValueAndValidity();
    });

    this.onSubmit.emit(this.form.value);
  }
}
