import { AUTH, SESSION, OOB } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ActionCodeInfo, Session } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    apiKey: string | null;
    mode: string | null;
    oobCode: string;
    continueUrl: string;
    lang: string;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: true,
    isCompleted: false,
    apiKey: null,
    mode: null,
    oobCode: "",
    continueUrl: "",
    lang: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case OOB.INIT: return oobInit(state);
        case OOB.DESTROY: return oobDestroy(state);
        case OOB.CLICK_LOGIN: return oobClickLogin(state);
        case AUTH.CHECK_ACTION_CODE: return authCheckActionCode(state, action.payload);
        case AUTH.CHECK_ACTION_CODE_SUCCESS: return authCheckActionCodeSuccess(state, action.payload);
        case AUTH.CHECK_ACTION_CODE_FAILURE: return authCheckActionCodeFailure(state, action.payload);
        case SESSION.RELOAD_SUCCESS: return sessionReloadSuccess(state, action.payload);
        default: return state;
    }
}
export function oobInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function oobDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function oobClickLogin(state: State): State {
    return state;
}
export function authCheckActionCode(state: State, payload: ActionCodeInfo): State {
    return {
        ...state,
        ...payload,
        message: null,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        isCompleted: false,
    };
}
export function authCheckActionCodeSuccess(state: State, payload: ActionCodeInfo): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        isCompleted: true,
    };
}
export function authCheckActionCodeFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function sessionReloadSuccess(state: State, payload: Session): State {
    return { ...state, isActive: false };
}
