import { VEHICLES_DETAILS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule } from "@client/utils/shared-constants";

export class VehiclesDetailsInitAction implements Action {
    readonly type = VEHICLES_DETAILS.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesDetailsDestroyAction implements Action {
    readonly type = VEHICLES_DETAILS.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesDetailsClickAddAction implements Action {
    readonly type = VEHICLES_DETAILS.CLICK_ADD;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesDetailsClickEditAction implements Action {
    readonly type = VEHICLES_DETAILS.CLICK_EDIT;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesDetailsClickDeleteAction implements Action {
    readonly type = VEHICLES_DETAILS.CLICK_DELETE;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}
