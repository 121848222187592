import { PPA_ADD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { PaymentPPAInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    form: PaymentPPAInfo;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isCompleted: false,
    form: {
        effectiveDate: "",
        institutionName: "",
        financialInstitutionId: 0,
        folioNo: 0,
        downloadUrl: "",
        transitNo: 0
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PPA_ADD.INIT: return ppaAddInit(state);
        case PPA_ADD.DESTROY: return ppaAddDestroy(state);
        case PPA_ADD.SUBMIT: return ppaAddSubmit(state);
        case PPA_ADD.SUBMIT_SUCCESS: return ppaAddSubmitSuccess(state);
        case PPA_ADD.SUBMIT_FAILURE: return ppaAddSubmitFailure(state, action.payload);
        case PPA_ADD.FORM_UPDATE: return ppaAddFormUpdate(state, action.payload);
        default: return state;
    }
}
export function ppaAddInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function ppaAddDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function ppaAddSubmit(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true
    };
}
export function ppaAddSubmitSuccess(state: State): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false
    };
}
export function ppaAddSubmitFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false
    };
}
export function ppaAddFormUpdate(state: State, payload: PaymentPPAInfo): State {
    return {
        ...state,
        form: payload
    };
}
