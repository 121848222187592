import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getAccountStatusIsSubscriptionRequired } from '../selectors/index';

@Injectable({
  providedIn: 'root'
})
export class HasNoSubscriptionGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return this.store.pipe(
      select(getAccountStatusIsSubscriptionRequired),
      mergeMap(s => {
        if (s) {
          return of(true);
        }
        return this.router.navigate(['/account/contract/details']);
      })
    );
  }
}
