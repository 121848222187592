import { ADMIN_ACCESS_CODE_SEARCH } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_ACCESS_CODE_SEARCH.INIT: return adminAccessCodeSearchInit(state);
        case ADMIN_ACCESS_CODE_SEARCH.DESTROY: return adminAccessCodeSearchDestroy(state);
        case ADMIN_ACCESS_CODE_SEARCH.HYDRATE: return adminAccessCodeSearchHydrate(state);
        case ADMIN_ACCESS_CODE_SEARCH.HYDRATE_SUCCESS: return adminAccessCodeSearchHydrateSuccess(state, action.payload);
        case ADMIN_ACCESS_CODE_SEARCH.HYDRATE_FAILURE: return adminAccessCodeSearchHydrateFailure(state, action.payload);
        default: return state;
    }
}
export function adminAccessCodeSearchInit(state: State): State {
    return state;
}
export function adminAccessCodeSearchDestroy(state: State): State {
    return state;
}
export function adminAccessCodeSearchHydrate(state: State): State {
    return state;
}
export function adminAccessCodeSearchHydrateSuccess(state: State, payload: any[]): State {
    return { ...state, ...payload };
}
export function adminAccessCodeSearchHydrateFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
