import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_METAS_FORM, ADMIN_METAS_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeServiceCustomFields, OrganizationInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: AccessCodeServiceCustomFields[];
    selecteMetas: AccessCodeServiceCustomFields | null;
    selectedOrganizationCode: string;
    selectedGroupCode: string;
    showOrganizationBacklink: boolean;
    showGroupBacklink: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    selecteMetas: null,
    selectedOrganizationCode: "",
    selectedGroupCode: "",
    showOrganizationBacklink: false,
    showGroupBacklink: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_METAS_LIST.INIT: return adminMetasListInit(state);
        case ADMIN_METAS_LIST.DESTROY: return adminMetasListDestroy(state);
        case ADMIN_METAS_LIST.HYDRATE: return adminMetasListHydrate(state);
        case ADMIN_METAS_LIST.HYDRATE_SUCCESS: return adminMetasListHydrateSuccess(state, action.payload);
        case ADMIN_METAS_LIST.HYDRATE_FAILURE: return adminMetasListHydrateFailure(state, action.payload);
        case ADMIN_METAS_LIST.DELETE: return adminMetasListDelete(state);
        case ADMIN_METAS_LIST.DELETE_SUCCESS: return adminMetasListDeleteSuccess(state);
        case ADMIN_METAS_LIST.DELETE_FAILURE: return adminMetasListDeleteFailure(state, action.payload);
        case ADMIN_METAS_LIST.CLICK_ADD: return adminMetasListClickAdd(state);
        case ADMIN_METAS_LIST.CLICK_EDIT: return adminMetasListClickEdit(state);
        case ADMIN_METAS_LIST.CLICK_DELETE: return adminMetasListClickDelete(state);
        case ADMIN_METAS_LIST.SELECT: return adminMetasListSelect(state, action.payload);
        case ADMIN_METAS_LIST.SELECT_ORGANIZATION_GROUP: return adminMetasListSelectOrganizationGroup(state, action.payload);
        case ADMIN_METAS_LIST.SELECT_ORGANIZATION: return adminMetasListSelectOrganization(state, action.payload);
        case ADMIN_METAS_LIST.CLICK_ORGANIZATION_BACKLINK: return adminMetasListClickOrganizationBacklink(state);
        case ADMIN_METAS_LIST.CLICK_GROUP_BACKLINK: return adminMetasListClickGroupBacklink(state);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_CONFIGURATION: return adminGroupListClickConfiguration(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_METAS_FORM.SELECT_ORGANIZATION_CODE: return adminMetasFormSelectOrganizationCode(state, action.payload);
        case ADMIN_METAS_FORM.SELECT_GROUP_CODE: return adminMetasFormSelectGroupCode(state, action.payload);
        case ADMIN_METAS_FORM.UPDATE_SUCCESS: return adminMetasFormUpdateSuccess(state);
        case ADMIN_METAS_FORM.ADD_SUCCESS: return adminMetasFormAddSuccess(state);
        default: return state;
    }
}
export function adminMetasListInit(state: State): State {
    return {
        ...initialState,
        data: state.data,
        selectedOrganizationCode: state.selectedOrganizationCode,
        selectedGroupCode: state.selectedGroupCode,
        isActive: true,
    };
}
export function adminMetasListDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function adminMetasListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminMetasListHydrateSuccess(state: State, payload: AccessCodeServiceCustomFields[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload,
    };
}
export function adminMetasListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminMetasListDelete(state: State): State {
    return {
        ...state,
        message: null,
        hasError: false,
    };
}
export function adminMetasListDeleteSuccess(state: State): State {
    return {
        ...state,
        selecteMetas: initialState.selecteMetas,
    };
}
export function adminMetasListDeleteFailure(state: State, payload: any): State {
    return { ...state, hasError: true, message: payload };
}
export function adminMetasListClickAdd(state: State): State {
    return state;
}
export function adminMetasListClickEdit(state: State): State {
    return state;
}
export function adminMetasListClickDelete(state: State): State {
    return state;
}
export function adminMetasListSelect(state: State, payload: AccessCodeServiceCustomFields): State {
    return {
        ...state,
        selecteMetas: payload,
    };
}
export function adminMetasListSelectOrganizationGroup(state: State, payload: string): State {
    return { ...state, selectedGroupCode: payload };
}
export function adminMetasListSelectOrganization(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload, selectedGroupCode: '' };
}
export function adminMetasListClickOrganizationBacklink(state: State): State {
    return state;
}
export function adminMetasListClickGroupBacklink(state: State): State {
    return state;
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return { ...state, selectedOrganizationCode: payload.organizationCode };
}
export function adminGroupListClickConfiguration(state: State, payload: AccessCodeGroupInfo): State {
    return { ...state, selectedOrganizationCode: payload.organizationCode, selectedGroupCode: payload.groupCode };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return { ...state, selectedGroupCode: payload.groupCode };
}
export function adminMetasFormSelectOrganizationCode(state: State, payload: string): State {
    return { ...state, selectedOrganizationCode: payload, selectedGroupCode: '' };
}
export function adminMetasFormSelectGroupCode(state: State, payload: string): State {
    return { ...state, selectedGroupCode: payload };
}
export function adminMetasFormUpdateSuccess(state: State): State {
    return {
        ...state,
        selecteMetas: initialState.selecteMetas,
    };
}
export function adminMetasFormAddSuccess(state: State): State {
    return {
        ...state,
        selecteMetas: initialState.selecteMetas,
    };
}
