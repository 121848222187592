import { CONTACT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isActive: boolean;
    disableSubject: boolean;
    disableNature: boolean;
    disableContractNo: boolean;
    disableService: boolean;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isActive: false,
    disableSubject: true,
    disableNature: true,
    disableContractNo: true,
    disableService: true
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTACT.INIT: return contactInit(state);
        case CONTACT.DESTROY: return contactDestroy(state);
        case CONTACT.SUBMIT_FORM: return contactSubmitForm(state, action.payload);
        case CONTACT.SUBMIT_FORM_SUCCESS: return contactSubmitFormSuccess(state, action.payload);
        case CONTACT.SUBMIT_FORM_FAILURE: return contactSubmitFormFailure(state, action.payload);
        default: return state;
    }
}
export function contactInit(state: State): State {
    return state;
}
export function contactDestroy(state: State): State {
    return initialState;
}
export function contactSubmitForm(state: State, payload: any): State {
    return {
        ...state,
        message: null,
        hasSuccess: false,
        hasError: false,
        isLoading: true
    };
}
export function contactSubmitFormSuccess(state: State, payload: any): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false
    };
}
export function contactSubmitFormFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload
    };
}
