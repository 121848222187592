import { Injectable } from "@angular/core";
import { IOrganizationDispatcher } from "@client/interfaces/organization-interface";
import { Store } from "@ngrx/store";
import { OrganizationHydrateAction, OrganizationHydrateSuccessAction, OrganizationHydrateFailureAction } from "@client/actions/organization-actions";
import { OrganizationInfo } from "@client/utils/shared-constants";
@Injectable()
export class OrganizationDispatcher implements IOrganizationDispatcher {
    constructor(private store: Store<any>) {
    }
    hydrate(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new OrganizationHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: OrganizationInfo[], correlationId = Date.now()) {
        this.store.dispatch(new OrganizationHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new OrganizationHydrateFailureAction(payload, correlationId));
    }
}
