import { Injectable } from "@angular/core";
import { IRegisterDispatcher } from "@client/interfaces/register-interface";
import { Store } from "@ngrx/store";
import { RegisterAlreadyConfirmedAction, RegisterDestroyAccessCodeAction, RegisterDestroyContractAction, RegisterDestroyContractConfirmationAction, RegisterDestroyEmailAction, RegisterDestroyEmailPendingAction, RegisterDestroySubscriptionAction, RegisterGoToSubscriptionAction, RegisterInitAccessCodeAction, RegisterInitContractAction, RegisterInitContractConfirmationAction, RegisterInitEmailAction, RegisterInitEmailPendingAction, RegisterInitSubscriptionAction, RegisterNotLoggedInAction, RegisterRecoverEmailFailureAction, RegisterRecoverEmailSuccessAction, RegisterRegisterCodeEmailFailureAction, RegisterRegisterCodeEmailSuccessAction, RegisterRegisterCodeSuccessAction, RegisterRegisterCodeFailureAction, RegisterRegisterContractEmailSuccessAction, RegisterRegisterContractEmailFailureAction, RegisterRegisterContractSuccessAction, RegisterRegisterContractFailureAction, RegisterRegisterFailureAction, RegisterRegisterSuccessAction, RegisterSelectMethodAction, RegisterSendValidationLinkFailureAction, RegisterSendValidationLinkSuccessAction, RegisterSubmitEmailFailureAction, RegisterValidateEmailSuccessAction, RegisterValidateEmailFailureAction, RegisterClickBackToLoginAction, RegisterClickIgnoreAccessCodeAction, RegisterClickIgnoreContractAction, RegisterClickIgnoreSubscriptionAction, RegisterGoToAccessCodeAction, RegisterGoToContractAction, RegisterGoToContractConfirmationAction, RegisterGoToEmailAction, RegisterGoToEmailPendingAction, RegisterLoginAction, RegisterLogoutAction, RegisterRecoverEmailAction, RegisterRegisterAction, RegisterRegisterCodeAction, RegisterRegisterCodeEmailAction, RegisterRegisterContractAction, RegisterRegisterContractEmailAction, RegisterRegisterContractResetAction, RegisterResendAction, RegisterSearchContractAction, RegisterSendValidationLinkAction, RegisterSubmitEmailAction, RegisterUpdateEmailFormAction, RegisterValidateEmailAction } from "@client/actions/register-actions";
import { UserEntity, AccessCodeInfo, RegisterEmailForm, ContractForm } from "@client/utils/shared-constants";
@Injectable()
export class RegisterDispatcher implements IRegisterDispatcher {
    constructor(private store: Store<any>) {
    }
    alreadyConfirmed(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterAlreadyConfirmedAction(payload, correlationId));
    }
    destroyAccessCode(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterDestroyAccessCodeAction(payload, correlationId));
    }
    destroyContract(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterDestroyContractAction(payload, correlationId));
    }
    destroyContractConfirmation(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterDestroyContractConfirmationAction(payload, correlationId));
    }
    destroyEmail(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterDestroyEmailAction(payload, correlationId));
    }
    destroyEmailPending(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterDestroyEmailPendingAction(payload, correlationId));
    }
    destroySubscription(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterDestroySubscriptionAction(payload, correlationId));
    }
    goToSubscription(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterGoToSubscriptionAction(payload, correlationId));
    }
    initAccessCode(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterInitAccessCodeAction(payload, correlationId));
    }
    initContract(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterInitContractAction(payload, correlationId));
    }
    initContractConfirmation(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterInitContractConfirmationAction(payload, correlationId));
    }
    initEmail(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterInitEmailAction(payload, correlationId));
    }
    initEmailPending(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterInitEmailPendingAction(payload, correlationId));
    }
    initSubscription(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterInitSubscriptionAction(payload, correlationId));
    }
    notLoggedIn(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterNotLoggedInAction(payload, correlationId));
    }
    recoverEmailFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRecoverEmailFailureAction(payload, correlationId));
    }
    recoverEmailSuccess(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRecoverEmailSuccessAction(payload, correlationId));
    }
    registerCodeEmailFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterCodeEmailFailureAction(payload, correlationId));
    }
    registerCodeEmailSuccess(payload: UserEntity, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterCodeEmailSuccessAction(payload, correlationId));
    }
    registerCodeSuccess(payload: AccessCodeInfo, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterCodeSuccessAction(payload, correlationId));
    }
    registerCodeFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterCodeFailureAction(payload, correlationId));
    }
    registerContractEmailSuccess(payload: UserEntity, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractEmailSuccessAction(payload, correlationId));
    }
    registerContractEmailFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractEmailFailureAction(payload, correlationId));
    }
    registerContractSuccess(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractSuccessAction(payload, correlationId));
    }
    registerContractFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractFailureAction(payload, correlationId));
    }
    registerFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterFailureAction(payload, correlationId));
    }
    registerSuccess(payload: UserEntity, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterSuccessAction(payload, correlationId));
    }
    selectMethod(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSelectMethodAction(payload, correlationId));
    }
    sendValidationLinkFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSendValidationLinkFailureAction(payload, correlationId));
    }
    sendValidationLinkSuccess(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSendValidationLinkSuccessAction(payload, correlationId));
    }
    submitEmailFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSubmitEmailFailureAction(payload, correlationId));
    }
    validateEmailSuccess(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterValidateEmailSuccessAction(payload, correlationId));
    }
    validateEmailFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterValidateEmailFailureAction(payload, correlationId));
    }
    clickBackToLogin(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterClickBackToLoginAction(payload, correlationId));
    }
    clickIgnoreAccessCode(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterClickIgnoreAccessCodeAction(payload, correlationId));
    }
    clickIgnoreContract(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterClickIgnoreContractAction(payload, correlationId));
    }
    clickIgnoreSubscription(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterClickIgnoreSubscriptionAction(payload, correlationId));
    }
    goToAccessCode(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterGoToAccessCodeAction(payload, correlationId));
    }
    goToContract(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterGoToContractAction(payload, correlationId));
    }
    goToContractConfirmation(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterGoToContractConfirmationAction(payload, correlationId));
    }
    goToEmail(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterGoToEmailAction(payload, correlationId));
    }
    goToEmailPending(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterGoToEmailPendingAction(payload, correlationId));
    }
    login(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterLoginAction(payload, correlationId));
    }
    logout(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterLogoutAction(payload, correlationId));
    }
    recoverEmail(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRecoverEmailAction(payload, correlationId));
    }
    register(payload: RegisterEmailForm, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterAction(payload, correlationId));
    }
    registerCode(payload: AccessCodeInfo, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterCodeAction(payload, correlationId));
    }
    registerCodeEmail(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterCodeEmailAction(payload, correlationId));
    }
    registerContract(payload: ContractForm, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractAction(payload, correlationId));
    }
    registerContractEmail(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractEmailAction(payload, correlationId));
    }
    registerContractReset(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterRegisterContractResetAction(payload, correlationId));
    }
    resend(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterResendAction(payload, correlationId));
    }
    searchContract(payload: ContractForm, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSearchContractAction(payload, correlationId));
    }
    sendValidationLink(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSendValidationLinkAction(payload, correlationId));
    }
    submitEmail(payload: RegisterEmailForm, correlationId = Date.now()) {
        this.store.dispatch(new RegisterSubmitEmailAction(payload, correlationId));
    }
    updateEmailForm(payload: RegisterEmailForm, correlationId = Date.now()) {
        this.store.dispatch(new RegisterUpdateEmailFormAction(payload, correlationId));
    }
    validateEmail(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new RegisterValidateEmailAction(payload, correlationId));
    }
}
