import { State as TestListState } from "@client/reducers/test-list-reducer";
import { Selector } from "@ngrx/store";
import { TestInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<TestListState, boolean> = (state: TestListState) => state.isActive;
export const getMessage: Selector<TestListState, string | null> = (state: TestListState) => state.message;
export const getHasError: Selector<TestListState, boolean> = (state: TestListState) => state.hasError;
export const getHasSuccess: Selector<TestListState, boolean> = (state: TestListState) => state.hasSuccess;
export const getIsLoading: Selector<TestListState, boolean> = (state: TestListState) => state.isLoading;
export const getData: Selector<TestListState, any> = (state: TestListState) => state.data;
export const getSelectedTest: Selector<TestListState, TestInfo | null> = (state: TestListState) => state.selectedTest;
export const getSelectedTestId: Selector<TestListState, number> = (state: TestListState) => getSelectedTest(state) && getSelectedTest(state).id;
export const getSelectedTestName: Selector<TestListState, string> = (state: TestListState) => getSelectedTest(state) && getSelectedTest(state).name;
export const getSelectedTestWhen: Selector<TestListState, string> = (state: TestListState) => getSelectedTest(state) && getSelectedTest(state).when;
export const getSelectedTestTimestamp: Selector<TestListState, number> = (state: TestListState) => getSelectedTest(state) && getSelectedTest(state).timestamp;
export const getSelectedTestGroupId: Selector<TestListState, string> = (state: TestListState) => getSelectedTest(state) && getSelectedTest(state).group_id;
export const getSelectedTestLevel: Selector<TestListState, string> = (state: TestListState) => getSelectedTest(state) && getSelectedTest(state).level;
