import { State as SubscriptionState } from "@client/reducers/subscription-reducer";
import { Selector } from "@ngrx/store";
import { SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<SubscriptionState, string | null> = (state: SubscriptionState) => state.message;
export const getHasError: Selector<SubscriptionState, boolean> = (state: SubscriptionState) => state.hasError;
export const getHasSuccess: Selector<SubscriptionState, boolean> = (state: SubscriptionState) => state.hasSuccess;
export const getIsHydrated: Selector<SubscriptionState, boolean> = (state: SubscriptionState) => state.isHydrated;
export const getIsLoading: Selector<SubscriptionState, boolean> = (state: SubscriptionState) => state.isLoading;
export const getIsCompleted: Selector<SubscriptionState, boolean> = (state: SubscriptionState) => state.isCompleted;
export const getData: Selector<SubscriptionState, SubscriptionInfo> = (state: SubscriptionState) => state.data;
export const getDataCustom: Selector<SubscriptionState, any> = (state: SubscriptionState) => getData(state) && getData(state).custom;
export const getDataSpaq: Selector<SubscriptionState, SubscriptionSpaqInfo> = (state: SubscriptionState) => getData(state) && getData(state).spaq;
export const getDataSpaqAccesDocuments: Selector<SubscriptionState, AccesDocumentInfo[]> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).accesDocuments;
export const getDataSpaqAccessOnHoldReason: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).accessOnHoldReason;
export const getDataSpaqAddressLine1: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).addressLine1;
export const getDataSpaqIdentificationCode: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).identificationCode;
export const getDataSpaqAddressLine2: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).addressLine2;
export const getDataSpaqAppartmentNo: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).appartmentNo;
export const getDataSpaqBalance: Selector<SubscriptionState, number> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).balance;
export const getDataSpaqCellPhone: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).cellPhone;
export const getDataSpaqCity: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).city;
export const getDataSpaqContractNo: Selector<SubscriptionState, number> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).contractNo;
export const getDataSpaqDynamicFieldJsonData: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).dynamicFieldJsonData;
export const getDataSpaqEmail: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).email;
export const getDataSpaqFirstName: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).firstName;
export const getDataSpaqHomePhone: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).homePhone;
export const getDataSpaqHomePhoneExtension: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).homePhoneExtension;
export const getDataSpaqIsAccessOnHold: Selector<SubscriptionState, boolean> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).isAccessOnHold;
export const getDataSpaqLanguage: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).language;
export const getDataSpaqLastName: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).lastName;
export const getDataSpaqPostalCode: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).postalCode;
export const getDataSpaqProvince: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).province;
export const getDataSpaqServiceCenter: Selector<SubscriptionState, number> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).serviceCenter;
export const getDataSpaqSex: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).sex;
export const getDataSpaqWorkPhone: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).workPhone;
export const getDataSpaqWorkPhoneExtension: Selector<SubscriptionState, number> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).workPhoneExtension;
export const getDataSpaqPaymentMethod: Selector<SubscriptionState, PaymentMethodInfo> = (state: SubscriptionState) => getDataSpaq(state) && getDataSpaq(state).paymentMethod;
export const getDataSpaqPaymentMethodCreditCardDataKey: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).CreditCardDataKey;
export const getDataSpaqPaymentMethodEffectiveDate: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).EffectiveDate;
export const getDataSpaqPaymentMethodEndDate: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).EndDate;
export const getDataSpaqPaymentMethodFinancialInstitutionId: Selector<SubscriptionState, number> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).FinancialInstitutionId;
export const getDataSpaqPaymentMethodFolioNo: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).FolioNo;
export const getDataSpaqPaymentMethodMethod: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).Method;
export const getDataSpaqPaymentMethodTransitNo: Selector<SubscriptionState, string> = (state: SubscriptionState) => getDataSpaqPaymentMethod(state) && getDataSpaqPaymentMethod(state).TransitNo;
