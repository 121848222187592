import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { State } from "@client/reducers/access-code-details-reducer";
import { getAccessCodeDetailsIsActive } from "@client/selectors";
/*test*/ 
@Injectable()
export class AccessCodeDetailsStore extends BaseStore {
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeDetailsIsActive));
    }
}
