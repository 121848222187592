import { FAQ } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo } from "@client/utils/shared-constants";

export class FaqHydrateAction implements Action {
    readonly type = FAQ.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class FaqHydrateSuccessAction implements Action {
    readonly type = FAQ.HYDRATE_SUCCESS;
    constructor(public payload: FaqDataInfo, public correlationId?: number) {
    }
}

export class FaqHydrateFailureAction implements Action {
    readonly type = FAQ.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class FaqInitAction implements Action {
    readonly type = FAQ.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class FaqDestroyAction implements Action {
    readonly type = FAQ.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class FaqOnBlurItemAction implements Action {
    readonly type = FAQ.ON_BLUR_ITEM;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class FaqOnClickItemAction implements Action {
    readonly type = FAQ.ON_CLICK_ITEM;
    constructor(public payload: FaqInfo, public correlationId?: number) {
    }
}

export class FaqOnFocusItemAction implements Action {
    readonly type = FAQ.ON_FOCUS_ITEM;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
