import { State as AdminGroupMetasState } from "@client/reducers/admin-group-metas-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeGroupInfo, AccessCodeServiceInfo, AccessCodeMetasInfo, AccessCodeMetasConditions, AccessCodeServiceCustomFields } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminGroupMetasState, boolean> = (state: AdminGroupMetasState) => state.isActive;
export const getMessage: Selector<AdminGroupMetasState, string | null> = (state: AdminGroupMetasState) => state.message;
export const getHasError: Selector<AdminGroupMetasState, boolean> = (state: AdminGroupMetasState) => state.hasError;
export const getHasSuccess: Selector<AdminGroupMetasState, boolean> = (state: AdminGroupMetasState) => state.hasSuccess;
export const getIsLoading: Selector<AdminGroupMetasState, boolean> = (state: AdminGroupMetasState) => state.isLoading;
export const getSelectedGroup: Selector<AdminGroupMetasState, AccessCodeGroupInfo | null> = (state: AdminGroupMetasState) => state.selectedGroup;
export const getSelectedGroupGroupCode: Selector<AdminGroupMetasState, string> = (state: AdminGroupMetasState) => getSelectedGroup(state) && getSelectedGroup(state).groupCode;
export const getSelectedGroupGroupDescription: Selector<AdminGroupMetasState, string> = (state: AdminGroupMetasState) => getSelectedGroup(state) && getSelectedGroup(state).groupDescription;
export const getSelectedGroupOrganizationCode: Selector<AdminGroupMetasState, string> = (state: AdminGroupMetasState) => getSelectedGroup(state) && getSelectedGroup(state).organizationCode;
export const getSelectedGroupPublic: Selector<AdminGroupMetasState, boolean> = (state: AdminGroupMetasState) => getSelectedGroup(state) && getSelectedGroup(state).public;
export const getSelectedGroupServices: Selector<AdminGroupMetasState, AccessCodeServiceInfo[]> = (state: AdminGroupMetasState) => getSelectedGroup(state) && getSelectedGroup(state).services;
export const getSelectedGroupMeta: Selector<AdminGroupMetasState, AccessCodeMetasInfo> = (state: AdminGroupMetasState) => getSelectedGroup(state) && getSelectedGroup(state).meta;
export const getSelectedGroupMetaConditions: Selector<AdminGroupMetasState, AccessCodeMetasConditions> = (state: AdminGroupMetasState) => getSelectedGroupMeta(state) && getSelectedGroupMeta(state).conditions;
export const getSelectedGroupMetaConditionsService: Selector<AdminGroupMetasState, string | null> = (state: AdminGroupMetasState) => getSelectedGroupMetaConditions(state) && getSelectedGroupMetaConditions(state).service;
export const getSelectedGroupMetaConditionsPaymentDas: Selector<AdminGroupMetasState, string | null> = (state: AdminGroupMetasState) => getSelectedGroupMetaConditions(state) && getSelectedGroupMetaConditions(state).paymentDas;
export const getSelectedGroupMetaConditionsPaymentCc: Selector<AdminGroupMetasState, string | null> = (state: AdminGroupMetasState) => getSelectedGroupMetaConditions(state) && getSelectedGroupMetaConditions(state).paymentCc;
export const getSelectedGroupMetaConditionsPaymentPpa: Selector<AdminGroupMetasState, string> = (state: AdminGroupMetasState) => getSelectedGroupMetaConditions(state) && getSelectedGroupMetaConditions(state).paymentPpa;
export const getSelectedGroupMetaConditionsPaymentInternet: Selector<AdminGroupMetasState, string | null> = (state: AdminGroupMetasState) => getSelectedGroupMetaConditions(state) && getSelectedGroupMetaConditions(state).paymentInternet;
export const getSelectedGroupMetaCustomFields: Selector<AdminGroupMetasState, AccessCodeServiceCustomFields[]> = (state: AdminGroupMetasState) => getSelectedGroupMeta(state) && getSelectedGroupMeta(state).customFields;
