import { ACCOUNT_STATUS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccountStatusState } from "@client/utils/shared-constants";
export interface State {
    isLoading: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    message: string;
    isCompleted: boolean;
    isAccessCodeRequired: boolean;
    isSubscriptionRequired: boolean;
    isCipRequired: boolean;
    isContractRequired: boolean;
}
export const initialState: State = {
    isLoading: null,
    hasSuccess: null,
    hasError: null,
    message: "",
    isCompleted: false,
    isAccessCodeRequired: false,
    isSubscriptionRequired: false,
    isCipRequired: false,
    isContractRequired: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ACCOUNT_STATUS.HYDRATE: return accountStatusHydrate(state);
        case ACCOUNT_STATUS.HYDRATE_FAILURE: return accountStatusHydrateFailure(state, action.payload);
        case ACCOUNT_STATUS.HYDRATE_SUCCESS: return accountStatusHydrateSuccess(state, action.payload);
        case ACCOUNT_STATUS.UPDATE: return accountStatusUpdate(state, action.payload);
        default: return state;
    }
}
export function accountStatusHydrate(state: State): State {
    return {
        ...initialState,
        isLoading: true,
        message: null,
        hasSuccess: false,
        hasError: false,
    };
}
export function accountStatusHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function accountStatusHydrateSuccess(state: State, payload: AccountStatusState): State {
    return {
        ...state,
        ...payload,
        hasSuccess: true,
        isLoading: false,
    };
}
export function accountStatusUpdate(state: State, payload: any): State {
    return { ...state, ...payload };
}
