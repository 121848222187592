import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { State } from "@client/reducers/vehicles-details-reducer";
import { getVehiclesDetailsHasSuccess, getVehiclesDetailsHasError, getVehiclesDetailsIsLoading, getVehiclesDetailsMessage } from "@client/selectors";
/*test*/ 
@Injectable()
export class VehiclesDetailsStore extends BaseStore {
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesDetailsHasSuccess));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesDetailsHasError));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesDetailsIsLoading));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getVehiclesDetailsMessage));
    }
}
