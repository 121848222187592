import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { SectorInfo } from '@client/utils/shared-constants';
import { from, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SectorsService {
  itemsRef: AngularFireList<SectorInfo>;
  subscription: Subscription;

  constructor(private db: AngularFireDatabase) {
    this.itemsRef = this.db.list<SectorInfo>('/sectors');
  }

  addSector(newName: SectorInfo) {
    return from(this.itemsRef.push(newName));
  }

  deleteEverything() {
    this.itemsRef.remove();
  }

  deleteSector(key: string) {
    this.itemsRef.remove(key);
  }

  hydrate(): Observable<SectorInfo[]> {
    return this.itemsRef.snapshotChanges().pipe(map(changes => changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))));
  }

  updateSector(key: string, sector: SectorInfo) {
    this.itemsRef.update(key, sector);
  }
}
