import { Injectable } from '@angular/core';
import { ORGANIZATION, SESSION } from '@client/actions';
import { OrganizationHydrateAction, OrganizationHydrateFailureAction, OrganizationHydrateSuccessAction } from '@client/actions/organization-actions';
import { OrganizationsService } from '@client/core/services/organization.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { empty, iif, of } from 'rxjs';

import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class OrganizationEffects {
  @Effect({ dispatch: true })
  hydrateParks$ = this.actions$.pipe(
    ofType(ORGANIZATION.HYDRATE),
    switchMap((action: any) =>
      (action && action.payload && action.payload.scope ? this.org.hydrateOrganizationScope(action.payload) : this.org.hydrate()).pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map(parks => new OrganizationHydrateSuccessAction(parks)),
        catchError(error => of(new OrganizationHydrateFailureAction(error)))
      )
    )
  );
  @Effect({ dispatch: true })
  sessionInit$ = this.actions$.pipe(
    ofType(SESSION.INIT, 'INIT_SCOPE'),
    switchMap((action: any) => iif(() => environment.services.organization, of(new OrganizationHydrateAction((action && action.payload && { scope: action.payload.scope }) || null)), empty()))
  );

  constructor(private actions$: Actions, private store: Store<any>, private org: OrganizationsService) {}
}
