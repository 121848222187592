import { CONTRACT_CONDITIONS_SERVICE } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm } from "@client/utils/shared-constants";

export class ContractConditionsServiceInitAction implements Action {
    readonly type = CONTRACT_CONDITIONS_SERVICE.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractConditionsServiceDestroyAction implements Action {
    readonly type = CONTRACT_CONDITIONS_SERVICE.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractConditionsServiceHydrateAction implements Action {
    readonly type = CONTRACT_CONDITIONS_SERVICE.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractConditionsServiceHydrateSuccessAction implements Action {
    readonly type = CONTRACT_CONDITIONS_SERVICE.HYDRATE_SUCCESS;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class ContractConditionsServiceHydrateFailureAction implements Action {
    readonly type = CONTRACT_CONDITIONS_SERVICE.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractConditionsServiceClickAcceptAction implements Action {
    readonly type = CONTRACT_CONDITIONS_SERVICE.CLICK_ACCEPT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
