import { State as VehiclesState } from "@client/reducers/vehicles-reducer";
import { Selector } from "@ngrx/store";
import { Vehicule } from "@client/utils/shared-constants";
export const getSelectedVehicle: Selector<VehiclesState, Vehicule | null> = (state: VehiclesState) => state.selectedVehicle;
export const getSelectedVehicleDescription: Selector<VehiclesState, string> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).description;
export const getSelectedVehicleId: Selector<VehiclesState, number> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).id;
export const getSelectedVehicleKey: Selector<VehiclesState, string> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).key;
export const getSelectedVehicleLicencePlate: Selector<VehiclesState, string> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).licencePlate;
export const getSelectedVehicleManufacturer: Selector<VehiclesState, string> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).manufacturer;
export const getSelectedVehicleModel: Selector<VehiclesState, string> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).model;
export const getSelectedVehicleVehiculeYear: Selector<VehiclesState, string> = (state: VehiclesState) => getSelectedVehicle(state) && getSelectedVehicle(state).vehiculeYear;
export const getIsHydrated: Selector<VehiclesState, boolean> = (state: VehiclesState) => state.isHydrated;
export const getHasVehicles: Selector<VehiclesState, boolean> = (state: VehiclesState) => state.hasVehicles;
export const getHasSuccess: Selector<VehiclesState, boolean> = (state: VehiclesState) => state.hasSuccess;
export const getHasError: Selector<VehiclesState, boolean> = (state: VehiclesState) => state.hasError;
export const getIsLoading: Selector<VehiclesState, boolean> = (state: VehiclesState) => state.isLoading;
export const getMessage: Selector<VehiclesState, string | null> = (state: VehiclesState) => state.message;
export const getSelectedYear: Selector<VehiclesState, string> = (state: VehiclesState) => state.selectedYear;
export const getSelectedBrand: Selector<VehiclesState, string> = (state: VehiclesState) => state.selectedBrand;
export const getSelectedModel: Selector<VehiclesState, string> = (state: VehiclesState) => state.selectedModel;
export const getYears: Selector<VehiclesState, any> = (state: VehiclesState) => state.years;
export const getBrands: Selector<VehiclesState, any> = (state: VehiclesState) => state.brands;
export const getModels: Selector<VehiclesState, any> = (state: VehiclesState) => state.models;
export const getData: Selector<VehiclesState, Vehicule[]> = (state: VehiclesState) => state.data;
