import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'plancton-content-loader',
  template: `
    <div *ngIf="loading">
      <i class="fa fa-circle-o-notch fa-circle-notch fa-spin fa-fw"></i>
    </div>
    <ng-content *ngIf="!loading"></ng-content>
  `,
  styles: [
    `
      div {
        text-align: center;
        padding: 2rem;
      }

      .fa {
        font-size: 3rem;
      }
    `
  ]
})
export class ContentLoaderComponent implements OnInit {
  @Input() loading: boolean;

  constructor() {}

  ngOnInit() {}
}
