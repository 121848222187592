import { ADMIN_FAQ_LIST, ADMIN_FAQ_EDIT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { FaqInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: FaqInfo | null;
    isEdit: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: null,
    isEdit: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_FAQ_EDIT.INIT: return adminFaqEditInit(state);
        case ADMIN_FAQ_EDIT.DESTROY: return adminFaqEditDestroy(state);
        case ADMIN_FAQ_EDIT.CANCEL: return adminFaqEditCancel(state);
        case ADMIN_FAQ_EDIT.CLICK_BACK: return adminFaqEditClickBack(state);
        case ADMIN_FAQ_EDIT.SUBMIT: return adminFaqEditSubmit(state, action.payload);
        case ADMIN_FAQ_EDIT.SUBMIT_SUCCESS: return adminFaqEditSubmitSuccess(state, action.payload);
        case ADMIN_FAQ_EDIT.SUBMIT_FAILURE: return adminFaqEditSubmitFailure(state, action.payload);
        case ADMIN_FAQ_LIST.CLICK_EDIT: return adminFaqListClickEdit(state, action.payload);
        default: return state;
    }
}
export function adminFaqEditInit(state: State): State {
    return {
        ...state, isActive: true
    };
}
export function adminFaqEditDestroy(state: State): State {
    return {
        ...initialState, isActive: false
    };
}
export function adminFaqEditCancel(state: State): State {
    return state;
}
export function adminFaqEditClickBack(state: State): State {
    return state;
}
export function adminFaqEditSubmit(state: State, payload: FaqInfo): State {
    return { ...state, isLoading: true, hasError: false, hasSuccess: false };
}
export function adminFaqEditSubmitSuccess(state: State, payload: FaqInfo): State {
    return { ...state, isLoading: false, hasSuccess: true };
}
export function adminFaqEditSubmitFailure(state: State, payload: any): State {
    return { ...state, isLoading: false, hasError: true, message: payload && payload.code || payload };
}
export function adminFaqListClickEdit(state: State, payload: FaqInfo): State {
    return { ...state, data: payload, isEdit: true };
}
