import { Injectable } from '@angular/core';
import { SESSION, VEHICLES, VEHICLES_ADD, VEHICLES_DETAILS, VEHICLES_FORM } from '@client/actions';

import { SessionInitAction } from '@client/actions/session-actions';
import { UserVehiclesDeleteAction } from '@client/actions/user-vehicles-actions';
import { VehiclesFormHydrateFailureAction, VehiclesFormHydrateSuccessAction, VehiclesHydrateAction, VehiclesHydrateFailureAction, VehiclesHydrateSuccessAction } from '@client/actions/vehicles-actions';
import {
  VehiclesAddAddAction,
  VehiclesAddAddFailureAction,
  VehiclesAddAddSuccessAction,
  VehiclesAddClickSubmitAction,
  VehiclesAddUpdateAction,
  VehiclesAddUpdateFailureAction,
  VehiclesAddUpdateSuccessAction
} from '@client/actions/vehicles-add-actions';
import { VehiclesDetailsClickDeleteAction } from '@client/actions/vehicles-details-actions';
import { VehiclesFormValidationRequiredAction } from '@client/actions/vehicles-form-actions';
import { AuthService } from '@client/core/services/auth.service';
import { VehiclesService } from '@client/core/services/vehicles.service';
import { Action } from '@client/lib/action';
import { getUserVehiclesCollection, getVehiclesFormValidated, getVehiclesSelectedBrand, getVehiclesSelectedYear } from '@client/selectors';
import { Vehicule } from '@client/utils/shared-constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { empty, iif, of } from 'rxjs';
import { catchError, concatMap, map, mapTo, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class VehiclesEffects {
  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehiclesAddAddAction>(VEHICLES_ADD.ADD),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(getUserVehiclesCollection),
              map(x => (x ? x : []))
            )
          )
        )
      ),
      switchMap(([x, currentList]) => {
        if (currentList.length >= 2) {
          return of(new VehiclesAddAddFailureAction('Vous avez atteint la limite de 2 véhicules', x.correlationId));
        }

        if (currentList.find(el => el.licencePlate.toLowerCase() === x.payload.licencePlate.toLowerCase())) {
          return of(new VehiclesAddAddFailureAction('Cette immatriculation est déjà enregistrée', x.correlationId));
        }
        return this.vehicleService.create(x.payload).pipe(
          map(r => new VehiclesAddAddSuccessAction(r, x.correlationId)),
          catchError(e => of(new VehiclesAddAddFailureAction((e && e.message) || e, x.correlationId)))
        );
      })
    )
  );
  //addFormUpdate$ = createEffect(() => this.actions$.pipe(ofType<Action>(VEHICLES_ADD.SELECT_YEAR, VEHICLES_ADD.SELECT_BRAND, VEHICLES_ADD.SELECT_MODEL), mapTo(new VehiclesHydrateAction())));
  addFormUpdate$ = createEffect(() => this.actions$.pipe(ofType<Action>(VEHICLES.INIT), mapTo(new VehiclesHydrateAction())));
  clickDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehiclesDetailsClickDeleteAction>(VEHICLES_DETAILS.CLICK_DELETE),
      map(action => new UserVehiclesDeleteAction(action.payload, action.correlationId))
    )
  );
  clickSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehiclesAddClickSubmitAction>(VEHICLES_ADD.CLICK_SUBMIT),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(getVehiclesFormValidated))))),
      map(([vehicle, isValidated]) => {
        if (vehicle.payload.licencePlate && vehicle.payload.licencePlate.length < 6 && !isValidated) {
          return new VehiclesFormValidationRequiredAction();
        }
        if (vehicle.payload.id > 0) {
          return new VehiclesAddUpdateAction(vehicle.payload, vehicle.correlationId);
        }
        return new VehiclesAddAddAction(vehicle.payload, vehicle.correlationId);
      })
    )
  );
  hydrate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(VEHICLES.HYDRATE, VEHICLES_FORM.INIT, VEHICLES_ADD.SELECT_YEAR, VEHICLES_ADD.SELECT_BRAND),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(getVehiclesSelectedYear)), this.store.pipe(select(getVehiclesSelectedBrand))))),
      switchMap(([a, year, brand]) =>
        this.vehicleService.getData(year, brand).pipe(
          map(data => new VehiclesFormHydrateSuccessAction(data, a.correlationId)),
          catchError(e => of(new VehiclesFormHydrateFailureAction(e, a.correlationId)))
        )
      )
    )
  );

  hydrateVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehiclesHydrateAction>(VEHICLES.HYDRATE),
      switchMap(action =>
        this.vehicleService.hydrate().pipe(
          takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
          map((vehicles: Vehicule[]) => new VehiclesHydrateSuccessAction(vehicles, action.correlationId)),
          catchError(error => of(new VehiclesHydrateFailureAction(error, action.correlationId)))
        )
      )
    )
  );
  sessionInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SessionInitAction>(SESSION.INIT),
      switchMap(action => iif(() => environment.services.vehicles, of(new VehiclesHydrateAction(null, action.correlationId)), empty()))
    )
  );
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehiclesAddUpdateAction>(VEHICLES_ADD.UPDATE),
      switchMap(v =>
        this.vehicleService.update(v.payload).pipe(
          map(x => new VehiclesAddUpdateSuccessAction(x, v.correlationId)),
          catchError(e => of(new VehiclesAddUpdateFailureAction(e, v.correlationId)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private vehicleService: VehiclesService) {}
}
