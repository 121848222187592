import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo } from "@client/utils/shared-constants";
import { State } from "@client/reducers/subscription-edit-reducer";
import { getSubscriptionEditMessage, getSubscriptionEditHasError, getSubscriptionEditHasSuccess, getSubscriptionEditIsActive, getSubscriptionEditIsComplete, getSubscriptionEditIsLoading, getSubscriptionEditForceFormUpdate, getSubscriptionEditForm, getSubscriptionEditFormCustom, getSubscriptionEditFormSpaq, getSubscriptionEditFormSpaqAccesDocuments, getSubscriptionEditFormSpaqAccessOnHoldReason, getSubscriptionEditFormSpaqAddressLine1, getSubscriptionEditFormSpaqIdentificationCode, getSubscriptionEditFormSpaqAddressLine2, getSubscriptionEditFormSpaqAppartmentNo, getSubscriptionEditFormSpaqBalance, getSubscriptionEditFormSpaqCellPhone, getSubscriptionEditFormSpaqCity, getSubscriptionEditFormSpaqContractNo, getSubscriptionEditFormSpaqDynamicFieldJsonData, getSubscriptionEditFormSpaqEmail, getSubscriptionEditFormSpaqFirstName, getSubscriptionEditFormSpaqHomePhone, getSubscriptionEditFormSpaqHomePhoneExtension, getSubscriptionEditFormSpaqIsAccessOnHold, getSubscriptionEditFormSpaqLanguage, getSubscriptionEditFormSpaqLastName, getSubscriptionEditFormSpaqPostalCode, getSubscriptionEditFormSpaqProvince, getSubscriptionEditFormSpaqServiceCenter, getSubscriptionEditFormSpaqSex, getSubscriptionEditFormSpaqWorkPhone, getSubscriptionEditFormSpaqWorkPhoneExtension, getSubscriptionEditFormSpaqPaymentMethod, getSubscriptionEditFormSpaqPaymentMethodCreditCardDataKey, getSubscriptionEditFormSpaqPaymentMethodEffectiveDate, getSubscriptionEditFormSpaqPaymentMethodEndDate, getSubscriptionEditFormSpaqPaymentMethodFinancialInstitutionId, getSubscriptionEditFormSpaqPaymentMethodFolioNo, getSubscriptionEditFormSpaqPaymentMethodMethod, getSubscriptionEditFormSpaqPaymentMethodTransitNo } from "@client/selectors";
/*test*/ 
@Injectable()
export class SubscriptionEditStore extends BaseStore {
    get message(): Observable<string | null> {
        return this.store.pipe(select(getSubscriptionEditMessage));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditHasError));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditHasSuccess));
    }
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditIsActive));
    }
    get isComplete(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditIsComplete));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditIsLoading));
    }
    get forceFormUpdate(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditForceFormUpdate));
    }
    get form(): Observable<SubscriptionInfo> {
        return this.store.pipe(select(getSubscriptionEditForm));
    }
    get formCustom(): Observable<any> {
        return this.store.pipe(select(getSubscriptionEditFormCustom));
    }
    get formSpaq(): Observable<SubscriptionSpaqInfo> {
        return this.store.pipe(select(getSubscriptionEditFormSpaq));
    }
    get formSpaqAccesDocuments(): Observable<AccesDocumentInfo[]> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqAccesDocuments));
    }
    get formSpaqAccessOnHoldReason(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqAccessOnHoldReason));
    }
    get formSpaqAddressLine1(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqAddressLine1));
    }
    get formSpaqIdentificationCode(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqIdentificationCode));
    }
    get formSpaqAddressLine2(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqAddressLine2));
    }
    get formSpaqAppartmentNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqAppartmentNo));
    }
    get formSpaqBalance(): Observable<number> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqBalance));
    }
    get formSpaqCellPhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqCellPhone));
    }
    get formSpaqCity(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqCity));
    }
    get formSpaqContractNo(): Observable<number> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqContractNo));
    }
    get formSpaqDynamicFieldJsonData(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqDynamicFieldJsonData));
    }
    get formSpaqEmail(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqEmail));
    }
    get formSpaqFirstName(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqFirstName));
    }
    get formSpaqHomePhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqHomePhone));
    }
    get formSpaqHomePhoneExtension(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqHomePhoneExtension));
    }
    get formSpaqIsAccessOnHold(): Observable<boolean> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqIsAccessOnHold));
    }
    get formSpaqLanguage(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqLanguage));
    }
    get formSpaqLastName(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqLastName));
    }
    get formSpaqPostalCode(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPostalCode));
    }
    get formSpaqProvince(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqProvince));
    }
    get formSpaqServiceCenter(): Observable<number> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqServiceCenter));
    }
    get formSpaqSex(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqSex));
    }
    get formSpaqWorkPhone(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqWorkPhone));
    }
    get formSpaqWorkPhoneExtension(): Observable<number> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqWorkPhoneExtension));
    }
    get formSpaqPaymentMethod(): Observable<PaymentMethodInfo> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethod));
    }
    get formSpaqPaymentMethodCreditCardDataKey(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodCreditCardDataKey));
    }
    get formSpaqPaymentMethodEffectiveDate(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodEffectiveDate));
    }
    get formSpaqPaymentMethodEndDate(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodEndDate));
    }
    get formSpaqPaymentMethodFinancialInstitutionId(): Observable<number> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodFinancialInstitutionId));
    }
    get formSpaqPaymentMethodFolioNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodFolioNo));
    }
    get formSpaqPaymentMethodMethod(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodMethod));
    }
    get formSpaqPaymentMethodTransitNo(): Observable<string> {
        return this.store.pipe(select(getSubscriptionEditFormSpaqPaymentMethodTransitNo));
    }
}
