import { SUBSCRIPTION_DETAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { SubscriptionInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    isLoading: boolean;
    form: SubscriptionInfo;
}
export const initialState: State = {
    isActive: false,
    isLoading: false,
    form: {
        custom: "custom",
        spaq: {
            accesDocuments: [],
            accessOnHoldReason: "",
            addressLine1: "",
            identificationCode: "",
            addressLine2: "",
            appartmentNo: "",
            balance: 0,
            cellPhone: "",
            city: "",
            contractNo: 0,
            dynamicFieldJsonData: "",
            email: "",
            firstName: "",
            homePhone: "",
            homePhoneExtension: "",
            isAccessOnHold: null,
            language: "French",
            lastName: "",
            postalCode: "",
            province: "QC",
            serviceCenter: 0,
            sex: "Male",
            workPhone: "",
            workPhoneExtension: 0,
            paymentMethod: {
                CreditCardDataKey: "",
                EffectiveDate: "",
                EndDate: "",
                FinancialInstitutionId: 0,
                FolioNo: "",
                Method: "Cash",
                TransitNo: ""
            }
        }
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SUBSCRIPTION_DETAILS.INIT: return subscriptionDetailsInit(state);
        case SUBSCRIPTION_DETAILS.DESTROY: return subscriptionDetailsDestroy(state);
        case SUBSCRIPTION_DETAILS.CLICK_EDIT: return subscriptionDetailsClickEdit(state);
        default: return state;
    }
}
export function subscriptionDetailsInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true
    };
}
export function subscriptionDetailsDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function subscriptionDetailsClickEdit(state: State): State {
    return state;
}
