import { Pipe, PipeTransform } from '@angular/core';
import { IsNilPipe } from './is-nil.pipe';

@Pipe({
  name: 'convertToBoolean'
})
export class ConvertToBooleanPipe implements PipeTransform {
  constructor(private isNil: IsNilPipe) {}

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return !!args;
    }
    return !!value;
  }
}
