import { REGISTER } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { RegisterEmailForm, UserEntity, AccessCodeInfo, ContractForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isRegistered: boolean;
    isLogged: boolean;
    isWaitingValidation: boolean;
    isActive: boolean;
    isComplete: boolean;
    isLoading: boolean;
    form: RegisterEmailForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isRegistered: false,
    isLogged: false,
    isWaitingValidation: false,
    isActive: false,
    isComplete: false,
    isLoading: false,
    form: {
        confirm_password: "",
        email: "",
        method: "",
        password: "",
        cip: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case REGISTER.ALREADY_CONFIRMED: return registerAlreadyConfirmed(state);
        case REGISTER.DESTROY_ACCESS_CODE: return registerDestroyAccessCode(state);
        case REGISTER.DESTROY_CONTRACT: return registerDestroyContract(state);
        case REGISTER.DESTROY_CONTRACT_CONFIRMATION: return registerDestroyContractConfirmation(state);
        case REGISTER.DESTROY_EMAIL: return registerDestroyEmail(state);
        case REGISTER.DESTROY_EMAIL_PENDING: return registerDestroyEmailPending(state);
        case REGISTER.DESTROY_SUBSCRIPTION: return registerDestroySubscription(state);
        case REGISTER.GO_TO_SUBSCRIPTION: return registerGoToSubscription(state);
        case REGISTER.INIT_ACCESS_CODE: return registerInitAccessCode(state);
        case REGISTER.INIT_CONTRACT: return registerInitContract(state);
        case REGISTER.INIT_CONTRACT_CONFIRMATION: return registerInitContractConfirmation(state);
        case REGISTER.INIT_EMAIL: return registerInitEmail(state);
        case REGISTER.INIT_EMAIL_PENDING: return registerInitEmailPending(state);
        case REGISTER.INIT_SUBSCRIPTION: return registerInitSubscription(state);
        case REGISTER.NOT_LOGGED_IN: return registerNotLoggedIn(state);
        case REGISTER.RECOVER_EMAIL_FAILURE: return registerRecoverEmailFailure(state, action.payload);
        case REGISTER.RECOVER_EMAIL_SUCCESS: return registerRecoverEmailSuccess(state, action.payload);
        case REGISTER.REGISTER_CODE_EMAIL_FAILURE: return registerRegisterCodeEmailFailure(state, action.payload);
        case REGISTER.REGISTER_CODE_EMAIL_SUCCESS: return registerRegisterCodeEmailSuccess(state, action.payload);
        case REGISTER.REGISTER_CODE_SUCCESS: return registerRegisterCodeSuccess(state, action.payload);
        case REGISTER.REGISTER_CODE_FAILURE: return registerRegisterCodeFailure(state, action.payload);
        case REGISTER.REGISTER_CONTRACT_EMAIL_SUCCESS: return registerRegisterContractEmailSuccess(state, action.payload);
        case REGISTER.REGISTER_CONTRACT_EMAIL_FAILURE: return registerRegisterContractEmailFailure(state, action.payload);
        case REGISTER.REGISTER_CONTRACT_SUCCESS: return registerRegisterContractSuccess(state, action.payload);
        case REGISTER.REGISTER_CONTRACT_FAILURE: return registerRegisterContractFailure(state, action.payload);
        case REGISTER.REGISTER_FAILURE: return registerRegisterFailure(state, action.payload);
        case REGISTER.REGISTER_SUCCESS: return registerRegisterSuccess(state, action.payload);
        case REGISTER.SELECT_METHOD: return registerSelectMethod(state, action.payload);
        case REGISTER.SEND_VALIDATION_LINK_FAILURE: return registerSendValidationLinkFailure(state, action.payload);
        case REGISTER.SEND_VALIDATION_LINK_SUCCESS: return registerSendValidationLinkSuccess(state);
        case REGISTER.SUBMIT_EMAIL_FAILURE: return registerSubmitEmailFailure(state, action.payload);
        case REGISTER.VALIDATE_EMAIL_SUCCESS: return registerValidateEmailSuccess(state);
        case REGISTER.VALIDATE_EMAIL_FAILURE: return registerValidateEmailFailure(state, action.payload);
        case REGISTER.CLICK_BACK_TO_LOGIN: return registerClickBackToLogin(state);
        case REGISTER.CLICK_IGNORE_ACCESS_CODE: return registerClickIgnoreAccessCode(state);
        case REGISTER.CLICK_IGNORE_CONTRACT: return registerClickIgnoreContract(state);
        case REGISTER.CLICK_IGNORE_SUBSCRIPTION: return registerClickIgnoreSubscription(state);
        case REGISTER.GO_TO_ACCESS_CODE: return registerGoToAccessCode(state);
        case REGISTER.GO_TO_CONTRACT: return registerGoToContract(state);
        case REGISTER.GO_TO_CONTRACT_CONFIRMATION: return registerGoToContractConfirmation(state);
        case REGISTER.GO_TO_EMAIL: return registerGoToEmail(state);
        case REGISTER.GO_TO_EMAIL_PENDING: return registerGoToEmailPending(state);
        case REGISTER.LOGIN: return registerLogin(state);
        case REGISTER.LOGOUT: return registerLogout(state);
        case REGISTER.RECOVER_EMAIL: return registerRecoverEmail(state, action.payload);
        case REGISTER.REGISTER: return registerRegister(state, action.payload);
        case REGISTER.REGISTER_CODE: return registerRegisterCode(state, action.payload);
        case REGISTER.REGISTER_CODE_EMAIL: return registerRegisterCodeEmail(state, action.payload);
        case REGISTER.REGISTER_CONTRACT: return registerRegisterContract(state, action.payload);
        case REGISTER.REGISTER_CONTRACT_EMAIL: return registerRegisterContractEmail(state, action.payload);
        case REGISTER.REGISTER_CONTRACT_RESET: return registerRegisterContractReset(state);
        case REGISTER.RESEND: return registerResend(state);
        case REGISTER.SEARCH_CONTRACT: return registerSearchContract(state, action.payload);
        case REGISTER.SEND_VALIDATION_LINK: return registerSendValidationLink(state);
        case REGISTER.SUBMIT_EMAIL: return registerSubmitEmail(state, action.payload);
        case REGISTER.UPDATE_EMAIL_FORM: return registerUpdateEmailForm(state, action.payload);
        case REGISTER.VALIDATE_EMAIL: return registerValidateEmail(state, action.payload);
        default: return state;
    }
}
export function registerAlreadyConfirmed(state: State): State {
    return state;
}
export function registerDestroyAccessCode(state: State): State {
    return state;
}
export function registerDestroyContract(state: State): State {
    return state;
}
export function registerDestroyContractConfirmation(state: State): State {
    return state;
}
export function registerDestroyEmail(state: State): State {
    return state;
}
export function registerDestroyEmailPending(state: State): State {
    return state;
}
export function registerDestroySubscription(state: State): State {
    return state;
}
export function registerGoToSubscription(state: State): State {
    return state;
}
export function registerInitAccessCode(state: State): State {
    return state;
}
export function registerInitContract(state: State): State {
    return state;
}
export function registerInitContractConfirmation(state: State): State {
    return state;
}
export function registerInitEmail(state: State): State {
    return state;
}
export function registerInitEmailPending(state: State): State {
    return state;
}
export function registerInitSubscription(state: State): State {
    return state;
}
export function registerNotLoggedIn(state: State): State {
    return state;
}
export function registerRecoverEmailFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRecoverEmailSuccess(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterCodeEmailFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterCodeEmailSuccess(state: State, payload: UserEntity): State {
    return { ...state };
}
export function registerRegisterCodeSuccess(state: State, payload: AccessCodeInfo): State {
    return { ...state };
}
export function registerRegisterCodeFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterContractEmailSuccess(state: State, payload: UserEntity): State {
    return { ...state };
}
export function registerRegisterContractEmailFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterContractSuccess(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterContractFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterSuccess(state: State, payload: UserEntity): State {
    return { ...state };
}
export function registerSelectMethod(state: State, payload: string): State {
    return { ...state };
}
export function registerSendValidationLinkFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerSendValidationLinkSuccess(state: State): State {
    return state;
}
export function registerSubmitEmailFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerValidateEmailSuccess(state: State): State {
    return state;
}
export function registerValidateEmailFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerClickBackToLogin(state: State): State {
    return state;
}
export function registerClickIgnoreAccessCode(state: State): State {
    return state;
}
export function registerClickIgnoreContract(state: State): State {
    return state;
}
export function registerClickIgnoreSubscription(state: State): State {
    return state;
}
export function registerGoToAccessCode(state: State): State {
    return state;
}
export function registerGoToContract(state: State): State {
    return state;
}
export function registerGoToContractConfirmation(state: State): State {
    return state;
}
export function registerGoToEmail(state: State): State {
    return state;
}
export function registerGoToEmailPending(state: State): State {
    return state;
}
export function registerLogin(state: State): State {
    return state;
}
export function registerLogout(state: State): State {
    return state;
}
export function registerRecoverEmail(state: State, payload: string): State {
    return { ...state };
}
export function registerRegister(state: State, payload: RegisterEmailForm): State {
    return { ...state };
}
export function registerRegisterCode(state: State, payload: AccessCodeInfo): State {
    return { ...state };
}
export function registerRegisterCodeEmail(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterContract(state: State, payload: ContractForm): State {
    return { ...state };
}
export function registerRegisterContractEmail(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function registerRegisterContractReset(state: State): State {
    return state;
}
export function registerResend(state: State): State {
    return state;
}
export function registerSearchContract(state: State, payload: ContractForm): State {
    return { ...state };
}
export function registerSendValidationLink(state: State): State {
    return state;
}
export function registerSubmitEmail(state: State, payload: RegisterEmailForm): State {
    return { ...state };
}
export function registerUpdateEmailForm(state: State, payload: RegisterEmailForm): State {
    return { ...state };
}
export function registerValidateEmail(state: State, payload: string): State {
    return { ...state };
}
