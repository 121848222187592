import { InjectionToken } from '@angular/core';
import { IAccessCodeAddDispatcher, IAccessCodeAddStore } from '@client/interfaces/access-code-add-interface';
import { IAccessCodeDetailsDispatcher, IAccessCodeDetailsStore } from '@client/interfaces/access-code-details-interface';
import { IAccessCodeDispatcher, IAccessCodeStore } from '@client/interfaces/access-code-interface';
import { IProfileDetailsDispatcher, IProfileDetailsStore } from '@client/interfaces/profile-details-interface';
import { IProfileEditDispatcher, IProfileEditStore } from '@client/interfaces/profile-edit-interface';
import { IProfileDispatcher, IProfileStore } from '@client/interfaces/profile-interface';
import { ISubscriptionDetailsDispatcher, ISubscriptionDetailsStore } from '@client/interfaces/subscription-details-interface';
import { ISubscriptionEditDispatcher, ISubscriptionEditStore } from '@client/interfaces/subscription-edit-interface';
import { ISubscriptionDispatcher, ISubscriptionStore } from '@client/interfaces/subscription-interface';
import { IVehiclesAddDispatcher, IVehiclesAddStore } from '@client/interfaces/vehicles-add-interface';
import { IVehiclesDetailsDispatcher, IVehiclesDetailsStore } from '@client/interfaces/vehicles-details-interface';
import { IVehiclesEditDispatcher, IVehiclesEditStore } from '@client/interfaces/vehicles-edit-interface';
import { IVehiclesFormDispatcher, IVehiclesFormStore } from '@client/interfaces/vehicles-form-interface';
import { IVehiclesDispatcher, IVehiclesStore } from '@client/interfaces/vehicles-interface';

export const PROFILE_DISPATCHER = new InjectionToken<IProfileDispatcher>('');
export const PROFILE_STORE = new InjectionToken<IProfileStore>('');
export const PROFILE_EDIT_DISPATCHER = new InjectionToken<IProfileEditDispatcher>('');
export const PROFILE_EDIT_STORE = new InjectionToken<IProfileEditStore>('');
export const PROFILE_DETAILS_DISPATCHER = new InjectionToken<IProfileDetailsDispatcher>('');
export const PROFILE_DETAILS_STORE = new InjectionToken<IProfileDetailsStore>('');

export const SUBSCRIPTION_DISPATCHER = new InjectionToken<ISubscriptionDispatcher>('');
export const SUBSCRIPTION_STORE = new InjectionToken<ISubscriptionStore>('');
export const SUBSCRIPTION_EDIT_DISPATCHER = new InjectionToken<ISubscriptionEditDispatcher>('');
export const SUBSCRIPTION_EDIT_STORE = new InjectionToken<ISubscriptionEditStore>('');
export const SUBSCRIPTION_DETAILS_DISPATCHER = new InjectionToken<ISubscriptionDetailsDispatcher>('');
export const SUBSCRIPTION_DETAILS_STORE = new InjectionToken<ISubscriptionDetailsStore>('');

export const ACCESS_CODE_DISPATCHER = new InjectionToken<IAccessCodeDispatcher>('');
export const ACCESS_CODE_STORE = new InjectionToken<IAccessCodeStore>('');
export const ACCESS_CODE_ADD_DISPATCHER = new InjectionToken<IAccessCodeAddDispatcher>('');
export const ACCESS_CODE_ADD_STORE = new InjectionToken<IAccessCodeAddStore>('');
export const ACCESS_CODE_DETAILS_DISPATCHER = new InjectionToken<IAccessCodeDetailsDispatcher>('');
export const ACCESS_CODE_DETAILS_STORE = new InjectionToken<IAccessCodeDetailsStore>('');

export const VEHICLES_STORE = new InjectionToken<IVehiclesStore>('');
export const VEHICLES_DETAILS_STORE = new InjectionToken<IVehiclesDetailsStore>('');
export const VEHICLES_EDIT_STORE = new InjectionToken<IVehiclesEditStore>('');
export const VEHICLES_ADD_STORE = new InjectionToken<IVehiclesAddStore>('');
export const VEHICLES_DISPATCHER = new InjectionToken<IVehiclesDispatcher>('');
export const VEHICLES_FORM_DISPATCHER = new InjectionToken<IVehiclesFormDispatcher>('');
export const VEHICLES_FORM_STORE = new InjectionToken<IVehiclesFormStore>('');
export const VEHICLES_EDIT_DISPATCHER = new InjectionToken<IVehiclesEditDispatcher>('');
export const VEHICLES_ADD_DISPATCHER = new InjectionToken<IVehiclesAddDispatcher>('');
export const VEHICLES_DETAILS_DISPATCHER = new InjectionToken<IVehiclesDetailsDispatcher>('');
