import { ADMIN } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    activePage: string;
}
export const initialState: State = {
    isActive: false,
    activePage: "organization"
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN.INIT: return adminInit(state);
        case ADMIN.DESTROY: return adminDestroy(state);
        case ADMIN.SHOW_PAGE: return adminShowPage(state, action.payload);
        default: return state;
    }
}
export function adminInit(state: State): State {
    return {
        ...initialState,
        isActive: true,
    };
}
export function adminDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function adminShowPage(state: State, payload: string): State {
    return { ...state, activePage: payload };
}
