import { State as OrganizationState, adapter as OrganizationAdapter } from "@client/reducers/organization-reducer";
import { Selector } from "@ngrx/store";
import { OrganizationInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<OrganizationState, string | null> = (state: OrganizationState) => state.message;
export const getHasError: Selector<OrganizationState, boolean> = (state: OrganizationState) => state.hasError;
export const getHasSuccess: Selector<OrganizationState, boolean> = (state: OrganizationState) => state.hasSuccess;
export const getIsLoading: Selector<OrganizationState, boolean> = (state: OrganizationState) => state.isLoading;
export const getSelected: Selector<OrganizationState, string | number | null> = (state: OrganizationState) => state.selected;
const { selectIds, selectEntities, selectAll, selectTotal } = OrganizationAdapter.getSelectors();
export const getIds = selectIds;
export const getEntities = selectEntities;
export const getAll = selectAll;
export const getTotal = selectTotal;
