import { PROFILE_DETAILS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities } from "@client/utils/shared-constants";

export class ProfileDetailsDestroyAction implements Action {
    readonly type = PROFILE_DETAILS.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ProfileDetailsInitAction implements Action {
    readonly type = PROFILE_DETAILS.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
