import { ADMIN_ORGANIZATION_FORM, ADMIN_ORGANIZATION_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OrganizationInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: OrganizationInfo[];
    selectedOrganization: OrganizationInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    selectedOrganization: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_ORGANIZATION_LIST.INIT: return adminOrganizationListInit(state);
        case ADMIN_ORGANIZATION_LIST.DESTROY: return adminOrganizationListDestroy(state);
        case ADMIN_ORGANIZATION_LIST.HYDRATE: return adminOrganizationListHydrate(state);
        case ADMIN_ORGANIZATION_LIST.HYDRATE_SUCCESS: return adminOrganizationListHydrateSuccess(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.HYDRATE_FAILURE: return adminOrganizationListHydrateFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.DELETE: return adminOrganizationListDelete(state);
        case ADMIN_ORGANIZATION_LIST.DELETE_SUCCESS: return adminOrganizationListDeleteSuccess(state);
        case ADMIN_ORGANIZATION_LIST.DELETE_FAILURE: return adminOrganizationListDeleteFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.CLICK_ADD: return adminOrganizationListClickAdd(state);
        case ADMIN_ORGANIZATION_LIST.CLICK_EDIT: return adminOrganizationListClickEdit(state);
        case ADMIN_ORGANIZATION_LIST.CLICK_DELETE: return adminOrganizationListClickDelete(state);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SHOW_GROUPS: return adminOrganizationListShowGroups(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SHOW_CODES: return adminOrganizationListShowCodes(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.UPDATE_SUCCESS: return adminOrganizationFormUpdateSuccess(state);
        case ADMIN_ORGANIZATION_FORM.ADD_SUCCESS: return adminOrganizationFormAddSuccess(state);
        default: return state;
    }
}
export function adminOrganizationListInit(state: State): State {
    return {
        ...initialState,
        data: state.data,
        selectedOrganization: state.selectedOrganization,
        isActive: true
    };
}
export function adminOrganizationListDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminOrganizationListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminOrganizationListHydrateSuccess(state: State, payload: OrganizationInfo[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload
    };
}
export function adminOrganizationListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminOrganizationListDelete(state: State): State {
    return {
        ...state,
        message: null,
        hasError: false
    };
}
export function adminOrganizationListDeleteSuccess(state: State): State {
    return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
    };
}
export function adminOrganizationListDeleteFailure(state: State, payload: any): State {
    return { ...state, hasError: true, message: payload };
}
export function adminOrganizationListClickAdd(state: State): State {
    return state;
}
export function adminOrganizationListClickEdit(state: State): State {
    return state;
}
export function adminOrganizationListClickDelete(state: State): State {
    return state;
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        selectedOrganization: payload
    };
}
export function adminOrganizationListShowGroups(state: State, payload: OrganizationInfo): State {
    return { ...state };
}
export function adminOrganizationListShowCodes(state: State, payload: OrganizationInfo): State {
    return { ...state };
}
export function adminOrganizationFormUpdateSuccess(state: State): State {
    return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
    };
}
export function adminOrganizationFormAddSuccess(state: State): State {
    return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
    };
}
