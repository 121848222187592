import { Injectable } from "@angular/core";
import { IVehiclesDispatcher } from "@client/interfaces/vehicles-interface";
import { Store } from "@ngrx/store";
import { VehiclesInitAction, VehiclesDestroyAction, VehiclesFormHydrateAction, VehiclesFormHydrateSuccessAction, VehiclesFormHydrateFailureAction, VehiclesHydrateAction, VehiclesHydrateSuccessAction, VehiclesHydrateFailureAction, VehiclesHydrateBrandsSuccessAction, VehiclesHydrateBrandsFailureAction, VehiclesHydrateModelsSuccessAction, VehiclesHydrateModelsFailureAction, VehiclesSelectYearAction, VehiclesSelectBrandAction } from "@client/actions/vehicles-actions";
@Injectable()
export class VehiclesDispatcher implements IVehiclesDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesDestroyAction(payload, correlationId));
    }
    formHydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormHydrateAction(payload, correlationId));
    }
    formHydrateSuccess(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormHydrateSuccessAction(payload, correlationId));
    }
    formHydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormHydrateFailureAction(payload, correlationId));
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateFailureAction(payload, correlationId));
    }
    hydrateBrandsSuccess(payload: string[], correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateBrandsSuccessAction(payload, correlationId));
    }
    hydrateBrandsFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateBrandsFailureAction(payload, correlationId));
    }
    hydrateModelsSuccess(payload: string[], correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateModelsSuccessAction(payload, correlationId));
    }
    hydrateModelsFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesHydrateModelsFailureAction(payload, correlationId));
    }
    selectYear(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesSelectYearAction(payload, correlationId));
    }
    selectBrand(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesSelectBrandAction(payload, correlationId));
    }
}
