import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {
  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) {}

  hydrate(): Observable<any> {
    return this.db.object('/config').valueChanges();
  }
}
