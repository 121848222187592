import { State as AdminServiceFormState } from "@client/reducers/admin-service-form-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeServiceInfo, ServiceInfo, ServiceMetaInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => state.isActive;
export const getMessage: Selector<AdminServiceFormState, string | null> = (state: AdminServiceFormState) => state.message;
export const getHasError: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => state.hasError;
export const getHasSuccess: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => state.hasSuccess;
export const getIsLoading: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => state.isLoading;
export const getIsEdit: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => state.isEdit;
export const getForm: Selector<AdminServiceFormState, AccessCodeServiceInfo | null> = (state: AdminServiceFormState) => state.form;
export const getFormGroupCode: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getForm(state) && getForm(state).groupCode;
export const getFormOrganizationCode: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getForm(state) && getForm(state).organizationCode;
export const getFormServiceId: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getForm(state) && getForm(state).serviceId;
export const getFormParkIdentificationNo: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getForm(state) && getForm(state).parkIdentificationNo;
export const getFormServiceEntity: Selector<AdminServiceFormState, ServiceInfo> = (state: AdminServiceFormState) => getForm(state) && getForm(state).serviceEntity;
export const getFormServiceEntityPeriod: Selector<AdminServiceFormState, any> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).period;
export const getFormServiceEntityAvailableFrom: Selector<AdminServiceFormState, string | null> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).availableFrom;
export const getFormServiceEntityAvailableServiceId: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).availableServiceId;
export const getFormServiceEntityAvailableUntil: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).availableUntil;
export const getFormServiceEntityCancelationNotice: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).cancelationNotice;
export const getFormServiceEntityCancelationNoticeTimeUnit: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).cancelationNoticeTimeUnit;
export const getFormServiceEntityDuration: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).duration;
export const getFormServiceEntityDurationTimeUnit: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).durationTimeUnit;
export const getFormServiceEntityIsBankingCardPaymentAccepted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isBankingCardPaymentAccepted;
export const getFormServiceEntityIsCancelable: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isCancelable;
export const getFormServiceEntityIsCancelationFirstDayOfMonth: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isCancelationFirstDayOfMonth;
export const getFormServiceEntityIsDasPaymentAccepted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isDasPaymentAccepted;
export const getFormServiceEntityIsFinancialInstitutionPaymentAccepted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isFinancialInstitutionPaymentAccepted;
export const getFormServiceEntityIsFixedDuration: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isFixedDuration;
export const getFormServiceEntityIsPpaPaymentAccepted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isPpaPaymentAccepted;
export const getFormServiceEntityIsSubscriptionAvailable: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isSubscriptionAvailable;
export const getFormServiceEntityIsTax1Exempted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isTax1Exempted;
export const getFormServiceEntityIsTax2Exempted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isTax2Exempted;
export const getFormServiceEntityIsTax3Exempted: Selector<AdminServiceFormState, boolean> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).isTax3Exempted;
export const getFormServiceEntityOnSaleFrom: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).onSaleFrom;
export const getFormServiceEntityOnSaleUntil: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).onSaleUntil;
export const getFormServiceEntityParkIdentificationNo: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).parkIdentificationNo;
export const getFormServiceEntityPartialMonthCalculation: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).partialMonthCalculation;
export const getFormServiceEntityPrice: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).price;
export const getFormServiceEntitySectorId: Selector<AdminServiceFormState, number> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).sectorId;
export const getFormServiceEntityServiceBillingType: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).serviceBillingType;
export const getFormServiceEntityServiceDescription: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).serviceDescription;
export const getFormServiceEntityServiceStartRule: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).serviceStartRule;
export const getFormServiceEntityServiceType: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).serviceType;
export const getFormServiceEntityMeta: Selector<AdminServiceFormState, ServiceMetaInfo> = (state: AdminServiceFormState) => getFormServiceEntity(state) && getFormServiceEntity(state).meta;
export const getFormServiceEntityMetaNote: Selector<AdminServiceFormState, string> = (state: AdminServiceFormState) => getFormServiceEntityMeta(state) && getFormServiceEntityMeta(state).note;
