import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccessCodeEffects } from '@client/effects/access-code';
import { AccountStatusEffects } from '@client/effects/account-status';
import { AuthEffects } from '@client/effects/auth';
import { CalendarEffects } from '@client/effects/calendar';
import { ConfigEffects } from '@client/effects/config';
import { ContactEffects } from '@client/effects/contact';
import { ContractEffects } from '@client/effects/contract';
import { FaqEffects } from '@client/effects/faq';
import { FireServicesEffects } from '@client/effects/fire-services';
import { LayoutEffects } from '@client/effects/layout';
import { LoginAuthEffects } from '@client/effects/login-auth';
import { MetadataEffects } from '@client/effects/metadata';
import { MonerisEffects } from '@client/effects/moneris';
import { NetworkStatusEffects } from '@client/effects/network-status';
import { OobAuthEffects } from '@client/effects/oob-auth';
import { OrganizationEffects } from '@client/effects/organization';
import { ParksEffects } from '@client/effects/parks';
import { PasswordAuthEffects } from '@client/effects/password-auth';
import { PpaEffects } from '@client/effects/ppa';
import { ProfileEffects } from '@client/effects/profile';
import { RedirectEffects } from '@client/effects/redirect';
import { RegisterContractEffects } from '@client/effects/register-contract';
import { RegisterEmailAuthEffects } from '@client/effects/register-email-auth';
import { RegisterEmailPendingAuthEffects } from '@client/effects/register-email-pending-auth';
import { RegisterESubscriptionEffects } from '@client/effects/register-subscription';
import { ScopeEffects } from '@client/effects/scope';
import { SectorsEffects } from '@client/effects/sectors';
import { ServicesEffects } from '@client/effects/services';
import { SessionEffects } from '@client/effects/session';
import { SettingsEffects } from '@client/effects/settings';
import { SplashEffects } from '@client/effects/splash';
import { SubscriptionEffects } from '@client/effects/subscription';
import { SubscriptionEditEffects } from '@client/effects/subscription-edit';
import { TelemetryEffects } from '@client/effects/telemetry';
import { UserEffects } from '@client/effects/user';
import { UserSessionEffects } from '@client/effects/user-session';
import { UserVehiclesEffects } from '@client/effects/user-vehicles';
import { VehiclesEffects } from '@client/effects/vehicles';
import { WindowEffects } from '@client/effects/window';
import { EffectsModule as NgrxEffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    NgrxEffectsModule.forRoot([
      SectorsEffects,
      CalendarEffects,
      FireServicesEffects,
      SessionEffects,
      AuthEffects,
      LoginAuthEffects,
      RedirectEffects,
      PasswordAuthEffects,
      OobAuthEffects,
      RegisterEmailAuthEffects,
      MetadataEffects,
      RegisterEmailPendingAuthEffects,
      RegisterESubscriptionEffects,
      UserSessionEffects,
      UserEffects,
      RegisterContractEffects,
      SubscriptionEditEffects,
      UserVehiclesEffects,
      VehiclesEffects,
      AccountStatusEffects,
      FaqEffects,
      ConfigEffects,
      LayoutEffects,
      ScopeEffects,
      ProfileEffects,
      SubscriptionEffects,
      ParksEffects,
      OrganizationEffects,
      SettingsEffects,
      SplashEffects,
      ServicesEffects,
      ContractEffects,
      AccessCodeEffects,
      NetworkStatusEffects,
      WindowEffects,
      PpaEffects,
      MonerisEffects,
      TelemetryEffects,
      ContactEffects
    ])
  ],
  providers: [],
  declarations: []
})
export class EffectsModule {}
