import { State as AdminOrganizationFormState } from "@client/reducers/admin-organization-form-reducer";
import { Selector } from "@ngrx/store";
import { OrganizationInfo, OrganizationConfigInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => state.isActive;
export const getMessage: Selector<AdminOrganizationFormState, string | null> = (state: AdminOrganizationFormState) => state.message;
export const getHasError: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => state.hasError;
export const getHasSuccess: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => state.hasSuccess;
export const getIsLoading: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => state.isLoading;
export const getIsEdit: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => state.isEdit;
export const getForm: Selector<AdminOrganizationFormState, OrganizationInfo | null> = (state: AdminOrganizationFormState) => state.form;
export const getFormAdresse: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).adresse;
export const getFormCity: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).city;
export const getFormOrganizationCode: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).organizationCode;
export const getFormOrganizationName: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).organizationName;
export const getFormPhoneNumber: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).phoneNumber;
export const getFormPostalCode: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).postalCode;
export const getFormProvince: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).province;
export const getFormLogo: Selector<AdminOrganizationFormState, string> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).logo;
export const getFormConfig: Selector<AdminOrganizationFormState, OrganizationConfigInfo | null> = (state: AdminOrganizationFormState) => getForm(state) && getForm(state).config;
export const getFormConfigEnableGateway: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => getFormConfig(state) && getFormConfig(state).enableGateway;
export const getFormConfigEnablePublicRegistration: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => getFormConfig(state) && getFormConfig(state).enablePublicRegistration;
export const getFormConfigEnablePrivateRegistration: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => getFormConfig(state) && getFormConfig(state).enablePrivateRegistration;
export const getFormConfigDisablePublicSubscriptionService: Selector<AdminOrganizationFormState, boolean> = (state: AdminOrganizationFormState) => getFormConfig(state) && getFormConfig(state).disablePublicSubscriptionService;
