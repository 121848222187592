import { Injectable } from '@angular/core';
import { REGISTER_SUBSCRIPTION } from '@client/actions';
import { AuthLogoutAction } from '@client/actions/auth-actions';
import { Action } from '@client/lib/action';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class RegisterContractEffects {
  @Effect()
  clickContinue$: Observable<Action> = this.actions$.pipe(ofType(REGISTER_SUBSCRIPTION.CLICK_LOGOUT), mapTo(new AuthLogoutAction()));

  constructor(private actions$: Actions) {}
}
