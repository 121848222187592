import { AUTH, SESSION, LOGIN } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { LoginForm, Session } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isLoggedIn: boolean;
    form: {
        email: string;
        password: string;
    };
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: false,
    isLoggedIn: false,
    form: {
        email: "",
        password: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case LOGIN.CLICK_SIGNIN: return loginClickSignin(state);
        case LOGIN.CLICK_LOGOUT: return loginClickLogout(state);
        case LOGIN.CLICK_REGISTER: return loginClickRegister(state);
        case LOGIN.CLICK_FORGOT_PASSWORD: return loginClickForgotPassword(state);
        case LOGIN.FORM_UPDATE: return loginFormUpdate(state, action.payload);
        case LOGIN.FORM_RESET: return loginFormReset(state);
        case LOGIN.INIT: return loginInit(state);
        case LOGIN.DESTROY: return loginDestroy(state);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD: return authSignInWithEmailAndPassword(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS: return authSignInWithEmailAndPasswordSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE: return authSignInWithEmailAndPasswordFailure(state, action.payload);
        case SESSION.STATE_CHANGE: return sessionStateChange(state, action.payload);
        default: return state;
    }
}
export function loginClickSignin(state: State): State {
    return state;
}
export function loginClickLogout(state: State): State {
    return state;
}
export function loginClickRegister(state: State): State {
    return state;
}
export function loginClickForgotPassword(state: State): State {
    return state;
}
export function loginFormUpdate(state: State, payload: LoginForm): State {
    return { ...state, form: payload };
}
export function loginFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form,
    };
}
export function loginInit(state: State): State {
    return {
        ...state,
        isActive: true,
        hasSuccess: false, hasError: false, message: null,
    };
}
export function loginDestroy(state: State): State {
    return initialState;
}
export function authSignInWithEmailAndPassword(state: State, payload: LoginForm): State {
    return { ...state, isLoading: true, isLoggedIn: false, hasSuccess: false, hasError: false, message: null };
}
export function authSignInWithEmailAndPasswordSuccess(state: State, payload: any): State {
    return { ...state, hasSuccess: true, hasError: false, isLoading: false, isLoggedIn: true };
}
export function authSignInWithEmailAndPasswordFailure(state: State, payload: any): State {
    return { ...state, hasSuccess: false, hasError: true, message: payload, isLoading: false, isLoggedIn: false };
}
export function sessionStateChange(state: State, payload: Session): State {
    return {
        ...state,
        isLoggedIn: !!payload.uid,
    };
}
