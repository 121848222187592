import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { FaqDataInfo, FaqGroupInfo, FaqInfo } from '@client/utils/shared-constants';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FaqService {
  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) {}

  add(faq: FaqInfo): Observable<any> {
    const faqObj = { ...faq };
    delete faqObj.id;

    return from(this.db.list(`/faq/list`).push(faqObj)).pipe(map(x => faq));
  }

  addGroup(faq: FaqGroupInfo): Observable<any> {
    const faqObj = { ...faq };
    delete faqObj.id;
    return from(this.db.list(`/faq/groups`).push(faqObj)).pipe(map(x => faq));
  }

  delete(faq: FaqInfo): Observable<any> {
    return from(this.db.object(`/faq/list/${faq.id}`).remove()).pipe(map(x => faq));
  }

  deleteGroup(faq: FaqGroupInfo): Observable<any> {
    return from(this.db.object(`/faq/groups/${faq.id}`).remove()).pipe(map(x => faq));
  }

  getAll(): Observable<any> {
    return this.db
      .list('/faq/list')
      .snapshotChanges()
      .pipe(
        map(org =>
          org.map(x => {
            return { ...(x.payload.val() as object), id: x.key };
          })
        )
      );
  }

  getAllGroups(): Observable<any> {
    return this.db
      .list('/faq/groups')
      .snapshotChanges()
      .pipe(
        map(org =>
          org.map(x => {
            return { ...(x.payload.val() as object), id: x.key };
          })
        )
      );
  }

  getAllVisible(): Observable<FaqDataInfo> {
    return this.db
      .object<FaqDataInfo>('/faq')
      .valueChanges()
      .pipe(
        map(f => {
          return {
            groups: Object.keys(f.groups || {})
              .map(k => ({ ...f.groups[k], id: k }))
              .filter((x: any) => !!x.visible),
            list: Object.keys(f.list || {})
              .map(k => ({ ...f.list[k], id: k }))
              .filter((x: any) => !!x.visible)
          };
        })
      );
  }

  update(faq: FaqInfo): Observable<any> {
    const faqObj = { ...faq };
    delete faqObj.id;
    return from(this.db.object(`/faq/list/${faq.id}`).update(faqObj)).pipe(map(x => faq));
  }

  updateGroup(faq: FaqGroupInfo): Observable<any> {
    const faqObj = { ...faq };
    delete faqObj.id;
    return from(this.db.object(`/faq/groups/${faq.id}`).update(faqObj)).pipe(map(x => faq));
  }
}
