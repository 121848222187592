import { SESSION } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session } from "@client/utils/shared-constants";

export class SessionInitAction implements Action {
    readonly type = SESSION.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SessionDestroyAction implements Action {
    readonly type = SESSION.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SessionReloadAction implements Action {
    readonly type = SESSION.RELOAD;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SessionReloadSuccessAction implements Action {
    readonly type = SESSION.RELOAD_SUCCESS;
    constructor(public payload: Session, public correlationId?: number) {
    }
}

export class SessionReloadFailureAction implements Action {
    readonly type = SESSION.RELOAD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class SessionOobAction implements Action {
    readonly type = SESSION.OOB;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class SessionOobResetPssswordAction implements Action {
    readonly type = SESSION.OOB_RESET_PSSSWORD;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class SessionOobRecoderEmailAction implements Action {
    readonly type = SESSION.OOB_RECODER_EMAIL;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class SessionOobVerifyEmailAction implements Action {
    readonly type = SESSION.OOB_VERIFY_EMAIL;
    constructor(public payload: AuthCodeInfo, public correlationId?: number) {
    }
}

export class SessionStartServiceAction implements Action {
    readonly type = SESSION.START_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SessionStopServiceAction implements Action {
    readonly type = SESSION.STOP_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SessionStateChangeAction implements Action {
    readonly type = SESSION.STATE_CHANGE;
    constructor(public payload: Session, public correlationId?: number) {
    }
}
