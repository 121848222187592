import { AUTH, REGISTER_EMAIL } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { RegisterEmailForm, LoginForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isRegistered: boolean;
    isLogged: boolean;
    isWaitingValidation: boolean;
    isActive: boolean;
    isComplete: boolean;
    isLoading: boolean;
    form: RegisterEmailForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isRegistered: false,
    isLogged: false,
    isWaitingValidation: false,
    isActive: false,
    isComplete: false,
    isLoading: false,
    form: {
        confirm_password: "",
        email: "",
        method: "",
        password: "",
        cip: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case REGISTER_EMAIL.INIT: return registerEmailInit(state);
        case REGISTER_EMAIL.DESTROY: return registerEmailDestroy(state);
        case REGISTER_EMAIL.CLICK_SUBMIT: return registerEmailClickSubmit(state);
        case REGISTER_EMAIL.CLICK_LOGIN: return registerEmailClickLogin(state);
        case REGISTER_EMAIL.CLICK_METHOD: return registerEmailClickMethod(state);
        case REGISTER_EMAIL.COMPLETE: return registerEmailComplete(state);
        case REGISTER_EMAIL.FORM_UPDATE: return registerEmailFormUpdate(state, action.payload);
        case REGISTER_EMAIL.FORM_RESET: return registerEmailFormReset(state);
        case AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD: return authCreateUserWithEmailAndPassword(state, action.payload);
        case AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS: return authCreateUserWithEmailAndPasswordSuccess(state, action.payload);
        case AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE: return authCreateUserWithEmailAndPasswordFailure(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD: return authSignInWithEmailAndPassword(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS: return authSignInWithEmailAndPasswordSuccess(state, action.payload);
        case AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE: return authSignInWithEmailAndPasswordFailure(state, action.payload);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK: return authSendEmailVerificationLink(state);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK_SUCCESS: return authSendEmailVerificationLinkSuccess(state);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK_FAILURE: return authSendEmailVerificationLinkFailure(state, action.payload);
        default: return state;
    }
}
export function registerEmailInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function registerEmailDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function registerEmailClickSubmit(state: State): State {
    return state;
}
export function registerEmailClickLogin(state: State): State {
    return state;
}
export function registerEmailClickMethod(state: State): State {
    return state;
}
export function registerEmailComplete(state: State): State {
    return {
        ...state,
        isComplete: true,
    };
}
export function registerEmailFormUpdate(state: State, payload: RegisterEmailForm): State {
    return { ...state, form: payload };
}
export function registerEmailFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form,
    };
}
export function authCreateUserWithEmailAndPassword(state: State, payload: RegisterEmailForm): State {
    return {
        ...state,
        isRegistered: false,
        isLoading: true,
        hasError: false,
        hasSuccess: false,
        message: null,
        isComplete: false,
    };
}
export function authCreateUserWithEmailAndPasswordSuccess(state: State, payload: any): State {
    return {
        ...state,
        isRegistered: true,
        hasSuccess: true,
        isLoading: false,
    };
}
export function authCreateUserWithEmailAndPasswordFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
export function authSignInWithEmailAndPassword(state: State, payload: LoginForm): State {
    return {
        ...state,
        isLogged: false,
        isLoading: true,
        hasError: false,
        hasSuccess: false,
        message: null,
    };
}
export function authSignInWithEmailAndPasswordSuccess(state: State, payload: any): State {
    return {
        ...state,
        isLogged: true,
        hasSuccess: true,
        isLoading: false,
    };
}
export function authSignInWithEmailAndPasswordFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
export function authSendEmailVerificationLink(state: State): State {
    return {
        ...state,
        isWaitingValidation: false,
        isLoading: true,
        hasError: false,
        hasSuccess: false,
        message: null,
    };
}
export function authSendEmailVerificationLinkSuccess(state: State): State {
    return {
        ...state,
        isWaitingValidation: true,
        hasSuccess: true,
        isLoading: false,
        isComplete: true,
    };
}
export function authSendEmailVerificationLinkFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
