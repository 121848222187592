import { APP } from "@client/actions";
import { Action } from "@client/lib/action";

export class AppInitAction implements Action {
    readonly type = APP.INIT;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AppDestroyAction implements Action {
    readonly type = APP.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
