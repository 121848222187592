import { Injectable } from '@angular/core';
import { SESSION, USER } from '@client/actions';
import { SectorsStopServiceAction } from '@client/actions/sectors-actions';
import { SessionDestroyAction, SessionInitAction } from '@client/actions/session-actions';
import { UserHydrateFailureAction, UserHydrateSuccessAction, UserStartServiceAction, UserStopServiceAction } from '@client/actions/user-actions';
import { UserVehiclesStopServiceAction } from '@client/actions/user-vehicles-actions';
import { SectorsService } from '@client/core/services/sectors.service';
import { UserVehiclesService } from '@client/core/services/user-vehicles.service';
import { UserService } from '@client/core/services/user.service';
import { Action } from '@client/lib/action';
import { UserState } from '@client/utils/shared-constants';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class FireServicesEffects {
  @Effect({ dispatch: true })
  authStateLogoutSuccess$ = this.actions$.pipe(
    ofType<SessionDestroyAction>(SESSION.DESTROY),
    mergeMap(action => [new SectorsStopServiceAction(null, action.correlationId), new UserStopServiceAction(null, action.correlationId), new UserVehiclesStopServiceAction(null, action.correlationId)])
  );
  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    mergeMap(action => [new UserStartServiceAction(null, action.correlationId)])
  );

  @Effect({ dispatch: true })
  hydrateUser$: Observable<Action> = this.actions$.pipe(
    ofType<UserStartServiceAction>(USER.START_SERVICE),
    switchMap(action =>
      this.users.hydrate().pipe(
        takeUntil(this.authStateLogoutSuccess$),
        map((user: UserState) => new UserHydrateSuccessAction(user, action.correlationId)),
        catchError(error => of(new UserHydrateFailureAction(error, action.correlationId)))
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private sectors: SectorsService, private users: UserService) {}
}
