import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
import {AccountStatusState} from '@client/utils/shared-constants';
import {EMPTY, from} from 'rxjs';
import {skip, switchMap} from 'rxjs/operators';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class MonerisService {

  constructor(private db: AngularFireDatabase, private auth: AngularFireAuth) {
  }

  hydrate() {

    return this.auth.user.pipe(
      switchMap((u: User) => u ? this.db
          .object<AccountStatusState>(`/user/${u.uid}/moneris`)
          .valueChanges()
          .pipe(skip(1))
        : EMPTY));
  }
}
