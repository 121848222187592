import { State as OobState } from "@client/reducers/oob-reducer";
import { Selector } from "@ngrx/store";
export const getMessage: Selector<OobState, string | null> = (state: OobState) => state.message;
export const getHasError: Selector<OobState, boolean> = (state: OobState) => state.hasError;
export const getHasSuccess: Selector<OobState, boolean> = (state: OobState) => state.hasSuccess;
export const getIsActive: Selector<OobState, boolean> = (state: OobState) => state.isActive;
export const getIsLoading: Selector<OobState, boolean> = (state: OobState) => state.isLoading;
export const getIsCompleted: Selector<OobState, boolean> = (state: OobState) => state.isCompleted;
export const getApiKey: Selector<OobState, string | null> = (state: OobState) => state.apiKey;
export const getMode: Selector<OobState, string | null> = (state: OobState) => state.mode;
export const getOobCode: Selector<OobState, string> = (state: OobState) => state.oobCode;
export const getContinueUrl: Selector<OobState, string> = (state: OobState) => state.continueUrl;
export const getLang: Selector<OobState, string> = (state: OobState) => state.lang;
