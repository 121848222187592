import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isString'
})
export class IsStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return typeof value === 'string';
  }
}
