import { ACCESS_CODE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeGroupInfo, AccessCodeSearchInfo } from "@client/utils/shared-constants";
export interface State {
    data: AccessCodeGroupInfo | null;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isPending: boolean;
    isHydrated: boolean;
    isActive: boolean;
}
export const initialState: State = {
    data: null,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isPending: false,
    isHydrated: false,
    isActive: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ACCESS_CODE.INIT: return accessCodeInit(state);
        case ACCESS_CODE.DESTROY: return accessCodeDestroy(state);
        case ACCESS_CODE.HYDRATE: return accessCodeHydrate(state);
        case ACCESS_CODE.HYDRATE_FAILURE: return accessCodeHydrateFailure(state, action.payload);
        case ACCESS_CODE.HYDRATE_SUCCESS: return accessCodeHydrateSuccess(state, action.payload);
        case ACCESS_CODE.ADD: return accessCodeAdd(state, action.payload);
        case ACCESS_CODE.DELETE: return accessCodeDelete(state);
        case ACCESS_CODE.ADD_SUCCESS: return accessCodeAddSuccess(state, action.payload);
        case ACCESS_CODE.ADD_FAILURE: return accessCodeAddFailure(state, action.payload);
        default: return state;
    }
}
export function accessCodeInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function accessCodeDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function accessCodeHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true
    };
}
export function accessCodeHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload
    };
}
export function accessCodeHydrateSuccess(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        isHydrated: true,
        data: payload || initialState.data,
    };
}
export function accessCodeAdd(state: State, payload: AccessCodeSearchInfo): State {
    return {
        ...state,
        isPending: true
    };
}
export function accessCodeDelete(state: State): State {
    return state;
}
export function accessCodeAddSuccess(state: State, payload: AccessCodeSearchInfo): State {
    return {
        ...state,
        isPending: false
    };
}
export function accessCodeAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false
    };
}
