import { CALENDAR } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isActive: boolean;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isActive: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CALENDAR.DESTROY: return calendarDestroy(state);
        case CALENDAR.INIT: return calendarInit(state);
        default: return state;
    }
}
export function calendarDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function calendarInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
