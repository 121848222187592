import { ACCESS_CODE_DETAILS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule, ServiceInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";

export class AccessCodeDetailsInitAction implements Action {
    readonly type = ACCESS_CODE_DETAILS.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccessCodeDetailsDestroyAction implements Action {
    readonly type = ACCESS_CODE_DETAILS.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccessCodeDetailsClickAddAccessCodeAction implements Action {
    readonly type = ACCESS_CODE_DETAILS.CLICK_ADD_ACCESS_CODE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
