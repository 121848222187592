import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getSessionState } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsNotLoggedInGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getSessionState),
      filter(x => x && x.isLoaded),
      mergeMap(s => {
        const loggedIn = !!s.uid;

        if (!loggedIn) {
          return of(true);
        }
        return this.router.navigate(['/account/contract/details']);
      })
    );
  }
}
