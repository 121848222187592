import { NETWORK_STATUS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isOnline: boolean;
}
export const initialState: State = {
    isOnline: true
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case NETWORK_STATUS.ONLINE: return networkStatusOnline(state);
        case NETWORK_STATUS.OFFLINE: return networkStatusOffline(state);
        default: return state;
    }
}
export function networkStatusOnline(state: State): State {
    return {
        ...state,
        isOnline: true
    };
}
export function networkStatusOffline(state: State): State {
    return {
        ...state,
        isOnline: false
    };
}
