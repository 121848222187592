import { Pipe, PipeTransform } from '@angular/core';

export enum Province {
  QC = 'Québec',
  ON = 'Ontario',
  MB = 'Manitoba',
  SK = 'Saskatchewan',
  AB = 'Alberta',
  BC = 'Colombie-Britanique',
  PE = 'Île-du-Prince-Édouard',
  NB = 'Nouveau-Brunswick',
  NL = 'Terre-Neuve-et-Labrador',
  NS = 'Nouvelle-Écosse',
  YT = 'Yukon',
  NT = 'Territoires du Nord-Ouest',
  NU = 'Nunavut'
}

@Pipe({
  name: 'province'
})
export class ProvincePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Province[value] || 'N/A';
  }
}
