import { State as RegisterEmailState } from "@client/reducers/register-email-reducer";
import { Selector } from "@ngrx/store";
import { RegisterEmailForm } from "@client/utils/shared-constants";
export const getMessage: Selector<RegisterEmailState, string | null> = (state: RegisterEmailState) => state.message;
export const getHasError: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.hasError;
export const getHasSuccess: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.hasSuccess;
export const getIsRegistered: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.isRegistered;
export const getIsLogged: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.isLogged;
export const getIsWaitingValidation: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.isWaitingValidation;
export const getIsActive: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.isActive;
export const getIsComplete: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.isComplete;
export const getIsLoading: Selector<RegisterEmailState, boolean> = (state: RegisterEmailState) => state.isLoading;
export const getForm: Selector<RegisterEmailState, RegisterEmailForm> = (state: RegisterEmailState) => state.form;
export const getFormConfirmPassword: Selector<RegisterEmailState, string> = (state: RegisterEmailState) => getForm(state) && getForm(state).confirm_password;
export const getFormEmail: Selector<RegisterEmailState, string> = (state: RegisterEmailState) => getForm(state) && getForm(state).email;
export const getFormMethod: Selector<RegisterEmailState, string> = (state: RegisterEmailState) => getForm(state) && getForm(state).method;
export const getFormPassword: Selector<RegisterEmailState, string> = (state: RegisterEmailState) => getForm(state) && getForm(state).password;
export const getFormCip: Selector<RegisterEmailState, string> = (state: RegisterEmailState) => getForm(state) && getForm(state).cip;
