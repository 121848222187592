import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ACCESS_CODE,
  ACCESS_CODE_DETAILS,
  ADMIN_FAQ_LIST,
  AUTH,
  CONTRACT,
  FORGOT_PASSWORD,
  LOGIN,
  OOB,
  PROFILE,
  REGISTER_EMAIL,
  REGISTER_PENDING,
  RESET_PASSWORD,
  SESSION,
  SUBSCRIPTION_EDIT,
  USER,
  VEHICLES_ADD,
  VEHICLES_DETAILS
} from '@client/actions';
import { AdminFaqListClickEditAction } from '@client/actions/admin-faq-list-actions';
import { AuthSignInWithEmailAndPasswordSuccessAction } from '@client/actions/auth-actions';
import {ContractAddServiceSuccessAction, ContractSearchSuccessAction} from '@client/actions/contract-actions';
import { SessionInitAction, SessionReloadSuccessAction } from '@client/actions/session-actions';
import { UserVehiclesDetailsClickEditAction } from '@client/actions/user-vehicles-details-actions';
import { AuthService } from '@client/core/services/auth.service';
import {
  getAccessCodeIsActive,
  getContractCreateIsActive,
  getContractDataIsCreationCompleted,
  getContractPaymenRequired,
  getOobIsActive,
  getSessionClaimsAccessLevel,
  getSettingsIsActive
} from '@client/selectors';
import { getAccountStatusIsSubscriptionRequired, getLoginIsActive, getRegisterSubscriptionIsActive } from '@client/selectors/index';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest as combineLatestOb, EMPTY, NEVER, Observable, of } from 'rxjs';
import { delay, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class RedirectEffects {
  @Effect({ dispatch: false })
  accessCodeAddSuccess$ = this.store.pipe(
    select(getAccessCodeIsActive),
    switchMap(isActive =>
      !isActive
        ? NEVER
        : this.actions$.pipe(
            ofType(ACCESS_CODE.ADD_SUCCESS),
            exhaustMap(() => this.router.navigate(['/account/access-code/list']))
          )
    )
  );
  @Effect({ dispatch: false })
  accessCodeDetailsClickAdd$ = this.store.pipe(
    select(getAccessCodeIsActive),
    switchMap(isActive =>
      !isActive
        ? NEVER
        : this.actions$.pipe(
            ofType(ACCESS_CODE_DETAILS.CLICK_ADD_ACCESS_CODE),
            exhaustMap(() => this.router.navigate(['/account/access-code/add']))
          )
    )
  );

  @Effect({ dispatch: false })
  addServiceSuccess$ = this.actions$.pipe(
    ofType<ContractAddServiceSuccessAction>(CONTRACT.ADD_SERVICE_SUCCESS),
    withLatestFrom(this.store.pipe(select(getAccessCodeIsActive))),
    exhaustMap(([action, isActive]) => (!isActive ? EMPTY : this.router.navigate(['/account/contract/details'])))
  );

  @Effect({ dispatch: false })
  contractSearchSuccess$ = this.actions$.pipe(
    ofType<ContractSearchSuccessAction>(CONTRACT.SEARCH_SUCCESS),
    exhaustMap(() => ( this.router.navigate(['/account/contract/details'])))
  );
  @Effect({ dispatch: false })
  contractCreate$ = this.store.pipe(
    select(getContractCreateIsActive),
    switchMap(isActive =>
      !isActive
        ? EMPTY
        : combineLatestOb(this.store.pipe(select(getContractDataIsCreationCompleted)), this.store.pipe(select(getContractPaymenRequired))).pipe(
            tap(([creationCompleted, paymentRequired]) => {
              if (paymentRequired) {
                this.router.navigate(['/account/contract/payment']);
              } else if (creationCompleted) {
                this.router.navigate(['/account/contract/details']);
              }
            })
          )
    )
  );
  @Effect({ dispatch: false })
  contractDeleteService$: Observable<boolean> = this.actions$.pipe(
    ofType(CONTRACT.DELETE_SERVICE_SUCCESS),
    exhaustMap(() => this.router.navigate(['/account/contract/create']))
  );
  @Effect({ dispatch: false })
  contractDeleteSyccess$: Observable<boolean> = this.actions$.pipe(
    ofType(CONTRACT.DELETE_SUCCESS),
    exhaustMap(() => this.router.navigate(['/account/contract/create']))
  );
  @Effect({ dispatch: false })
  faqEdit$: Observable<any> = this.actions$.pipe(
    ofType(ADMIN_FAQ_LIST.CLICK_EDIT),
    map((action: AdminFaqListClickEditAction) => action.payload),
    exhaustMap((faq: any) => this.router.navigate([`/admin/faq/edit/${faq.id}`]))
  );
  @Effect({ dispatch: false })
  forgotPasswd$: Observable<boolean> = this.actions$.pipe(
    ofType(LOGIN.CLICK_FORGOT_PASSWORD),
    exhaustMap(() => this.router.navigate(['/auth/password/forgot']))
  );
  @Effect({ dispatch: false })
  forgotPasswordSuccess$: Observable<boolean> = this.actions$.pipe(
    ofType(AUTH.FORGOT_PASSWORD_SUCCESS),
    exhaustMap(() => this.router.navigate(['/auth/password/wait']))
  );
  @Effect({ dispatch: false })
  forgotPasswordWait$: Observable<boolean> = this.actions$.pipe(
    ofType(FORGOT_PASSWORD.FORM_RESET),
    exhaustMap(() => this.router.navigate(['/auth/password/wait']))
  );
  @Effect({ dispatch: false })
  loginRedirect$: Observable<any> = this.actions$.pipe(
    ofType(
      AUTH.LOGOUT_SUCCESS,
      SESSION.DESTROY,
      FORGOT_PASSWORD.CLICK_LOGIN,
      REGISTER_EMAIL.CLICK_LOGIN,
      REGISTER_PENDING.CLICK_LOGIN,
      OOB.CLICK_LOGIN,
      RESET_PASSWORD.CLICK_LOGIN,
      AUTH.CONFIRM_PASSWORD_RESET_SUCCESS,
      USER.DELETE_USER_SUCCESS
    ),
    exhaustMap(() => this.router.navigate(['/auth/login/email']).then(() => location.reload(true) ))
  );
  @Effect({ dispatch: false })
  loginSuccess$: Observable<boolean> = this.actions$.pipe(
    ofType<AuthSignInWithEmailAndPasswordSuccessAction>(AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS),
    withLatestFrom(this.store.pipe(select(getLoginIsActive)), this.store.pipe(select(getSessionClaimsAccessLevel))),
    exhaustMap(([x, isActive, accessLevel]) => {
      if (x.payload.emailVerified) {
        if (Number(accessLevel) === 5) {
          return this.router.navigate(['/admin/organization']);
        }
        return this.router.navigate(['/account/contract/details']);
      }
      if (isActive) {
        return this.router.navigate(['/auth/register/email/pending']);
      }
      return EMPTY;
    })
  );
  @Effect({ dispatch: false })
  profileUpdateSuccess$ = this.store.pipe(
    select(getSettingsIsActive),
    switchMap(isActive =>
      !isActive
        ? NEVER
        : this.actions$.pipe(
            ofType(PROFILE.UPDATE_SUCCESS),
            exhaustMap(() => this.router.navigate(['/account/settings/profil/details']))
          )
    )
  );
  @Effect({ dispatch: true })
  redirectUserAfterOOb = this.actions$.pipe(
    ofType<SessionReloadSuccessAction>(SESSION.RELOAD_SUCCESS),
    switchMap(action =>
      this.store.pipe(
        select(getOobIsActive),
        mergeMap(isActive => (isActive ? of(new SessionInitAction(null, action.correlationId)) : EMPTY))
      )
    )
  );
  @Effect({ dispatch: false })
  registerComplete$: Observable<boolean> = this.actions$.pipe(
    ofType(REGISTER_EMAIL.COMPLETE),
    exhaustMap(() => this.router.navigate(['/auth/register/email/pending']))
  );
  @Effect({ dispatch: false })
  registerEmail$: Observable<boolean> = this.actions$.pipe(
    ofType(LOGIN.CLICK_REGISTER),
    exhaustMap(() => this.router.navigate(['/auth/register/email']))
  );
  @Effect({ dispatch: false })
  registerSubscriptionUpdateSuccess$ = this.store.pipe(
    select(getRegisterSubscriptionIsActive),
    switchMap(isActive =>
      !isActive
        ? NEVER
        : this.store.pipe(
            select(getAccountStatusIsSubscriptionRequired),
            filter(x => !x),
            exhaustMap(() => this.router.navigate(['/account/contract/details']))
          )
    )
  );
  @Effect({ dispatch: false })
  resetPassword$: Observable<boolean> = this.actions$.pipe(
    ofType(SESSION.OOB_RESET_PSSSWORD),
    exhaustMap(() => this.router.navigate(['/auth/password/reset']))
  );
  @Effect({ dispatch: false })
  updateSubscriptionSuccess$ = this.store.pipe(
    select(getSettingsIsActive),
    switchMap(isActive =>
      !isActive
        ? NEVER
        : this.actions$.pipe(
            ofType(SUBSCRIPTION_EDIT.UPDATE_SUCCESS),
            delay(1000),
            exhaustMap(() => this.router.navigate(['/account/settings/subscription/details']))
          )
    )
  );
  @Effect({ dispatch: false })
  validateEmailCodeSuccess$: Observable<boolean> = this.actions$.pipe(
    ofType(AUTH.VALIDATE_EMAIL_CODE_SUCCESS),
    exhaustMap(() => this.router.navigate(['/auth/login/email']))
  );
  @Effect({ dispatch: false })
  vehicleAdd$: Observable<any> = this.actions$.pipe(
    ofType(VEHICLES_DETAILS.CLICK_ADD),
    withLatestFrom(this.store.pipe(select(getSettingsIsActive))),
    exhaustMap(([v, isActive]) => (!isActive ? EMPTY : this.router.navigate([`/account/settings/vehicles/add`])))
  );

  @Effect({ dispatch: false })
  vehicleAddSuccess$ = this.actions$.pipe(
    ofType(VEHICLES_ADD.ADD_SUCCESS, VEHICLES_ADD.UPDATE_SUCCESS),
    withLatestFrom(this.store.pipe(select(getSettingsIsActive))),
    switchMap(([action, isActive]) => (!isActive ? NEVER : this.router.navigate(['/account/settings/vehicles/list'])))
  );

  @Effect({ dispatch: false })
  vehicleEdit$: Observable<any> = this.actions$.pipe(
    ofType(VEHICLES_DETAILS.CLICK_EDIT),
    map((action: UserVehiclesDetailsClickEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getSettingsIsActive))),
    exhaustMap(([v, isActive]) => (!isActive ? EMPTY : this.router.navigate([`/account/settings/vehicles/edit/${v.id}`])))
  );

  constructor(private router: Router, private actions$: Actions, private store: Store<any>, private authService: AuthService) {}
}
