import { State as UserState } from "@client/reducers/user-reducer";
import { Selector } from "@ngrx/store";
import { UserAccountStatus, SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo, Vehicule, SubscriptionService, PaymentInfo } from "@client/utils/shared-constants";
export const getAccountStatus: Selector<UserState, UserAccountStatus> = (state: UserState) => state.accountStatus;
export const getAccountStatusIsCompleted: Selector<UserState, boolean> = (state: UserState) => getAccountStatus(state) && getAccountStatus(state).isCompleted;
export const getAccountStatusIsAccessCodeRequired: Selector<UserState, boolean> = (state: UserState) => getAccountStatus(state) && getAccountStatus(state).isAccessCodeRequired;
export const getAccountStatusIsSubscriptionRequired: Selector<UserState, boolean> = (state: UserState) => getAccountStatus(state) && getAccountStatus(state).isSubscriptionRequired;
export const getAccountStatusIsContractRequired: Selector<UserState, boolean> = (state: UserState) => getAccountStatus(state) && getAccountStatus(state).isContractRequired;
export const getAccountStatusIsCipRequired: Selector<UserState, boolean> = (state: UserState) => getAccountStatus(state) && getAccountStatus(state).isCipRequired;
export const getSubscription: Selector<UserState, SubscriptionInfo | null> = (state: UserState) => state.subscription;
export const getSubscriptionCustom: Selector<UserState, any> = (state: UserState) => getSubscription(state) && getSubscription(state).custom;
export const getSubscriptionSpaq: Selector<UserState, SubscriptionSpaqInfo> = (state: UserState) => getSubscription(state) && getSubscription(state).spaq;
export const getSubscriptionSpaqAccesDocuments: Selector<UserState, AccesDocumentInfo[]> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).accesDocuments;
export const getSubscriptionSpaqAccessOnHoldReason: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).accessOnHoldReason;
export const getSubscriptionSpaqAddressLine1: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).addressLine1;
export const getSubscriptionSpaqIdentificationCode: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).identificationCode;
export const getSubscriptionSpaqAddressLine2: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).addressLine2;
export const getSubscriptionSpaqAppartmentNo: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).appartmentNo;
export const getSubscriptionSpaqBalance: Selector<UserState, number> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).balance;
export const getSubscriptionSpaqCellPhone: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).cellPhone;
export const getSubscriptionSpaqCity: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).city;
export const getSubscriptionSpaqContractNo: Selector<UserState, number> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).contractNo;
export const getSubscriptionSpaqDynamicFieldJsonData: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).dynamicFieldJsonData;
export const getSubscriptionSpaqEmail: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).email;
export const getSubscriptionSpaqFirstName: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).firstName;
export const getSubscriptionSpaqHomePhone: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).homePhone;
export const getSubscriptionSpaqHomePhoneExtension: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).homePhoneExtension;
export const getSubscriptionSpaqIsAccessOnHold: Selector<UserState, boolean> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).isAccessOnHold;
export const getSubscriptionSpaqLanguage: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).language;
export const getSubscriptionSpaqLastName: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).lastName;
export const getSubscriptionSpaqPostalCode: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).postalCode;
export const getSubscriptionSpaqProvince: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).province;
export const getSubscriptionSpaqServiceCenter: Selector<UserState, number> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).serviceCenter;
export const getSubscriptionSpaqSex: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).sex;
export const getSubscriptionSpaqWorkPhone: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).workPhone;
export const getSubscriptionSpaqWorkPhoneExtension: Selector<UserState, number> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).workPhoneExtension;
export const getSubscriptionSpaqPaymentMethod: Selector<UserState, PaymentMethodInfo> = (state: UserState) => getSubscriptionSpaq(state) && getSubscriptionSpaq(state).paymentMethod;
export const getSubscriptionSpaqPaymentMethodCreditCardDataKey: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).CreditCardDataKey;
export const getSubscriptionSpaqPaymentMethodEffectiveDate: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).EffectiveDate;
export const getSubscriptionSpaqPaymentMethodEndDate: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).EndDate;
export const getSubscriptionSpaqPaymentMethodFinancialInstitutionId: Selector<UserState, number> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).FinancialInstitutionId;
export const getSubscriptionSpaqPaymentMethodFolioNo: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).FolioNo;
export const getSubscriptionSpaqPaymentMethodMethod: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).Method;
export const getSubscriptionSpaqPaymentMethodTransitNo: Selector<UserState, string> = (state: UserState) => getSubscriptionSpaqPaymentMethod(state) && getSubscriptionSpaqPaymentMethod(state).TransitNo;
export const getVehicles: Selector<UserState, Vehicule[]> = (state: UserState) => state.vehicles;
export const getSubscriptionServices: Selector<UserState, SubscriptionService[]> = (state: UserState) => state.subscriptionServices;
export const getServices: Selector<UserState, SubscriptionService[]> = (state: UserState) => state.services;
export const getPayments: Selector<UserState, PaymentInfo[]> = (state: UserState) => state.payments;
export const getMoneris: Selector<UserState, {
    data_key: string | null;
}> = (state: UserState) => state.moneris;
export const getMonerisDataKey: Selector<UserState, string | null> = (state: UserState) => getMoneris(state) && getMoneris(state).data_key;
