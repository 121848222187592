import { Injectable } from "@angular/core";
import { IFaqDispatcher } from "@client/interfaces/faq-interface";
import { Store } from "@ngrx/store";
import { FaqHydrateAction, FaqHydrateSuccessAction, FaqHydrateFailureAction, FaqInitAction, FaqDestroyAction, FaqOnBlurItemAction, FaqOnClickItemAction, FaqOnFocusItemAction } from "@client/actions/faq-actions";
import { FaqDataInfo, FaqInfo } from "@client/utils/shared-constants";
@Injectable()
export class FaqDispatcher implements IFaqDispatcher {
    constructor(private store: Store<any>) {
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new FaqHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: FaqDataInfo, correlationId = Date.now()) {
        this.store.dispatch(new FaqHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new FaqHydrateFailureAction(payload, correlationId));
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new FaqInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new FaqDestroyAction(payload, correlationId));
    }
    onBlurItem(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new FaqOnBlurItemAction(payload, correlationId));
    }
    onClickItem(payload: FaqInfo, correlationId = Date.now()) {
        this.store.dispatch(new FaqOnClickItemAction(payload, correlationId));
    }
    onFocusItem(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new FaqOnFocusItemAction(payload, correlationId));
    }
}
