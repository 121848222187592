import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreFormComponent } from '@client/shared/components/forms/core-form.component';
import { MessageStateModule } from '@client/shared/components/message-state';
import { PipesModule } from '@client/shared/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule, NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { InputBankAccountNumberComponent } from './input-bank-account-number/input-bank-account-number.component';
import { InputBankNumberComponent } from './input-bank-number/input-bank-number.component';
import { InputBankTransitNumberComponent } from './input-bank-transit-number/input-bank-transit-number.component';
import { InputCaptchaComponent } from './input-captcha/input-captcha.component';
import { InputCardNumberComponent } from './input-card-number/input-card-number.component';
import { InputComboBoxComponent } from './input-combo-box/input-combo-box.component';

import { InputContractNoComponent } from './input-contract-no/input-contract-no.component';
import { InputContractNumberComponent } from './input-contract-number/input-contract-number.component';
import { InputCvvComponent } from './input-cvv/input-cvv.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { InputRegistrationMethodComponent } from './input-registration-method/input-registration-method.component';
import { InputSmsComponent } from './input-sms/input-sms.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { InputZipcodeComponent } from './input-zipcode/input-zipcode.component';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { SelectMonthComponent } from './select-month/select-month.component';
import { SelectProvinceComponent } from './select-province/select-province.component';
import { SelectSexeComponent } from './select-sexe/select-sexe.component';
import { SelectYearComponent } from './select-year/select-year.component';
import { LabelModule } from '@progress/kendo-angular-label';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonsModule,
    NumericTextBoxModule,
    NumericTextBoxModule,
    TextBoxModule,
    InputsModule,
    DropDownsModule,
    MessageStateModule,
    ComboBoxModule,
    DropDownsModule,
    MessageStateModule,
    PipesModule,
    TranslateModule,
    LabelModule
  ],
  declarations: [
    InputEmailComponent,
    InputPasswordComponent,
    InputSmsComponent,
    InputErrorComponent,
    InputCaptchaComponent,
    InputTextComponent,
    InputZipcodeComponent,
    InputContractNumberComponent,
    SelectSexeComponent,
    SelectProvinceComponent,
    SelectLanguageComponent,
    SelectMonthComponent,
    SelectYearComponent,
    InputCardNumberComponent,
    InputCvvComponent,
    InputBankTransitNumberComponent,
    InputBankNumberComponent,
    InputBankAccountNumberComponent,
    InputContractNoComponent,
    InputPhoneComponent,
    InputRegistrationMethodComponent,
    InputComboBoxComponent,
    InputTextareaComponent,
    CoreFormComponent
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NumericTextBoxModule,
    NumericTextBoxModule,
    TextBoxModule,
    ButtonsModule,
    InputEmailComponent,
    InputPasswordComponent,
    InputSmsComponent,
    InputErrorComponent,
    InputCaptchaComponent,
    InputTextComponent,
    InputZipcodeComponent,
    InputContractNumberComponent,
    SelectSexeComponent,
    SelectProvinceComponent,
    SelectLanguageComponent,
    SelectMonthComponent,
    SelectYearComponent,
    InputCardNumberComponent,
    InputCvvComponent,
    InputBankTransitNumberComponent,
    InputBankNumberComponent,
    InputBankAccountNumberComponent,
    InputContractNoComponent,
    InputPhoneComponent,
    InputRegistrationMethodComponent,
    InputComboBoxComponent,
    InputTextareaComponent,
    CoreFormComponent,
    ComboBoxModule,
    DropDownsModule,
    InputsModule,
    ReactiveFormsModule,
    FormsModule,
    LabelModule
  ]
})
export class FormModule {}
