import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { metaReducers, reducers } from '@client/reducers';
import {StoreRouterConnectingModule, DefaultRouterStateSerializer, RouterState} from '@ngrx/router-store';
import { StoreModule as NgrxStoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from './configs/environment';

@NgModule({
  imports: [
    CommonModule,
    NgrxStoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer, routerState: RouterState.Minimal }),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      name: 'SPAQ',
      features: {
        pause: true,
        lock: true,
        persist: true,
        export: true,
        import: true,
        jump: true,
        skip: true,
        reorder: true,
        dispatch: true,
        test: true
      }
    })
  ],
  declarations: []
})
export class StoreModule {}
