import { AUTH, REGISTER_PENDING } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AuthCodeInfo } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    isResent: boolean;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: false,
    isCompleted: false,
    isResent: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case REGISTER_PENDING.INIT: return registerPendingInit(state);
        case REGISTER_PENDING.DESTROY: return registerPendingDestroy(state);
        case REGISTER_PENDING.CLICK_LOGIN: return registerPendingClickLogin(state);
        case REGISTER_PENDING.CLICK_RESENT: return registerPendingClickResent(state);
        case REGISTER_PENDING.CLICK_LOGOUT: return registerPendingClickLogout(state);
        case AUTH.SIGN_OUT: return authSignOut(state);
        case AUTH.SIGN_OUT_SUCCESS: return authSignOutSuccess(state);
        case AUTH.SIGN_OUT_FAILURE: return authSignOutFailure(state, action.payload);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK: return authSendEmailVerificationLink(state);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK_SUCCESS: return authSendEmailVerificationLinkSuccess(state);
        case AUTH.SEND_EMAIL_VERIFICATION_LINK_FAILURE: return authSendEmailVerificationLinkFailure(state, action.payload);
        case AUTH.VALIDATE_EMAIL_CODE_SUCCESS: return authValidateEmailCodeSuccess(state, action.payload);
        default: return state;
    }
}
export function registerPendingInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function registerPendingDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function registerPendingClickLogin(state: State): State {
    return state;
}
export function registerPendingClickResent(state: State): State {
    return state;
}
export function registerPendingClickLogout(state: State): State {
    return state;
}
export function authSignOut(state: State): State {
    return {
        ...state,
        isLoading: true,
        message: null,
        hasError: false,
        hasSuccess: false
    };
}
export function authSignOutSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true
    };
}
export function authSignOutFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function authSendEmailVerificationLink(state: State): State {
    if (!state.isActive) {
        return { ...state };
    }
    return {
        ...state,
        isResent: false,
        isLoading: true,
        message: null,
        hasError: false,
        hasSuccess: false
    };
}
export function authSendEmailVerificationLinkSuccess(state: State): State {
    if (!state.isActive) {
        return { ...state };
    }
    return {
        ...state,
        isResent: true,
        isLoading: false,
        hasSuccess: true
    };
}
export function authSendEmailVerificationLinkFailure(state: State, payload: any): State {
    if (!state.isActive) {
        return { ...state };
    }
    return {
        ...state,
        isLoading: false,
        message: payload,
        hasError: true
    };
}
export function authValidateEmailCodeSuccess(state: State, payload: AuthCodeInfo): State {
    return {
        ...state,
        isCompleted: true
    };
}
