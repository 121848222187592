import { VEHICLES_ADD } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule } from "@client/utils/shared-constants";

export class VehiclesAddInitAction implements Action {
    readonly type = VEHICLES_ADD.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesAddDestroyAction implements Action {
    readonly type = VEHICLES_ADD.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesAddFormUpdateAction implements Action {
    readonly type = VEHICLES_ADD.FORM_UPDATE;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesAddSelectYearAction implements Action {
    readonly type = VEHICLES_ADD.SELECT_YEAR;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesAddSelectBrandAction implements Action {
    readonly type = VEHICLES_ADD.SELECT_BRAND;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesAddSelectModelAction implements Action {
    readonly type = VEHICLES_ADD.SELECT_MODEL;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesAddClickSubmitAction implements Action {
    readonly type = VEHICLES_ADD.CLICK_SUBMIT;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesAddAddAction implements Action {
    readonly type = VEHICLES_ADD.ADD;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesAddAddSuccessAction implements Action {
    readonly type = VEHICLES_ADD.ADD_SUCCESS;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesAddAddFailureAction implements Action {
    readonly type = VEHICLES_ADD.ADD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesAddUpdateAction implements Action {
    readonly type = VEHICLES_ADD.UPDATE;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesAddUpdateSuccessAction implements Action {
    readonly type = VEHICLES_ADD.UPDATE_SUCCESS;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesAddUpdateFailureAction implements Action {
    readonly type = VEHICLES_ADD.UPDATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesAddValidationRequiredAction implements Action {
    readonly type = VEHICLES_ADD.VALIDATION_REQUIRED;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
