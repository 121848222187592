import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const subject = args ? String(value).toLowerCase() : String(value);
    return subject !== '' ? subject.substr(0, 1).toUpperCase() + subject.substr(1) : '';
  }
}
