import { SESSION, SUBSCRIPTION, REGISTER_SUBSCRIPTION, SUBSCRIPTION_EDIT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { SubscriptionInfo, Session } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isComplete: boolean;
    isLoading: boolean;
    forceFormUpdate: boolean;
    form: SubscriptionInfo;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isComplete: false,
    isLoading: false,
    forceFormUpdate: false,
    form: {
        custom: "custom",
        spaq: {
            accesDocuments: [],
            accessOnHoldReason: "",
            addressLine1: "",
            identificationCode: "",
            addressLine2: "",
            appartmentNo: "",
            balance: 0,
            cellPhone: "",
            city: "",
            contractNo: 0,
            dynamicFieldJsonData: "",
            email: "",
            firstName: "",
            homePhone: "",
            homePhoneExtension: "",
            isAccessOnHold: null,
            language: "French",
            lastName: "",
            postalCode: "",
            province: "QC",
            serviceCenter: 0,
            sex: "Male",
            workPhone: "",
            workPhoneExtension: 0,
            paymentMethod: {
                CreditCardDataKey: "",
                EffectiveDate: "",
                EndDate: "",
                FinancialInstitutionId: 0,
                FolioNo: "",
                Method: "Cash",
                TransitNo: ""
            }
        }
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SUBSCRIPTION_EDIT.INIT: return subscriptionEditInit(state);
        case SUBSCRIPTION_EDIT.DESTROY: return subscriptionEditDestroy(state);
        case SUBSCRIPTION_EDIT.UPDATE: return subscriptionEditUpdate(state, action.payload);
        case SUBSCRIPTION_EDIT.UPDATE_SUCCESS: return subscriptionEditUpdateSuccess(state, action.payload);
        case SUBSCRIPTION_EDIT.UPDATE_FAILURE: return subscriptionEditUpdateFailure(state, action.payload);
        case SUBSCRIPTION_EDIT.CLICK_SUBMIT: return subscriptionEditClickSubmit(state);
        case SUBSCRIPTION_EDIT.FORM_UPDATE: return subscriptionEditFormUpdate(state, action.payload);
        case SUBSCRIPTION_EDIT.FORM_RESET: return subscriptionEditFormReset(state);
        case SUBSCRIPTION_EDIT.UPLOAD: return subscriptionEditUpload(state, action.payload);
        case SUBSCRIPTION_EDIT.UPLOAD_SUCCESS: return subscriptionEditUploadSuccess(state, action.payload);
        case SUBSCRIPTION_EDIT.UPLOAD_FAILURE: return subscriptionEditUploadFailure(state, action.payload);
        case SESSION.STATE_CHANGE: return sessionStateChange(state, action.payload);
        case SUBSCRIPTION.HYDRATE_SUCCESS: return subscriptionHydrateSuccess(state, action.payload);
        case REGISTER_SUBSCRIPTION.CREATE: return registerSubscriptionCreate(state, action.payload);
        case REGISTER_SUBSCRIPTION.CREATE_SUCCESS: return registerSubscriptionCreateSuccess(state, action.payload);
        case REGISTER_SUBSCRIPTION.CREATE_FAILURE: return registerSubscriptionCreateFailure(state, action.payload);
        default: return state;
    }
}
export function subscriptionEditInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function subscriptionEditDestroy(state: State): State {
    return {
        ...state,
        ...initialState,
        form: state.form,
        isActive: false
    };
}
export function subscriptionEditUpdate(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isComplete: false,
        isLoading: true,
    };
}
export function subscriptionEditUpdateSuccess(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        hasSuccess: true,
        isComplete: true,
        isLoading: false,
    };
}
export function subscriptionEditUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function subscriptionEditClickSubmit(state: State): State {
    return state;
}
export function subscriptionEditFormUpdate(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        forceFormUpdate: false,
        form: {
            ...state.form,
            ...payload,
        },
    };
}
export function subscriptionEditFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form
    };
}
export function subscriptionEditUpload(state: State, payload: any): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isComplete: false,
        isLoading: true,
    };
}
export function subscriptionEditUploadSuccess(state: State, payload: any): State {
    const custom = state.form && state.form.custom || {};
    return {
        ...state,
        hasSuccess: true,
        isComplete: true,
        isLoading: false,
        forceFormUpdate: true,
        form: {
            ...state.form,
            custom: {
                ...custom,
                [payload.key]: payload.file
            }
        }
    };
}
export function subscriptionEditUploadFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function sessionStateChange(state: State, payload: Session): State {
    return {
        ...state,
        form: {
            ...state.form,
            spaq: {
                ...state.form.spaq,
                email: payload.email,
            },
        },
    };
}
export function subscriptionHydrateSuccess(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        form: payload || initialState.form
    };
}
export function registerSubscriptionCreate(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isComplete: false,
        isLoading: true,
    };
}
export function registerSubscriptionCreateSuccess(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        hasSuccess: true,
        isComplete: true,
        isLoading: false,
    };
}
export function registerSubscriptionCreateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
