import { VEHICLES_EDIT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule } from "@client/utils/shared-constants";

export class VehiclesEditInitAction implements Action {
    readonly type = VEHICLES_EDIT.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesEditDestroyAction implements Action {
    readonly type = VEHICLES_EDIT.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesEditFormUpdateAction implements Action {
    readonly type = VEHICLES_EDIT.FORM_UPDATE;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesEditSelectYearAction implements Action {
    readonly type = VEHICLES_EDIT.SELECT_YEAR;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesEditSelectBrandAction implements Action {
    readonly type = VEHICLES_EDIT.SELECT_BRAND;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesEditSelectModelAction implements Action {
    readonly type = VEHICLES_EDIT.SELECT_MODEL;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesEditClickSubmitAction implements Action {
    readonly type = VEHICLES_EDIT.CLICK_SUBMIT;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesEditUpdateAction implements Action {
    readonly type = VEHICLES_EDIT.UPDATE;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesEditUpdateSuccessAction implements Action {
    readonly type = VEHICLES_EDIT.UPDATE_SUCCESS;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class VehiclesEditUpdateFailureAction implements Action {
    readonly type = VEHICLES_EDIT.UPDATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
