import { SERVICES } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule, ServiceInfo } from "@client/utils/shared-constants";

export class ServicesHydrateAction implements Action {
    readonly type = SERVICES.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ServicesHydrateSuccessAction implements Action {
    readonly type = SERVICES.HYDRATE_SUCCESS;
    constructor(public payload: ServiceInfo[], public correlationId?: number) {
    }
}

export class ServicesHydrateFailureAction implements Action {
    readonly type = SERVICES.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
