import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccessCodeAddDispatcher } from '@client/dispatchers/access-code-add-dispatcher';
import { AccessCodeDetailsDispatcher } from '@client/dispatchers/access-code-details-dispatcher';
import { AccessCodeDispatcher } from '@client/dispatchers/access-code-dispatcher';
import { ACCESS_CODE_ADD_DISPATCHER, ACCESS_CODE_ADD_STORE, ACCESS_CODE_DETAILS_DISPATCHER, ACCESS_CODE_DETAILS_STORE, ACCESS_CODE_DISPATCHER, ACCESS_CODE_STORE } from '@client/injection';
import { FaqComponent } from '@client/modules/faq/faq.component';
import { FaqViewPageComponent } from '@client/modules/faq/pages/faq-view-page.component';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { AccessCodeAddStore } from '@client/stores/access-code-add-store';
import { AccessCodeDetailsStore } from '@client/stores/access-code-details-store';
import { AccessCodeStore } from '@client/stores/access-code-store';
import { FaqFooterComponent, FaqHeaderComponent, FaqItemComponent, FaqListComponent, FaqPageComponent } from './components';
import { FooterTemplateDirective, HeaderTemplateDirective, ItemsDirective, ItemTemplateDirective, ListTemplateDirective, NoDataDirective, TemplateContextDirective } from './templates';

@NgModule({
  imports: [SharedCommonModule, SharedComponentsModule],
  declarations: [
    FaqPageComponent,
    FaqListComponent,
    FaqItemComponent,
    FaqHeaderComponent,
    FaqFooterComponent,
    HeaderTemplateDirective,
    FooterTemplateDirective,
    ItemTemplateDirective,
    TemplateContextDirective,
    NoDataDirective,
    FaqViewPageComponent,
    ItemsDirective,
    ListTemplateDirective,
    FaqComponent
  ],
  exports: [FaqPageComponent, FaqListComponent, FaqItemComponent, FaqHeaderComponent, ItemTemplateDirective, FaqFooterComponent, HeaderTemplateDirective, FooterTemplateDirective, ListTemplateDirective, FaqViewPageComponent],
  entryComponents: []
})
export class FaqModule {
  static forRoot(): ModuleWithProviders<FaqModule> {
    return {
      ngModule: FaqModule,
      providers: [
        { provide: ACCESS_CODE_DISPATCHER, useClass: AccessCodeDispatcher },
        { provide: ACCESS_CODE_ADD_DISPATCHER, useClass: AccessCodeAddDispatcher },
        { provide: ACCESS_CODE_DETAILS_DISPATCHER, useClass: AccessCodeDetailsDispatcher },
        { provide: ACCESS_CODE_STORE, useClass: AccessCodeStore },
        { provide: ACCESS_CODE_ADD_STORE, useClass: AccessCodeAddStore },
        { provide: ACCESS_CODE_DETAILS_STORE, useClass: AccessCodeDetailsStore }
      ]
    };
  }
}
