import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { initialState } from '@client/reducers/session-reducer';
import {Session, SessionClaims, SubscriptionInfo} from '@client/utils/shared-constants';
import {EMPTY, from, Observable, of, Subscription} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class SessionService {
  _auth: Observable<Session | null>;

  get auth() {
    return this._auth;
  }

  subscription: Subscription;

  constructor(private afAuth: AngularFireAuth) {

    this._auth = this.afAuth.user.pipe(
      switchMap((u: User) => of(u).pipe(
        switchMap(state =>
          !state
            ? of(initialState)
            : afAuth.user.pipe(switchMap(u => from(u.getIdTokenResult()).pipe(
            map((idTokenResult: any) => {
              if (state) {
                const session = state.toJSON() as Session;
                return { ...session, claims: idTokenResult.claims as SessionClaims };
              }
              return initialState;
            })
            )
        )
      ))))
    );
  }

  reload(): Observable<Session> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => from(u.reload()).pipe(
        switchMap(state =>
          from(u.getIdTokenResult()).pipe(
            map((idTokenResult: any) => {
              const session = u.toJSON() as Session;
              return { ...session, claims: idTokenResult.claims as SessionClaims };
            })
          )
        )
      ))
    );
  }

  start() {
    return this._auth;
  }
}
