import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProfileDetailsDispatcher } from '@client/dispatchers/profile-details-dispatcher';
import { ProfileDispatcher } from '@client/dispatchers/profile-dispatcher';
import { ProfileEditDispatcher } from '@client/dispatchers/profile-edit-dispatcher';
import { PROFILE_DETAILS_DISPATCHER, PROFILE_DETAILS_STORE, PROFILE_DISPATCHER, PROFILE_EDIT_DISPATCHER, PROFILE_EDIT_STORE, PROFILE_STORE } from '@client/injection';
import { FormModule } from '@client/shared/components/forms';
import { PipesModule } from '@client/shared/pipes.module';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import { ProfileDetailsStore } from '@client/stores/profile-details-store';
import { ProfileEditStore } from '@client/stores/profile-edit-store';
import { ProfileStore } from '@client/stores/profile-store';
import { ProfilFormComponent, ProfilInfosComponent, ProfilInfosItemComponent } from './components';
import { ProfilDetailsComponent, ProfileComponent, ProfilEditComponent } from './pages';

@NgModule({
  imports: [SharedComponentsModule, PipesModule, FormModule, SharedCommonModule, SharedFormModule],
  declarations: [ProfilDetailsComponent, ProfilInfosComponent, ProfilFormComponent, ProfilEditComponent, ProfilInfosItemComponent, ProfileComponent],
  exports: [ProfilDetailsComponent, ProfilEditComponent]
})
export class ProfileModule {
  static forRoot(): ModuleWithProviders<ProfileModule> {
    return {
      ngModule: ProfileModule,
      providers: [
        { provide: PROFILE_DISPATCHER, useClass: ProfileDispatcher },
        { provide: PROFILE_EDIT_DISPATCHER, useClass: ProfileEditDispatcher },
        { provide: PROFILE_DETAILS_DISPATCHER, useClass: ProfileDetailsDispatcher },
        { provide: PROFILE_STORE, useClass: ProfileStore },
        { provide: PROFILE_EDIT_STORE, useClass: ProfileEditStore },
        { provide: PROFILE_DETAILS_STORE, useClass: ProfileDetailsStore }
      ]
    };
  }
}
