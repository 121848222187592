import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ParkInfo } from '@client/utils/shared-constants';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ParksService {
  constructor(private db: AngularFireDatabase) {}

  getAll(): Observable<any> {
    return this.db.list('/park').valueChanges();
  }

  hydrate(): Observable<ParkInfo[]> {
    return this.db.list<ParkInfo>('/park').valueChanges();
  }
}
