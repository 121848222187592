import { NgModule } from '@angular/core';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { PpaEditPageComponent } from '@client/modules/payment/pages/ppa-edit.component';
import { FormModule } from '@client/shared/components/forms';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { UploadModule } from '@progress/kendo-angular-upload';
import {
  MonerisPayComponent,
  MonerisVaultComponent,
  PaymentDasDetailsComponent,
  PaymentDetailsComponent,
  PaymentInternetDetailsComponent,
  PaymentPpaDetailsComponent,
  PaymentPpaEditComponent,
  PaymentSelectMethodComponent,
  PpaDetailsComponent,
  PpaFormComponent
} from './components';

@NgModule({
  imports: [SharedCommonModule, DropDownsModule, AngularFireStorageModule, UploadModule, DateInputsModule, IntlModule, TranslateModule, SharedComponentsModule, FormModule],
  declarations: [
    PaymentPpaDetailsComponent,
    PaymentPpaEditComponent,
    PaymentInternetDetailsComponent,
    PaymentDasDetailsComponent,
    PpaFormComponent,
    PpaDetailsComponent,
    MonerisVaultComponent,
    PaymentDetailsComponent,
    MonerisPayComponent,
    PaymentSelectMethodComponent,
    PpaEditPageComponent
  ],
  exports: [
    PaymentPpaDetailsComponent,
    PaymentPpaEditComponent,
    PaymentInternetDetailsComponent,
    PaymentDasDetailsComponent,
    PaymentDetailsComponent,
    MonerisPayComponent,
    MonerisVaultComponent,
    PaymentSelectMethodComponent,
    PpaEditPageComponent
  ]
})
export class PaymentModule {}
