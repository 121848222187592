import { ModuleWithProviders, NgModule } from '@angular/core';
import { OrganizationServicesComponent } from '@client/modules/organization/pages';
import { ServicesModule } from '@client/modules/services';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { OrganizationDetailsComponent } from './components';

@NgModule({
  imports: [SharedCommonModule, ServicesModule, SharedComponentsModule, SharedCommonModule],
  declarations: [OrganizationDetailsComponent, OrganizationServicesComponent],
  exports: [OrganizationDetailsComponent, OrganizationServicesComponent]
})
export class OrganizationsModule {
  static forRoot(): ModuleWithProviders<OrganizationsModule> {
    return {
      ngModule: OrganizationsModule,
      providers: []
    };
  }
}
