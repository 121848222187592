import { CONTRACT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm } from "@client/utils/shared-constants";

export class ContractHydrateAction implements Action {
    readonly type = CONTRACT.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractHydrateSuccessAction implements Action {
    readonly type = CONTRACT.HYDRATE_SUCCESS;
    constructor(public payload: ContractInfo, public correlationId?: number) {
    }
}

export class ContractHydrateFailureAction implements Action {
    readonly type = CONTRACT.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractAddParkAction implements Action {
    readonly type = CONTRACT.ADD_PARK;
    constructor(public payload: ParkInfo, public correlationId?: number) {
    }
}

export class ContractAddParkSuccessAction implements Action {
    readonly type = CONTRACT.ADD_PARK_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractAddParkFailureAction implements Action {
    readonly type = CONTRACT.ADD_PARK_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractAddServiceAction implements Action {
    readonly type = CONTRACT.ADD_SERVICE;
    constructor(public payload: ServiceInfoWithGroup, public correlationId?: number) {
    }
}

export class ContractAddServiceSuccessAction implements Action {
    readonly type = CONTRACT.ADD_SERVICE_SUCCESS;
    constructor(public payload: ServiceInfoWithGroup, public correlationId?: number) {
    }
}

export class ContractAddServiceFailureAction implements Action {
    readonly type = CONTRACT.ADD_SERVICE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractAddSubscriptionServiceAction implements Action {
    readonly type = CONTRACT.ADD_SUBSCRIPTION_SERVICE;
    constructor(public payload: SubscriptionService, public correlationId?: number) {
    }
}

export class ContractAddSubscriptionServiceSuccessAction implements Action {
    readonly type = CONTRACT.ADD_SUBSCRIPTION_SERVICE_SUCCESS;
    constructor(public payload: SubscriptionService, public correlationId?: number) {
    }
}

export class ContractAddSubscriptionServiceFailureAction implements Action {
    readonly type = CONTRACT.ADD_SUBSCRIPTION_SERVICE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractAddPaymentAction implements Action {
    readonly type = CONTRACT.ADD_PAYMENT;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class ContractAddPaymentSuccessAction implements Action {
    readonly type = CONTRACT.ADD_PAYMENT_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractAddPaymentFailureAction implements Action {
    readonly type = CONTRACT.ADD_PAYMENT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractAcceptConditionsServiceAction implements Action {
    readonly type = CONTRACT.ACCEPT_CONDITIONS_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractAcceptConditionsServiceSuccessAction implements Action {
    readonly type = CONTRACT.ACCEPT_CONDITIONS_SERVICE_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractAcceptConditionsServiceFailureAction implements Action {
    readonly type = CONTRACT.ACCEPT_CONDITIONS_SERVICE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractAcceptConditionsPaymentAction implements Action {
    readonly type = CONTRACT.ACCEPT_CONDITIONS_PAYMENT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractAcceptConditionsPaymentSuccessAction implements Action {
    readonly type = CONTRACT.ACCEPT_CONDITIONS_PAYMENT_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractAcceptConditionsPaymentFailureAction implements Action {
    readonly type = CONTRACT.ACCEPT_CONDITIONS_PAYMENT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractSearchAction implements Action {
    readonly type = CONTRACT.SEARCH;
    constructor(public payload: ContractForm, public correlationId?: number) {
    }
}

export class ContractSearchSuccessAction implements Action {
    readonly type = CONTRACT.SEARCH_SUCCESS;
    constructor(public payload: ContractForm, public correlationId?: number) {
    }
}

export class ContractSearchFailureAction implements Action {
    readonly type = CONTRACT.SEARCH_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractDeleteAction implements Action {
    readonly type = CONTRACT.DELETE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractDeleteSuccessAction implements Action {
    readonly type = CONTRACT.DELETE_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractDeleteFailureAction implements Action {
    readonly type = CONTRACT.DELETE_FAILURE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContractCancelAction implements Action {
    readonly type = CONTRACT.CANCEL;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class ContractCancelSuccessAction implements Action {
    readonly type = CONTRACT.CANCEL_SUCCESS;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class ContractCancelFailureAction implements Action {
    readonly type = CONTRACT.CANCEL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractDeleteServiceAction implements Action {
    readonly type = CONTRACT.DELETE_SERVICE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractDeleteServiceSuccessAction implements Action {
    readonly type = CONTRACT.DELETE_SERVICE_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContractDeleteServiceFailureAction implements Action {
    readonly type = CONTRACT.DELETE_SERVICE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
