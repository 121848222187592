import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { ProfileInfo } from "@client/utils/shared-constants";
import { State } from "@client/reducers/profile-reducer";
import { getProfileIsHydrated, getProfileMessage, getProfileHasError, getProfileHasSuccess, getProfileIsLoading, getProfileIsPending, getProfileData, getProfileDataCreatedAt, getProfileDataEmail, getProfileDataEmailVerified, getProfileDataLastLoginAt, getProfileDataPhoneNumber, getProfileDataUid, getProfileDataDisplayName, getProfileDataPhotoUrl } from "@client/selectors";
/*test*/ 
@Injectable()
export class ProfileStore extends BaseStore {
    get isHydrated(): Observable<boolean> {
        return this.store.pipe(select(getProfileIsHydrated));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getProfileMessage));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getProfileHasError));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getProfileHasSuccess));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getProfileIsLoading));
    }
    get isPending(): Observable<boolean> {
        return this.store.pipe(select(getProfileIsPending));
    }
    get data(): Observable<ProfileInfo | null> {
        return this.store.pipe(select(getProfileData));
    }
    get dataCreatedAt(): Observable<string> {
        return this.store.pipe(select(getProfileDataCreatedAt));
    }
    get dataEmail(): Observable<string> {
        return this.store.pipe(select(getProfileDataEmail));
    }
    get dataEmailVerified(): Observable<boolean> {
        return this.store.pipe(select(getProfileDataEmailVerified));
    }
    get dataLastLoginAt(): Observable<string> {
        return this.store.pipe(select(getProfileDataLastLoginAt));
    }
    get dataPhoneNumber(): Observable<string> {
        return this.store.pipe(select(getProfileDataPhoneNumber));
    }
    get dataUid(): Observable<string> {
        return this.store.pipe(select(getProfileDataUid));
    }
    get dataDisplayName(): Observable<string> {
        return this.store.pipe(select(getProfileDataDisplayName));
    }
    get dataPhotoUrl(): Observable<string> {
        return this.store.pipe(select(getProfileDataPhotoUrl));
    }
}
