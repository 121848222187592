import { ADMIN_PROFILE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
}
export const initialState: State = {};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_PROFILE.INIT: return adminProfileInit(state);
        case ADMIN_PROFILE.DESTROY: return adminProfileDestroy(state);
        case ADMIN_PROFILE.CLICK_SYNC: return adminProfileClickSync(state);
        case ADMIN_PROFILE.CLICK_NEW_CONTRACT: return adminProfileClickNewContract(state);
        case ADMIN_PROFILE.CLICK_NEW_ACCESS_CODE: return adminProfileClickNewAccessCode(state);
        case ADMIN_PROFILE.CLICK_NEW_ACCOUNT: return adminProfileClickNewAccount(state);
        default: return state;
    }
}
export function adminProfileInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminProfileDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminProfileClickSync(state: State): State {
    return state;
}
export function adminProfileClickNewContract(state: State): State {
    return state;
}
export function adminProfileClickNewAccessCode(state: State): State {
    return state;
}
export function adminProfileClickNewAccount(state: State): State {
    return state;
}
