import { SESSION, PROFILE, PROFILE_EDIT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ProfileEditForm, Session, ProfileInfo, ProfileWritableEntities } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    isActive: boolean;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isPending: boolean;
    form: ProfileEditForm | null;
}
export const initialState: State = {
    message: null,
    isActive: false,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isPending: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PROFILE_EDIT.DESTROY: return profileEditDestroy(state);
        case PROFILE_EDIT.INIT: return profileEditInit(state);
        case PROFILE_EDIT.CLICK_SUBMIT: return profileEditClickSubmit(state);
        case PROFILE_EDIT.FORM_UPDATE: return profileEditFormUpdate(state, action.payload);
        case SESSION.STATE_CHANGE: return sessionStateChange(state, action.payload);
        case PROFILE.HYDRATE_SUCCESS: return profileHydrateSuccess(state, action.payload);
        case PROFILE.UPDATE_FAILURE: return profileUpdateFailure(state, action.payload);
        case PROFILE.UPDATE_SUCCESS: return profileUpdateSuccess(state, action.payload);
        case PROFILE.UPDATE: return profileUpdate(state, action.payload);
        default: return state;
    }
}
export function profileEditDestroy(state: State): State {
    return {
        ...initialState,
        form: state.form,
        isActive: true,
    };
}
export function profileEditInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function profileEditClickSubmit(state: State): State {
    return state;
}
export function profileEditFormUpdate(state: State, payload: ProfileEditForm): State {
    return {
        ...state
    };
}
export function sessionStateChange(state: State, payload: Session): State {
    return {
        ...state,
        form: state.isActive ? state.form : {
            displayName: payload && payload.displayName || null,
            photoURL: payload && payload.photoURL || null,
        }
    };
}
export function profileHydrateSuccess(state: State, payload: ProfileInfo): State {
    return {
        ...state,
        form: !state.form || !state.isActive ? {
            displayName: payload.displayName,
            photoURL: payload.photoURL,
        } : state.form
    };
}
export function profileUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
export function profileUpdateSuccess(state: State, payload: ProfileWritableEntities): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function profileUpdate(state: State, payload: ProfileWritableEntities): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
