import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONTACT } from '@client/actions';
import { ContactSubmitFormAction, ContactSubmitFormFailureAction, ContactSubmitFormSuccessAction } from '@client/actions/contact-actions';
import { ConfigService } from '@client/core/services/config.service';
import { Action } from '@client/lib/action';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class ContactEffects {
  @Effect({ dispatch: true })
  submit$: Observable<Action> = this.actions$.pipe(
    ofType<ContactSubmitFormAction>(CONTACT.SUBMIT_FORM),
    switchMap(action =>
      this.http.post(environment.backendApiUrl + '/contactUs', action.payload).pipe(
        map((res: any) => new ContactSubmitFormSuccessAction(res)),
        catchError(error => of(new ContactSubmitFormFailureAction(error)))
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private config: ConfigService, private http: HttpClient) {}
}
