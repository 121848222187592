import { ADMIN_CONFIGURATION } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { MonerisConfiguration, SystemConfiguration } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    isEdit: boolean;
    form: MonerisConfiguration | null;
    data: SystemConfiguration | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isLoaded: false,
    isEdit: false,
    form: null,
    data: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_CONFIGURATION.INIT: return adminConfigurationInit(state);
        case ADMIN_CONFIGURATION.DESTROY: return adminConfigurationDestroy(state);
        case ADMIN_CONFIGURATION.HYDRATE: return adminConfigurationHydrate(state);
        case ADMIN_CONFIGURATION.HYDRATE_SUCCESS: return adminConfigurationHydrateSuccess(state, action.payload);
        case ADMIN_CONFIGURATION.HYDRATE_FAILURE: return adminConfigurationHydrateFailure(state, action.payload);
        case ADMIN_CONFIGURATION.UPDATE_MONERIS: return adminConfigurationUpdateMoneris(state, action.payload);
        case ADMIN_CONFIGURATION.UPDATE_MONERIS_SUCCESS: return adminConfigurationUpdateMonerisSuccess(state);
        case ADMIN_CONFIGURATION.UPDATE_MONERIS_FAILURE: return adminConfigurationUpdateMonerisFailure(state, action.payload);
        default: return state;
    }
}
export function adminConfigurationInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminConfigurationDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminConfigurationHydrate(state: State): State {
    return {
        ...state,
        isLoaded: false,
        isLoading: true,
        hasError: false,
        hasSuccess: false,
        message: null,
        data: null
    };
}
export function adminConfigurationHydrateSuccess(state: State, payload: SystemConfiguration): State {
    return {
        ...state,
        isLoaded: true,
        isLoading: false,
        hasSuccess: true,
        data: payload
    };
}
export function adminConfigurationHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminConfigurationUpdateMoneris(state: State, payload: MonerisConfiguration): State {
    return { ...state, ...payload };
}
export function adminConfigurationUpdateMonerisSuccess(state: State): State {
    return state;
}
export function adminConfigurationUpdateMonerisFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
