import { Injectable } from '@angular/core';
import { PARKS, SESSION } from '@client/actions';
import { ParksHydrateAction, ParksHydrateFailureAction, ParksHydrateSuccessAction } from '@client/actions/parks-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { ParksService } from '@client/core/services/parks.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { empty, iif, of } from 'rxjs';

import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class ParksEffects {
  @Effect({ dispatch: true })
  hydrateParks$ = this.actions$.pipe(
    ofType<ParksHydrateAction>(PARKS.HYDRATE),
    switchMap(action =>
      this.parks.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map(parks => new ParksHydrateSuccessAction(parks, action.correlationId)),
        catchError(error => of(new ParksHydrateFailureAction(error, action.correlationId)))
      )
    )
  );
  @Effect({ dispatch: true })
  sessionInit$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.parks, of(new ParksHydrateAction(null, action.correlationId)), empty()))
  );

  constructor(private actions$: Actions, private store: Store<any>, private parks: ParksService) {}
}
