import { CONTRACT, CONTRACT_DETAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import moment from 'moment-timezone';
import { ContractInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    freshlyCreated: boolean;
    pdfSuccess: boolean | null;
    canUpdatePpa: boolean;
    canUpdateCc: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isCompleted: false,
    freshlyCreated: false,
    pdfSuccess: null,
    canUpdatePpa: false,
    canUpdateCc: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT_DETAILS.INIT: return contractDetailsInit(state);
        case CONTRACT_DETAILS.DESTROY: return contractDetailsDestroy(state);
        case CONTRACT_DETAILS.CLICK_CANCEL: return contractDetailsClickCancel(state);
        case CONTRACT_DETAILS.CLICK_PPA_UPDATE: return contractDetailsClickPpaUpdate(state);
        case CONTRACT_DETAILS.CLICK_CC_UPDATE: return contractDetailsClickCcUpdate(state);
        case CONTRACT.HYDRATE_SUCCESS: return contractHydrateSuccess(state, action.payload);
        default: return state;
    }
}
export function contractDetailsInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function contractDetailsDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function contractDetailsClickCancel(state: State): State {
    return state;
}
export function contractDetailsClickPpaUpdate(state: State): State {
    return state;
}
export function contractDetailsClickCcUpdate(state: State): State {
    return state;
}
export function contractHydrateSuccess(state: State, payload: ContractInfo): State {
    return {
        ...state,
        canUpdateCc: payload && payload.isPaid && payload.subscription && payload.subscription.spaq && payload.subscription.spaq.paymentMethod && payload.subscription.spaq.paymentMethod.Method.toString() === 'Credit' && payload.service && payload.service.serviceBillingType === 'Monthly' ? true : false,
        canUpdatePpa: payload && payload.isPaid && payload.subscription && payload.subscription.spaq && payload.subscription.spaq.paymentMethod && payload.subscription.spaq.paymentMethod.Method.toString() === 'PreAuthorized' ? true : false,
        freshlyCreated: payload && payload.createdAt && moment().diff(payload.createdAt, 'days') === 0 ? true : false
    };
}
