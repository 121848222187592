import { Injectable } from '@angular/core';
import { LOGIN } from '@client/actions';
import { AuthLogoutAction, AuthSignInWithEmailAndPasswordAction } from '@client/actions/auth-actions';
import { Action } from '@client/lib/action';
import { getLoginForm } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mapTo, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class LoginAuthEffects {
  @Effect()
  loginWithEmailAndPassword$: Observable<Action> = this.actions$.pipe(
    ofType(LOGIN.CLICK_SIGNIN),
    withLatestFrom(this.store.pipe(select(getLoginForm))),
    map(([a, b]) => new AuthSignInWithEmailAndPasswordAction(b))
  );
  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(ofType(LOGIN.CLICK_LOGOUT), mapTo(new AuthLogoutAction()));

  constructor(private actions$: Actions, private store: Store<any>) {}
}
