import { State as AccessCodeState } from "@client/reducers/access-code-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeGroupInfo, AccessCodeServiceInfo, AccessCodeMetasInfo, AccessCodeMetasConditions, AccessCodeServiceCustomFields } from "@client/utils/shared-constants";
export const getData: Selector<AccessCodeState, AccessCodeGroupInfo | null> = (state: AccessCodeState) => state.data;
export const getDataGroupCode: Selector<AccessCodeState, string> = (state: AccessCodeState) => getData(state) && getData(state).groupCode;
export const getDataGroupDescription: Selector<AccessCodeState, string> = (state: AccessCodeState) => getData(state) && getData(state).groupDescription;
export const getDataOrganizationCode: Selector<AccessCodeState, string> = (state: AccessCodeState) => getData(state) && getData(state).organizationCode;
export const getDataPublic: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => getData(state) && getData(state).public;
export const getDataServices: Selector<AccessCodeState, AccessCodeServiceInfo[]> = (state: AccessCodeState) => getData(state) && getData(state).services;
export const getDataMeta: Selector<AccessCodeState, AccessCodeMetasInfo> = (state: AccessCodeState) => getData(state) && getData(state).meta;
export const getDataMetaConditions: Selector<AccessCodeState, AccessCodeMetasConditions> = (state: AccessCodeState) => getDataMeta(state) && getDataMeta(state).conditions;
export const getDataMetaConditionsService: Selector<AccessCodeState, string | null> = (state: AccessCodeState) => getDataMetaConditions(state) && getDataMetaConditions(state).service;
export const getDataMetaConditionsPaymentDas: Selector<AccessCodeState, string | null> = (state: AccessCodeState) => getDataMetaConditions(state) && getDataMetaConditions(state).paymentDas;
export const getDataMetaConditionsPaymentCc: Selector<AccessCodeState, string | null> = (state: AccessCodeState) => getDataMetaConditions(state) && getDataMetaConditions(state).paymentCc;
export const getDataMetaConditionsPaymentPpa: Selector<AccessCodeState, string> = (state: AccessCodeState) => getDataMetaConditions(state) && getDataMetaConditions(state).paymentPpa;
export const getDataMetaConditionsPaymentInternet: Selector<AccessCodeState, string | null> = (state: AccessCodeState) => getDataMetaConditions(state) && getDataMetaConditions(state).paymentInternet;
export const getDataMetaCustomFields: Selector<AccessCodeState, AccessCodeServiceCustomFields[]> = (state: AccessCodeState) => getDataMeta(state) && getDataMeta(state).customFields;
export const getMessage: Selector<AccessCodeState, string | null> = (state: AccessCodeState) => state.message;
export const getHasError: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => state.hasError;
export const getHasSuccess: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => state.hasSuccess;
export const getIsLoading: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => state.isLoading;
export const getIsPending: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => state.isPending;
export const getIsHydrated: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => state.isHydrated;
export const getIsActive: Selector<AccessCodeState, boolean> = (state: AccessCodeState) => state.isActive;
