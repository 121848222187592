import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {SubscriptionInfo, UpdateEmailInfo} from '@client/utils/shared-constants';
import {EMPTY, from, Observable} from 'rxjs';
import {switchMap} from "rxjs/operators";
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class EmailService {
  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {}

  updateEmail({ email, currentPassword }: UpdateEmailInfo): Observable<any> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => from(
        this.afAuth.signInWithEmailAndPassword(u.email, currentPassword)
          .then(r => {
            return u.updateEmail(email);
          })
          .then(x => {
            return u.sendEmailVerification();
          })
      ))
    );
  }
}
