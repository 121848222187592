import { REGISTER } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo } from "@client/utils/shared-constants";

export class RegisterAlreadyConfirmedAction implements Action {
    readonly type = REGISTER.ALREADY_CONFIRMED;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterDestroyAccessCodeAction implements Action {
    readonly type = REGISTER.DESTROY_ACCESS_CODE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterDestroyContractAction implements Action {
    readonly type = REGISTER.DESTROY_CONTRACT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterDestroyContractConfirmationAction implements Action {
    readonly type = REGISTER.DESTROY_CONTRACT_CONFIRMATION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterDestroyEmailAction implements Action {
    readonly type = REGISTER.DESTROY_EMAIL;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterDestroyEmailPendingAction implements Action {
    readonly type = REGISTER.DESTROY_EMAIL_PENDING;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterDestroySubscriptionAction implements Action {
    readonly type = REGISTER.DESTROY_SUBSCRIPTION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterGoToSubscriptionAction implements Action {
    readonly type = REGISTER.GO_TO_SUBSCRIPTION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterInitAccessCodeAction implements Action {
    readonly type = REGISTER.INIT_ACCESS_CODE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterInitContractAction implements Action {
    readonly type = REGISTER.INIT_CONTRACT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterInitContractConfirmationAction implements Action {
    readonly type = REGISTER.INIT_CONTRACT_CONFIRMATION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterInitEmailAction implements Action {
    readonly type = REGISTER.INIT_EMAIL;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterInitEmailPendingAction implements Action {
    readonly type = REGISTER.INIT_EMAIL_PENDING;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterInitSubscriptionAction implements Action {
    readonly type = REGISTER.INIT_SUBSCRIPTION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterNotLoggedInAction implements Action {
    readonly type = REGISTER.NOT_LOGGED_IN;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterRecoverEmailFailureAction implements Action {
    readonly type = REGISTER.RECOVER_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRecoverEmailSuccessAction implements Action {
    readonly type = REGISTER.RECOVER_EMAIL_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterCodeEmailFailureAction implements Action {
    readonly type = REGISTER.REGISTER_CODE_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterCodeEmailSuccessAction implements Action {
    readonly type = REGISTER.REGISTER_CODE_EMAIL_SUCCESS;
    constructor(public payload: UserEntity, public correlationId?: number) {
    }
}

export class RegisterRegisterCodeSuccessAction implements Action {
    readonly type = REGISTER.REGISTER_CODE_SUCCESS;
    constructor(public payload: AccessCodeInfo, public correlationId?: number) {
    }
}

export class RegisterRegisterCodeFailureAction implements Action {
    readonly type = REGISTER.REGISTER_CODE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterContractEmailSuccessAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT_EMAIL_SUCCESS;
    constructor(public payload: UserEntity, public correlationId?: number) {
    }
}

export class RegisterRegisterContractEmailFailureAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterContractSuccessAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterContractFailureAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterFailureAction implements Action {
    readonly type = REGISTER.REGISTER_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterSuccessAction implements Action {
    readonly type = REGISTER.REGISTER_SUCCESS;
    constructor(public payload: UserEntity, public correlationId?: number) {
    }
}

export class RegisterSelectMethodAction implements Action {
    readonly type = REGISTER.SELECT_METHOD;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class RegisterSendValidationLinkFailureAction implements Action {
    readonly type = REGISTER.SEND_VALIDATION_LINK_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterSendValidationLinkSuccessAction implements Action {
    readonly type = REGISTER.SEND_VALIDATION_LINK_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSubmitEmailFailureAction implements Action {
    readonly type = REGISTER.SUBMIT_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterValidateEmailSuccessAction implements Action {
    readonly type = REGISTER.VALIDATE_EMAIL_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterValidateEmailFailureAction implements Action {
    readonly type = REGISTER.VALIDATE_EMAIL_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterClickBackToLoginAction implements Action {
    readonly type = REGISTER.CLICK_BACK_TO_LOGIN;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterClickIgnoreAccessCodeAction implements Action {
    readonly type = REGISTER.CLICK_IGNORE_ACCESS_CODE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterClickIgnoreContractAction implements Action {
    readonly type = REGISTER.CLICK_IGNORE_CONTRACT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterClickIgnoreSubscriptionAction implements Action {
    readonly type = REGISTER.CLICK_IGNORE_SUBSCRIPTION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterGoToAccessCodeAction implements Action {
    readonly type = REGISTER.GO_TO_ACCESS_CODE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterGoToContractAction implements Action {
    readonly type = REGISTER.GO_TO_CONTRACT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterGoToContractConfirmationAction implements Action {
    readonly type = REGISTER.GO_TO_CONTRACT_CONFIRMATION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterGoToEmailAction implements Action {
    readonly type = REGISTER.GO_TO_EMAIL;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterGoToEmailPendingAction implements Action {
    readonly type = REGISTER.GO_TO_EMAIL_PENDING;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterLoginAction implements Action {
    readonly type = REGISTER.LOGIN;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterLogoutAction implements Action {
    readonly type = REGISTER.LOGOUT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterRecoverEmailAction implements Action {
    readonly type = REGISTER.RECOVER_EMAIL;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class RegisterRegisterAction implements Action {
    readonly type = REGISTER.REGISTER;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class RegisterRegisterCodeAction implements Action {
    readonly type = REGISTER.REGISTER_CODE;
    constructor(public payload: AccessCodeInfo, public correlationId?: number) {
    }
}

export class RegisterRegisterCodeEmailAction implements Action {
    readonly type = REGISTER.REGISTER_CODE_EMAIL;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterContractAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT;
    constructor(public payload: ContractForm, public correlationId?: number) {
    }
}

export class RegisterRegisterContractEmailAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT_EMAIL;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class RegisterRegisterContractResetAction implements Action {
    readonly type = REGISTER.REGISTER_CONTRACT_RESET;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterResendAction implements Action {
    readonly type = REGISTER.RESEND;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSearchContractAction implements Action {
    readonly type = REGISTER.SEARCH_CONTRACT;
    constructor(public payload: ContractForm, public correlationId?: number) {
    }
}

export class RegisterSendValidationLinkAction implements Action {
    readonly type = REGISTER.SEND_VALIDATION_LINK;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterSubmitEmailAction implements Action {
    readonly type = REGISTER.SUBMIT_EMAIL;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class RegisterUpdateEmailFormAction implements Action {
    readonly type = REGISTER.UPDATE_EMAIL_FORM;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class RegisterValidateEmailAction implements Action {
    readonly type = REGISTER.VALIDATE_EMAIL;
    constructor(public payload: string, public correlationId?: number) {
    }
}
