import { ADMIN_USERS_LIST, ADMIN_USERS_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    form: {} | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_USERS_FORM.INIT: return adminUsersFormInit(state);
        case ADMIN_USERS_FORM.DESTROY: return adminUsersFormDestroy(state);
        case ADMIN_USERS_FORM.CLICK_SUBMIT: return adminUsersFormClickSubmit(state, action.payload);
        case ADMIN_USERS_FORM.UPDATE: return adminUsersFormUpdate(state, action.payload);
        case ADMIN_USERS_FORM.UPDATE_SUCCESS: return adminUsersFormUpdateSuccess(state);
        case ADMIN_USERS_FORM.UPDATE_FAILURE: return adminUsersFormUpdateFailure(state, action.payload);
        case ADMIN_USERS_LIST.CLICK_EDIT: return adminUsersListClickEdit(state);
        case ADMIN_USERS_LIST.SELECT: return adminUsersListSelect(state, action.payload);
        case ADMIN_USERS_LIST.SELECT_USER: return adminUsersListSelectUser(state, action.payload);
        default: return state;
    }
}
export function adminUsersFormInit(state: State): State {
    return state;
}
export function adminUsersFormDestroy(state: State): State {
    return state;
}
export function adminUsersFormClickSubmit(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminUsersFormUpdate(state: State, payload: any): State {
    return {
        ...state,
        isLoading: true,
        message: null,
        hasError: false,
        hasSuccess: false
    };
}
export function adminUsersFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true
    };
}
export function adminUsersFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        message: payload,
        hasError: true
    };
}
export function adminUsersListClickEdit(state: State): State {
    return state;
}
export function adminUsersListSelect(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminUsersListSelectUser(state: State, payload: number): State {
    return { ...state };
}
