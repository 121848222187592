import { Injectable } from '@angular/core';
import { MonerisHydrateAction } from '@client/actions/moneris-actions';
import { MonerisService } from '@client/core/services/moneris.service';
import { getMonerisPaypageIsActive, getMonerisVaultIsActive } from '@client/selectors';
import { Actions, Effect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class MonerisEffects {
  @Effect({ dispatch: true })
  start$ = combineLatest(this.store.pipe(select(getMonerisPaypageIsActive)), this.store.pipe(select(getMonerisVaultIsActive))).pipe(
    switchMap(([a, b]) => (!a && !b ? EMPTY : this.moneris.hydrate().pipe(map(x => new MonerisHydrateAction(x)))))
  );

  constructor(private actions$: Actions, private store: Store<any>, private moneris: MonerisService) {}
}
