import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {EMPTY, from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class MetadataService {
  constructor(private db: AngularFireDatabase, private auth: AngularFireAuth) {}

  hydrate(): Observable<any> {
    return this.auth.user.pipe(
      switchMap((u: User) => this.db
        .object(`/user/${u.uid}/metadata`)
        .snapshotChanges()
        .pipe(map(x => x.payload.val())))
    );
  }
}
