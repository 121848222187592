import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {ProfileWritableEntities, SubscriptionInfo} from '@client/utils/shared-constants';
import {EMPTY, from, Observable, of} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import firebase from 'firebase';
import User = firebase.User;

@Injectable()
export class ProfileService {
  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {}

  get(): Observable<any> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => of(u.toJSON()))
    );
  }

  getProfile(): Observable<any> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => this.db.object(`/user/${u.uid}/profil`).valueChanges())
    );
  }

  hydrate(): Observable<any> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => this.db.object(`/user/${u.uid}/profil`).valueChanges())
    );
  }

  updateProfile(data: ProfileWritableEntities): Observable<any> {
    return this.afAuth.user.pipe(
      switchMap((u: User) => from(u.updateProfile(data)).pipe(switchMap(() => this.db.object(`/user/${u.uid}/profil`).update(data))))
    );
  }
}
