import { VEHICLES } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule } from "@client/utils/shared-constants";

export class VehiclesInitAction implements Action {
    readonly type = VEHICLES.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesDestroyAction implements Action {
    readonly type = VEHICLES.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesFormHydrateAction implements Action {
    readonly type = VEHICLES.FORM_HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesFormHydrateSuccessAction implements Action {
    readonly type = VEHICLES.FORM_HYDRATE_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesFormHydrateFailureAction implements Action {
    readonly type = VEHICLES.FORM_HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesHydrateAction implements Action {
    readonly type = VEHICLES.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesHydrateSuccessAction implements Action {
    readonly type = VEHICLES.HYDRATE_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesHydrateFailureAction implements Action {
    readonly type = VEHICLES.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesHydrateBrandsSuccessAction implements Action {
    readonly type = VEHICLES.HYDRATE_BRANDS_SUCCESS;
    constructor(public payload: string[], public correlationId?: number) {
    }
}

export class VehiclesHydrateBrandsFailureAction implements Action {
    readonly type = VEHICLES.HYDRATE_BRANDS_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesHydrateModelsSuccessAction implements Action {
    readonly type = VEHICLES.HYDRATE_MODELS_SUCCESS;
    constructor(public payload: string[], public correlationId?: number) {
    }
}

export class VehiclesHydrateModelsFailureAction implements Action {
    readonly type = VEHICLES.HYDRATE_MODELS_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class VehiclesSelectYearAction implements Action {
    readonly type = VEHICLES.SELECT_YEAR;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesSelectBrandAction implements Action {
    readonly type = VEHICLES.SELECT_BRAND;
    constructor(public payload: string, public correlationId?: number) {
    }
}
