import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { SUBSCRIPTION_EDIT } from '@client/actions';
import {
  SubscriptionEditClickSubmitAction,
  SubscriptionEditUpdateAction,
  SubscriptionEditUpdateFailureAction,
  SubscriptionEditUpdateSuccessAction,
  SubscriptionEditUploadAction,
  SubscriptionEditUploadFailureAction,
  SubscriptionEditUploadSuccessAction
} from '@client/actions/subscription-edit-actions';
import { AuthService } from '@client/core/services/auth.service';
import { Action } from '@client/lib/action';
import { getContractCreateIsActive, getSettingsIsActive, getSubscriptionEditForm } from '@client/selectors';
import { getSessionUid } from '@client/selectors/index';
import { readFile } from '@client/utils/readfile';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { empty, Observable, of } from 'rxjs';
import { catchError, last, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class SubscriptionEditEffects {
  @Effect({ dispatch: true })
  submit$: Observable<Action> = this.actions$.pipe(
    ofType<SubscriptionEditClickSubmitAction>(SUBSCRIPTION_EDIT.CLICK_SUBMIT),
    withLatestFrom(this.store.select(getSubscriptionEditForm), this.store.select(getSettingsIsActive), this.store.select(getContractCreateIsActive)),
    switchMap(([a, b, isActive, contractIsActive]) => (isActive || contractIsActive ? of(new SubscriptionEditUpdateAction(b, a.correlationId)) : empty()))
  );

  @Effect({ dispatch: true })
  updateSubscription$: Observable<Action> = this.actions$.pipe(
    ofType<SubscriptionEditUpdateAction>(SUBSCRIPTION_EDIT.UPDATE),
    switchMap(action => {
      let dynamicFieldJsonData = null;

      if (action.payload.custom) {
        dynamicFieldJsonData = { body: action.payload.custom };
      }

      const data = {
        custom: action.payload.custom,
        spaq: {
          ...action.payload.spaq,
          identificationCode: (action.payload.custom && action.payload.custom._identification) || action.payload.spaq.identificationCode || null,
          dynamicFieldJsonData: JSON.stringify(dynamicFieldJsonData)
        }
      };
      console.log(data);
      const subscriptionUpdateCall = this.fns.httpsCallable('subscriptionUpdate');
      return subscriptionUpdateCall(data).pipe(
        map(x => new SubscriptionEditUpdateSuccessAction(x, action.correlationId)),
        catchError(e => of(new SubscriptionEditUpdateFailureAction(e.message, action.correlationId)))
      );
    })
  );

  @Effect({ dispatch: true })
  upload$ = this.actions$.pipe(
    ofType<SubscriptionEditUploadAction>(SUBSCRIPTION_EDIT.UPLOAD),
    switchMap(action =>
      readFile(action.payload.file).pipe(
        mergeMap((blob: any) => {
          const subscriptionUpdateCall = this.fns.httpsCallable('attachmentsCreate');
          return subscriptionUpdateCall({
            AttachmentType: 'Autres',
            AttachmentFileType: blob.type === 'application/pdf' ? 'Pdf' : 'Image',
            DataBytes: blob.data
          });
        }),
        map(x => action)
      )
    ),
    withLatestFrom(this.store.pipe(select(getSessionUid))),
    switchMap<any, any>(([action, uid]: [SubscriptionEditUploadAction, string]) => {
      const filePath = 'users/' + uid + '/' + new Date().getTime();
      const fileRef = this.storage.ref(filePath);

      const task = this.storage.upload(filePath, action.payload.file);

      const uploadPercent = task.percentageChanges();
      return task.snapshotChanges().pipe(
        last(),
        mergeMap(() => fileRef.getDownloadURL()),
        map(x => new SubscriptionEditUploadSuccessAction({ key: action.payload.key, file: x }, action.correlationId)),
        catchError(e => of(new SubscriptionEditUploadFailureAction(e, action.correlationId)))
      );
    })
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private fns: AngularFireFunctions, private storage: AngularFireStorage) {}
}
