import { AUTH, SESSION } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Session, AuthCodeInfo } from "@client/utils/shared-constants";
export interface State {
    isLoaded: boolean;
    isAnonymous: boolean;
    emailVerified: boolean;
    lastLoginAt: string | null;
    uid: string | null;
    apiKey: string | null;
    email: string;
    displayName: string;
    createdAt: string;
    phoneNumber: string;
    photoURL: string;
    claims: {
        admin: boolean;
        accessLevel: number;
    };
}
export const initialState: State = {
    isLoaded: false,
    isAnonymous: null,
    emailVerified: null,
    lastLoginAt: null,
    uid: null,
    apiKey: null,
    email: "",
    displayName: "",
    createdAt: "",
    phoneNumber: "",
    photoURL: "",
    claims: {
        admin: false,
        accessLevel: 0
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SESSION.INIT: return sessionInit(state);
        case SESSION.DESTROY: return sessionDestroy(state);
        case SESSION.RELOAD: return sessionReload(state);
        case SESSION.RELOAD_SUCCESS: return sessionReloadSuccess(state, action.payload);
        case SESSION.RELOAD_FAILURE: return sessionReloadFailure(state, action.payload);
        case SESSION.OOB: return sessionOob(state, action.payload);
        case SESSION.OOB_RESET_PSSSWORD: return sessionOobResetPsssword(state, action.payload);
        case SESSION.OOB_RECODER_EMAIL: return sessionOobRecoderEmail(state, action.payload);
        case SESSION.OOB_VERIFY_EMAIL: return sessionOobVerifyEmail(state, action.payload);
        case SESSION.START_SERVICE: return sessionStartService(state);
        case SESSION.STOP_SERVICE: return sessionStopService(state);
        case SESSION.STATE_CHANGE: return sessionStateChange(state, action.payload);
        case AUTH.LOGOUT_SUCCESS: return authLogoutSuccess(state);
        case AUTH.VALIDATE_EMAIL_CODE_SUCCESS: return authValidateEmailCodeSuccess(state, action.payload);
        default: return state;
    }
}
export function sessionInit(state: State): State {
    return state;
}
export function sessionDestroy(state: State): State {
    return { ...initialState, isLoaded: state.isLoaded };
}
export function sessionReload(state: State): State {
    return {
        ...state,
    };
}
export function sessionReloadSuccess(state: State, payload: Session): State {
    return {
        ...state,
        ...payload,
    };
}
export function sessionReloadFailure(state: State, payload: any): State {
    return state;
}
export function sessionOob(state: State, payload: AuthCodeInfo): State {
    return { ...state };
}
export function sessionOobResetPsssword(state: State, payload: AuthCodeInfo): State {
    return state;
}
export function sessionOobRecoderEmail(state: State, payload: AuthCodeInfo): State {
    return state;
}
export function sessionOobVerifyEmail(state: State, payload: AuthCodeInfo): State {
    return state;
}
export function sessionStartService(state: State): State {
    return state;
}
export function sessionStopService(state: State): State {
    return state;
}
export function sessionStateChange(state: State, payload: Session): State {
    return { ...state, ...payload, isLoaded: true };
}
export function authLogoutSuccess(state: State): State {
    return { ...initialState, isLoaded: state.isLoaded };
}
export function authValidateEmailCodeSuccess(state: State, payload: AuthCodeInfo): State {
    return { ...state, emailVerified: true };
}
