import { State as LoginState } from "@client/reducers/login-reducer";
import { Selector } from "@ngrx/store";
export const getMessage: Selector<LoginState, string | null> = (state: LoginState) => state.message;
export const getHasError: Selector<LoginState, boolean> = (state: LoginState) => state.hasError;
export const getHasSuccess: Selector<LoginState, boolean> = (state: LoginState) => state.hasSuccess;
export const getIsActive: Selector<LoginState, boolean> = (state: LoginState) => state.isActive;
export const getIsLoading: Selector<LoginState, boolean> = (state: LoginState) => state.isLoading;
export const getIsLoggedIn: Selector<LoginState, boolean> = (state: LoginState) => state.isLoggedIn;
export const getForm: Selector<LoginState, {
    email: string;
    password: string;
}> = (state: LoginState) => state.form;
export const getFormEmail: Selector<LoginState, string> = (state: LoginState) => getForm(state) && getForm(state).email;
export const getFormPassword: Selector<LoginState, string> = (state: LoginState) => getForm(state) && getForm(state).password;
