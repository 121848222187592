import { Injectable } from "@angular/core";
import { IAccessCodeAddDispatcher } from "@client/interfaces/access-code-add-interface";
import { Store } from "@ngrx/store";
import { AccessCodeAddInitAction, AccessCodeAddDestroyAction, AccessCodeAddFormUpdateAction, AccessCodeAddSubmitAction } from "@client/actions/access-code-add-actions";
import { AccessCodeSearchInfo } from "@client/utils/shared-constants";
@Injectable()
export class AccessCodeAddDispatcher implements IAccessCodeAddDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddDestroyAction(payload, correlationId));
    }
    formUpdate(payload: AccessCodeSearchInfo, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddFormUpdateAction(payload, correlationId));
    }
    submit(payload: AccessCodeSearchInfo, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddSubmitAction(payload, correlationId));
    }
}
