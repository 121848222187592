import { State as PpaAddState } from "@client/reducers/ppa-add-reducer";
import { Selector } from "@ngrx/store";
import { PaymentPPAInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<PpaAddState, boolean> = (state: PpaAddState) => state.isActive;
export const getMessage: Selector<PpaAddState, string | null> = (state: PpaAddState) => state.message;
export const getHasError: Selector<PpaAddState, boolean> = (state: PpaAddState) => state.hasError;
export const getHasSuccess: Selector<PpaAddState, boolean> = (state: PpaAddState) => state.hasSuccess;
export const getIsLoading: Selector<PpaAddState, boolean> = (state: PpaAddState) => state.isLoading;
export const getIsCompleted: Selector<PpaAddState, boolean> = (state: PpaAddState) => state.isCompleted;
export const getForm: Selector<PpaAddState, PaymentPPAInfo> = (state: PpaAddState) => state.form;
export const getFormEffectiveDate: Selector<PpaAddState, string> = (state: PpaAddState) => getForm(state) && getForm(state).effectiveDate;
export const getFormInstitutionName: Selector<PpaAddState, string> = (state: PpaAddState) => getForm(state) && getForm(state).institutionName;
export const getFormFinancialInstitutionId: Selector<PpaAddState, number> = (state: PpaAddState) => getForm(state) && getForm(state).financialInstitutionId;
export const getFormFolioNo: Selector<PpaAddState, number> = (state: PpaAddState) => getForm(state) && getForm(state).folioNo;
export const getFormDownloadUrl: Selector<PpaAddState, string> = (state: PpaAddState) => getForm(state) && getForm(state).downloadUrl;
export const getFormTransitNo: Selector<PpaAddState, number> = (state: PpaAddState) => getForm(state) && getForm(state).transitNo;
