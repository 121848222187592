import { State as AdminServiceListState } from "@client/reducers/admin-service-list-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeServiceInfo, ServiceInfo, ServiceMetaInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => state.isActive;
export const getMessage: Selector<AdminServiceListState, string | null> = (state: AdminServiceListState) => state.message;
export const getHasError: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => state.hasError;
export const getHasSuccess: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => state.isLoading;
export const getData: Selector<AdminServiceListState, AccessCodeServiceInfo[]> = (state: AdminServiceListState) => state.data;
export const getSelecteService: Selector<AdminServiceListState, AccessCodeServiceInfo | null> = (state: AdminServiceListState) => state.selecteService;
export const getSelecteServiceGroupCode: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteService(state) && getSelecteService(state).groupCode;
export const getSelecteServiceOrganizationCode: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteService(state) && getSelecteService(state).organizationCode;
export const getSelecteServiceServiceId: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteService(state) && getSelecteService(state).serviceId;
export const getSelecteServiceParkIdentificationNo: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteService(state) && getSelecteService(state).parkIdentificationNo;
export const getSelecteServiceServiceEntity: Selector<AdminServiceListState, ServiceInfo> = (state: AdminServiceListState) => getSelecteService(state) && getSelecteService(state).serviceEntity;
export const getSelecteServiceServiceEntityPeriod: Selector<AdminServiceListState, any> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).period;
export const getSelecteServiceServiceEntityAvailableFrom: Selector<AdminServiceListState, string | null> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).availableFrom;
export const getSelecteServiceServiceEntityAvailableServiceId: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).availableServiceId;
export const getSelecteServiceServiceEntityAvailableUntil: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).availableUntil;
export const getSelecteServiceServiceEntityCancelationNotice: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).cancelationNotice;
export const getSelecteServiceServiceEntityCancelationNoticeTimeUnit: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).cancelationNoticeTimeUnit;
export const getSelecteServiceServiceEntityDuration: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).duration;
export const getSelecteServiceServiceEntityDurationTimeUnit: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).durationTimeUnit;
export const getSelecteServiceServiceEntityIsBankingCardPaymentAccepted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isBankingCardPaymentAccepted;
export const getSelecteServiceServiceEntityIsCancelable: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isCancelable;
export const getSelecteServiceServiceEntityIsCancelationFirstDayOfMonth: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isCancelationFirstDayOfMonth;
export const getSelecteServiceServiceEntityIsDasPaymentAccepted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isDasPaymentAccepted;
export const getSelecteServiceServiceEntityIsFinancialInstitutionPaymentAccepted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isFinancialInstitutionPaymentAccepted;
export const getSelecteServiceServiceEntityIsFixedDuration: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isFixedDuration;
export const getSelecteServiceServiceEntityIsPpaPaymentAccepted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isPpaPaymentAccepted;
export const getSelecteServiceServiceEntityIsSubscriptionAvailable: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isSubscriptionAvailable;
export const getSelecteServiceServiceEntityIsTax1Exempted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isTax1Exempted;
export const getSelecteServiceServiceEntityIsTax2Exempted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isTax2Exempted;
export const getSelecteServiceServiceEntityIsTax3Exempted: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).isTax3Exempted;
export const getSelecteServiceServiceEntityOnSaleFrom: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).onSaleFrom;
export const getSelecteServiceServiceEntityOnSaleUntil: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).onSaleUntil;
export const getSelecteServiceServiceEntityParkIdentificationNo: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).parkIdentificationNo;
export const getSelecteServiceServiceEntityPartialMonthCalculation: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).partialMonthCalculation;
export const getSelecteServiceServiceEntityPrice: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).price;
export const getSelecteServiceServiceEntitySectorId: Selector<AdminServiceListState, number> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).sectorId;
export const getSelecteServiceServiceEntityServiceBillingType: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).serviceBillingType;
export const getSelecteServiceServiceEntityServiceDescription: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).serviceDescription;
export const getSelecteServiceServiceEntityServiceStartRule: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).serviceStartRule;
export const getSelecteServiceServiceEntityServiceType: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).serviceType;
export const getSelecteServiceServiceEntityMeta: Selector<AdminServiceListState, ServiceMetaInfo> = (state: AdminServiceListState) => getSelecteServiceServiceEntity(state) && getSelecteServiceServiceEntity(state).meta;
export const getSelecteServiceServiceEntityMetaNote: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => getSelecteServiceServiceEntityMeta(state) && getSelecteServiceServiceEntityMeta(state).note;
export const getSelectedOrganizationCode: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => state.selectedOrganizationCode;
export const getSelectedGroupCode: Selector<AdminServiceListState, string> = (state: AdminServiceListState) => state.selectedGroupCode;
export const getShowOrganizationBacklink: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => state.showOrganizationBacklink;
export const getShowGroupBacklink: Selector<AdminServiceListState, boolean> = (state: AdminServiceListState) => state.showGroupBacklink;
