import { AUTH, FORGOT_PASSWORD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ResetPasswordForm, ForgotPasswordForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    isResent: boolean;
    form_reset: ResetPasswordForm;
    form: ForgotPasswordForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: false,
    isCompleted: false,
    isResent: false,
    form_reset: {
        confirm_password: "",
        password: ""
    },
    form: {
        email: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case FORGOT_PASSWORD.CLICK_SUBMIT: return forgotPasswordClickSubmit(state);
        case FORGOT_PASSWORD.CLICK_LOGIN: return forgotPasswordClickLogin(state);
        case FORGOT_PASSWORD.CLICK_RESEND_EMAIL: return forgotPasswordClickResendEmail(state);
        case FORGOT_PASSWORD.FORM_UPDATE: return forgotPasswordFormUpdate(state, action.payload);
        case FORGOT_PASSWORD.FORM_RESET: return forgotPasswordFormReset(state);
        case FORGOT_PASSWORD.INIT: return forgotPasswordInit(state);
        case FORGOT_PASSWORD.INIT_CONFIRMATION: return forgotPasswordInitConfirmation(state);
        case FORGOT_PASSWORD.DESTROY_CONFIRMATION: return forgotPasswordDestroyConfirmation(state);
        case FORGOT_PASSWORD.DESTROY: return forgotPasswordDestroy(state);
        case AUTH.FORGOT_PASSWORD: return authForgotPassword(state, action.payload);
        case AUTH.FORGOT_PASSWORD_SUCCESS: return authForgotPasswordSuccess(state);
        case AUTH.FORGOT_PASSWORD_FAILURE: return authForgotPasswordFailure(state, action.payload);
        default: return state;
    }
}
export function forgotPasswordClickSubmit(state: State): State {
    return state;
}
export function forgotPasswordClickLogin(state: State): State {
    return state;
}
export function forgotPasswordClickResendEmail(state: State): State {
    return {
        ...state,
        isResent: true,
    };
}
export function forgotPasswordFormUpdate(state: State, payload: ForgotPasswordForm): State {
    return { ...state, form: payload };
}
export function forgotPasswordFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form,
    };
}
export function forgotPasswordInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function forgotPasswordInitConfirmation(state: State): State {
    return state;
}
export function forgotPasswordDestroyConfirmation(state: State): State {
    return state;
}
export function forgotPasswordDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function authForgotPassword(state: State, payload: ForgotPasswordForm): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
        isCompleted: false,
    };
}
export function authForgotPasswordSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        isCompleted: true,
        hasSuccess: true,
    };
}
export function authForgotPasswordFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
        isResent: false,
    };
}
