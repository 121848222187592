import { Injectable } from "@angular/core";
import { ISubscriptionDispatcher } from "@client/interfaces/subscription-interface";
import { Store } from "@ngrx/store";
import { SubscriptionInitAction, SubscriptionDestroyAction, SubscriptionHydrateAction, SubscriptionHydrateSuccessAction, SubscriptionHydrateFailureAction } from "@client/actions/subscription-actions";
import { SubscriptionInfo } from "@client/utils/shared-constants";
@Injectable()
export class SubscriptionDispatcher implements ISubscriptionDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionDestroyAction(payload, correlationId));
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: SubscriptionInfo, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionHydrateFailureAction(payload, correlationId));
    }
}
