import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? value.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3') : null;
  }
}
