import { State as UpdatePasswordState } from "@client/reducers/update-password-reducer";
import { Selector } from "@ngrx/store";
import { UpdatePasswordForm } from "@client/utils/shared-constants";
export const getMessage: Selector<UpdatePasswordState, string | null> = (state: UpdatePasswordState) => state.message;
export const getHasError: Selector<UpdatePasswordState, boolean> = (state: UpdatePasswordState) => state.hasError;
export const getHasSuccess: Selector<UpdatePasswordState, boolean> = (state: UpdatePasswordState) => state.hasSuccess;
export const getIsActive: Selector<UpdatePasswordState, boolean> = (state: UpdatePasswordState) => state.isActive;
export const getIsLoading: Selector<UpdatePasswordState, boolean> = (state: UpdatePasswordState) => state.isLoading;
export const getIsCompleted: Selector<UpdatePasswordState, boolean> = (state: UpdatePasswordState) => state.isCompleted;
export const getIsFormVisible: Selector<UpdatePasswordState, boolean> = (state: UpdatePasswordState) => state.isFormVisible;
export const getForm: Selector<UpdatePasswordState, UpdatePasswordForm> = (state: UpdatePasswordState) => state.form;
export const getFormConfirmPassword: Selector<UpdatePasswordState, string> = (state: UpdatePasswordState) => getForm(state) && getForm(state).confirm_password;
export const getFormCurrentPassword: Selector<UpdatePasswordState, string> = (state: UpdatePasswordState) => getForm(state) && getForm(state).current_password;
export const getFormPassword: Selector<UpdatePasswordState, string> = (state: UpdatePasswordState) => getForm(state) && getForm(state).password;
