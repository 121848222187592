import { Injectable } from '@angular/core';
import { NetworkStatusOfflineAction, NetworkStatusOnlineAction } from '@client/actions/network-status-actions';
import { Effect } from '@ngrx/effects';
import { fromEvent, merge, of } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable()
export class NetworkStatusEffects {
  @Effect({ dispatch: true })
  networkStatus$ = merge(fromEvent(window, 'offline').pipe(mapTo(false)), fromEvent(window, 'online').pipe(mapTo(true)), of(navigator.onLine)).pipe(
    map(isOnline => (isOnline ? new NetworkStatusOnlineAction() : new NetworkStatusOfflineAction()))
  );

  constructor() {}
}
