import { Component, Input, OnInit } from '@angular/core';
import { InputBase } from '../input-base';

@Component({
  selector: 'spaq-input-bank-number',
  template: `
    <kendo-textbox-container [formGroup]="form" class="w-100" [floatingLabel]="label">
      <kendo-combobox class="w-100" [formControlName]="name" [id]="name" [valuePrimitive]="valuePrimitive" [data]="data" [textField]="'value'" [valueField]="'text'"></kendo-combobox>

      <small class="alert-danger mt-1" *ngIf="form.get(name).touched && form.get(name).errors && form.get(name).dirty">
        {{ form.get(name).hasError('required') ? 'Ce champ est requis' : '' }}
        {{ form.get(name).hasError('pattern') ? 'Format invalide' : '' }}
        {{ form.get(name).hasError('minlength') || form.get(name).hasError('maxlength') ? 'Doit être composé de 3 chiffres' : '' }}
      </small> </kendo-textbox-container
    ><!--

  001	Banque de Montréal
  002	Banque de Nouvelle-Écosse
  003	Banque Royale et Trust Royale
  004	Banque Toronto-Dominion
  006	Banque Nationale
  010	CIBC
  016	Banque Hong Kong
  039	Banque Laurentienne
  260	CITIBANKO
  509	Canada Trust
  522	Trust Prêt et Revenu
  540	Banque Manuvie
  544	Montréal Trust
  577	Trust Général
  587	Société Nationale de Fiducie
  608	Alterna Bank
  614	Tangerine
  815	Caisses Populaires Desjardins
  828	Ottawa Carleton Credit Union LTD
  829	Caisse Populaire Lasalle Inc.
  842	Alterna Savings
  865	Nouveau Brunswick C.P.
  879	Morden Credit Union

  -->
  `
})
export class InputBankNumberComponent extends InputBase implements OnInit {
  data = [
    { text: '001', value: '001 - Banque de Montréal' },
    { text: '002', value: '002 - Banque de Nouvelle-Écosse' },
    { text: '003', value: '003 - Banque Royale et Trust Royale' },
    { text: '004', value: '004 - Banque Toronto-Dominion' },
    { text: '006', value: '006 - Banque Nationale' },
    { text: '010', value: '010 - CIBC' },
    { text: '016', value: '016 - Banque Hong Kong' },
    { text: '039', value: '039 - Banque Laurentienne' },
    { text: '260', value: '260 - CITIBANKO' },
    { text: '509', value: '509 - Canada Trust' },
    { text: '522', value: '522 - Trust Prêt et Revenu' },
    { text: '540', value: '540 - Banque Manuvie' },
    { text: '544', value: '544 - Montréal Trust' },
    { text: '577', value: '577 - Trust Général' },
    { text: '587', value: '587 - Société Nationale de Fiducie' },
    { text: '608', value: '608 - Alterna Bank' },
    { text: '614', value: '614 - Tangerine' },
    { text: '815', value: '815 - Caisses Populaires Desjardins' },
    { text: '828', value: '828 - Ottawa Carleton Credit Union LTD' },
    { text: '829', value: '829 - Caisse Populaire Lasalle Inc.' },
    { text: '842', value: '842 - Alterna Savings' },
    { text: '865', value: '865 - Nouveau Brunswick C.P.' },
    { text: '879', value: '879 - Morden Credit Union' }
  ];
  @Input() valuePrimitive = false;

  constructor() {
    super();
  }

  ngOnInit() {}
}
