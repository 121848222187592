import { State as AdminMetasListState } from "@client/reducers/admin-metas-list-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeServiceCustomFields, AccessCodeServiceCustomFieldsValue } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => state.isActive;
export const getMessage: Selector<AdminMetasListState, string | null> = (state: AdminMetasListState) => state.message;
export const getHasError: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => state.hasError;
export const getHasSuccess: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => state.isLoading;
export const getData: Selector<AdminMetasListState, AccessCodeServiceCustomFields[]> = (state: AdminMetasListState) => state.data;
export const getSelecteMetas: Selector<AdminMetasListState, AccessCodeServiceCustomFields | null> = (state: AdminMetasListState) => state.selecteMetas;
export const getSelecteMetasLabel: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).label;
export const getSelecteMetasName: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).name;
export const getSelecteMetasType: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).type;
export const getSelecteMetasValues: Selector<AdminMetasListState, AccessCodeServiceCustomFieldsValue[]> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).values;
export const getSelecteMetasRequired: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).required;
export const getSelecteMetasOrganizationCode: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).organizationCode;
export const getSelecteMetasGroupCode: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).groupCode;
export const getSelecteMetasOrder: Selector<AdminMetasListState, number> = (state: AdminMetasListState) => getSelecteMetas(state) && getSelecteMetas(state).order;
export const getSelectedOrganizationCode: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => state.selectedOrganizationCode;
export const getSelectedGroupCode: Selector<AdminMetasListState, string> = (state: AdminMetasListState) => state.selectedGroupCode;
export const getShowOrganizationBacklink: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => state.showOrganizationBacklink;
export const getShowGroupBacklink: Selector<AdminMetasListState, boolean> = (state: AdminMetasListState) => state.showGroupBacklink;
