import { Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export class InputBase {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() required = false;
  styles: [];
  @Input() value: string;
}
