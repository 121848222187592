import { ADMIN_USERS_FORM, ADMIN_USERS_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selectedUser: number | null;
    data: any;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selectedUser: null,
    data: []
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_USERS_LIST.INIT: return adminUsersListInit(state);
        case ADMIN_USERS_LIST.DESTROY: return adminUsersListDestroy(state);
        case ADMIN_USERS_LIST.HYDRATE: return adminUsersListHydrate(state);
        case ADMIN_USERS_LIST.HYDRATE_SUCCESS: return adminUsersListHydrateSuccess(state, action.payload);
        case ADMIN_USERS_LIST.HYDRATE_FAILURE: return adminUsersListHydrateFailure(state, action.payload);
        case ADMIN_USERS_LIST.CLICK_EDIT: return adminUsersListClickEdit(state);
        case ADMIN_USERS_LIST.SELECT: return adminUsersListSelect(state, action.payload);
        case ADMIN_USERS_LIST.SELECT_USER: return adminUsersListSelectUser(state, action.payload);
        case ADMIN_USERS_LIST.DELETE_USER: return adminUsersListDeleteUser(state, action.payload);
        case ADMIN_USERS_LIST.DELETE_USER_SUCCESS: return adminUsersListDeleteUserSuccess(state, action.payload);
        case ADMIN_USERS_LIST.DELETE_USER_FAILURE: return adminUsersListDeleteUserFailure(state, action.payload);
        case ADMIN_USERS_FORM.UPDATE_SUCCESS: return adminUsersFormUpdateSuccess(state);
        default: return state;
    }
}
export function adminUsersListInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminUsersListDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminUsersListHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true
    };
}
export function adminUsersListHydrateSuccess(state: State, payload: any[]): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: false,
        data: payload
    };
}
export function adminUsersListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false
    };
}
export function adminUsersListClickEdit(state: State): State {
    return state;
}
export function adminUsersListSelect(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminUsersListSelectUser(state: State, payload: number): State {
    return { ...state, selectedUser: payload };
}
export function adminUsersListDeleteUser(state: State, payload: any): State {
    return {
        ...state,
        isLoading: true,
        message: null,
        hasError: false,
        hasSuccess: false
    };
}
export function adminUsersListDeleteUserSuccess(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true
    };
}
export function adminUsersListDeleteUserFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        message: payload,
        hasError: true
    };
}
export function adminUsersFormUpdateSuccess(state: State): State {
    return state;
}
