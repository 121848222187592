import { EMAIL_EDIT, PROFILE, PROFILE_DETAILS, PROFILE_EDIT, SUBSCRIPTION_DETAILS, SUBSCRIPTION_EDIT, UPDATE_PASSWORD, VEHICLES_FORM, VEHICLES, VEHICLES_DETAILS, ACCESS_CODE_DETAILS, ACCESS_CODE_ADD, PPA_ADD, MONERIS_VAULT, SETTINGS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    isEditable: boolean;
    isAddable: boolean;
    subTitle: string | null;
    editLink: string | null;
    addLink: string | null;
    backLink: string | null;
}
export const initialState: State = {
    isActive: false,
    isEditable: false,
    isAddable: false,
    subTitle: null,
    editLink: null,
    addLink: null,
    backLink: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SETTINGS.INIT: return settingsInit(state);
        case SETTINGS.DESTROY: return settingsDestroy(state);
        case SETTINGS.CLICK: return settingsClick(state, action.payload);
        case EMAIL_EDIT.INIT: return emailEditInit(state);
        case PROFILE.INIT: return profileInit(state);
        case PROFILE_DETAILS.INIT: return profileDetailsInit(state);
        case PROFILE_EDIT.INIT: return profileEditInit(state);
        case SUBSCRIPTION_DETAILS.INIT: return subscriptionDetailsInit(state);
        case SUBSCRIPTION_EDIT.INIT: return subscriptionEditInit(state);
        case UPDATE_PASSWORD.INIT: return updatePasswordInit(state);
        case VEHICLES_FORM.INIT: return vehiclesFormInit(state, action.payload);
        case VEHICLES.HYDRATE_SUCCESS: return vehiclesHydrateSuccess(state, action.payload);
        case VEHICLES_DETAILS.INIT: return vehiclesDetailsInit(state);
        case ACCESS_CODE_DETAILS.INIT: return accessCodeDetailsInit(state);
        case ACCESS_CODE_ADD.INIT: return accessCodeAddInit(state);
        case PPA_ADD.INIT: return ppaAddInit(state);
        case MONERIS_VAULT.INIT: return monerisVaultInit(state);
        default: return state;
    }
}
export function settingsInit(state: State): State {
    return {
        ...initialState,
        isActive: true,
    };
}
export function settingsDestroy(state: State): State {
    return {
        ...initialState,
        isActive: false,
    };
}
export function settingsClick(state: State, payload: any): State {
    return state;
}
export function emailEditInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        subTitle: 'MODIFIER L\'ADRESSE COURRIEL DU COMPTE',
        backLink: null
    };
}
export function profileInit(state: State): State {
    return state;
}
export function profileDetailsInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isEditable: true,
        editLink: '/account/settings/profil/edit',
        subTitle: 'MON PROFIL',
        backLink: null
    };
}
export function profileEditInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isEditable: false,
        editLink: '/account/settings/profil/edit',
        subTitle: 'MODIFIER MON PROFIL',
        backLink: '/account/settings/profil/details'
    };
}
export function subscriptionDetailsInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isEditable: true,
        editLink: '/account/settings/subscription/edit',
        subTitle: 'INFORMATIONS PERSONNELLES',
        backLink: null
    };
}
export function subscriptionEditInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        subTitle: 'MODIFIER MES INFORMATIONS PERSONNELLES',
        backLink: '/account/settings/subscription/details'
    };
}
export function updatePasswordInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        subTitle: 'MODIFIER LE MOT DE PASSE DU COMPTE',
        backLink: null
    };
}
export function vehiclesFormInit(state: State, payload: string): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isEditable: false,
        subTitle: 'AJOUTER UN VÉHICULE',
        backLink: '/account/settings/vehicles/list'
    };
}
export function vehiclesHydrateSuccess(state: State, payload: any): State {
    let isAddable = state.isAddable;
    if (state.subTitle === 'MES VÉHICULES') {
        if ((payload || []).length >= 2) {
            isAddable = false;
        }
        else {
            isAddable = true;
        }
    }
    return { ...state, ...payload, isAddable };
}
export function vehiclesDetailsInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isAddable: true,
        addLink: '/account/settings/vehicles/add',
        subTitle: 'MES VÉHICULES',
        backLink: null
    };
}
export function accessCodeDetailsInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isAddable: true,
        addLink: '/account/access-code/add',
        subTitle: 'Code d\'accès',
        backLink: null
    };
}
export function accessCodeAddInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        isAddable: false,
        subTitle: 'Ajouter un code d\'accès',
        backLink: '/account/access-code/list'
    };
}
export function ppaAddInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        subTitle: 'Mise à jour des informations bancaire',
        backLink: null
    };
}
export function monerisVaultInit(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive,
        subTitle: 'Mise à jour de la carte de crédit',
        backLink: null
    };
}
