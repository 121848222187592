import { METADATA } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState } from "@client/utils/shared-constants";

export class MetadataHydrateAction implements Action {
    readonly type = METADATA.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class MetadataHydrateFailureAction implements Action {
    readonly type = METADATA.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class MetadataHydrateSuccessAction implements Action {
    readonly type = METADATA.HYDRATE_SUCCESS;
    constructor(public payload: MetadataState, public correlationId?: number) {
    }
}
