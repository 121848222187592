import { State as VehiclesAddState } from "@client/reducers/vehicles-add-reducer";
import { Selector } from "@ngrx/store";
import { Vehicule } from "@client/utils/shared-constants";
export const getHasSuccess: Selector<VehiclesAddState, boolean> = (state: VehiclesAddState) => state.hasSuccess;
export const getIsActive: Selector<VehiclesAddState, boolean> = (state: VehiclesAddState) => state.isActive;
export const getIsCompleted: Selector<VehiclesAddState, boolean> = (state: VehiclesAddState) => state.isCompleted;
export const getRedirectOnComplete: Selector<VehiclesAddState, boolean> = (state: VehiclesAddState) => state.redirectOnComplete;
export const getHasError: Selector<VehiclesAddState, boolean> = (state: VehiclesAddState) => state.hasError;
export const getIsLoading: Selector<VehiclesAddState, boolean> = (state: VehiclesAddState) => state.isLoading;
export const getMessage: Selector<VehiclesAddState, string | null> = (state: VehiclesAddState) => state.message;
export const getForm: Selector<VehiclesAddState, Vehicule> = (state: VehiclesAddState) => state.form;
export const getFormDescription: Selector<VehiclesAddState, string> = (state: VehiclesAddState) => getForm(state) && getForm(state).description;
export const getFormId: Selector<VehiclesAddState, number> = (state: VehiclesAddState) => getForm(state) && getForm(state).id;
export const getFormKey: Selector<VehiclesAddState, string> = (state: VehiclesAddState) => getForm(state) && getForm(state).key;
export const getFormLicencePlate: Selector<VehiclesAddState, string> = (state: VehiclesAddState) => getForm(state) && getForm(state).licencePlate;
export const getFormManufacturer: Selector<VehiclesAddState, string> = (state: VehiclesAddState) => getForm(state) && getForm(state).manufacturer;
export const getFormModel: Selector<VehiclesAddState, string> = (state: VehiclesAddState) => getForm(state) && getForm(state).model;
export const getFormVehiculeYear: Selector<VehiclesAddState, string> = (state: VehiclesAddState) => getForm(state) && getForm(state).vehiculeYear;
