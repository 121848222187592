import { ADMIN_NOTES_LIST, ADMIN_NOTES_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ServiceMetaInfo, ServiceInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    form: ServiceMetaInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_NOTES_FORM.INIT: return adminNotesFormInit(state);
        case ADMIN_NOTES_FORM.DESTROY: return adminNotesFormDestroy(state);
        case ADMIN_NOTES_FORM.CLICK_SUBMIT: return adminNotesFormClickSubmit(state, action.payload);
        case ADMIN_NOTES_FORM.UPDATE: return adminNotesFormUpdate(state, action.payload);
        case ADMIN_NOTES_FORM.UPDATE_SUCCESS: return adminNotesFormUpdateSuccess(state);
        case ADMIN_NOTES_FORM.UPDATE_FAILURE: return adminNotesFormUpdateFailure(state, action.payload);
        case ADMIN_NOTES_LIST.CLICK_EDIT: return adminNotesListClickEdit(state);
        case ADMIN_NOTES_LIST.SELECT: return adminNotesListSelect(state, action.payload);
        case ADMIN_NOTES_LIST.SELECT_PARK: return adminNotesListSelectPark(state, action.payload);
        default: return state;
    }
}
export function adminNotesFormInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminNotesFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminNotesFormClickSubmit(state: State, payload: ServiceMetaInfo): State {
    return state;
}
export function adminNotesFormUpdate(state: State, payload: ServiceMetaInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminNotesFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminNotesFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminNotesListClickEdit(state: State): State {
    return state;
}
export function adminNotesListSelect(state: State, payload: ServiceInfo): State {
    return {
        ...state,
        form: payload.meta
    };
}
export function adminNotesListSelectPark(state: State, payload: number): State {
    return state;
}
