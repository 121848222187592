import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInput } from '@client/shared/components/forms/abstract-input';

@Component({
  selector: 'plancton-input-text',
  template: `
    <kendo-textbox-container class="w-100" for="login-email-email" [id]="formControlName" [floatingLabel]="label">
      <input [name]="formControlName" [id]="formControlName" [disabled]="disabled" type="text" kendoTextBox [(ngModel)]="value" (ngModelChange)="updateModel()" (blur)="onModelTouched && onModelTouched()" />

      <div *ngIf="!isValid">
        <small class="alert-danger" *ngIf="control.hasError('required')">Champ requis</small>
        <small class="alert-danger" *ngIf="control.hasError('pattern')">Format invalide</small>
        <small class="alert-danger" *ngIf="control.hasError('maxlength')">Doit contenir moins de caractères</small>
      </div>
    </kendo-textbox-container>
  `,
  styles: [],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTextComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputTextComponent), multi: true }
  ]
})
export class InputTextComponent extends AbstractInput {
  @Input() label = '';
}
