import { State as ContractSearchState } from "@client/reducers/contract-search-reducer";
import { Selector } from "@ngrx/store";
import { ContractForm } from "@client/utils/shared-constants";
export const getMessage: Selector<ContractSearchState, string | null> = (state: ContractSearchState) => state.message;
export const getHasError: Selector<ContractSearchState, boolean> = (state: ContractSearchState) => state.hasError;
export const getHasSuccess: Selector<ContractSearchState, boolean> = (state: ContractSearchState) => state.hasSuccess;
export const getIsActive: Selector<ContractSearchState, boolean> = (state: ContractSearchState) => state.isActive;
export const getIsComplete: Selector<ContractSearchState, boolean> = (state: ContractSearchState) => state.isComplete;
export const getIsLoading: Selector<ContractSearchState, boolean> = (state: ContractSearchState) => state.isLoading;
export const getForm: Selector<ContractSearchState, ContractForm> = (state: ContractSearchState) => state.form;
export const getFormContractNo: Selector<ContractSearchState, string> = (state: ContractSearchState) => getForm(state) && getForm(state).contractNo;
export const getFormPhone: Selector<ContractSearchState, string> = (state: ContractSearchState) => getForm(state) && getForm(state).phone;
export const getFormPostalCode: Selector<ContractSearchState, string> = (state: ContractSearchState) => getForm(state) && getForm(state).postalCode;
