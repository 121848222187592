import { CONTACT } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState } from "@client/utils/shared-constants";

export class ContactInitAction implements Action {
    readonly type = CONTACT.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContactDestroyAction implements Action {
    readonly type = CONTACT.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ContactSubmitFormAction implements Action {
    readonly type = CONTACT.SUBMIT_FORM;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContactSubmitFormSuccessAction implements Action {
    readonly type = CONTACT.SUBMIT_FORM_SUCCESS;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ContactSubmitFormFailureAction implements Action {
    readonly type = CONTACT.SUBMIT_FORM_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
