import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { Vehicule } from "@client/utils/shared-constants";
import { State } from "@client/reducers/vehicles-reducer";
import { getVehiclesSelectedVehicle, getVehiclesSelectedVehicleDescription, getVehiclesSelectedVehicleId, getVehiclesSelectedVehicleKey, getVehiclesSelectedVehicleLicencePlate, getVehiclesSelectedVehicleManufacturer, getVehiclesSelectedVehicleModel, getVehiclesSelectedVehicleVehiculeYear, getVehiclesIsHydrated, getVehiclesHasVehicles, getVehiclesHasSuccess, getVehiclesHasError, getVehiclesIsLoading, getVehiclesMessage, getVehiclesSelectedYear, getVehiclesSelectedBrand, getVehiclesSelectedModel, getVehiclesYears, getVehiclesBrands, getVehiclesModels, getVehiclesData } from "@client/selectors";
/*test*/ 
@Injectable()
export class VehiclesStore extends BaseStore {
    get selectedVehicle(): Observable<Vehicule | null> {
        return this.store.pipe(select(getVehiclesSelectedVehicle));
    }
    get selectedVehicleDescription(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedVehicleDescription));
    }
    get selectedVehicleId(): Observable<number> {
        return this.store.pipe(select(getVehiclesSelectedVehicleId));
    }
    get selectedVehicleKey(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedVehicleKey));
    }
    get selectedVehicleLicencePlate(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedVehicleLicencePlate));
    }
    get selectedVehicleManufacturer(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedVehicleManufacturer));
    }
    get selectedVehicleModel(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedVehicleModel));
    }
    get selectedVehicleVehiculeYear(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedVehicleVehiculeYear));
    }
    get isHydrated(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesIsHydrated));
    }
    get hasVehicles(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesHasVehicles));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesHasSuccess));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesHasError));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesIsLoading));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getVehiclesMessage));
    }
    get selectedYear(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedYear));
    }
    get selectedBrand(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedBrand));
    }
    get selectedModel(): Observable<string> {
        return this.store.pipe(select(getVehiclesSelectedModel));
    }
    get years(): Observable<any> {
        return this.store.pipe(select(getVehiclesYears));
    }
    get brands(): Observable<any> {
        return this.store.pipe(select(getVehiclesBrands));
    }
    get models(): Observable<any> {
        return this.store.pipe(select(getVehiclesModels));
    }
    get data(): Observable<Vehicule[]> {
        return this.store.pipe(select(getVehiclesData));
    }
}
