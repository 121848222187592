import { State as ParksState, adapter as ParksAdapter } from "@client/reducers/parks-reducer";
import { Selector } from "@ngrx/store";
import { ParkInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<ParksState, string | null> = (state: ParksState) => state.message;
export const getHasError: Selector<ParksState, boolean> = (state: ParksState) => state.hasError;
export const getHasSuccess: Selector<ParksState, boolean> = (state: ParksState) => state.hasSuccess;
export const getIsLoading: Selector<ParksState, boolean> = (state: ParksState) => state.isLoading;
export const getSelected: Selector<ParksState, string | number | null> = (state: ParksState) => state.selected;
const { selectIds, selectEntities, selectAll, selectTotal } = ParksAdapter.getSelectors();
export const getIds = selectIds;
export const getEntities = selectEntities;
export const getAll = selectAll;
export const getTotal = selectTotal;
