import { AUTH, UPDATE_PASSWORD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { UpdatePasswordForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    isFormVisible: boolean;
    form: UpdatePasswordForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: false,
    isCompleted: false,
    isFormVisible: true,
    form: {
        confirm_password: "",
        current_password: "",
        password: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case UPDATE_PASSWORD.INIT: return updatePasswordInit(state);
        case UPDATE_PASSWORD.DESTROY: return updatePasswordDestroy(state);
        case UPDATE_PASSWORD.CLICK_SUBMIT: return updatePasswordClickSubmit(state);
        case UPDATE_PASSWORD.CLICK_LOGIN: return updatePasswordClickLogin(state);
        case UPDATE_PASSWORD.FORM_UPDATE: return updatePasswordFormUpdate(state, action.payload);
        case UPDATE_PASSWORD.FORM_RESET: return updatePasswordFormReset(state);
        case AUTH.UPDATE_PASSWORD: return authUpdatePassword(state, action.payload);
        case AUTH.UPDATE_PASSWORD_SUCCESS: return authUpdatePasswordSuccess(state, action.payload);
        case AUTH.UPDATE_PASSWORD_FAILURE: return authUpdatePasswordFailure(state, action.payload);
        default: return state;
    }
}
export function updatePasswordInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function updatePasswordDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function updatePasswordClickSubmit(state: State): State {
    return state;
}
export function updatePasswordClickLogin(state: State): State {
    return state;
}
export function updatePasswordFormUpdate(state: State, payload: UpdatePasswordForm): State {
    return {
        ...state,
        form: payload,
    };
}
export function updatePasswordFormReset(state: State): State {
    return {
        ...state,
        form: initialState.form,
    };
}
export function authUpdatePassword(state: State, payload: UpdatePasswordForm): State {
    return {
        ...state,
        isCompleted: false,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function authUpdatePasswordSuccess(state: State, payload: UpdatePasswordForm): State {
    return {
        ...state,
        isCompleted: true,
        isLoading: false,
        hasSuccess: true,
        isFormVisible: false,
    };
}
export function authUpdatePasswordFailure(state: State, payload: any): State {
    return {
        ...state,
        ...payload,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
