import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '@client/shared/components/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  exports: [FormsModule, FormModule, ReactiveFormsModule, TranslateModule],
  imports: [TranslateModule],
  providers: []
})
export class SharedFormModule {}
