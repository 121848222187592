import { PARKS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm } from "@client/utils/shared-constants";

export class ParksHydrateAction implements Action {
    readonly type = PARKS.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class ParksHydrateSuccessAction implements Action {
    readonly type = PARKS.HYDRATE_SUCCESS;
    constructor(public payload: ParkInfo[], public correlationId?: number) {
    }
}

export class ParksHydrateFailureAction implements Action {
    readonly type = PARKS.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class ParksSelectAction implements Action {
    readonly type = PARKS.SELECT;
    constructor(public payload: ParkInfo, public correlationId?: number) {
    }
}
