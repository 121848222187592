import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getContractState, getSessionClaimsAccessLevel } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasContractGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return this.store.pipe(
      select(getContractState),
      filter(x => x.isLoaded !== null),
      withLatestFrom(this.store.pipe(select(getSessionClaimsAccessLevel))),
      mergeMap(([s, accessLevel]) => {
        const isCompleted = (s.data && s.data.isCreationCompleted) || null;

        if (isCompleted) {
          return of(true);
        }
        if (Number(accessLevel) === 5) {
          return this.router.navigate(['/admin/organization']);
        }
        return this.router.navigate(['/account/contract/create']);
      })
    );
  }
}
