import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './app/configs/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import * as moment from 'moment-timezone';
import firebase from 'firebase';

moment.tz.setDefault('America/Montreal');
moment.locale('fr_CA');

Sentry.init({
  dsn: 'https://7aa07785cb664cb3816ec36fd0a229b0@o130370.ingest.sentry.io/287780' ,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'ciussse-chus.spaq.com', 'ciusssemtl.spaq.com', 'cisssmo.spaq.com', 'ciussse-chus.passerelle.staging.spaq.app', 'ciusssemtl.passerelle.staging.spaq.app', 'cisssmo.passerelle.staging.spaq.app'],
      routingInstrumentation: Sentry.routingInstrumentation,

    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// firebase.initializeApp(environment.firebase, '[DEFAULT]');
// firebase.database().useEmulator('localhost', 9000);
// firebase.functions().useEmulator('localhost', 5001);
// firebase.auth().useEmulator('http://localhost:9099/');

if (environment.production) {
  enableProdMode();

  if (console) {
    // console.log = () => {};
    // console.error = () => {};
    // console.warn = () => {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    console.log(err);
  });
