import { ADMIN_CONFIGURATION } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule, ServiceInfo, AccessCodeGroupInfo, SystemConfiguration, MonerisConfiguration } from "@client/utils/shared-constants";

export class AdminConfigurationInitAction implements Action {
    readonly type = ADMIN_CONFIGURATION.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AdminConfigurationDestroyAction implements Action {
    readonly type = ADMIN_CONFIGURATION.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AdminConfigurationHydrateAction implements Action {
    readonly type = ADMIN_CONFIGURATION.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AdminConfigurationHydrateSuccessAction implements Action {
    readonly type = ADMIN_CONFIGURATION.HYDRATE_SUCCESS;
    constructor(public payload: SystemConfiguration, public correlationId?: number) {
    }
}

export class AdminConfigurationHydrateFailureAction implements Action {
    readonly type = ADMIN_CONFIGURATION.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AdminConfigurationUpdateMonerisAction implements Action {
    readonly type = ADMIN_CONFIGURATION.UPDATE_MONERIS;
    constructor(public payload: MonerisConfiguration, public correlationId?: number) {
    }
}

export class AdminConfigurationUpdateMonerisSuccessAction implements Action {
    readonly type = ADMIN_CONFIGURATION.UPDATE_MONERIS_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AdminConfigurationUpdateMonerisFailureAction implements Action {
    readonly type = ADMIN_CONFIGURATION.UPDATE_MONERIS_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
