import { ACCOUNT_STATUS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState } from "@client/utils/shared-constants";

export class AccountStatusHydrateAction implements Action {
    readonly type = ACCOUNT_STATUS.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccountStatusHydrateFailureAction implements Action {
    readonly type = ACCOUNT_STATUS.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AccountStatusHydrateSuccessAction implements Action {
    readonly type = ACCOUNT_STATUS.HYDRATE_SUCCESS;
    constructor(public payload: AccountStatusState, public correlationId?: number) {
    }
}

export class AccountStatusUpdateAction implements Action {
    readonly type = ACCOUNT_STATUS.UPDATE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
