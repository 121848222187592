import { ADMIN_CODE_IMPORT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_CODE_IMPORT.INIT: return adminCodeImportInit(state);
        case ADMIN_CODE_IMPORT.DESTROY: return adminCodeImportDestroy(state);
        case ADMIN_CODE_IMPORT.READ_FILE: return adminCodeImportReadFile(state, action.payload);
        case ADMIN_CODE_IMPORT.READ_FILE_SUCCESS: return adminCodeImportReadFileSuccess(state, action.payload);
        case ADMIN_CODE_IMPORT.READ_FILE_ERROR: return adminCodeImportReadFileError(state, action.payload);
        default: return state;
    }
}
export function adminCodeImportInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function adminCodeImportDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminCodeImportReadFile(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminCodeImportReadFileSuccess(state: State, payload: any[]): State {
    return state;
}
export function adminCodeImportReadFileError(state: State, payload: any): State {
    return { ...state, ...payload };
}
