import { VEHICLES_FORM } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule } from "@client/utils/shared-constants";

export class VehiclesFormInitAction implements Action {
    readonly type = VEHICLES_FORM.INIT;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesFormDestroyAction implements Action {
    readonly type = VEHICLES_FORM.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesFormSelectYearAction implements Action {
    readonly type = VEHICLES_FORM.SELECT_YEAR;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesFormSelectBrandAction implements Action {
    readonly type = VEHICLES_FORM.SELECT_BRAND;
    constructor(public payload: string, public correlationId?: number) {
    }
}

export class VehiclesFormValidationRequiredAction implements Action {
    readonly type = VEHICLES_FORM.VALIDATION_REQUIRED;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class VehiclesFormClickValidationAction implements Action {
    readonly type = VEHICLES_FORM.CLICK_VALIDATION;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
