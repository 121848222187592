import { SECTORS } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo } from "@client/utils/shared-constants";

export class SectorsStartServiceAction implements Action {
    readonly type = SECTORS.START_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SectorsStopServiceAction implements Action {
    readonly type = SECTORS.STOP_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SectorsHydrateAction implements Action {
    readonly type = SECTORS.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SectorsHydrateSuccessAction implements Action {
    readonly type = SECTORS.HYDRATE_SUCCESS;
    constructor(public payload: SectorInfo[], public correlationId?: number) {
    }
}

export class SectorsHydrateFailureAction implements Action {
    readonly type = SECTORS.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
