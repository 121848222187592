import { USER_VEHICLES_DETAILS, VEHICLES_ADD } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    hasSuccess: boolean;
    isActive: boolean;
    isCompleted: boolean;
    redirectOnComplete: boolean;
    hasError: boolean;
    isLoading: boolean;
    message: string | null;
    form: Vehicule;
}
export const initialState: State = {
    hasSuccess: false,
    isActive: false,
    isCompleted: false,
    redirectOnComplete: false,
    hasError: false,
    isLoading: false,
    message: null,
    form: {
        description: "",
        id: 0,
        key: "",
        licencePlate: "",
        manufacturer: "",
        model: "",
        vehiculeYear: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case VEHICLES_ADD.INIT: return vehiclesAddInit(state);
        case VEHICLES_ADD.DESTROY: return vehiclesAddDestroy(state);
        case VEHICLES_ADD.FORM_UPDATE: return vehiclesAddFormUpdate(state, action.payload);
        case VEHICLES_ADD.SELECT_YEAR: return vehiclesAddSelectYear(state, action.payload);
        case VEHICLES_ADD.SELECT_BRAND: return vehiclesAddSelectBrand(state, action.payload);
        case VEHICLES_ADD.SELECT_MODEL: return vehiclesAddSelectModel(state, action.payload);
        case VEHICLES_ADD.CLICK_SUBMIT: return vehiclesAddClickSubmit(state, action.payload);
        case VEHICLES_ADD.ADD: return vehiclesAddAdd(state, action.payload);
        case VEHICLES_ADD.ADD_SUCCESS: return vehiclesAddAddSuccess(state, action.payload);
        case VEHICLES_ADD.ADD_FAILURE: return vehiclesAddAddFailure(state, action.payload);
        case VEHICLES_ADD.UPDATE: return vehiclesAddUpdate(state, action.payload);
        case VEHICLES_ADD.UPDATE_SUCCESS: return vehiclesAddUpdateSuccess(state, action.payload);
        case VEHICLES_ADD.UPDATE_FAILURE: return vehiclesAddUpdateFailure(state, action.payload);
        case VEHICLES_ADD.VALIDATION_REQUIRED: return vehiclesAddValidationRequired(state);
        case USER_VEHICLES_DETAILS.CLICK_DELETE: return userVehiclesDetailsClickDelete(state, action.payload);
        case USER_VEHICLES_DETAILS.CLICK_EDIT: return userVehiclesDetailsClickEdit(state, action.payload);
        default: return state;
    }
}
export function vehiclesAddInit(state: State): State {
    return {
        ...initialState,
        form: state.form,
        isActive: true,
    };
}
export function vehiclesAddDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function vehiclesAddFormUpdate(state: State, payload: Vehicule): State {
    return {
        ...state,
        form: payload,
    };
}
export function vehiclesAddSelectYear(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesAddSelectBrand(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesAddSelectModel(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesAddClickSubmit(state: State, payload: Vehicule): State {
    return state;
}
export function vehiclesAddAdd(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        isLoading: true,
        message: null,
    };
}
export function vehiclesAddAddSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function vehiclesAddAddFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
export function vehiclesAddUpdate(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        isLoading: true,
        isCompleted: false,
        message: null,
    };
}
export function vehiclesAddUpdateSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        isCompleted: true,
    };
}
export function vehiclesAddUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
export function vehiclesAddValidationRequired(state: State): State {
    return state;
}
export function userVehiclesDetailsClickDelete(state: State, payload: Vehicule): State {
    return { ...state };
}
export function userVehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return {
        ...state,
        form: payload,
    };
}
