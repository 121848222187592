import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@client/lib/action';
import { getSessionUid } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { from, Observable, of, Subject } from 'rxjs';
import { bufferTime, catchError, concatMap, filter, map, publish, refCount, tap } from 'rxjs/operators';

@Injectable()
export class TelemetryService {
  queue: Subject<Action> = new Subject<Action>();
  userId: string | null = null;

  constructor(private http: HttpClient, private store: Store<any>) {
    this.store.pipe(select(getSessionUid)).subscribe(x => (this.userId = x));

    this.initialize();
  }

  private request() {
    return x => this.http.post(x.url, x.payload).pipe(this.catchRequest);
  }

  catchRequest(o: Observable<any>) {
    return o.pipe(
      catchError(e =>
        of(e).pipe(
          tap(err => {
            console.log('Unable to send Telemetry', err);
          })
        )
      )
    );
  }

  flattenPayloads(payloads: Array<Action>) {
    return payloads.reduce(
      (acc: Array<Array<Action>>, value) => {
        const lastPayloadArray = acc[acc.length - 1];
        const length = JSON.stringify(lastPayloadArray).length;
        if (length < 300000) {
          lastPayloadArray.push(value);
        } else {
          acc.push([value]);
        }
        return acc;
      },
      [[]]
    );
  }

  getRequestUrlWithPayload(payloads: Array<Array<Action>>) {
    return payloads.map(x => ({ url: `/api/telemetry`, payload: x }));
  }

  initialize() {
    const requestObservable = this.queue.pipe(
      bufferTime(2000),
      filter(x => x.length > 0),
      map(this.flattenPayloads),
      map(this.getRequestUrlWithPayload),
      tap(x => {
        if (x.length >= 5) {
          console.log(`Telemetry: too many events are queued in one buffer time, bail out some of them`);
        }
      }),
      concatMap((urls: any[]) => from(urls.slice(0, 5)).pipe(concatMap(this.request()))),
      publish(),
      refCount()
    );
    requestObservable.subscribe(
      x => {},
      e => {
        console.log(`Telemetry: unexpected error occurred`, e);
      }
    );
  }

  public track(event: Action): void {
    const payload = {
      timestamp: Date.now(),
      size: JSON.stringify(event.payload || {}).length,
      uid: this.userId,
      ...event
    };
    this.queue.next(payload);
  }
}
