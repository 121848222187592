import { State as AdminGroupFormState } from "@client/reducers/admin-group-form-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeGroupInfo, AccessCodeServiceInfo, AccessCodeMetasInfo, AccessCodeMetasConditions, AccessCodeServiceCustomFields } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminGroupFormState, boolean> = (state: AdminGroupFormState) => state.isActive;
export const getMessage: Selector<AdminGroupFormState, string | null> = (state: AdminGroupFormState) => state.message;
export const getHasError: Selector<AdminGroupFormState, boolean> = (state: AdminGroupFormState) => state.hasError;
export const getHasSuccess: Selector<AdminGroupFormState, boolean> = (state: AdminGroupFormState) => state.hasSuccess;
export const getIsLoading: Selector<AdminGroupFormState, boolean> = (state: AdminGroupFormState) => state.isLoading;
export const getIsEdit: Selector<AdminGroupFormState, boolean> = (state: AdminGroupFormState) => state.isEdit;
export const getForm: Selector<AdminGroupFormState, AccessCodeGroupInfo | null> = (state: AdminGroupFormState) => state.form;
export const getFormGroupCode: Selector<AdminGroupFormState, string> = (state: AdminGroupFormState) => getForm(state) && getForm(state).groupCode;
export const getFormGroupDescription: Selector<AdminGroupFormState, string> = (state: AdminGroupFormState) => getForm(state) && getForm(state).groupDescription;
export const getFormOrganizationCode: Selector<AdminGroupFormState, string> = (state: AdminGroupFormState) => getForm(state) && getForm(state).organizationCode;
export const getFormPublic: Selector<AdminGroupFormState, boolean> = (state: AdminGroupFormState) => getForm(state) && getForm(state).public;
export const getFormServices: Selector<AdminGroupFormState, AccessCodeServiceInfo[]> = (state: AdminGroupFormState) => getForm(state) && getForm(state).services;
export const getFormMeta: Selector<AdminGroupFormState, AccessCodeMetasInfo> = (state: AdminGroupFormState) => getForm(state) && getForm(state).meta;
export const getFormMetaConditions: Selector<AdminGroupFormState, AccessCodeMetasConditions> = (state: AdminGroupFormState) => getFormMeta(state) && getFormMeta(state).conditions;
export const getFormMetaConditionsService: Selector<AdminGroupFormState, string | null> = (state: AdminGroupFormState) => getFormMetaConditions(state) && getFormMetaConditions(state).service;
export const getFormMetaConditionsPaymentDas: Selector<AdminGroupFormState, string | null> = (state: AdminGroupFormState) => getFormMetaConditions(state) && getFormMetaConditions(state).paymentDas;
export const getFormMetaConditionsPaymentCc: Selector<AdminGroupFormState, string | null> = (state: AdminGroupFormState) => getFormMetaConditions(state) && getFormMetaConditions(state).paymentCc;
export const getFormMetaConditionsPaymentPpa: Selector<AdminGroupFormState, string> = (state: AdminGroupFormState) => getFormMetaConditions(state) && getFormMetaConditions(state).paymentPpa;
export const getFormMetaConditionsPaymentInternet: Selector<AdminGroupFormState, string | null> = (state: AdminGroupFormState) => getFormMetaConditions(state) && getFormMetaConditions(state).paymentInternet;
export const getFormMetaCustomFields: Selector<AdminGroupFormState, AccessCodeServiceCustomFields[]> = (state: AdminGroupFormState) => getFormMeta(state) && getFormMeta(state).customFields;
