import { createFeatureSelector, createSelector, MemoizedSelector, Selector } from "@ngrx/store";
import { RouterReducerState } from "@ngrx/router-store";
import { RootState } from "@client/reducers";
import { State as NetworkStatusState } from "@client/reducers/network-status-reducer";
import { State as PopupState } from "@client/reducers/popup-reducer";
import { State as EmailEditState } from "@client/reducers/email-edit-reducer";
import { State as AuthState } from "@client/reducers/auth-reducer";
import { State as OobState } from "@client/reducers/oob-reducer";
import { State as SessionState } from "@client/reducers/session-reducer";
import { State as FaqState } from "@client/reducers/faq-reducer";
import { State as OrganizationState, adapter as OrganizationAdapter } from "@client/reducers/organization-reducer";
import { State as SectorsState } from "@client/reducers/sectors-reducer";
import { State as ContractState } from "@client/reducers/contract-reducer";
import { State as ContractCancelState } from "@client/reducers/contract-cancel-reducer";
import { State as ContractCreateState } from "@client/reducers/contract-create-reducer";
import { State as ContractDetailsState } from "@client/reducers/contract-details-reducer";
import { State as ContractConditionsServiceState } from "@client/reducers/contract-conditions-service-reducer";
import { State as ContractConditionsPaymentState } from "@client/reducers/contract-conditions-payment-reducer";
import { State as ParksState, adapter as ParksAdapter } from "@client/reducers/parks-reducer";
import { State as ParksSelectState } from "@client/reducers/parks-select-reducer";
import { State as CalendarState } from "@client/reducers/calendar-reducer";
import { State as LayoutState } from "@client/reducers/layout-reducer";
import { State as ProfileState } from "@client/reducers/profile-reducer";
import { State as ProfileDetailsState } from "@client/reducers/profile-details-reducer";
import { State as ProfileEditState } from "@client/reducers/profile-edit-reducer";
import { State as SubscriptionState } from "@client/reducers/subscription-reducer";
import { State as RegisterState } from "@client/reducers/register-reducer";
import { State as LoginState } from "@client/reducers/login-reducer";
import { State as MetadataState } from "@client/reducers/metadata-reducer";
import { State as ContactState } from "@client/reducers/contact-reducer";
import { State as AccountStatusState } from "@client/reducers/account-status-reducer";
import { State as RegisterEmailState } from "@client/reducers/register-email-reducer";
import { State as SubscriptionDetailsState } from "@client/reducers/subscription-details-reducer";
import { State as SubscriptionEditState } from "@client/reducers/subscription-edit-reducer";
import { State as RegisterSubscriptionState } from "@client/reducers/register-subscription-reducer";
import { State as RegisterAccessCodeState } from "@client/reducers/register-access-code-reducer";
import { State as RegisterContractState } from "@client/reducers/register-contract-reducer";
import { State as RegisterPendingState } from "@client/reducers/register-pending-reducer";
import { State as UpdatePasswordState } from "@client/reducers/update-password-reducer";
import { State as ResetPasswordState } from "@client/reducers/reset-password-reducer";
import { State as ForgotPasswordState } from "@client/reducers/forgot-password-reducer";
import { State as UserState } from "@client/reducers/user-reducer";
import { State as UserVehiclesState } from "@client/reducers/user-vehicles-reducer";
import { State as UserVehiclesDetailsState } from "@client/reducers/user-vehicles-details-reducer";
import { State as VehiclesFormState } from "@client/reducers/vehicles-form-reducer";
import { State as VehiclesState } from "@client/reducers/vehicles-reducer";
import { State as VehiclesDetailsState } from "@client/reducers/vehicles-details-reducer";
import { State as VehiclesAddState } from "@client/reducers/vehicles-add-reducer";
import { State as VehiclesEditState } from "@client/reducers/vehicles-edit-reducer";
import { State as SettingsState } from "@client/reducers/settings-reducer";
import { State as ServicesState, adapter as ServicesAdapter } from "@client/reducers/services-reducer";
import { State as PaymentSelectMethodState } from "@client/reducers/payment-select-method-reducer";
import { State as ServicesSelectState } from "@client/reducers/services-select-reducer";
import { State as AccessCodeState } from "@client/reducers/access-code-reducer";
import { State as ContractSearchState } from "@client/reducers/contract-search-reducer";
import { State as AccessCodeDetailsState } from "@client/reducers/access-code-details-reducer";
import { State as AccessCodeAddState } from "@client/reducers/access-code-add-reducer";
import { State as AdminConfigurationState } from "@client/reducers/admin-configuration-reducer";
import { State as AdminProfileState } from "@client/reducers/admin-profile-reducer";
import { State as AdminLogsState } from "@client/reducers/admin-logs-reducer";
import { State as AdminMailsState } from "@client/reducers/admin-mails-reducer";
import { State as AdminOrganizationFormState } from "@client/reducers/admin-organization-form-reducer";
import { State as TestState } from "@client/reducers/test-reducer";
import { State as TestGroupFormState } from "@client/reducers/test-group-form-reducer";
import { State as TestListState } from "@client/reducers/test-list-reducer";
import { State as TestFormState } from "@client/reducers/test-form-reducer";
import { State as TestGroupListState } from "@client/reducers/test-group-list-reducer";
import { State as AdminState } from "@client/reducers/admin-reducer";
import { State as AdminOrganizationListState } from "@client/reducers/admin-organization-list-reducer";
import { State as AdminGroupFormState } from "@client/reducers/admin-group-form-reducer";
import { State as AdminGroupMetasState } from "@client/reducers/admin-group-metas-reducer";
import { State as AdminGroupListState } from "@client/reducers/admin-group-list-reducer";
import { State as AdminContractListState } from "@client/reducers/admin-contract-list-reducer";
import { State as AdminServiceListState } from "@client/reducers/admin-service-list-reducer";
import { State as AdminMetasListState } from "@client/reducers/admin-metas-list-reducer";
import { State as AdminNotesFormState } from "@client/reducers/admin-notes-form-reducer";
import { State as AdminNotesListState } from "@client/reducers/admin-notes-list-reducer";
import { State as AdminAccessCodeSearchState } from "@client/reducers/admin-access-code-search-reducer";
import { State as AdminUsersFormState } from "@client/reducers/admin-users-form-reducer";
import { State as AdminUsersListState } from "@client/reducers/admin-users-list-reducer";
import { State as AdminCodeListState } from "@client/reducers/admin-code-list-reducer";
import { State as AdminMetasFormState } from "@client/reducers/admin-metas-form-reducer";
import { State as AdminCodeImportState } from "@client/reducers/admin-code-import-reducer";
import { State as AdminCodeFormState } from "@client/reducers/admin-code-form-reducer";
import { State as AdminServiceFormState } from "@client/reducers/admin-service-form-reducer";
import { State as AdminFaqListGroupState } from "@client/reducers/admin-faq-list-group-reducer";
import { State as AdminFaqListState } from "@client/reducers/admin-faq-list-reducer";
import { State as AdminFaqGroupEditState } from "@client/reducers/admin-faq-group-edit-reducer";
import { State as AdminFaqEditState } from "@client/reducers/admin-faq-edit-reducer";
import { State as PpaAddState } from "@client/reducers/ppa-add-reducer";
import { State as MonerisPaypageState } from "@client/reducers/moneris-paypage-reducer";
import { State as MonerisVaultState } from "@client/reducers/moneris-vault-reducer";
import * as fromNetworkStatus from "@client/selectors/network-status-selectors";
import * as fromPopup from "@client/selectors/popup-selectors";
import * as fromEmailEdit from "@client/selectors/email-edit-selectors";
import * as fromAuth from "@client/selectors/auth-selectors";
import * as fromOob from "@client/selectors/oob-selectors";
import * as fromSession from "@client/selectors/session-selectors";
import * as fromFaq from "@client/selectors/faq-selectors";
import * as fromOrganization from "@client/selectors/organization-selectors";
import * as fromSectors from "@client/selectors/sectors-selectors";
import * as fromContract from "@client/selectors/contract-selectors";
import * as fromContractCancel from "@client/selectors/contract-cancel-selectors";
import * as fromContractCreate from "@client/selectors/contract-create-selectors";
import * as fromContractDetails from "@client/selectors/contract-details-selectors";
import * as fromContractConditionsService from "@client/selectors/contract-conditions-service-selectors";
import * as fromContractConditionsPayment from "@client/selectors/contract-conditions-payment-selectors";
import * as fromParks from "@client/selectors/parks-selectors";
import * as fromParksSelect from "@client/selectors/parks-select-selectors";
import * as fromCalendar from "@client/selectors/calendar-selectors";
import * as fromLayout from "@client/selectors/layout-selectors";
import * as fromProfile from "@client/selectors/profile-selectors";
import * as fromProfileDetails from "@client/selectors/profile-details-selectors";
import * as fromProfileEdit from "@client/selectors/profile-edit-selectors";
import * as fromSubscription from "@client/selectors/subscription-selectors";
import * as fromRegister from "@client/selectors/register-selectors";
import * as fromLogin from "@client/selectors/login-selectors";
import * as fromMetadata from "@client/selectors/metadata-selectors";
import * as fromContact from "@client/selectors/contact-selectors";
import * as fromAccountStatus from "@client/selectors/account-status-selectors";
import * as fromRegisterEmail from "@client/selectors/register-email-selectors";
import * as fromSubscriptionDetails from "@client/selectors/subscription-details-selectors";
import * as fromSubscriptionEdit from "@client/selectors/subscription-edit-selectors";
import * as fromRegisterSubscription from "@client/selectors/register-subscription-selectors";
import * as fromRegisterAccessCode from "@client/selectors/register-access-code-selectors";
import * as fromRegisterContract from "@client/selectors/register-contract-selectors";
import * as fromRegisterPending from "@client/selectors/register-pending-selectors";
import * as fromUpdatePassword from "@client/selectors/update-password-selectors";
import * as fromResetPassword from "@client/selectors/reset-password-selectors";
import * as fromForgotPassword from "@client/selectors/forgot-password-selectors";
import * as fromUser from "@client/selectors/user-selectors";
import * as fromUserVehicles from "@client/selectors/user-vehicles-selectors";
import * as fromUserVehiclesDetails from "@client/selectors/user-vehicles-details-selectors";
import * as fromVehiclesForm from "@client/selectors/vehicles-form-selectors";
import * as fromVehicles from "@client/selectors/vehicles-selectors";
import * as fromVehiclesDetails from "@client/selectors/vehicles-details-selectors";
import * as fromVehiclesAdd from "@client/selectors/vehicles-add-selectors";
import * as fromVehiclesEdit from "@client/selectors/vehicles-edit-selectors";
import * as fromSettings from "@client/selectors/settings-selectors";
import * as fromServices from "@client/selectors/services-selectors";
import * as fromPaymentSelectMethod from "@client/selectors/payment-select-method-selectors";
import * as fromServicesSelect from "@client/selectors/services-select-selectors";
import * as fromAccessCode from "@client/selectors/access-code-selectors";
import * as fromContractSearch from "@client/selectors/contract-search-selectors";
import * as fromAccessCodeDetails from "@client/selectors/access-code-details-selectors";
import * as fromAccessCodeAdd from "@client/selectors/access-code-add-selectors";
import * as fromAdminConfiguration from "@client/selectors/admin-configuration-selectors";
import * as fromAdminProfile from "@client/selectors/admin-profile-selectors";
import * as fromAdminLogs from "@client/selectors/admin-logs-selectors";
import * as fromAdminMails from "@client/selectors/admin-mails-selectors";
import * as fromAdminOrganizationForm from "@client/selectors/admin-organization-form-selectors";
import * as fromTest from "@client/selectors/test-selectors";
import * as fromTestGroupForm from "@client/selectors/test-group-form-selectors";
import * as fromTestList from "@client/selectors/test-list-selectors";
import * as fromTestForm from "@client/selectors/test-form-selectors";
import * as fromTestGroupList from "@client/selectors/test-group-list-selectors";
import * as fromAdmin from "@client/selectors/admin-selectors";
import * as fromAdminOrganizationList from "@client/selectors/admin-organization-list-selectors";
import * as fromAdminGroupForm from "@client/selectors/admin-group-form-selectors";
import * as fromAdminGroupMetas from "@client/selectors/admin-group-metas-selectors";
import * as fromAdminGroupList from "@client/selectors/admin-group-list-selectors";
import * as fromAdminContractList from "@client/selectors/admin-contract-list-selectors";
import * as fromAdminServiceList from "@client/selectors/admin-service-list-selectors";
import * as fromAdminMetasList from "@client/selectors/admin-metas-list-selectors";
import * as fromAdminNotesForm from "@client/selectors/admin-notes-form-selectors";
import * as fromAdminNotesList from "@client/selectors/admin-notes-list-selectors";
import * as fromAdminAccessCodeSearch from "@client/selectors/admin-access-code-search-selectors";
import * as fromAdminUsersForm from "@client/selectors/admin-users-form-selectors";
import * as fromAdminUsersList from "@client/selectors/admin-users-list-selectors";
import * as fromAdminCodeList from "@client/selectors/admin-code-list-selectors";
import * as fromAdminMetasForm from "@client/selectors/admin-metas-form-selectors";
import * as fromAdminCodeImport from "@client/selectors/admin-code-import-selectors";
import * as fromAdminCodeForm from "@client/selectors/admin-code-form-selectors";
import * as fromAdminServiceForm from "@client/selectors/admin-service-form-selectors";
import * as fromAdminFaqListGroup from "@client/selectors/admin-faq-list-group-selectors";
import * as fromAdminFaqList from "@client/selectors/admin-faq-list-selectors";
import * as fromAdminFaqGroupEdit from "@client/selectors/admin-faq-group-edit-selectors";
import * as fromAdminFaqEdit from "@client/selectors/admin-faq-edit-selectors";
import * as fromPpaAdd from "@client/selectors/ppa-add-selectors";
import * as fromMonerisPaypage from "@client/selectors/moneris-paypage-selectors";
import * as fromMonerisVault from "@client/selectors/moneris-vault-selectors";
import { EmailEditForm, FaqDataInfo, FaqGroupInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ContractCostInfo, ServiceInfo, ServiceMetaInfo, SubscriptionService, SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo, Vehicule, LayoutScreenInfo, OrganizationConfigInfo, NodeItem, ProfileInfo, ProfileEditForm, RegisterEmailForm, AccessCodeSearchInfo, ContractForm, UpdatePasswordForm, ResetPasswordForm, ForgotPasswordForm, UserAccountStatus, PaymentInfo, AccessCodeGroupInfo, AccessCodeServiceInfo, AccessCodeMetasInfo, AccessCodeMetasConditions, AccessCodeServiceCustomFields, MonerisConfiguration, SystemConfiguration, TestGroupInfo, TestInfo, AccessCodeServiceCustomFieldsValue, AccessCodeInfo, PaymentPPAInfo } from "@client/utils/shared-constants";
export const getRouterState: MemoizedSelector<RootState, RouterReducerState> = createFeatureSelector<RouterReducerState>("__router");
export const getNetworkStatusState: MemoizedSelector<RootState, NetworkStatusState> = createFeatureSelector<NetworkStatusState>("__networkStatus");
export const getPopupState: MemoizedSelector<RootState, PopupState> = createFeatureSelector<PopupState>("__popup");
export const getEmailEditState: MemoizedSelector<RootState, EmailEditState> = createFeatureSelector<EmailEditState>("__emailEdit");
export const getAuthState: MemoizedSelector<RootState, AuthState> = createFeatureSelector<AuthState>("__auth");
export const getOobState: MemoizedSelector<RootState, OobState> = createFeatureSelector<OobState>("__oob");
export const getSessionState: MemoizedSelector<RootState, SessionState> = createFeatureSelector<SessionState>("__session");
export const getFaqState: MemoizedSelector<RootState, FaqState> = createFeatureSelector<FaqState>("__faq");
export const getOrganizationState: MemoizedSelector<RootState, OrganizationState> = createFeatureSelector<OrganizationState>("__organization");
export const getSectorsState: MemoizedSelector<RootState, SectorsState> = createFeatureSelector<SectorsState>("__sectors");
export const getContractState: MemoizedSelector<RootState, ContractState> = createFeatureSelector<ContractState>("__contract");
export const getContractCancelState: MemoizedSelector<RootState, ContractCancelState> = createFeatureSelector<ContractCancelState>("__contractCancel");
export const getContractCreateState: MemoizedSelector<RootState, ContractCreateState> = createFeatureSelector<ContractCreateState>("__contractCreate");
export const getContractDetailsState: MemoizedSelector<RootState, ContractDetailsState> = createFeatureSelector<ContractDetailsState>("__contractDetails");
export const getContractConditionsServiceState: MemoizedSelector<RootState, ContractConditionsServiceState> = createFeatureSelector<ContractConditionsServiceState>("__contractConditionsService");
export const getContractConditionsPaymentState: MemoizedSelector<RootState, ContractConditionsPaymentState> = createFeatureSelector<ContractConditionsPaymentState>("__contractConditionsPayment");
export const getParksState: MemoizedSelector<RootState, ParksState> = createFeatureSelector<ParksState>("__parks");
export const getParksSelectState: MemoizedSelector<RootState, ParksSelectState> = createFeatureSelector<ParksSelectState>("__parksSelect");
export const getCalendarState: MemoizedSelector<RootState, CalendarState> = createFeatureSelector<CalendarState>("__calendar");
export const getLayoutState: MemoizedSelector<RootState, LayoutState> = createFeatureSelector<LayoutState>("__layout");
export const getProfileState: MemoizedSelector<RootState, ProfileState> = createFeatureSelector<ProfileState>("__profile");
export const getProfileDetailsState: MemoizedSelector<RootState, ProfileDetailsState> = createFeatureSelector<ProfileDetailsState>("__profileDetails");
export const getProfileEditState: MemoizedSelector<RootState, ProfileEditState> = createFeatureSelector<ProfileEditState>("__profileEdit");
export const getSubscriptionState: MemoizedSelector<RootState, SubscriptionState> = createFeatureSelector<SubscriptionState>("__subscription");
export const getRegisterState: MemoizedSelector<RootState, RegisterState> = createFeatureSelector<RegisterState>("__register");
export const getLoginState: MemoizedSelector<RootState, LoginState> = createFeatureSelector<LoginState>("__login");
export const getMetadataState: MemoizedSelector<RootState, MetadataState> = createFeatureSelector<MetadataState>("__metadata");
export const getContactState: MemoizedSelector<RootState, ContactState> = createFeatureSelector<ContactState>("__contact");
export const getAccountStatusState: MemoizedSelector<RootState, AccountStatusState> = createFeatureSelector<AccountStatusState>("__accountStatus");
export const getRegisterEmailState: MemoizedSelector<RootState, RegisterEmailState> = createFeatureSelector<RegisterEmailState>("__registerEmail");
export const getSubscriptionDetailsState: MemoizedSelector<RootState, SubscriptionDetailsState> = createFeatureSelector<SubscriptionDetailsState>("__subscriptionDetails");
export const getSubscriptionEditState: MemoizedSelector<RootState, SubscriptionEditState> = createFeatureSelector<SubscriptionEditState>("__subscriptionEdit");
export const getRegisterSubscriptionState: MemoizedSelector<RootState, RegisterSubscriptionState> = createFeatureSelector<RegisterSubscriptionState>("__registerSubscription");
export const getRegisterAccessCodeState: MemoizedSelector<RootState, RegisterAccessCodeState> = createFeatureSelector<RegisterAccessCodeState>("__registerAccessCode");
export const getRegisterContractState: MemoizedSelector<RootState, RegisterContractState> = createFeatureSelector<RegisterContractState>("__registerContract");
export const getRegisterPendingState: MemoizedSelector<RootState, RegisterPendingState> = createFeatureSelector<RegisterPendingState>("__registerPending");
export const getUpdatePasswordState: MemoizedSelector<RootState, UpdatePasswordState> = createFeatureSelector<UpdatePasswordState>("__updatePassword");
export const getResetPasswordState: MemoizedSelector<RootState, ResetPasswordState> = createFeatureSelector<ResetPasswordState>("__resetPassword");
export const getForgotPasswordState: MemoizedSelector<RootState, ForgotPasswordState> = createFeatureSelector<ForgotPasswordState>("__forgotPassword");
export const getUserState: MemoizedSelector<RootState, UserState> = createFeatureSelector<UserState>("__user");
export const getUserVehiclesState: MemoizedSelector<RootState, UserVehiclesState> = createFeatureSelector<UserVehiclesState>("__userVehicles");
export const getUserVehiclesDetailsState: MemoizedSelector<RootState, UserVehiclesDetailsState> = createFeatureSelector<UserVehiclesDetailsState>("__userVehiclesDetails");
export const getVehiclesFormState: MemoizedSelector<RootState, VehiclesFormState> = createFeatureSelector<VehiclesFormState>("__vehiclesForm");
export const getVehiclesState: MemoizedSelector<RootState, VehiclesState> = createFeatureSelector<VehiclesState>("__vehicles");
export const getVehiclesDetailsState: MemoizedSelector<RootState, VehiclesDetailsState> = createFeatureSelector<VehiclesDetailsState>("__vehiclesDetails");
export const getVehiclesAddState: MemoizedSelector<RootState, VehiclesAddState> = createFeatureSelector<VehiclesAddState>("__vehiclesAdd");
export const getVehiclesEditState: MemoizedSelector<RootState, VehiclesEditState> = createFeatureSelector<VehiclesEditState>("__vehiclesEdit");
export const getSettingsState: MemoizedSelector<RootState, SettingsState> = createFeatureSelector<SettingsState>("__settings");
export const getServicesState: MemoizedSelector<RootState, ServicesState> = createFeatureSelector<ServicesState>("__services");
export const getPaymentSelectMethodState: MemoizedSelector<RootState, PaymentSelectMethodState> = createFeatureSelector<PaymentSelectMethodState>("__paymentSelectMethod");
export const getServicesSelectState: MemoizedSelector<RootState, ServicesSelectState> = createFeatureSelector<ServicesSelectState>("__servicesSelect");
export const getAccessCodeState: MemoizedSelector<RootState, AccessCodeState> = createFeatureSelector<AccessCodeState>("__accessCode");
export const getContractSearchState: MemoizedSelector<RootState, ContractSearchState> = createFeatureSelector<ContractSearchState>("__contractSearch");
export const getAccessCodeDetailsState: MemoizedSelector<RootState, AccessCodeDetailsState> = createFeatureSelector<AccessCodeDetailsState>("__accessCodeDetails");
export const getAccessCodeAddState: MemoizedSelector<RootState, AccessCodeAddState> = createFeatureSelector<AccessCodeAddState>("__accessCodeAdd");
export const getAdminConfigurationState: MemoizedSelector<RootState, AdminConfigurationState> = createFeatureSelector<AdminConfigurationState>("__adminConfiguration");
export const getAdminProfileState: MemoizedSelector<RootState, AdminProfileState> = createFeatureSelector<AdminProfileState>("__adminProfile");
export const getAdminLogsState: MemoizedSelector<RootState, AdminLogsState> = createFeatureSelector<AdminLogsState>("__adminLogs");
export const getAdminMailsState: MemoizedSelector<RootState, AdminMailsState> = createFeatureSelector<AdminMailsState>("__adminMails");
export const getAdminOrganizationFormState: MemoizedSelector<RootState, AdminOrganizationFormState> = createFeatureSelector<AdminOrganizationFormState>("__adminOrganizationForm");
export const getTestState: MemoizedSelector<RootState, TestState> = createFeatureSelector<TestState>("__test");
export const getTestGroupFormState: MemoizedSelector<RootState, TestGroupFormState> = createFeatureSelector<TestGroupFormState>("__testGroupForm");
export const getTestListState: MemoizedSelector<RootState, TestListState> = createFeatureSelector<TestListState>("__testList");
export const getTestFormState: MemoizedSelector<RootState, TestFormState> = createFeatureSelector<TestFormState>("__testForm");
export const getTestGroupListState: MemoizedSelector<RootState, TestGroupListState> = createFeatureSelector<TestGroupListState>("__testGroupList");
export const getAdminState: MemoizedSelector<RootState, AdminState> = createFeatureSelector<AdminState>("__admin");
export const getAdminOrganizationListState: MemoizedSelector<RootState, AdminOrganizationListState> = createFeatureSelector<AdminOrganizationListState>("__adminOrganizationList");
export const getAdminGroupFormState: MemoizedSelector<RootState, AdminGroupFormState> = createFeatureSelector<AdminGroupFormState>("__adminGroupForm");
export const getAdminGroupMetasState: MemoizedSelector<RootState, AdminGroupMetasState> = createFeatureSelector<AdminGroupMetasState>("__adminGroupMetas");
export const getAdminGroupListState: MemoizedSelector<RootState, AdminGroupListState> = createFeatureSelector<AdminGroupListState>("__adminGroupList");
export const getAdminContractListState: MemoizedSelector<RootState, AdminContractListState> = createFeatureSelector<AdminContractListState>("__adminContractList");
export const getAdminServiceListState: MemoizedSelector<RootState, AdminServiceListState> = createFeatureSelector<AdminServiceListState>("__adminServiceList");
export const getAdminMetasListState: MemoizedSelector<RootState, AdminMetasListState> = createFeatureSelector<AdminMetasListState>("__adminMetasList");
export const getAdminNotesFormState: MemoizedSelector<RootState, AdminNotesFormState> = createFeatureSelector<AdminNotesFormState>("__adminNotesForm");
export const getAdminNotesListState: MemoizedSelector<RootState, AdminNotesListState> = createFeatureSelector<AdminNotesListState>("__adminNotesList");
export const getAdminAccessCodeSearchState: MemoizedSelector<RootState, AdminAccessCodeSearchState> = createFeatureSelector<AdminAccessCodeSearchState>("__adminAccessCodeSearch");
export const getAdminUsersFormState: MemoizedSelector<RootState, AdminUsersFormState> = createFeatureSelector<AdminUsersFormState>("__adminUsersForm");
export const getAdminUsersListState: MemoizedSelector<RootState, AdminUsersListState> = createFeatureSelector<AdminUsersListState>("__adminUsersList");
export const getAdminCodeListState: MemoizedSelector<RootState, AdminCodeListState> = createFeatureSelector<AdminCodeListState>("__adminCodeList");
export const getAdminMetasFormState: MemoizedSelector<RootState, AdminMetasFormState> = createFeatureSelector<AdminMetasFormState>("__adminMetasForm");
export const getAdminCodeImportState: MemoizedSelector<RootState, AdminCodeImportState> = createFeatureSelector<AdminCodeImportState>("__adminCodeImport");
export const getAdminCodeFormState: MemoizedSelector<RootState, AdminCodeFormState> = createFeatureSelector<AdminCodeFormState>("__adminCodeForm");
export const getAdminServiceFormState: MemoizedSelector<RootState, AdminServiceFormState> = createFeatureSelector<AdminServiceFormState>("__adminServiceForm");
export const getAdminFaqListGroupState: MemoizedSelector<RootState, AdminFaqListGroupState> = createFeatureSelector<AdminFaqListGroupState>("__adminFaqListGroup");
export const getAdminFaqListState: MemoizedSelector<RootState, AdminFaqListState> = createFeatureSelector<AdminFaqListState>("__adminFaqList");
export const getAdminFaqGroupEditState: MemoizedSelector<RootState, AdminFaqGroupEditState> = createFeatureSelector<AdminFaqGroupEditState>("__adminFaqGroupEdit");
export const getAdminFaqEditState: MemoizedSelector<RootState, AdminFaqEditState> = createFeatureSelector<AdminFaqEditState>("__adminFaqEdit");
export const getPpaAddState: MemoizedSelector<RootState, PpaAddState> = createFeatureSelector<PpaAddState>("__ppaAdd");
export const getMonerisPaypageState: MemoizedSelector<RootState, MonerisPaypageState> = createFeatureSelector<MonerisPaypageState>("__monerisPaypage");
export const getMonerisVaultState: MemoizedSelector<RootState, MonerisVaultState> = createFeatureSelector<MonerisVaultState>("__monerisVault");
export const getMonerisVaultIsActive: MemoizedSelector<RootState, boolean> = createSelector(getMonerisVaultState, fromMonerisVault.getIsActive);
export const getMonerisVaultMessage: MemoizedSelector<RootState, string | null> = createSelector(getMonerisVaultState, fromMonerisVault.getMessage);
export const getMonerisVaultHasError: MemoizedSelector<RootState, boolean> = createSelector(getMonerisVaultState, fromMonerisVault.getHasError);
export const getMonerisVaultHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getMonerisVaultState, fromMonerisVault.getHasSuccess);
export const getMonerisVaultIsVisible: MemoizedSelector<RootState, boolean> = createSelector(getMonerisVaultState, fromMonerisVault.getIsVisible);
export const getMonerisVaultResult: MemoizedSelector<RootState, any | null> = createSelector(getMonerisVaultState, fromMonerisVault.getResult);
export const getMonerisPaypageIsActive: MemoizedSelector<RootState, boolean> = createSelector(getMonerisPaypageState, fromMonerisPaypage.getIsActive);
export const getMonerisPaypageMessage: MemoizedSelector<RootState, string | null> = createSelector(getMonerisPaypageState, fromMonerisPaypage.getMessage);
export const getMonerisPaypageHasError: MemoizedSelector<RootState, boolean> = createSelector(getMonerisPaypageState, fromMonerisPaypage.getHasError);
export const getMonerisPaypageHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getMonerisPaypageState, fromMonerisPaypage.getHasSuccess);
export const getMonerisPaypageIsVisible: MemoizedSelector<RootState, boolean> = createSelector(getMonerisPaypageState, fromMonerisPaypage.getIsVisible);
export const getMonerisPaypageResult: MemoizedSelector<RootState, any | null> = createSelector(getMonerisPaypageState, fromMonerisPaypage.getResult);
export const getPpaAddIsActive: MemoizedSelector<RootState, boolean> = createSelector(getPpaAddState, fromPpaAdd.getIsActive);
export const getPpaAddMessage: MemoizedSelector<RootState, string | null> = createSelector(getPpaAddState, fromPpaAdd.getMessage);
export const getPpaAddHasError: MemoizedSelector<RootState, boolean> = createSelector(getPpaAddState, fromPpaAdd.getHasError);
export const getPpaAddHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getPpaAddState, fromPpaAdd.getHasSuccess);
export const getPpaAddIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getPpaAddState, fromPpaAdd.getIsLoading);
export const getPpaAddIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getPpaAddState, fromPpaAdd.getIsCompleted);
export const getPpaAddForm: MemoizedSelector<RootState, PaymentPPAInfo> = createSelector(getPpaAddState, fromPpaAdd.getForm);
export const getPpaAddFormEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getPpaAddState, fromPpaAdd.getFormEffectiveDate);
export const getPpaAddFormInstitutionName: MemoizedSelector<RootState, string> = createSelector(getPpaAddState, fromPpaAdd.getFormInstitutionName);
export const getPpaAddFormFinancialInstitutionId: MemoizedSelector<RootState, number> = createSelector(getPpaAddState, fromPpaAdd.getFormFinancialInstitutionId);
export const getPpaAddFormFolioNo: MemoizedSelector<RootState, number> = createSelector(getPpaAddState, fromPpaAdd.getFormFolioNo);
export const getPpaAddFormDownloadUrl: MemoizedSelector<RootState, string> = createSelector(getPpaAddState, fromPpaAdd.getFormDownloadUrl);
export const getPpaAddFormTransitNo: MemoizedSelector<RootState, number> = createSelector(getPpaAddState, fromPpaAdd.getFormTransitNo);
export const getAdminFaqEditIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getIsActive);
export const getAdminFaqEditMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getMessage);
export const getAdminFaqEditHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getHasError);
export const getAdminFaqEditHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getHasSuccess);
export const getAdminFaqEditIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getIsLoading);
export const getAdminFaqEditData: MemoizedSelector<RootState, FaqInfo | null> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getData);
export const getAdminFaqEditDataBody: MemoizedSelector<RootState, string> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataBody);
export const getAdminFaqEditDataCollapse: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataCollapse);
export const getAdminFaqEditDataId: MemoizedSelector<RootState, string> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataId);
export const getAdminFaqEditDataTitle: MemoizedSelector<RootState, string> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataTitle);
export const getAdminFaqEditDataOrder: MemoizedSelector<RootState, any> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataOrder);
export const getAdminFaqEditDataVisible: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataVisible);
export const getAdminFaqEditDataOrganization: MemoizedSelector<RootState, string | null> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getDataOrganization);
export const getAdminFaqEditIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqEditState, fromAdminFaqEdit.getIsEdit);
export const getAdminFaqGroupEditIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getIsActive);
export const getAdminFaqGroupEditMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getMessage);
export const getAdminFaqGroupEditHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getHasError);
export const getAdminFaqGroupEditHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getHasSuccess);
export const getAdminFaqGroupEditIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getIsLoading);
export const getAdminFaqGroupEditData: MemoizedSelector<RootState, FaqGroupInfo | null> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getData);
export const getAdminFaqGroupEditDataName: MemoizedSelector<RootState, string> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataName);
export const getAdminFaqGroupEditDataCollapse: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataCollapse);
export const getAdminFaqGroupEditDataId: MemoizedSelector<RootState, string> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataId);
export const getAdminFaqGroupEditDataTitle: MemoizedSelector<RootState, string> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataTitle);
export const getAdminFaqGroupEditDataOrder: MemoizedSelector<RootState, any> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataOrder);
export const getAdminFaqGroupEditDataVisible: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataVisible);
export const getAdminFaqGroupEditDataOrganization: MemoizedSelector<RootState, string | null> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getDataOrganization);
export const getAdminFaqGroupEditIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqGroupEditState, fromAdminFaqGroupEdit.getIsEdit);
export const getAdminFaqListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListState, fromAdminFaqList.getIsActive);
export const getAdminFaqListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminFaqListState, fromAdminFaqList.getMessage);
export const getAdminFaqListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListState, fromAdminFaqList.getHasError);
export const getAdminFaqListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListState, fromAdminFaqList.getHasSuccess);
export const getAdminFaqListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListState, fromAdminFaqList.getIsLoading);
export const getAdminFaqListCollection: MemoizedSelector<RootState, FaqInfo[]> = createSelector(getAdminFaqListState, fromAdminFaqList.getCollection);
export const getAdminFaqListSelectedFaqId: MemoizedSelector<RootState, string> = createSelector(getAdminFaqListState, fromAdminFaqList.getSelectedFaqId);
export const getAdminFaqListGroupIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getIsActive);
export const getAdminFaqListGroupMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getMessage);
export const getAdminFaqListGroupHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getHasError);
export const getAdminFaqListGroupHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getHasSuccess);
export const getAdminFaqListGroupIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getIsLoading);
export const getAdminFaqListGroupCollection: MemoizedSelector<RootState, FaqGroupInfo[]> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getCollection);
export const getAdminFaqListGroupSelectedFaqId: MemoizedSelector<RootState, string> = createSelector(getAdminFaqListGroupState, fromAdminFaqListGroup.getSelectedFaqId);
export const getAdminServiceFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getIsActive);
export const getAdminServiceFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getMessage);
export const getAdminServiceFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getHasError);
export const getAdminServiceFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getHasSuccess);
export const getAdminServiceFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getIsLoading);
export const getAdminServiceFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getIsEdit);
export const getAdminServiceFormForm: MemoizedSelector<RootState, AccessCodeServiceInfo | null> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getForm);
export const getAdminServiceFormFormGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormGroupCode);
export const getAdminServiceFormFormOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormOrganizationCode);
export const getAdminServiceFormFormServiceId: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceId);
export const getAdminServiceFormFormParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormParkIdentificationNo);
export const getAdminServiceFormFormServiceEntity: MemoizedSelector<RootState, ServiceInfo> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntity);
export const getAdminServiceFormFormServiceEntityPeriod: MemoizedSelector<RootState, any> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityPeriod);
export const getAdminServiceFormFormServiceEntityAvailableFrom: MemoizedSelector<RootState, string | null> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityAvailableFrom);
export const getAdminServiceFormFormServiceEntityAvailableServiceId: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityAvailableServiceId);
export const getAdminServiceFormFormServiceEntityAvailableUntil: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityAvailableUntil);
export const getAdminServiceFormFormServiceEntityCancelationNotice: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityCancelationNotice);
export const getAdminServiceFormFormServiceEntityCancelationNoticeTimeUnit: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityCancelationNoticeTimeUnit);
export const getAdminServiceFormFormServiceEntityDuration: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityDuration);
export const getAdminServiceFormFormServiceEntityDurationTimeUnit: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityDurationTimeUnit);
export const getAdminServiceFormFormServiceEntityIsBankingCardPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsBankingCardPaymentAccepted);
export const getAdminServiceFormFormServiceEntityIsCancelable: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsCancelable);
export const getAdminServiceFormFormServiceEntityIsCancelationFirstDayOfMonth: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsCancelationFirstDayOfMonth);
export const getAdminServiceFormFormServiceEntityIsDasPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsDasPaymentAccepted);
export const getAdminServiceFormFormServiceEntityIsFinancialInstitutionPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsFinancialInstitutionPaymentAccepted);
export const getAdminServiceFormFormServiceEntityIsFixedDuration: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsFixedDuration);
export const getAdminServiceFormFormServiceEntityIsPpaPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsPpaPaymentAccepted);
export const getAdminServiceFormFormServiceEntityIsSubscriptionAvailable: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsSubscriptionAvailable);
export const getAdminServiceFormFormServiceEntityIsTax1Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsTax1Exempted);
export const getAdminServiceFormFormServiceEntityIsTax2Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsTax2Exempted);
export const getAdminServiceFormFormServiceEntityIsTax3Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityIsTax3Exempted);
export const getAdminServiceFormFormServiceEntityOnSaleFrom: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityOnSaleFrom);
export const getAdminServiceFormFormServiceEntityOnSaleUntil: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityOnSaleUntil);
export const getAdminServiceFormFormServiceEntityParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityParkIdentificationNo);
export const getAdminServiceFormFormServiceEntityPartialMonthCalculation: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityPartialMonthCalculation);
export const getAdminServiceFormFormServiceEntityPrice: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityPrice);
export const getAdminServiceFormFormServiceEntitySectorId: MemoizedSelector<RootState, number> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntitySectorId);
export const getAdminServiceFormFormServiceEntityServiceBillingType: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityServiceBillingType);
export const getAdminServiceFormFormServiceEntityServiceDescription: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityServiceDescription);
export const getAdminServiceFormFormServiceEntityServiceStartRule: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityServiceStartRule);
export const getAdminServiceFormFormServiceEntityServiceType: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityServiceType);
export const getAdminServiceFormFormServiceEntityMeta: MemoizedSelector<RootState, ServiceMetaInfo> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityMeta);
export const getAdminServiceFormFormServiceEntityMetaNote: MemoizedSelector<RootState, string> = createSelector(getAdminServiceFormState, fromAdminServiceForm.getFormServiceEntityMetaNote);
export const getAdminCodeFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getIsActive);
export const getAdminCodeFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getMessage);
export const getAdminCodeFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getHasError);
export const getAdminCodeFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getHasSuccess);
export const getAdminCodeFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getIsLoading);
export const getAdminCodeFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getIsEdit);
export const getAdminCodeFormForm: MemoizedSelector<RootState, AccessCodeInfo | null> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getForm);
export const getAdminCodeFormFormCompanyCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormCompanyCode);
export const getAdminCodeFormFormCompanyName: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormCompanyName);
export const getAdminCodeFormFormFirstName: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormFirstName);
export const getAdminCodeFormFormId: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormId);
export const getAdminCodeFormFormLastName: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormLastName);
export const getAdminCodeFormFormUsed: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormUsed);
export const getAdminCodeFormFormUserCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormUserCode);
export const getAdminCodeFormFormOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormOrganizationCode);
export const getAdminCodeFormFormGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormGroupCode);
export const getAdminCodeFormFormSingle: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeFormState, fromAdminCodeForm.getFormSingle);
export const getAdminCodeImportIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeImportState, fromAdminCodeImport.getIsActive);
export const getAdminCodeImportMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminCodeImportState, fromAdminCodeImport.getMessage);
export const getAdminCodeImportHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeImportState, fromAdminCodeImport.getHasError);
export const getAdminCodeImportHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeImportState, fromAdminCodeImport.getHasSuccess);
export const getAdminCodeImportIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeImportState, fromAdminCodeImport.getIsLoading);
export const getAdminMetasFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getIsActive);
export const getAdminMetasFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getMessage);
export const getAdminMetasFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getHasError);
export const getAdminMetasFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getHasSuccess);
export const getAdminMetasFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getIsLoading);
export const getAdminMetasFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getIsEdit);
export const getAdminMetasFormImportData: MemoizedSelector<RootState, any> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getImportData);
export const getAdminMetasFormForm: MemoizedSelector<RootState, AccessCodeServiceCustomFields> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getForm);
export const getAdminMetasFormFormLabel: MemoizedSelector<RootState, string> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormLabel);
export const getAdminMetasFormFormName: MemoizedSelector<RootState, string> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormName);
export const getAdminMetasFormFormType: MemoizedSelector<RootState, string> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormType);
export const getAdminMetasFormFormValues: MemoizedSelector<RootState, AccessCodeServiceCustomFieldsValue[]> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormValues);
export const getAdminMetasFormFormRequired: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormRequired);
export const getAdminMetasFormFormOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormOrganizationCode);
export const getAdminMetasFormFormGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormGroupCode);
export const getAdminMetasFormFormOrder: MemoizedSelector<RootState, number> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getFormOrder);
export const getAdminMetasFormSelectedOption: MemoizedSelector<RootState, number | null> = createSelector(getAdminMetasFormState, fromAdminMetasForm.getSelectedOption);
export const getAdminCodeListHasImport: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getHasImport);
export const getAdminCodeListSelectedCodeIndex: MemoizedSelector<RootState, number | null> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelectedCodeIndex);
export const getAdminCodeListSelectedFirstnameIndex: MemoizedSelector<RootState, number | null> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelectedFirstnameIndex);
export const getAdminCodeListSelectedLastnameIndex: MemoizedSelector<RootState, number | null> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelectedLastnameIndex);
export const getAdminCodeListExtraIndex: MemoizedSelector<RootState, any | null> = createSelector(getAdminCodeListState, fromAdminCodeList.getExtraIndex);
export const getAdminCodeListCanUpdate: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getCanUpdate);
export const getAdminCodeListCanDelete: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getCanDelete);
export const getAdminCodeListCanAdd: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getCanAdd);
export const getAdminCodeListIgnoreHeader: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getIgnoreHeader);
export const getAdminCodeListImportStats: MemoizedSelector<RootState, {
    total: number;
    add: number;
    update: number;
    delete: number;
}> = createSelector(getAdminCodeListState, fromAdminCodeList.getImportStats);
export const getAdminCodeListImportStatsTotal: MemoizedSelector<RootState, number> = createSelector(getAdminCodeListState, fromAdminCodeList.getImportStatsTotal);
export const getAdminCodeListImportStatsAdd: MemoizedSelector<RootState, number> = createSelector(getAdminCodeListState, fromAdminCodeList.getImportStatsAdd);
export const getAdminCodeListImportStatsUpdate: MemoizedSelector<RootState, number> = createSelector(getAdminCodeListState, fromAdminCodeList.getImportStatsUpdate);
export const getAdminCodeListImportStatsDelete: MemoizedSelector<RootState, number> = createSelector(getAdminCodeListState, fromAdminCodeList.getImportStatsDelete);
export const getAdminCodeListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getIsActive);
export const getAdminCodeListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminCodeListState, fromAdminCodeList.getMessage);
export const getAdminCodeListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getHasError);
export const getAdminCodeListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getHasSuccess);
export const getAdminCodeListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getIsLoading);
export const getAdminCodeListData: MemoizedSelector<RootState, AccessCodeInfo[]> = createSelector(getAdminCodeListState, fromAdminCodeList.getData);
export const getAdminCodeListImportData: MemoizedSelector<RootState, any> = createSelector(getAdminCodeListState, fromAdminCodeList.getImportData);
export const getAdminCodeListSelecteAccessCode: MemoizedSelector<RootState, AccessCodeInfo | null> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCode);
export const getAdminCodeListSelecteAccessCodeCompanyCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeCompanyCode);
export const getAdminCodeListSelecteAccessCodeCompanyName: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeCompanyName);
export const getAdminCodeListSelecteAccessCodeFirstName: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeFirstName);
export const getAdminCodeListSelecteAccessCodeId: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeId);
export const getAdminCodeListSelecteAccessCodeLastName: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeLastName);
export const getAdminCodeListSelecteAccessCodeUsed: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeUsed);
export const getAdminCodeListSelecteAccessCodeUserCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeUserCode);
export const getAdminCodeListSelecteAccessCodeOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeOrganizationCode);
export const getAdminCodeListSelecteAccessCodeGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeGroupCode);
export const getAdminCodeListSelecteAccessCodeSingle: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelecteAccessCodeSingle);
export const getAdminCodeListSelectedOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelectedOrganizationCode);
export const getAdminCodeListSelectedGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminCodeListState, fromAdminCodeList.getSelectedGroupCode);
export const getAdminCodeListShowOrganizationBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getShowOrganizationBacklink);
export const getAdminCodeListShowGroupBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminCodeListState, fromAdminCodeList.getShowGroupBacklink);
export const getAdminUsersListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersListState, fromAdminUsersList.getIsActive);
export const getAdminUsersListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminUsersListState, fromAdminUsersList.getMessage);
export const getAdminUsersListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersListState, fromAdminUsersList.getHasError);
export const getAdminUsersListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersListState, fromAdminUsersList.getHasSuccess);
export const getAdminUsersListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersListState, fromAdminUsersList.getIsLoading);
export const getAdminUsersListSelectedUser: MemoizedSelector<RootState, number | null> = createSelector(getAdminUsersListState, fromAdminUsersList.getSelectedUser);
export const getAdminUsersListData: MemoizedSelector<RootState, any> = createSelector(getAdminUsersListState, fromAdminUsersList.getData);
export const getAdminUsersFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersFormState, fromAdminUsersForm.getIsActive);
export const getAdminUsersFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminUsersFormState, fromAdminUsersForm.getMessage);
export const getAdminUsersFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersFormState, fromAdminUsersForm.getHasError);
export const getAdminUsersFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersFormState, fromAdminUsersForm.getHasSuccess);
export const getAdminUsersFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminUsersFormState, fromAdminUsersForm.getIsLoading);
export const getAdminUsersFormForm: MemoizedSelector<RootState, {} | null> = createSelector(getAdminUsersFormState, fromAdminUsersForm.getForm);
export const getAdminAccessCodeSearchIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminAccessCodeSearchState, fromAdminAccessCodeSearch.getIsActive);
export const getAdminAccessCodeSearchMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminAccessCodeSearchState, fromAdminAccessCodeSearch.getMessage);
export const getAdminAccessCodeSearchHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminAccessCodeSearchState, fromAdminAccessCodeSearch.getHasError);
export const getAdminAccessCodeSearchHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminAccessCodeSearchState, fromAdminAccessCodeSearch.getHasSuccess);
export const getAdminAccessCodeSearchIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminAccessCodeSearchState, fromAdminAccessCodeSearch.getIsLoading);
export const getAdminNotesListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getIsActive);
export const getAdminNotesListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminNotesListState, fromAdminNotesList.getMessage);
export const getAdminNotesListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getHasError);
export const getAdminNotesListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getHasSuccess);
export const getAdminNotesListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getIsLoading);
export const getAdminNotesListSelecteService: MemoizedSelector<RootState, ServiceInfo | null> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteService);
export const getAdminNotesListSelecteServicePeriod: MemoizedSelector<RootState, any> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServicePeriod);
export const getAdminNotesListSelecteServiceAvailableFrom: MemoizedSelector<RootState, string | null> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceAvailableFrom);
export const getAdminNotesListSelecteServiceAvailableServiceId: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceAvailableServiceId);
export const getAdminNotesListSelecteServiceAvailableUntil: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceAvailableUntil);
export const getAdminNotesListSelecteServiceCancelationNotice: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceCancelationNotice);
export const getAdminNotesListSelecteServiceCancelationNoticeTimeUnit: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceCancelationNoticeTimeUnit);
export const getAdminNotesListSelecteServiceDuration: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceDuration);
export const getAdminNotesListSelecteServiceDurationTimeUnit: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceDurationTimeUnit);
export const getAdminNotesListSelecteServiceIsBankingCardPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsBankingCardPaymentAccepted);
export const getAdminNotesListSelecteServiceIsCancelable: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsCancelable);
export const getAdminNotesListSelecteServiceIsCancelationFirstDayOfMonth: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsCancelationFirstDayOfMonth);
export const getAdminNotesListSelecteServiceIsDasPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsDasPaymentAccepted);
export const getAdminNotesListSelecteServiceIsFinancialInstitutionPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsFinancialInstitutionPaymentAccepted);
export const getAdminNotesListSelecteServiceIsFixedDuration: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsFixedDuration);
export const getAdminNotesListSelecteServiceIsPpaPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsPpaPaymentAccepted);
export const getAdminNotesListSelecteServiceIsSubscriptionAvailable: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsSubscriptionAvailable);
export const getAdminNotesListSelecteServiceIsTax1Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsTax1Exempted);
export const getAdminNotesListSelecteServiceIsTax2Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsTax2Exempted);
export const getAdminNotesListSelecteServiceIsTax3Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceIsTax3Exempted);
export const getAdminNotesListSelecteServiceOnSaleFrom: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceOnSaleFrom);
export const getAdminNotesListSelecteServiceOnSaleUntil: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceOnSaleUntil);
export const getAdminNotesListSelecteServiceParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceParkIdentificationNo);
export const getAdminNotesListSelecteServicePartialMonthCalculation: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServicePartialMonthCalculation);
export const getAdminNotesListSelecteServicePrice: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServicePrice);
export const getAdminNotesListSelecteServiceSectorId: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceSectorId);
export const getAdminNotesListSelecteServiceServiceBillingType: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceServiceBillingType);
export const getAdminNotesListSelecteServiceServiceDescription: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceServiceDescription);
export const getAdminNotesListSelecteServiceServiceStartRule: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceServiceStartRule);
export const getAdminNotesListSelecteServiceServiceType: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceServiceType);
export const getAdminNotesListSelecteServiceMeta: MemoizedSelector<RootState, ServiceMetaInfo> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceMeta);
export const getAdminNotesListSelecteServiceMetaNote: MemoizedSelector<RootState, string> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelecteServiceMetaNote);
export const getAdminNotesListSelectedParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getAdminNotesListState, fromAdminNotesList.getSelectedParkIdentificationNo);
export const getAdminNotesFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getIsActive);
export const getAdminNotesFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getMessage);
export const getAdminNotesFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getHasError);
export const getAdminNotesFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getHasSuccess);
export const getAdminNotesFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getIsLoading);
export const getAdminNotesFormForm: MemoizedSelector<RootState, ServiceMetaInfo | null> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getForm);
export const getAdminNotesFormFormNote: MemoizedSelector<RootState, string> = createSelector(getAdminNotesFormState, fromAdminNotesForm.getFormNote);
export const getAdminMetasListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getIsActive);
export const getAdminMetasListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminMetasListState, fromAdminMetasList.getMessage);
export const getAdminMetasListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getHasError);
export const getAdminMetasListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getHasSuccess);
export const getAdminMetasListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getIsLoading);
export const getAdminMetasListData: MemoizedSelector<RootState, AccessCodeServiceCustomFields[]> = createSelector(getAdminMetasListState, fromAdminMetasList.getData);
export const getAdminMetasListSelecteMetas: MemoizedSelector<RootState, AccessCodeServiceCustomFields | null> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetas);
export const getAdminMetasListSelecteMetasLabel: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasLabel);
export const getAdminMetasListSelecteMetasName: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasName);
export const getAdminMetasListSelecteMetasType: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasType);
export const getAdminMetasListSelecteMetasValues: MemoizedSelector<RootState, AccessCodeServiceCustomFieldsValue[]> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasValues);
export const getAdminMetasListSelecteMetasRequired: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasRequired);
export const getAdminMetasListSelecteMetasOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasOrganizationCode);
export const getAdminMetasListSelecteMetasGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasGroupCode);
export const getAdminMetasListSelecteMetasOrder: MemoizedSelector<RootState, number> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelecteMetasOrder);
export const getAdminMetasListSelectedOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelectedOrganizationCode);
export const getAdminMetasListSelectedGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminMetasListState, fromAdminMetasList.getSelectedGroupCode);
export const getAdminMetasListShowOrganizationBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getShowOrganizationBacklink);
export const getAdminMetasListShowGroupBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminMetasListState, fromAdminMetasList.getShowGroupBacklink);
export const getAdminServiceListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getIsActive);
export const getAdminServiceListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminServiceListState, fromAdminServiceList.getMessage);
export const getAdminServiceListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getHasError);
export const getAdminServiceListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getHasSuccess);
export const getAdminServiceListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getIsLoading);
export const getAdminServiceListData: MemoizedSelector<RootState, AccessCodeServiceInfo[]> = createSelector(getAdminServiceListState, fromAdminServiceList.getData);
export const getAdminServiceListSelecteService: MemoizedSelector<RootState, AccessCodeServiceInfo | null> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteService);
export const getAdminServiceListSelecteServiceGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceGroupCode);
export const getAdminServiceListSelecteServiceOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceOrganizationCode);
export const getAdminServiceListSelecteServiceServiceId: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceId);
export const getAdminServiceListSelecteServiceParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceParkIdentificationNo);
export const getAdminServiceListSelecteServiceServiceEntity: MemoizedSelector<RootState, ServiceInfo> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntity);
export const getAdminServiceListSelecteServiceServiceEntityPeriod: MemoizedSelector<RootState, any> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityPeriod);
export const getAdminServiceListSelecteServiceServiceEntityAvailableFrom: MemoizedSelector<RootState, string | null> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityAvailableFrom);
export const getAdminServiceListSelecteServiceServiceEntityAvailableServiceId: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityAvailableServiceId);
export const getAdminServiceListSelecteServiceServiceEntityAvailableUntil: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityAvailableUntil);
export const getAdminServiceListSelecteServiceServiceEntityCancelationNotice: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityCancelationNotice);
export const getAdminServiceListSelecteServiceServiceEntityCancelationNoticeTimeUnit: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityCancelationNoticeTimeUnit);
export const getAdminServiceListSelecteServiceServiceEntityDuration: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityDuration);
export const getAdminServiceListSelecteServiceServiceEntityDurationTimeUnit: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityDurationTimeUnit);
export const getAdminServiceListSelecteServiceServiceEntityIsBankingCardPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsBankingCardPaymentAccepted);
export const getAdminServiceListSelecteServiceServiceEntityIsCancelable: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsCancelable);
export const getAdminServiceListSelecteServiceServiceEntityIsCancelationFirstDayOfMonth: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsCancelationFirstDayOfMonth);
export const getAdminServiceListSelecteServiceServiceEntityIsDasPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsDasPaymentAccepted);
export const getAdminServiceListSelecteServiceServiceEntityIsFinancialInstitutionPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsFinancialInstitutionPaymentAccepted);
export const getAdminServiceListSelecteServiceServiceEntityIsFixedDuration: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsFixedDuration);
export const getAdminServiceListSelecteServiceServiceEntityIsPpaPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsPpaPaymentAccepted);
export const getAdminServiceListSelecteServiceServiceEntityIsSubscriptionAvailable: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsSubscriptionAvailable);
export const getAdminServiceListSelecteServiceServiceEntityIsTax1Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsTax1Exempted);
export const getAdminServiceListSelecteServiceServiceEntityIsTax2Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsTax2Exempted);
export const getAdminServiceListSelecteServiceServiceEntityIsTax3Exempted: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityIsTax3Exempted);
export const getAdminServiceListSelecteServiceServiceEntityOnSaleFrom: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityOnSaleFrom);
export const getAdminServiceListSelecteServiceServiceEntityOnSaleUntil: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityOnSaleUntil);
export const getAdminServiceListSelecteServiceServiceEntityParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityParkIdentificationNo);
export const getAdminServiceListSelecteServiceServiceEntityPartialMonthCalculation: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityPartialMonthCalculation);
export const getAdminServiceListSelecteServiceServiceEntityPrice: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityPrice);
export const getAdminServiceListSelecteServiceServiceEntitySectorId: MemoizedSelector<RootState, number> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntitySectorId);
export const getAdminServiceListSelecteServiceServiceEntityServiceBillingType: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityServiceBillingType);
export const getAdminServiceListSelecteServiceServiceEntityServiceDescription: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityServiceDescription);
export const getAdminServiceListSelecteServiceServiceEntityServiceStartRule: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityServiceStartRule);
export const getAdminServiceListSelecteServiceServiceEntityServiceType: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityServiceType);
export const getAdminServiceListSelecteServiceServiceEntityMeta: MemoizedSelector<RootState, ServiceMetaInfo> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityMeta);
export const getAdminServiceListSelecteServiceServiceEntityMetaNote: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelecteServiceServiceEntityMetaNote);
export const getAdminServiceListSelectedOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelectedOrganizationCode);
export const getAdminServiceListSelectedGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminServiceListState, fromAdminServiceList.getSelectedGroupCode);
export const getAdminServiceListShowOrganizationBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getShowOrganizationBacklink);
export const getAdminServiceListShowGroupBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminServiceListState, fromAdminServiceList.getShowGroupBacklink);
export const getAdminContractListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminContractListState, fromAdminContractList.getIsActive);
export const getAdminContractListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminContractListState, fromAdminContractList.getMessage);
export const getAdminContractListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminContractListState, fromAdminContractList.getHasError);
export const getAdminContractListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminContractListState, fromAdminContractList.getHasSuccess);
export const getAdminContractListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminContractListState, fromAdminContractList.getIsLoading);
export const getAdminContractListSelectedContract: MemoizedSelector<RootState, number | null> = createSelector(getAdminContractListState, fromAdminContractList.getSelectedContract);
export const getAdminContractListData: MemoizedSelector<RootState, any> = createSelector(getAdminContractListState, fromAdminContractList.getData);
export const getAdminContractListOnSync: MemoizedSelector<RootState, boolean> = createSelector(getAdminContractListState, fromAdminContractList.getOnSync);
export const getAdminGroupListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupListState, fromAdminGroupList.getIsActive);
export const getAdminGroupListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupListState, fromAdminGroupList.getMessage);
export const getAdminGroupListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupListState, fromAdminGroupList.getHasError);
export const getAdminGroupListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupListState, fromAdminGroupList.getHasSuccess);
export const getAdminGroupListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupListState, fromAdminGroupList.getIsLoading);
export const getAdminGroupListData: MemoizedSelector<RootState, AccessCodeGroupInfo[]> = createSelector(getAdminGroupListState, fromAdminGroupList.getData);
export const getAdminGroupListSelecteGroup: MemoizedSelector<RootState, AccessCodeGroupInfo | null> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroup);
export const getAdminGroupListSelecteGroupGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupGroupCode);
export const getAdminGroupListSelecteGroupGroupDescription: MemoizedSelector<RootState, string> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupGroupDescription);
export const getAdminGroupListSelecteGroupOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupOrganizationCode);
export const getAdminGroupListSelecteGroupPublic: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupPublic);
export const getAdminGroupListSelecteGroupServices: MemoizedSelector<RootState, AccessCodeServiceInfo[]> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupServices);
export const getAdminGroupListSelecteGroupMeta: MemoizedSelector<RootState, AccessCodeMetasInfo> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMeta);
export const getAdminGroupListSelecteGroupMetaConditions: MemoizedSelector<RootState, AccessCodeMetasConditions> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaConditions);
export const getAdminGroupListSelecteGroupMetaConditionsService: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaConditionsService);
export const getAdminGroupListSelecteGroupMetaConditionsPaymentDas: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaConditionsPaymentDas);
export const getAdminGroupListSelecteGroupMetaConditionsPaymentCc: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaConditionsPaymentCc);
export const getAdminGroupListSelecteGroupMetaConditionsPaymentPpa: MemoizedSelector<RootState, string> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaConditionsPaymentPpa);
export const getAdminGroupListSelecteGroupMetaConditionsPaymentInternet: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaConditionsPaymentInternet);
export const getAdminGroupListSelecteGroupMetaCustomFields: MemoizedSelector<RootState, AccessCodeServiceCustomFields[]> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelecteGroupMetaCustomFields);
export const getAdminGroupListSelectedOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupListState, fromAdminGroupList.getSelectedOrganizationCode);
export const getAdminGroupListShowOrganizationBacklink: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupListState, fromAdminGroupList.getShowOrganizationBacklink);
export const getAdminGroupMetasIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getIsActive);
export const getAdminGroupMetasMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getMessage);
export const getAdminGroupMetasHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getHasError);
export const getAdminGroupMetasHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getHasSuccess);
export const getAdminGroupMetasIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getIsLoading);
export const getAdminGroupMetasSelectedGroup: MemoizedSelector<RootState, AccessCodeGroupInfo | null> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroup);
export const getAdminGroupMetasSelectedGroupGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupGroupCode);
export const getAdminGroupMetasSelectedGroupGroupDescription: MemoizedSelector<RootState, string> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupGroupDescription);
export const getAdminGroupMetasSelectedGroupOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupOrganizationCode);
export const getAdminGroupMetasSelectedGroupPublic: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupPublic);
export const getAdminGroupMetasSelectedGroupServices: MemoizedSelector<RootState, AccessCodeServiceInfo[]> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupServices);
export const getAdminGroupMetasSelectedGroupMeta: MemoizedSelector<RootState, AccessCodeMetasInfo> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMeta);
export const getAdminGroupMetasSelectedGroupMetaConditions: MemoizedSelector<RootState, AccessCodeMetasConditions> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaConditions);
export const getAdminGroupMetasSelectedGroupMetaConditionsService: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaConditionsService);
export const getAdminGroupMetasSelectedGroupMetaConditionsPaymentDas: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaConditionsPaymentDas);
export const getAdminGroupMetasSelectedGroupMetaConditionsPaymentCc: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaConditionsPaymentCc);
export const getAdminGroupMetasSelectedGroupMetaConditionsPaymentPpa: MemoizedSelector<RootState, string> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaConditionsPaymentPpa);
export const getAdminGroupMetasSelectedGroupMetaConditionsPaymentInternet: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaConditionsPaymentInternet);
export const getAdminGroupMetasSelectedGroupMetaCustomFields: MemoizedSelector<RootState, AccessCodeServiceCustomFields[]> = createSelector(getAdminGroupMetasState, fromAdminGroupMetas.getSelectedGroupMetaCustomFields);
export const getAdminGroupFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getIsActive);
export const getAdminGroupFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getMessage);
export const getAdminGroupFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getHasError);
export const getAdminGroupFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getHasSuccess);
export const getAdminGroupFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getIsLoading);
export const getAdminGroupFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getIsEdit);
export const getAdminGroupFormForm: MemoizedSelector<RootState, AccessCodeGroupInfo | null> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getForm);
export const getAdminGroupFormFormGroupCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormGroupCode);
export const getAdminGroupFormFormGroupDescription: MemoizedSelector<RootState, string> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormGroupDescription);
export const getAdminGroupFormFormOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormOrganizationCode);
export const getAdminGroupFormFormPublic: MemoizedSelector<RootState, boolean> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormPublic);
export const getAdminGroupFormFormServices: MemoizedSelector<RootState, AccessCodeServiceInfo[]> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormServices);
export const getAdminGroupFormFormMeta: MemoizedSelector<RootState, AccessCodeMetasInfo> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMeta);
export const getAdminGroupFormFormMetaConditions: MemoizedSelector<RootState, AccessCodeMetasConditions> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaConditions);
export const getAdminGroupFormFormMetaConditionsService: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaConditionsService);
export const getAdminGroupFormFormMetaConditionsPaymentDas: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaConditionsPaymentDas);
export const getAdminGroupFormFormMetaConditionsPaymentCc: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaConditionsPaymentCc);
export const getAdminGroupFormFormMetaConditionsPaymentPpa: MemoizedSelector<RootState, string> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaConditionsPaymentPpa);
export const getAdminGroupFormFormMetaConditionsPaymentInternet: MemoizedSelector<RootState, string | null> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaConditionsPaymentInternet);
export const getAdminGroupFormFormMetaCustomFields: MemoizedSelector<RootState, AccessCodeServiceCustomFields[]> = createSelector(getAdminGroupFormState, fromAdminGroupForm.getFormMetaCustomFields);
export const getAdminOrganizationListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getIsActive);
export const getAdminOrganizationListMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getMessage);
export const getAdminOrganizationListHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getHasError);
export const getAdminOrganizationListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getHasSuccess);
export const getAdminOrganizationListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getIsLoading);
export const getAdminOrganizationListData: MemoizedSelector<RootState, OrganizationInfo[]> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getData);
export const getAdminOrganizationListSelectedOrganization: MemoizedSelector<RootState, OrganizationInfo | null> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganization);
export const getAdminOrganizationListSelectedOrganizationAdresse: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationAdresse);
export const getAdminOrganizationListSelectedOrganizationCity: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationCity);
export const getAdminOrganizationListSelectedOrganizationOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationOrganizationCode);
export const getAdminOrganizationListSelectedOrganizationOrganizationName: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationOrganizationName);
export const getAdminOrganizationListSelectedOrganizationPhoneNumber: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationPhoneNumber);
export const getAdminOrganizationListSelectedOrganizationPostalCode: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationPostalCode);
export const getAdminOrganizationListSelectedOrganizationProvince: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationProvince);
export const getAdminOrganizationListSelectedOrganizationLogo: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationLogo);
export const getAdminOrganizationListSelectedOrganizationConfig: MemoizedSelector<RootState, OrganizationConfigInfo | null> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationConfig);
export const getAdminOrganizationListSelectedOrganizationConfigEnableGateway: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationConfigEnableGateway);
export const getAdminOrganizationListSelectedOrganizationConfigEnablePublicRegistration: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationConfigEnablePublicRegistration);
export const getAdminOrganizationListSelectedOrganizationConfigEnablePrivateRegistration: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationConfigEnablePrivateRegistration);
export const getAdminOrganizationListSelectedOrganizationConfigDisablePublicSubscriptionService: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationListState, fromAdminOrganizationList.getSelectedOrganizationConfigDisablePublicSubscriptionService);
export const getAdminIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminState, fromAdmin.getIsActive);
export const getAdminActivePage: MemoizedSelector<RootState, string> = createSelector(getAdminState, fromAdmin.getActivePage);
export const getTestGroupListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupListState, fromTestGroupList.getIsActive);
export const getTestGroupListMessage: MemoizedSelector<RootState, string | null> = createSelector(getTestGroupListState, fromTestGroupList.getMessage);
export const getTestGroupListHasError: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupListState, fromTestGroupList.getHasError);
export const getTestGroupListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupListState, fromTestGroupList.getHasSuccess);
export const getTestGroupListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupListState, fromTestGroupList.getIsLoading);
export const getTestGroupListData: MemoizedSelector<RootState, TestGroupInfo[]> = createSelector(getTestGroupListState, fromTestGroupList.getData);
export const getTestGroupListSelecteGroup: MemoizedSelector<RootState, TestGroupInfo | null> = createSelector(getTestGroupListState, fromTestGroupList.getSelecteGroup);
export const getTestGroupListSelecteGroupId: MemoizedSelector<RootState, string> = createSelector(getTestGroupListState, fromTestGroupList.getSelecteGroupId);
export const getTestGroupListSelecteGroupName: MemoizedSelector<RootState, string> = createSelector(getTestGroupListState, fromTestGroupList.getSelecteGroupName);
export const getTestGroupListSelecteGroupTests: MemoizedSelector<RootState, any> = createSelector(getTestGroupListState, fromTestGroupList.getSelecteGroupTests);
export const getTestFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getTestFormState, fromTestForm.getIsActive);
export const getTestFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getTestFormState, fromTestForm.getMessage);
export const getTestFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getTestFormState, fromTestForm.getHasError);
export const getTestFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getTestFormState, fromTestForm.getHasSuccess);
export const getTestFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getTestFormState, fromTestForm.getIsLoading);
export const getTestFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getTestFormState, fromTestForm.getIsEdit);
export const getTestFormForm: MemoizedSelector<RootState, TestInfo> = createSelector(getTestFormState, fromTestForm.getForm);
export const getTestFormFormId: MemoizedSelector<RootState, number> = createSelector(getTestFormState, fromTestForm.getFormId);
export const getTestFormFormName: MemoizedSelector<RootState, string> = createSelector(getTestFormState, fromTestForm.getFormName);
export const getTestFormFormWhen: MemoizedSelector<RootState, string> = createSelector(getTestFormState, fromTestForm.getFormWhen);
export const getTestFormFormTimestamp: MemoizedSelector<RootState, number> = createSelector(getTestFormState, fromTestForm.getFormTimestamp);
export const getTestFormFormGroupId: MemoizedSelector<RootState, string> = createSelector(getTestFormState, fromTestForm.getFormGroupId);
export const getTestFormFormLevel: MemoizedSelector<RootState, string> = createSelector(getTestFormState, fromTestForm.getFormLevel);
export const getTestFormSelectedGroupId: MemoizedSelector<RootState, string> = createSelector(getTestFormState, fromTestForm.getSelectedGroupId);
export const getTestListIsActive: MemoizedSelector<RootState, boolean> = createSelector(getTestListState, fromTestList.getIsActive);
export const getTestListMessage: MemoizedSelector<RootState, string | null> = createSelector(getTestListState, fromTestList.getMessage);
export const getTestListHasError: MemoizedSelector<RootState, boolean> = createSelector(getTestListState, fromTestList.getHasError);
export const getTestListHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getTestListState, fromTestList.getHasSuccess);
export const getTestListIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getTestListState, fromTestList.getIsLoading);
export const getTestListData: MemoizedSelector<RootState, any> = createSelector(getTestListState, fromTestList.getData);
export const getTestListSelectedTest: MemoizedSelector<RootState, TestInfo | null> = createSelector(getTestListState, fromTestList.getSelectedTest);
export const getTestListSelectedTestId: MemoizedSelector<RootState, number> = createSelector(getTestListState, fromTestList.getSelectedTestId);
export const getTestListSelectedTestName: MemoizedSelector<RootState, string> = createSelector(getTestListState, fromTestList.getSelectedTestName);
export const getTestListSelectedTestWhen: MemoizedSelector<RootState, string> = createSelector(getTestListState, fromTestList.getSelectedTestWhen);
export const getTestListSelectedTestTimestamp: MemoizedSelector<RootState, number> = createSelector(getTestListState, fromTestList.getSelectedTestTimestamp);
export const getTestListSelectedTestGroupId: MemoizedSelector<RootState, string> = createSelector(getTestListState, fromTestList.getSelectedTestGroupId);
export const getTestListSelectedTestLevel: MemoizedSelector<RootState, string> = createSelector(getTestListState, fromTestList.getSelectedTestLevel);
export const getTestGroupFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupFormState, fromTestGroupForm.getIsActive);
export const getTestGroupFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getTestGroupFormState, fromTestGroupForm.getMessage);
export const getTestGroupFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupFormState, fromTestGroupForm.getHasError);
export const getTestGroupFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupFormState, fromTestGroupForm.getHasSuccess);
export const getTestGroupFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupFormState, fromTestGroupForm.getIsLoading);
export const getTestGroupFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getTestGroupFormState, fromTestGroupForm.getIsEdit);
export const getTestGroupFormForm: MemoizedSelector<RootState, TestGroupInfo> = createSelector(getTestGroupFormState, fromTestGroupForm.getForm);
export const getTestGroupFormFormId: MemoizedSelector<RootState, string> = createSelector(getTestGroupFormState, fromTestGroupForm.getFormId);
export const getTestGroupFormFormName: MemoizedSelector<RootState, string> = createSelector(getTestGroupFormState, fromTestGroupForm.getFormName);
export const getTestGroupFormFormTests: MemoizedSelector<RootState, any> = createSelector(getTestGroupFormState, fromTestGroupForm.getFormTests);
export const getTestIsActive: MemoizedSelector<RootState, boolean> = createSelector(getTestState, fromTest.getIsActive);
export const getTestActivePage: MemoizedSelector<RootState, string> = createSelector(getTestState, fromTest.getActivePage);
export const getAdminOrganizationFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getIsActive);
export const getAdminOrganizationFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getMessage);
export const getAdminOrganizationFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getHasError);
export const getAdminOrganizationFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getHasSuccess);
export const getAdminOrganizationFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getIsLoading);
export const getAdminOrganizationFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getIsEdit);
export const getAdminOrganizationFormForm: MemoizedSelector<RootState, OrganizationInfo | null> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getForm);
export const getAdminOrganizationFormFormAdresse: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormAdresse);
export const getAdminOrganizationFormFormCity: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormCity);
export const getAdminOrganizationFormFormOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormOrganizationCode);
export const getAdminOrganizationFormFormOrganizationName: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormOrganizationName);
export const getAdminOrganizationFormFormPhoneNumber: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormPhoneNumber);
export const getAdminOrganizationFormFormPostalCode: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormPostalCode);
export const getAdminOrganizationFormFormProvince: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormProvince);
export const getAdminOrganizationFormFormLogo: MemoizedSelector<RootState, string> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormLogo);
export const getAdminOrganizationFormFormConfig: MemoizedSelector<RootState, OrganizationConfigInfo | null> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormConfig);
export const getAdminOrganizationFormFormConfigEnableGateway: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormConfigEnableGateway);
export const getAdminOrganizationFormFormConfigEnablePublicRegistration: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormConfigEnablePublicRegistration);
export const getAdminOrganizationFormFormConfigEnablePrivateRegistration: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormConfigEnablePrivateRegistration);
export const getAdminOrganizationFormFormConfigDisablePublicSubscriptionService: MemoizedSelector<RootState, boolean> = createSelector(getAdminOrganizationFormState, fromAdminOrganizationForm.getFormConfigDisablePublicSubscriptionService);
export const getAdminConfigurationIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getIsActive);
export const getAdminConfigurationMessage: MemoizedSelector<RootState, string | null> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getMessage);
export const getAdminConfigurationHasError: MemoizedSelector<RootState, boolean> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getHasError);
export const getAdminConfigurationHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getHasSuccess);
export const getAdminConfigurationIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getIsLoading);
export const getAdminConfigurationIsLoaded: MemoizedSelector<RootState, boolean> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getIsLoaded);
export const getAdminConfigurationIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getIsEdit);
export const getAdminConfigurationForm: MemoizedSelector<RootState, MonerisConfiguration | null> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getForm);
export const getAdminConfigurationData: MemoizedSelector<RootState, SystemConfiguration | null> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getData);
export const getAdminConfigurationDataMoneris: MemoizedSelector<RootState, {
    url: string;
    vault: {
        id: string;
        key: string;
    };
    paypage: {
        id: string;
        key: string;
    };
}> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMoneris);
export const getAdminConfigurationDataMonerisUrl: MemoizedSelector<RootState, string> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisUrl);
export const getAdminConfigurationDataMonerisVault: MemoizedSelector<RootState, {
    id: string;
    key: string;
}> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisVault);
export const getAdminConfigurationDataMonerisVaultId: MemoizedSelector<RootState, string> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisVaultId);
export const getAdminConfigurationDataMonerisVaultKey: MemoizedSelector<RootState, string> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisVaultKey);
export const getAdminConfigurationDataMonerisPaypage: MemoizedSelector<RootState, {
    id: string;
    key: string;
}> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisPaypage);
export const getAdminConfigurationDataMonerisPaypageId: MemoizedSelector<RootState, string> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisPaypageId);
export const getAdminConfigurationDataMonerisPaypageKey: MemoizedSelector<RootState, string> = createSelector(getAdminConfigurationState, fromAdminConfiguration.getDataMonerisPaypageKey);
export const getAccessCodeAddMessage: MemoizedSelector<RootState, string | null> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getMessage);
export const getAccessCodeAddHasError: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getHasError);
export const getAccessCodeAddHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getHasSuccess);
export const getAccessCodeAddIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getIsLoading);
export const getAccessCodeAddIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getIsActive);
export const getAccessCodeAddForm: MemoizedSelector<RootState, AccessCodeSearchInfo> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getForm);
export const getAccessCodeAddFormCompanyCode: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormCompanyCode);
export const getAccessCodeAddFormOrganization: MemoizedSelector<RootState, OrganizationInfo> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganization);
export const getAccessCodeAddFormOrganizationAdresse: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationAdresse);
export const getAccessCodeAddFormOrganizationCity: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationCity);
export const getAccessCodeAddFormOrganizationOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationOrganizationCode);
export const getAccessCodeAddFormOrganizationOrganizationName: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationOrganizationName);
export const getAccessCodeAddFormOrganizationPhoneNumber: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationPhoneNumber);
export const getAccessCodeAddFormOrganizationPostalCode: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationPostalCode);
export const getAccessCodeAddFormOrganizationProvince: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationProvince);
export const getAccessCodeAddFormOrganizationLogo: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationLogo);
export const getAccessCodeAddFormOrganizationConfig: MemoizedSelector<RootState, OrganizationConfigInfo | null> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationConfig);
export const getAccessCodeAddFormOrganizationConfigEnableGateway: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationConfigEnableGateway);
export const getAccessCodeAddFormOrganizationConfigEnablePublicRegistration: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationConfigEnablePublicRegistration);
export const getAccessCodeAddFormOrganizationConfigEnablePrivateRegistration: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationConfigEnablePrivateRegistration);
export const getAccessCodeAddFormOrganizationConfigDisablePublicSubscriptionService: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormOrganizationConfigDisablePublicSubscriptionService);
export const getAccessCodeAddFormUserCode: MemoizedSelector<RootState, string> = createSelector(getAccessCodeAddState, fromAccessCodeAdd.getFormUserCode);
export const getAccessCodeDetailsIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeDetailsState, fromAccessCodeDetails.getIsActive);
export const getContractSearchMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractSearchState, fromContractSearch.getMessage);
export const getContractSearchHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractSearchState, fromContractSearch.getHasError);
export const getContractSearchHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractSearchState, fromContractSearch.getHasSuccess);
export const getContractSearchIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContractSearchState, fromContractSearch.getIsActive);
export const getContractSearchIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getContractSearchState, fromContractSearch.getIsComplete);
export const getContractSearchIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractSearchState, fromContractSearch.getIsLoading);
export const getContractSearchForm: MemoizedSelector<RootState, ContractForm> = createSelector(getContractSearchState, fromContractSearch.getForm);
export const getContractSearchFormContractNo: MemoizedSelector<RootState, string> = createSelector(getContractSearchState, fromContractSearch.getFormContractNo);
export const getContractSearchFormPhone: MemoizedSelector<RootState, string> = createSelector(getContractSearchState, fromContractSearch.getFormPhone);
export const getContractSearchFormPostalCode: MemoizedSelector<RootState, string> = createSelector(getContractSearchState, fromContractSearch.getFormPostalCode);
export const getAccessCodeData: MemoizedSelector<RootState, AccessCodeGroupInfo | null> = createSelector(getAccessCodeState, fromAccessCode.getData);
export const getAccessCodeDataGroupCode: MemoizedSelector<RootState, string> = createSelector(getAccessCodeState, fromAccessCode.getDataGroupCode);
export const getAccessCodeDataGroupDescription: MemoizedSelector<RootState, string> = createSelector(getAccessCodeState, fromAccessCode.getDataGroupDescription);
export const getAccessCodeDataOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getAccessCodeState, fromAccessCode.getDataOrganizationCode);
export const getAccessCodeDataPublic: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getDataPublic);
export const getAccessCodeDataServices: MemoizedSelector<RootState, AccessCodeServiceInfo[]> = createSelector(getAccessCodeState, fromAccessCode.getDataServices);
export const getAccessCodeDataMeta: MemoizedSelector<RootState, AccessCodeMetasInfo> = createSelector(getAccessCodeState, fromAccessCode.getDataMeta);
export const getAccessCodeDataMetaConditions: MemoizedSelector<RootState, AccessCodeMetasConditions> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaConditions);
export const getAccessCodeDataMetaConditionsService: MemoizedSelector<RootState, string | null> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaConditionsService);
export const getAccessCodeDataMetaConditionsPaymentDas: MemoizedSelector<RootState, string | null> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaConditionsPaymentDas);
export const getAccessCodeDataMetaConditionsPaymentCc: MemoizedSelector<RootState, string | null> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaConditionsPaymentCc);
export const getAccessCodeDataMetaConditionsPaymentPpa: MemoizedSelector<RootState, string> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaConditionsPaymentPpa);
export const getAccessCodeDataMetaConditionsPaymentInternet: MemoizedSelector<RootState, string | null> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaConditionsPaymentInternet);
export const getAccessCodeDataMetaCustomFields: MemoizedSelector<RootState, AccessCodeServiceCustomFields[]> = createSelector(getAccessCodeState, fromAccessCode.getDataMetaCustomFields);
export const getAccessCodeMessage: MemoizedSelector<RootState, string | null> = createSelector(getAccessCodeState, fromAccessCode.getMessage);
export const getAccessCodeHasError: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getHasError);
export const getAccessCodeHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getHasSuccess);
export const getAccessCodeIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getIsLoading);
export const getAccessCodeIsPending: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getIsPending);
export const getAccessCodeIsHydrated: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getIsHydrated);
export const getAccessCodeIsActive: MemoizedSelector<RootState, boolean> = createSelector(getAccessCodeState, fromAccessCode.getIsActive);
export const getServicesSelectIsActive: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getIsActive);
export const getServicesSelectMessage: MemoizedSelector<RootState, string | null> = createSelector(getServicesSelectState, fromServicesSelect.getMessage);
export const getServicesSelectHasError: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getHasError);
export const getServicesSelectHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getHasSuccess);
export const getServicesSelectIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getIsLoading);
export const getServicesSelectSelectedService: MemoizedSelector<RootState, ServiceInfo | null> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedService);
export const getServicesSelectSelectedServicePeriod: MemoizedSelector<RootState, any> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServicePeriod);
export const getServicesSelectSelectedServiceAvailableFrom: MemoizedSelector<RootState, string | null> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceAvailableFrom);
export const getServicesSelectSelectedServiceAvailableServiceId: MemoizedSelector<RootState, number> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceAvailableServiceId);
export const getServicesSelectSelectedServiceAvailableUntil: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceAvailableUntil);
export const getServicesSelectSelectedServiceCancelationNotice: MemoizedSelector<RootState, number> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceCancelationNotice);
export const getServicesSelectSelectedServiceCancelationNoticeTimeUnit: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceCancelationNoticeTimeUnit);
export const getServicesSelectSelectedServiceDuration: MemoizedSelector<RootState, number> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceDuration);
export const getServicesSelectSelectedServiceDurationTimeUnit: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceDurationTimeUnit);
export const getServicesSelectSelectedServiceIsBankingCardPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsBankingCardPaymentAccepted);
export const getServicesSelectSelectedServiceIsCancelable: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsCancelable);
export const getServicesSelectSelectedServiceIsCancelationFirstDayOfMonth: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsCancelationFirstDayOfMonth);
export const getServicesSelectSelectedServiceIsDasPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsDasPaymentAccepted);
export const getServicesSelectSelectedServiceIsFinancialInstitutionPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsFinancialInstitutionPaymentAccepted);
export const getServicesSelectSelectedServiceIsFixedDuration: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsFixedDuration);
export const getServicesSelectSelectedServiceIsPpaPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsPpaPaymentAccepted);
export const getServicesSelectSelectedServiceIsSubscriptionAvailable: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsSubscriptionAvailable);
export const getServicesSelectSelectedServiceIsTax1Exempted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsTax1Exempted);
export const getServicesSelectSelectedServiceIsTax2Exempted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsTax2Exempted);
export const getServicesSelectSelectedServiceIsTax3Exempted: MemoizedSelector<RootState, boolean> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceIsTax3Exempted);
export const getServicesSelectSelectedServiceOnSaleFrom: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceOnSaleFrom);
export const getServicesSelectSelectedServiceOnSaleUntil: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceOnSaleUntil);
export const getServicesSelectSelectedServiceParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceParkIdentificationNo);
export const getServicesSelectSelectedServicePartialMonthCalculation: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServicePartialMonthCalculation);
export const getServicesSelectSelectedServicePrice: MemoizedSelector<RootState, number> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServicePrice);
export const getServicesSelectSelectedServiceSectorId: MemoizedSelector<RootState, number> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceSectorId);
export const getServicesSelectSelectedServiceServiceBillingType: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceServiceBillingType);
export const getServicesSelectSelectedServiceServiceDescription: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceServiceDescription);
export const getServicesSelectSelectedServiceServiceStartRule: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceServiceStartRule);
export const getServicesSelectSelectedServiceServiceType: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceServiceType);
export const getServicesSelectSelectedServiceMeta: MemoizedSelector<RootState, ServiceMetaInfo> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceMeta);
export const getServicesSelectSelectedServiceMetaNote: MemoizedSelector<RootState, string> = createSelector(getServicesSelectState, fromServicesSelect.getSelectedServiceMetaNote);
export const getPaymentSelectMethodIsActive: MemoizedSelector<RootState, boolean> = createSelector(getPaymentSelectMethodState, fromPaymentSelectMethod.getIsActive);
export const getPaymentSelectMethodSelectedMethod: MemoizedSelector<RootState, string | null> = createSelector(getPaymentSelectMethodState, fromPaymentSelectMethod.getSelectedMethod);
export const getPaymentSelectMethodIsDasPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getPaymentSelectMethodState, fromPaymentSelectMethod.getIsDasPaymentAccepted);
export const getPaymentSelectMethodIsBankingCardPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getPaymentSelectMethodState, fromPaymentSelectMethod.getIsBankingCardPaymentAccepted);
export const getPaymentSelectMethodIsFinancialInstitutionPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getPaymentSelectMethodState, fromPaymentSelectMethod.getIsFinancialInstitutionPaymentAccepted);
export const getPaymentSelectMethodIsPpaPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getPaymentSelectMethodState, fromPaymentSelectMethod.getIsPpaPaymentAccepted);
export const getServicesIds: MemoizedSelector<RootState, number[] | string[]> = createSelector(getServicesState, fromServices.getIds);
export const getServicesEntities: MemoizedSelector<RootState, {
    [k: string]: ServiceInfo;
}> = createSelector(getServicesState, fromServices.getEntities);
export const getServicesAll: MemoizedSelector<RootState, ServiceInfo[]> = createSelector(getServicesState, fromServices.getAll);
export const getServicesTotal: MemoizedSelector<RootState, number> = createSelector(getServicesState, fromServices.getTotal);
export const getServicesMessage: MemoizedSelector<RootState, string | null> = createSelector(getServicesState, fromServices.getMessage);
export const getServicesHasError: MemoizedSelector<RootState, boolean> = createSelector(getServicesState, fromServices.getHasError);
export const getServicesHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getServicesState, fromServices.getHasSuccess);
export const getServicesIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getServicesState, fromServices.getIsLoading);
export const getServicesCanSubscribe: MemoizedSelector<RootState, boolean> = createSelector(getServicesState, fromServices.getCanSubscribe);
export const getServicesSelected: MemoizedSelector<RootState, string | number | null> = createSelector(getServicesState, fromServices.getSelected);
export const getSettingsIsActive: MemoizedSelector<RootState, boolean> = createSelector(getSettingsState, fromSettings.getIsActive);
export const getSettingsIsEditable: MemoizedSelector<RootState, boolean> = createSelector(getSettingsState, fromSettings.getIsEditable);
export const getSettingsIsAddable: MemoizedSelector<RootState, boolean> = createSelector(getSettingsState, fromSettings.getIsAddable);
export const getSettingsSubTitle: MemoizedSelector<RootState, string | null> = createSelector(getSettingsState, fromSettings.getSubTitle);
export const getSettingsEditLink: MemoizedSelector<RootState, string | null> = createSelector(getSettingsState, fromSettings.getEditLink);
export const getSettingsAddLink: MemoizedSelector<RootState, string | null> = createSelector(getSettingsState, fromSettings.getAddLink);
export const getSettingsBackLink: MemoizedSelector<RootState, string | null> = createSelector(getSettingsState, fromSettings.getBackLink);
export const getVehiclesEditHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesEditState, fromVehiclesEdit.getHasSuccess);
export const getVehiclesEditIsActive: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesEditState, fromVehiclesEdit.getIsActive);
export const getVehiclesEditHasError: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesEditState, fromVehiclesEdit.getHasError);
export const getVehiclesEditIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesEditState, fromVehiclesEdit.getIsLoading);
export const getVehiclesEditIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesEditState, fromVehiclesEdit.getIsCompleted);
export const getVehiclesEditRedirectOnComplete: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesEditState, fromVehiclesEdit.getRedirectOnComplete);
export const getVehiclesEditMessage: MemoizedSelector<RootState, string | null> = createSelector(getVehiclesEditState, fromVehiclesEdit.getMessage);
export const getVehiclesEditForm: MemoizedSelector<RootState, Vehicule> = createSelector(getVehiclesEditState, fromVehiclesEdit.getForm);
export const getVehiclesEditFormDescription: MemoizedSelector<RootState, string> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormDescription);
export const getVehiclesEditFormId: MemoizedSelector<RootState, number> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormId);
export const getVehiclesEditFormKey: MemoizedSelector<RootState, string> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormKey);
export const getVehiclesEditFormLicencePlate: MemoizedSelector<RootState, string> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormLicencePlate);
export const getVehiclesEditFormManufacturer: MemoizedSelector<RootState, string> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormManufacturer);
export const getVehiclesEditFormModel: MemoizedSelector<RootState, string> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormModel);
export const getVehiclesEditFormVehiculeYear: MemoizedSelector<RootState, string> = createSelector(getVehiclesEditState, fromVehiclesEdit.getFormVehiculeYear);
export const getVehiclesAddHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesAddState, fromVehiclesAdd.getHasSuccess);
export const getVehiclesAddIsActive: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesAddState, fromVehiclesAdd.getIsActive);
export const getVehiclesAddIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesAddState, fromVehiclesAdd.getIsCompleted);
export const getVehiclesAddRedirectOnComplete: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesAddState, fromVehiclesAdd.getRedirectOnComplete);
export const getVehiclesAddHasError: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesAddState, fromVehiclesAdd.getHasError);
export const getVehiclesAddIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesAddState, fromVehiclesAdd.getIsLoading);
export const getVehiclesAddMessage: MemoizedSelector<RootState, string | null> = createSelector(getVehiclesAddState, fromVehiclesAdd.getMessage);
export const getVehiclesAddForm: MemoizedSelector<RootState, Vehicule> = createSelector(getVehiclesAddState, fromVehiclesAdd.getForm);
export const getVehiclesAddFormDescription: MemoizedSelector<RootState, string> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormDescription);
export const getVehiclesAddFormId: MemoizedSelector<RootState, number> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormId);
export const getVehiclesAddFormKey: MemoizedSelector<RootState, string> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormKey);
export const getVehiclesAddFormLicencePlate: MemoizedSelector<RootState, string> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormLicencePlate);
export const getVehiclesAddFormManufacturer: MemoizedSelector<RootState, string> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormManufacturer);
export const getVehiclesAddFormModel: MemoizedSelector<RootState, string> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormModel);
export const getVehiclesAddFormVehiculeYear: MemoizedSelector<RootState, string> = createSelector(getVehiclesAddState, fromVehiclesAdd.getFormVehiculeYear);
export const getVehiclesDetailsHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesDetailsState, fromVehiclesDetails.getHasSuccess);
export const getVehiclesDetailsHasError: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesDetailsState, fromVehiclesDetails.getHasError);
export const getVehiclesDetailsIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesDetailsState, fromVehiclesDetails.getIsLoading);
export const getVehiclesDetailsMessage: MemoizedSelector<RootState, string | null> = createSelector(getVehiclesDetailsState, fromVehiclesDetails.getMessage);
export const getVehiclesSelectedVehicle: MemoizedSelector<RootState, Vehicule | null> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicle);
export const getVehiclesSelectedVehicleDescription: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleDescription);
export const getVehiclesSelectedVehicleId: MemoizedSelector<RootState, number> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleId);
export const getVehiclesSelectedVehicleKey: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleKey);
export const getVehiclesSelectedVehicleLicencePlate: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleLicencePlate);
export const getVehiclesSelectedVehicleManufacturer: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleManufacturer);
export const getVehiclesSelectedVehicleModel: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleModel);
export const getVehiclesSelectedVehicleVehiculeYear: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedVehicleVehiculeYear);
export const getVehiclesIsHydrated: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesState, fromVehicles.getIsHydrated);
export const getVehiclesHasVehicles: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesState, fromVehicles.getHasVehicles);
export const getVehiclesHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesState, fromVehicles.getHasSuccess);
export const getVehiclesHasError: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesState, fromVehicles.getHasError);
export const getVehiclesIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesState, fromVehicles.getIsLoading);
export const getVehiclesMessage: MemoizedSelector<RootState, string | null> = createSelector(getVehiclesState, fromVehicles.getMessage);
export const getVehiclesSelectedYear: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedYear);
export const getVehiclesSelectedBrand: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedBrand);
export const getVehiclesSelectedModel: MemoizedSelector<RootState, string> = createSelector(getVehiclesState, fromVehicles.getSelectedModel);
export const getVehiclesYears: MemoizedSelector<RootState, any> = createSelector(getVehiclesState, fromVehicles.getYears);
export const getVehiclesBrands: MemoizedSelector<RootState, any> = createSelector(getVehiclesState, fromVehicles.getBrands);
export const getVehiclesModels: MemoizedSelector<RootState, any> = createSelector(getVehiclesState, fromVehicles.getModels);
export const getVehiclesData: MemoizedSelector<RootState, Vehicule[]> = createSelector(getVehiclesState, fromVehicles.getData);
export const getVehiclesFormIsActive: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getIsActive);
export const getVehiclesFormHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getHasSuccess);
export const getVehiclesFormHasError: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getHasError);
export const getVehiclesFormIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getIsLoading);
export const getVehiclesFormMessage: MemoizedSelector<RootState, string | null> = createSelector(getVehiclesFormState, fromVehiclesForm.getMessage);
export const getVehiclesFormIsEdit: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getIsEdit);
export const getVehiclesFormSelectedId: MemoizedSelector<RootState, string | null> = createSelector(getVehiclesFormState, fromVehiclesForm.getSelectedId);
export const getVehiclesFormForm: MemoizedSelector<RootState, Vehicule> = createSelector(getVehiclesFormState, fromVehiclesForm.getForm);
export const getVehiclesFormFormDescription: MemoizedSelector<RootState, string> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormDescription);
export const getVehiclesFormFormId: MemoizedSelector<RootState, number> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormId);
export const getVehiclesFormFormKey: MemoizedSelector<RootState, string> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormKey);
export const getVehiclesFormFormLicencePlate: MemoizedSelector<RootState, string> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormLicencePlate);
export const getVehiclesFormFormManufacturer: MemoizedSelector<RootState, string> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormManufacturer);
export const getVehiclesFormFormModel: MemoizedSelector<RootState, string> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormModel);
export const getVehiclesFormFormVehiculeYear: MemoizedSelector<RootState, string> = createSelector(getVehiclesFormState, fromVehiclesForm.getFormVehiculeYear);
export const getVehiclesFormValidated: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getValidated);
export const getVehiclesFormIsValidationRequired: MemoizedSelector<RootState, boolean> = createSelector(getVehiclesFormState, fromVehiclesForm.getIsValidationRequired);
export const getUserVehiclesDetailsHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesDetailsState, fromUserVehiclesDetails.getHasSuccess);
export const getUserVehiclesDetailsHasError: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesDetailsState, fromUserVehiclesDetails.getHasError);
export const getUserVehiclesDetailsIsActive: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesDetailsState, fromUserVehiclesDetails.getIsActive);
export const getUserVehiclesDetailsIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesDetailsState, fromUserVehiclesDetails.getIsLoading);
export const getUserVehiclesDetailsMessage: MemoizedSelector<RootState, string | null> = createSelector(getUserVehiclesDetailsState, fromUserVehiclesDetails.getMessage);
export const getUserVehiclesHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesState, fromUserVehicles.getHasSuccess);
export const getUserVehiclesHasError: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesState, fromUserVehicles.getHasError);
export const getUserVehiclesIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getUserVehiclesState, fromUserVehicles.getIsLoading);
export const getUserVehiclesMessage: MemoizedSelector<RootState, string | null> = createSelector(getUserVehiclesState, fromUserVehicles.getMessage);
export const getUserVehiclesCollection: MemoizedSelector<RootState, Vehicule[]> = createSelector(getUserVehiclesState, fromUserVehicles.getCollection);
export const getUserAccountStatus: MemoizedSelector<RootState, UserAccountStatus> = createSelector(getUserState, fromUser.getAccountStatus);
export const getUserAccountStatusIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getUserState, fromUser.getAccountStatusIsCompleted);
export const getUserAccountStatusIsAccessCodeRequired: MemoizedSelector<RootState, boolean> = createSelector(getUserState, fromUser.getAccountStatusIsAccessCodeRequired);
export const getUserAccountStatusIsSubscriptionRequired: MemoizedSelector<RootState, boolean> = createSelector(getUserState, fromUser.getAccountStatusIsSubscriptionRequired);
export const getUserAccountStatusIsContractRequired: MemoizedSelector<RootState, boolean> = createSelector(getUserState, fromUser.getAccountStatusIsContractRequired);
export const getUserAccountStatusIsCipRequired: MemoizedSelector<RootState, boolean> = createSelector(getUserState, fromUser.getAccountStatusIsCipRequired);
export const getUserSubscription: MemoizedSelector<RootState, SubscriptionInfo | null> = createSelector(getUserState, fromUser.getSubscription);
export const getUserSubscriptionCustom: MemoizedSelector<RootState, any> = createSelector(getUserState, fromUser.getSubscriptionCustom);
export const getUserSubscriptionSpaq: MemoizedSelector<RootState, SubscriptionSpaqInfo> = createSelector(getUserState, fromUser.getSubscriptionSpaq);
export const getUserSubscriptionSpaqAccesDocuments: MemoizedSelector<RootState, AccesDocumentInfo[]> = createSelector(getUserState, fromUser.getSubscriptionSpaqAccesDocuments);
export const getUserSubscriptionSpaqAccessOnHoldReason: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqAccessOnHoldReason);
export const getUserSubscriptionSpaqAddressLine1: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqAddressLine1);
export const getUserSubscriptionSpaqIdentificationCode: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqIdentificationCode);
export const getUserSubscriptionSpaqAddressLine2: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqAddressLine2);
export const getUserSubscriptionSpaqAppartmentNo: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqAppartmentNo);
export const getUserSubscriptionSpaqBalance: MemoizedSelector<RootState, number> = createSelector(getUserState, fromUser.getSubscriptionSpaqBalance);
export const getUserSubscriptionSpaqCellPhone: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqCellPhone);
export const getUserSubscriptionSpaqCity: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqCity);
export const getUserSubscriptionSpaqContractNo: MemoizedSelector<RootState, number> = createSelector(getUserState, fromUser.getSubscriptionSpaqContractNo);
export const getUserSubscriptionSpaqDynamicFieldJsonData: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqDynamicFieldJsonData);
export const getUserSubscriptionSpaqEmail: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqEmail);
export const getUserSubscriptionSpaqFirstName: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqFirstName);
export const getUserSubscriptionSpaqHomePhone: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqHomePhone);
export const getUserSubscriptionSpaqHomePhoneExtension: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqHomePhoneExtension);
export const getUserSubscriptionSpaqIsAccessOnHold: MemoizedSelector<RootState, boolean> = createSelector(getUserState, fromUser.getSubscriptionSpaqIsAccessOnHold);
export const getUserSubscriptionSpaqLanguage: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqLanguage);
export const getUserSubscriptionSpaqLastName: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqLastName);
export const getUserSubscriptionSpaqPostalCode: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPostalCode);
export const getUserSubscriptionSpaqProvince: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqProvince);
export const getUserSubscriptionSpaqServiceCenter: MemoizedSelector<RootState, number> = createSelector(getUserState, fromUser.getSubscriptionSpaqServiceCenter);
export const getUserSubscriptionSpaqSex: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqSex);
export const getUserSubscriptionSpaqWorkPhone: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqWorkPhone);
export const getUserSubscriptionSpaqWorkPhoneExtension: MemoizedSelector<RootState, number> = createSelector(getUserState, fromUser.getSubscriptionSpaqWorkPhoneExtension);
export const getUserSubscriptionSpaqPaymentMethod: MemoizedSelector<RootState, PaymentMethodInfo> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethod);
export const getUserSubscriptionSpaqPaymentMethodCreditCardDataKey: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodCreditCardDataKey);
export const getUserSubscriptionSpaqPaymentMethodEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodEffectiveDate);
export const getUserSubscriptionSpaqPaymentMethodEndDate: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodEndDate);
export const getUserSubscriptionSpaqPaymentMethodFinancialInstitutionId: MemoizedSelector<RootState, number> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodFinancialInstitutionId);
export const getUserSubscriptionSpaqPaymentMethodFolioNo: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodFolioNo);
export const getUserSubscriptionSpaqPaymentMethodMethod: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodMethod);
export const getUserSubscriptionSpaqPaymentMethodTransitNo: MemoizedSelector<RootState, string> = createSelector(getUserState, fromUser.getSubscriptionSpaqPaymentMethodTransitNo);
export const getUserVehicles: MemoizedSelector<RootState, Vehicule[]> = createSelector(getUserState, fromUser.getVehicles);
export const getUserSubscriptionServices: MemoizedSelector<RootState, SubscriptionService[]> = createSelector(getUserState, fromUser.getSubscriptionServices);
export const getUserServices: MemoizedSelector<RootState, SubscriptionService[]> = createSelector(getUserState, fromUser.getServices);
export const getUserPayments: MemoizedSelector<RootState, PaymentInfo[]> = createSelector(getUserState, fromUser.getPayments);
export const getUserMoneris: MemoizedSelector<RootState, {
    data_key: string | null;
}> = createSelector(getUserState, fromUser.getMoneris);
export const getUserMonerisDataKey: MemoizedSelector<RootState, string | null> = createSelector(getUserState, fromUser.getMonerisDataKey);
export const getForgotPasswordMessage: MemoizedSelector<RootState, string | null> = createSelector(getForgotPasswordState, fromForgotPassword.getMessage);
export const getForgotPasswordHasError: MemoizedSelector<RootState, boolean> = createSelector(getForgotPasswordState, fromForgotPassword.getHasError);
export const getForgotPasswordHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getForgotPasswordState, fromForgotPassword.getHasSuccess);
export const getForgotPasswordIsActive: MemoizedSelector<RootState, boolean> = createSelector(getForgotPasswordState, fromForgotPassword.getIsActive);
export const getForgotPasswordIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getForgotPasswordState, fromForgotPassword.getIsLoading);
export const getForgotPasswordIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getForgotPasswordState, fromForgotPassword.getIsCompleted);
export const getForgotPasswordIsResent: MemoizedSelector<RootState, boolean> = createSelector(getForgotPasswordState, fromForgotPassword.getIsResent);
export const getForgotPasswordFormReset: MemoizedSelector<RootState, ResetPasswordForm> = createSelector(getForgotPasswordState, fromForgotPassword.getFormReset);
export const getForgotPasswordFormResetConfirmPassword: MemoizedSelector<RootState, string> = createSelector(getForgotPasswordState, fromForgotPassword.getFormResetConfirmPassword);
export const getForgotPasswordFormResetPassword: MemoizedSelector<RootState, string> = createSelector(getForgotPasswordState, fromForgotPassword.getFormResetPassword);
export const getForgotPasswordForm: MemoizedSelector<RootState, ForgotPasswordForm> = createSelector(getForgotPasswordState, fromForgotPassword.getForm);
export const getForgotPasswordFormEmail: MemoizedSelector<RootState, string> = createSelector(getForgotPasswordState, fromForgotPassword.getFormEmail);
export const getResetPasswordMessage: MemoizedSelector<RootState, string | null> = createSelector(getResetPasswordState, fromResetPassword.getMessage);
export const getResetPasswordHasError: MemoizedSelector<RootState, boolean> = createSelector(getResetPasswordState, fromResetPassword.getHasError);
export const getResetPasswordHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getResetPasswordState, fromResetPassword.getHasSuccess);
export const getResetPasswordIsActive: MemoizedSelector<RootState, boolean> = createSelector(getResetPasswordState, fromResetPassword.getIsActive);
export const getResetPasswordIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getResetPasswordState, fromResetPassword.getIsLoading);
export const getResetPasswordIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getResetPasswordState, fromResetPassword.getIsCompleted);
export const getResetPasswordForm: MemoizedSelector<RootState, ResetPasswordForm> = createSelector(getResetPasswordState, fromResetPassword.getForm);
export const getResetPasswordFormConfirmPassword: MemoizedSelector<RootState, string> = createSelector(getResetPasswordState, fromResetPassword.getFormConfirmPassword);
export const getResetPasswordFormPassword: MemoizedSelector<RootState, string> = createSelector(getResetPasswordState, fromResetPassword.getFormPassword);
export const getUpdatePasswordMessage: MemoizedSelector<RootState, string | null> = createSelector(getUpdatePasswordState, fromUpdatePassword.getMessage);
export const getUpdatePasswordHasError: MemoizedSelector<RootState, boolean> = createSelector(getUpdatePasswordState, fromUpdatePassword.getHasError);
export const getUpdatePasswordHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getUpdatePasswordState, fromUpdatePassword.getHasSuccess);
export const getUpdatePasswordIsActive: MemoizedSelector<RootState, boolean> = createSelector(getUpdatePasswordState, fromUpdatePassword.getIsActive);
export const getUpdatePasswordIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getUpdatePasswordState, fromUpdatePassword.getIsLoading);
export const getUpdatePasswordIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getUpdatePasswordState, fromUpdatePassword.getIsCompleted);
export const getUpdatePasswordIsFormVisible: MemoizedSelector<RootState, boolean> = createSelector(getUpdatePasswordState, fromUpdatePassword.getIsFormVisible);
export const getUpdatePasswordForm: MemoizedSelector<RootState, UpdatePasswordForm> = createSelector(getUpdatePasswordState, fromUpdatePassword.getForm);
export const getUpdatePasswordFormConfirmPassword: MemoizedSelector<RootState, string> = createSelector(getUpdatePasswordState, fromUpdatePassword.getFormConfirmPassword);
export const getUpdatePasswordFormCurrentPassword: MemoizedSelector<RootState, string> = createSelector(getUpdatePasswordState, fromUpdatePassword.getFormCurrentPassword);
export const getUpdatePasswordFormPassword: MemoizedSelector<RootState, string> = createSelector(getUpdatePasswordState, fromUpdatePassword.getFormPassword);
export const getRegisterPendingMessage: MemoizedSelector<RootState, string | null> = createSelector(getRegisterPendingState, fromRegisterPending.getMessage);
export const getRegisterPendingHasError: MemoizedSelector<RootState, boolean> = createSelector(getRegisterPendingState, fromRegisterPending.getHasError);
export const getRegisterPendingHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getRegisterPendingState, fromRegisterPending.getHasSuccess);
export const getRegisterPendingIsActive: MemoizedSelector<RootState, boolean> = createSelector(getRegisterPendingState, fromRegisterPending.getIsActive);
export const getRegisterPendingIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getRegisterPendingState, fromRegisterPending.getIsLoading);
export const getRegisterPendingIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getRegisterPendingState, fromRegisterPending.getIsCompleted);
export const getRegisterPendingIsResent: MemoizedSelector<RootState, boolean> = createSelector(getRegisterPendingState, fromRegisterPending.getIsResent);
export const getRegisterContractMessage: MemoizedSelector<RootState, string | null> = createSelector(getRegisterContractState, fromRegisterContract.getMessage);
export const getRegisterContractHasError: MemoizedSelector<RootState, boolean> = createSelector(getRegisterContractState, fromRegisterContract.getHasError);
export const getRegisterContractHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getRegisterContractState, fromRegisterContract.getHasSuccess);
export const getRegisterContractIsActive: MemoizedSelector<RootState, boolean> = createSelector(getRegisterContractState, fromRegisterContract.getIsActive);
export const getRegisterContractIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getRegisterContractState, fromRegisterContract.getIsComplete);
export const getRegisterContractIsPending: MemoizedSelector<RootState, boolean> = createSelector(getRegisterContractState, fromRegisterContract.getIsPending);
export const getRegisterContractForm: MemoizedSelector<RootState, ContractForm> = createSelector(getRegisterContractState, fromRegisterContract.getForm);
export const getRegisterContractFormContractNo: MemoizedSelector<RootState, string> = createSelector(getRegisterContractState, fromRegisterContract.getFormContractNo);
export const getRegisterContractFormPhone: MemoizedSelector<RootState, string> = createSelector(getRegisterContractState, fromRegisterContract.getFormPhone);
export const getRegisterContractFormPostalCode: MemoizedSelector<RootState, string> = createSelector(getRegisterContractState, fromRegisterContract.getFormPostalCode);
export const getRegisterAccessCodeMessage: MemoizedSelector<RootState, string | null> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getMessage);
export const getRegisterAccessCodeHasError: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getHasError);
export const getRegisterAccessCodeHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getHasSuccess);
export const getRegisterAccessCodeIsActive: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getIsActive);
export const getRegisterAccessCodeIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getIsComplete);
export const getRegisterAccessCodeIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getIsLoading);
export const getRegisterAccessCodeForm: MemoizedSelector<RootState, AccessCodeSearchInfo> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getForm);
export const getRegisterAccessCodeFormCompanyCode: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormCompanyCode);
export const getRegisterAccessCodeFormOrganization: MemoizedSelector<RootState, OrganizationInfo> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganization);
export const getRegisterAccessCodeFormOrganizationAdresse: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationAdresse);
export const getRegisterAccessCodeFormOrganizationCity: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationCity);
export const getRegisterAccessCodeFormOrganizationOrganizationCode: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationOrganizationCode);
export const getRegisterAccessCodeFormOrganizationOrganizationName: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationOrganizationName);
export const getRegisterAccessCodeFormOrganizationPhoneNumber: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationPhoneNumber);
export const getRegisterAccessCodeFormOrganizationPostalCode: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationPostalCode);
export const getRegisterAccessCodeFormOrganizationProvince: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationProvince);
export const getRegisterAccessCodeFormOrganizationLogo: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationLogo);
export const getRegisterAccessCodeFormOrganizationConfig: MemoizedSelector<RootState, OrganizationConfigInfo | null> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationConfig);
export const getRegisterAccessCodeFormOrganizationConfigEnableGateway: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationConfigEnableGateway);
export const getRegisterAccessCodeFormOrganizationConfigEnablePublicRegistration: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationConfigEnablePublicRegistration);
export const getRegisterAccessCodeFormOrganizationConfigEnablePrivateRegistration: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationConfigEnablePrivateRegistration);
export const getRegisterAccessCodeFormOrganizationConfigDisablePublicSubscriptionService: MemoizedSelector<RootState, boolean> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormOrganizationConfigDisablePublicSubscriptionService);
export const getRegisterAccessCodeFormUserCode: MemoizedSelector<RootState, string> = createSelector(getRegisterAccessCodeState, fromRegisterAccessCode.getFormUserCode);
export const getRegisterSubscriptionMessage: MemoizedSelector<RootState, string | null> = createSelector(getRegisterSubscriptionState, fromRegisterSubscription.getMessage);
export const getRegisterSubscriptionHasError: MemoizedSelector<RootState, boolean> = createSelector(getRegisterSubscriptionState, fromRegisterSubscription.getHasError);
export const getRegisterSubscriptionHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getRegisterSubscriptionState, fromRegisterSubscription.getHasSuccess);
export const getRegisterSubscriptionIsActive: MemoizedSelector<RootState, boolean> = createSelector(getRegisterSubscriptionState, fromRegisterSubscription.getIsActive);
export const getRegisterSubscriptionIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getRegisterSubscriptionState, fromRegisterSubscription.getIsComplete);
export const getRegisterSubscriptionIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getRegisterSubscriptionState, fromRegisterSubscription.getIsLoading);
export const getSubscriptionEditMessage: MemoizedSelector<RootState, string | null> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getMessage);
export const getSubscriptionEditHasError: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getHasError);
export const getSubscriptionEditHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getHasSuccess);
export const getSubscriptionEditIsActive: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getIsActive);
export const getSubscriptionEditIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getIsComplete);
export const getSubscriptionEditIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getIsLoading);
export const getSubscriptionEditForceFormUpdate: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getForceFormUpdate);
export const getSubscriptionEditForm: MemoizedSelector<RootState, SubscriptionInfo> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getForm);
export const getSubscriptionEditFormCustom: MemoizedSelector<RootState, any> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormCustom);
export const getSubscriptionEditFormSpaq: MemoizedSelector<RootState, SubscriptionSpaqInfo> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaq);
export const getSubscriptionEditFormSpaqAccesDocuments: MemoizedSelector<RootState, AccesDocumentInfo[]> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqAccesDocuments);
export const getSubscriptionEditFormSpaqAccessOnHoldReason: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqAccessOnHoldReason);
export const getSubscriptionEditFormSpaqAddressLine1: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqAddressLine1);
export const getSubscriptionEditFormSpaqIdentificationCode: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqIdentificationCode);
export const getSubscriptionEditFormSpaqAddressLine2: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqAddressLine2);
export const getSubscriptionEditFormSpaqAppartmentNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqAppartmentNo);
export const getSubscriptionEditFormSpaqBalance: MemoizedSelector<RootState, number> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqBalance);
export const getSubscriptionEditFormSpaqCellPhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqCellPhone);
export const getSubscriptionEditFormSpaqCity: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqCity);
export const getSubscriptionEditFormSpaqContractNo: MemoizedSelector<RootState, number> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqContractNo);
export const getSubscriptionEditFormSpaqDynamicFieldJsonData: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqDynamicFieldJsonData);
export const getSubscriptionEditFormSpaqEmail: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqEmail);
export const getSubscriptionEditFormSpaqFirstName: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqFirstName);
export const getSubscriptionEditFormSpaqHomePhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqHomePhone);
export const getSubscriptionEditFormSpaqHomePhoneExtension: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqHomePhoneExtension);
export const getSubscriptionEditFormSpaqIsAccessOnHold: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqIsAccessOnHold);
export const getSubscriptionEditFormSpaqLanguage: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqLanguage);
export const getSubscriptionEditFormSpaqLastName: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqLastName);
export const getSubscriptionEditFormSpaqPostalCode: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPostalCode);
export const getSubscriptionEditFormSpaqProvince: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqProvince);
export const getSubscriptionEditFormSpaqServiceCenter: MemoizedSelector<RootState, number> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqServiceCenter);
export const getSubscriptionEditFormSpaqSex: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqSex);
export const getSubscriptionEditFormSpaqWorkPhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqWorkPhone);
export const getSubscriptionEditFormSpaqWorkPhoneExtension: MemoizedSelector<RootState, number> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqWorkPhoneExtension);
export const getSubscriptionEditFormSpaqPaymentMethod: MemoizedSelector<RootState, PaymentMethodInfo> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethod);
export const getSubscriptionEditFormSpaqPaymentMethodCreditCardDataKey: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodCreditCardDataKey);
export const getSubscriptionEditFormSpaqPaymentMethodEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodEffectiveDate);
export const getSubscriptionEditFormSpaqPaymentMethodEndDate: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodEndDate);
export const getSubscriptionEditFormSpaqPaymentMethodFinancialInstitutionId: MemoizedSelector<RootState, number> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodFinancialInstitutionId);
export const getSubscriptionEditFormSpaqPaymentMethodFolioNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodFolioNo);
export const getSubscriptionEditFormSpaqPaymentMethodMethod: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodMethod);
export const getSubscriptionEditFormSpaqPaymentMethodTransitNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionEditState, fromSubscriptionEdit.getFormSpaqPaymentMethodTransitNo);
export const getSubscriptionDetailsIsActive: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getIsActive);
export const getSubscriptionDetailsIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getIsLoading);
export const getSubscriptionDetailsForm: MemoizedSelector<RootState, SubscriptionInfo> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getForm);
export const getSubscriptionDetailsFormCustom: MemoizedSelector<RootState, any> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormCustom);
export const getSubscriptionDetailsFormSpaq: MemoizedSelector<RootState, SubscriptionSpaqInfo> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaq);
export const getSubscriptionDetailsFormSpaqAccesDocuments: MemoizedSelector<RootState, AccesDocumentInfo[]> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqAccesDocuments);
export const getSubscriptionDetailsFormSpaqAccessOnHoldReason: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqAccessOnHoldReason);
export const getSubscriptionDetailsFormSpaqAddressLine1: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqAddressLine1);
export const getSubscriptionDetailsFormSpaqIdentificationCode: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqIdentificationCode);
export const getSubscriptionDetailsFormSpaqAddressLine2: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqAddressLine2);
export const getSubscriptionDetailsFormSpaqAppartmentNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqAppartmentNo);
export const getSubscriptionDetailsFormSpaqBalance: MemoizedSelector<RootState, number> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqBalance);
export const getSubscriptionDetailsFormSpaqCellPhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqCellPhone);
export const getSubscriptionDetailsFormSpaqCity: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqCity);
export const getSubscriptionDetailsFormSpaqContractNo: MemoizedSelector<RootState, number> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqContractNo);
export const getSubscriptionDetailsFormSpaqDynamicFieldJsonData: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqDynamicFieldJsonData);
export const getSubscriptionDetailsFormSpaqEmail: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqEmail);
export const getSubscriptionDetailsFormSpaqFirstName: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqFirstName);
export const getSubscriptionDetailsFormSpaqHomePhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqHomePhone);
export const getSubscriptionDetailsFormSpaqHomePhoneExtension: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqHomePhoneExtension);
export const getSubscriptionDetailsFormSpaqIsAccessOnHold: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqIsAccessOnHold);
export const getSubscriptionDetailsFormSpaqLanguage: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqLanguage);
export const getSubscriptionDetailsFormSpaqLastName: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqLastName);
export const getSubscriptionDetailsFormSpaqPostalCode: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPostalCode);
export const getSubscriptionDetailsFormSpaqProvince: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqProvince);
export const getSubscriptionDetailsFormSpaqServiceCenter: MemoizedSelector<RootState, number> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqServiceCenter);
export const getSubscriptionDetailsFormSpaqSex: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqSex);
export const getSubscriptionDetailsFormSpaqWorkPhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqWorkPhone);
export const getSubscriptionDetailsFormSpaqWorkPhoneExtension: MemoizedSelector<RootState, number> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqWorkPhoneExtension);
export const getSubscriptionDetailsFormSpaqPaymentMethod: MemoizedSelector<RootState, PaymentMethodInfo> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethod);
export const getSubscriptionDetailsFormSpaqPaymentMethodCreditCardDataKey: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodCreditCardDataKey);
export const getSubscriptionDetailsFormSpaqPaymentMethodEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodEffectiveDate);
export const getSubscriptionDetailsFormSpaqPaymentMethodEndDate: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodEndDate);
export const getSubscriptionDetailsFormSpaqPaymentMethodFinancialInstitutionId: MemoizedSelector<RootState, number> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodFinancialInstitutionId);
export const getSubscriptionDetailsFormSpaqPaymentMethodFolioNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodFolioNo);
export const getSubscriptionDetailsFormSpaqPaymentMethodMethod: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodMethod);
export const getSubscriptionDetailsFormSpaqPaymentMethodTransitNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionDetailsState, fromSubscriptionDetails.getFormSpaqPaymentMethodTransitNo);
export const getRegisterEmailMessage: MemoizedSelector<RootState, string | null> = createSelector(getRegisterEmailState, fromRegisterEmail.getMessage);
export const getRegisterEmailHasError: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getHasError);
export const getRegisterEmailHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getHasSuccess);
export const getRegisterEmailIsRegistered: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getIsRegistered);
export const getRegisterEmailIsLogged: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getIsLogged);
export const getRegisterEmailIsWaitingValidation: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getIsWaitingValidation);
export const getRegisterEmailIsActive: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getIsActive);
export const getRegisterEmailIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getIsComplete);
export const getRegisterEmailIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getRegisterEmailState, fromRegisterEmail.getIsLoading);
export const getRegisterEmailForm: MemoizedSelector<RootState, RegisterEmailForm> = createSelector(getRegisterEmailState, fromRegisterEmail.getForm);
export const getRegisterEmailFormConfirmPassword: MemoizedSelector<RootState, string> = createSelector(getRegisterEmailState, fromRegisterEmail.getFormConfirmPassword);
export const getRegisterEmailFormEmail: MemoizedSelector<RootState, string> = createSelector(getRegisterEmailState, fromRegisterEmail.getFormEmail);
export const getRegisterEmailFormMethod: MemoizedSelector<RootState, string> = createSelector(getRegisterEmailState, fromRegisterEmail.getFormMethod);
export const getRegisterEmailFormPassword: MemoizedSelector<RootState, string> = createSelector(getRegisterEmailState, fromRegisterEmail.getFormPassword);
export const getRegisterEmailFormCip: MemoizedSelector<RootState, string> = createSelector(getRegisterEmailState, fromRegisterEmail.getFormCip);
export const getAccountStatusIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getIsLoading);
export const getAccountStatusHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getHasSuccess);
export const getAccountStatusHasError: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getHasError);
export const getAccountStatusMessage: MemoizedSelector<RootState, string> = createSelector(getAccountStatusState, fromAccountStatus.getMessage);
export const getAccountStatusIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getIsCompleted);
export const getAccountStatusIsAccessCodeRequired: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getIsAccessCodeRequired);
export const getAccountStatusIsSubscriptionRequired: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getIsSubscriptionRequired);
export const getAccountStatusIsCipRequired: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getIsCipRequired);
export const getAccountStatusIsContractRequired: MemoizedSelector<RootState, boolean> = createSelector(getAccountStatusState, fromAccountStatus.getIsContractRequired);
export const getContactMessage: MemoizedSelector<RootState, string | null> = createSelector(getContactState, fromContact.getMessage);
export const getContactHasError: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getHasError);
export const getContactHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getHasSuccess);
export const getContactIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getIsLoading);
export const getContactIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getIsActive);
export const getContactDisableSubject: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getDisableSubject);
export const getContactDisableNature: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getDisableNature);
export const getContactDisableContractNo: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getDisableContractNo);
export const getContactDisableService: MemoizedSelector<RootState, boolean> = createSelector(getContactState, fromContact.getDisableService);
export const getMetadataRefreshUser: MemoizedSelector<RootState, string> = createSelector(getMetadataState, fromMetadata.getRefreshUser);
export const getLoginMessage: MemoizedSelector<RootState, string | null> = createSelector(getLoginState, fromLogin.getMessage);
export const getLoginHasError: MemoizedSelector<RootState, boolean> = createSelector(getLoginState, fromLogin.getHasError);
export const getLoginHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getLoginState, fromLogin.getHasSuccess);
export const getLoginIsActive: MemoizedSelector<RootState, boolean> = createSelector(getLoginState, fromLogin.getIsActive);
export const getLoginIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getLoginState, fromLogin.getIsLoading);
export const getLoginIsLoggedIn: MemoizedSelector<RootState, boolean> = createSelector(getLoginState, fromLogin.getIsLoggedIn);
export const getLoginForm: MemoizedSelector<RootState, {
    email: string;
    password: string;
}> = createSelector(getLoginState, fromLogin.getForm);
export const getLoginFormEmail: MemoizedSelector<RootState, string> = createSelector(getLoginState, fromLogin.getFormEmail);
export const getLoginFormPassword: MemoizedSelector<RootState, string> = createSelector(getLoginState, fromLogin.getFormPassword);
export const getRegisterMessage: MemoizedSelector<RootState, string | null> = createSelector(getRegisterState, fromRegister.getMessage);
export const getRegisterHasError: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getHasError);
export const getRegisterHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getHasSuccess);
export const getRegisterIsRegistered: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getIsRegistered);
export const getRegisterIsLogged: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getIsLogged);
export const getRegisterIsWaitingValidation: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getIsWaitingValidation);
export const getRegisterIsActive: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getIsActive);
export const getRegisterIsComplete: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getIsComplete);
export const getRegisterIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getRegisterState, fromRegister.getIsLoading);
export const getRegisterForm: MemoizedSelector<RootState, RegisterEmailForm> = createSelector(getRegisterState, fromRegister.getForm);
export const getRegisterFormConfirmPassword: MemoizedSelector<RootState, string> = createSelector(getRegisterState, fromRegister.getFormConfirmPassword);
export const getRegisterFormEmail: MemoizedSelector<RootState, string> = createSelector(getRegisterState, fromRegister.getFormEmail);
export const getRegisterFormMethod: MemoizedSelector<RootState, string> = createSelector(getRegisterState, fromRegister.getFormMethod);
export const getRegisterFormPassword: MemoizedSelector<RootState, string> = createSelector(getRegisterState, fromRegister.getFormPassword);
export const getRegisterFormCip: MemoizedSelector<RootState, string> = createSelector(getRegisterState, fromRegister.getFormCip);
export const getSubscriptionMessage: MemoizedSelector<RootState, string | null> = createSelector(getSubscriptionState, fromSubscription.getMessage);
export const getSubscriptionHasError: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionState, fromSubscription.getHasError);
export const getSubscriptionHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionState, fromSubscription.getHasSuccess);
export const getSubscriptionIsHydrated: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionState, fromSubscription.getIsHydrated);
export const getSubscriptionIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionState, fromSubscription.getIsLoading);
export const getSubscriptionIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionState, fromSubscription.getIsCompleted);
export const getSubscriptionData: MemoizedSelector<RootState, SubscriptionInfo> = createSelector(getSubscriptionState, fromSubscription.getData);
export const getSubscriptionDataCustom: MemoizedSelector<RootState, any> = createSelector(getSubscriptionState, fromSubscription.getDataCustom);
export const getSubscriptionDataSpaq: MemoizedSelector<RootState, SubscriptionSpaqInfo> = createSelector(getSubscriptionState, fromSubscription.getDataSpaq);
export const getSubscriptionDataSpaqAccesDocuments: MemoizedSelector<RootState, AccesDocumentInfo[]> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqAccesDocuments);
export const getSubscriptionDataSpaqAccessOnHoldReason: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqAccessOnHoldReason);
export const getSubscriptionDataSpaqAddressLine1: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqAddressLine1);
export const getSubscriptionDataSpaqIdentificationCode: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqIdentificationCode);
export const getSubscriptionDataSpaqAddressLine2: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqAddressLine2);
export const getSubscriptionDataSpaqAppartmentNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqAppartmentNo);
export const getSubscriptionDataSpaqBalance: MemoizedSelector<RootState, number> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqBalance);
export const getSubscriptionDataSpaqCellPhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqCellPhone);
export const getSubscriptionDataSpaqCity: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqCity);
export const getSubscriptionDataSpaqContractNo: MemoizedSelector<RootState, number> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqContractNo);
export const getSubscriptionDataSpaqDynamicFieldJsonData: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqDynamicFieldJsonData);
export const getSubscriptionDataSpaqEmail: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqEmail);
export const getSubscriptionDataSpaqFirstName: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqFirstName);
export const getSubscriptionDataSpaqHomePhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqHomePhone);
export const getSubscriptionDataSpaqHomePhoneExtension: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqHomePhoneExtension);
export const getSubscriptionDataSpaqIsAccessOnHold: MemoizedSelector<RootState, boolean> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqIsAccessOnHold);
export const getSubscriptionDataSpaqLanguage: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqLanguage);
export const getSubscriptionDataSpaqLastName: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqLastName);
export const getSubscriptionDataSpaqPostalCode: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPostalCode);
export const getSubscriptionDataSpaqProvince: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqProvince);
export const getSubscriptionDataSpaqServiceCenter: MemoizedSelector<RootState, number> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqServiceCenter);
export const getSubscriptionDataSpaqSex: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqSex);
export const getSubscriptionDataSpaqWorkPhone: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqWorkPhone);
export const getSubscriptionDataSpaqWorkPhoneExtension: MemoizedSelector<RootState, number> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqWorkPhoneExtension);
export const getSubscriptionDataSpaqPaymentMethod: MemoizedSelector<RootState, PaymentMethodInfo> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethod);
export const getSubscriptionDataSpaqPaymentMethodCreditCardDataKey: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodCreditCardDataKey);
export const getSubscriptionDataSpaqPaymentMethodEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodEffectiveDate);
export const getSubscriptionDataSpaqPaymentMethodEndDate: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodEndDate);
export const getSubscriptionDataSpaqPaymentMethodFinancialInstitutionId: MemoizedSelector<RootState, number> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodFinancialInstitutionId);
export const getSubscriptionDataSpaqPaymentMethodFolioNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodFolioNo);
export const getSubscriptionDataSpaqPaymentMethodMethod: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodMethod);
export const getSubscriptionDataSpaqPaymentMethodTransitNo: MemoizedSelector<RootState, string> = createSelector(getSubscriptionState, fromSubscription.getDataSpaqPaymentMethodTransitNo);
export const getProfileEditMessage: MemoizedSelector<RootState, string | null> = createSelector(getProfileEditState, fromProfileEdit.getMessage);
export const getProfileEditIsActive: MemoizedSelector<RootState, boolean> = createSelector(getProfileEditState, fromProfileEdit.getIsActive);
export const getProfileEditHasError: MemoizedSelector<RootState, boolean> = createSelector(getProfileEditState, fromProfileEdit.getHasError);
export const getProfileEditHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getProfileEditState, fromProfileEdit.getHasSuccess);
export const getProfileEditIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getProfileEditState, fromProfileEdit.getIsLoading);
export const getProfileEditIsPending: MemoizedSelector<RootState, boolean> = createSelector(getProfileEditState, fromProfileEdit.getIsPending);
export const getProfileEditForm: MemoizedSelector<RootState, ProfileEditForm | null> = createSelector(getProfileEditState, fromProfileEdit.getForm);
export const getProfileEditFormDisplayName: MemoizedSelector<RootState, string> = createSelector(getProfileEditState, fromProfileEdit.getFormDisplayName);
export const getProfileEditFormPhotoUrl: MemoizedSelector<RootState, string> = createSelector(getProfileEditState, fromProfileEdit.getFormPhotoUrl);
export const getProfileDetailsIsActive: MemoizedSelector<RootState, boolean> = createSelector(getProfileDetailsState, fromProfileDetails.getIsActive);
export const getProfileIsHydrated: MemoizedSelector<RootState, boolean> = createSelector(getProfileState, fromProfile.getIsHydrated);
export const getProfileMessage: MemoizedSelector<RootState, string | null> = createSelector(getProfileState, fromProfile.getMessage);
export const getProfileHasError: MemoizedSelector<RootState, boolean> = createSelector(getProfileState, fromProfile.getHasError);
export const getProfileHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getProfileState, fromProfile.getHasSuccess);
export const getProfileIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getProfileState, fromProfile.getIsLoading);
export const getProfileIsPending: MemoizedSelector<RootState, boolean> = createSelector(getProfileState, fromProfile.getIsPending);
export const getProfileData: MemoizedSelector<RootState, ProfileInfo | null> = createSelector(getProfileState, fromProfile.getData);
export const getProfileDataCreatedAt: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataCreatedAt);
export const getProfileDataEmail: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataEmail);
export const getProfileDataEmailVerified: MemoizedSelector<RootState, boolean> = createSelector(getProfileState, fromProfile.getDataEmailVerified);
export const getProfileDataLastLoginAt: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataLastLoginAt);
export const getProfileDataPhoneNumber: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataPhoneNumber);
export const getProfileDataUid: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataUid);
export const getProfileDataDisplayName: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataDisplayName);
export const getProfileDataPhotoUrl: MemoizedSelector<RootState, string> = createSelector(getProfileState, fromProfile.getDataPhotoUrl);
export const getLayoutIsPublicArea: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getIsPublicArea);
export const getLayoutShowSidebav: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getShowSidebav);
export const getLayoutIsScope: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getIsScope);
export const getLayoutScopeId: MemoizedSelector<RootState, string> = createSelector(getLayoutState, fromLayout.getScopeId);
export const getLayoutScreen: MemoizedSelector<RootState, LayoutScreenInfo> = createSelector(getLayoutState, fromLayout.getScreen);
export const getLayoutScreenDesktop: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScreenDesktop);
export const getLayoutScreenMobile: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScreenMobile);
export const getLayoutScreenTablet: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScreenTablet);
export const getLayoutScopeConfig: MemoizedSelector<RootState, OrganizationConfigInfo> = createSelector(getLayoutState, fromLayout.getScopeConfig);
export const getLayoutScopeConfigEnableGateway: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScopeConfigEnableGateway);
export const getLayoutScopeConfigEnablePublicRegistration: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScopeConfigEnablePublicRegistration);
export const getLayoutScopeConfigEnablePrivateRegistration: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScopeConfigEnablePrivateRegistration);
export const getLayoutScopeConfigDisablePublicSubscriptionService: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getScopeConfigDisablePublicSubscriptionService);
export const getLayoutEnableAdminOptions: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getEnableAdminOptions);
export const getLayoutIsAdminMenu: MemoizedSelector<RootState, boolean> = createSelector(getLayoutState, fromLayout.getIsAdminMenu);
export const getLayoutSelectedGroup: MemoizedSelector<RootState, string | null> = createSelector(getLayoutState, fromLayout.getSelectedGroup);
export const getLayoutSelectedItem: MemoizedSelector<RootState, string | null> = createSelector(getLayoutState, fromLayout.getSelectedItem);
export const getLayoutAdminMenu: MemoizedSelector<RootState, {
    [k: string]: NodeItem | null;
}> = createSelector(getLayoutState, fromLayout.getAdminMenu);
export const getLayoutMenu: MemoizedSelector<RootState, {
    [k: string]: NodeItem | null;
}> = createSelector(getLayoutState, fromLayout.getMenu);
export const getCalendarMessage: MemoizedSelector<RootState, string | null> = createSelector(getCalendarState, fromCalendar.getMessage);
export const getCalendarHasError: MemoizedSelector<RootState, boolean> = createSelector(getCalendarState, fromCalendar.getHasError);
export const getCalendarHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getCalendarState, fromCalendar.getHasSuccess);
export const getCalendarIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getCalendarState, fromCalendar.getIsLoading);
export const getCalendarIsActive: MemoizedSelector<RootState, boolean> = createSelector(getCalendarState, fromCalendar.getIsActive);
export const getParksSelectIsActive: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getIsActive);
export const getParksSelectMessage: MemoizedSelector<RootState, string | null> = createSelector(getParksSelectState, fromParksSelect.getMessage);
export const getParksSelectHasError: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getHasError);
export const getParksSelectHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getHasSuccess);
export const getParksSelectIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getIsLoading);
export const getParksSelectShowEmpty: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getShowEmpty);
export const getParksSelectShowPrivate: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getShowPrivate);
export const getParksSelectShowPublic: MemoizedSelector<RootState, boolean> = createSelector(getParksSelectState, fromParksSelect.getShowPublic);
export const getParksSelectSelectedPark: MemoizedSelector<RootState, ParkInfo | null> = createSelector(getParksSelectState, fromParksSelect.getSelectedPark);
export const getParksSelectSelectedParkCity: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkCity);
export const getParksSelectSelectedParkCivicNumber1: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkCivicNumber1);
export const getParksSelectSelectedParkCivicNumber2: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkCivicNumber2);
export const getParksSelectSelectedParkEmail: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkEmail);
export const getParksSelectSelectedParkGpsLatitude: MemoizedSelector<RootState, number> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkGpsLatitude);
export const getParksSelectSelectedParkGpsLongitude: MemoizedSelector<RootState, number> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkGpsLongitude);
export const getParksSelectSelectedParkParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkParkIdentificationNo);
export const getParksSelectSelectedParkParkName: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkParkName);
export const getParksSelectSelectedParkPhoneExtension: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkPhoneExtension);
export const getParksSelectSelectedParkPhoneNo: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkPhoneNo);
export const getParksSelectSelectedParkPostalCode: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkPostalCode);
export const getParksSelectSelectedParkProvince: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkProvince);
export const getParksSelectSelectedParkServerHostName: MemoizedSelector<RootState, string> = createSelector(getParksSelectState, fromParksSelect.getSelectedParkServerHostName);
export const getParksIds: MemoizedSelector<RootState, number[] | string[]> = createSelector(getParksState, fromParks.getIds);
export const getParksEntities: MemoizedSelector<RootState, {
    [k: string]: ParkInfo;
}> = createSelector(getParksState, fromParks.getEntities);
export const getParksAll: MemoizedSelector<RootState, ParkInfo[]> = createSelector(getParksState, fromParks.getAll);
export const getParksTotal: MemoizedSelector<RootState, number> = createSelector(getParksState, fromParks.getTotal);
export const getParksMessage: MemoizedSelector<RootState, string | null> = createSelector(getParksState, fromParks.getMessage);
export const getParksHasError: MemoizedSelector<RootState, boolean> = createSelector(getParksState, fromParks.getHasError);
export const getParksHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getParksState, fromParks.getHasSuccess);
export const getParksIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getParksState, fromParks.getIsLoading);
export const getParksSelected: MemoizedSelector<RootState, string | number | null> = createSelector(getParksState, fromParks.getSelected);
export const getContractConditionsPaymentIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getIsLoading);
export const getContractConditionsPaymentHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getHasSuccess);
export const getContractConditionsPaymentHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getHasError);
export const getContractConditionsPaymentMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getMessage);
export const getContractConditionsPaymentText: MemoizedSelector<RootState, string> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getText);
export const getContractConditionsPaymentIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getIsActive);
export const getContractConditionsPaymentAcceptedAt: MemoizedSelector<RootState, string> = createSelector(getContractConditionsPaymentState, fromContractConditionsPayment.getAcceptedAt);
export const getContractConditionsServiceIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getIsLoading);
export const getContractConditionsServiceHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getHasSuccess);
export const getContractConditionsServiceHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getHasError);
export const getContractConditionsServiceMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getMessage);
export const getContractConditionsServiceText: MemoizedSelector<RootState, string> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getText);
export const getContractConditionsServiceIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getIsActive);
export const getContractConditionsServiceAcceptedAt: MemoizedSelector<RootState, string> = createSelector(getContractConditionsServiceState, fromContractConditionsService.getAcceptedAt);
export const getContractDetailsIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getIsActive);
export const getContractDetailsMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractDetailsState, fromContractDetails.getMessage);
export const getContractDetailsHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getHasError);
export const getContractDetailsHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getHasSuccess);
export const getContractDetailsIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getIsLoading);
export const getContractDetailsIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getIsCompleted);
export const getContractDetailsFreshlyCreated: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getFreshlyCreated);
export const getContractDetailsPdfSuccess: MemoizedSelector<RootState, boolean | null> = createSelector(getContractDetailsState, fromContractDetails.getPdfSuccess);
export const getContractDetailsCanUpdatePpa: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getCanUpdatePpa);
export const getContractDetailsCanUpdateCc: MemoizedSelector<RootState, boolean> = createSelector(getContractDetailsState, fromContractDetails.getCanUpdateCc);
export const getContractCreateIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getIsActive);
export const getContractCreateMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractCreateState, fromContractCreate.getMessage);
export const getContractCreateHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getHasError);
export const getContractCreateHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getHasSuccess);
export const getContractCreateIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getIsLoading);
export const getContractCreateHasSubscriptionUpdate: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getHasSubscriptionUpdate);
export const getContractCreateIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getIsCompleted);
export const getContractCreateStepCompleted: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getStepCompleted);
export const getContractCreateHasVehicles: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getHasVehicles);
export const getContractCreateStep: MemoizedSelector<RootState, number> = createSelector(getContractCreateState, fromContractCreate.getStep);
export const getContractCreateSelectedParkId: MemoizedSelector<RootState, number | null> = createSelector(getContractCreateState, fromContractCreate.getSelectedParkId);
export const getContractCreateSelectedServiceId: MemoizedSelector<RootState, number | null> = createSelector(getContractCreateState, fromContractCreate.getSelectedServiceId);
export const getContractCreateSelectedPayment: MemoizedSelector<RootState, string | null> = createSelector(getContractCreateState, fromContractCreate.getSelectedPayment);
export const getContractCreateConditionsServiceAcceptedAt: MemoizedSelector<RootState, string> = createSelector(getContractCreateState, fromContractCreate.getConditionsServiceAcceptedAt);
export const getContractCreateConditionsPaymentAcceptedAt: MemoizedSelector<RootState, string> = createSelector(getContractCreateState, fromContractCreate.getConditionsPaymentAcceptedAt);
export const getContractCreateIsVisibleVehicleForm: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getIsVisibleVehicleForm);
export const getContractCreateSkipPayment: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getSkipPayment);
export const getContractCreateSkipPaymentConditions: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getSkipPaymentConditions);
export const getContractCreatePaymentAuthRequired: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getPaymentAuthRequired);
export const getContractCreateChoosePaymentCompleted: MemoizedSelector<RootState, boolean> = createSelector(getContractCreateState, fromContractCreate.getChoosePaymentCompleted);
export const getContractCancelIsActive: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getIsActive);
export const getContractCancelMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractCancelState, fromContractCancel.getMessage);
export const getContractCancelHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getHasError);
export const getContractCancelHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getHasSuccess);
export const getContractCancelIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getIsLoading);
export const getContractCancelIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getIsCompleted);
export const getContractCancelSelectedDate: MemoizedSelector<RootState, string | null> = createSelector(getContractCancelState, fromContractCancel.getSelectedDate);
export const getContractCancelSelectedService: MemoizedSelector<RootState, SubscriptionService | null> = createSelector(getContractCancelState, fromContractCancel.getSelectedService);
export const getContractCancelSelectedServiceEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceEffectiveDate);
export const getContractCancelSelectedServiceEndDate: MemoizedSelector<RootState, string> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceEndDate);
export const getContractCancelSelectedServiceIsApproved: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceIsApproved);
export const getContractCancelSelectedServiceIsPaidByGroup: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceIsPaidByGroup);
export const getContractCancelSelectedServiceServiceDescription: MemoizedSelector<RootState, string> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceServiceDescription);
export const getContractCancelSelectedServiceServiceId: MemoizedSelector<RootState, number> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceServiceId);
export const getContractCancelSelectedServiceId: MemoizedSelector<RootState, number> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceId);
export const getContractCancelSelectedServiceInvoiceAmount: MemoizedSelector<RootState, string> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceInvoiceAmount);
export const getContractCancelSelectedServiceInvoiceTo: MemoizedSelector<RootState, string> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceInvoiceTo);
export const getContractCancelSelectedServiceInvoiceFrom: MemoizedSelector<RootState, string> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceInvoiceFrom);
export const getContractCancelSelectedServiceIsCancellationRequest: MemoizedSelector<RootState, boolean> = createSelector(getContractCancelState, fromContractCancel.getSelectedServiceIsCancellationRequest);
export const getContractMessage: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getMessage);
export const getContractHasError: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getHasError);
export const getContractHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getHasSuccess);
export const getContractIsLoaded: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getIsLoaded);
export const getContractIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getIsLoading);
export const getContractIsPending: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getIsPending);
export const getContractPaymenRequired: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getPaymenRequired);
export const getContractIsCancelable: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getIsCancelable);
export const getContractIsEnded: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getIsEnded);
export const getContractData: MemoizedSelector<RootState, ContractInfo | null> = createSelector(getContractState, fromContract.getData);
export const getContractDataIsCreationCompleted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataIsCreationCompleted);
export const getContractDataIsPaid: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataIsPaid);
export const getContractDataPaymentBeforeApproval: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataPaymentBeforeApproval);
export const getContractDataPark: MemoizedSelector<RootState, ParkInfo> = createSelector(getContractState, fromContract.getDataPark);
export const getContractDataParkCity: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkCity);
export const getContractDataParkCivicNumber1: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkCivicNumber1);
export const getContractDataParkCivicNumber2: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkCivicNumber2);
export const getContractDataParkEmail: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkEmail);
export const getContractDataParkGpsLatitude: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataParkGpsLatitude);
export const getContractDataParkGpsLongitude: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataParkGpsLongitude);
export const getContractDataParkParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataParkParkIdentificationNo);
export const getContractDataParkParkName: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkParkName);
export const getContractDataParkPhoneExtension: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkPhoneExtension);
export const getContractDataParkPhoneNo: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkPhoneNo);
export const getContractDataParkPostalCode: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkPostalCode);
export const getContractDataParkProvince: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkProvince);
export const getContractDataParkServerHostName: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataParkServerHostName);
export const getContractDataCost: MemoizedSelector<RootState, ContractCostInfo> = createSelector(getContractState, fromContract.getDataCost);
export const getContractDataCostInvoiceId: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataCostInvoiceId);
export const getContractDataCostInvoiceDate: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataCostInvoiceDate);
export const getContractDataCostInvoiceDescription: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataCostInvoiceDescription);
export const getContractDataCostPaidAmount: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataCostPaidAmount);
export const getContractDataCostInvoiceAmount: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataCostInvoiceAmount);
export const getContractDataCostInvoiceTax1: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataCostInvoiceTax1);
export const getContractDataCostInvoiceTax2: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataCostInvoiceTax2);
export const getContractDataCostInvoiceTotalAmount: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataCostInvoiceTotalAmount);
export const getContractDataService: MemoizedSelector<RootState, ServiceInfo> = createSelector(getContractState, fromContract.getDataService);
export const getContractDataServicePeriod: MemoizedSelector<RootState, any> = createSelector(getContractState, fromContract.getDataServicePeriod);
export const getContractDataServiceAvailableFrom: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataServiceAvailableFrom);
export const getContractDataServiceAvailableServiceId: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataServiceAvailableServiceId);
export const getContractDataServiceAvailableUntil: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceAvailableUntil);
export const getContractDataServiceCancelationNotice: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataServiceCancelationNotice);
export const getContractDataServiceCancelationNoticeTimeUnit: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceCancelationNoticeTimeUnit);
export const getContractDataServiceDuration: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataServiceDuration);
export const getContractDataServiceDurationTimeUnit: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceDurationTimeUnit);
export const getContractDataServiceIsBankingCardPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsBankingCardPaymentAccepted);
export const getContractDataServiceIsCancelable: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsCancelable);
export const getContractDataServiceIsCancelationFirstDayOfMonth: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsCancelationFirstDayOfMonth);
export const getContractDataServiceIsDasPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsDasPaymentAccepted);
export const getContractDataServiceIsFinancialInstitutionPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsFinancialInstitutionPaymentAccepted);
export const getContractDataServiceIsFixedDuration: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsFixedDuration);
export const getContractDataServiceIsPpaPaymentAccepted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsPpaPaymentAccepted);
export const getContractDataServiceIsSubscriptionAvailable: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsSubscriptionAvailable);
export const getContractDataServiceIsTax1Exempted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsTax1Exempted);
export const getContractDataServiceIsTax2Exempted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsTax2Exempted);
export const getContractDataServiceIsTax3Exempted: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataServiceIsTax3Exempted);
export const getContractDataServiceOnSaleFrom: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceOnSaleFrom);
export const getContractDataServiceOnSaleUntil: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceOnSaleUntil);
export const getContractDataServiceParkIdentificationNo: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataServiceParkIdentificationNo);
export const getContractDataServicePartialMonthCalculation: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServicePartialMonthCalculation);
export const getContractDataServicePrice: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataServicePrice);
export const getContractDataServiceSectorId: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataServiceSectorId);
export const getContractDataServiceServiceBillingType: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceServiceBillingType);
export const getContractDataServiceServiceDescription: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceServiceDescription);
export const getContractDataServiceServiceStartRule: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceServiceStartRule);
export const getContractDataServiceServiceType: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceServiceType);
export const getContractDataServiceMeta: MemoizedSelector<RootState, ServiceMetaInfo> = createSelector(getContractState, fromContract.getDataServiceMeta);
export const getContractDataServiceMetaNote: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataServiceMetaNote);
export const getContractDataPdfLink: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataPdfLink);
export const getContractDataPdfContent: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataPdfContent);
export const getContractDataPayment: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataPayment);
export const getContractDataSectorId: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataSectorId);
export const getContractDataScopeId: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataScopeId);
export const getContractDataGroupId: MemoizedSelector<RootState, string | null> = createSelector(getContractState, fromContract.getDataGroupId);
export const getContractDataSubscriptionService: MemoizedSelector<RootState, SubscriptionService> = createSelector(getContractState, fromContract.getDataSubscriptionService);
export const getContractDataSubscriptionServiceEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionServiceEffectiveDate);
export const getContractDataSubscriptionServiceEndDate: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionServiceEndDate);
export const getContractDataSubscriptionServiceIsApproved: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataSubscriptionServiceIsApproved);
export const getContractDataSubscriptionServiceIsPaidByGroup: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataSubscriptionServiceIsPaidByGroup);
export const getContractDataSubscriptionServiceServiceDescription: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionServiceServiceDescription);
export const getContractDataSubscriptionServiceServiceId: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionServiceServiceId);
export const getContractDataSubscriptionServiceId: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionServiceId);
export const getContractDataSubscriptionServiceInvoiceAmount: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionServiceInvoiceAmount);
export const getContractDataSubscriptionServiceInvoiceTo: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionServiceInvoiceTo);
export const getContractDataSubscriptionServiceInvoiceFrom: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionServiceInvoiceFrom);
export const getContractDataSubscriptionServiceIsCancellationRequest: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataSubscriptionServiceIsCancellationRequest);
export const getContractDataSubscription: MemoizedSelector<RootState, SubscriptionInfo> = createSelector(getContractState, fromContract.getDataSubscription);
export const getContractDataSubscriptionCustom: MemoizedSelector<RootState, any> = createSelector(getContractState, fromContract.getDataSubscriptionCustom);
export const getContractDataSubscriptionSpaq: MemoizedSelector<RootState, SubscriptionSpaqInfo> = createSelector(getContractState, fromContract.getDataSubscriptionSpaq);
export const getContractDataSubscriptionSpaqAccesDocuments: MemoizedSelector<RootState, AccesDocumentInfo[]> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqAccesDocuments);
export const getContractDataSubscriptionSpaqAccessOnHoldReason: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqAccessOnHoldReason);
export const getContractDataSubscriptionSpaqAddressLine1: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqAddressLine1);
export const getContractDataSubscriptionSpaqIdentificationCode: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqIdentificationCode);
export const getContractDataSubscriptionSpaqAddressLine2: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqAddressLine2);
export const getContractDataSubscriptionSpaqAppartmentNo: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqAppartmentNo);
export const getContractDataSubscriptionSpaqBalance: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqBalance);
export const getContractDataSubscriptionSpaqCellPhone: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqCellPhone);
export const getContractDataSubscriptionSpaqCity: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqCity);
export const getContractDataSubscriptionSpaqContractNo: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqContractNo);
export const getContractDataSubscriptionSpaqDynamicFieldJsonData: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqDynamicFieldJsonData);
export const getContractDataSubscriptionSpaqEmail: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqEmail);
export const getContractDataSubscriptionSpaqFirstName: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqFirstName);
export const getContractDataSubscriptionSpaqHomePhone: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqHomePhone);
export const getContractDataSubscriptionSpaqHomePhoneExtension: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqHomePhoneExtension);
export const getContractDataSubscriptionSpaqIsAccessOnHold: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqIsAccessOnHold);
export const getContractDataSubscriptionSpaqLanguage: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqLanguage);
export const getContractDataSubscriptionSpaqLastName: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqLastName);
export const getContractDataSubscriptionSpaqPostalCode: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPostalCode);
export const getContractDataSubscriptionSpaqProvince: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqProvince);
export const getContractDataSubscriptionSpaqServiceCenter: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqServiceCenter);
export const getContractDataSubscriptionSpaqSex: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqSex);
export const getContractDataSubscriptionSpaqWorkPhone: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqWorkPhone);
export const getContractDataSubscriptionSpaqWorkPhoneExtension: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqWorkPhoneExtension);
export const getContractDataSubscriptionSpaqPaymentMethod: MemoizedSelector<RootState, PaymentMethodInfo> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethod);
export const getContractDataSubscriptionSpaqPaymentMethodCreditCardDataKey: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodCreditCardDataKey);
export const getContractDataSubscriptionSpaqPaymentMethodEffectiveDate: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodEffectiveDate);
export const getContractDataSubscriptionSpaqPaymentMethodEndDate: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodEndDate);
export const getContractDataSubscriptionSpaqPaymentMethodFinancialInstitutionId: MemoizedSelector<RootState, number> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodFinancialInstitutionId);
export const getContractDataSubscriptionSpaqPaymentMethodFolioNo: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodFolioNo);
export const getContractDataSubscriptionSpaqPaymentMethodMethod: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodMethod);
export const getContractDataSubscriptionSpaqPaymentMethodTransitNo: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataSubscriptionSpaqPaymentMethodTransitNo);
export const getContractDataVehicles: MemoizedSelector<RootState, Vehicule[]> = createSelector(getContractState, fromContract.getDataVehicles);
export const getContractDataConditionsServiceAcceptedAt: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataConditionsServiceAcceptedAt);
export const getContractDataConditionsPaymentAcceptedAt: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataConditionsPaymentAcceptedAt);
export const getContractDataCreatedAt: MemoizedSelector<RootState, string> = createSelector(getContractState, fromContract.getDataCreatedAt);
export const getContractDataPdfSuccess: MemoizedSelector<RootState, boolean> = createSelector(getContractState, fromContract.getDataPdfSuccess);
export const getSectorsMessage: MemoizedSelector<RootState, string | null> = createSelector(getSectorsState, fromSectors.getMessage);
export const getSectorsPending: MemoizedSelector<RootState, boolean> = createSelector(getSectorsState, fromSectors.getPending);
export const getSectorsIsServiceRunning: MemoizedSelector<RootState, boolean> = createSelector(getSectorsState, fromSectors.getIsServiceRunning);
export const getSectorsLoading: MemoizedSelector<RootState, boolean> = createSelector(getSectorsState, fromSectors.getLoading);
export const getSectorsHasError: MemoizedSelector<RootState, boolean> = createSelector(getSectorsState, fromSectors.getHasError);
export const getSectorsHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getSectorsState, fromSectors.getHasSuccess);
export const getSectorsIsHydrated: MemoizedSelector<RootState, boolean> = createSelector(getSectorsState, fromSectors.getIsHydrated);
export const getSectorsCollection: MemoizedSelector<RootState, SectorInfo[]> = createSelector(getSectorsState, fromSectors.getCollection);
export const getOrganizationIds: MemoizedSelector<RootState, number[] | string[]> = createSelector(getOrganizationState, fromOrganization.getIds);
export const getOrganizationEntities: MemoizedSelector<RootState, {
    [k: string]: OrganizationInfo;
}> = createSelector(getOrganizationState, fromOrganization.getEntities);
export const getOrganizationAll: MemoizedSelector<RootState, OrganizationInfo[]> = createSelector(getOrganizationState, fromOrganization.getAll);
export const getOrganizationTotal: MemoizedSelector<RootState, number> = createSelector(getOrganizationState, fromOrganization.getTotal);
export const getOrganizationMessage: MemoizedSelector<RootState, string | null> = createSelector(getOrganizationState, fromOrganization.getMessage);
export const getOrganizationHasError: MemoizedSelector<RootState, boolean> = createSelector(getOrganizationState, fromOrganization.getHasError);
export const getOrganizationHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getOrganizationState, fromOrganization.getHasSuccess);
export const getOrganizationIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getOrganizationState, fromOrganization.getIsLoading);
export const getOrganizationSelected: MemoizedSelector<RootState, string | number | null> = createSelector(getOrganizationState, fromOrganization.getSelected);
export const getFaqIsActive: MemoizedSelector<RootState, boolean> = createSelector(getFaqState, fromFaq.getIsActive);
export const getFaqHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getFaqState, fromFaq.getHasSuccess);
export const getFaqHasError: MemoizedSelector<RootState, boolean> = createSelector(getFaqState, fromFaq.getHasError);
export const getFaqMessage: MemoizedSelector<RootState, string | null> = createSelector(getFaqState, fromFaq.getMessage);
export const getFaqIsHydrated: MemoizedSelector<RootState, boolean> = createSelector(getFaqState, fromFaq.getIsHydrated);
export const getFaqCollection: MemoizedSelector<RootState, FaqDataInfo> = createSelector(getFaqState, fromFaq.getCollection);
export const getFaqCollectionGroups: MemoizedSelector<RootState, FaqGroupInfo[]> = createSelector(getFaqState, fromFaq.getCollectionGroups);
export const getFaqCollectionList: MemoizedSelector<RootState, FaqInfo[]> = createSelector(getFaqState, fromFaq.getCollectionList);
export const getFaqSelectedFaqId: MemoizedSelector<RootState, string> = createSelector(getFaqState, fromFaq.getSelectedFaqId);
export const getFaqErrorMessage: MemoizedSelector<RootState, string | null> = createSelector(getFaqState, fromFaq.getErrorMessage);
export const getFaqSuccessMessage: MemoizedSelector<RootState, string | null> = createSelector(getFaqState, fromFaq.getSuccessMessage);
export const getFaqSelectedOrganizationId: MemoizedSelector<RootState, number> = createSelector(getFaqState, fromFaq.getSelectedOrganizationId);
export const getFaqIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getFaqState, fromFaq.getIsLoading);
export const getSessionIsLoaded: MemoizedSelector<RootState, boolean> = createSelector(getSessionState, fromSession.getIsLoaded);
export const getSessionIsAnonymous: MemoizedSelector<RootState, boolean> = createSelector(getSessionState, fromSession.getIsAnonymous);
export const getSessionEmailVerified: MemoizedSelector<RootState, boolean> = createSelector(getSessionState, fromSession.getEmailVerified);
export const getSessionLastLoginAt: MemoizedSelector<RootState, string | null> = createSelector(getSessionState, fromSession.getLastLoginAt);
export const getSessionUid: MemoizedSelector<RootState, string | null> = createSelector(getSessionState, fromSession.getUid);
export const getSessionApiKey: MemoizedSelector<RootState, string | null> = createSelector(getSessionState, fromSession.getApiKey);
export const getSessionEmail: MemoizedSelector<RootState, string> = createSelector(getSessionState, fromSession.getEmail);
export const getSessionDisplayName: MemoizedSelector<RootState, string> = createSelector(getSessionState, fromSession.getDisplayName);
export const getSessionCreatedAt: MemoizedSelector<RootState, string> = createSelector(getSessionState, fromSession.getCreatedAt);
export const getSessionPhoneNumber: MemoizedSelector<RootState, string> = createSelector(getSessionState, fromSession.getPhoneNumber);
export const getSessionPhotoUrl: MemoizedSelector<RootState, string> = createSelector(getSessionState, fromSession.getPhotoUrl);
export const getSessionClaims: MemoizedSelector<RootState, {
    admin: boolean;
    accessLevel: number;
}> = createSelector(getSessionState, fromSession.getClaims);
export const getSessionClaimsAdmin: MemoizedSelector<RootState, boolean> = createSelector(getSessionState, fromSession.getClaimsAdmin);
export const getSessionClaimsAccessLevel: MemoizedSelector<RootState, number> = createSelector(getSessionState, fromSession.getClaimsAccessLevel);
export const getOobMessage: MemoizedSelector<RootState, string | null> = createSelector(getOobState, fromOob.getMessage);
export const getOobHasError: MemoizedSelector<RootState, boolean> = createSelector(getOobState, fromOob.getHasError);
export const getOobHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getOobState, fromOob.getHasSuccess);
export const getOobIsActive: MemoizedSelector<RootState, boolean> = createSelector(getOobState, fromOob.getIsActive);
export const getOobIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getOobState, fromOob.getIsLoading);
export const getOobIsCompleted: MemoizedSelector<RootState, boolean> = createSelector(getOobState, fromOob.getIsCompleted);
export const getOobApiKey: MemoizedSelector<RootState, string | null> = createSelector(getOobState, fromOob.getApiKey);
export const getOobMode: MemoizedSelector<RootState, string | null> = createSelector(getOobState, fromOob.getMode);
export const getOobOobCode: MemoizedSelector<RootState, string> = createSelector(getOobState, fromOob.getOobCode);
export const getOobContinueUrl: MemoizedSelector<RootState, string> = createSelector(getOobState, fromOob.getContinueUrl);
export const getOobLang: MemoizedSelector<RootState, string> = createSelector(getOobState, fromOob.getLang);
export const getAuthApplyActionCodePending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getApplyActionCodePending);
export const getAuthCheckActionCodePending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getCheckActionCodePending);
export const getAuthConfirmPasswordResetPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getConfirmPasswordResetPending);
export const getAuthCreateUserWithEmailAndPasswordPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getCreateUserWithEmailAndPasswordPending);
export const getAuthGetRedirectResultPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getGetRedirectResultPending);
export const getAuthSendPasswordResetEmailPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSendPasswordResetEmailPending);
export const getAuthSendSignInLinkToEmailPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSendSignInLinkToEmailPending);
export const getAuthSetPersistencePending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSetPersistencePending);
export const getAuthSignInAndRetrieveDataWithCredentialPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInAndRetrieveDataWithCredentialPending);
export const getAuthSignInAnonymouslyPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInAnonymouslyPending);
export const getAuthSignInWithCustomTokenPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInWithCustomTokenPending);
export const getAuthSignInWithEmailAndPasswordPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInWithEmailAndPasswordPending);
export const getAuthSignInWithEmailLinkPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInWithEmailLinkPending);
export const getAuthSignInWithPhoneNumberPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInWithPhoneNumberPending);
export const getAuthSignInWithPopupPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInWithPopupPending);
export const getAuthSignInWithRedirectPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignInWithRedirectPending);
export const getAuthUpdateCurrentUserPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getUpdateCurrentUserPending);
export const getAuthVerifyPasswordResetCodePending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getVerifyPasswordResetCodePending);
export const getAuthSignOutPending: MemoizedSelector<RootState, boolean> = createSelector(getAuthState, fromAuth.getSignOutPending);
export const getEmailEditMessage: MemoizedSelector<RootState, string | null> = createSelector(getEmailEditState, fromEmailEdit.getMessage);
export const getEmailEditHasError: MemoizedSelector<RootState, boolean> = createSelector(getEmailEditState, fromEmailEdit.getHasError);
export const getEmailEditHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getEmailEditState, fromEmailEdit.getHasSuccess);
export const getEmailEditIsActive: MemoizedSelector<RootState, boolean> = createSelector(getEmailEditState, fromEmailEdit.getIsActive);
export const getEmailEditIsLoading: MemoizedSelector<RootState, boolean> = createSelector(getEmailEditState, fromEmailEdit.getIsLoading);
export const getEmailEditIsFormVisible: MemoizedSelector<RootState, boolean> = createSelector(getEmailEditState, fromEmailEdit.getIsFormVisible);
export const getEmailEditForm: MemoizedSelector<RootState, EmailEditForm> = createSelector(getEmailEditState, fromEmailEdit.getForm);
export const getEmailEditFormEmail: MemoizedSelector<RootState, string> = createSelector(getEmailEditState, fromEmailEdit.getFormEmail);
export const getEmailEditFormCurrentPassword: MemoizedSelector<RootState, string> = createSelector(getEmailEditState, fromEmailEdit.getFormCurrentPassword);
export const getPopupMessage: MemoizedSelector<RootState, string | null> = createSelector(getPopupState, fromPopup.getMessage);
export const getPopupHasError: MemoizedSelector<RootState, boolean> = createSelector(getPopupState, fromPopup.getHasError);
export const getPopupHasSuccess: MemoizedSelector<RootState, boolean> = createSelector(getPopupState, fromPopup.getHasSuccess);
export const getPopupOpened: MemoizedSelector<RootState, boolean> = createSelector(getPopupState, fromPopup.getOpened);
export const getPopupTitle: MemoizedSelector<RootState, string> = createSelector(getPopupState, fromPopup.getTitle);
export const getPopupContent: MemoizedSelector<RootState, string> = createSelector(getPopupState, fromPopup.getContent);
export const getPopupWidth: MemoizedSelector<RootState, number> = createSelector(getPopupState, fromPopup.getWidth);
export const getPopupHeight: MemoizedSelector<RootState, number> = createSelector(getPopupState, fromPopup.getHeight);
export const getNetworkStatusIsOnline: MemoizedSelector<RootState, boolean> = createSelector(getNetworkStatusState, fromNetworkStatus.getIsOnline);
export const getOrganizationById = createSelector(getOrganizationEntities, (a, b) => {
    return a[b];
});
export const getSectorsCollectionById = createSelector(getSectorsCollection, (a: SectorInfo[], b) => {
    const index = a.findIndex(s => s.sectorId === b);
    return index >= 0 ? a[index] : null;
});
export const getParksById = createSelector(getParksEntities, (a, b) => {
    return a[b];
});
export const getServicesById = createSelector(getServicesEntities, (a, b) => {
    return a[b];
});
export const getAdminFaqListGroupCollectionById = createSelector(getAdminFaqListGroupCollection, (a: FaqGroupInfo[], b) => {
    const index = a.findIndex(s => s.id === b);
    return index >= 0 ? a[index] : null;
});
export const getAdminFaqListCollectionById = createSelector(getAdminFaqListCollection, (a: FaqInfo[], b) => {
    const index = a.findIndex(s => s.id === b);
    return index >= 0 ? a[index] : null;
});
