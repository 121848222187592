import { ADMIN_MAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
}
export const initialState: State = {};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_MAILS.INIT: return adminMailsInit(state);
        case ADMIN_MAILS.DESTROY: return adminMailsDestroy(state);
        default: return state;
    }
}
export function adminMailsInit(state: State): State {
    return state;
}
export function adminMailsDestroy(state: State): State {
    return state;
}
