import { State as AdminFaqEditState } from "@client/reducers/admin-faq-edit-reducer";
import { Selector } from "@ngrx/store";
import { FaqInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => state.isActive;
export const getMessage: Selector<AdminFaqEditState, string | null> = (state: AdminFaqEditState) => state.message;
export const getHasError: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => state.hasError;
export const getHasSuccess: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => state.hasSuccess;
export const getIsLoading: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => state.isLoading;
export const getData: Selector<AdminFaqEditState, FaqInfo | null> = (state: AdminFaqEditState) => state.data;
export const getDataBody: Selector<AdminFaqEditState, string> = (state: AdminFaqEditState) => getData(state) && getData(state).body;
export const getDataCollapse: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => getData(state) && getData(state).collapse;
export const getDataId: Selector<AdminFaqEditState, string> = (state: AdminFaqEditState) => getData(state) && getData(state).id;
export const getDataTitle: Selector<AdminFaqEditState, string> = (state: AdminFaqEditState) => getData(state) && getData(state).title;
export const getDataOrder: Selector<AdminFaqEditState, any> = (state: AdminFaqEditState) => getData(state) && getData(state).order;
export const getDataVisible: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => getData(state) && getData(state).visible;
export const getDataOrganization: Selector<AdminFaqEditState, string | null> = (state: AdminFaqEditState) => getData(state) && getData(state).organization;
export const getIsEdit: Selector<AdminFaqEditState, boolean> = (state: AdminFaqEditState) => state.isEdit;
