import { ADMIN_NOTES_FORM, ADMIN_NOTES_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ServiceInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selecteService: ServiceInfo | null;
    selectedParkIdentificationNo: number;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selecteService: null,
    selectedParkIdentificationNo: 0
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_NOTES_LIST.INIT: return adminNotesListInit(state);
        case ADMIN_NOTES_LIST.DESTROY: return adminNotesListDestroy(state);
        case ADMIN_NOTES_LIST.CLICK_EDIT: return adminNotesListClickEdit(state);
        case ADMIN_NOTES_LIST.SELECT: return adminNotesListSelect(state, action.payload);
        case ADMIN_NOTES_LIST.SELECT_PARK: return adminNotesListSelectPark(state, action.payload);
        case ADMIN_NOTES_LIST.CLICK_ORGANIZATION_BACKLINK: return adminNotesListClickOrganizationBacklink(state);
        case ADMIN_NOTES_LIST.CLICK_GROUP_BACKLINK: return adminNotesListClickGroupBacklink(state);
        case ADMIN_NOTES_FORM.UPDATE_SUCCESS: return adminNotesFormUpdateSuccess(state);
        default: return state;
    }
}
export function adminNotesListInit(state: State): State {
    return state;
}
export function adminNotesListDestroy(state: State): State {
    return state;
}
export function adminNotesListClickEdit(state: State): State {
    return state;
}
export function adminNotesListSelect(state: State, payload: ServiceInfo): State {
    return { ...state, selecteService: payload };
}
export function adminNotesListSelectPark(state: State, payload: number): State {
    return { ...state, selectedParkIdentificationNo: payload };
}
export function adminNotesListClickOrganizationBacklink(state: State): State {
    return state;
}
export function adminNotesListClickGroupBacklink(state: State): State {
    return state;
}
export function adminNotesFormUpdateSuccess(state: State): State {
    return state;
}
