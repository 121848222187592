import { USER_VEHICLES, USER_VEHICLES_DETAILS } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    hasSuccess: boolean;
    hasError: boolean;
    isActive: boolean;
    isLoading: boolean;
    message: string | null;
}
export const initialState: State = {
    hasSuccess: false,
    hasError: false,
    isActive: false,
    isLoading: false,
    message: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case USER_VEHICLES_DETAILS.INIT: return userVehiclesDetailsInit(state);
        case USER_VEHICLES_DETAILS.DESTROY: return userVehiclesDetailsDestroy(state);
        case USER_VEHICLES_DETAILS.CLICK_DELETE: return userVehiclesDetailsClickDelete(state, action.payload);
        case USER_VEHICLES_DETAILS.CLICK_ADD: return userVehiclesDetailsClickAdd(state);
        case USER_VEHICLES_DETAILS.CLICK_EDIT: return userVehiclesDetailsClickEdit(state, action.payload);
        case USER_VEHICLES.DELETE: return userVehiclesDelete(state, action.payload);
        case USER_VEHICLES.DELETE_SUCCESS: return userVehiclesDeleteSuccess(state, action.payload);
        case USER_VEHICLES.DELETE_FAILURE: return userVehiclesDeleteFailure(state, action.payload);
        default: return state;
    }
}
export function userVehiclesDetailsInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true,
    };
}
export function userVehiclesDetailsDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function userVehiclesDetailsClickDelete(state: State, payload: Vehicule): State {
    return state;
}
export function userVehiclesDetailsClickAdd(state: State): State {
    return state;
}
export function userVehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return state;
}
export function userVehiclesDelete(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true,
    };
}
export function userVehiclesDeleteSuccess(state: State, payload: Vehicule): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
    };
}
export function userVehiclesDeleteFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false,
    };
}
