import { ADMIN_ORGANIZATION_LIST, ADMIN_ORGANIZATION_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OrganizationInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    form: OrganizationInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_ORGANIZATION_FORM.INIT: return adminOrganizationFormInit(state);
        case ADMIN_ORGANIZATION_FORM.DESTROY: return adminOrganizationFormDestroy(state);
        case ADMIN_ORGANIZATION_FORM.CLICK_SUBMIT: return adminOrganizationFormClickSubmit(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.UPDATE: return adminOrganizationFormUpdate(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.UPDATE_SUCCESS: return adminOrganizationFormUpdateSuccess(state);
        case ADMIN_ORGANIZATION_FORM.UPDATE_FAILURE: return adminOrganizationFormUpdateFailure(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.ADD: return adminOrganizationFormAdd(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.ADD_SUCCESS: return adminOrganizationFormAddSuccess(state);
        case ADMIN_ORGANIZATION_FORM.ADD_FAILURE: return adminOrganizationFormAddFailure(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.UPLOAD: return adminOrganizationFormUpload(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.UPLOAD_SUCCESS: return adminOrganizationFormUploadSuccess(state, action.payload);
        case ADMIN_ORGANIZATION_FORM.UPLOAD_FAILURE: return adminOrganizationFormUploadFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.CLICK_ADD: return adminOrganizationListClickAdd(state);
        case ADMIN_ORGANIZATION_LIST.CLICK_EDIT: return adminOrganizationListClickEdit(state);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        default: return state;
    }
}
export function adminOrganizationFormInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminOrganizationFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminOrganizationFormClickSubmit(state: State, payload: OrganizationInfo): State {
    return state;
}
export function adminOrganizationFormUpdate(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminOrganizationFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminOrganizationFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminOrganizationFormAdd(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminOrganizationFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminOrganizationFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminOrganizationFormUpload(state: State, payload: any): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminOrganizationFormUploadSuccess(state: State, payload: string): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        form: {
            ...state.form,
            logo: payload
        }
    };
}
export function adminOrganizationFormUploadFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminOrganizationListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
        form: initialState.form
    };
}
export function adminOrganizationListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true
    };
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        form: payload
    };
}
