import { SUBSCRIPTION } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo } from "@client/utils/shared-constants";

export class SubscriptionInitAction implements Action {
    readonly type = SUBSCRIPTION.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionDestroyAction implements Action {
    readonly type = SUBSCRIPTION.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionHydrateAction implements Action {
    readonly type = SUBSCRIPTION.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class SubscriptionHydrateSuccessAction implements Action {
    readonly type = SUBSCRIPTION.HYDRATE_SUCCESS;
    constructor(public payload: SubscriptionInfo, public correlationId?: number) {
    }
}

export class SubscriptionHydrateFailureAction implements Action {
    readonly type = SUBSCRIPTION.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
