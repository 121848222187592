import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormModule } from '@client/shared/components/forms';
import { MessageStateModule } from '@client/shared/components/message-state';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import {
  ForgotPasswordConfirmChangeComponent,
  PasswordDetailsComponent,
  PasswordEditComponent,
  PasswordForgotComponent,
  PasswordForgotFormComponent,
  PasswordForgotResetComponent,
  PasswordForgotSuccessComponent,
  PasswordForgotWaitComponent,
  PasswordResetFormComponent,
  UpdatePasswordComponent
} from './components';

import { ForgotPasswordComponent, ForgotPasswordPendingComponent, ForgotPasswordResetComponent } from './pages';

@NgModule({
  imports: [SharedCommonModule, SharedComponentsModule, FormModule, MessageStateModule, SharedFormModule],
  declarations: [
    PasswordEditComponent,
    UpdatePasswordComponent,
    PasswordDetailsComponent,
    PasswordForgotComponent,
    PasswordForgotFormComponent,
    PasswordForgotSuccessComponent,
    PasswordForgotWaitComponent,
    PasswordForgotResetComponent,
    PasswordResetFormComponent,
    ForgotPasswordConfirmChangeComponent,
    ForgotPasswordResetComponent,
    ForgotPasswordComponent,
    ForgotPasswordPendingComponent
  ],
  exports: [
    PasswordEditComponent,
    UpdatePasswordComponent,
    PasswordDetailsComponent,
    PasswordForgotComponent,
    PasswordForgotFormComponent,
    PasswordForgotSuccessComponent,
    PasswordForgotWaitComponent,
    PasswordForgotResetComponent,
    PasswordResetFormComponent
  ]
})
export class PasswordModule {
  static forRoot(): ModuleWithProviders<PasswordModule> {
    return {
      ngModule: PasswordModule,
      providers: []
    };
  }
}
