import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_GROUP_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeGroupInfo, OrganizationInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    form: AccessCodeGroupInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_GROUP_FORM.INIT: return adminGroupFormInit(state);
        case ADMIN_GROUP_FORM.DESTROY: return adminGroupFormDestroy(state);
        case ADMIN_GROUP_FORM.CLICK_ADD_ORGANIZATION: return adminGroupFormClickAddOrganization(state);
        case ADMIN_GROUP_FORM.CLICK_SUBMIT: return adminGroupFormClickSubmit(state, action.payload);
        case ADMIN_GROUP_FORM.UPDATE: return adminGroupFormUpdate(state, action.payload);
        case ADMIN_GROUP_FORM.UPDATE_SUCCESS: return adminGroupFormUpdateSuccess(state);
        case ADMIN_GROUP_FORM.UPDATE_FAILURE: return adminGroupFormUpdateFailure(state, action.payload);
        case ADMIN_GROUP_FORM.ADD: return adminGroupFormAdd(state, action.payload);
        case ADMIN_GROUP_FORM.ADD_SUCCESS: return adminGroupFormAddSuccess(state);
        case ADMIN_GROUP_FORM.ADD_FAILURE: return adminGroupFormAddFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_ADD: return adminGroupListClickAdd(state);
        case ADMIN_GROUP_LIST.CLICK_EDIT: return adminGroupListClickEdit(state);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        default: return state;
    }
}
export function adminGroupFormInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminGroupFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminGroupFormClickAddOrganization(state: State): State {
    return state;
}
export function adminGroupFormClickSubmit(state: State, payload: AccessCodeGroupInfo): State {
    return { ...state };
}
export function adminGroupFormUpdate(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminGroupFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminGroupFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminGroupFormAdd(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function adminGroupFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        form: {
            ...initialState.form,
            groupCode: state.form && state.form.groupCode,
        },
    };
}
export function adminGroupFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return { ...state, form: { groupCode: '', groupDescription: '', organizationCode: payload.organizationCode, meta: null, public: false } };
}
export function adminGroupListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
        form: { ...initialState.form, organizationCode: state.form && state.form.organizationCode || null }
    };
}
export function adminGroupListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true
    };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        form: payload
    };
}
