import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Injectable()
export class HasForgotPasswordGuard implements CanActivate {
  constructor(private store: Store<any>, private route: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return of(true);
    /*
    return this.store.select(getForgotPasswordSuccess).pipe(
      map(e => {
        if (!e) {
          this.route.navigate(['/auth/password/forgot']);
        }
        return e;
      }),
    );
    */
  }
}
