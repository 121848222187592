import { PARKS_SELECT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ParkInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    showEmpty: boolean;
    showPrivate: boolean;
    showPublic: boolean;
    selectedPark: ParkInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    showEmpty: false,
    showPrivate: true,
    showPublic: true,
    selectedPark: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case PARKS_SELECT.INIT: return parksSelectInit(state);
        case PARKS_SELECT.DESTROY: return parksSelectDestroy(state);
        case PARKS_SELECT.CLICK_PARK: return parksSelectClickPark(state, action.payload);
        default: return state;
    }
}
export function parksSelectInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function parksSelectDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function parksSelectClickPark(state: State, payload: ParkInfo): State {
    return {
        ...state,
        selectedPark: payload
    };
}
