import { State as ProfileState } from "@client/reducers/profile-reducer";
import { Selector } from "@ngrx/store";
import { ProfileInfo } from "@client/utils/shared-constants";
export const getIsHydrated: Selector<ProfileState, boolean> = (state: ProfileState) => state.isHydrated;
export const getMessage: Selector<ProfileState, string | null> = (state: ProfileState) => state.message;
export const getHasError: Selector<ProfileState, boolean> = (state: ProfileState) => state.hasError;
export const getHasSuccess: Selector<ProfileState, boolean> = (state: ProfileState) => state.hasSuccess;
export const getIsLoading: Selector<ProfileState, boolean> = (state: ProfileState) => state.isLoading;
export const getIsPending: Selector<ProfileState, boolean> = (state: ProfileState) => state.isPending;
export const getData: Selector<ProfileState, ProfileInfo | null> = (state: ProfileState) => state.data;
export const getDataCreatedAt: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).createdAt;
export const getDataEmail: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).email;
export const getDataEmailVerified: Selector<ProfileState, boolean> = (state: ProfileState) => getData(state) && getData(state).emailVerified;
export const getDataLastLoginAt: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).lastLoginAt;
export const getDataPhoneNumber: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).phoneNumber;
export const getDataUid: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).uid;
export const getDataDisplayName: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).displayName;
export const getDataPhotoUrl: Selector<ProfileState, string> = (state: ProfileState) => getData(state) && getData(state).photoURL;
