import { Injectable } from "@angular/core";
import { IProfileDispatcher } from "@client/interfaces/profile-interface";
import { Store } from "@ngrx/store";
import { ProfileDestroyAction, ProfileDestroyEditAction, ProfileGoToAction, ProfileHydrateAction, ProfileHydrateSuccessAction, ProfileHydrateFailureAction, ProfileInitAction, ProfileInitEditAction, ProfileSubmitFormEditAction, ProfileSubmitFormEditFailureAction, ProfileUpdateFailureAction, ProfileUpdateSuccessAction, ProfileUpdateAction, ProfileUpdateFormEditAction } from "@client/actions/profile-actions";
import { ProfileInfo, ProfileWritableEntities } from "@client/utils/shared-constants";
@Injectable()
export class ProfileDispatcher implements IProfileDispatcher {
    constructor(private store: Store<any>) {
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileDestroyAction(payload, correlationId));
    }
    destroyEdit(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileDestroyEditAction(payload, correlationId));
    }
    goTo(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileGoToAction(payload, correlationId));
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: ProfileInfo, correlationId = Date.now()) {
        this.store.dispatch(new ProfileHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new ProfileHydrateFailureAction(payload, correlationId));
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileInitAction(payload, correlationId));
    }
    initEdit(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ProfileInitEditAction(payload, correlationId));
    }
    submitFormEdit(payload: ProfileWritableEntities, correlationId = Date.now()) {
        this.store.dispatch(new ProfileSubmitFormEditAction(payload, correlationId));
    }
    submitFormEditFailure(payload: ProfileWritableEntities, correlationId = Date.now()) {
        this.store.dispatch(new ProfileSubmitFormEditFailureAction(payload, correlationId));
    }
    updateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new ProfileUpdateFailureAction(payload, correlationId));
    }
    updateSuccess(payload: ProfileWritableEntities, correlationId = Date.now()) {
        this.store.dispatch(new ProfileUpdateSuccessAction(payload, correlationId));
    }
    update(payload: ProfileWritableEntities, correlationId = Date.now()) {
        this.store.dispatch(new ProfileUpdateAction(payload, correlationId));
    }
    updateFormEdit(payload: ProfileWritableEntities, correlationId = Date.now()) {
        this.store.dispatch(new ProfileUpdateFormEditAction(payload, correlationId));
    }
}
