import { Injectable } from '@angular/core';
import { ACCESS_CODE, ACCESS_CODE_ADD, SESSION } from '@client/actions';
import { AccessCodeAddAction, AccessCodeAddFailureAction, AccessCodeAddSuccessAction, AccessCodeHydrateAction, AccessCodeHydrateFailureAction, AccessCodeHydrateSuccessAction } from '@client/actions/access-code-actions';
import { AccessCodeAddSubmitAction } from '@client/actions/access-code-add-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { AccessCodeService } from '@client/core/services/access-code.service';
import { AuthService } from '@client/core/services/auth.service';
import { Action } from '@client/lib/action';
import { getLayoutIsScope, getLayoutScopeId } from '@client/selectors';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class AccessCodeEffects {
  @Effect({ dispatch: true })
  addAccessCode: Observable<Action> = this.actions$.pipe(
    ofType<AccessCodeAddAction>(ACCESS_CODE.ADD),
    withLatestFrom(this.store.pipe(select(getLayoutScopeId)), this.store.pipe(select(getLayoutIsScope))),
    switchMap(([x, scopeId, isScope]: [AccessCodeAddAction, any, any]) => {
      if (isScope) {
        if (!x.payload.companyCode.toUpperCase().startsWith(scopeId.toUpperCase() + '-')) {
          return of(new AccessCodeAddFailureAction('Le code de l\'organisation est invalide', x.correlationId));
        }
      }
      return this.accessCode.add(x.payload).pipe(
        map(a => new AccessCodeAddSuccessAction(a, x.correlationId)),
        catchError(e => of(new AccessCodeAddFailureAction(e, x.correlationId)))
      );
    })
  );

  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.accessCode, of(new AccessCodeHydrateAction(null, action.correlationId)), EMPTY))
  );

  @Effect({ dispatch: false })
  deleteAccessCode = this.actions$.pipe(
    ofType(ACCESS_CODE.DELETE),
    switchMap(x => this.accessCode.delete().pipe(take(1)))
  );

  hydrateAccessCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AccessCodeHydrateAction>(ACCESS_CODE.HYDRATE),
      switchMap(action =>
        this.accessCode.hydrate().pipe(
          takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
          map(accessCode => new AccessCodeHydrateSuccessAction(accessCode, action.correlationId)),
          catchError(error => of(new AccessCodeHydrateFailureAction(error, action.correlationId)))
        )
      )
    )
  );

  submit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AccessCodeAddSubmitAction>(ACCESS_CODE_ADD.SUBMIT),
      map(action => new AccessCodeAddAction(action.payload, action.correlationId))
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private accessCode: AccessCodeService) {}
}
