import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { Vehicule } from "@client/utils/shared-constants";
import { State } from "@client/reducers/vehicles-edit-reducer";
import { getVehiclesEditHasSuccess, getVehiclesEditIsActive, getVehiclesEditHasError, getVehiclesEditIsLoading, getVehiclesEditIsCompleted, getVehiclesEditRedirectOnComplete, getVehiclesEditMessage, getVehiclesEditForm, getVehiclesEditFormDescription, getVehiclesEditFormId, getVehiclesEditFormKey, getVehiclesEditFormLicencePlate, getVehiclesEditFormManufacturer, getVehiclesEditFormModel, getVehiclesEditFormVehiculeYear } from "@client/selectors";
/*test*/ 
@Injectable()
export class VehiclesEditStore extends BaseStore {
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesEditHasSuccess));
    }
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesEditIsActive));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesEditHasError));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesEditIsLoading));
    }
    get isCompleted(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesEditIsCompleted));
    }
    get redirectOnComplete(): Observable<boolean> {
        return this.store.pipe(select(getVehiclesEditRedirectOnComplete));
    }
    get message(): Observable<string | null> {
        return this.store.pipe(select(getVehiclesEditMessage));
    }
    get form(): Observable<Vehicule> {
        return this.store.pipe(select(getVehiclesEditForm));
    }
    get formDescription(): Observable<string> {
        return this.store.pipe(select(getVehiclesEditFormDescription));
    }
    get formId(): Observable<number> {
        return this.store.pipe(select(getVehiclesEditFormId));
    }
    get formKey(): Observable<string> {
        return this.store.pipe(select(getVehiclesEditFormKey));
    }
    get formLicencePlate(): Observable<string> {
        return this.store.pipe(select(getVehiclesEditFormLicencePlate));
    }
    get formManufacturer(): Observable<string> {
        return this.store.pipe(select(getVehiclesEditFormManufacturer));
    }
    get formModel(): Observable<string> {
        return this.store.pipe(select(getVehiclesEditFormModel));
    }
    get formVehiculeYear(): Observable<string> {
        return this.store.pipe(select(getVehiclesEditFormVehiculeYear));
    }
}
