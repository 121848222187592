import { Injectable } from "@angular/core";
import { IVehiclesFormDispatcher } from "@client/interfaces/vehicles-form-interface";
import { Store } from "@ngrx/store";
import { VehiclesFormInitAction, VehiclesFormDestroyAction, VehiclesFormSelectYearAction, VehiclesFormSelectBrandAction, VehiclesFormValidationRequiredAction, VehiclesFormClickValidationAction } from "@client/actions/vehicles-form-actions";
@Injectable()
export class VehiclesFormDispatcher implements IVehiclesFormDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormDestroyAction(payload, correlationId));
    }
    selectYear(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormSelectYearAction(payload, correlationId));
    }
    selectBrand(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormSelectBrandAction(payload, correlationId));
    }
    validationRequired(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormValidationRequiredAction(payload, correlationId));
    }
    clickValidation(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesFormClickValidationAction(payload, correlationId));
    }
}
