import { State as AccountStatusState } from "@client/reducers/account-status-reducer";
import { Selector } from "@ngrx/store";
export const getIsLoading: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.isLoading;
export const getHasSuccess: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.hasSuccess;
export const getHasError: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.hasError;
export const getMessage: Selector<AccountStatusState, string> = (state: AccountStatusState) => state.message;
export const getIsCompleted: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.isCompleted;
export const getIsAccessCodeRequired: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.isAccessCodeRequired;
export const getIsSubscriptionRequired: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.isSubscriptionRequired;
export const getIsCipRequired: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.isCipRequired;
export const getIsContractRequired: Selector<AccountStatusState, boolean> = (state: AccountStatusState) => state.isContractRequired;
