import { ORGANIZATION } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OrganizationInfo } from "@client/utils/shared-constants";
export interface State extends EntityState<OrganizationInfo> {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selected: string | number | null;
}
export const selectId = (a: OrganizationInfo) => {
    return a.organizationCode;
};
export const sortByName = (a: OrganizationInfo, b: OrganizationInfo) => {
    return a.organizationName.localeCompare(b.organizationName);
};
export const adapter: EntityAdapter<OrganizationInfo> = createEntityAdapter<OrganizationInfo>({
    sortComparer: sortByName,
    selectId: selectId
});
export const initialState: State = adapter.getInitialState({
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selected: null
});
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ORGANIZATION.HYDRATE: return organizationHydrate(state, action.payload);
        case ORGANIZATION.HYDRATE_SUCCESS: return organizationHydrateSuccess(state, action.payload);
        case ORGANIZATION.HYDRATE_FAILURE: return organizationHydrateFailure(state, action.payload);
        default: return state;
    }
}
export function organizationHydrate(state: State, payload: any): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true
    };
}
export function organizationHydrateSuccess(state: State, payload: OrganizationInfo[]): State {
    return adapter.addAll(payload, {
        ...state,
        hasSuccess: true,
        isLoading: false,
    });
}
export function organizationHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload
    };
}
