import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_METAS_LIST, ADMIN_METAS_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeServiceCustomFields, AccessCodeServiceCustomFieldsValue, OrganizationInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    importData: any;
    form: AccessCodeServiceCustomFields;
    selectedOption: number | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    importData: [],
    form: {
        label: "",
        name: "",
        type: "text",
        values: [],
        required: true,
        organizationCode: "",
        groupCode: "",
        order: 0
    },
    selectedOption: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_METAS_FORM.INIT: return adminMetasFormInit(state);
        case ADMIN_METAS_FORM.DESTROY: return adminMetasFormDestroy(state);
        case ADMIN_METAS_FORM.CLICK_IMPORT: return adminMetasFormClickImport(state);
        case ADMIN_METAS_FORM.CLICK_ADD_ORGANIZATION: return adminMetasFormClickAddOrganization(state);
        case ADMIN_METAS_FORM.CLICK_ADD_GROUP: return adminMetasFormClickAddGroup(state);
        case ADMIN_METAS_FORM.CLICK_ADD_OPTION: return adminMetasFormClickAddOption(state);
        case ADMIN_METAS_FORM.CLICK_EDIT_OPTION: return adminMetasFormClickEditOption(state);
        case ADMIN_METAS_FORM.CLICK_DELETE_OPTION: return adminMetasFormClickDeleteOption(state);
        case ADMIN_METAS_FORM.READ_FILE: return adminMetasFormReadFile(state, action.payload);
        case ADMIN_METAS_FORM.READ_FILE_SUCCESS: return adminMetasFormReadFileSuccess(state, action.payload);
        case ADMIN_METAS_FORM.READ_FILE_ERROR: return adminMetasFormReadFileError(state, action.payload);
        case ADMIN_METAS_FORM.ADD_OPTION: return adminMetasFormAddOption(state, action.payload);
        case ADMIN_METAS_FORM.IMPORT_OPTIONS: return adminMetasFormImportOptions(state, action.payload);
        case ADMIN_METAS_FORM.UPDATE_OPTION: return adminMetasFormUpdateOption(state, action.payload);
        case ADMIN_METAS_FORM.SELECT_OPTION: return adminMetasFormSelectOption(state, action.payload);
        case ADMIN_METAS_FORM.SELECT_ORGANIZATION_CODE: return adminMetasFormSelectOrganizationCode(state, action.payload);
        case ADMIN_METAS_FORM.SELECT_GROUP_CODE: return adminMetasFormSelectGroupCode(state, action.payload);
        case ADMIN_METAS_FORM.CLICK_SUBMIT: return adminMetasFormClickSubmit(state, action.payload);
        case ADMIN_METAS_FORM.UPDATE_FORM: return adminMetasFormUpdateForm(state, action.payload);
        case ADMIN_METAS_FORM.UPDATE: return adminMetasFormUpdate(state, action.payload);
        case ADMIN_METAS_FORM.UPDATE_SUCCESS: return adminMetasFormUpdateSuccess(state);
        case ADMIN_METAS_FORM.UPDATE_FAILURE: return adminMetasFormUpdateFailure(state, action.payload);
        case ADMIN_METAS_FORM.ADD: return adminMetasFormAdd(state, action.payload);
        case ADMIN_METAS_FORM.ADD_SUCCESS: return adminMetasFormAddSuccess(state);
        case ADMIN_METAS_FORM.ADD_FAILURE: return adminMetasFormAddFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.CLICK_CONFIGURATION: return adminGroupListClickConfiguration(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_METAS_LIST.CLICK_ADD: return adminMetasListClickAdd(state);
        case ADMIN_METAS_LIST.CLICK_EDIT: return adminMetasListClickEdit(state);
        case ADMIN_METAS_LIST.SELECT: return adminMetasListSelect(state, action.payload);
        case ADMIN_METAS_LIST.SELECT_ORGANIZATION_GROUP: return adminMetasListSelectOrganizationGroup(state, action.payload);
        case ADMIN_METAS_LIST.SELECT_ORGANIZATION: return adminMetasListSelectOrganization(state, action.payload);
        default: return state;
    }
}
export function adminMetasFormInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function adminMetasFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminMetasFormClickImport(state: State): State {
    return state;
}
export function adminMetasFormClickAddOrganization(state: State): State {
    return state;
}
export function adminMetasFormClickAddGroup(state: State): State {
    return state;
}
export function adminMetasFormClickAddOption(state: State): State {
    return {
        ...state,
        selectedOption: null,
    };
}
export function adminMetasFormClickEditOption(state: State): State {
    return state;
}
export function adminMetasFormClickDeleteOption(state: State): State {
    const values = [...state.form.values];
    values.splice(state.selectedOption, 1);
    return {
        ...state,
        form: {
            ...state.form,
            values: values
        }
    };
}
export function adminMetasFormReadFile(state: State, payload: any): State {
    return { ...state, importData: [] };
}
export function adminMetasFormReadFileSuccess(state: State, payload: any[]): State {
    return { ...state, importData: payload };
}
export function adminMetasFormReadFileError(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminMetasFormAddOption(state: State, payload: AccessCodeServiceCustomFieldsValue): State {
    const values = [...state.form.values];
    if (state.selectedOption !== null) {
        values[state.selectedOption] = payload;
    }
    else {
        values.push(payload);
    }
    return {
        ...state,
        form: {
            ...state.form,
            values: values,
        },
    };
}
export function adminMetasFormImportOptions(state: State, payload: AccessCodeServiceCustomFieldsValue[]): State {
    return {
        ...state,
        form: {
            ...state.form,
            values: payload
        }
    };
}
export function adminMetasFormUpdateOption(state: State, payload: AccessCodeServiceCustomFieldsValue): State {
    return { ...state };
}
export function adminMetasFormSelectOption(state: State, payload: number): State {
    return { ...state, selectedOption: payload };
}
export function adminMetasFormSelectOrganizationCode(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            organizationCode: payload,
        },
    };
}
export function adminMetasFormSelectGroupCode(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload,
        },
    };
}
export function adminMetasFormClickSubmit(state: State, payload: AccessCodeServiceCustomFields): State {
    return {
        ...state,
    };
}
export function adminMetasFormUpdateForm(state: State, payload: AccessCodeServiceCustomFields): State {
    return { ...state, form: payload };
}
export function adminMetasFormUpdate(state: State, payload: AccessCodeServiceCustomFields): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminMetasFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminMetasFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminMetasFormAdd(state: State, payload: AccessCodeServiceCustomFields): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminMetasFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        form: {
            ...state.form,
            name: null,
            label: null,
            values: [],
            type: 'text',
        },
    };
}
export function adminMetasFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        form: {
            ...initialState.form,
            organizationCode: payload.organizationCode
        }
    };
}
export function adminGroupListClickConfiguration(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload.groupCode,
            organizationCode: payload.organizationCode,
        },
    };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        form: {
            ...initialState.form,
            organizationCode: state.form && state.form.organizationCode || null,
            groupCode: payload.groupCode
        }
    };
}
export function adminMetasListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
    };
}
export function adminMetasListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true,
    };
}
export function adminMetasListSelect(state: State, payload: AccessCodeServiceCustomFields): State {
    return {
        ...state,
        form: payload,
    };
}
export function adminMetasListSelectOrganizationGroup(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload,
        },
    };
}
export function adminMetasListSelectOrganization(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            organizationCode: payload,
        },
    };
}
