import { CONTRACT, CONTRACT_SEARCH } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ContractForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isComplete: boolean;
    isLoading: boolean;
    form: ContractForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isComplete: false,
    isLoading: false,
    form: {
        contractNo: "",
        phone: "",
        postalCode: "",
        method: "contract"
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT_SEARCH.INIT: return contractSearchInit(state);
        case CONTRACT_SEARCH.DESTROY: return contractSearchDestroy(state);
        case CONTRACT_SEARCH.FORM_UPDATE: return contractSearchFormUpdate(state, action.payload);
        case CONTRACT.SEARCH: return contractSearch(state, action.payload);
        case CONTRACT.SEARCH_SUCCESS: return contractSearchSuccess(state, action.payload);
        case CONTRACT.SEARCH_FAILURE: return contractSearchFailure(state, action.payload);
        default: return state;
    }
}
export function contractSearchInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function contractSearchDestroy(state: State): State {
    return {
        ...initialState,
        isActive: false
    };
}
export function contractSearchFormUpdate(state: State, payload: ContractForm): State {
    return { ...state, form: payload || initialState.form };
}
export function contractSearch(state: State, payload: ContractForm): State {
    return {
        ...state,
        hasError: false,
        hasSuccess: false,
        message: null,
        isLoading: true
    };
}
export function contractSearchSuccess(state: State, payload: ContractForm): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false
    };
}
export function contractSearchFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false
    };
}
