import { NgModule } from '@angular/core';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ClipNumberPipe } from './pipes/clip-number.pipe';
import { ConvertToBooleanPipe } from './pipes/convert-to-boolean.pipe';
import { ConvertToNumberPipe } from './pipes/convert-to-number.pipe';
import { ConvertToStringPipe } from './pipes/convert-to-string.pipe';
import { DecapitalizePipe } from './pipes/decapitalize.pipe';
import { FirstPipe } from './pipes/first.pipe';
import { IsNilPipe } from './pipes/is-nil.pipe';
import { IsStringPipe } from './pipes/is-string.pipe';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { LastPipe } from './pipes/last.pipe';
import { LowercasePipe } from './pipes/lowercase.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { NanDefaultPipe } from './pipes/nan-default.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ProvincePipe } from './pipes/province.pipe';
import { SlicePipe } from './pipes/slice.pipe';
import { SnakeCasePipe } from './pipes/snake-case.pipe';
import { SubstrPipe } from './pipes/substr.pipe';
import { SwapCasePipe } from './pipes/swap-case.pipe';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { ToIntegerPipe } from './pipes/to-integer.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UpperCasePipe } from './pipes/upper-case.pipe';
import { ValidatePipe } from './pipes/validate.pipe';
import { WordToCamelPipe } from './pipes/word-to-camel.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { ZipcodePipe } from './pipes/zipcode.pipe';

const PIPES = [
  IsStringPipe,
  IsNilPipe,
  CapitalizePipe,
  LowercasePipe,
  ToStringPipe,
  WordToCamelPipe,
  CamelCasePipe,
  DecapitalizePipe,
  KebabCasePipe,
  SnakeCasePipe,
  UpperCasePipe,
  SwapCasePipe,
  TitleCasePipe,
  ClipNumberPipe,
  ToIntegerPipe,
  TruncatePipe,
  NanDefaultPipe,
  FirstPipe,
  LastPipe,
  SlicePipe,
  SubstrPipe,
  ConvertToBooleanPipe,
  ConvertToStringPipe,
  ConvertToNumberPipe,
  ZipcodePipe,
  ValidatePipe,
  PhonePipe,
  ProvincePipe,
  MomentPipe,
  TimestampPipe,
  YesNoPipe
];

@NgModule({
  imports: [],
  declarations: PIPES,
  providers: PIPES,
  exports: PIPES
})
export class PipesModule {}
