import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContentLoaderModule } from '@client/shared/components/content-loader';
import { PipesModule } from '@client/shared/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  exports: [CommonModule, PipesModule, ContentLoaderModule, TranslateModule, RouterModule],
  imports: [TranslateModule],
  providers: []
})
export class SharedCommonModule {}
