import { State as ServicesState, adapter as ServicesAdapter } from "@client/reducers/services-reducer";
import { Selector } from "@ngrx/store";
import { ServiceInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<ServicesState, string | null> = (state: ServicesState) => state.message;
export const getHasError: Selector<ServicesState, boolean> = (state: ServicesState) => state.hasError;
export const getHasSuccess: Selector<ServicesState, boolean> = (state: ServicesState) => state.hasSuccess;
export const getIsLoading: Selector<ServicesState, boolean> = (state: ServicesState) => state.isLoading;
export const getCanSubscribe: Selector<ServicesState, boolean> = (state: ServicesState) => state.canSubscribe;
export const getSelected: Selector<ServicesState, string | number | null> = (state: ServicesState) => state.selected;
const { selectIds, selectEntities, selectAll, selectTotal } = ServicesAdapter.getSelectors();
export const getIds = selectIds;
export const getEntities = selectEntities;
export const getAll = selectAll;
export const getTotal = selectTotal;
