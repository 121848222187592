import { Injectable } from "@angular/core";
import { ISubscriptionDetailsDispatcher } from "@client/interfaces/subscription-details-interface";
import { Store } from "@ngrx/store";
import { SubscriptionDetailsInitAction, SubscriptionDetailsDestroyAction, SubscriptionDetailsClickEditAction } from "@client/actions/subscription-details-actions";
@Injectable()
export class SubscriptionDetailsDispatcher implements ISubscriptionDetailsDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionDetailsInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionDetailsDestroyAction(payload, correlationId));
    }
    clickEdit(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SubscriptionDetailsClickEditAction(payload, correlationId));
    }
}
