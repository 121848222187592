import { ADMIN_CONTRACT_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selectedContract: number | null;
    data: any;
    onSync: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selectedContract: null,
    data: [],
    onSync: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_CONTRACT_LIST.INIT: return adminContractListInit(state);
        case ADMIN_CONTRACT_LIST.DESTROY: return adminContractListDestroy(state);
        case ADMIN_CONTRACT_LIST.SYNC: return adminContractListSync(state, action.payload);
        case ADMIN_CONTRACT_LIST.SYNC_SUCCESS: return adminContractListSyncSuccess(state, action.payload);
        case ADMIN_CONTRACT_LIST.SYNC_FAILURE: return adminContractListSyncFailure(state, action.payload);
        case ADMIN_CONTRACT_LIST.HYDRATE: return adminContractListHydrate(state);
        case ADMIN_CONTRACT_LIST.HYDRATE_SUCCESS: return adminContractListHydrateSuccess(state, action.payload);
        case ADMIN_CONTRACT_LIST.HYDRATE_FAILURE: return adminContractListHydrateFailure(state, action.payload);
        default: return state;
    }
}
export function adminContractListInit(state: State): State {
    return {
        ...state,
        isActive: true
    };
}
export function adminContractListDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function adminContractListSync(state: State, payload: any): State {
    return { ...state, onSync: true };
}
export function adminContractListSyncSuccess(state: State, payload: any): State {
    return { ...state, onSync: false };
}
export function adminContractListSyncFailure(state: State, payload: any): State {
    return { ...state, onSync: false };
}
export function adminContractListHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: true
    };
}
export function adminContractListHydrateSuccess(state: State, payload: any[]): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        message: null,
        isLoading: false,
        data: payload
    };
}
export function adminContractListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        message: payload,
        isLoading: false
    };
}
