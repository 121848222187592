import { USER } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState } from "@client/utils/shared-constants";

export class UserStartServiceAction implements Action {
    readonly type = USER.START_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserStopServiceAction implements Action {
    readonly type = USER.STOP_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserHydrateAction implements Action {
    readonly type = USER.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserHydrateSuccessAction implements Action {
    readonly type = USER.HYDRATE_SUCCESS;
    constructor(public payload: UserState, public correlationId?: number) {
    }
}

export class UserHydrateFailureAction implements Action {
    readonly type = USER.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class UserDeleteUserAction implements Action {
    readonly type = USER.DELETE_USER;
    constructor(public payload: LoginForm, public correlationId?: number) {
    }
}

export class UserDeleteUserSuccessAction implements Action {
    readonly type = USER.DELETE_USER_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserDeleteUserFailureAction implements Action {
    readonly type = USER.DELETE_USER_FAILURE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserSyncAction implements Action {
    readonly type = USER.SYNC;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserSyncSuccessAction implements Action {
    readonly type = USER.SYNC_SUCCESS;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserSyncFailureAction implements Action {
    readonly type = USER.SYNC_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
