import { Injectable } from '@angular/core';

import { AngularFireFunctions } from '@angular/fire/functions';
import { USER } from '@client/actions';
import { UserHydrateAction, UserHydrateFailureAction, UserHydrateSuccessAction, UserSyncFailureAction, UserSyncSuccessAction } from '@client/actions/user-actions';
import { UserService } from '@client/core/services/user.service';
import { Action } from '@client/lib/action';
import { getSessionUid } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
  @Effect()
  hydrate: Observable<Action> = this.actions$.pipe(
    ofType<UserHydrateAction>(USER.HYDRATE),
    switchMap(action =>
      this.user.hydrate().pipe(
        takeUntil(
          this.store.pipe(
            select(getSessionUid),
            filter(x => !x)
          )
        ),
        map(u => new UserHydrateSuccessAction(u, action.correlationId)),
        catchError(e => of(new UserHydrateFailureAction(e, action.correlationId)))
      )
    )
  );
  @Effect()
  refreshCurrentUser$ = this.actions$.pipe(
    ofType(USER.SYNC),
    switchMap(() => {
      const test = this.fns.httpsCallable('syncAccount');
      return test(null).pipe(
        map(x => new UserSyncSuccessAction()),
        catchError(e => of(new UserSyncFailureAction(e)))
      );
    })
  );

  constructor(private actions$: Actions, private store: Store<any>, private user: UserService, private fns: AngularFireFunctions) {}
}
