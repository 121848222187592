import { USER_VEHICLES } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule } from "@client/utils/shared-constants";

export class UserVehiclesStartServiceAction implements Action {
    readonly type = USER_VEHICLES.START_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserVehiclesStopServiceAction implements Action {
    readonly type = USER_VEHICLES.STOP_SERVICE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserVehiclesHydrateAction implements Action {
    readonly type = USER_VEHICLES.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class UserVehiclesHydrateSuccessAction implements Action {
    readonly type = USER_VEHICLES.HYDRATE_SUCCESS;
    constructor(public payload: Vehicule[], public correlationId?: number) {
    }
}

export class UserVehiclesHydrateFailureAction implements Action {
    readonly type = USER_VEHICLES.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class UserVehiclesDeleteAction implements Action {
    readonly type = USER_VEHICLES.DELETE;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class UserVehiclesDeleteSuccessAction implements Action {
    readonly type = USER_VEHICLES.DELETE_SUCCESS;
    constructor(public payload: Vehicule, public correlationId?: number) {
    }
}

export class UserVehiclesDeleteFailureAction implements Action {
    readonly type = USER_VEHICLES.DELETE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
