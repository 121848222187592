import { ADMIN_FAQ_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { FaqInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    collection: FaqInfo[];
    selectedFaqId: string;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    collection: [],
    selectedFaqId: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_FAQ_LIST.INIT: return adminFaqListInit(state);
        case ADMIN_FAQ_LIST.DESTROY: return adminFaqListDestroy(state);
        case ADMIN_FAQ_LIST.CLICK_ADD: return adminFaqListClickAdd(state);
        case ADMIN_FAQ_LIST.CLICK_SHOW_GROUP: return adminFaqListClickShowGroup(state);
        case ADMIN_FAQ_LIST.CLICK_ADD_GROUP: return adminFaqListClickAddGroup(state);
        case ADMIN_FAQ_LIST.CLICK_EDIT: return adminFaqListClickEdit(state, action.payload);
        case ADMIN_FAQ_LIST.CLICK_DELETE: return adminFaqListClickDelete(state, action.payload);
        case ADMIN_FAQ_LIST.CLICK_DELETE_SUCCESS: return adminFaqListClickDeleteSuccess(state, action.payload);
        case ADMIN_FAQ_LIST.CLICK_DELETE_FAILURE: return adminFaqListClickDeleteFailure(state, action.payload);
        case ADMIN_FAQ_LIST.HYDRATE: return adminFaqListHydrate(state);
        case ADMIN_FAQ_LIST.HYDRATE_SUCCESS: return adminFaqListHydrateSuccess(state, action.payload);
        case ADMIN_FAQ_LIST.HYDRATE_FAILURE: return adminFaqListHydrateFailure(state, action.payload);
        default: return state;
    }
}
export function adminFaqListInit(state: State): State {
    return state;
}
export function adminFaqListDestroy(state: State): State {
    return state;
}
export function adminFaqListClickAdd(state: State): State {
    return state;
}
export function adminFaqListClickShowGroup(state: State): State {
    return state;
}
export function adminFaqListClickAddGroup(state: State): State {
    return state;
}
export function adminFaqListClickEdit(state: State, payload: FaqInfo): State {
    return { ...state, ...payload };
}
export function adminFaqListClickDelete(state: State, payload: FaqInfo): State {
    return state;
}
export function adminFaqListClickDeleteSuccess(state: State, payload: FaqInfo): State {
    return state;
}
export function adminFaqListClickDeleteFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function adminFaqListHydrate(state: State): State {
    return {
        ...state,
        isLoading: false,
        message: null,
        hasSuccess: false,
        hasError: false
    };
}
export function adminFaqListHydrateSuccess(state: State, payload: FaqInfo[]): State {
    return { ...state, collection: payload, hasSuccess: true };
}
export function adminFaqListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        message: payload,
        hasError: true,
        isLoading: false
    };
}
