import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutAccountContainerComponent } from '@client/core/layout/account/layout-account-container.component';
import { LayoutAccountContentComponent } from '@client/core/layout/account/layout-account-content/layout-account-content.component';
import { LayoutAccountFooterComponent } from '@client/core/layout/account/layout-account-footer';
import { LayoutAccountHeaderComponent } from '@client/core/layout/account/layout-account-header';
import { LayoutAccountMainComponent } from '@client/core/layout/account/layout-account-main';
import { LayoutAccountSidebarComponent } from '@client/core/layout/account/layout-account-sidebar';
import { LayoutAccountComponent } from '@client/core/layout/account/layout-account.component';
import { LayoutAuthContainerComponent } from '@client/core/layout/auth/layout-auth-container.component';
import { LayoutAuthHeaderComponent } from '@client/core/layout/auth/layout-auth-header';
import { LayoutAuthComponent } from '@client/core/layout/auth/layout-auth.component';
import { LayoutPageCardsComponent } from '@client/core/layout/pages/layout-page-cards';
import { LayoutPageDefaultComponent } from '@client/core/layout/pages/layout-page-default';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PanelBarModule, LayoutModule } from '@progress/kendo-angular-layout';
import { BehaviorSubject } from 'rxjs';

export const LAYOUT_PAGE_TITLE = new InjectionToken<BehaviorSubject<string>>('');

@NgModule({
  declarations: [
    LayoutAuthComponent,
    LayoutAccountComponent,
    LayoutAccountContainerComponent,
    LayoutAuthContainerComponent,
    LayoutAccountHeaderComponent,
    LayoutAuthHeaderComponent,
    LayoutAccountFooterComponent,
    LayoutAccountSidebarComponent,
    LayoutAccountContentComponent,
    LayoutAccountMainComponent,
    LayoutPageDefaultComponent,
    LayoutPageCardsComponent
  ],
  exports: [
    LayoutAuthComponent,
    LayoutAccountComponent,
    LayoutAccountContainerComponent,
    LayoutAuthContainerComponent,
    LayoutAccountHeaderComponent,
    LayoutAuthHeaderComponent,
    LayoutAccountFooterComponent,
    LayoutAccountSidebarComponent,
    LayoutAccountContentComponent,
    LayoutAccountMainComponent,
    LayoutPageDefaultComponent,
    LayoutPageCardsComponent
  ],
  imports: [CommonModule, ButtonsModule, PanelBarModule, RouterModule, TranslateModule, LayoutModule],
  providers: [
    {
      provide: LAYOUT_PAGE_TITLE,
      useValue: new BehaviorSubject('')
    }
  ]
})
export class CoreLayoutModule {}
