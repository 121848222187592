import { State as AdminGroupListState } from "@client/reducers/admin-group-list-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeGroupInfo, AccessCodeServiceInfo, AccessCodeMetasInfo, AccessCodeMetasConditions, AccessCodeServiceCustomFields } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminGroupListState, boolean> = (state: AdminGroupListState) => state.isActive;
export const getMessage: Selector<AdminGroupListState, string | null> = (state: AdminGroupListState) => state.message;
export const getHasError: Selector<AdminGroupListState, boolean> = (state: AdminGroupListState) => state.hasError;
export const getHasSuccess: Selector<AdminGroupListState, boolean> = (state: AdminGroupListState) => state.hasSuccess;
export const getIsLoading: Selector<AdminGroupListState, boolean> = (state: AdminGroupListState) => state.isLoading;
export const getData: Selector<AdminGroupListState, AccessCodeGroupInfo[]> = (state: AdminGroupListState) => state.data;
export const getSelecteGroup: Selector<AdminGroupListState, AccessCodeGroupInfo | null> = (state: AdminGroupListState) => state.selecteGroup;
export const getSelecteGroupGroupCode: Selector<AdminGroupListState, string> = (state: AdminGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).groupCode;
export const getSelecteGroupGroupDescription: Selector<AdminGroupListState, string> = (state: AdminGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).groupDescription;
export const getSelecteGroupOrganizationCode: Selector<AdminGroupListState, string> = (state: AdminGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).organizationCode;
export const getSelecteGroupPublic: Selector<AdminGroupListState, boolean> = (state: AdminGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).public;
export const getSelecteGroupServices: Selector<AdminGroupListState, AccessCodeServiceInfo[]> = (state: AdminGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).services;
export const getSelecteGroupMeta: Selector<AdminGroupListState, AccessCodeMetasInfo> = (state: AdminGroupListState) => getSelecteGroup(state) && getSelecteGroup(state).meta;
export const getSelecteGroupMetaConditions: Selector<AdminGroupListState, AccessCodeMetasConditions> = (state: AdminGroupListState) => getSelecteGroupMeta(state) && getSelecteGroupMeta(state).conditions;
export const getSelecteGroupMetaConditionsService: Selector<AdminGroupListState, string | null> = (state: AdminGroupListState) => getSelecteGroupMetaConditions(state) && getSelecteGroupMetaConditions(state).service;
export const getSelecteGroupMetaConditionsPaymentDas: Selector<AdminGroupListState, string | null> = (state: AdminGroupListState) => getSelecteGroupMetaConditions(state) && getSelecteGroupMetaConditions(state).paymentDas;
export const getSelecteGroupMetaConditionsPaymentCc: Selector<AdminGroupListState, string | null> = (state: AdminGroupListState) => getSelecteGroupMetaConditions(state) && getSelecteGroupMetaConditions(state).paymentCc;
export const getSelecteGroupMetaConditionsPaymentPpa: Selector<AdminGroupListState, string> = (state: AdminGroupListState) => getSelecteGroupMetaConditions(state) && getSelecteGroupMetaConditions(state).paymentPpa;
export const getSelecteGroupMetaConditionsPaymentInternet: Selector<AdminGroupListState, string | null> = (state: AdminGroupListState) => getSelecteGroupMetaConditions(state) && getSelecteGroupMetaConditions(state).paymentInternet;
export const getSelecteGroupMetaCustomFields: Selector<AdminGroupListState, AccessCodeServiceCustomFields[]> = (state: AdminGroupListState) => getSelecteGroupMeta(state) && getSelecteGroupMeta(state).customFields;
export const getSelectedOrganizationCode: Selector<AdminGroupListState, string> = (state: AdminGroupListState) => state.selectedOrganizationCode;
export const getShowOrganizationBacklink: Selector<AdminGroupListState, boolean> = (state: AdminGroupListState) => state.showOrganizationBacklink;
