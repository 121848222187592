import { NgModule } from '@angular/core';
import { AccessCodeAddDispatcher } from '@client/dispatchers/access-code-add-dispatcher';
import { AccessCodeDetailsDispatcher } from '@client/dispatchers/access-code-details-dispatcher';
import { CalendarDispatcher } from '@client/dispatchers/calendar-dispatcher';
import { FaqDispatcher } from '@client/dispatchers/faq-dispatcher';
import { LayoutDispatcher } from '@client/dispatchers/layout-dispatcher';
import { OrganizationDispatcher } from '@client/dispatchers/organization-dispatcher';
import { ParksDispatcher } from '@client/dispatchers/parks-dispatcher';
import { ProfileDetailsDispatcher } from '@client/dispatchers/profile-details-dispatcher';
import { ProfileDispatcher } from '@client/dispatchers/profile-dispatcher';
import { ProfileEditDispatcher } from '@client/dispatchers/profile-edit-dispatcher';
import { RegisterDispatcher } from '@client/dispatchers/register-dispatcher';
import { SectorsDispatcher } from '@client/dispatchers/sectors-dispatcher';
import { SubscriptionDetailsDispatcher } from '@client/dispatchers/subscription-details-dispatcher';
import { SubscriptionDispatcher } from '@client/dispatchers/subscription-dispatcher';
import { SubscriptionEditDispatcher } from '@client/dispatchers/subscription-edit-dispatcher';
import { VehiclesAddDispatcher } from '@client/dispatchers/vehicles-add-dispatcher';
import { VehiclesDispatcher } from '@client/dispatchers/vehicles-dispatcher';
import { VehiclesEditDispatcher } from '@client/dispatchers/vehicles-edit-dispatcher';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    CalendarDispatcher,
    FaqDispatcher,
    LayoutDispatcher,
    OrganizationDispatcher,
    ParksDispatcher,
    ProfileDispatcher,
    ProfileEditDispatcher,
    ProfileDetailsDispatcher,
    RegisterDispatcher,
    SectorsDispatcher,
    SubscriptionEditDispatcher,
    SubscriptionDispatcher,
    SubscriptionDetailsDispatcher,
    AccessCodeAddDispatcher,
    AccessCodeDetailsDispatcher,
    VehiclesDispatcher,
    VehiclesAddDispatcher,
    VehiclesEditDispatcher
  ]
})
export class DispatchersModule {}
