import { REGISTER_SUBSCRIPTION } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { SubscriptionInfo } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isComplete: boolean;
    isLoading: boolean;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isComplete: false,
    isLoading: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case REGISTER_SUBSCRIPTION.INIT: return registerSubscriptionInit(state);
        case REGISTER_SUBSCRIPTION.DESTROY: return registerSubscriptionDestroy(state);
        case REGISTER_SUBSCRIPTION.CLICK_SUBMIT: return registerSubscriptionClickSubmit(state);
        case REGISTER_SUBSCRIPTION.CREATE: return registerSubscriptionCreate(state, action.payload);
        case REGISTER_SUBSCRIPTION.CREATE_SUCCESS: return registerSubscriptionCreateSuccess(state, action.payload);
        case REGISTER_SUBSCRIPTION.CREATE_FAILURE: return registerSubscriptionCreateFailure(state, action.payload);
        case REGISTER_SUBSCRIPTION.CLICK_LOGOUT: return registerSubscriptionClickLogout(state);
        case REGISTER_SUBSCRIPTION.FORM_UPDATE: return registerSubscriptionFormUpdate(state, action.payload);
        case REGISTER_SUBSCRIPTION.FORM_RESET: return registerSubscriptionFormReset(state);
        default: return state;
    }
}
export function registerSubscriptionInit(state: State): State {
    return {
        ...state,
        ...initialState,
        isActive: true
    };
}
export function registerSubscriptionDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function registerSubscriptionClickSubmit(state: State): State {
    return {
        ...state,
    };
}
export function registerSubscriptionCreate(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        message: null,
        hasError: false,
        isComplete: false,
    };
}
export function registerSubscriptionCreateSuccess(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        isComplete: true,
    };
}
export function registerSubscriptionCreateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        message: payload,
        hasError: true,
    };
}
export function registerSubscriptionClickLogout(state: State): State {
    return state;
}
export function registerSubscriptionFormUpdate(state: State, payload: SubscriptionInfo): State {
    return { ...state };
}
export function registerSubscriptionFormReset(state: State): State {
    return state;
}
