import { Injectable } from '@angular/core';
import { AUTH, OOB } from '@client/actions';
import { AuthCheckActionCodeAction, AuthCheckActionCodeSuccessAction } from '@client/actions/auth-actions';
import { SessionOobRecoderEmailAction, SessionOobResetPssswordAction, SessionOobVerifyEmailAction } from '@client/actions/session-actions';
import { getRouterState } from '@client/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OobAuthEffects {
  @Effect({ dispatch: true })
  oobInit$: Observable<any> = this.actions$.pipe(
    ofType(OOB.INIT),
    withLatestFrom(this.store.pipe(select(getRouterState))),
    map(([a, b]) => b),
    map((b: any) => new AuthCheckActionCodeAction(b && b.state && b.state.root && b.state.root.queryParams))
  );

  @Effect()
  oobMode$: Observable<any> = this.actions$.pipe(
    ofType(AUTH.CHECK_ACTION_CODE_SUCCESS),
    map((action: AuthCheckActionCodeSuccessAction) => action.payload),
    switchMap(data => {
      switch (data.mode) {
        case 'verifyEmail':
          return of(new SessionOobVerifyEmailAction(data));
        case 'recoverEmail':
          return of(new SessionOobRecoderEmailAction(data));
        case 'resetPassword':
          return of(new SessionOobResetPssswordAction(data));
        default:
          return EMPTY;
      }
    })
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
