import { TEST_LIST, TEST_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TestInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    form: TestInfo;
    selectedGroupId: string;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    form: {
        id: 0,
        name: "",
        when: "",
        timestamp: 0,
        group_id: "",
        level: "simple"
    },
    selectedGroupId: ""
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case TEST_FORM.INIT: return testFormInit(state);
        case TEST_FORM.DESTROY: return testFormDestroy(state);
        case TEST_FORM.CLICK_SUBMIT: return testFormClickSubmit(state, action.payload);
        case TEST_FORM.UPDATE: return testFormUpdate(state, action.payload);
        case TEST_FORM.UPDATE_SUCCESS: return testFormUpdateSuccess(state);
        case TEST_FORM.UPDATE_FAILURE: return testFormUpdateFailure(state, action.payload);
        case TEST_FORM.ADD: return testFormAdd(state, action.payload);
        case TEST_FORM.ADD_SUCCESS: return testFormAddSuccess(state);
        case TEST_FORM.ADD_FAILURE: return testFormAddFailure(state, action.payload);
        case TEST_FORM.FORM_UPDATE: return testFormFormUpdate(state, action.payload);
        case TEST_LIST.SELECT: return testListSelect(state, action.payload);
        case TEST_LIST.CLICK_ADD: return testListClickAdd(state);
        case TEST_LIST.CLICK_EDIT: return testListClickEdit(state);
        default: return state;
    }
}
export function testFormInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function testFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testFormClickSubmit(state: State, payload: TestInfo): State {
    return { ...state };
}
export function testFormUpdate(state: State, payload: TestInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function testFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function testFormAdd(state: State, payload: TestInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        form: {
            ...initialState.form,
            group_id: state.form.group_id,
        },
    };
}
export function testFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function testFormFormUpdate(state: State, payload: TestInfo): State {
    return {
        ...state,
        form: {
            ...state.form,
            ...payload,
        },
    };
}
export function testListSelect(state: State, payload: TestInfo): State {
    return {
        ...state,
        form: payload,
    };
}
export function testListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
        form: initialState.form,
    };
}
export function testListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true,
    };
}
