import { SERVICES_SELECT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ServiceInfo, ServiceInfoWithGroup } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    selectedService: ServiceInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    selectedService: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SERVICES_SELECT.INIT: return servicesSelectInit(state);
        case SERVICES_SELECT.DESTROY: return servicesSelectDestroy(state);
        case SERVICES_SELECT.CLICK_SERVICE: return servicesSelectClickService(state, action.payload);
        default: return state;
    }
}
export function servicesSelectInit(state: State): State {
    return {
        ...initialState,
        isActive: true
    };
}
export function servicesSelectDestroy(state: State): State {
    return {
        ...state,
        isActive: false
    };
}
export function servicesSelectClickService(state: State, payload: ServiceInfoWithGroup): State {
    return { ...state, ...payload };
}
