import { State as SessionState } from "@client/reducers/session-reducer";
import { Selector } from "@ngrx/store";
export const getIsLoaded: Selector<SessionState, boolean> = (state: SessionState) => state.isLoaded;
export const getIsAnonymous: Selector<SessionState, boolean> = (state: SessionState) => state.isAnonymous;
export const getEmailVerified: Selector<SessionState, boolean> = (state: SessionState) => state.emailVerified;
export const getLastLoginAt: Selector<SessionState, string | null> = (state: SessionState) => state.lastLoginAt;
export const getUid: Selector<SessionState, string | null> = (state: SessionState) => state.uid;
export const getApiKey: Selector<SessionState, string | null> = (state: SessionState) => state.apiKey;
export const getEmail: Selector<SessionState, string> = (state: SessionState) => state.email;
export const getDisplayName: Selector<SessionState, string> = (state: SessionState) => state.displayName;
export const getCreatedAt: Selector<SessionState, string> = (state: SessionState) => state.createdAt;
export const getPhoneNumber: Selector<SessionState, string> = (state: SessionState) => state.phoneNumber;
export const getPhotoUrl: Selector<SessionState, string> = (state: SessionState) => state.photoURL;
export const getClaims: Selector<SessionState, {
    admin: boolean;
    accessLevel: number;
}> = (state: SessionState) => state.claims;
export const getClaimsAdmin: Selector<SessionState, boolean> = (state: SessionState) => getClaims(state) && getClaims(state).admin;
export const getClaimsAccessLevel: Selector<SessionState, number> = (state: SessionState) => getClaims(state) && getClaims(state).accessLevel;
