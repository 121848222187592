import { MONERIS, MONERIS_VAULT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isVisible: boolean;
    result: any | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isVisible: true,
    result: "result"
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case MONERIS_VAULT.INIT: return monerisVaultInit(state);
        case MONERIS_VAULT.DESTROY: return monerisVaultDestroy(state);
        case MONERIS_VAULT.SHOW_IFRAME: return monerisVaultShowIframe(state);
        case MONERIS_VAULT.HIDE_IFRAME: return monerisVaultHideIframe(state);
        case MONERIS_VAULT.CLICK_RETRY: return monerisVaultClickRetry(state);
        case MONERIS.HYDRATE: return monerisHydrate(state, action.payload);
        default: return state;
    }
}
export function monerisVaultInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function monerisVaultDestroy(state: State): State {
    return {
        ...initialState,
        isActive: false,
    };
}
export function monerisVaultShowIframe(state: State): State {
    return {
        ...state,
        isVisible: true,
    };
}
export function monerisVaultHideIframe(state: State): State {
    return {
        ...state,
        isVisible: false,
    };
}
export function monerisVaultClickRetry(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive
    };
}
export function monerisHydrate(state: State, payload: any): State {
    return {
        ...state,
        result: payload,
        hasSuccess: payload && payload.res_success === 'true',
        hasError: payload && payload.res_success !== 'true',
        message: payload && payload.res_success === 'true' ? 'Transaction traitée avec succès' : 'TRANSACTION NON COMPLÉTÉE',
    };
}
