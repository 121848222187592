import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getParksAll } from '@client/selectors';
import { ContractSearchForm, SubscriptionInfo, SubscriptionSpaqInfo } from '@client/utils/shared-constants';
import { select, Store } from '@ngrx/store';
import { Observable, of, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class SpaqApiService {
  private url = environment.backendApiUrl + '/spaq';

  constructor(private store: Store<any>, private http: HttpClient) {}

  fetchSpaqContract(contractNo: number) {
    return zip(
      this.getSubscriptionByContractNo({ contractNo: contractNo.toString(), phone: '11', postalCode: 'df' }),
      this.getVehiclesByContractNo({ contractNo: contractNo.toString() }),
      this.getServicesByContractNo({ contractNo: contractNo.toString() }),
      this.store.pipe(select(getParksAll), take(1))
    ).pipe(
      take(1),
      map(data => {
        return null;
      })
    );
  }

  getServicesByContractNo(search: Partial<ContractSearchForm>): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/subscription/${search.contractNo}/services`);
  }

  getSubscriptionByContractNo(search: Partial<ContractSearchForm>) {
    return this.http.post<SubscriptionSpaqInfo>(`${this.url}/subscription/${search.contractNo}`, {
      phone: search.phone,
      postalCode: search.postalCode
    });
  }

  getVehiclesByContractNo(search: Partial<ContractSearchForm>): Observable<SubscriptionInfo> {
    return this.http.get<SubscriptionInfo>(`${this.url}/subscription/${search.contractNo}/vehicles`);
  }

  search(search: ContractSearchForm) {
    return of(null);
  }
}
