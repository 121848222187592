import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_SERVICE_LIST, ADMIN_SERVICE_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeServiceInfo, OrganizationInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    form: AccessCodeServiceInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_SERVICE_FORM.INIT: return adminServiceFormInit(state);
        case ADMIN_SERVICE_FORM.DESTROY: return adminServiceFormDestroy(state);
        case ADMIN_SERVICE_FORM.CLICK_ADD_ORGANIZATION: return adminServiceFormClickAddOrganization(state);
        case ADMIN_SERVICE_FORM.CLICK_ADD_GROUP: return adminServiceFormClickAddGroup(state);
        case ADMIN_SERVICE_FORM.SELECT_ORGANIZATION_CODE: return adminServiceFormSelectOrganizationCode(state, action.payload);
        case ADMIN_SERVICE_FORM.SELECT_GROUP_CODE: return adminServiceFormSelectGroupCode(state, action.payload);
        case ADMIN_SERVICE_FORM.SELECT_PARK: return adminServiceFormSelectPark(state, action.payload);
        case ADMIN_SERVICE_FORM.SELECT_SERVICE: return adminServiceFormSelectService(state, action.payload);
        case ADMIN_SERVICE_FORM.CLICK_SUBMIT: return adminServiceFormClickSubmit(state, action.payload);
        case ADMIN_SERVICE_FORM.UPDATE: return adminServiceFormUpdate(state, action.payload);
        case ADMIN_SERVICE_FORM.UPDATE_SUCCESS: return adminServiceFormUpdateSuccess(state);
        case ADMIN_SERVICE_FORM.UPDATE_FAILURE: return adminServiceFormUpdateFailure(state, action.payload);
        case ADMIN_SERVICE_FORM.ADD: return adminServiceFormAdd(state, action.payload);
        case ADMIN_SERVICE_FORM.ADD_SUCCESS: return adminServiceFormAddSuccess(state);
        case ADMIN_SERVICE_FORM.ADD_FAILURE: return adminServiceFormAddFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_SERVICE_LIST.CLICK_ADD: return adminServiceListClickAdd(state);
        case ADMIN_SERVICE_LIST.CLICK_EDIT: return adminServiceListClickEdit(state);
        case ADMIN_SERVICE_LIST.SELECT: return adminServiceListSelect(state, action.payload);
        case ADMIN_SERVICE_LIST.SELECT_ORGANIZATION_GROUP: return adminServiceListSelectOrganizationGroup(state, action.payload);
        case ADMIN_SERVICE_LIST.SELECT_ORGANIZATION: return adminServiceListSelectOrganization(state, action.payload);
        default: return state;
    }
}
export function adminServiceFormInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function adminServiceFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminServiceFormClickAddOrganization(state: State): State {
    return state;
}
export function adminServiceFormClickAddGroup(state: State): State {
    return state;
}
export function adminServiceFormSelectOrganizationCode(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            organizationCode: payload,
        },
    };
}
export function adminServiceFormSelectGroupCode(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload,
        },
    };
}
export function adminServiceFormSelectPark(state: State, payload: number): State {
    return {
        ...state, form: {
            ...state.form,
            parkIdentificationNo: payload,
        },
    };
}
export function adminServiceFormSelectService(state: State, payload: number): State {
    return {
        ...state, form: {
            ...state.form,
            serviceId: payload,
        },
    };
}
export function adminServiceFormClickSubmit(state: State, payload: AccessCodeServiceInfo): State {
    return { ...state };
}
export function adminServiceFormUpdate(state: State, payload: AccessCodeServiceInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminServiceFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminServiceFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminServiceFormAdd(state: State, payload: AccessCodeServiceInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminServiceFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        form: {
            ...state.form,
            serviceId: null,
            parkIdentificationNo: null,
        },
    };
}
export function adminServiceFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        form: {
            ...initialState.form,
            organizationCode: payload.organizationCode
        }
    };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        form: {
            ...initialState.form,
            organizationCode: state.form && state.form.organizationCode || null,
            groupCode: payload.groupCode
        }
    };
}
export function adminServiceListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
    };
}
export function adminServiceListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true,
    };
}
export function adminServiceListSelect(state: State, payload: AccessCodeServiceInfo): State {
    return {
        ...state,
        form: payload,
    };
}
export function adminServiceListSelectOrganizationGroup(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload,
        },
    };
}
export function adminServiceListSelectOrganization(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            organizationCode: payload,
        },
    };
}
