import { Injectable } from "@angular/core";
import { IVehiclesEditDispatcher } from "@client/interfaces/vehicles-edit-interface";
import { Store } from "@ngrx/store";
import { VehiclesEditInitAction, VehiclesEditDestroyAction, VehiclesEditFormUpdateAction, VehiclesEditSelectYearAction, VehiclesEditSelectBrandAction, VehiclesEditSelectModelAction, VehiclesEditClickSubmitAction, VehiclesEditUpdateAction, VehiclesEditUpdateSuccessAction, VehiclesEditUpdateFailureAction } from "@client/actions/vehicles-edit-actions";
import { Vehicule } from "@client/utils/shared-constants";
@Injectable()
export class VehiclesEditDispatcher implements IVehiclesEditDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditDestroyAction(payload, correlationId));
    }
    formUpdate(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditFormUpdateAction(payload, correlationId));
    }
    selectYear(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditSelectYearAction(payload, correlationId));
    }
    selectBrand(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditSelectBrandAction(payload, correlationId));
    }
    selectModel(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditSelectModelAction(payload, correlationId));
    }
    clickSubmit(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditClickSubmitAction(payload, correlationId));
    }
    update(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditUpdateAction(payload, correlationId));
    }
    updateSuccess(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditUpdateSuccessAction(payload, correlationId));
    }
    updateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesEditUpdateFailureAction(payload, correlationId));
    }
}
