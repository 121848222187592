import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zipcode'
})
export class ZipcodePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? value.replace(/([A-Z]\d[A-Z]).?(\d[A-Z]\d)/, '$1 $2') : null;
  }
}
