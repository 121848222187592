import { Injectable } from "@angular/core";
import { ISectorsDispatcher } from "@client/interfaces/sectors-interface";
import { Store } from "@ngrx/store";
import { SectorsStartServiceAction, SectorsStopServiceAction, SectorsHydrateAction, SectorsHydrateSuccessAction, SectorsHydrateFailureAction } from "@client/actions/sectors-actions";
import { SectorInfo } from "@client/utils/shared-constants";
@Injectable()
export class SectorsDispatcher implements ISectorsDispatcher {
    constructor(private store: Store<any>) {
    }
    startService(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SectorsStartServiceAction(payload, correlationId));
    }
    stopService(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SectorsStopServiceAction(payload, correlationId));
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new SectorsHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: SectorInfo[], correlationId = Date.now()) {
        this.store.dispatch(new SectorsHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new SectorsHydrateFailureAction(payload, correlationId));
    }
}
