import { State as ServicesSelectState } from "@client/reducers/services-select-reducer";
import { Selector } from "@ngrx/store";
import { ServiceInfo, ServiceMetaInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => state.isActive;
export const getMessage: Selector<ServicesSelectState, string | null> = (state: ServicesSelectState) => state.message;
export const getHasError: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => state.hasError;
export const getHasSuccess: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => state.hasSuccess;
export const getIsLoading: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => state.isLoading;
export const getSelectedService: Selector<ServicesSelectState, ServiceInfo | null> = (state: ServicesSelectState) => state.selectedService;
export const getSelectedServicePeriod: Selector<ServicesSelectState, any> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).period;
export const getSelectedServiceAvailableFrom: Selector<ServicesSelectState, string | null> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).availableFrom;
export const getSelectedServiceAvailableServiceId: Selector<ServicesSelectState, number> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).availableServiceId;
export const getSelectedServiceAvailableUntil: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).availableUntil;
export const getSelectedServiceCancelationNotice: Selector<ServicesSelectState, number> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).cancelationNotice;
export const getSelectedServiceCancelationNoticeTimeUnit: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).cancelationNoticeTimeUnit;
export const getSelectedServiceDuration: Selector<ServicesSelectState, number> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).duration;
export const getSelectedServiceDurationTimeUnit: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).durationTimeUnit;
export const getSelectedServiceIsBankingCardPaymentAccepted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isBankingCardPaymentAccepted;
export const getSelectedServiceIsCancelable: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isCancelable;
export const getSelectedServiceIsCancelationFirstDayOfMonth: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isCancelationFirstDayOfMonth;
export const getSelectedServiceIsDasPaymentAccepted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isDasPaymentAccepted;
export const getSelectedServiceIsFinancialInstitutionPaymentAccepted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isFinancialInstitutionPaymentAccepted;
export const getSelectedServiceIsFixedDuration: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isFixedDuration;
export const getSelectedServiceIsPpaPaymentAccepted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isPpaPaymentAccepted;
export const getSelectedServiceIsSubscriptionAvailable: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isSubscriptionAvailable;
export const getSelectedServiceIsTax1Exempted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isTax1Exempted;
export const getSelectedServiceIsTax2Exempted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isTax2Exempted;
export const getSelectedServiceIsTax3Exempted: Selector<ServicesSelectState, boolean> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).isTax3Exempted;
export const getSelectedServiceOnSaleFrom: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).onSaleFrom;
export const getSelectedServiceOnSaleUntil: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).onSaleUntil;
export const getSelectedServiceParkIdentificationNo: Selector<ServicesSelectState, number> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).parkIdentificationNo;
export const getSelectedServicePartialMonthCalculation: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).partialMonthCalculation;
export const getSelectedServicePrice: Selector<ServicesSelectState, number> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).price;
export const getSelectedServiceSectorId: Selector<ServicesSelectState, number> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).sectorId;
export const getSelectedServiceServiceBillingType: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).serviceBillingType;
export const getSelectedServiceServiceDescription: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).serviceDescription;
export const getSelectedServiceServiceStartRule: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).serviceStartRule;
export const getSelectedServiceServiceType: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).serviceType;
export const getSelectedServiceMeta: Selector<ServicesSelectState, ServiceMetaInfo> = (state: ServicesSelectState) => getSelectedService(state) && getSelectedService(state).meta;
export const getSelectedServiceMetaNote: Selector<ServicesSelectState, string> = (state: ServicesSelectState) => getSelectedServiceMeta(state) && getSelectedServiceMeta(state).note;
