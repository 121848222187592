import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'spaq-layout-account-container',
  template: `
    <div class="container-fluid h-100">
      <div class="row justify-content-md-center h-100">
        <div class="col-md-12 m-0 h-100">
          <div class="flex-row h-100 row row-fluid">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutAccountContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
