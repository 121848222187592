import { State as VehiclesEditState } from "@client/reducers/vehicles-edit-reducer";
import { Selector } from "@ngrx/store";
import { Vehicule } from "@client/utils/shared-constants";
export const getHasSuccess: Selector<VehiclesEditState, boolean> = (state: VehiclesEditState) => state.hasSuccess;
export const getIsActive: Selector<VehiclesEditState, boolean> = (state: VehiclesEditState) => state.isActive;
export const getHasError: Selector<VehiclesEditState, boolean> = (state: VehiclesEditState) => state.hasError;
export const getIsLoading: Selector<VehiclesEditState, boolean> = (state: VehiclesEditState) => state.isLoading;
export const getIsCompleted: Selector<VehiclesEditState, boolean> = (state: VehiclesEditState) => state.isCompleted;
export const getRedirectOnComplete: Selector<VehiclesEditState, boolean> = (state: VehiclesEditState) => state.redirectOnComplete;
export const getMessage: Selector<VehiclesEditState, string | null> = (state: VehiclesEditState) => state.message;
export const getForm: Selector<VehiclesEditState, Vehicule> = (state: VehiclesEditState) => state.form;
export const getFormDescription: Selector<VehiclesEditState, string> = (state: VehiclesEditState) => getForm(state) && getForm(state).description;
export const getFormId: Selector<VehiclesEditState, number> = (state: VehiclesEditState) => getForm(state) && getForm(state).id;
export const getFormKey: Selector<VehiclesEditState, string> = (state: VehiclesEditState) => getForm(state) && getForm(state).key;
export const getFormLicencePlate: Selector<VehiclesEditState, string> = (state: VehiclesEditState) => getForm(state) && getForm(state).licencePlate;
export const getFormManufacturer: Selector<VehiclesEditState, string> = (state: VehiclesEditState) => getForm(state) && getForm(state).manufacturer;
export const getFormModel: Selector<VehiclesEditState, string> = (state: VehiclesEditState) => getForm(state) && getForm(state).model;
export const getFormVehiculeYear: Selector<VehiclesEditState, string> = (state: VehiclesEditState) => getForm(state) && getForm(state).vehiculeYear;
