import { TEST_GROUP_FORM, TEST_GROUP_LIST } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { TestGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: TestGroupInfo[];
    selecteGroup: TestGroupInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: [],
    selecteGroup: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case TEST_GROUP_LIST.INIT: return testGroupListInit(state);
        case TEST_GROUP_LIST.DESTROY: return testGroupListDestroy(state);
        case TEST_GROUP_LIST.HYDRATE: return testGroupListHydrate(state);
        case TEST_GROUP_LIST.HYDRATE_SUCCESS: return testGroupListHydrateSuccess(state, action.payload);
        case TEST_GROUP_LIST.HYDRATE_FAILURE: return testGroupListHydrateFailure(state, action.payload);
        case TEST_GROUP_LIST.SELECT: return testGroupListSelect(state, action.payload);
        case TEST_GROUP_LIST.DELETE: return testGroupListDelete(state);
        case TEST_GROUP_LIST.DELETE_SUCCESS: return testGroupListDeleteSuccess(state);
        case TEST_GROUP_LIST.DELETE_FAILURE: return testGroupListDeleteFailure(state, action.payload);
        case TEST_GROUP_LIST.CLICK_ADD: return testGroupListClickAdd(state);
        case TEST_GROUP_LIST.CLICK_EDIT: return testGroupListClickEdit(state);
        case TEST_GROUP_LIST.CLICK_DELETE: return testGroupListClickDelete(state);
        case TEST_GROUP_FORM.UPDATE_SUCCESS: return testGroupFormUpdateSuccess(state);
        case TEST_GROUP_FORM.ADD_SUCCESS: return testGroupFormAddSuccess(state);
        default: return state;
    }
}
export function testGroupListInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function testGroupListDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
    };
}
export function testGroupListHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function testGroupListHydrateSuccess(state: State, payload: TestGroupInfo[]): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        data: payload || [],
    };
}
export function testGroupListHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function testGroupListSelect(state: State, payload: TestGroupInfo): State {
    return { ...state, selecteGroup: payload || null };
}
export function testGroupListDelete(state: State): State {
    return state;
}
export function testGroupListDeleteSuccess(state: State): State {
    return {
        ...state,
        selecteGroup: null,
    };
}
export function testGroupListDeleteFailure(state: State, payload: any): State {
    return { ...state };
}
export function testGroupListClickAdd(state: State): State {
    return {
        ...state,
        selecteGroup: null,
    };
}
export function testGroupListClickEdit(state: State): State {
    return state;
}
export function testGroupListClickDelete(state: State): State {
    return state;
}
export function testGroupFormUpdateSuccess(state: State): State {
    return state;
}
export function testGroupFormAddSuccess(state: State): State {
    return state;
}
