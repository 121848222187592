import { Injectable } from "@angular/core";
import { IVehiclesAddDispatcher } from "@client/interfaces/vehicles-add-interface";
import { Store } from "@ngrx/store";
import { VehiclesAddInitAction, VehiclesAddDestroyAction, VehiclesAddFormUpdateAction, VehiclesAddSelectYearAction, VehiclesAddSelectBrandAction, VehiclesAddSelectModelAction, VehiclesAddClickSubmitAction, VehiclesAddAddAction, VehiclesAddAddSuccessAction, VehiclesAddAddFailureAction, VehiclesAddUpdateAction, VehiclesAddUpdateSuccessAction, VehiclesAddUpdateFailureAction, VehiclesAddValidationRequiredAction } from "@client/actions/vehicles-add-actions";
import { Vehicule } from "@client/utils/shared-constants";
@Injectable()
export class VehiclesAddDispatcher implements IVehiclesAddDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddDestroyAction(payload, correlationId));
    }
    formUpdate(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddFormUpdateAction(payload, correlationId));
    }
    selectYear(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddSelectYearAction(payload, correlationId));
    }
    selectBrand(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddSelectBrandAction(payload, correlationId));
    }
    selectModel(payload: string, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddSelectModelAction(payload, correlationId));
    }
    clickSubmit(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddClickSubmitAction(payload, correlationId));
    }
    add(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddAddAction(payload, correlationId));
    }
    addSuccess(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddAddSuccessAction(payload, correlationId));
    }
    addFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddAddFailureAction(payload, correlationId));
    }
    update(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddUpdateAction(payload, correlationId));
    }
    updateSuccess(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddUpdateSuccessAction(payload, correlationId));
    }
    updateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddUpdateFailureAction(payload, correlationId));
    }
    validationRequired(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesAddValidationRequiredAction(payload, correlationId));
    }
}
