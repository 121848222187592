import { Injectable } from "@angular/core";
import { IAccessCodeDispatcher } from "@client/interfaces/access-code-interface";
import { Store } from "@ngrx/store";
import { AccessCodeInitAction, AccessCodeDestroyAction, AccessCodeHydrateAction, AccessCodeHydrateFailureAction, AccessCodeHydrateSuccessAction, AccessCodeAddAction, AccessCodeDeleteAction, AccessCodeAddSuccessAction, AccessCodeAddFailureAction } from "@client/actions/access-code-actions";
import { AccessCodeGroupInfo, AccessCodeSearchInfo } from "@client/utils/shared-constants";
@Injectable()
export class AccessCodeDispatcher implements IAccessCodeDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeDestroyAction(payload, correlationId));
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeHydrateAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeHydrateFailureAction(payload, correlationId));
    }
    hydrateSuccess(payload: AccessCodeGroupInfo, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeHydrateSuccessAction(payload, correlationId));
    }
    add(payload: AccessCodeSearchInfo, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddAction(payload, correlationId));
    }
    delete(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeDeleteAction(payload, correlationId));
    }
    addSuccess(payload: AccessCodeSearchInfo, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddSuccessAction(payload, correlationId));
    }
    addFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeAddFailureAction(payload, correlationId));
    }
}
