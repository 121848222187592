import { State as ContractCreateState } from "@client/reducers/contract-create-reducer";
import { Selector } from "@ngrx/store";
export const getIsActive: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.isActive;
export const getMessage: Selector<ContractCreateState, string | null> = (state: ContractCreateState) => state.message;
export const getHasError: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.hasError;
export const getHasSuccess: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.hasSuccess;
export const getIsLoading: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.isLoading;
export const getHasSubscriptionUpdate: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.hasSubscriptionUpdate;
export const getIsCompleted: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.isCompleted;
export const getStepCompleted: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.stepCompleted;
export const getHasVehicles: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.hasVehicles;
export const getStep: Selector<ContractCreateState, number> = (state: ContractCreateState) => state.step;
export const getSelectedParkId: Selector<ContractCreateState, number | null> = (state: ContractCreateState) => state.selectedParkId;
export const getSelectedServiceId: Selector<ContractCreateState, number | null> = (state: ContractCreateState) => state.selectedServiceId;
export const getSelectedPayment: Selector<ContractCreateState, string | null> = (state: ContractCreateState) => state.selectedPayment;
export const getConditionsServiceAcceptedAt: Selector<ContractCreateState, string> = (state: ContractCreateState) => state.conditionsServiceAcceptedAt;
export const getConditionsPaymentAcceptedAt: Selector<ContractCreateState, string> = (state: ContractCreateState) => state.conditionsPaymentAcceptedAt;
export const getIsVisibleVehicleForm: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.isVisibleVehicleForm;
export const getSkipPayment: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.skipPayment;
export const getSkipPaymentConditions: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.skipPaymentConditions;
export const getPaymentAuthRequired: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.paymentAuthRequired;
export const getChoosePaymentCompleted: Selector<ContractCreateState, boolean> = (state: ContractCreateState) => state.choosePaymentCompleted;
