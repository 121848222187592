import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BaseStore } from "@client/lib/base-store";
import { Observable } from "rxjs";
import { AccessCodeSearchInfo, OrganizationInfo, OrganizationConfigInfo } from "@client/utils/shared-constants";
import { State } from "@client/reducers/access-code-add-reducer";
import { getAccessCodeAddMessage, getAccessCodeAddHasError, getAccessCodeAddHasSuccess, getAccessCodeAddIsLoading, getAccessCodeAddIsActive, getAccessCodeAddForm, getAccessCodeAddFormCompanyCode, getAccessCodeAddFormOrganization, getAccessCodeAddFormOrganizationAdresse, getAccessCodeAddFormOrganizationCity, getAccessCodeAddFormOrganizationOrganizationCode, getAccessCodeAddFormOrganizationOrganizationName, getAccessCodeAddFormOrganizationPhoneNumber, getAccessCodeAddFormOrganizationPostalCode, getAccessCodeAddFormOrganizationProvince, getAccessCodeAddFormOrganizationLogo, getAccessCodeAddFormOrganizationConfig, getAccessCodeAddFormOrganizationConfigEnableGateway, getAccessCodeAddFormOrganizationConfigEnablePublicRegistration, getAccessCodeAddFormOrganizationConfigEnablePrivateRegistration, getAccessCodeAddFormOrganizationConfigDisablePublicSubscriptionService, getAccessCodeAddFormUserCode } from "@client/selectors";
/*test*/ 
@Injectable()
export class AccessCodeAddStore extends BaseStore {
    get message(): Observable<string | null> {
        return this.store.pipe(select(getAccessCodeAddMessage));
    }
    get hasError(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddHasError));
    }
    get hasSuccess(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddHasSuccess));
    }
    get isLoading(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddIsLoading));
    }
    get isActive(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddIsActive));
    }
    get form(): Observable<AccessCodeSearchInfo> {
        return this.store.pipe(select(getAccessCodeAddForm));
    }
    get formCompanyCode(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormCompanyCode));
    }
    get formOrganization(): Observable<OrganizationInfo> {
        return this.store.pipe(select(getAccessCodeAddFormOrganization));
    }
    get formOrganizationAdresse(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationAdresse));
    }
    get formOrganizationCity(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationCity));
    }
    get formOrganizationOrganizationCode(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationOrganizationCode));
    }
    get formOrganizationOrganizationName(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationOrganizationName));
    }
    get formOrganizationPhoneNumber(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationPhoneNumber));
    }
    get formOrganizationPostalCode(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationPostalCode));
    }
    get formOrganizationProvince(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationProvince));
    }
    get formOrganizationLogo(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationLogo));
    }
    get formOrganizationConfig(): Observable<OrganizationConfigInfo | null> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationConfig));
    }
    get formOrganizationConfigEnableGateway(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationConfigEnableGateway));
    }
    get formOrganizationConfigEnablePublicRegistration(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationConfigEnablePublicRegistration));
    }
    get formOrganizationConfigEnablePrivateRegistration(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationConfigEnablePrivateRegistration));
    }
    get formOrganizationConfigDisablePublicSubscriptionService(): Observable<boolean> {
        return this.store.pipe(select(getAccessCodeAddFormOrganizationConfigDisablePublicSubscriptionService));
    }
    get formUserCode(): Observable<string> {
        return this.store.pipe(select(getAccessCodeAddFormUserCode));
    }
}
