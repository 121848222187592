import { Injectable } from '@angular/core';
import { REGISTER_PENDING } from '@client/actions';
import { AuthLogoutAction, AuthSendEmailVerificationLinkAction } from '@client/actions/auth-actions';
import { AuthService } from '@client/core/services/auth.service';
import { Action } from '@client/lib/action';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class RegisterEmailPendingAuthEffects {
  @Effect()
  Logout$: Observable<Action> = this.actions$.pipe(ofType(REGISTER_PENDING.CLICK_LOGOUT), mapTo(new AuthLogoutAction()));
  @Effect()
  sendEmail$: Observable<Action> = this.actions$.pipe(ofType(REGISTER_PENDING.CLICK_RESENT), mapTo(new AuthSendEmailVerificationLinkAction()));

  constructor(private actions$: Actions) {}
}
