import { Injectable } from '@angular/core';
import { SERVICES, SESSION } from '@client/actions';
import { ServicesHydrateAction, ServicesHydrateFailureAction, ServicesHydrateSuccessAction } from '@client/actions/services-actions';
import { SessionInitAction } from '@client/actions/session-actions';
import { ServicesService } from '@client/core/services/services.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { empty, iif, of } from 'rxjs';

import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../configs/environment';

@Injectable()
export class ServicesEffects {
  @Effect({ dispatch: true })
  hydrateParks$ = this.actions$.pipe(
    ofType<ServicesHydrateAction>(SERVICES.HYDRATE),
    switchMap(action =>
      this.services.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map(services => new ServicesHydrateSuccessAction(services, action.correlationId)),
        catchError(error => of(new ServicesHydrateFailureAction(error, action.correlationId)))
      )
    )
  );
  @Effect({ dispatch: true })
  sessionInit$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    switchMap(action => iif(() => environment.services.services, of(new ServicesHydrateAction(null, action.correlationId)), empty()))
  );

  constructor(private actions$: Actions, private store: Store<any>, private services: ServicesService) {}
}
