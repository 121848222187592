import { SESSION, USER } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { UserAccountStatus, SubscriptionInfo, Vehicule, SubscriptionService, PaymentInfo, UserState, LoginForm } from "@client/utils/shared-constants";
export interface State {
    accountStatus: UserAccountStatus;
    subscription: SubscriptionInfo | null;
    vehicles: Vehicule[];
    subscriptionServices: SubscriptionService[];
    services: SubscriptionService[];
    payments: PaymentInfo[];
    moneris: {
        data_key: string | null;
    };
}
export const initialState: State = {
    accountStatus: {
        isCompleted: false,
        isAccessCodeRequired: false,
        isSubscriptionRequired: false,
        isContractRequired: false,
        isCipRequired: false
    },
    subscription: null,
    vehicles: [],
    subscriptionServices: [],
    services: [],
    payments: [],
    moneris: {
        data_key: null
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case USER.START_SERVICE: return userStartService(state);
        case USER.STOP_SERVICE: return userStopService(state);
        case USER.HYDRATE: return userHydrate(state);
        case USER.HYDRATE_SUCCESS: return userHydrateSuccess(state, action.payload);
        case USER.HYDRATE_FAILURE: return userHydrateFailure(state, action.payload);
        case USER.DELETE_USER: return userDeleteUser(state, action.payload);
        case USER.DELETE_USER_SUCCESS: return userDeleteUserSuccess(state);
        case USER.DELETE_USER_FAILURE: return userDeleteUserFailure(state);
        case USER.SYNC: return userSync(state);
        case USER.SYNC_SUCCESS: return userSyncSuccess(state);
        case USER.SYNC_FAILURE: return userSyncFailure(state, action.payload);
        case SESSION.DESTROY: return sessionDestroy(state);
        default: return state;
    }
}
export function userStartService(state: State): State {
    return state;
}
export function userStopService(state: State): State {
    return state;
}
export function userHydrate(state: State): State {
    return state;
}
export function userHydrateSuccess(state: State, payload: UserState): State {
    return { ...state, ...payload };
}
export function userHydrateFailure(state: State, payload: any): State {
    return { ...state };
}
export function userDeleteUser(state: State, payload: LoginForm): State {
    return state;
}
export function userDeleteUserSuccess(state: State): State {
    return state;
}
export function userDeleteUserFailure(state: State): State {
    return state;
}
export function userSync(state: State): State {
    return state;
}
export function userSyncSuccess(state: State): State {
    return state;
}
export function userSyncFailure(state: State, payload: any): State {
    return state;
}
export function sessionDestroy(state: State): State {
    return initialState;
}
