<div class="h-100">
  <div [class]="'pane-p navbar-collapse navbar-expand h-100'" id="collapsing-navbar">
    <div class="panelbar p-0 h-100">
      <header class="p-2">
        <div class="d-flex flex-row">
          <div [ngStyle]="{ backgroundImage: 'url(' + avatar + ')' }" class="avatar"></div>
          <div>
            <h6>{{ displayName || 'Bienvenue' }}</h6>
            <a id="logout" (click)="logout()" href="javascript://">Déconnexion</a>
          </div>
        </div>
      </header>
      <kendo-buttongroup *ngIf="enableAdminOptions" [selection]="'single'" class="w-100">
        <button (click)="onClickUserMenu()" *ngIf="!isStaff" [selected]="!isAdminMenu" [toggleable]="true" class="w-50" kendoButton>Utilisateur</button>
        <button (click)="onClickAdminMenu()" [selected]="isAdminMenu" [toggleable]="true" class="w-50" kendoButton>Administrateur</button>
      </kendo-buttongroup>
      <div class="options">Menu</div>
      <kendo-panelbar keepItemContent="true">
        <kendo-panelbar-item
          (click)="click($event, item)"
          *ngFor="let item of items"
          [class]="item.class"
          [expanded]="selectedGroup === item.id"
          [hidden]="item.enabled !== true"
          [iconClass]="item.icon"
          [id]="item.id"
          [selected]="selectedGroup === item.id && !selectedItem"
          [title]="item.title"
        >
          <kendo-panelbar-item
            (click)="click($event, subItem)"
            *ngFor="let subItem of item.children"
            [class]="subItem.class"
            [hidden]="subItem.enabled !== true"
            [iconClass]="subItem.icon"
            [id]="subItem.id"
            [selected]="selectedItem === subItem.id"
            [title]="subItem.title"
          ></kendo-panelbar-item>
        </kendo-panelbar-item>
      </kendo-panelbar>
      <div class="options"></div>
      <div class="build"  *ngIf="(enableAdminOptions$ | async)">
        <b>{{ lastBuild }}</b>
      </div>
    </div>
  </div>
</div>
