import { State as AccessCodeAddState } from "@client/reducers/access-code-add-reducer";
import { Selector } from "@ngrx/store";
import { AccessCodeSearchInfo, OrganizationInfo, OrganizationConfigInfo } from "@client/utils/shared-constants";
export const getMessage: Selector<AccessCodeAddState, string | null> = (state: AccessCodeAddState) => state.message;
export const getHasError: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => state.hasError;
export const getHasSuccess: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => state.hasSuccess;
export const getIsLoading: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => state.isLoading;
export const getIsActive: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => state.isActive;
export const getForm: Selector<AccessCodeAddState, AccessCodeSearchInfo> = (state: AccessCodeAddState) => state.form;
export const getFormCompanyCode: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getForm(state) && getForm(state).companyCode;
export const getFormOrganization: Selector<AccessCodeAddState, OrganizationInfo> = (state: AccessCodeAddState) => getForm(state) && getForm(state).organization;
export const getFormOrganizationAdresse: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).adresse;
export const getFormOrganizationCity: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).city;
export const getFormOrganizationOrganizationCode: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).organizationCode;
export const getFormOrganizationOrganizationName: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).organizationName;
export const getFormOrganizationPhoneNumber: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).phoneNumber;
export const getFormOrganizationPostalCode: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).postalCode;
export const getFormOrganizationProvince: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).province;
export const getFormOrganizationLogo: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).logo;
export const getFormOrganizationConfig: Selector<AccessCodeAddState, OrganizationConfigInfo | null> = (state: AccessCodeAddState) => getFormOrganization(state) && getFormOrganization(state).config;
export const getFormOrganizationConfigEnableGateway: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => getFormOrganizationConfig(state) && getFormOrganizationConfig(state).enableGateway;
export const getFormOrganizationConfigEnablePublicRegistration: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => getFormOrganizationConfig(state) && getFormOrganizationConfig(state).enablePublicRegistration;
export const getFormOrganizationConfigEnablePrivateRegistration: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => getFormOrganizationConfig(state) && getFormOrganizationConfig(state).enablePrivateRegistration;
export const getFormOrganizationConfigDisablePublicSubscriptionService: Selector<AccessCodeAddState, boolean> = (state: AccessCodeAddState) => getFormOrganizationConfig(state) && getFormOrganizationConfig(state).disablePublicSubscriptionService;
export const getFormUserCode: Selector<AccessCodeAddState, string> = (state: AccessCodeAddState) => getForm(state) && getForm(state).userCode;
