import { Injectable } from "@angular/core";
import { ILayoutDispatcher } from "@client/interfaces/layout-interface";
import { Store } from "@ngrx/store";
import { LayoutClickAction, LayoutSetScreenAction, LayoutAddNodeAction, LayoutRemoveNodeAction, LayoutAddGroupAction, LayoutRemoveGroupAction, LayoutCloseSideNavAction, LayoutOpenSideNavAction, LayoutCollapseSideNavAction, LayoutClickDeleteUserAction, LayoutInitPublicAction, LayoutInitPrivateAction, LayoutCollapseAdminOptionsAction, LayoutClickAdminMenuAction, LayoutClickUserMenuAction } from "@client/actions/layout-actions";
import { NodeItem, LayoutScreenInfo } from "@client/utils/shared-constants";
@Injectable()
export class LayoutDispatcher implements ILayoutDispatcher {
    constructor(private store: Store<any>) {
    }
    click(payload: NodeItem, correlationId = Date.now()) {
        this.store.dispatch(new LayoutClickAction(payload, correlationId));
    }
    setScreen(payload: LayoutScreenInfo, correlationId = Date.now()) {
        this.store.dispatch(new LayoutSetScreenAction(payload, correlationId));
    }
    addNode(payload: NodeItem, correlationId = Date.now()) {
        this.store.dispatch(new LayoutAddNodeAction(payload, correlationId));
    }
    removeNode(payload: NodeItem, correlationId = Date.now()) {
        this.store.dispatch(new LayoutRemoveNodeAction(payload, correlationId));
    }
    addGroup(payload: NodeItem, correlationId = Date.now()) {
        this.store.dispatch(new LayoutAddGroupAction(payload, correlationId));
    }
    removeGroup(payload: NodeItem, correlationId = Date.now()) {
        this.store.dispatch(new LayoutRemoveGroupAction(payload, correlationId));
    }
    closeSideNav(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutCloseSideNavAction(payload, correlationId));
    }
    openSideNav(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutOpenSideNavAction(payload, correlationId));
    }
    collapseSideNav(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutCollapseSideNavAction(payload, correlationId));
    }
    clickDeleteUser(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutClickDeleteUserAction(payload, correlationId));
    }
    initPublic(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutInitPublicAction(payload, correlationId));
    }
    initPrivate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutInitPrivateAction(payload, correlationId));
    }
    collapseAdminOptions(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutCollapseAdminOptionsAction(payload, correlationId));
    }
    clickAdminMenu(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutClickAdminMenuAction(payload, correlationId));
    }
    clickUserMenu(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new LayoutClickUserMenuAction(payload, correlationId));
    }
}
