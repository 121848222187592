import { ADMIN_FAQ_LIST_GROUP, ADMIN_FAQ_GROUP_EDIT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { FaqGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    data: FaqGroupInfo | null;
    isEdit: boolean;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    data: null,
    isEdit: false
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_FAQ_GROUP_EDIT.INIT: return adminFaqGroupEditInit(state);
        case ADMIN_FAQ_GROUP_EDIT.DESTROY: return adminFaqGroupEditDestroy(state);
        case ADMIN_FAQ_GROUP_EDIT.CANCEL: return adminFaqGroupEditCancel(state);
        case ADMIN_FAQ_GROUP_EDIT.CLICK_BACK: return adminFaqGroupEditClickBack(state);
        case ADMIN_FAQ_GROUP_EDIT.SUBMIT: return adminFaqGroupEditSubmit(state, action.payload);
        case ADMIN_FAQ_GROUP_EDIT.SUBMIT_SUCCESS: return adminFaqGroupEditSubmitSuccess(state, action.payload);
        case ADMIN_FAQ_GROUP_EDIT.SUBMIT_FAILURE: return adminFaqGroupEditSubmitFailure(state, action.payload);
        case ADMIN_FAQ_LIST_GROUP.CLICK_EDIT: return adminFaqListGroupClickEdit(state, action.payload);
        default: return state;
    }
}
export function adminFaqGroupEditInit(state: State): State {
    return {
        ...state, isActive: true
    };
}
export function adminFaqGroupEditDestroy(state: State): State {
    return {
        ...initialState, isActive: false
    };
}
export function adminFaqGroupEditCancel(state: State): State {
    return state;
}
export function adminFaqGroupEditClickBack(state: State): State {
    return state;
}
export function adminFaqGroupEditSubmit(state: State, payload: FaqGroupInfo): State {
    return { ...state, isLoading: true, hasError: false, hasSuccess: false };
}
export function adminFaqGroupEditSubmitSuccess(state: State, payload: FaqGroupInfo): State {
    return { ...state, isLoading: false, hasSuccess: true };
}
export function adminFaqGroupEditSubmitFailure(state: State, payload: any): State {
    return { ...state, isLoading: false, hasError: true, message: payload && payload.code || payload };
}
export function adminFaqListGroupClickEdit(state: State, payload: FaqGroupInfo): State {
    return { ...state, data: payload, isEdit: true };
}
