import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validate'
})
export class ValidatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return false;
    }
    if (value.invalid && (value.dirty || value.touched)) {
      return true;
    }

    return false;
  }
}
