import { USER_VEHICLES_DETAILS, VEHICLES_FORM, VEHICLES_DETAILS, VEHICLES_ADD, VEHICLES_EDIT, VEHICLES } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Vehicule } from "@client/utils/shared-constants";
export interface State {
    selectedVehicle: Vehicule | null;
    isHydrated: boolean;
    hasVehicles: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    isLoading: boolean;
    message: string | null;
    selectedYear: string;
    selectedBrand: string;
    selectedModel: string;
    years: any;
    brands: any;
    models: any;
    data: Vehicule[];
}
export const initialState: State = {
    selectedVehicle: null,
    isHydrated: false,
    hasVehicles: false,
    hasSuccess: false,
    hasError: false,
    isLoading: false,
    message: null,
    selectedYear: null,
    selectedBrand: null,
    selectedModel: null,
    years: [],
    brands: [],
    models: [],
    data: []
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case VEHICLES.INIT: return vehiclesInit(state);
        case VEHICLES.DESTROY: return vehiclesDestroy(state);
        case VEHICLES.FORM_HYDRATE: return vehiclesFormHydrate(state);
        case VEHICLES.FORM_HYDRATE_SUCCESS: return vehiclesFormHydrateSuccess(state, action.payload);
        case VEHICLES.FORM_HYDRATE_FAILURE: return vehiclesFormHydrateFailure(state, action.payload);
        case VEHICLES.HYDRATE: return vehiclesHydrate(state);
        case VEHICLES.HYDRATE_SUCCESS: return vehiclesHydrateSuccess(state, action.payload);
        case VEHICLES.HYDRATE_FAILURE: return vehiclesHydrateFailure(state, action.payload);
        case VEHICLES.HYDRATE_BRANDS_SUCCESS: return vehiclesHydrateBrandsSuccess(state, action.payload);
        case VEHICLES.HYDRATE_BRANDS_FAILURE: return vehiclesHydrateBrandsFailure(state, action.payload);
        case VEHICLES.HYDRATE_MODELS_SUCCESS: return vehiclesHydrateModelsSuccess(state, action.payload);
        case VEHICLES.HYDRATE_MODELS_FAILURE: return vehiclesHydrateModelsFailure(state, action.payload);
        case VEHICLES.SELECT_YEAR: return vehiclesSelectYear(state, action.payload);
        case VEHICLES.SELECT_BRAND: return vehiclesSelectBrand(state, action.payload);
        case USER_VEHICLES_DETAILS.CLICK_DELETE: return userVehiclesDetailsClickDelete(state, action.payload);
        case USER_VEHICLES_DETAILS.CLICK_EDIT: return userVehiclesDetailsClickEdit(state, action.payload);
        case VEHICLES_FORM.DESTROY: return vehiclesFormDestroy(state);
        case VEHICLES_DETAILS.CLICK_EDIT: return vehiclesDetailsClickEdit(state, action.payload);
        case VEHICLES_ADD.DESTROY: return vehiclesAddDestroy(state);
        case VEHICLES_ADD.SELECT_YEAR: return vehiclesAddSelectYear(state, action.payload);
        case VEHICLES_ADD.SELECT_BRAND: return vehiclesAddSelectBrand(state, action.payload);
        case VEHICLES_ADD.SELECT_MODEL: return vehiclesAddSelectModel(state, action.payload);
        case VEHICLES_EDIT.SELECT_YEAR: return vehiclesEditSelectYear(state, action.payload);
        case VEHICLES_EDIT.SELECT_BRAND: return vehiclesEditSelectBrand(state, action.payload);
        case VEHICLES_EDIT.SELECT_MODEL: return vehiclesEditSelectModel(state, action.payload);
        default: return state;
    }
}
export function vehiclesInit(state: State): State {
    return state;
}
export function vehiclesDestroy(state: State): State {
    return state;
}
export function vehiclesFormHydrate(state: State): State {
    return state;
}
export function vehiclesFormHydrateSuccess(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function vehiclesFormHydrateFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function vehiclesHydrate(state: State): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        isLoading: true,
        message: null,
    };
}
export function vehiclesHydrateSuccess(state: State, payload: any): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        isHydrated: true,
        hasVehicles: !!payload,
        data: payload,
    };
}
export function vehiclesHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
export function vehiclesHydrateBrandsSuccess(state: State, payload: string[]): State {
    return {
        ...state,
        brands: payload,
    };
}
export function vehiclesHydrateBrandsFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
export function vehiclesHydrateModelsSuccess(state: State, payload: string[]): State {
    return {
        ...state,
        models: payload,
    };
}
export function vehiclesHydrateModelsFailure(state: State, payload: any): State {
    return { ...state, ...payload };
}
export function vehiclesSelectYear(state: State, payload: string): State {
    return {
        ...state,
        selectedYear: payload,
        selectedBrand: null,
        selectedModel: null
    };
}
export function vehiclesSelectBrand(state: State, payload: string): State {
    return {
        ...state,
        selectedBrand: payload,
    };
}
export function userVehiclesDetailsClickDelete(state: State, payload: Vehicule): State {
    return {
        ...state,
    };
}
export function userVehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return {
        ...state,
        selectedYear: payload.vehiculeYear,
        selectedBrand: payload.manufacturer,
        selectedModel: payload.model,
        selectedVehicle: payload,
    };
}
export function vehiclesFormDestroy(state: State): State {
    return {
        ...state,
        selectedYear: null,
        selectedBrand: null,
        selectedModel: null,
        selectedVehicle: null
    };
}
export function vehiclesDetailsClickEdit(state: State, payload: Vehicule): State {
    return {
        ...state,
        selectedYear: payload.vehiculeYear,
        selectedBrand: payload.manufacturer,
        selectedModel: payload.model
    };
}
export function vehiclesAddDestroy(state: State): State {
    return {
        ...state,
        selectedVehicle: initialState.selectedVehicle,
    };
}
export function vehiclesAddSelectYear(state: State, payload: string): State {
    return {
        ...state,
        selectedYear: payload,
        selectedBrand: null,
        selectedModel: null
    };
}
export function vehiclesAddSelectBrand(state: State, payload: string): State {
    return {
        ...state,
        selectedBrand: payload,
        selectedModel: null
    };
}
export function vehiclesAddSelectModel(state: State, payload: string): State {
    return {
        ...state,
        selectedModel: payload,
    };
}
export function vehiclesEditSelectYear(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesEditSelectBrand(state: State, payload: string): State {
    return { ...state };
}
export function vehiclesEditSelectModel(state: State, payload: string): State {
    return { ...state };
}
