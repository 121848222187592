import { State as AdminFaqListGroupState } from "@client/reducers/admin-faq-list-group-reducer";
import { Selector } from "@ngrx/store";
import { FaqGroupInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminFaqListGroupState, boolean> = (state: AdminFaqListGroupState) => state.isActive;
export const getMessage: Selector<AdminFaqListGroupState, string | null> = (state: AdminFaqListGroupState) => state.message;
export const getHasError: Selector<AdminFaqListGroupState, boolean> = (state: AdminFaqListGroupState) => state.hasError;
export const getHasSuccess: Selector<AdminFaqListGroupState, boolean> = (state: AdminFaqListGroupState) => state.hasSuccess;
export const getIsLoading: Selector<AdminFaqListGroupState, boolean> = (state: AdminFaqListGroupState) => state.isLoading;
export const getCollection: Selector<AdminFaqListGroupState, FaqGroupInfo[]> = (state: AdminFaqListGroupState) => state.collection;
export const getSelectedFaqId: Selector<AdminFaqListGroupState, string> = (state: AdminFaqListGroupState) => state.selectedFaqId;
