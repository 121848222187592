import { Injectable } from "@angular/core";
import { IVehiclesDetailsDispatcher } from "@client/interfaces/vehicles-details-interface";
import { Store } from "@ngrx/store";
import { VehiclesDetailsInitAction, VehiclesDetailsDestroyAction, VehiclesDetailsClickAddAction, VehiclesDetailsClickEditAction, VehiclesDetailsClickDeleteAction } from "@client/actions/vehicles-details-actions";
import { Vehicule } from "@client/utils/shared-constants";
@Injectable()
export class VehiclesDetailsDispatcher implements IVehiclesDetailsDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesDetailsInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesDetailsDestroyAction(payload, correlationId));
    }
    clickAdd(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesDetailsClickAddAction(payload, correlationId));
    }
    clickEdit(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesDetailsClickEditAction(payload, correlationId));
    }
    clickDelete(payload: Vehicule, correlationId = Date.now()) {
        this.store.dispatch(new VehiclesDetailsClickDeleteAction(payload, correlationId));
    }
}
