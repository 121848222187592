import { Injectable } from "@angular/core";
import { IParksDispatcher } from "@client/interfaces/parks-interface";
import { Store } from "@ngrx/store";
import { ParksHydrateAction, ParksHydrateSuccessAction, ParksHydrateFailureAction, ParksSelectAction } from "@client/actions/parks-actions";
import { ParkInfo } from "@client/utils/shared-constants";
@Injectable()
export class ParksDispatcher implements IParksDispatcher {
    constructor(private store: Store<any>) {
    }
    hydrate(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new ParksHydrateAction(payload, correlationId));
    }
    hydrateSuccess(payload: ParkInfo[], correlationId = Date.now()) {
        this.store.dispatch(new ParksHydrateSuccessAction(payload, correlationId));
    }
    hydrateFailure(payload: any, correlationId = Date.now()) {
        this.store.dispatch(new ParksHydrateFailureAction(payload, correlationId));
    }
    select(payload: ParkInfo, correlationId = Date.now()) {
        this.store.dispatch(new ParksSelectAction(payload, correlationId));
    }
}
