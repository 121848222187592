import { Injectable } from '@angular/core';
import { SESSION } from '@client/actions';
import { AdminConfigurationHydrateAction, AdminConfigurationHydrateFailureAction, AdminConfigurationHydrateSuccessAction } from '@client/actions/admin-configuration-actions';
import { ADMIN_CONFIGURATION } from '@client/actions/index';
import { SessionInitAction } from '@client/actions/session-actions';
import { ConfigService } from '@client/core/services/config.service';
import { Action } from '@client/lib/action';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { SystemConfiguration } from '../../utils/shared-constants';

@Injectable()
export class FaqEffects {
  @Effect({ dispatch: true })
  authStateSuccess$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    map(action => new AdminConfigurationHydrateAction(null, action.correlationId))
  );
  @Effect({ dispatch: true })
  hydrateConfig$: Observable<Action> = this.actions$.pipe(
    ofType<AdminConfigurationHydrateAction>(ADMIN_CONFIGURATION.HYDRATE),
    switchMap(action =>
      this.config.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map((config: SystemConfiguration) => new AdminConfigurationHydrateSuccessAction(config, action.correlationId)),
        catchError(error => of(new AdminConfigurationHydrateFailureAction(error, action.correlationId)))
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private config: ConfigService) {}
}
