import { SUBSCRIPTION_EDIT, SUBSCRIPTION } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { SubscriptionInfo } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isHydrated: boolean;
    isLoading: boolean;
    isCompleted: boolean;
    data: SubscriptionInfo;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isHydrated: false,
    isLoading: false,
    isCompleted: false,
    data: {
        custom: "custom",
        spaq: {
            accesDocuments: [],
            accessOnHoldReason: "",
            addressLine1: "",
            identificationCode: "",
            addressLine2: "",
            appartmentNo: "",
            balance: 0,
            cellPhone: "",
            city: "",
            contractNo: 0,
            dynamicFieldJsonData: "",
            email: "",
            firstName: "",
            homePhone: "",
            homePhoneExtension: "",
            isAccessOnHold: null,
            language: "French",
            lastName: "",
            postalCode: "",
            province: "QC",
            serviceCenter: 0,
            sex: "Male",
            workPhone: "",
            workPhoneExtension: 0,
            paymentMethod: {
                CreditCardDataKey: "",
                EffectiveDate: "",
                EndDate: "",
                FinancialInstitutionId: 0,
                FolioNo: "",
                Method: "Cash",
                TransitNo: ""
            }
        }
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case SUBSCRIPTION.INIT: return subscriptionInit(state);
        case SUBSCRIPTION.DESTROY: return subscriptionDestroy(state);
        case SUBSCRIPTION.HYDRATE: return subscriptionHydrate(state);
        case SUBSCRIPTION.HYDRATE_SUCCESS: return subscriptionHydrateSuccess(state, action.payload);
        case SUBSCRIPTION.HYDRATE_FAILURE: return subscriptionHydrateFailure(state, action.payload);
        case SUBSCRIPTION_EDIT.UPLOAD_SUCCESS: return subscriptionEditUploadSuccess(state, action.payload);
        default: return state;
    }
}
export function subscriptionInit(state: State): State {
    return state;
}
export function subscriptionDestroy(state: State): State {
    return state;
}
export function subscriptionHydrate(state: State): State {
    return {
        ...state,
        message: null,
        hasSuccess: false,
        hasError: false,
        isLoading: true,
    };
}
export function subscriptionHydrateSuccess(state: State, payload: SubscriptionInfo): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        isCompleted: !!payload,
        isHydrated: true,
        data: payload,
    };
}
export function subscriptionHydrateFailure(state: State, payload: any): State {
    return {
        ...state,
        message: payload,
        hasError: true,
        isLoading: false,
    };
}
export function subscriptionEditUploadSuccess(state: State, payload: any): State {
    const custom = state.data && state.data.custom || {};
    return {
        ...state,
        data: {
            ...state.data,
            custom: {
                ...custom,
                [payload.key]: payload.file
            }
        }
    };
}
