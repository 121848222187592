import { State as EmailEditState } from "@client/reducers/email-edit-reducer";
import { Selector } from "@ngrx/store";
import { EmailEditForm } from "@client/utils/shared-constants";
export const getMessage: Selector<EmailEditState, string | null> = (state: EmailEditState) => state.message;
export const getHasError: Selector<EmailEditState, boolean> = (state: EmailEditState) => state.hasError;
export const getHasSuccess: Selector<EmailEditState, boolean> = (state: EmailEditState) => state.hasSuccess;
export const getIsActive: Selector<EmailEditState, boolean> = (state: EmailEditState) => state.isActive;
export const getIsLoading: Selector<EmailEditState, boolean> = (state: EmailEditState) => state.isLoading;
export const getIsFormVisible: Selector<EmailEditState, boolean> = (state: EmailEditState) => state.isFormVisible;
export const getForm: Selector<EmailEditState, EmailEditForm> = (state: EmailEditState) => state.form;
export const getFormEmail: Selector<EmailEditState, string> = (state: EmailEditState) => getForm(state) && getForm(state).email;
export const getFormCurrentPassword: Selector<EmailEditState, string> = (state: EmailEditState) => getForm(state) && getForm(state).current_password;
