import { AUTH, EMAIL_EDIT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { EmailEditForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isActive: boolean;
    isLoading: boolean;
    isFormVisible: boolean;
    form: EmailEditForm;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isActive: false,
    isLoading: false,
    isFormVisible: true,
    form: {
        email: "",
        current_password: ""
    }
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case EMAIL_EDIT.INIT: return emailEditInit(state);
        case EMAIL_EDIT.DESTROY: return emailEditDestroy(state);
        case EMAIL_EDIT.CLICK_SUBMIT: return emailEditClickSubmit(state, action.payload);
        case EMAIL_EDIT.UPDATE_FORM: return emailEditUpdateForm(state, action.payload);
        case AUTH.UPDATE_EMAIL: return authUpdateEmail(state, action.payload);
        case AUTH.UPDATE_EMAIL_SUCCESS: return authUpdateEmailSuccess(state, action.payload);
        case AUTH.UPDATE_EMAIL_FAILURE: return authUpdateEmailFailure(state, action.payload);
        default: return state;
    }
}
export function emailEditInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function emailEditDestroy(state: State): State {
    return {
        ...initialState,
        isActive: false,
    };
}
export function emailEditClickSubmit(state: State, payload: EmailEditForm): State {
    return { ...state };
}
export function emailEditUpdateForm(state: State, payload: EmailEditForm): State {
    return {
        ...state,
        form: payload,
    };
}
export function authUpdateEmail(state: State, payload: EmailEditForm): State {
    return {
        ...state,
        hasSuccess: false,
        hasError: false,
        isLoading: true,
        message: null,
    };
}
export function authUpdateEmailSuccess(state: State, payload: EmailEditForm): State {
    return {
        ...state,
        hasSuccess: true,
        isLoading: false,
        isFormVisible: false,
    };
}
export function authUpdateEmailFailure(state: State, payload: any): State {
    return {
        ...state,
        hasError: true,
        isLoading: false,
        message: payload,
    };
}
