import { REGISTER_EMAIL } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState } from "@client/utils/shared-constants";

export class RegisterEmailInitAction implements Action {
    readonly type = REGISTER_EMAIL.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterEmailDestroyAction implements Action {
    readonly type = REGISTER_EMAIL.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterEmailClickSubmitAction implements Action {
    readonly type = REGISTER_EMAIL.CLICK_SUBMIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterEmailClickLoginAction implements Action {
    readonly type = REGISTER_EMAIL.CLICK_LOGIN;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterEmailClickMethodAction implements Action {
    readonly type = REGISTER_EMAIL.CLICK_METHOD;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterEmailCompleteAction implements Action {
    readonly type = REGISTER_EMAIL.COMPLETE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class RegisterEmailFormUpdateAction implements Action {
    readonly type = REGISTER_EMAIL.FORM_UPDATE;
    constructor(public payload: RegisterEmailForm, public correlationId?: number) {
    }
}

export class RegisterEmailFormResetAction implements Action {
    readonly type = REGISTER_EMAIL.FORM_RESET;
    constructor(public payload?: null, public correlationId?: number) {
    }
}
