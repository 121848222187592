import { MONERIS, MONERIS_PAYPAGE } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isVisible: boolean;
    result: any | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isVisible: true,
    result: "result"
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case MONERIS_PAYPAGE.INIT: return monerisPaypageInit(state);
        case MONERIS_PAYPAGE.DESTROY: return monerisPaypageDestroy(state);
        case MONERIS_PAYPAGE.SHOW_IFRAME: return monerisPaypageShowIframe(state);
        case MONERIS_PAYPAGE.HIDE_IFRAME: return monerisPaypageHideIframe(state);
        case MONERIS_PAYPAGE.CLICK_RETRY: return monerisPaypageClickRetry(state);
        case MONERIS.HYDRATE: return monerisHydrate(state, action.payload);
        default: return state;
    }
}
export function monerisPaypageInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function monerisPaypageDestroy(state: State): State {
    return {
        ...initialState,
        isActive: false,
    };
}
export function monerisPaypageShowIframe(state: State): State {
    return {
        ...state,
        isVisible: true,
    };
}
export function monerisPaypageHideIframe(state: State): State {
    return {
        ...state,
        isVisible: false,
    };
}
export function monerisPaypageClickRetry(state: State): State {
    return {
        ...initialState,
        isActive: state.isActive
    };
}
export function monerisHydrate(state: State, payload: any): State {
    return {
        ...state,
        result: payload,
        hasSuccess: payload && payload.result === '1',
        hasError: payload && payload.result !== '1',
        message: payload && payload.result === '1' ? 'Transaction traitée avec succès' : 'TRANSACTION NON COMPLÉTÉE',
    };
}
