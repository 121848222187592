import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccessCodeModule } from '@client/modules/access-code';
import { ContractModule } from '@client/modules/contract';
import { SubscriptionModule } from '@client/modules/subscription';
import { FormModule } from '@client/shared/components/forms';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import {
  RecoverEmailComponent,
  RegisterAccessCodeComponent,
  RegisterContractComponent,
  RegisterContractConfirmDetailsComponent,
  RegisterContractEmailComponent,
  RegisterContractEmailConfirmComponent,
  RegisterContractEmailPendingComponent,
  RegisterEmailComponent,
  RegisterEmailConfirmationComponent,
  RegisterEmailFormComponent,
  RegisterEmailPendingComponent,
  RegisterSubscriptionComponent,
  RegisterTitleComponent
} from './components';
import {
  RegisterAccessCodePageComponent,
  RegisterContractConfirmationPageComponent,
  RegisterContractPageComponent,
  RegisterEmailConfirmationPageComponent,
  RegisterEmailPageComponent,
  RegisterEmailPendingPageComponent,
  RegisterSubscriptionPageComponent
} from './pages';

@NgModule({
  imports: [ContractModule, SubscriptionModule, AccessCodeModule, SharedComponentsModule, FormModule, SharedCommonModule, SharedFormModule],
  declarations: [
    //  RegisterCodeEmailComponent,
    RegisterEmailComponent,
    RegisterEmailFormComponent,
    RegisterEmailPendingComponent,
    RegisterEmailConfirmationComponent,
    RegisterContractComponent,
    RegisterContractEmailComponent,
    RegisterContractEmailConfirmComponent,
    RegisterContractEmailPendingComponent,
    RegisterContractConfirmDetailsComponent,
    RecoverEmailComponent,
    RegisterAccessCodeComponent,
    RegisterTitleComponent,
    RegisterSubscriptionComponent,

    RegisterAccessCodePageComponent,
    RegisterContractPageComponent,
    RegisterContractConfirmationPageComponent,
    RegisterEmailPageComponent,
    RegisterEmailConfirmationPageComponent,
    RegisterEmailPendingPageComponent,
    RegisterSubscriptionPageComponent
  ],
  exports: [
    //  RegisterCodeEmailComponent,
    RegisterEmailComponent,
    RegisterEmailFormComponent,
    RegisterEmailPendingComponent,
    RegisterEmailConfirmationComponent,
    RegisterContractComponent,
    RegisterContractEmailComponent,
    RegisterContractEmailConfirmComponent,
    RegisterContractEmailPendingComponent,
    RegisterContractConfirmDetailsComponent,
    RecoverEmailComponent,
    RegisterAccessCodeComponent,
    RegisterTitleComponent,
    RegisterSubscriptionComponent
  ]
})
export class RegisterModule {
  static forRoot(): ModuleWithProviders<RegisterModule> {
    return {
      ngModule: RegisterModule,
      providers: []
    };
  }
}
