import { Injectable } from "@angular/core";
import { IAccessCodeDetailsDispatcher } from "@client/interfaces/access-code-details-interface";
import { Store } from "@ngrx/store";
import { AccessCodeDetailsInitAction, AccessCodeDetailsDestroyAction, AccessCodeDetailsClickAddAccessCodeAction } from "@client/actions/access-code-details-actions";
@Injectable()
export class AccessCodeDetailsDispatcher implements IAccessCodeDetailsDispatcher {
    constructor(private store: Store<any>) {
    }
    init(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeDetailsInitAction(payload, correlationId));
    }
    destroy(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeDetailsDestroyAction(payload, correlationId));
    }
    clickAddAccessCode(payload?: null, correlationId = Date.now()) {
        this.store.dispatch(new AccessCodeDetailsClickAddAccessCodeAction(payload, correlationId));
    }
}
