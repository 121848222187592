import { State as SubscriptionDetailsState } from "@client/reducers/subscription-details-reducer";
import { Selector } from "@ngrx/store";
import { SubscriptionInfo, SubscriptionSpaqInfo, AccesDocumentInfo, PaymentMethodInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<SubscriptionDetailsState, boolean> = (state: SubscriptionDetailsState) => state.isActive;
export const getIsLoading: Selector<SubscriptionDetailsState, boolean> = (state: SubscriptionDetailsState) => state.isLoading;
export const getForm: Selector<SubscriptionDetailsState, SubscriptionInfo> = (state: SubscriptionDetailsState) => state.form;
export const getFormCustom: Selector<SubscriptionDetailsState, any> = (state: SubscriptionDetailsState) => getForm(state) && getForm(state).custom;
export const getFormSpaq: Selector<SubscriptionDetailsState, SubscriptionSpaqInfo> = (state: SubscriptionDetailsState) => getForm(state) && getForm(state).spaq;
export const getFormSpaqAccesDocuments: Selector<SubscriptionDetailsState, AccesDocumentInfo[]> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).accesDocuments;
export const getFormSpaqAccessOnHoldReason: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).accessOnHoldReason;
export const getFormSpaqAddressLine1: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).addressLine1;
export const getFormSpaqIdentificationCode: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).identificationCode;
export const getFormSpaqAddressLine2: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).addressLine2;
export const getFormSpaqAppartmentNo: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).appartmentNo;
export const getFormSpaqBalance: Selector<SubscriptionDetailsState, number> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).balance;
export const getFormSpaqCellPhone: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).cellPhone;
export const getFormSpaqCity: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).city;
export const getFormSpaqContractNo: Selector<SubscriptionDetailsState, number> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).contractNo;
export const getFormSpaqDynamicFieldJsonData: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).dynamicFieldJsonData;
export const getFormSpaqEmail: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).email;
export const getFormSpaqFirstName: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).firstName;
export const getFormSpaqHomePhone: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).homePhone;
export const getFormSpaqHomePhoneExtension: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).homePhoneExtension;
export const getFormSpaqIsAccessOnHold: Selector<SubscriptionDetailsState, boolean> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).isAccessOnHold;
export const getFormSpaqLanguage: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).language;
export const getFormSpaqLastName: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).lastName;
export const getFormSpaqPostalCode: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).postalCode;
export const getFormSpaqProvince: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).province;
export const getFormSpaqServiceCenter: Selector<SubscriptionDetailsState, number> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).serviceCenter;
export const getFormSpaqSex: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).sex;
export const getFormSpaqWorkPhone: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).workPhone;
export const getFormSpaqWorkPhoneExtension: Selector<SubscriptionDetailsState, number> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).workPhoneExtension;
export const getFormSpaqPaymentMethod: Selector<SubscriptionDetailsState, PaymentMethodInfo> = (state: SubscriptionDetailsState) => getFormSpaq(state) && getFormSpaq(state).paymentMethod;
export const getFormSpaqPaymentMethodCreditCardDataKey: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).CreditCardDataKey;
export const getFormSpaqPaymentMethodEffectiveDate: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).EffectiveDate;
export const getFormSpaqPaymentMethodEndDate: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).EndDate;
export const getFormSpaqPaymentMethodFinancialInstitutionId: Selector<SubscriptionDetailsState, number> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).FinancialInstitutionId;
export const getFormSpaqPaymentMethodFolioNo: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).FolioNo;
export const getFormSpaqPaymentMethodMethod: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).Method;
export const getFormSpaqPaymentMethodTransitNo: Selector<SubscriptionDetailsState, string> = (state: SubscriptionDetailsState) => getFormSpaqPaymentMethod(state) && getFormSpaqPaymentMethod(state).TransitNo;
