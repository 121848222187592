import { ADMIN_ORGANIZATION_LIST, ADMIN_GROUP_LIST, ADMIN_CODE_LIST, ADMIN_CODE_FORM } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { AccessCodeInfo, OrganizationInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";
export interface State {
    isActive: boolean;
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoading: boolean;
    isEdit: boolean;
    form: AccessCodeInfo | null;
}
export const initialState: State = {
    isActive: false,
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoading: false,
    isEdit: false,
    form: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case ADMIN_CODE_FORM.INIT: return adminCodeFormInit(state);
        case ADMIN_CODE_FORM.DESTROY: return adminCodeFormDestroy(state);
        case ADMIN_CODE_FORM.CLICK_ADD_ORGANIZATION: return adminCodeFormClickAddOrganization(state);
        case ADMIN_CODE_FORM.CLICK_ADD_GROUP: return adminCodeFormClickAddGroup(state);
        case ADMIN_CODE_FORM.SELECT_ORGANIZATION_CODE: return adminCodeFormSelectOrganizationCode(state, action.payload);
        case ADMIN_CODE_FORM.SELECT_GROUP_CODE: return adminCodeFormSelectGroupCode(state, action.payload);
        case ADMIN_CODE_FORM.CLICK_SUBMIT: return adminCodeFormClickSubmit(state, action.payload);
        case ADMIN_CODE_FORM.UPDATE: return adminCodeFormUpdate(state, action.payload);
        case ADMIN_CODE_FORM.UPDATE_SUCCESS: return adminCodeFormUpdateSuccess(state);
        case ADMIN_CODE_FORM.UPDATE_FAILURE: return adminCodeFormUpdateFailure(state, action.payload);
        case ADMIN_CODE_FORM.ADD: return adminCodeFormAdd(state, action.payload);
        case ADMIN_CODE_FORM.ADD_SUCCESS: return adminCodeFormAddSuccess(state);
        case ADMIN_CODE_FORM.ADD_FAILURE: return adminCodeFormAddFailure(state, action.payload);
        case ADMIN_ORGANIZATION_LIST.SELECT: return adminOrganizationListSelect(state, action.payload);
        case ADMIN_GROUP_LIST.SELECT: return adminGroupListSelect(state, action.payload);
        case ADMIN_CODE_LIST.CLICK_ADD: return adminCodeListClickAdd(state);
        case ADMIN_CODE_LIST.CLICK_EDIT: return adminCodeListClickEdit(state);
        case ADMIN_CODE_LIST.SELECT: return adminCodeListSelect(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_ORGANIZATION_GROUP: return adminCodeListSelectOrganizationGroup(state, action.payload);
        case ADMIN_CODE_LIST.SELECT_ORGANIZATION: return adminCodeListSelectOrganization(state, action.payload);
        default: return state;
    }
}
export function adminCodeFormInit(state: State): State {
    return {
        ...state,
        isActive: true,
    };
}
export function adminCodeFormDestroy(state: State): State {
    return {
        ...state,
        isActive: false,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminCodeFormClickAddOrganization(state: State): State {
    return state;
}
export function adminCodeFormClickAddGroup(state: State): State {
    return state;
}
export function adminCodeFormSelectOrganizationCode(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            organizationCode: payload,
        },
    };
}
export function adminCodeFormSelectGroupCode(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload,
        },
    };
}
export function adminCodeFormClickSubmit(state: State, payload: AccessCodeInfo): State {
    return { ...state };
}
export function adminCodeFormUpdate(state: State, payload: AccessCodeInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminCodeFormUpdateSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
    };
}
export function adminCodeFormUpdateFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminCodeFormAdd(state: State, payload: AccessCodeInfo): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null,
    };
}
export function adminCodeFormAddSuccess(state: State): State {
    return {
        ...state,
        isLoading: false,
        hasSuccess: true,
        form: {
            ...initialState.form,
            organizationCode: state.form.organizationCode,
            groupCode: state.form.groupCode,
        },
    };
}
export function adminCodeFormAddFailure(state: State, payload: any): State {
    return {
        ...state,
        isLoading: false,
        hasError: true,
        message: payload,
    };
}
export function adminOrganizationListSelect(state: State, payload: OrganizationInfo): State {
    return {
        ...state,
        form: {
            ...initialState.form,
            organizationCode: payload.organizationCode
        }
    };
}
export function adminGroupListSelect(state: State, payload: AccessCodeGroupInfo): State {
    return {
        ...state,
        form: {
            ...initialState.form,
            organizationCode: state.form && state.form.organizationCode || null,
            groupCode: payload.groupCode
        }
    };
}
export function adminCodeListClickAdd(state: State): State {
    return {
        ...state,
        isEdit: false,
    };
}
export function adminCodeListClickEdit(state: State): State {
    return {
        ...state,
        isEdit: true,
    };
}
export function adminCodeListSelect(state: State, payload: AccessCodeInfo): State {
    return {
        ...state,
        form: payload,
    };
}
export function adminCodeListSelectOrganizationGroup(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            groupCode: payload,
        },
    };
}
export function adminCodeListSelectOrganization(state: State, payload: string): State {
    return {
        ...state, form: {
            ...state.form,
            organizationCode: payload,
        },
    };
}
