import { ACCESS_CODE } from "@client/actions";
import { Action } from "@client/lib/action";
import { EmailEditForm, ActionCodeInfo, RegisterEmailForm, AuthCredential, LoginForm, User, AuthCodeInfo, ForgotPasswordForm, ResetPasswordForm, UpdatePasswordForm, Session, FaqDataInfo, FaqInfo, OrganizationInfo, SectorInfo, ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm, NodeItem, LayoutScreenInfo, ProfileInfo, ProfileWritableEntities, ProfileEditForm, SubscriptionInfo, UserEntity, AccessCodeInfo, MetadataState, AccountStatusState, AccessCodeSearchInfo, UserState, Vehicule, ServiceInfo, AccessCodeGroupInfo } from "@client/utils/shared-constants";

export class AccessCodeInitAction implements Action {
    readonly type = ACCESS_CODE.INIT;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccessCodeDestroyAction implements Action {
    readonly type = ACCESS_CODE.DESTROY;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccessCodeHydrateAction implements Action {
    readonly type = ACCESS_CODE.HYDRATE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccessCodeHydrateFailureAction implements Action {
    readonly type = ACCESS_CODE.HYDRATE_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}

export class AccessCodeHydrateSuccessAction implements Action {
    readonly type = ACCESS_CODE.HYDRATE_SUCCESS;
    constructor(public payload: AccessCodeGroupInfo, public correlationId?: number) {
    }
}

export class AccessCodeAddAction implements Action {
    readonly type = ACCESS_CODE.ADD;
    constructor(public payload: AccessCodeSearchInfo, public correlationId?: number) {
    }
}

export class AccessCodeDeleteAction implements Action {
    readonly type = ACCESS_CODE.DELETE;
    constructor(public payload?: null, public correlationId?: number) {
    }
}

export class AccessCodeAddSuccessAction implements Action {
    readonly type = ACCESS_CODE.ADD_SUCCESS;
    constructor(public payload: AccessCodeSearchInfo, public correlationId?: number) {
    }
}

export class AccessCodeAddFailureAction implements Action {
    readonly type = ACCESS_CODE.ADD_FAILURE;
    constructor(public payload: any, public correlationId?: number) {
    }
}
