import { ModuleWithProviders, NgModule } from '@angular/core';
import { EmailDetailsComponent, EmailEditComponent } from '@client/modules/email/pages';
import { FormModule } from '@client/shared/components/forms';
import { SharedCommonModule } from '@client/shared/shared-common.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { SharedFormModule } from '@client/shared/shared-form.module';
import { UpdateEmailComponent } from './components';

@NgModule({
  imports: [SharedCommonModule, FormModule, SharedComponentsModule, SharedFormModule],
  declarations: [EmailEditComponent, UpdateEmailComponent, EmailDetailsComponent],
  exports: [EmailEditComponent, UpdateEmailComponent, EmailDetailsComponent]
})
export class EmailModule {
  static forRoot(): ModuleWithProviders<EmailModule> {
    return {
      ngModule: EmailModule,
      providers: []
    };
  }
}
